// @ts-nocheck
import { GraphQLErrors } from '@apollo/client/errors'
import FormError from '@/composables/common/form/FormError'
import FormErrors from '@/composables/common/form/FormErrors'
import { UnwrapNestedRefs } from 'vue'

/**
 * @deprecated Vuelidate導入に伴い不要になったので、`src/utils`の`extractValidationErrors()`を使用する
 * @param graphQLErrors
 * @param formErrors
 */
export default function onFormErrors (graphQLErrors: GraphQLErrors, formErrors: UnwrapNestedRefs<FormErrors>) {
  graphQLErrors.forEach((e) => {
    if (e.extensions.category === 'validation') {
      // バリデーションエラー
      Object.keys(e.extensions.validation).forEach((key, index) => {
        const formName = key.replace('input.', '').replace(/_([a-z])/g, (g) => g[1].toUpperCase())
        const message = e.extensions.validation[key]?.join(' ')
        formErrors[formName] = new FormError(message)
        formErrors.__errorCount__ = index + 1
      })
    }
  })
}
