import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstProjects from '@/const/transaction/projects'

const constProjects = ConstProjects()

const baseListColumns: ListViewColumn[] = [{
  id: 'id',
  text: constProjects.columnLabel.get('id'),
  displayField: 'id',
  sortField: 'id_number',
  align: 'right',
}, {
  id: 'name',
  text: constProjects.columnLabel.get('name'),
  displayField: 'name',
  sortField: 'kana',
}, {
  id: 'contract_job_amount',
  text: constProjects.columnLabel.get('contractJobAmount'),
  displayField: 'contract_job_amount',
  sortField: 'contract_job_amount',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'target_marginal_profit',
  text: constProjects.columnLabel.get('targetMarginalProfit'),
  displayField: 'target_marginal_profit',
  sortField: 'target_marginal_profit',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'marginal_profit',
  text: constProjects.columnLabel.get('marginalProfit'),
  displayField: 'marginal_profit',
  sortField: 'marginal_profit',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'before_cost',
  text: constProjects.columnLabel.get('beforeCost'),
  displayField: 'before_cost',
  sortField: 'before_cost',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'variable_cost',
  text: constProjects.columnLabel.get('variableCost'),
  displayField: 'variable_cost',
  sortField: 'variable_cost',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'contract_construction_period',
  text: constProjects.columnLabel.get('contractConstructionPeriod'),
  align: 'center',
  columns: [{
    id: 'contract_construction_period_start_date',
    text: constProjects.columnLabel.get('startDate'),
    displayField: 'contract_construction_period_start_date',
    sortField: 'contract_construction_period_start_date',
  }, {
    id: 'contract_construction_period_end_date',
    text: constProjects.columnLabel.get('endDate'),
    displayField: 'contract_construction_period_end_date',
    sortField: 'contract_construction_period_end_date',
  }],
}, {
  id: 'salesRep',
  text: constProjects.columnLabel.get('salesRep'),
  displayField: 'sales_rep_name',
  sortField: 'sales_rep_kana',
}, {
  id: 'siteAgent',
  text: constProjects.columnLabel.get('siteAgent'),
  displayField: 'site_agent_name',
  sortField: 'site_agent_kana',
}, {
  id: 'completion_time',
  text: constProjects.columnLabel.get('completionTime'),
  displayField: 'completion_time',
  sortField: 'completion_time',
}, {
  id: 'note',
  text: constProjects.columnLabel.get('note'),
  displayField: 'note',
  sortField: 'note',
}, {
  id: 'process_status',
  text: constProjects.columnLabel.get('processStatus'),
  displayField: 'process_status',
  sortField: 'process_status',
  align: 'center',
}, {
  id: 'bill_status',
  text: constProjects.columnLabel.get('invoiceStatus'),
  displayField: 'bill_status',
  sortField: 'bill_status',
  align: 'center',
}, {
  id: 'updated_at',
  text: constProjects.columnLabel.get('updatedAt'),
  displayField: 'updated_at',
  sortField: 'updated_at',
}, {
  id: 'updated_by',
  text: constProjects.columnLabel.get('updatedBy'),
  displayField: 'updated_by_name',
  sortField: 'updated_by_kana',
}]

const standardListColumns = {
  project: [
    'id',
    'name',
    'contract_job_amount',
    'target_marginal_profit',
    'marginal_profit',
    'before_cost',
    'variable_cost',
    //'contract_construction_period',
    //'salesRep',
    //'siteAgent',
    'completion_time',
    'note',
    'updated_at',
    'updated_by',
  ],
}
const listIcon: ListViewIcon = {
  caution: {
    name: 'triangle-exclamation',
    classes: [
      '--error',
    ],
  },
}
const listConverter: ListViewConverter = {
  processStatus: {
    0: {
      text: constProjects.processStatus.get('0') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(180,160,0)',
        color: 'rgb(255,255,255)',
      },
    },
    1: {
      text: constProjects.processStatus.get('1') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(0,140,0)',
        color: 'rgb(255,255,255)',
      },
    },
    2: {
      text: constProjects.processStatus.get('2') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(0,0,200)',
        color: 'rgb(255,255,255)',
      },
    },
    3: {
      text: constProjects.processStatus.get('3') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(200,0,0)',
        color: 'rgb(255,255,255)',
      },
    },
    4: {
      text: constProjects.processStatus.get('4') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(0,100,100)',
        color: 'rgb(255,255,255)',
      },
    },
    10: {
      text: constProjects.processStatus.get('10') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(160,80,80)',
        color: 'rgb(255,255,255)',
      },
    },
  },
  invoiceStatus: {
    0: {
      text: '',
      type: 'ellipse',
      style: {
        background: 'transparent',
        color: 'transparent',
      },
    },
    1: {
      text: constProjects.invoiceStatus.get('1') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(200,0,0)',
        color: 'rgb(255,255,255)',
      },
    },
    2: {
      text: constProjects.invoiceStatus.get('2') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(180,120,0)',
        color: 'rgb(255,255,255)',
      },
    },
    3: {
      text: constProjects.invoiceStatus.get('3') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(0,140,180)',
        color: 'rgb(255,255,255)',
      },
    },
    4: {
      text: constProjects.invoiceStatus.get('4') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(0,140,0)',
        color: 'rgb(255,255,255)',
      },
    },
  },
}

export default function project () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<Array<ListViewRow>>([])
  const listSort: ListViewSort = reactive({
    field: 'kana',
    order: {
      'kana': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }
  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
