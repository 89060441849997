export interface DbPayment {
  id: string
  payment_date: string
  supplier_code: string
  supplier_name: string
  supplier_kana: string
  number_of_deposits: number
  payment_method: string
  payment_method_type: number
  payment_amount: bigint
  updated_at: string
  updated_by_name: string
  updated_by_kana: string
}

export default function payments () {
  const payments: Array<DbPayment> = [{
    id: '1234',
    payment_date: '2023-02-03',
    supplier_code: '22345678',
    supplier_name: 'KSY',
    supplier_kana: 'ケイエスワイ',
    number_of_deposits: 1,
    payment_method: '振込',
    payment_method_type: 1,
    payment_amount: 10000000n,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }]

  return {
    payments,
  }
}
