import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstPayments from '@/const/transaction/payments'

const constPayments = ConstPayments()

const baseListColumns: ListViewColumn[] = [{
  id: 'payment_date',
  text: constPayments.columnLabel.get('paymentDate'),
  displayField: 'payment_date',
  sortField: 'payment_date',
}, {
  id: 'supplier_code',
  text: constPayments.columnLabel.get('supplierCode'),
  displayField: 'supplier_code',
  sortField: 'supplier_code',
}, {
  id: 'supplier',
  text: constPayments.columnLabel.get('supplier'),
  displayField: 'supplier_name',
  sortField: 'supplier_kana',
  /*
}, {
  id: 'number_of_deposits',
  text: constPayments.columnLabel.get('numberOfDeposits'),
  displayField: 'number_of_deposits',
  sortField: 'number_of_deposits',
  align: 'right',
  */
}, {
  id: 'payment_method',
  text: constPayments.columnLabel.get('paymentMethod'),
  displayField: 'payment_method',
  sortField: 'payment_method_type',
}, {
  id: 'payment_amount',
  text: constPayments.columnLabel.get('paymentAmount'),
  displayField: 'payment_amount',
  sortField: 'payment_amount',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}]

const standardListColumns = {
  general: [
    'payment_date',
    'supplier_code',
    'supplier',
    //'number_of_deposits',
    'payment_method',
    'payment_amount',
  ],
  /*
  client: [
    'payment_date',
    //'number_of_deposits',
    'payment_method',
    'payment_amount',
  ],
  */
}

const listIcon: ListViewIcon = {
}
const listConverter: ListViewConverter = {
}

export default function payment () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'payment_date',
    order: {
      'payment_date': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
