import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstPurchases from '@/const/transaction/purchases'

const constPurchases = ConstPurchases()

const baseListColumns: ListViewColumn[] = [{
  id: 'purchaseInformation',
  text: constPurchases.columnLabel.get('purchaseInformation'),
  align: 'center',
  columns: [{
    id: 'status',
    text: constPurchases.columnLabel.get('status'),
    displayField: 'status',
    sortField: 'status',
    align: 'center',
    width: '200px',
  }, {
    id: 'id',
    text: constPurchases.columnLabel.get('id'),
    displayField: 'id',
    sortField: 'id_number',
    align: 'right',
    bottomRow: {
      bold: true,
    },
  }, {
    id: 'issue_date',
    text: constPurchases.columnLabel.get('issueDate'),
    displayField: 'issue_date',
    sortField: 'issue_date',
  }, {
    id: 'issuer',
    text: constPurchases.columnLabel.get('issuer'),
    displayField: 'issuer_name',
    sortField: 'issuer_kana',
  }, {
    id: 'order_date',
    text: constPurchases.columnLabel.get('orderDate'),
    displayField: 'order_date',
    sortField: 'order_date',
  }, {
    id: 'orderer',
    text: constPurchases.columnLabel.get('orderer'),
    displayField: 'orderer_name',
    sortField: 'orderer_kana',
  }, {
    id: 'updated_at',
    text: constPurchases.columnLabel.get('updatedAt'),
    displayField: 'updated_at',
    sortField: 'updated_at',
  }, {
    id: 'updated_by',
    text: constPurchases.columnLabel.get('updatedBy'),
    displayField: 'updated_by_name',
    sortField: 'updated_by_kana',
  }],
}, {
  id: 'purchaseInformation_draft',
  text: constPurchases.columnLabel.get('purchaseInformation'),
  align: 'center',
  columns: [{
    id: 'id',
    text: constPurchases.columnLabel.get('id'),
    displayField: 'id',
    sortField: 'id_number',
    align: 'right',
  }, {
    id: 'issue_date',
    text: constPurchases.columnLabel.get('issueDate'),
    displayField: 'issue_date',
    sortField: 'issue_date',
  }, {
    id: 'issuer',
    text: constPurchases.columnLabel.get('issuer'),
    displayField: 'issuer_name',
    sortField: 'issuer_kana',
  }, {
    id: 'updated_at',
    text: constPurchases.columnLabel.get('updatedAt'),
    displayField: 'updated_at',
    sortField: 'updated_at',
  }, {
    id: 'updated_by',
    text: constPurchases.columnLabel.get('updatedBy'),
    displayField: 'updated_by_name',
    sortField: 'updated_by_kana',
  }],
}, {
  id: 'supplierInformation',
  text: constPurchases.columnLabel.get('supplierInformation'),
  align: 'center',
  columns: [{
    id: 'supplier',
    text: constPurchases.columnLabel.get('supplier'),
    displayField: 'supplier_name',
    sortField: 'supplier_kana',
  }, {
    id: 'supplier_quotation_no',
    text: constPurchases.columnLabel.get('supplierQuotationNo'),
    displayField: 'supplier_quotation_no',
    sortField: 'supplier_quotation_no',
  }],
}, {
  id: 'buyerInformation',
  text: constPurchases.columnLabel.get('buyerInformation'),
  align: 'center',
  columns: [{
    id: 'base',
    text: constPurchases.columnLabel.get('base'),
    displayField: 'base_name',
    sortField: 'base_display_order',
  }],
}, {
  id: 'contractInformation',
  text: constPurchases.columnLabel.get('contractInformation'),
  align: 'center',
  columns: [{
    id: 'apportionment_id',
    text: constPurchases.columnLabel.get('apportionmentId'),
    displayField: 'apportionment_id',
    sortField: 'apportionment_id_number',
    align: 'right',
  }, {
    id: 'apportionment_name',
    text: constPurchases.columnLabel.get('apportionmentName'),
    displayField: 'apportionment_name',
    sortField: 'apportionment_kana',
  }, {
    id: 'construction_period',
    text: constPurchases.columnLabel.get('constructionPeriod'),
    displayField: 'construction_period',
    sortField: 'construction_period',
  }, {
    id: 'sales_representative',
    text: constPurchases.columnLabel.get('salesRepresentative'),
    displayField: 'sales_representative_name',
    sortField: 'sales_representative_kana',
  }, {
    id: 'site_agent',
    text: constPurchases.columnLabel.get('siteAgent'),
    displayField: 'site_agent_name',
    sortField: 'site_agent_kana',
  }],
}, {
  id: 'transactionInformation',
  text: constPurchases.columnLabel.get('transactionInformation'),
  align: 'center',
  columns: [{
    id: 'delivery_destination',
    text: constPurchases.columnLabel.get('deliveryDestination'),
    displayField: 'delivery_destination',
    sortField: 'delivery_destination',
  }, {
    id: 'delivery_address',
    text: constPurchases.columnLabel.get('deliveryAddress'),
    displayField: 'delivery_address',
    sortField: 'delivery_address',
  }, {
    id: 'delivery_deadline',
    text: constPurchases.columnLabel.get('deliveryDeadline'),
    displayField: 'delivery_deadline',
    sortField: 'delivery_deadline',
  }, {
    id: 'delivery_conditions',
    text: constPurchases.columnLabel.get('deliveryConditions'),
    displayField: 'delivery_conditions',
    sortField: 'delivery_conditions',
  }, {
    id: 'payment_terms',
    text: constPurchases.columnLabel.get('paymentTerms'),
    displayField: 'payment_terms',
    sortField: 'payment_terms',
  }, {
    id: 'order_details',
    text: constPurchases.columnLabel.get('orderDetails'),
    displayField: 'order_details',
    sortField: 'order_details',
  }, {
    id: 'note',
    text: constPurchases.columnLabel.get('note'),
    displayField: 'note',
    sortField: 'note',
  }],
}, {
  id: 'costInformation',
  text: constPurchases.columnLabel.get('costInformation'),
  align: 'center',
  columns: [{
    id: 'preOrderCost',
    text: constPurchases.columnLabel.get('preOrderCost'),
    displayField: 'pre_order_cost',
    sortField: 'pre_order_cost',
    displayConvert: 'check',
    align: 'center',
    width: '100px',
  }, {
    id: 'amount_including_tax',
    text: constPurchases.columnLabel.get('amountIncludingTax'),
    displayField: 'amount_including_tax',
    displayConvert: 'numericalDelimiter',
    sortField: 'amount_including_tax',
    align: 'right',
    bottomRow: {
      bold: true,
    },
  }, {
    id: 'amount_excluding_tax',
    text: constPurchases.columnLabel.get('amountExcludingTax'),
    displayField: 'amount_excluding_tax',
    displayConvert: 'numericalDelimiter',
    sortField: 'amount_excluding_tax',
    align: 'right',
    bottomRow: {
      bold: true,
    },
  }, {
    id: 'consumption_tax_amount',
    text: constPurchases.columnLabel.get('consumptionTaxAmount'),
    displayField: 'consumption_tax_amount',
    displayConvert: 'numericalDelimiter',
    sortField: 'consumption_tax_amount',
    align: 'right',
    bottomRow: {
      bold: true,
    },
  }, {
    id: 'delivered_amount_including_tax',
    text: constPurchases.columnLabel.get('deliveredAmountIncludingTax'),
    displayField: 'delivered_amount_including_tax',
    sortField: 'delivered_amount_including_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
    bottomRow: {
      bold: true,
    },
  }, {
    id: 'delivered_amount_excluding_tax',
    text: constPurchases.columnLabel.get('deliveredAmountExcludingTax'),
    displayField: 'delivered_amount_excluding_tax',
    sortField: 'delivered_amount_excluding_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
    bottomRow: {
      bold: true,
    },
  }, {
    id: 'amount_balance_including_tax',
    text: constPurchases.columnLabel.get('amountBalanceIncludingTax'),
    displayField: 'amount_balance_including_tax',
    sortField: 'amount_balance_including_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
    bottomRow: {
      bold: true,
    },
  }, {
    id: 'amount_balance_excluding_tax',
    text: constPurchases.columnLabel.get('amountBalanceExcludingTax'),
    displayField: 'amount_balance_excluding_tax',
    sortField: 'amount_balance_excluding_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
    bottomRow: {
      bold: true,
    },
  }],
}]

const standardListColumns = {
  purchase: [
    'purchaseInformation',
    'supplierInformation',
    'buyerInformation',
    'contractInformation',
    'transactionInformation',
    'costInformation',
  ],
  general: [
    'purchaseInformation',
    'supplierInformation',
    'buyerInformation',
    'contractInformation',
    'transactionInformation',
    'costInformation',
  ],
  draft: [
    'purchaseInformation_draft',
    'supplierInformation',
    'buyerInformation',
    'contractInformation',
    'transactionInformation',
    'costInformation',
  ],
  backlog: [
    'purchaseInformation',
    'supplierInformation',
    'buyerInformation',
    'contractInformation',
    'transactionInformation',
    'costInformation',
  ],
}

const listIcon: ListViewIcon = {
  caution: {
    name: 'triangle-exclamation',
    classes: [
      '--error',
    ]
  },
}
const listConverter: ListViewConverter = {
  //expense_type: ConverterExpenseType,
  //account: ConverterAccountType,
  status: {
    '0': {
      text: constPurchases.status.get('0') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(100,0,0)',
        color: 'rgb(255,255,255)',
      },
    },
    '1': {
      text: constPurchases.status.get('1') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(200,0,0)',
        color: 'rgb(255,255,255)',
      },
    },
    /*
    '2': {
      text: constPurchases.status.get('2') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(100,100,100)',
        color: 'rgb(200,200,200)',
      },
    },
    */
    '2': {
      text: constPurchases.status.get('2') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(200,160,0)',
        color: 'rgb(255,255,255)',
      },
    },
    '3': {
      text: constPurchases.status.get('3') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(0,140,0)',
        color: 'rgb(255,255,255)',
      },
    },
    '4': {
      text: constPurchases.status.get('4') ?? '',
      type: 'rectangle',
      style: {
        background: 'rgb(0,0,200)',
        color: 'rgb(255,255,255)',
      },
    },
    '10': {
      text: constPurchases.status.get('10') ?? '',
      type: 'rectangle',
      style: {
        background: 'rgb(100,100,100)',
        color: 'rgb(200,200,200)',
      },
    },
    '20': {
      text: constPurchases.status.get('20') ?? '',
      type: 'rectangle',
      style: {
        background: 'rgb(200,0,200)',
        color: 'rgb(255,255,255)',
      },
    },
  },
}

export default function sendOrder () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'id_number',
    order: {
      'id_number': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
        contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }
  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }
  const sumValue = reactive({
    id: '合計',
    amount_including_tax: 0,
    amount_excluding_tax: 0,
    consumption_tax_amount: 0,
    delivered_amount_including_tax: 0,
    delivered_amount_excluding_tax: 0,
    amount_balance_including_tax: 0,
    amount_balance_excluding_tax: 0,
  })

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    sumValue,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
