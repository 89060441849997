import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import ConstEstimates from '@/const/transaction/estimates'
import {
  SearchBoxValue,
  SearchBoxItem,
} from '@/const/component/searchBox'

const constEstimates = ConstEstimates()

const items = [
  'id',
  'revisionNo',
  'revisionDescription',
  'client',
  'estimateDate',
  'memo',
  'issueDate',
  'issuer',
  'sentDate',
  'sender',
  'updatedAt',
  'updatedBy',
  'base',
  'contract',
  'contractId',
  'contractName',
  'deliveryDate',
  'placeOfDelivery',
  'transactionSystem',
  'dateOfExpiry',
  'amountIncludingTax',
  'amountExcludingTax',
  'consumptionTaxAmount',
]

export default function search () {
  const searchItems = computed(() => {
    return items.map(el => {
      console.log('el', el)
      console.log('constEstimates.searchLabel.get(el)', constEstimates.searchLabel.get(el))
      return {
        id: el,
        label: constEstimates.searchLabel.get(el),
      } as SearchBoxItem
    })
  })

  return {
    searchItems,
  }
}
