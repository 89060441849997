import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstDepositBalances from '@/const/transaction/depositBalances'

const constDepositBalances = ConstDepositBalances()

const baseListColumns: ListViewColumn[] = [{
  id: 'year_month',
  text: constDepositBalances.columnLabel.get('yearMonth'),
  displayField: 'year_month',
  sortField: 'year_month',
}, {
  id: 'billing_target_code',
  text: constDepositBalances.columnLabel.get('billingTargetCode'),
  displayField: 'billing_target_code',
  sortField: 'billing_target_code',
}, {
  id: 'billing_target',
  text: constDepositBalances.columnLabel.get('billingTarget'),
  displayField: 'billing_target_name',
  sortField: 'billing_target_kana',
}, {
  id: 'previous_month_balance',
  text: constDepositBalances.columnLabel.get('previousMonthBalance'),
  displayField: 'previous_month_balance',
  sortField: 'previous_month_balance',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'amount_incurred',
  text: constDepositBalances.columnLabel.get('amountIncurred'),
  displayField: 'amount_incurred',
  sortField: 'amount_incurred',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'deposit_amount',
  text: constDepositBalances.columnLabel.get('depositAmount'),
  displayField: 'deposit_amount',
  sortField: 'deposit_amount',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'current_month_balance',
  text: constDepositBalances.columnLabel.get('currentMonthBalance'),
  displayField: 'current_month_balance',
  sortField: 'current_month_balance',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}]

const standardListColumns = {
  balance: [
    'billing_target_code',
    'billing_target',
    'previous_month_balance',
    'amount_incurred',
    'deposit_amount',
    'current_month_balance',
  ],
}

const listIcon: ListViewIcon = {
}
const listConverter: ListViewConverter = {
}

export default function depositBalance () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'billing_target_kana',
    order: {
      'billing_target_kana': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
