export interface DbJournalPurchaseDetail {
  id: string
  delivery_date: string
  expense_account_type: number
  consumption_tax_type: string
  amount: bigint
  supplier: string
  supplier_name: string
  supplier_kana: string
  purchase_delivery_id: string
  purchase_delivery_id_number: number
  purchase_delivery_details_line_no: number
  model_number: string
  description: string
  expense_type: number
}

export default function journalPurchaseDetails () {
  const journalPurchaseDetails: Array<DbJournalPurchaseDetail> = [{
    id: '1',
    delivery_date: '2023-05-02',
    expense_account_type: 1,
    consumption_tax_type: '外税 10%',
    amount: 1000000n,
    supplier: '1',
    supplier_name: '仕入先Ａ',
    supplier_kana: 'シイレサキA',
    purchase_delivery_id: '111',
    purchase_delivery_id_number: 111,
    purchase_delivery_details_line_no: 1,
    model_number: '1234-5678',
    description: 'LANケーブル　50m',
    expense_type: 1,
  }, {
    id: '2',
    delivery_date: '2023-05-14',
    expense_account_type: 2,
    consumption_tax_type: '外税 10%',
    amount: 700000n,
    supplier: '2',
    supplier_name: '仕入先Ｂ',
    supplier_kana: 'シイレサキB',
    purchase_delivery_id: '222',
    purchase_delivery_id_number: 222,
    purchase_delivery_details_line_no: 12,
    model_number: '',
    description: '高所配線作業',
    expense_type: 2,
  }, {
    id: '3',
    delivery_date: '2023-05-21',
    expense_account_type: 3,
    consumption_tax_type: '内税 10%',
    amount: 300000n,
    supplier: '3',
    supplier_name: '仕入先Ｃ',
    supplier_kana: 'シイレサキC',
    purchase_delivery_id: '312',
    purchase_delivery_id_number: 312,
    purchase_delivery_details_line_no: 3,
    model_number: 'A1234-01',
    description: '電気代',
    expense_type: 3,
  }]

  return {
    journalPurchaseDetails,
  }
}
