import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstJournalFixedValues from '@/const/transaction/journalFixedValues'

const constTable = ConstJournalFixedValues()

const baseListColumns: ListViewColumn[] = [{
  id: 'journal_type',
  text: constTable.columnLabel.get('journal_type'),
  displayField: 'journal_type',
  sortField: 'journal_type_number',
}, {
  id: 'data_type',
  text: constTable.columnLabel.get('data_type'),
  displayField: 'data_type',
  sortField: 'data_type',
  align: 'right',
}, {
  id: 'journal_entry_format',
  text: constTable.columnLabel.get('journal_entry_format'),
  displayField: 'journal_entry_format',
  sortField: 'journal_entry_format',
  align: 'right',
}, {
  id: 'slip_type',
  text: constTable.columnLabel.get('slip_type'),
  displayField: 'slip_type',
  sortField: 'slip_type',
  align: 'right',
}, {
  id: 'supplementary_code_debit',
  text: constTable.columnLabel.get('supplementary_code_debit'),
  displayField: 'supplementary_code_debit',
  sortField: 'supplementary_code_debit',
  align: 'right',
}, {
  id: 'subdivision_1st_debit',
  text: constTable.columnLabel.get('subdivision_1st_debit'),
  displayField: 'subdivision_1st_debit',
  sortField: 'subdivision_1st_debit',
  align: 'right',
}, {
  id: 'subdivision_2nd_debit',
  text: constTable.columnLabel.get('subdivision_2nd_debit'),
  displayField: 'subdivision_2nd_debit',
  sortField: 'subdivision_2nd_debit',
  align: 'right',
}, {
  id: 'auxiliary_code_2nd_debit',
  text: constTable.columnLabel.get('auxiliary_code_2nd_debit'),
  displayField: 'auxiliary_code_2nd_debit',
  sortField: 'auxiliary_code_2nd_debit',
  align: 'right',
}, {
  id: 'consumption_tax_code_debit',
  text: constTable.columnLabel.get('consumption_tax_code_debit'),
  displayField: 'consumption_tax_code_debit',
  sortField: 'consumption_tax_code_debit',
  align: 'right',
}, {
  id: 'consumption_tax_rate_debit',
  text: constTable.columnLabel.get('consumption_tax_rate_debit'),
  displayField: 'consumption_tax_rate_debit',
  sortField: 'consumption_tax_rate_debit',
  align: 'right',
}, {
  id: 'tax_excluded_type_debit',
  text: constTable.columnLabel.get('tax_excluded_type_debit'),
  displayField: 'tax_excluded_type_debit',
  sortField: 'tax_excluded_type_debit',
  align: 'right',
}, {
  id: 'supplementary_code_credit',
  text: constTable.columnLabel.get('supplementary_code_credit'),
  displayField: 'supplementary_code_credit',
  sortField: 'supplementary_code_credit',
  align: 'right',
}, {
  id: 'subdivision_1st_credit',
  text: constTable.columnLabel.get('subdivision_1st_credit'),
  displayField: 'subdivision_1st_credit',
  sortField: 'subdivision_1st_credit',
  align: 'right',
}, {
  id: 'subdivision_2nd_credit',
  text: constTable.columnLabel.get('subdivision_2nd_credit'),
  displayField: 'subdivision_2nd_credit',
  sortField: 'subdivision_2nd_credit',
  align: 'right',
}, {
  id: 'auxiliary_code_2nd_credit',
  text: constTable.columnLabel.get('auxiliary_code_2nd_credit'),
  displayField: 'auxiliary_code_2nd_credit',
  sortField: 'auxiliary_code_2nd_credit',
  align: 'right',
}, {
  id: 'consumption_tax_code_credit',
  text: constTable.columnLabel.get('consumption_tax_code_credit'),
  displayField: 'consumption_tax_code_credit',
  sortField: 'consumption_tax_code_credit',
  align: 'right',
}, {
  id: 'consumption_tax_rate_credit',
  text: constTable.columnLabel.get('consumption_tax_rate_credit'),
  displayField: 'consumption_tax_rate_credit',
  sortField: 'consumption_tax_rate_credit',
  align: 'right',
}, {
  id: 'tax_excluded_type_credit',
  text: constTable.columnLabel.get('tax_excluded_type_credit'),
  displayField: 'tax_excluded_type_credit',
  sortField: 'tax_excluded_type_credit',
  align: 'right',
}, {
  id: 'updated_at',
  text: constTable.columnLabel.get('updated_at'),
  displayField: 'updated_at',
  sortField: 'updated_at',
}, {
  id: 'updated_by',
  text: constTable.columnLabel.get('updated_by'),
  displayField: 'updated_by_name',
  sortField: 'updated_by_kana',
}]

const standardListColumns = {
  fixed: [
    'journal_type',
    'data_type',
    'journal_entry_format',
    'slip_type',
    'supplementary_code_debit',
    'subdivision_1st_debit',
    'subdivision_2nd_debit',
    'auxiliary_code_2nd_debit',
    //'consumption_tax_code_debit',
    //'consumption_tax_rate_debit',
    'tax_excluded_type_debit',
    'supplementary_code_credit',
    'subdivision_1st_credit',
    'subdivision_2nd_credit',
    'auxiliary_code_2nd_credit',
    //'consumption_tax_code_credit',
    //'consumption_tax_rate_credit',
    'tax_excluded_type_credit',
    'updated_at',
    'updated_by',
  ],
}

const listIcon: ListViewIcon = {
}
const listConverter: ListViewConverter = {
}

export default function payment () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'journal_type_number',
    order: {
      'journal_type_number': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
