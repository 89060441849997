import gql from 'graphql-tag'
import {
  ref,
  reactive,
  computed,
  watch,
} from 'vue'
import {
  useQuery,
} from '@vue/apollo-composable'
import {
  Project,
  QueryProjectsArgs,
  QueryProjectsOrderByColumn,
  SortOrder ,
} from '@/graphql'

const doc = {
  projects: {
    select: gql`
      query GetSelectProjects($orderBy: [QueryProjectsOrderByOrderByClause!], $first: Int!, $page: Int!) {
        projects(orderBy: $orderBy, first: $first, page: $page) {
          data {
            id
            name
            kana
          }
          paginatorInfo {
            hasMorePages
          }
        }
      }
    `,
  },
}

const projects = () => {
  const items = ref<Project[]>([])
  const variables = reactive<QueryProjectsArgs>({
    first: 100,
    page: 1,
    orderBy: [{ column: QueryProjectsOrderByColumn.Id, order: SortOrder.Asc }],  //DisplayOrder に変更
  })
  const options:{ [ key: string ]: string } = {
    fetchPolicy: 'cache-and-network',
  }
  const query = useQuery(doc.projects.select, variables, options)
  const result = computed(() => (query.result.value ? query.result.value.projects : {}))
  const data = computed(() => result.value.data ?? [])
  const paginator = computed(() => result.value.paginatorInfo ?? {})

  watch(data, (val) => {
    if (!val.length) return
    items.value = items.value.concat(val)
  })
  watch(paginator, (val) => {
    if (variables && val.hasMorePages && variables.page) variables.page++
  })

  return {
    loading: query.loading,
    loadError: query.error,
    items,
  }
}

export default function projectSelect () {
  return {
    projects,
  }
}
