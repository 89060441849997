import gql from 'graphql-tag'
import {
  ref,
  reactive,
  computed,
  watch,
} from 'vue'
import {
  useQuery,
} from '@vue/apollo-composable'
import {
  Construction,
  QueryConstructionsArgs,
  QueryConstructionsOrderByColumn,
  SortOrder ,
} from '@/graphql'
import tools from '@/functions/tools'

const doc = {
  constructions: {
    select: gql`
        query GetSelectBoxConstructions($condition: ConstructionSearchCondition!, $orderBy: [QueryConstructionsOrderByOrderByClause!], $first: Int!, $page: Int!) {
        constructions(condition: $condition, orderBy: $orderBy, first: $first, page: $page) {
          data {
            id
            name
            kana
            construction_location
            overview
            contract_construction_period_start_date
            contract_construction_period_end_date
          }
          paginatorInfo {
            hasMorePages
          }
        }
      }
    `,
  },
}

const constructions = () => {
  const { stringToInt } = tools()
  const items = ref<Construction[]>([])
  const variables = reactive<QueryConstructionsArgs>({
    condition: {},
    first: 100,
    page: 1,
    orderBy: [{ column: QueryConstructionsOrderByColumn.Id, order: SortOrder.Asc }],
  })
  const options:{ [ key: string ]: string } = {
    fetchPolicy: 'cache-and-network',
  }
  const query = useQuery(doc.constructions.select, variables, options)
  const result = computed(() => (query.result.value ? query.result.value.constructions : {}))
  const data = computed(() => result.value.data ?? [])
  const paginator = computed(() => result.value.paginatorInfo ?? {})

  watch(data, (val) => {
    if (!val.length) return
    const itms = val as Construction[]
    const processed = itms.map(el => {
      return {
        ...el,
        id_number: stringToInt(el.id),
      }
    })
    items.value = items.value.concat(processed)
  })
  watch(paginator, (val) => {
    if (variables && val.hasMorePages && variables.page) variables.page++
  })

  return {
    loading: query.loading,
    loadError: query.error,
    items,
  }
}

export default function constructionSelectBox () {
  return {
    constructions,
  }
}
