export interface DbJournalDepositItems {
  id: string
  element_name: string
  deposit_type: string
  account_code_main_debit: number | null
  account_code_main_credit: number | null
  account_code_side_debit: number | null
  account_code_side_credit: number | null
  account_code_main_debit_advance: number | null
  account_code_main_credit_advance: number | null
  account_code_side_debit_advance: number | null
  account_code_side_credit_advance: number | null
  updated_at: string
  updated_by: string
  updated_by_name: string
  updated_by_kana: string
}

export default function journalDepositItems () {
  const journalDepositItems: Array<DbJournalDepositItems> = [{
    id: '1',
    element_name: '売上_振込普通東邦郡山南',
    deposit_type: '1',
    account_code_main_debit: 124,
    account_code_main_credit: 320,
    account_code_side_debit: 124,
    account_code_side_credit: 324,
    account_code_main_debit_advance: 320,
    account_code_main_credit_advance: 162,
    account_code_side_debit_advance: 324,
    account_code_side_credit_advance: 164,
    updated_at: '2023-04-01',
    updated_by: '1',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインA',
  }, {
    id: '2',
    element_name: '売上_振込普通福島縣商工',
    deposit_type: '1',
    account_code_main_debit: 111,
    account_code_main_credit: 333,
    account_code_side_debit: 127,
    account_code_side_credit: 324,
    account_code_main_debit_advance: 333,
    account_code_main_credit_advance: 163,
    account_code_side_debit_advance: 324,
    account_code_side_credit_advance: 164,
    updated_at: '2023-05-01',
    updated_by: '2',
    updated_by_name: '社員Ｂ',
    updated_by_kana: 'シャインB',
  }, {
    id: '3',
    element_name: '売上_振込手数料',
    deposit_type: '4',
    account_code_main_debit: 553,
    account_code_main_credit: 320,
    account_code_side_debit: 483,
    account_code_side_credit: 324,
    account_code_main_debit_advance: 320,
    account_code_main_credit_advance: 162,
    account_code_side_debit_advance: 324,
    account_code_side_credit_advance: 164,
    updated_at: '2023-06-03',
    updated_by: '3',
    updated_by_name: '社員Ｃ',
    updated_by_kana: 'シャインC',
  }, {
    id: '4',
    element_name: '売上_安全協力会費',
    deposit_type: '7',
    account_code_main_debit: 558,
    account_code_main_credit: 320,
    account_code_side_debit: null,
    account_code_side_credit: null,
    account_code_main_debit_advance: 320,
    account_code_main_credit_advance: 162,
    account_code_side_debit_advance: null,
    account_code_side_credit_advance: null,
    updated_at: '2023-06-03',
    updated_by: '3',
    updated_by_name: '社員Ｃ',
    updated_by_kana: 'シャインC',
  }]

  return {
    journalDepositItems,
  }
}
