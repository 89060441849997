import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstPlans from '@/const/transaction/plans'

const constTable = ConstPlans()

const baseListColumns: ListViewColumn[] = [{
  id: 'status',
  text: constTable.columnLabel.get('status'),
  displayField: 'status',
  sortField: 'status',
  width: '100px',
}, {
  id: 'id',
  text: constTable.columnLabel.get('id'),
  displayField: 'id',
  sortField: 'id_number',
}, {
  id: 'name',
  text: constTable.columnLabel.get('name'),
  displayField: 'name',
  sortField: 'name',
}, {
  id: 'note',
  text: constTable.columnLabel.get('note'),
  displayField: 'note',
  sortField: 'note',
}, {
  id: 'estimate_id',
  text: constTable.columnLabel.get('estimate_id'),
  displayField: 'estimate_id',
  sortField: 'estimate_id_number',
}, {
  id: 'contract_amount_budget',
  text: constTable.columnLabel.get('contract_amount_budget'),
  displayField: 'contract_amount_budget',
  sortField: 'contract_amount_budget',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'material_cost_budget',
  text: constTable.columnLabel.get('material_cost_budget'),
  displayField: 'material_cost_budget',
  sortField: 'material_cost_budget',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'outsourcing_cost_budget',
  text: constTable.columnLabel.get('outsourcing_cost_budget'),
  displayField: 'outsourcing_cost_budget',
  sortField: 'outsourcing_cost_budget',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'expense_cost_budget',
  text: constTable.columnLabel.get('expense_cost_budget'),
  displayField: 'expense_cost_budget',
  sortField: 'expense_cost_budget',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'others_cost_budget',
  text: constTable.columnLabel.get('others_cost_budget'),
  displayField: 'others_cost_budget',
  sortField: 'others_cost_budget',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'labor_cost_budget',
  text: constTable.columnLabel.get('labor_cost_budget'),
  displayField: 'labor_cost_budget',
  sortField: 'labor_cost_budget',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'marginal_profit_budget',
  text: constTable.columnLabel.get('marginal_profit_budget'),
  displayField: 'marginal_profit_budget',
  sortField: 'marginal_profit_budget',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'marginal_profit_ratio_budget',
  text: constTable.columnLabel.get('marginal_profit_ratio_budget'),
  displayField: 'marginal_profit_ratio_budget',
  sortField: 'marginal_profit_ratio_budget',
  displayConvert: 'decimalDelimiter',
  align: 'right',
}, {
  id: 'gross_profit_budget',
  text: constTable.columnLabel.get('gross_profit_budget'),
  displayField: 'gross_profit_budget',
  sortField: 'gross_profit_budget',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'productivity_budget',
  text: constTable.columnLabel.get('productivity_budget'),
  displayField: 'productivity_budget',
  sortField: 'productivity_budget',
  displayConvert: 'decimalDelimiter',
  align: 'right',
}, {
  id: 'updated_at',
  text: constTable.columnLabel.get('updated_at'),
  displayField: 'updated_at',
  sortField: 'updated_at',
}, {
  id: 'updated_by',
  text: constTable.columnLabel.get('updated_by'),
  displayField: 'updated_by_name',
  sortField: 'updated_by_kana',
}]

const standardListColumns = {
  plan: [
    'status',
    'id',
    'name',
    'note',
    'estimate_id',
    'contract_amount_budget',
    'material_cost_budget',
    'outsourcing_cost_budget',
    'labor_cost_budget',
    'expense_cost_budget',
    'others_cost_budget',
    'marginal_profit_budget',
    'marginal_profit_ratio_budget',
    'gross_profit_budget',
    'productivity_budget',
    'updated_at',
    'updated_by',
  ],
}

const listIcon: ListViewIcon = {
  caution: {
    name: 'triangle-exclamation',
    classes: [
      '--error',
    ]
  },
}
const listConverter: ListViewConverter = {
  status: {
    '0': {
      text: constTable.status.get('0') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(0,140,0)',
        color: 'rgb(255,255,255)',
      },
    },
    '10': {
      text: constTable.status.get('10') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(140,0,0)',
        color: 'rgb(255,255,255)',
      },
    },
    '20': {
      text: constTable.status.get('20') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(0,0,140)',
        color: 'rgb(255,255,255)',
      },
    },
  },
}

export default function deposit () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'id_number',
    order: {
      'id_number': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
