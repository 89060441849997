export const tableName = '予算'

export default function budgets () {
  const columnLabel = new Map([
    [ 'base', '拠点' ],
    [ 'division', '部門' ],
    [ 'budgetAmount', '予算［円］' ],
    [ 'completeAmount', '完成［円］' ],
    [ 'progressAmount', '仕掛［円］' ],
    [ 'prospectAmount', '見込［円］' ],
    [ 'achievementRate', '達成率［％］' ],
  ])

  return {
    columnLabel,
  }
}
