export interface DbJournalPrefix {
  id: string
  journal_items_type: string
  journal_items_type_number: number
  main: string | null
  side: string | null
  sale: string | null
  assets: string | null
  updated_at: string
  updated_by_name: string
  updated_by_kana: string
}

export default function journalPrefixies () {
  const journalPrefixies: Array<DbJournalPrefix> = [{
    id: '1',
    journal_items_type: '売上',
    journal_items_type_number: 1,
    main: '(工事)',
    side: '(兼業)',
    sale: null,
    assets: null,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '2',
    journal_items_type: '入金',
    journal_items_type_number: 2,
    main: '(工事)',
    side: '(兼業)',
    sale: null,
    assets: null,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '3',
    journal_items_type: '仕入',
    journal_items_type_number: 3,
    main: '',
    side: '(兼業)',
    sale: '(販)',
    assets: '(資)',
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }]

  return {
    journalPrefixies,
  }
}
