export const contractsTableName = '案件'

export default function contracts () {
  const itemLabel = new Map([
    [ 'contractInformation', '案件情報' ],

    [ 'processStatus', '工程ステータス' ],
    [ 'id', '案件番号' ],
    [ 'project', 'プロジェクト' ],
    [ 'projectId', 'プロジェクト番号' ],
    [ 'name', '案件名' ],
    [ 'kana', '案件名（カナ）' ],
    [ 'contractType', '案件区分' ],
    [ 'publicPrivateWorksType', '公共民間区分' ],
    [ 'contractorType', '請負区分' ],
    [ 'contractCategory', '案件種別' ],
    [ 'constructionType', '工事区分' ],
    [ 'division', '部門' ],
    [ 'base', '拠点' ],
    [ 'salesRep', '営業担当' ],
    [ 'constructionLocation', '現場住所' ],
    [ 'siteAgent', '現場代理人' ],
    [ 'client', '発注者' ],
    [ 'billingTarget', '請求先' ],
    [ 'contractPeriod', '契約工期' ],
    [ 'contractPeriodStartDate', '契約工期始期' ],
    [ 'contractPeriodEndDate', '契約工期終期' ],
    [ 'note', '備考' ],
    [ 'notCounted', '集計対象外' ],
    [ 'editLock', '編集ロック' ],
    [ 'lostOrderDate', '失注日' ],
    [ 'lostOrderReason', '失注理由' ],
    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],


    [ 'prospectInformation', '見込情報' ],

    [ 'ordersAccuracy', '受注確度' ],
    [ 'destinationTarget', '折衝先' ],
    [ 'predictedContractJobAmount', '概算請負金額［円］' ],
    [ 'estimatedMaterialCost', '見込材料費［円］' ],
    [ 'estimatedOutsourcingCost', '見込外注費［円］' ],
    [ 'estimatedExpenseCost', '見込経費［円］' ],
    [ 'estimatedOthersCost', '見込その他［円］' ],
    [ 'estimatedLaborCost', '見込労務費［円］' ],
    [ 'targetMarginalProfit', '予定限界利益［円］' ],
    [ 'targetMarginalProfitRatio', '予定限界利益率［％］' ],
    [ 'targetProductivity', '予定生産性' ],
    [ 'targetGrossProfit', '予定粗利益［円］' ],
    [ 'inquiryDate', '引合日' ],
    [ 'estimatedSalesDate', '見込売上日' ],
    [ 'beforeCost', '受注前原価［円］' ],
    [ 'inquiryDate', '引合日' ],
    [ 'lostOrderDate', '失注日' ],
    //[ 'lostReason', '失注理由' ],


    [ 'estimateInformation', '見積情報' ],

    [ 'estimateId', '見積番号' ],
    [ 'contractAmountBudget', '請負金額予算［円］' ],
    [ 'materialCostBudget', '材料費予算［円］' ],
    [ 'outsourcingCostBudget', '外注費予算［円］' ],
    [ 'expenseCostBudget', '経費予算［円］' ],
    [ 'othersCostBudget', 'その他予算［円］' ],
    [ 'laborCostBudget', '労務費予算［円］' ],
    [ 'marginalProfitBudget', '限界利益予算［円］' ],
    [ 'marginalProfitRatioBudget', '限界利益率予算［％］' ],
    [ 'productivityBudget', '生産性予算' ],
    [ 'grossProfitBudget', '粗利益予算［円］' ],
    [ 'estimateDate', '見積日' ],
    [ 'expectedSalesDate', '売上予定日' ],
    

    [ 'orderInformation', '受注情報' ],

    [ 'clientOrderNo', '得意先注文番号' ],
    [ 'constructionRep', '施工担当' ],
    [ 'constructionTarget', '施工先' ],
    [ 'contractJobAmount', '請負金額［円］' ],
    [ 'marginalProfit', '限界利益［円］' ],
    [ 'marginalProfitRatio', '限界利益率［％］' ],
    [ 'marginalProfitBudgetBalance', '限界利益予算残［円］' ],
    [ 'productivity', '生産性' ],
    [ 'grossProfit', '粗利益［円］' ],
    [ 'materialCost', '材料費［円］' ],
    [ 'materialCostBalance', '材料費残［円］' ],
    [ 'outsourcingCost', '外注費［円］' ],
    [ 'outsourcingCostBalance', '外注費残［円］' ],
    [ 'expenseCost', '経費［円］' ],
    [ 'expenseCostBalance', '経費残［円］' ],
    [ 'othersCost', 'その他［円］' ],
    [ 'othersCostBalance', 'その他残［円］' ],
    [ 'laborCost', '労務費［円］' ],
    [ 'laborCostBalance', '労務費残［円］' ],
    [ 'purchaseAmountBalance', '発注残金額［円］' ],
    [ 'billedAmount', '請求済み金額［円］' ],
    [ 'orderDate', '受注日' ],
    [ 'completeDate', '完成日' ],
    [ 'billDate', '請求日' ],
    [ 'salesDate', '売上日' ],
    [ 'autoCompleteDate', '自動完成請求予定日' ],


    //[ 'overview', '案件概要' ],
    //[ 'clientRep', '得意先担当者' ],

    //[ 'constructionDesigner', '工事設計者' ],
    //[ 'leadEngineer', '主任技術者' ],
    //[ 'supervisingEngineer', '監理技術者' ],

    //[ 'lastPurchaseDate', '最終仕入日' ],
    //[ 'lastInvoiceIssueDate', '最終請求書発行日' ],

/*
    [ 'variableCost', '原価' ],
    [ 'totalVariableCost', '合計［円］' ],
    [ 'budget', '予算［円］' ],
    [ 'budgetBalance', '予算残金［円］' ],
    [ 'salesAmount', '売上金額［円］' ],
    [ 'accountsReceivableAmount', '売掛金額［円］' ],
    [ 'unbilledAmount', '未請求額［円］' ],
    [ 'amountOfAccountsPayable', '買掛金額［円］' ],
    
*/
    //[ 'attributeType', '属性区分' ],
    //[ 'costInputType', '原価入力区分' ],
    //[ 'consumptionTaxCalcType', '消費税計算区分' ],
    //[ 'taxRateType', '税率区分' ],
    //[ 'billingType', '請負区分' ],
    //[ 'contractJobAmountExcludingTax', '請負税抜額' ],
    //[ 'contractJobConsumptionTaxAmount', '請負消費税額' ],
    //[ 'paymentTerms', '支払条件' ],

    //[ 'billId', '請求番号' ],

    //[ 'backlogStatus', 'データ種別' ],
    [ 'invoiceStatus', '請求ステータス' ],
    //[ 'paymentStatus', '入金ステータス' ],
    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],
  ])
  const searchLabel = new Map([
    [ 'contractInformation', '案件情報' ],
    [ 'prospectInformation', '見込情報' ],
    [ 'estimateInformation', '見積情報' ],
    [ 'orderInformation', '受注情報' ],
    [ 'id', '案件番号' ],
    [ 'name', '案件名' ],
    [ 'kana', '案件名（カナ）' ],
    [ 'project', 'プロジェクト' ],
    [ 'projectId', 'プロジェクト番号' ],
    [ 'overview', '案件概要' ],
    [ 'notCounted', '集計対象外' ],
    [ 'editLock', '編集ロック' ],

    [ 'contractType', '案件区分' ],
    [ 'contractorType', '請負区分' ],
    [ 'publicPrivateWorksType', '公共民間区分' ],
    [ 'contractCategory', '案件種別' ],
    [ 'constructionType', '工事区分' ],
    [ 'constructionLocation', '現場住所' ],
    [ 'destinationTarget', '折衝先' ],
    [ 'client', '発注者' ],
    [ 'billingTarget', '請求先' ],

    [ 'clientRep', '得意先担当者' ],
    [ 'salesRep', '営業担当' ],
    [ 'siteAgent', '現場代理人' ],

    [ 'constructionTarget', '施工先' ],
    [ 'constructionRep', '施工担当' ],
    //[ 'constructionDesigner', '工事設計者' ],
    //[ 'leadEngineer', '主任技術者' ],
    //[ 'supervisingEngineer', '監理技術者' ],
    [ 'base', '拠点' ],
    [ 'division', '部門' ],
    [ 'contractPeriod', '契約工期' ],
    [ 'contractPeriodStartDate', '契約工期始期' ],
    [ 'contractPeriodEndDate', '契約工期終期' ],
    
    [ 'startDate', '開始日' ],
    [ 'endDate', '終了日' ],
    [ 'ordersAccuracy', '受注確度' ],
    [ 'ordersDate', '受注日' ],
    //[ 'lastSuspensionDate', '最終中断日' ],
    //[ 'lastResumeDate', '最終再開日' ],
    [ 'completeDate', '完成日' ],
    [ 'lostOrderDate', '失注日' ],
    [ 'lostReason', '失注理由' ],
    [ 'lastEstimateDate', '最終見積日' ],
    [ 'lastSendOrderDate', '最終発注日' ],
    [ 'lastPurchaseDate', '最終仕入日' ],
    [ 'lastInvoiceIssueDate', '最終請求書発行日' ],

    [ 'billDate', '請求日' ],
    [ 'salesDate', '売上日' ],
    [ 'autoCompleteDate', '自動完成請求予定日' ],

    [ 'targetMarginalProfit', '予定限界利益' ],
    [ 'targetMarginalProfitRatio', '予定限界利益率' ],
    [ 'targetProductivity', '予定生産性' ],
    [ 'targetGrossProfit', '予定粗利益' ],

    [ 'marginalProfitRatio', '限界利益率' ],
    [ 'marginalProfit', '限界利益' ],
    
    [ 'marginalProfitBudgetBalance', '限界利益予算残' ],
    [ 'grossProfit', '粗利益' ],

    [ 'predictedContractJobAmount', '概算請負金額' ],
    [ 'contractJobAmount', '請負金額' ],
    [ 'beforeCost', '受注前原価' ],

    [ 'estimatedMaterialCost', '見込材料費' ],
    [ 'estimatedOutsourcingCost', '見込外注費' ],
    [ 'estimatedExpenseCost', '見込経費' ],
    [ 'estimatedOthersCost', '見込その他' ],
    [ 'estimatedLaborCost', '見込労務費' ],
    [ 'estimatedSalesDate', '見込売上日' ],

    [ 'contractAmountBudget', '請負金額予算' ],
    [ 'materialCostBudget', '材料費予算' ],
    [ 'outsourcingCostBudget', '外注費予算' ],
    [ 'expenseCostBudget', '経費予算' ],
    [ 'othersCostBudget', 'その他予算' ],
    [ 'laborCostBudget', '労務費予算' ],
    [ 'marginalProfitBudget', '限界利益予算' ],
    [ 'marginalProfitRatioBudget', '限界利益率予算' ],
    [ 'productivityBudget', '生産性予算' ],
    [ 'grossProfitBudget', '粗利益予算' ],
    [ 'expectedSalesDate', '売上予定日' ],

    [ 'variableCost', '原価' ],
    [ 'materialCost', '材料費' ],
    [ 'materialCostBalance', '材料費残' ],
    [ 'outsourcingCost', '外注費' ],
    [ 'outsourcingCostBalance', '外注費残' ],
    [ 'expenseCost', '経費' ],
    [ 'expenseCostBalance', '経費残' ],
    [ 'othersCost', 'その他' ],
    [ 'othersCostBalance', 'その他残' ],
    [ 'laborCost', '労務費' ],
    [ 'laborCostBalance', '労務費残' ],
    [ 'totalVariableCost', '合計' ],
    [ 'budget', '予算' ],
    [ 'budgetBalance', '予算残金' ],
    [ 'salesAmount', '売上金額' ],
    [ 'accountsReceivableAmount', '売掛金額' ],
    [ 'unbilledAmount', '未請求額' ],
    [ 'purchaseAmountBalance', '発注残金額' ],
    [ 'amountOfAccountsPayable', '買掛金額' ],
    [ 'productivity', '生産性' ],
/*
    [ 'materialBeforeCost', '材料費［円］' ],
    [ 'outsourcingBeforeCost', '外注費［円］' ],
    [ 'expenseBeforeCost', '経費［円］' ],
    [ 'laborBeforeCost', '労務費［円］' ],
    [ 'totalBeforeCost', '合計［円］' ],
    [ 'variableCost', '原価' ],
    [ 'materialCost', '材料費［円］' ],
    [ 'outsourcingCost', '外注費［円］' ],
    [ 'expenseCost', '経費［円］' ],
    [ 'laborCost', '労務費［円］' ],
    [ 'totalVariableCost', '合計［円］' ],
    [ 'budget', '予算［円］' ],
    [ 'budgetBalance', '予算残金［円］' ],
    [ 'salesAmount', '売上金額［円］' ],
    [ 'accountsReceivableAmount', '売掛金額［円］' ],
    [ 'unbilledAmount', '未請求額［円］' ],
    [ 'purchaseAmountBalance', '発注残金［円］' ],
    [ 'amountOfAccountsPayable', '買掛金額［円］' ],
*/
    //[ 'attributeType', '属性区分' ],
    //[ 'costInputType', '原価入力区分' ],
    //[ 'consumptionTaxCalcType', '消費税計算区分' ],
    //[ 'taxRateType', '税率区分' ],
    //[ 'billingType', '請負区分' ],
    //[ 'contractJobAmountExcludingTax', '請負税抜額' ],
    //[ 'contractJobConsumptionTaxAmount', '請負消費税額' ],
    //[ 'paymentTerms', '支払条件' ],
    //[ 'note', '備考' ],

    [ 'estimateId', '見積番号' ],
    [ 'billId', '請求番号' ],
    
    [ 'note', '備考' ],
    [ 'backlogStatus', 'データ種別' ],
    [ 'processStatus', '工程ステータス' ],
    [ 'invoiceStatus', '請求書ステータス' ],
    [ 'paymentStatus', '入金ステータス' ],
    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],
  ])
  const columnLabel = new Map([
    [ 'contractInformation', '案件情報' ],

    [ 'processStatus', '工程ST' ],
    [ 'invoiceStatus', '請求書ST' ],
    [ 'id', '案件番号' ],
    [ 'project', 'プロジェクト' ],
    [ 'projectId', 'PJ番号' ],
    [ 'name', '案件名' ],
    [ 'kana', '案件名（カナ）' ],
    [ 'contractType', '区分' ],
    [ 'publicPrivateWorksType', '公民' ],
    [ 'contractorType', '元下' ],
    [ 'contractCategory', '種別' ],
    [ 'constructionType', '工事区分' ],
    [ 'division', '部門' ],
    [ 'base', '拠点' ],
    [ 'salesRep', '営業担当' ],
    [ 'constructionLocation', '現場住所' ],
    [ 'siteAgent', '現場代理人' ],
    [ 'client', '発注者' ],
    [ 'billingTarget', '請求先' ],
    [ 'contractPeriod', '契約工期' ],
    [ 'contractPeriodStartDate', '契約工期始期' ],
    [ 'contractPeriodEndDate', '契約工期終期' ],
    [ 'note', '備考' ],
    [ 'notCounted', '対象外' ],
    [ 'editLock', 'ロック' ],
    [ 'lostOrderDate', '失注日' ],
    //[ 'lostOrderReason', '失注理由' ],
    //[ 'updatedAt', '最終更新日時' ],
    //[ 'updatedBy', '最終更新者' ],


    [ 'prospectInformation', '見込情報' ],

    [ 'ordersAccuracy', '受確' ],
    [ 'destinationTarget', '折衝先' ],
    [ 'predictedContractJobAmount', '概算請負金額［円］' ],
    [ 'estimatedMaterialCost', '見込材料費［円］' ],
    [ 'estimatedOutsourcingCost', '見込外注費［円］' ],
    [ 'estimatedExpenseCost', '見込経費［円］' ],
    [ 'estimatedOthersCost', '見込その他［円］' ],
    [ 'estimatedLaborCost', '見込労務費［円］' ],
    [ 'targetMarginalProfit', '予定限界利益［円］' ],
    [ 'targetMarginalProfitRatio', '予定限界利益率［％］' ],
    [ 'targetProductivity', '予定生産性' ],
    [ 'targetGrossProfit', '予定GP［円］' ],
    [ 'estimatedSalesDate', '見込売上日' ],
    [ 'beforeCost', '受注前原価［円］' ],
    [ 'inquiryDate', '引合日' ],


    [ 'estimateInformation', '見積情報' ],

    [ 'estimateId', '見積番号' ],
    [ 'contractAmountBudget', '請負金額予算［円］' ],
    [ 'materialCostBudget', '材料費予算［円］' ],
    [ 'outsourcingCostBudget', '外注費予算［円］' ],
    [ 'expenseCostBudget', '経費予算［円］' ],
    [ 'othersCostBudget', 'その他予算［円］' ],
    [ 'laborCostBudget', '労務費予算［円］' ],
    [ 'marginalProfitBudget', '限界利益予算［円］' ],
    [ 'marginalProfitRatioBudget', '限界利益率予算［％］' ],
    [ 'productivityBudget', '生産性予算' ],
    [ 'grossProfitBudget', 'GP予算［円］' ],
    [ 'estimateDate', '見積日' ],
    [ 'expectedSalesDate', '売上予定日' ],
    
    
    [ 'orderInformation', '受注情報' ],

    [ 'clientOrderNo', '得意先注文番号' ],
    [ 'constructionRep', '施工担当' ],
    [ 'constructionTarget', '施工先' ],
    [ 'contractJobAmount', '請負金額［円］' ],
    [ 'marginalProfit', '限界利益［円］' ],
    [ 'marginalProfitRatio', '限界利益率［％］' ],
    [ 'marginalProfitBudgetBalance', '限界利益予算残［円］' ],
    [ 'productivity', '生産性' ],
    [ 'grossProfit', 'GP［円］' ],
    [ 'materialCost', '材料費［円］' ],
    [ 'materialCostBalance', '材料費残［円］' ],
    [ 'outsourcingCost', '外注費［円］' ],
    [ 'outsourcingCostBalance', '外注費残［円］' ],
    [ 'expenseCost', '経費［円］' ],
    [ 'expenseCostBalance', '経費残［円］' ],
    [ 'othersCost', 'その他［円］' ],
    [ 'othersCostBalance', 'その他残［円］' ],
    [ 'laborCost', '労務費［円］' ],
    [ 'laborCostBalance', '労務費残［円］' ],
    [ 'purchaseAmountBalance', '発注残金額［円］' ],
    [ 'billedAmount', '請求済み金額［円］' ],
    [ 'orderDate', '受注日' ],
    [ 'completeDate', '完成日' ],
    [ 'billDate', '請求日' ],
    [ 'salesDate', '売上日' ],
    [ 'autoCompleteDate', '自動完成請求予定日' ],


    //[ 'overview', '概要' ],
    //[ 'clientRep', '得意先担当者' ],

    //[ 'constructionDesigner', '工事設計者' ],
    //[ 'leadEngineer', '主任技術者' ],
    //[ 'supervisingEngineer', '監理技術者' ],
    
    //[ 'lastEstimateDate', '最終見積日' ],
    //[ 'lastSendOrderDate', '最終発注日' ],
    //[ 'lastPurchaseDate', '最終仕入日' ],
    //[ 'lastInvoiceIssueDate', '最終請求書発行日' ],

    /*
    [ 'variableCost', '原価' ],
    [ 'totalVariableCost', '合計［円］' ],
    [ 'budget', '予算［円］' ],
    [ 'budgetBalance', '予算残金［円］' ],
    [ 'salesAmount', '売上金額［円］' ],
    [ 'accountsReceivableAmount', '売掛金額［円］' ],
    [ 'unbilledAmount', '未請求額［円］' ],
    [ 'amountOfAccountsPayable', '買掛金額［円］' ],
    [ 'productivity', '生産性' ],
    */
    //[ 'attributeType', '属性区分' ],
    //[ 'costInputType', '原価入力区分' ],
    //[ 'consumptionTaxCalcType', '消費税計算区分' ],
    //[ 'taxRateType', '税率区分' ],
    //[ 'billingType', '請負区分' ],
    //[ 'contractJobAmountExcludingTax', '請負税抜額' ],
    //[ 'contractJobConsumptionTaxAmount', '請負消費税額' ],
    //[ 'paymentTerms', '支払条件' ],

    //[ 'billId', '請求番号' ],
    
    //[ 'backlogStatus', 'データ種別' ],
    
  ])
  /*
  const attributeType = new Map([
    [ '1', '標準工事' ],
    [ '2', '原価集計外' ],
  ])
  */
  const contractorType = new Map([
    [ '1', '元請' ],
    [ '2', '下請' ],
  ])
  const contractorTypeShort = new Map([
    [ '1', '元' ],
    [ '2', '下' ],
  ])
  const contractType = new Map([
    [ '1', '工事' ],
    [ '2', '物販' ],
    [ '3', '障害' ],
    [ '4', '保守' ],
    [ '5', '役務' ],
  ])
  const contractTypeShort = new Map([
    [ '1', '工' ],
    [ '2', '物' ],
    [ '3', '障' ],
    [ '4', '保' ],
    [ '5', '役' ],
  ])
  const ordersAccuracy = new Map([
    [ 'u', '内示（100%）' ],
    [ 'a', 'A（80%）' ],
    [ 'b', 'B（60%）' ],
    [ 'c', 'C（40%）' ],
    [ 'd', 'D（20%）' ],
    [ 'e', 'E（5%）' ],
  ])
  const ordersAccuracyShort = new Map([
    [ 'u', '内' ],
    [ 'a', 'A' ],
    [ 'b', 'B' ],
    [ 'c', 'C' ],
    [ 'd', 'D' ],
    [ 'e', 'E' ],
  ])
  /*
  const taxRateType = new Map([
    [ '0', '0%' ],
    [ '1', '10%' ],
  ])
  */
  const publicPrivateWorksType = new Map([
    [ '1', '公共' ],
    [ '2', '民間' ],
  ])
  const publicPrivateWorksTypeShort = new Map([
    [ '1', '公' ],
    [ '2', '民' ],
  ])
  /*
  const billingType = new Map([
    [ '1', '下請' ],
    [ '2', '元請' ],
  ])
  const costInputType = new Map([
    [ '1', 'する' ],
    [ '2', 'しない' ],
  ])
  const consumptionTaxCalcType = new Map([
    [ '1', '見積単位' ],
    [ '2', '明細単位' ],
  ])
  */
  const processStatus = new Map([
    [ '0', '引合' ],
    [ '1', '見積' ],
    [ '2', '受注' ],
    //[ '3', '中断中' ],
    [ '3', '完成' ],
    [ '10', '失注' ],
  ])
  const invoiceStatus = new Map([
    [ '0', '未作成' ],
    [ '1', '一部作成済み' ],
    [ '2', '作成済み' ],
  ])
  /*
  const paymentStatus = new Map([
    [ '0', '請求前' ],
    [ '1', '未入金' ],
    [ '2', '一部入金' ],
    [ '3', '入金済み' ],
  ])
  */

  return {
    itemLabel,
    searchLabel,
    columnLabel,
    //attributeType,
    contractorType,
    contractorTypeShort,
    contractType,
    contractTypeShort,
    publicPrivateWorksType,
    publicPrivateWorksTypeShort,
    ordersAccuracy,
    ordersAccuracyShort,
    processStatus,
    invoiceStatus,
    //paymentStatus,
    /*
    taxRateType,
    publicPrivateWorksType,
    billingType,
    costInputType,
    consumptionTaxCalcType,
    */
  }
}
