import {
  Rounding,
} from '@/graphql'

export default function amount () {
  const decimalDelimiter = (val: number | null) => {
    if (val !== 0 && !val) return null
    return val.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }
  const numericalDelimiter = (val: number | bigint | null) => {
    if (val !== 0 && val !== 0n && !val) return null
    return val.toLocaleString()
  }
  const yenNumericalDelimiter = (val: number | bigint | null) => {
    if (val !== 0 && val !== 0n && !val) return null
    return `￥${val.toLocaleString()}`
  }
  const roundingAmount = (
    quantity: number,
    unitPrice: number | null,
    rounding: Rounding = Rounding.RoundDown
  ) => {
    if (unitPrice === null || isNaN(unitPrice)) return 0n
    const val: bigint = BigInt(quantity * 100) * BigInt(unitPrice * 100)
    switch (rounding) {
      case Rounding.Rounding:
        return (val + 5000n) / 10000n
      case Rounding.RoundUp:
        return (val + 9000n) / 10000n
      default:
        return val / 10000n
    }
  }
  const roundingTax = (
    value: bigint,
    tax: number,
    rounding: Rounding = Rounding.RoundDown
  ) => {
    if (isNaN(tax)) return 0n
    const val: bigint = value * BigInt(tax)
    switch (rounding) {
      case Rounding.Rounding:
        return (val + 50n) / 100n
      case Rounding.RoundUp:
        return (val + 90n) / 100n
      default:
        return val / 100n
    }
  }

  return {
    decimalDelimiter,
    numericalDelimiter,
    yenNumericalDelimiter,
    roundingAmount,
    roundingTax,
  }
}
