export const tableName = '見積'

export default function estimates () {
  const itemLabel = new Map([
    [ 'estimateInformation', '見積情報' ],

    [ 'status', 'ステータス' ],
    //[ 'result', '合否結果' ],
    [ 'id', '見積番号' ],
    [ 'revision', '訂正履歴' ],
    [ 'revisionNo', '訂番' ],
    [ 'revisionDescription', '訂正内容' ],
    [ 'memo', 'メモ' ],
    [ 'estimateDate', '見積日' ],
    [ 'issueDate', '発行日時' ], 
    [ 'issuer', '発行者' ], //id, name, kana
    [ 'sentDate', '送付日時' ], 
    [ 'sender', '送付者' ], //id, name, kana
    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],


    [ 'contractInformation', '案件情報' ],

    [ 'contract', '案件' ],
    [ 'contractId', '案件番号' ],
    [ 'contractName', '案件名' ],
    [ 'contractPeriod', '契約工期' ],
    [ 'salesRepresentative', '営業担当者' ],
    [ 'siteAgent', '現場代理人' ],
    [ 'client', '発注者' ], //id, name, kana
    [ 'officialCompanyName', '正式社名' ],
    [ 'officialBranchName', '正式支店名' ],


    [ 'constractorInformation', '自社情報' ],

    [ 'companyName', '社名' ],
    [ 'base', '拠点' ], //id, name, kana
    [ 'basePostcode', '拠点郵便番号' ],
    [ 'baseAddress', '拠点住所' ],
    [ 'baseTel', '拠点TEL' ],
    [ 'baseFax', '拠点FAX' ],


    [ 'transactionInformation', '取引情報' ],

    [ 'deliveryDate', '受渡期日' ],
    [ 'placeOfDelivery', '受渡場所' ],
    [ 'transactionSystem', '取引方法' ],
    [ 'dateOfExpiry', '有効期限' ],
    [ 'amountIncludingTax', '税込金額［円］' ],
    [ 'amountExcludingTax', '税抜金額［円］' ],
    [ 'consumptionTaxAmount', '消費税額［円］' ],

    [ 'details', '明細' ],
  ])
  const searchLabel = new Map([
    //[ 'status', 'ステータス' ],
    //[ 'result', '合否結果' ],
    [ 'id', '見積番号' ],
    [ 'revisionNo', '訂番' ],
    [ 'revisionDescription', '訂正内容' ],
    [ 'client', '発注者' ], //id, name, kana
    [ 'memo', 'メモ' ],
    [ 'estimateDate', '見積日' ],
    [ 'issueDate', '発行日時' ], 
    [ 'issuer', '発行者' ], //id, name, kana
    [ 'sentDate', '送付日時' ], 
    [ 'sender', '送付者' ], //id, name, kana
    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],
    [ 'base', '拠点' ], //id, name, kana
    [ 'contract', '案件' ],
    [ 'contractId', '案件番号' ],
    [ 'contractName', '案件名' ],
    [ 'deliveryDate', '受渡期日' ],
    [ 'placeOfDelivery', '受渡場所' ],
    [ 'transactionSystem', '取引方法' ],
    [ 'dateOfExpiry', '有効期限' ],
    [ 'amountIncludingTax', '税込金額' ],
    [ 'amountExcludingTax', '税抜金額' ],
    [ 'consumptionTaxAmount', '消費税額' ],
  ])
  const columnLabel = new Map([
    [ 'estimateInformation', '見積情報' ],

    [ 'status', 'ステータス' ],
    //[ 'result', '合否結果' ],
    [ 'id', '見積番号' ],
    [ 'revision', '訂正履歴' ],
    [ 'revisionNo', '訂番' ],
    [ 'revisionDescription', '訂正内容' ],
    [ 'memo', 'メモ' ],
    [ 'estimateDate', '見積日' ],
    [ 'issueDate', '発行日時' ], 
    [ 'issuer', '発行者' ], //id, name, kana
    [ 'sentDate', '送付日時' ], 
    [ 'sender', '送付者' ], //id, name, kana
    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],


    [ 'contractInformation', '案件情報' ],

    [ 'contract', '案件' ],
    [ 'contractId', '案件番号' ],
    [ 'contractName', '案件名' ],
    [ 'contractPeriod', '契約工期' ],
    [ 'salesRepresentative', '営業担当者' ],
    [ 'siteAgent', '現場代理人' ],
    [ 'client', '発注者' ], //id, name, kana
    [ 'officialCompanyName', '正式社名' ],
    [ 'officialBranchName', '正式支店名' ],


    [ 'constractorInformation', '自社情報' ],

    [ 'companyName', '自社名' ],
    [ 'base', '拠点' ], //id, name, kana
    [ 'basePostcode', '拠点郵便番号' ],
    [ 'baseAddress', '拠点住所' ],
    [ 'baseTel', '拠点TEL' ],
    [ 'baseFax', '拠点FAX' ],


    [ 'transactionInformation', '取引情報' ],

    [ 'deliveryDate', '受渡期日' ],
    [ 'placeOfDelivery', '受渡場所' ],
    [ 'transactionSystem', '取引方法' ],
    [ 'dateOfExpiry', '有効期限' ],
    [ 'amountIncludingTax', '税込金額［円］' ],
    [ 'amountExcludingTax', '税抜金額［円］' ],
    [ 'consumptionTaxAmount', '消費税額［円］' ],


    [ 'details', '明細' ],
  ])
  /*
  const result = new Map([
    [ 'WAITING', '受注待ち' ],
    [ 'ADOPTION', '採用' ],
    [ 'REJECTION', '不採用' ],
    [ 'LOST', '失注' ],
  ])
  */
  const status = new Map([
    /*
    [ '0', 'ドラフト' ],
    [ '1', '発行済み' ],
    [ '2', '送付済み' ],
    [ '10', '受注' ],
    [ '11', '不採用' ],
    [ '20', '見積取消' ],
    [ '30', '失注' ],
    */
    [ '0', 'ドラフト' ],
    [ '10', '見積書生成済み' ],
    [ '11', '承認中' ],
    [ '12', '承認差戻' ],
    [ '13', '承認否決' ],
    [ '14', '承認可決' ],
    [ '20', '受注' ],
    [ '21', '不成立' ],
    [ '22', '失注' ],
  ])

  return {
    itemLabel,
    columnLabel,
    searchLabel,
    //result,
    status,
  }
}
