import {
  reactive,
} from 'vue'
import {
  ListBoxItem,
} from '@/const/component/listBox'
import {
  SearchFilterItem,
  SearchFilterValue,
} from '@/const/component/searchFilterList'

const searchItem: {
  menuItems: ListBoxItem[]
  filterItems: SearchFilterItem[]
} = {
  menuItems: [{
    id: 'editedMyself',
    group: 'myself',
    text: '自分で編集したもの',
  }, {
    id: 'salesRepresentative',
    group: 'myself',
    text: '自分が営業担当のもの',
  }, {
    id: 'siteAgent',
    group: 'myself',
    text: '自分が現場代理人のもの',
  }, {
    id: 'ownBase',
    group: 'myself',
    text: '自身の拠点が担当のもの',
  }],
  filterItems: [{
    id: 'id',
    label: '案件番号',
    type: 'bigint',
  }, {
    id: 'estimate_id',
    label: '見積番号',
    type: 'bigint',
  }, {
    id: 'receive_order_type',
    label: '案件区分',
    type: 'check',
    items: [{
      id: 1,
      label: '工事',
    }, {
      id: 2,
      label: '物販',
    }, {
      id: 3,
      label: '障害',
    }, {
      id: 4,
      label: '保守',
    }, {
      id: 5,
      label: '役務',
    }],
  }, {
    id: 'public_private_works_type',
    label: '公共民間区分',
    type: 'check',
    items: [{
      id: 1,
      label: '公共',
    }, {
      id: 2,
      label: '民間',
    }],
  }, {
    id: 'receive_orders_accuracy',
    label: '受注確度',
    type: 'check',
    items: [{
      id: 'a',
      label: 'A（80%以上）',
    }, {
      id: 'b',
      label: 'B（50%以上80%未満）',
    }, {
      id: 'c',
      label: 'C（50%未満）',
    }],
  }, {
    id: 'last_estimate_date',
    label: '最終見積日',
    type: 'date',
  }, {
    id: 'last_send_order_date',
    label: '最終発注日',
    type: 'date',
  }, {
    id: 'last_purchase_date',
    label: '最終仕入日',
    type: 'date',
  }, {
    id: 'last_invoice_date',
    label: '最終請求日',
    type: 'date',
  }, {
    id: 'predicted_contract_job_Amount',
    label: '概算請負金額［円］',
    type: 'bigint',
  }, {
    id: 'contract_job_amount',
    label: '請負金額［円］',
    type: 'bigint',
  }, {
    id: 'target_marginal_profit_ratio',
    label: '目標限界利益率［％］',
    type: 'bigint',
  }, {
    id: 'target_marginal_profit',
    label: '目標限界利益［円］',
    type: 'bigint',
  }, {
    id: 'marginal_profit_ratio',
    label: '限界利益率［％］',
    type: 'bigint',
  }, {
    id: 'marginal_profit',
    label: '限界利益［円］',
    type: 'bigint',
  }, {
    id: 'variable_cost',
    label: '変動費［円］',
    type: 'bigint',
  }, {
    id: 'budget',
    label: '予算［円］',
    type: 'bigint',
  }, {
    id: 'budget_balance',
    label: '予算残金［円］',
    type: 'bigint',
  }, {
    id: 'sales_amount',
    label: '売上金額［円］',
    type: 'bigint',
  }, {
    id: 'accounts_receivable_amount',
    label: '売掛金額［円］',
    type: 'bigint',
  }, {
    id: 'unbilled_amount',
    label: '未請求額［円］',
    type: 'bigint',
  }, {
    id: 'send_order_balance',
    label: '発注残金［円］',
    type: 'bigint',
  }, {
    id: 'amount_of_accounts_payable',
    label: '買掛金額［円］',
    type: 'bigint',
  }, {
    id: 'last_purchase_date',
    label: '最終更新日',
    type: 'date',
  }]
}

export default function construction () {
  const searchValue = reactive<{
    searchText: string | null
    menu: { [ key: string ]: string | null }
    filter: SearchFilterValue
  }>({
    searchText: null,
    menu: {
      myself: 'editedMyself',
    },
    filter: {
      id: {
        type: 'off',
        single: null,
        range: [ null, null ],
      },
      receive_order_type: [
        false,
        false,
        false,
        false,
        false,
      ],
      public_private_works_type: [
        false,
        false,
      ],
      receive_orders_accuracy: [
        false,
        false,
        false,
      ],
      receive_order_amount: {
        type: 'off',
        single: null,
        range: [ null, null ],
      },
      status: [
        false,
        false,
        false,
        false,
        false,
      ],
    },
  })

  return {
    searchItem,
    searchValue,
  }
}
