export interface DbPurchaseDelivery {
  id: string
  id_number: number

  delivery_date: string

  supplier_id: string
  supplier_name: string
  supplier_kana: string
  delivery_slip_number: string | null
  updated_at: string
  updated_by_name: string
  updated_by_kana: string
  
  purchase_id: string
  purchase_id_number: number

  base_id: string | null
  base_name: string | null

  orderer_id: string | null
  orderer_name: string | null
  orderer_kana: string | null

  apportionment_type: string | null
  apportionment_id: string | null
  apportionment_id_number: number | null
  apportionment_name: string | null
  apportionment_kana: string | null

  construction_period: string | null
  
  sales_representative_id: string | null
  sales_representative_name: string | null
  sales_representative_kana: string | null
  
  site_agent_id: string | null
  site_agent_name: string | null
  site_agent_kana: string | null

  closing_date: string | null
  closed: boolean
  payment_date: string | null

  pre_order_cost: boolean

  amount_including_tax: bigint
  amount_excluding_tax: bigint
  consumption_tax_amount: bigint
}

export default function purchaseDeliveries () {
  const purchaseDeliveries: Array<DbPurchaseDelivery> = [{
    id: '1',
    id_number: 1,
    delivery_date: '2022-06-30',
    supplier_id: '12',
    supplier_name: '〇〇商事',
    supplier_kana: 'マルマルショウジ',
    delivery_slip_number: '1234-5678',
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
    purchase_id: '1',
    purchase_id_number: 1,
    base_id: '1',
    base_name: '郡山本社',
    orderer_id: '2221',
    orderer_name: '▼▼▼ △△△',
    orderer_kana: 'サンカクサンカクサンカク サンカクサンカクサンカク',
    apportionment_type: 'プロジェクト',
    apportionment_id: 'P38',
    apportionment_id_number: 38,
    apportionment_name: 'Pプロジェクト1011',
    apportionment_kana: 'プロジェクト1011',
    construction_period: '2000-01-01 ～ 2020-02-20',
    sales_representative_id: '2222',
    sales_representative_name: '営業 太郎',
    sales_representative_kana: 'エイギョウ タロウ',
    site_agent_id: '3333',
    site_agent_name: '現場 次郎',
    site_agent_kana: 'ゲンバ ジロウ',
    closing_date: '2022-02-02',
    closed: true,
    payment_date: '2022-03-01',
    pre_order_cost: false,
    amount_including_tax: 125422n,
    amount_excluding_tax: 114020n,
    consumption_tax_amount: 11402n,
  },{
    id: '2',
    id_number: 2,
    delivery_date: '2022-07-02',
    supplier_id: '1234',
    supplier_name: '〇☓商事',
    supplier_kana: 'マルバツショウジ',
    delivery_slip_number: '1234456-01',
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
    purchase_id: '4',
    purchase_id_number: 4,
    base_id: '1',
    base_name: '郡山本社',
    orderer_id: '2221',
    orderer_name: '▼▼▼ △△△',
    orderer_kana: 'サンカクサンカクサンカク サンカクサンカクサンカク',
    apportionment_type: 'プロジェクト',
    apportionment_id: 'P38',
    apportionment_id_number: 38,
    apportionment_name: 'Pプロジェクト1011',
    apportionment_kana: 'プロジェクト1011',
    construction_period: '2000-01-01 ～ 2020-02-20',
    sales_representative_id: '2222',
    sales_representative_name: '営業 太郎',
    sales_representative_kana: 'エイギョウ タロウ',
    site_agent_id: '3333',
    site_agent_name: '現場 次郎',
    site_agent_kana: 'ゲンバ ジロウ',
    closing_date: '2022-02-02',
    closed: false,
    payment_date: '2022-03-01',
    pre_order_cost: true,
    amount_including_tax: 21345678n,
    amount_excluding_tax: 20000000n,
    consumption_tax_amount: 1345678n,
  }]

  return {
    purchaseDeliveries,
  }
}
