export const tableName = '買掛残'

export default function paymentBalances () {
  const columnLabel = new Map([
    [ 'yearMonth', '集計月' ],
    [ 'supplierCode', '仕入先コード' ], //id, name, kana
    [ 'supplier', '仕入先' ], //id, name, kana
    
    [ 'previousMonthBalance', '前月残金［円］' ],
    [ 'amountIncurred', '当月発生金額［円］' ],
    [ 'paymentAmount', '当月支払額［円］' ],
    [ 'currentMonthBalance', '当月残金［円］' ],
  ])

  return {
    columnLabel,
  }
}
