export const tableName = '稼動表'

export default function attendances () {
  const columnLabel = new Map([
    [ 'id', '現金仕入番号' ],
    [ 'costType', '原価区分' ],
    [ 'expenseType', '経費種別' ],
  ])

  return {
    columnLabel,
  }
}