export interface DbBudget {
  id: string
  base_name: string
  base_display_no: number
  division_name: string
  division_display_no: number
  budget_amount: bigint
  complete_amount: bigint
  progress_amount: bigint
  prospect_amount: bigint
  achievement_rate: number
}

export default function budgets () {
  const budgets: Array<DbBudget> = [{
    id: '1',
    base_name: '本社',
    base_display_no: 1,
    division_name: 'システム',
    division_display_no: 1,
    budget_amount: 1000000000n,
    complete_amount: 600000000n,
    progress_amount: 20000000n,
    prospect_amount: 10000000n,
    achievement_rate: 90.0,
  }, {
    id: '2',
    base_name: '仙台',
    base_display_no: 2,
    division_name: 'システム',
    division_display_no: 1,
    budget_amount: 3000000000n,
    complete_amount: 1200000000n,
    progress_amount: 150000000n,
    prospect_amount: 600000000n,
    achievement_rate: 110.0,
  }, {
    id: '3',
    base_name: '仙台',
    base_display_no: 2,
    division_name: '営業',
    division_display_no: 2,
    budget_amount: 5000000000n,
    complete_amount: 4500000000n,
    progress_amount: 400000000n,
    prospect_amount: 300000000n,
    achievement_rate: 104.0,
  }]

  return {
    budgets,
  }
}
