export const tableName = '承認ルート'

export default function approvalRoutes () {
  const itemLabel = new Map([
    [ 'id', 'ID' ],
    [ 'name', '承認ルート名' ],
    [ 'document_type', '書類種別' ], 
    [ 'approver1', '第１承認者' ], 
    [ 'approver2', '第２承認者' ], 
    [ 'approver3', '第３承認者' ], 
    [ 'approver4', '第４承認者' ], 
    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])
  const searchLabel = new Map([
  ])
  const columnLabel = new Map([
    [ 'id', 'ID' ],
    [ 'name', 'ルート名' ],
    [ 'document_type', '書類種別' ], 
    [ 'approver1', '第１承認者' ], 
    [ 'approver2', '第２承認者' ], 
    [ 'approver3', '第３承認者' ], 
    [ 'approver4', '第４承認者' ], 
    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])

  const documentType = new Map([
    [ '1', '実行予算計画書' ],
    [ '2', '見積書' ],
    [ '3', '施工報告書' ],
  ])

  return {
    itemLabel,
    searchLabel,
    columnLabel,
    documentType,
  }
}
