import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstPurchaseDeliveryDetails from '@/const/transaction/purchaseDeliveryDetails'

const constPurchaseDeliveryDetails = ConstPurchaseDeliveryDetails()

const baseListColumns: ListViewColumn[] = [{
  id: 'details_line_no',
  text: constPurchaseDeliveryDetails.columnLabel.get('detailsLineNo'),
  displayField: 'details_line_no',
  //sortField: 'details_line_no',
  align: 'right',
}, {
  id: 'model_number',
  text: constPurchaseDeliveryDetails.columnLabel.get('modelNumber'),
  displayField: 'model_number',
  //sortField: 'model_number',
}, {
  id: 'description',
  text: constPurchaseDeliveryDetails.columnLabel.get('description'),
  displayField: 'description',
  //sortField: 'description',
}, {
  id: 'expense_type',
  text: constPurchaseDeliveryDetails.columnLabel.get('expenseType'),
  displayField: 'expense_type',
  //sortField: 'expense_type',
  align: 'center',
  width: '100px',
}, {
  id: 'expense_account_type',
  text: constPurchaseDeliveryDetails.columnLabel.get('expenseAccountType'),
  displayField: 'expense_account_type',
  //sortField: 'expense_account_type',
  align: 'center',
  width: '100px',
}, {
  id: 'not_manage_backlog',
  text: constPurchaseDeliveryDetails.columnLabel.get('notManageBacklog'),
  displayField: 'not_manage_backlog',
  //sortField: 'not_manage_backlog',
  displayConvert: 'check',
  align: 'center',
  width: '100px',
}, {
  id: 'quantity',
  text: constPurchaseDeliveryDetails.columnLabel.get('quantity'),
  displayField: 'quantity',
  //sortField: 'quantity',
  displayConvert: 'decimalDelimiter',
  align: 'right',
}, {
  id: 'unit',
  text: constPurchaseDeliveryDetails.columnLabel.get('unit'),
  displayField: 'unit',
  sortField: 'unit',
}, {
  id: 'unit_price',
  text: constPurchaseDeliveryDetails.columnLabel.get('unitPrice'),
  displayField: 'unit_price',
  //sortField: 'unit_price',
  displayConvert: 'decimalDelimiter',
  align: 'right',
}, {
  id: 'amount_including_tax',
  text: constPurchaseDeliveryDetails.columnLabel.get('amountIncludingTax'),
  displayField: 'amount_including_tax',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'amount_excluding_tax',
  text: constPurchaseDeliveryDetails.columnLabel.get('amountExcludingTax'),
  displayField: 'amount_excluding_tax',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'consumption_tax_amount',
  text: constPurchaseDeliveryDetails.columnLabel.get('consumptionTaxAmount'),
  displayField: 'consumption_tax_amount',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'consumption_tax_type',
  text: constPurchaseDeliveryDetails.columnLabel.get('consumptionTaxType'),
  displayField: 'consumption_tax_type',
}, {
  id: 'consumption_tax_rate',
  text: constPurchaseDeliveryDetails.columnLabel.get('consumptionTaxRate'),
  displayField: 'consumption_tax_rate',
  sortField: 'consumption_tax_rate',
}, {
  id: 'memo',
  text: constPurchaseDeliveryDetails.columnLabel.get('memo'),
  displayField: 'memo',
  sortField: 'memo',
}]

const standardListColumns = {
  delivery: [
    'details_line_no',
    'model_number',
    'description',
    'expense_type',
    'expense_account_type',
    //'not_manage_backlog',
    'quantity',
    'unit',
    'unit_price',
    //'consumption_tax_type',
    'consumption_tax_rate',
    'consumption_tax_amount',
    'amount_including_tax',
    'amount_excluding_tax',
    'memo',
  ],
  general: [
    'details_line_no',
    'model_number',
    'description',
    'expense_type',
    'expense_account_type',
    //'not_manage_backlog',
    'quantity',
    'unit',
    'unit_price',
    //'consumption_tax_type',
    'consumption_tax_rate',
    'consumption_tax_amount',
    'amount_including_tax',
    'amount_excluding_tax',
    'memo',
  ],
  information: [
    'details_line_no',
    'model_number',
    'description',
    'expense_type',
    'expense_account_type',
    //'not_manage_backlog',
    'quantity',
    'unit',
    'unit_price',
    //'consumption_tax_type',
    'consumption_tax_rate',
    'consumption_tax_amount',
    'amount_including_tax',
    'amount_excluding_tax',
    'memo',
  ],
}

const listConverter: ListViewConverter = {
  expense_type: {
    1: {
      text: constPurchaseDeliveryDetails.expenseType.get('MATERIAL') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(0,140,0)',
        color: 'rgb(255,255,255)',
      },
    },
    2: {
      text: constPurchaseDeliveryDetails.expenseType.get('OUTSOURCING') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(180,140,0)',
        color: 'rgb(255,255,255)',
      },
    },
    3: {
      text: constPurchaseDeliveryDetails.expenseType.get('EXPENSES') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(200,100,100)',
        color: 'rgb(255,255,255)',
      },
    },
    4: {
      text: constPurchaseDeliveryDetails.expenseType.get('LABOR') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(0,40,160)',
        color: 'rgb(255,255,255)',
      },
    },
  },
  expense_account_type: {
    1: {
      text: '仕入',
      type: 'ellipse',
      style: {
        background: 'rgb(0,0,220)',
        color: 'rgb(255,255,255)',
      },
    },
    2: {
      text: '租税公課',
      type: 'ellipse',
      style: {
        background: 'rgb(0,100,200)',
        color: 'rgb(255,255,255)',
      },
    },
    3: {
      text: '水道光熱費',
      type: 'ellipse',
      style: {
        background: 'rgb(0,100,100)',
        color: 'rgb(255,255,255)',
      },
    },
    4: {
      text: '通信費',
      type: 'ellipse',
      style: {
        background: 'rgb(0,200,150)',
        color: 'rgb(255,255,255)',
      },
    },
    5: {
      text: '旅費交通費',
      type: 'ellipse',
      style: {
        background: 'rgb(0,140,0)',
        color: 'rgb(255,255,255)',
      },
    },
    6: {
      text: '広告宣伝費',
      type: 'ellipse',
      style: {
        background: 'rgb(120,180,0)',
        color: 'rgb(255,255,255)',
      },
    },
    7: {
      text: '接待交際費',
      type: 'ellipse',
      style: {
        background: 'rgb(160,160,0)',
        color: 'rgb(255,255,255)',
      },
    },
    8: {
      text: '消耗品費',
      type: 'ellipse',
      style: {
        background: 'rgb(200,120,0)',
        color: 'rgb(255,255,255)',
      },
    },
    9: {
      text: '給与賃金',
      type: 'ellipse',
      style: {
        background: 'rgb(200,0,0)',
        color: 'rgb(255,255,255)',
      },
    },
    10: {
      text: '福利厚生費',
      type: 'ellipse',
      style: {
        background: 'rgb(220,100,100)',
        color: 'rgb(255,255,255)',
      },
    },
    11: {
      text: '地代家賃',
      type: 'ellipse',
      style: {
        background: 'rgb(140,0,200)',
        color: 'rgb(255,255,255)',
      },
    },
    12: {
      text: '減価償却費',
      type: 'ellipse',
      style: {
        background: 'rgb(100,0,100)',
        color: 'rgb(255,255,255)',
      },
    },
    13: {
      text: '雑費',
      type: 'ellipse',
      style: {
        background: 'rgb(0,0,100)',
        color: 'rgb(255,255,255)',
      },
    },
  },
  status: {
    0: {
      text: constPurchaseDeliveryDetails.status.get('0') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(200,0,0)',
        color: 'rgb(255,255,255)',
      },
    },
    10: {
      text: constPurchaseDeliveryDetails.status.get('10') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(200,150,0)',
        color: 'rgb(255,255,255)',
      },
    },
    20: {
      text: constPurchaseDeliveryDetails.status.get('20') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(0,100,0)',
        color: 'rgb(255,255,255)',
      },
    },
  },
}

export default function purchaseAccountDetail () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'details_line_no',
    order: {
      'details_line_no': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }
  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
