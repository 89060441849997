export const tableName = '仕入内訳'

export default function journalPurchaseItems () {
  const itemLabel = new Map([
    [ 'expense_type', '経費種別' ],
    [ 'element_name', '要素内訳名' ],
    
    [ 'account_code_main', '工事' ],
    [ 'account_code_main_debit', '（借方）勘定科目コード' ],
    [ 'account_code_main_credit', '（貸方）勘定科目コード' ],
    [ 'account_code_side', '兼業' ],
    [ 'account_code_side_debit', '（借方）勘定科目コード' ],
    [ 'account_code_side_credit', '（貸方）勘定科目コード' ],
    [ 'account_code_sale', '販売' ],
    [ 'account_code_sale_debit', '（借方）勘定科目コード' ],
    [ 'account_code_sale_credit', '（貸方）勘定科目コード' ],
    [ 'account_code_assets', '資産' ],
    [ 'account_code_assets_debit', '（借方）勘定科目コード' ],
    [ 'account_code_assets_credit', '（貸方）勘定科目コード' ],

    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],
  ])
  const columnLabel = new Map([
    [ 'expense_type', '経費種別' ],
    [ 'element_name', '要素内訳名' ],
    
    [ 'account_code', '勘定科目コード' ],
    [ 'account_code_main', '工事' ],
    [ 'account_code_main_debit', '借方' ],
    [ 'account_code_main_credit', '貸方' ],
    [ 'account_code_side', '兼業' ],
    [ 'account_code_side_debit', '借方' ],
    [ 'account_code_side_credit', '貸方' ],
    [ 'account_code_sale', '販売' ],
    [ 'account_code_sale_debit', '借方' ],
    [ 'account_code_sale_credit', '貸方' ],
    [ 'account_code_assets', '資産' ],
    [ 'account_code_assets_debit', '借方' ],
    [ 'account_code_assets_credit', '貸方' ],

    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],
  ])

  return {
    itemLabel,
    columnLabel,
  }
}
