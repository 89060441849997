export interface DbProject {
  id: string
  id_number: number
  name: string
  kana: string
  contract_job_amount: bigint
  target_marginal_profit: bigint
  marginal_profit: bigint
  before_cost: bigint
  variable_cost: bigint
  process_status: number
  bill_status: number
  updated_at: string
  updated_by_name: string
  updated_by_kana: string
  icon?: string
}

export default function projects () {
  const projects: DbProject[] = [{
    id: 'P1011',
    id_number: 1011,
    name: 'Ａプロジェクト',
    kana: 'Ａプロジェクト',
    contract_job_amount: 10000000n,
    target_marginal_profit: 5000000n,
    marginal_profit: 8750000n,
    before_cost: 0n,
    variable_cost: 1250000n,
    process_status: 1,
    bill_status: 0,
    updated_at: '2022-11-02',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: 'P1012',
    id_number: 1012,
    name: 'Ｂプロジェクト',
    kana: 'Ｂプロジェクト',
    contract_job_amount: 30000000n,
    target_marginal_profit: 15000000n,
    marginal_profit: 13250000n,
    before_cost: 1000000n,
    variable_cost: 16750000n,
    process_status: 1,
    bill_status: 0,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
    icon: 'caution',
  }, {
    id: 'P3033',
    id_number: 3033,
    name: 'Ｃプロジェクト',
    kana: 'Ｃプロジェクト',
    contract_job_amount: 500000n,
    target_marginal_profit: 250000n,
    marginal_profit: 300000n,
    before_cost: 50000n,
    variable_cost: 200000n,
    process_status: 1,
    bill_status: 0,
    updated_at: '2023-02-02',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: 'P4444',
    id_number: 4444,
    name: 'Ｄプロジェクト',
    kana: 'Ｄプロジェクト',
    contract_job_amount: 20000000n,
    target_marginal_profit: 10000000n,
    marginal_profit: 11000000n,
    before_cost: 0n,
    variable_cost: 9000000n,
    process_status: 1,
    bill_status: 0,
    updated_at: '2023-02-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }]

  return {
    projects,
  }
}