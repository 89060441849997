import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import { PadViewItem } from '@/const/component/padView'
import ConstEstimates from '@/const/transaction/estimates'

export default function pad () {
  const constEstimates = ConstEstimates()

  const getPadItems = (readonly = true) => {
    const ro = readonly
    const rq = !readonly

    const items: PadViewItem[] = [{
      id: 'estimateInformation',
      label: constEstimates.itemLabel.get('estimateInformation'),
      groups: [{
        items: [{
          id: 'status',
          label: constEstimates.itemLabel.get('status') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'id',
          label: constEstimates.itemLabel.get('id') ?? '',
          type: 'text',
          readonly: true,
        }]
      }, {
        items: [{
          id: 'revisionNo',
          label: constEstimates.itemLabel.get('revisionNo') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'revisionDescription',
          label: constEstimates.itemLabel.get('revisionDescription') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }]
      }, {
        items: [{
          id: 'memo',
          label: constEstimates.itemLabel.get('memo') ?? '',
          type: 'textarea',
          cols: 50,
          readonly: ro,
        }, {
          id: 'estimateDate',
          label: constEstimates.itemLabel.get('estimateDate') ?? '',
          type: 'date',
          readonly: ro,
        }]
      }, {
        items: [{
          id: 'issueDate',
          label: constEstimates.itemLabel.get('issueDate') ?? '',
          type: 'date',
          readonly: true,
        }, {
          id: 'issuer',
          label: constEstimates.itemLabel.get('issuer') ?? '',
          type: 'text',
          readonly: true,
        }]
      }, {
        items: [{
          id: 'sentDate',
          label: constEstimates.itemLabel.get('sentDate') ?? '',
          type: 'date',
          readonly: true,
        }, {
          id: 'sender',
          label: constEstimates.itemLabel.get('sender') ?? '',
          type: 'text',
          readonly: true,
        }]
      }, {
        items: [{
          id: 'updatedAt',
          label: constEstimates.itemLabel.get('updatedAt') ?? '',
          type: 'date',
          readonly: true,
        }, {
          id: 'updatedBy',
          label: constEstimates.itemLabel.get('updatedBy') ?? '',
          type: 'text',
          readonly: true,
        }]
      }],
    }, {
      id: 'contractInformation',
      label: constEstimates.itemLabel.get('contractInformation'),
      groups: [{
        items: [{
          id: 'contract',
          label: constEstimates.itemLabel.get('contract') ?? '',
          type: 'contract',
          readonly: ro,
        }, {
          id: 'contractPeriod',
          label: constEstimates.itemLabel.get('contractPeriod') ?? '',
          type: 'dateRange',
          readonly: ro,
        }],
      }, {
        items: [{
          id: 'client',
          label: constEstimates.itemLabel.get('client') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'officialCompanyName',
          label: constEstimates.itemLabel.get('officialCompanyName') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'officialBranchName',
          label: constEstimates.itemLabel.get('officialBranchName') ?? '',
          type: 'text',
          readonly: true,
        }],
      }],
    }, {
      id: 'constractorInformation',
      label: constEstimates.itemLabel.get('constractorInformation'),
      groups: [{
        items: [{
          id: 'companyName',
          label: constEstimates.itemLabel.get('companyName') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'base',
          label: constEstimates.itemLabel.get('base') ?? '',
          type: 'base',
          readonly: true,
        }, {
          id: 'baseAddress',
          label: constEstimates.itemLabel.get('baseAddress') ?? '',
          type: 'addressPhoneView',
          readonly: true,
        }],
      }],
    }]

    const transactionInformation = {
      id: 'transactionInformation',
      label: constEstimates.itemLabel.get('transactionInformation'),
      groups: [{
        items: [{
          id: 'deliveryDate',
          label: constEstimates.itemLabel.get('deliveryDate') ?? '',
          type: 'text',
          readonly: ro,
        }, {
          id: 'placeOfDelivery',
          label: constEstimates.itemLabel.get('placeOfDelivery') ?? '',
          type: 'text',
          readonly: ro,
        }, {
          id: 'transactionSystem',
          label: constEstimates.itemLabel.get('transactionSystem') ?? '',
          type: 'text',
          readonly: ro,
        }, {
          id: 'dateOfExpiry',
          label: constEstimates.itemLabel.get('dateOfExpiry') ?? '',
          type: 'text',
          readonly: ro,
        }],
      }, {
        items: [{
          id: 'amountIncludingTax',
          label: constEstimates.itemLabel.get('amountIncludingTax') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'amountExcludingTax',
          label: constEstimates.itemLabel.get('amountExcludingTax') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'consumptionTaxAmount',
          label: constEstimates.itemLabel.get('consumptionTaxAmount') ?? '',
          type: 'number',
          readonly: true,
        }],
      }],
    }
    const transactionInformation_edit = {
      id: 'transactionInformation',
      label: constEstimates.itemLabel.get('transactionInformation'),
      groups: [{
        items: [{
          id: 'deliveryDate',
          label: constEstimates.itemLabel.get('deliveryDate') ?? '',
          type: 'text',
          readonly: ro,
        }, {
          id: 'placeOfDelivery',
          label: constEstimates.itemLabel.get('placeOfDelivery') ?? '',
          type: 'text',
          readonly: ro,
        }, {
          id: 'transactionSystem',
          label: constEstimates.itemLabel.get('transactionSystem') ?? '',
          type: 'text',
          readonly: ro,
        }, {
          id: 'dateOfExpiry',
          label: constEstimates.itemLabel.get('dateOfExpiry') ?? '',
          type: 'text',
          readonly: ro,
        }],
      }],
    }

    if (ro) items.push(transactionInformation)
    else items.push(transactionInformation_edit)

    return items
  }

  return {
    getPadItems,
  }
}
