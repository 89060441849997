import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstJournalSalesDetails from '@/const/transaction/journalSalesDetails'

const constTable = ConstJournalSalesDetails()

const expenseAccountTypeItems = [{
  value: '1',
  label: '仕入',
}, {
  value: '2',
  label: '租税公課',
}, {
  value: '3',
  label: '水道光熱費',
}, {
  value: '4',
  label: '通信費',
}, {
  value: '5',
  label: '旅費交通費',
}, {
  value: '6',
  label: '広告宣伝費',
}, {
  value: '7',
  label: '接待交際費',
}, {
  value: '8',
  label: '消耗品費',
}, {
  value: '9',
  label: '給与賃金',
}, {
  value: '10',
  label: '福利厚生費',
}, {
  value: '11',
  label: '地代家賃',
}, {
  value: '12',
  label: '減価償却費',
}, {
  value: '13',
  label: '雑費',
}, {
  value: '20',
  label: '売上',
}]

const baseListColumns: ListViewColumn[] = [{
  id: 'sales_date',
  text: constTable.columnLabel.get('salesDate'),
  displayField: 'sales_date',
  sortField: 'sales_date',
}, {
  id: 'expenseAccountType',
  text: constTable.columnLabel.get('expenseAccountType'),
  displayField: 'expense_account_type',
  sortField: 'expense_account_type',
  align: 'center',
  width: '100px',
  edit: {
    type: 'select',
    items: expenseAccountTypeItems,
  },
}, {
  id: 'consumption_tax_type',
  text: constTable.columnLabel.get('consumptionTaxType'),
  displayField: 'consumption_tax_type',
  sortField: 'consumption_tax_type',
}, {
  id: 'amount',
  text: constTable.columnLabel.get('amount'),
  displayField: 'amount',
  sortField: 'amount',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'client',
  text: constTable.columnLabel.get('client'),
  displayField: 'client_name',
  sortField: 'client_kana',
}, {
  id: 'billing_target',
  text: constTable.columnLabel.get('billingTarget'),
  displayField: 'billing_target_name',
  sortField: 'billing_target_kana',
}, {
  id: 'contract_id',
  text: constTable.columnLabel.get('contractId'),
  displayField: 'contract_id',
  sortField: 'contract_id_number',
  align: 'right',
}, {
  id: 'contract_name',
  text: constTable.columnLabel.get('contractName'),
  displayField: 'contract_name',
  sortField: 'contract_kana',
}, {
  id: 'contractType',
  text: constTable.columnLabel.get('contractType'),
  displayField: 'contract_type',
  sortField: 'contract_type',
  align: 'center',
  width: '20px',
}, {
  id: 'publicPrivateWorksType',
  text: constTable.columnLabel.get('publicPrivateWorksType'),
  displayField: 'public_private_works_type',
  sortField: 'public_private_works_type',
  align: 'center',
  width: '20px',
}, {
  id: 'contractorType',
  text: constTable.columnLabel.get('contractorType'),
  displayField: 'contractor_type',
  sortField: 'contractor_type',
  align: 'center',
  width: '20px',
}, {
  id: 'contractCategory',
  text: constTable.columnLabel.get('contractCategory'),
  displayField: 'contract_category',
  sortField: 'contract_category',
}, {
  id: 'constructionType',
  text: constTable.columnLabel.get('constructionType'),
  displayField: 'construction_type',
  sortField: 'construction_type',
}]

const standardListColumns = {
  sales: [
    /*
    'details_line_no',
    'description',
    'quantity',
    'unit',
    'unit_price',
    'consumption_tax_rate',
    'consumption_tax_amount',
    'amount_excluding_tax',
    'amount_including_tax',
    */
    'sales_date',
    'expenseAccountType',
    'consumption_tax_type',
    'amount',
    'client',
    'billing_target',
    'contract_id',
    'contract_name',
    'contractType',
    'publicPrivateWorksType',
    'contractorType',
    'contractCategory',
    'constructionType',
  ],
}

const listIcon: ListViewIcon = {
}
const listConverter: ListViewConverter = {
}

export default function deposit () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'sales_date',
    order: {
      'sales_date': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    expenseAccountTypeItems,
    getListColumns,
    setListColumns,
  }
}
