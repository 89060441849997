export const tableName = '予算'

export default function budgetSettings () {
  const itemLabel = new Map([
    [ 'base', '拠点' ],
    [ 'division', '部門' ],
    [ 'accountDate', '計上日' ],
    [ 'budgetAmount', '予算金額［円］' ],
    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],
  ])
  const columnLabel = new Map([
    [ 'base', '拠点' ],
    [ 'division', '部門' ],
    [ 'accountDate', '計上日' ],
    [ 'budgetAmount', '予算金額［円］' ],
    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],
  ])

  return {
    itemLabel,
    columnLabel,
  }
}
