export const tableName = '見積明細'

export interface EstimateDetailItem {
  id: string
  id_number: number
  details_line_no: number
  detail_type: number      // 1: 注文品 / 2: メモ
  description: string
  quantity: number | null
  unit: string | null
  unit_price: number | null
  amount_including_tax: number | null
  amount_excluding_tax: number | null
  consumption_tax_amount: number | null
  consumption_tax_type: string | null
  consumption_tax_id: string | null
  consumption_tax_rate: number
  note: string | null
  checked?: boolean
}

export default function estimateDetails () {
  const columnLabel = new Map([
    [ 'detailsLineNo', 'No.' ],
    [ 'description', '品名' ],
    [ 'quantity', '数量' ],
    [ 'unit', '単位' ],
    [ 'unitPrice', '単価［円］' ],
    [ 'consumptionTaxType', '消費税区分' ],
    [ 'consumptionTaxRate', '消費税率' ],
    [ 'consumptionTaxAmount', '消費税額［円］' ],
    [ 'amount', '金額［円］' ],
    [ 'amountExcludingTax', '税抜金額［円］' ],
    [ 'amountIncludingTax', '税込金額［円］' ],
    [ 'note', '備考' ],
    /*
    [ 'quotationInformation', '業者見積情報' ],
    [ 'quotationSupplier', '見積先業者' ],
    [ 'quotationSupplierName', '見積先業者名' ],
    [ 'quotationSupplierKana', '見積先業者名（カナ）' ],
    [ 'quotationUnitPrice', '単価［円］' ],
    [ 'quotationConsumptionTaxRate', '消費税率［％］' ],
    [ 'orderCount', '発注回数' ],
    */
  ])

  return {
    columnLabel,
  }
}
