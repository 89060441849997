export const tableName = '発注者'

export default function clients () {
  const itemLabel = new Map([
    [ 'id', 'ID' ],
    [ 'code', '発注者コード' ],
    [ 'name', '発注者名' ],
    [ 'kana', '発注者名（カナ）' ],
    [ 'official_company_name', '正式社名' ],
    [ 'official_branch_name', '正式支店名' ],
    [ 'honorific_title', '敬称' ],
    [ 'postcode', '郵便番号' ],
    [ 'address', '住所' ],
    [ 'closing_date', '締め日' ],     // 1～31
    [ 'collection_month', '回収月' ], // 0:当月／1:翌月／2:翌々月
    [ 'collection_date', '回収日' ],  // 1～31

    [ 'default_value', '初期値' ],
    [ 'public_private_works_type', '公共民間区分' ],
    [ 'tax_rounding', '消費税端数処理' ],
    [ 'amount_rounding', '金額端数処理' ],

    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])
  const searchLabel = new Map([
    [ 'code', '発注者コード' ],
    [ 'name', '発注者名' ],
    [ 'official_company_name', '正式社名' ],
    [ 'official_branch_name', '正式支店名' ],
    [ 'address', '住所' ],
  ])
  const columnLabel = new Map([
    [ 'id', 'ID' ],
    [ 'code', 'コード' ],
    [ 'name', '発注者名' ],
    [ 'official_company_name', '正式社名' ],
    [ 'official_branch_name', '正式支店名' ],
    [ 'honorific_title', '敬称' ],
    [ 'postcode', '郵便番号' ],
    [ 'address', '住所' ],
    [ 'closing_date', '締め日' ],     // 1～31
    [ 'collection_month', '回収月' ], // 0:当月／1:翌月／2:翌々月
    [ 'collection_date', '回収日' ],  // 1～31

    [ 'default_value', '初期値' ],
    [ 'public_private_works_type', '公民' ],
    [ 'tax_rounding', '消費税' ],
    [ 'amount_rounding', '金額' ],
    
    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])

  const collectionMonth = new Map([
    [ '0', '当月' ],
    [ '1', '翌月' ],
    [ '2', '翌々月' ],
  ])
  const publicPrivateWorksType = new Map([
    [ '0', '公' ],
    [ '1', '民' ],
  ])
  const longPublicPrivateWorksType = new Map([
    [ '0', '公共' ],
    [ '1', '民間' ],
  ])
  const taxRounding = new Map([
    [ '1', '切り捨て' ],
    [ '2', '四捨五入' ],
    [ '3', '切り上げ' ],
  ])
  const amountRounding = new Map([
    [ '1', '切り捨て' ],
    [ '2', '四捨五入' ],
    [ '3', '切り上げ' ],
  ])

  return {
    itemLabel,
    searchLabel,
    columnLabel,
    collectionMonth,
    publicPrivateWorksType,
    longPublicPrivateWorksType,
    taxRounding,
    amountRounding,
  }
}
