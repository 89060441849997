export const tableName = '承認者'

export default function approvalDetails () {
  const columnLabel = new Map([
    [ 'circulationNo', 'No.' ],
    [ 'status', 'ステータス' ], //回覧待ち／未決／差戻／承認／却下
    [ 'approver', '承認者' ],
    [ 'comment', 'コメント' ],
    [ 'approvalDateTime', '承認日時' ],
  ])

  const status = new Map([
    [ 'waiting ', '回覧待ち' ],   //0
    [ 'untreated', '未処理' ],    //1
    [ 'sendBack', '差戻' ],       //10
    [ 'approval', '承認' ],       //20
    [ 'rejection', '却下' ],      //21
  ])

  return {
    columnLabel,
    status,
  }
}
