import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstApprovals from '@/const/transaction/approvals'

const constTable = ConstApprovals()

const baseListColumns: ListViewColumn[] = [{
  id: 'status',
  text: constTable.columnLabel.get('status'),
  displayField: 'status',
  sortField: 'status_number',
  align: 'center',
  width: '100px',
}, {
  id: 'id',
  text: constTable.columnLabel.get('id'),
  displayField: 'id',
  sortField: 'id_number',
  align: 'right',
}, {
  id: 'revision',
  text: constTable.columnLabel.get('revision'),
  displayField: 'revision',
  sortField: 'revision_number',
  align: 'right',
  /*
}, {
  id: 'draftDate',
  text: constTable.columnLabel.get('draftDate'),
  displayField: 'draft_date',
  sortField: 'draft_date',
  */
}, {
  id: 'drafter',
  text: constTable.columnLabel.get('drafter'),
  displayField: 'drafter_name',
  sortField: 'drafter_kana',
}, {
  id: 'circulationDateTime',
  text: constTable.columnLabel.get('circulationDateTime'),
  displayField: 'circulation_date_time',
  sortField: 'circulation_date_time',
}, {
  id: 'resultDateTime',
  text: constTable.columnLabel.get('resultDateTime'),
  displayField: 'result_date_time',
  sortField: 'result_date_time',
}, {
  id: 'contractId',
  text: constTable.columnLabel.get('contractId'),
  displayField: 'contract_id',
  sortField: 'contract_id_number',
  align: 'right',
}, {
  id: 'contractName',
  text: constTable.columnLabel.get('contractName'),
  displayField: 'contract_name',
  sortField: 'contract_kana',
}, {
  id: 'documentType',
  text: constTable.columnLabel.get('documentType'),
  displayField: 'document_type',
  sortField: 'document_type',
}, {
  id: 'documentId',
  text: constTable.columnLabel.get('documentId'),
  displayField: 'document_id',
  sortField: 'document_id_number',
  align: 'right',
}, {
  id: 'route',
  text: constTable.columnLabel.get('route'),
  displayField: 'route_name',
  sortField: 'route_id',
}, {
  id: 'approver',
  text: constTable.columnLabel.get('approver'),
  displayField: 'approver_name',
  sortField: 'approver_kana',
}]

const standardListColumns = {
  general: [
    'status',
    'id',
    'revision',
    'drafter',
    //'draftDate',
    'circulationDateTime',
    'resultDateTime',
    'contractId',
    'contractName',
    'documentType',
    'documentId',
    'route',
    'approver',
  ],
  routing: [
    'status',
    'id',
    'revision',
    'drafter',
    //'draftDate',
    'circulationDateTime',
    'resultDateTime',
    'contractId',
    'contractName',
    'documentType',
    'documentId',
    'route',
    'approver',
  ],
  approvalDrafter: [
    'status',
    'id',
    'revision',
    'drafter',
    //'draftDate',
    'circulationDateTime',
    'resultDateTime',
    'contractId',
    'contractName',
    'documentType',
    'documentId',
    'route',
    'approver',
  ],
  approvalApprover: [
    'status',
    'id',
    'revision',
    'drafter',
    //'draftDate',
    'circulationDateTime',
    'resultDateTime',
    'contractId',
    'contractName',
    'documentType',
    'documentId',
    'route',
    'approver',
  ],
}

const listIcon: ListViewIcon = {
}
const listConverter: ListViewConverter = {
  status: {
    'circulation': {
      text: constTable.status.get('circulation') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(0,120,180)',
        color: 'rgb(255,255,255)',
      },
    },
    'sendBack': {
      text: constTable.status.get('sendBack') ?? '',
      type: 'rectangle',
      style: {
        background: 'rgb(200,160,0)',
        color: 'rgb(255,255,255)',
      },
    },
    'approval': {
      text: constTable.status.get('approval') ?? '',
      type: 'rectangle',
      style: {
        background: 'rgb(0,140,0)',
        color: 'rgb(255,255,255)',
      },
    },
    'rejection': {
      text: constTable.status.get('rejection') ?? '',
      type: 'rectangle',
      style: {
        background: 'rgb(200,0,0)',
        color: 'rgb(255,255,255)',
      },
    },
    '20': {
      text: constTable.status.get('20') ?? '',
      type: 'rectangle',
      style: {
        background: 'rgb(0,80,200)',
        color: 'rgb(255,255,255)',
      },
    },
  },
}

export default function approval () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'id_number',
    order: {
      'id_number': -1,
    },
  })

  const getFormItems = () => {
    console.log('contentsStatus.page', contentsStatus.page)
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
