import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useMutation } from '@vue/apollo-composable'
import { LOGIN_AS_EMPLOYEE } from '@/graphql/mutations/Auth'
import { LoginDialog } from '@/composables/common/dialog'
import { FormErrors } from '@/composables/common/form'
import onMutationError from '@/composables/common/OnMutationError'
import { apiTokenStoreKey } from '@/store/ApiToken'
import { copyFormToInput } from '@/utils'

export type LoginAsEmployeeForm = {
  email?: string
  password?: string
}

export default function useLoginAsEmployee () {
  const { t } = useI18n({ useScope: 'global' })
  const apiTokenStore = useStore(apiTokenStoreKey)
  const router = useRouter()

  const loginAsEmployeeForm = reactive<LoginAsEmployeeForm>({
    email: '',
    password: ''
  })

  const formErrors = reactive<FormErrors>({})

  const { mutate, onDone, onError } = useMutation(LOGIN_AS_EMPLOYEE, () => ({
    variables: {
      input: {
        ...copyFormToInput(loginAsEmployeeForm)
      }
    }
  }))

  const loginDialog = reactive<LoginDialog>({
    title: t('message.login'),
    cancelable: false,
    show: false,
    disabled: false,
    unauthorized: false
  })

  const login = async (email?: string, password?: string) => {
    loginDialog.disabled = true
    loginAsEmployeeForm.email = email
    loginAsEmployeeForm.password = password
    await mutate()
  }

  onDone(async (param) => {
    await apiTokenStore.dispatch('setApiToken', param.data?.loginAsEmployee)
    await router.push('/')
  })

  onError(async (error) => {
    loginDialog.disabled = false
    await onMutationError(error, loginAsEmployeeForm, formErrors)
    error.graphQLErrors?.filter((graphQLError) => graphQLError.extensions.category === 'authentication').forEach(() => {
      loginDialog.unauthorized = true
    })
  })

  return {
    loginDialog,
    formErrors,
    login
  }
}
