export interface DbPurchaseDeliveryDetail {
  //id: number
  id: string
  id_number: number
  details_line_no: number
  details_line_no_text: string
  model_number: string
  description: string
  expense_type: string | null         // 1: 材料費 / 2: 外注費 / 3: 経費 / 4: 労務費 / 0: その他
  expense_account_type: string | null
  not_manage_backlog: number | null
  quantity: number | null
  unit: string | null
  unit_price: number | null
  amount: number | null
  amount_including_tax: number | null
  amount_excluding_tax: number | null
  consumption_tax_amount: number | null
  consumption_tax_rate: string | null
  consumption_tax_rate_number: number | null
  checked?: boolean,
}
/*
export const ConverterPurchaseDetailType = {
  1: {
    text: '明細',
    type: 'ellipse',
    style: {
      background: 'rgb(0,120,200)',
      color: 'rgb(255,255,255)',
    },
  },
  2: {
    text: '値引',
    type: 'ellipse',
    style: {
      background: 'rgb(180,0,180)',
      color: 'rgb(255,255,255)',
    },
  },
}
export const ConverterAccountType = {
  1: {
    text: '仕入',
    type: 'ellipse',
    style: {
      background: 'rgb(0,0,220)',
      color: 'rgb(255,255,255)',
    },
  },
  2: {
    text: '租税公課',
    type: 'ellipse',
    style: {
      background: 'rgb(0,100,200)',
      color: 'rgb(255,255,255)',
    },
  },
  3: {
    text: '水道光熱費',
    type: 'ellipse',
    style: {
      background: 'rgb(0,100,100)',
      color: 'rgb(255,255,255)',
    },
  },
  4: {
    text: '通信費',
    type: 'ellipse',
    style: {
      background: 'rgb(0,200,150)',
      color: 'rgb(255,255,255)',
    },
  },
  5: {
    text: '旅費交通費',
    type: 'ellipse',
    style: {
      background: 'rgb(0,140,0)',
      color: 'rgb(255,255,255)',
    },
  },
  6: {
    text: '広告宣伝費',
    type: 'ellipse',
    style: {
      background: 'rgb(120,180,0)',
      color: 'rgb(255,255,255)',
    },
  },
  7: {
    text: '接待交際費',
    type: 'ellipse',
    style: {
      background: 'rgb(160,160,0)',
      color: 'rgb(255,255,255)',
    },
  },
  8: {
    text: '消耗品費',
    type: 'ellipse',
    style: {
      background: 'rgb(200,120,0)',
      color: 'rgb(255,255,255)',
    },
  },
  9: {
    text: '給与賃金',
    type: 'ellipse',
    style: {
      background: 'rgb(200,0,0)',
      color: 'rgb(255,255,255)',
    },
  },
  10: {
    text: '福利厚生費',
    type: 'ellipse',
    style: {
      background: 'rgb(220,100,100)',
      color: 'rgb(255,255,255)',
    },
  },
  11: {
    text: '地代家賃',
    type: 'ellipse',
    style: {
      background: 'rgb(140,0,200)',
      color: 'rgb(255,255,255)',
    },
  },
  12: {
    text: '減価償却費',
    type: 'ellipse',
    style: {
      background: 'rgb(100,0,100)',
      color: 'rgb(255,255,255)',
    },
  },
  13: {
    text: '雑費',
    type: 'ellipse',
    style: {
      background: 'rgb(0,0,100)',
      color: 'rgb(255,255,255)',
    },
  },
}
*/

export default function purchaseDeliveryDetails () {
  const purchaseDeliveryDetails: Array<DbPurchaseDeliveryDetail> = [{
    id: '160',
    id_number: 160,
    details_line_no: 3,
    details_line_no_text: '2023-01-24',
    model_number: 'M302-SL',
    description: 'Transcend SDカード　32GB',
    expense_type: '2',
    expense_account_type: '〇〇費',
    not_manage_backlog: 0,
    quantity: 2,
    unit_price: 1800,
    unit: '枚',
    amount: 3600,
    amount_including_tax: 3600,
    amount_excluding_tax: 3273,
    consumption_tax_amount: 327,
    consumption_tax_rate: '内税 10％',
    consumption_tax_rate_number: 10,
  }, {
    id: '106',
    id_number: 106,
    details_line_no: 6,
    details_line_no_text: '2021-02-20',
    model_number: '',
    description: 'トラック式 折り曲げ式ブーム',
    expense_type: '3',
    expense_account_type: '〇〇費',
    not_manage_backlog: 1,
    quantity: 2,
    unit: '日',
    unit_price: 24000,
    amount: null,
    amount_including_tax: 24000,
    amount_excluding_tax: 24000,
    consumption_tax_amount: 0,
    consumption_tax_rate: '非課税',
    consumption_tax_rate_number: 0,
  }, {
    id: 'd1008',
    id_number: 1008,
    details_line_no: 8,
    details_line_no_text: '8',
    model_number: '',
    description: '自走式ポスト型ホイール',
    expense_type: '1',
    expense_account_type: '〇〇費',
    not_manage_backlog: 1,
    quantity: 10,
    unit: '日',
    unit_price: 50000,
    amount: null,
    amount_including_tax: 500000,
    amount_excluding_tax: 500000,
    consumption_tax_amount: 0,
    consumption_tax_rate: '不課税',
    consumption_tax_rate_number: null,
}]

  return {
    purchaseDeliveryDetails,
  }
}
