import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import app from './modules/app'
import sidebar from './modules/sidebar'
import send_order_s from './modules/sendOrder'
import send_order_l from './modules/sendOrder'
//import purchase from './modules/purchase'

export const store = createStore({
  modules: {
    app,
    sidebar,
    send_order_s,
    send_order_l,
    //purchase,
  },
  plugins: [
    createPersistedState({
      key: 'asaka',
      paths: [
        'app',
        'send_order_s',
        //'purchase',
      ],
      storage: window.sessionStorage,
    }),
    createPersistedState({
      key: 'asaka',
      paths: [
        'sidebar',
        'send_order_l',
      ],
      storage: window.localStorage,
    }),
  ],
})
