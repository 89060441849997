export interface SelectItem {
  value: string
  label: string
}
export default function selector () {
  const searchType: { [ key: string]: {
    label: string
    placeholder? : string
    maxLength?: number
  }} = {
    deselect: {
      label: '指定しない',
    },
    notSet: {
      label: '未設定のもの',
    },
    yet: {
      label: '未定のもの',
    },
    all: {
      label: 'すべてから選択',
    },
    recently: {
      label: '最近使用したもの',
    },
    arbitrary: {
      label: '名称一部検索',
      placeholder: '名称一部入力',
      maxLength: 20,
    },
    kana: {
      label: '名称カナ検索',
      placeholder: '全角カナ入力',
      maxLength: 20,
    },
  }
  const getSelectItem = (key: string) => {
    return {
      value: key,
      label: searchType[key] ? searchType[key].label : ''
    }
  }
  const getPlaceholder = (key: string) => {
    return searchType[key] ? searchType[key].placeholder : undefined
  }
  const getMaxLength = (key: string) => {
    return searchType[key] ? searchType[key].maxLength : undefined
  }

  return {
    getSelectItem,
    getPlaceholder,
    getMaxLength,
  }
}
