export interface DbOrderProbability {
  id: string
  base_name: string
  base_display_order: number
  division_name: string
  division_display_order: number
  unofficial: number | null
  rank_a: number | null
  rank_b: number | null
  rank_c: number | null
  rank_d: number | null
  rank_e: number | null  
  updated_at: string
  updated_by_name: string
  updated_by_kana: string
}

export default function orderProbabilities () {
  const orderProbabilities: Array<DbOrderProbability> = [{
    id: '1',
    base_name: '郡山本社',
    base_display_order: 1,
    division_name: 'エンジニア',
    division_display_order: 1,
    unofficial: 100,
    rank_a: 70,
    rank_b: 50,
    rank_c: 30,
    rank_d: 20,
    rank_e: 10,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '2',
    base_name: '郡山本社',
    base_display_order: 1,
    division_name: 'システム',
    division_display_order: 2,
    unofficial: 100,
    rank_a: 70,
    rank_b: 65,
    rank_c: 30,
    rank_d: 25,
    rank_e: 10,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '3',
    base_name: '仙台',
    base_display_order: 2,
    division_name: 'エンジニア',
    division_display_order: 1,
    unofficial: 100,
    rank_a: 70,
    rank_b: 55,
    rank_c: 30,
    rank_d: 20,
    rank_e: 10,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '4',
    base_name: '仙台',
    base_display_order: 2,
    division_name: 'システム',
    division_display_order: 2,
    unofficial: 100,
    rank_a: 60,
    rank_b: 55,
    rank_c: 35,
    rank_d: 20,
    rank_e: 10,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '5',
    base_name: '東京',
    base_display_order: 3,
    division_name: 'エンジニア',
    division_display_order: 1,
    unofficial: 100,
    rank_a: 75,
    rank_b: 50,
    rank_c: 35,
    rank_d: 20,
    rank_e: 15,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '6',
    base_name: '東京',
    base_display_order: 3,
    division_name: 'システム',
    division_display_order: 2,
    unofficial: 100,
    rank_a: 80,
    rank_b: 55,
    rank_c: 30,
    rank_d: 25,
    rank_e: 10,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }]

  return {
    orderProbabilities,
  }
}
