import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstPurchaseItems from '@/const/transaction/purchaseItems'
import ConstPurchaseDetails from '@/const/transaction/purchaseDetails'

const constPurchaseItems = ConstPurchaseItems()
const constPurchaseDetails = ConstPurchaseDetails()

const baseListColumns: ListViewColumn[] = [{
  id: 'model_number',
  text: constPurchaseItems.columnLabel.get('modelNumber'),
  displayField: 'model_number',
  sortField: 'model_number',
}, {
  id: 'description',
  text: constPurchaseItems.columnLabel.get('description'),
  displayField: 'description',
  sortField: 'description',
}, {
  id: 'expense_type',
  text: constPurchaseItems.columnLabel.get('expenseType'),
  displayField: 'expense_type',
  sortField: 'expense_type',
  align: 'center',
  width: '100px',
}, {
  id: 'expense_account_type',
  text: constPurchaseItems.columnLabel.get('expenseAccountType'),
  displayField: 'expense_account_type',
  sortField: 'expense_account_type',
}, {
  id: 'quantity',
  text: constPurchaseItems.columnLabel.get('quantity'),
  displayField: 'quantity',
  sortField: 'quantity',
  displayConvert: 'decimalDelimiter',
  align: 'right',
}, {
  id: 'unit',
  text: constPurchaseItems.columnLabel.get('unit'),
  displayField: 'unit',
  sortField: 'unit',
}, {
  id: 'unit_price',
  text: constPurchaseItems.columnLabel.get('unitPrice'),
  displayField: 'unit_price',
  sortField: 'unit_price',
  displayConvert: 'decimalDelimiter',
  align: 'right',
}, {
  id: 'amount_including_tax',
  text: constPurchaseItems.columnLabel.get('amountIncludingTax'),
  displayField: 'amount_including_tax',
  sortField: 'amount_including_tax',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'amount_excluding_tax',
  text: constPurchaseItems.columnLabel.get('amountExcludingTax'),
  displayField: 'amount_excluding_tax',
  sortField: 'amount_excluding_tax',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'consumption_tax_amount',
  text: constPurchaseItems.columnLabel.get('consumptionTaxAmount'),
  displayField: 'consumption_tax_amount',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'consumption_tax_type',
  text: constPurchaseItems.columnLabel.get('consumptionTaxType'),
  displayField: 'consumption_tax_type',
  sortField: 'consumption_tax_type',
}, {
  id: 'consumption_tax_rate',
  text: constPurchaseItems.columnLabel.get('consumptionTaxRate'),
  displayField: 'consumption_tax_rate',
  sortField: 'consumption_tax_rate',
}, {
  id: 'order_date',
  text: constPurchaseItems.columnLabel.get('orderDate'),
  displayField: 'order_date',
  sortField: 'order_date',
}, {
  id: 'order_by',
  text: constPurchaseItems.columnLabel.get('orderBy'),
  displayField: 'order_by_name',
  sortField: 'order_by_kana',
}]
/*
const standardListColumns = {
  purchase: [
    'status',
    'id',
    'send_order_date',
    'payment_date',
    'preOrderCost',
    'supplier',
    //'sales_representative',
    //'site_agent',
    'orderer',
    'base',
    'amount_including_tax',
    'amount_excluding_tax',
    'consumption_tax_amount',
    'delivered_amount_including_tax',
    'delivered_amount_excluding_tax',
    'amount_balance_including_tax',
    'amount_balance_excluding_tax',
    'supplier_estimate',
    'delivery_destination',
    'delivery_address',
    'construction_period',
    'delivery_deadline',
    'delivery_conditions',
    'payment_terms',
    'order_details',
    'note',
    'issue_date',
    'issuer',
    'updated_at',
    'updated_by',
  ],
  general: [
    'status',
    'id',
    'send_order_date',
    'payment_date',
    'preOrderCost',
    'supplier',
    'apportionment',
    'sales_representative',
    'site_agent',
    'orderer',
    'base',
    'amount_including_tax',
    'amount_excluding_tax',
    'consumption_tax_amount',
    'delivered_amount_including_tax',
    'delivered_amount_excluding_tax',
    'amount_balance_including_tax',
    'amount_balance_excluding_tax',
    'supplier_estimate',
    'delivery_destination',
    'delivery_address',
    'construction_period',
    'delivery_deadline',
    'delivery_conditions',
    'payment_terms',
    'order_details',
    'note',
    'issue_date',
    'issuer',
    'updated_at',
    'updated_by',
  ],
  draft: [
    'id',
    'send_order_date',
    'payment_date',
    'preOrderCost',
    'supplier',
    'apportionment',
    'sales_representative',
    'site_agent',
    'orderer',
    'base',
    'amount_including_tax',
    'amount_excluding_tax',
    'consumption_tax_amount',
    'delivered_amount_including_tax',
    'delivered_amount_excluding_tax',
    'amount_balance_including_tax',
    'amount_balance_excluding_tax',
    'supplier_estimate',
    'delivery_destination',
    'delivery_address',
    'construction_period',
    'delivery_deadline',
    'delivery_conditions',
    'payment_terms',
    'order_details',
    'note',
    'updated_at',
    'updated_by',
  ],
  backlog: [
    'status',
    'id',
    'send_order_date',
    'payment_date',
    'preOrderCost',
    'supplier',
    'apportionment',
    'sales_representative',
    'site_agent',
    'orderer',
    'base',
    'amount_including_tax',
    'amount_excluding_tax',
    'consumption_tax_amount',
    'delivered_amount_including_tax',
    'delivered_amount_excluding_tax',
    'amount_balance_including_tax',
    'amount_balance_excluding_tax',
    'supplier_estimate',
    'delivery_destination',
    'delivery_address',
    'construction_period',
    'delivery_deadline',
    'delivery_conditions',
    'payment_terms',
    'order_details',
    'note',
    'issue_date',
    'issuer',
    'updated_at',
    'updated_by',
  ],
}
*/
const listIcon: ListViewIcon = {
  favorite: {
    name: 'heart',
    classes: [
      '--favorite',
    ],
  },
}

export default function sendOrder () {
  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'model_number',
    order: {
      'model_number': 1,
    },
  })
  /*
  const getFormItems = () => {
    return standardListColumns[
        contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }
  */
  const getListColumns = () => {
    /*
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
    */
    return baseListColumns
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }
  const listConverter: ListViewConverter = {
    expense_type: {
      '1': {
        text: constPurchaseDetails.expenseType.get('1') ?? '',
        type: 'ellipse',
        style: {
          background: 'rgb(0,140,0)',
          color: 'rgb(255,255,255)',
        },
      },
      '2': {
        text: constPurchaseDetails.expenseType.get('2') ?? '',
        type: 'ellipse',
        style: {
          background: 'rgb(180,140,0)',
          color: 'rgb(255,255,255)',
        },
      },
      '3': {
        text: constPurchaseDetails.expenseType.get('3') ?? '',
        type: 'ellipse',
        style: {
          background: 'rgb(200,100,100)',
          color: 'rgb(255,255,255)',
        },
      },
      '0': {
        text: constPurchaseDetails.expenseType.get('0') ?? '',
        type: 'ellipse',
        style: {
          background: 'rgb(0,40,160)',
          color: 'rgb(255,255,255)',
        },
      },
    },
    not_manage_backlog: {
      0: {
        text: constPurchaseDetails.notManageBacklog.get('0') ?? '',
        type: 'rectangle',
        style: {
          background: 'rgb(0,180,180)',
          color: 'rgb(255,255,255)',
        },
      },
      1: {
        text: constPurchaseDetails.notManageBacklog.get('1') ?? '',
        type: 'rectangle',
        style: {
          background: 'rgb(240,80,0)',
          color: 'rgb(255,255,255)',
        },
      },
    },
  }

  return {
    baseListColumns,
    listConverter,
    listIcon,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
