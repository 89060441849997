import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
/*
import {
  TreeViewType,
} from '@/const/component/treeView'
*/
const rows = {
  plan: [{
    id: '1',
    constraction_name: '電気工事',
    material: 1000,
    outsource: 1000,
    expenses: 1000,
    labor: 1000,
    others: 1000,
    icon: 'ng',
  }, {
    id: '2',
    constraction_name: 'LAN設備工事',
    material: 1000,
    outsource: 1000,
    expenses: 1000,
    labor: 1000,
    others: 1000,
    icon: 'ok',
  }],
  construction: [{
    id: '1',
    constraction_name: '電気工事',
    material: 1000,
    outsource: 1000,
    expenses: 1000,
    labor: 1000,
    others: 1000,
  }, {
    id: '2',
    constraction_name: 'LAN設備工事',
    material: 1000,
    outsource: 1000,
    expenses: 1000,
    labor: 1000,
    others: 1000,
  }],
  cost: [{
    id: '1',
    constraction_name: '電気工事',
    material: 1000,
    outsource: 1000,
    expenses: 1000,
    labor: 1000,
    others: 1000,
  }, {
    id: '2',
    constraction_name: 'LAN設備工事',
    material: 1000,
    outsource: 1000,
    expenses: 1000,
    labor: 1000,
    others: 1000,
  }],
  item: [{
    id: '1',
    supplier_name: '仕入先Ａ',
    supplier_kana: 'シイレサキA',
    model_number: 'MLN-UFC6A/BU300R',
    description: 'LANケーブル U/FTP (高遮蔽アルミシールド型) 300m巻 Cat.6A カテゴリー6A 10Gbps',
    quantity: 40,
    unit: '巻',
    unit_price: 26180,
    consumption_tax_type: '外税',
    consumption_tax_rate: 10,
    consumption_tax_amount: 104720,
    amount_excluding_tax: 1047200,
    amount_including_tax: 1151920,
  }, {
    id: '1',
    supplier_name: '仕入先Ｂ',
    supplier_kana: 'シイレサキB',
    model_number: 'MLN-HFU6A/BU300R',
    description: 'LANケーブル F/UTP AWG26 細径 Cat.6A LANケーブル 300m ブルー',
    quantity: 40,
    unit: '巻',
    unit_price: 30030,
    consumption_tax_type: '外税',
    consumption_tax_rate: 10,
    consumption_tax_amount: 120120,
    amount_excluding_tax: 1201200,
    amount_including_tax: 1321320,
  }],
  option: [{
    id: '1',
    constraction_name: '電気工事',
    material: 1000,
    outsource: 1000,
    expenses: 1000,
    labor: 1000,
    others: 1000,
  }, {
    id: '2',
    constraction_name: 'LAN設備工事',
    material: 1000,
    outsource: 1000,
    expenses: 1000,
    labor: 1000,
    others: 1000,
  }],
}

export default function planInformation () {
  const getListRows = (type: string) => rows[type as keyof typeof rows]

  return {
    getListRows,
  }
}