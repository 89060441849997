export interface DbApprovalRoute {
  id: string
  name: string
  document_type: string
  document_type_number: number
  approver1_id: string | null
  approver1_name: string | null
  approver1_kana: string | null
  approver2_id: string | null
  approver2_name: string | null
  approver2_kana: string | null
  approver3_id: string | null
  approver3_name: string | null
  approver3_kana: string | null
  approver4_id: string | null
  approver4_name: string | null
  approver4_kana: string | null
  updated_at: string
  updated_by_name: string
  updated_by_kana: string
}

export default function approvalRoutes () {
  const approvalRoutes: Array<DbApprovalRoute> = [{
    id: '1',
    name: '郡山 見積',
    document_type: '1',
    document_type_number: 1,
    approver1_id: '1',
    approver1_name: '係長Ａ',
    approver1_kana: 'カカリチョウA',
    approver2_id: '2',
    approver2_name: '課長Ｂ',
    approver2_kana: 'カチョウB',
    approver3_id: '3',
    approver3_name: '部長Ｃ',
    approver3_kana: 'ブチョウC',
    approver4_id: '4',
    approver4_name: '社長Ｄ',
    approver4_kana: 'シャチョウD',
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }]

  return {
    approvalRoutes,
  }
}
