import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import ConstDeposits from '@/const/transaction/deposits'
import {
  SearchBoxValue,
  SearchBoxItem,
} from '@/const/component/searchBox'

const constDeposits = ConstDeposits()

const items = [
  'billingTarget',
]

export default function search () {
  const searchItems = computed(() => {
    return items.map(el => {
      return {
        id: el,
        label: constDeposits.searchLabel.get(el),
      } as SearchBoxItem
    })
  })

  return {
    searchItems,
  }
}
