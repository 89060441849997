import { InjectionKey } from 'vue'
import { createStore, Store } from 'vuex'
import { Tab } from '@/composables/common/tabs'

export interface TabsStore {
  currentTabName?: string
  tabs?: Array<Tab>
}

export const tabsStoreKey: InjectionKey<Store<TabsStore>> = Symbol('tabsStoreKey')

export const tabsStore = createStore<TabsStore>({
  state: {
    currentTabName: undefined,
    tabs: new Array<Tab>()
  },

  mutations: {
    init (state, tabs: Array<Tab>) {
      state.tabs = tabs
      if (tabs.length > 0) {
        state.currentTabName = tabs[0].name
      }
    },
    setTabs (state, tabs: Array<Tab>) {
      state.tabs = tabs
    },
    setCurrentTabName (state, name: string) {
      state.currentTabName = name
    },
    makeTabDisabled (state, name: string) {
      const tab = state.tabs?.find((t) => t.name === name)
      if (tab) {
        tab.disabled = true
      }
    },
    makeAllTabsEnabled (state) {
      // eslint-disable-next-line no-unused-expressions
      state.tabs?.forEach((t) => {
        t.disabled = false
      })
    }
  },

  actions: {
    init ({ commit }, tabs: Array<Tab>) {
      commit('init', tabs)
    },
    setTabs ({ commit }, tabs: Array<Tab>) {
      commit('setTabs', tabs)
    },
    setCurrentTabName ({ commit }, name: string) {
      commit('setCurrentTabName', name)
    },
    makeTabDisabled ({ commit }, name: string) {
      commit('makeTabDisabled', name)
    },
    makeAllTabsEnabled ({ commit }) {
      commit('makeAllTabsEnabled')
    }
  },

  getters: {
    tabs: (state) => state.tabs,
    currentTabName: (state) => state.currentTabName
  }
})
