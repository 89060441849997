export const tableName = 'カレンダー'

export default function calendars () {
  const itemLabel = new Map([
    [ 'id', 'ID' ],
    [ 'employee_number', '社員番号' ],
    [ 'name', '氏名' ],
    [ 'kana', 'フリガナ' ],
    [ 'birthdate', '生年月日' ],
    [ 'age', '年齢' ],
    [ 'gender', '性別' ],
    [ 'blood_type', '血液型' ],
    [ 'postcode', '郵便番号' ],
    [ 'address', '住所' ],
    [ 'email_address', 'メールアドレス' ],
    [ 'slack_username', 'slackユーザ名' ],
    [ 'division', '部門' ],
    [ 'base', '拠点' ],
    [ 'joining_date', '入社年月日' ],
    [ 'retirement_date', '退職年月日' ],
    [ 'length_of_service', '勤続年数' ],
    [ 'enrollment_type', '在籍区分' ],
    [ 'health_insurance_insured_number', '健康保険被保険者番号' ],
    [ 'employment_insurance_insured_number', '雇用保険被保険者番号' ],
    [ 'basic_pension_number', '基礎年金番号' ],
    [ 'labor_cost_unit_price', '労務費単価［円／時間］' ],
    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])
  const searchLabel = new Map([
    [ 'employeeNumber', '社員番号' ],
    [ 'supplier', '仕入先' ],
  ])
  const columnLabel = new Map([
    [ 'id', 'ID' ],
    [ 'employee_number', '社員番号' ],
    [ 'name', '氏名' ],
    [ 'kana', 'フリガナ' ],
    [ 'birthdate', '生年月日' ],
    [ 'age', '年齢' ],
    [ 'gender', '性別' ],
    [ 'blood_type', '血液型' ],
    [ 'postcode', '郵便番号' ],
    [ 'address', '住所' ],
    [ 'email_address', 'メールアドレス' ],
    [ 'slack_username', 'slackユーザ名' ],
    [ 'division', '部門' ],
    [ 'base', '拠点' ],
    [ 'joining_date', '入社年月日' ],
    [ 'retirement_date', '退職年月日' ],
    [ 'enrollment_type', '在籍区分' ],
    [ 'health_insurance_insured_number', '健康保険被保険者番号' ],
    [ 'employment_insurance_insured_number', '雇用保険被保険者番号' ],
    [ 'basic_pension_number', '基礎年金番号' ],
    [ 'labor_cost_unit_price', '労務費単価［円／時間］' ],
    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])

  const enrollmentType = new Map([
    [ '0', '入社前' ],
    [ '1', '在籍中' ],
    [ '2', '退職済み' ],
  ])
  const gender = new Map([
    [ '0', '不明' ],
    [ '1', '男性' ],
    [ '2', '女性' ],
  ])
  const bloodType = new Map([
    [ '0', '不明' ],
    [ '1', 'Ａ型' ],
    [ '2', 'Ｂ型' ],
    [ '3', 'Ｏ型' ],
    [ '4', 'AB型' ],
  ])

  return {
    itemLabel,
    searchLabel,
    columnLabel,
    enrollmentType,
    gender,
    bloodType,
  }
}
