import gql from 'graphql-tag'

export const AUTHORIZED_AS_EMPLOYEE = gql`
  query AuthorizedAsEmployee {
    authorizedAsEmployee {
      id
      email
      employee_number
      name
      kana
      postcode
      address
      sex
      blood_type
      birthdate
      division {
        id
        name
      }
      base {
          id
          name
      }
      joining_date
      retirement_date
      health_insurance_insured_number
      employment_insurance_insured_number
      basic_pension_number
      labor_cost_unit_price
      roles {
        id
        ability
      }
    }
  }
`
