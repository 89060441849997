export interface DbJournalSalesDetail {
  id: string
  sales_date: string
  expense_account_type: number
  consumption_tax_type: string
  amount: bigint
  client: string
  client_name: string
  client_kana: string
  billing_target: string
  billing_target_name: string
  billing_target_kana: string
  contract_id: string
  contract_id_number: number
  contract_name: string
  contract_kana: string
  contract_type: number
  public_private_works_type: number
  contractor_type: number
  contract_category: string
  construction_type: string
}

export default function journalSalesDetails () {
  const journalSalesDetails: Array<DbJournalSalesDetail> = [{
    id: '1',
    sales_date: '2023-05-02',
    expense_account_type: 20,
    consumption_tax_type: '内税 10%',
    amount: 10000000n,
    client: '1',
    client_name: '得意先Ａ',
    client_kana: 'トクイサキA',
    billing_target: '2',
    billing_target_name: '請求先Ａ',
    billing_target_kana: 'セイキュウサキA',
    contract_id: '11',
    contract_id_number: 1,
    contract_name: '案件Ａ',
    contract_kana: 'アンケンＡ',
    contract_type: 1,
    public_private_works_type: 1,
    contractor_type: 2,
    contract_category: 'TEL',
    construction_type: '電気工事',
  }, {
    id: '2',
    sales_date: '2023-05-12',
    expense_account_type: 20,
    consumption_tax_type: '外税 10%',
    amount: 5000000n,
    client: '2',
    client_name: '得意先Ｂ',
    client_kana: 'トクイサキB',
    billing_target: '22',
    billing_target_name: '請求先Ｂ',
    billing_target_kana: 'セイキュウサキB',
    contract_id: '222',
    contract_id_number: 1,
    contract_name: '案件Ｂ',
    contract_kana: 'アンケンB',
    contract_type: 3,
    public_private_works_type: 1,
    contractor_type: 1,
    contract_category: 'LAN',
    construction_type: '消防施設工事',
  }, {
    id: '3',
    sales_date: '2023-05-23',
    expense_account_type: 20,
    consumption_tax_type: '外税 10%',
    amount: 5000000n,
    client: '3',
    client_name: '得意先Ｃ',
    client_kana: 'トクイサキC',
    billing_target: '33',
    billing_target_name: '請求先Ｃ',
    billing_target_kana: 'セイキュウサキC',
    contract_id: '333',
    contract_id_number: 1,
    contract_name: '案件Ｃ',
    contract_kana: 'アンケンC',
    contract_type: 2,
    public_private_works_type: 2,
    contractor_type: 2,
    contract_category: 'TEL',
    construction_type: '通信工事',
  }]

  return {
    journalSalesDetails,
  }
}
