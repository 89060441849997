import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import ConstBills from '@/const/transaction/bills'
import {
  SearchBoxValue,
  SearchBoxItem,
} from '@/const/component/searchBox'

const constBills = ConstBills()

const items = [
  'id',
  'clientOrderNo',
  'memo',
  'contractId',
  'contractName',
]

export default function search () {
  const searchItems = computed(() => {
    return items.map(el => {
      return {
        id: el,
        label: constBills.searchLabel.get(el),
      } as SearchBoxItem
    })
  })

  return {
    searchItems,
  }
}
