export const tableName = '日報'

export default function dailyReports () {
  const columnLabel = new Map([
    [ 'date', '日付' ],
    [ 'within_time', '時間内' ],
    [ 'overtime', '時間外' ],
    [ 'working_total_time', '合計' ],
    [ 'holiday_time', '休日' ],
    [ 'midnight_time', '深夜' ],
    //[ 'correction_required_count', '要修正件数' ],
  ])

  return {
    columnLabel,
  }
}