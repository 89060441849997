export const tableName = '日報詳細'

export default function dailyReportDetails () {
  const itemLabel = new Map([
    [ 'start_time', '開始時刻' ],
    [ 'end_time', '終了時刻' ],
    [ 'contract', '案件' ],
    [ 'within_time', '時間内' ],
    [ 'overtime', '時間外' ],
    [ 'working_total_time', '合計' ],
    [ 'holiday_time', '休日' ],
    [ 'midnight_time', '深夜' ],
    //[ 'correction_required_count', '要修正件数' ],
  ])
  const columnLabel = new Map([
    [ 'start_time', '開始時刻' ],
    [ 'end_time', '終了時刻' ],
    [ 'contract_id', '案件番号' ],
    [ 'contract_name', '案件名' ],
    [ 'within_time', '時間内' ],
    [ 'overtime', '時間外' ],
    [ 'working_total_time', '合計' ],
    [ 'holiday_time', '休日' ],
    [ 'midnight_time', '深夜' ],
    //[ 'correction_required_count', '要修正件数' ],
  ])

  return {
    itemLabel,
    columnLabel,
  }
}