import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import { PadViewItem } from '@/const/component/padView'
import ConstClients from '@/const/transaction/master/clients'

export default function pad () {
  const constTable = ConstClients()

  const publicPrivateWorksTypeItems = computed(() => {
    return Array.from(constTable.longPublicPrivateWorksType).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })
  const collectionMonthItems = computed(() => {
    return Array.from(constTable.collectionMonth).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })
  const taxRoundingItems = computed(() => {
    return Array.from(constTable.taxRounding).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })
  const amountRoundingItems = computed(() => {
    return Array.from(constTable.amountRounding).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })

  const getPadItems = (readonly = true) => {
    const ro = readonly
    const rq = !readonly

    const items: PadViewItem[] = [{
      id: 'client',
      groups: [{
        items: [{
          id: 'code',
          label: constTable.itemLabel.get('code') ?? '',
          type: 'number',
          readonly: ro,
          required: rq,
        }]
      }, {
        items: [{
          id: 'name',
          label: constTable.itemLabel.get('name') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }, {
          id: 'kana',
          label: constTable.itemLabel.get('kana') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }]
      }, {
        items: [{
          id: 'official_company_name',
          label: constTable.itemLabel.get('official_company_name') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }, {
          id: 'official_branch_name',
          label: constTable.itemLabel.get('official_branch_name') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }, {
          id: 'honorific_title',
          label: constTable.itemLabel.get('honorific_title') ?? '',
          type: 'honorificTitle',
          readonly: ro,
          required: rq,
        }]
      }, {
        items: [{
          id: 'postcode',
          label: constTable.itemLabel.get('postcode') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }, {
          id: 'address',
          label: constTable.itemLabel.get('address') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }]
      }, {
        items: [{
          id: 'closing_date',
          label: constTable.itemLabel.get('closing_date') ?? '',
          type: 'number',
          readonly: ro,
          required: rq,
        }],
      }, {
        items: [{
          id: 'collection_month',
          label: constTable.itemLabel.get('collection_month') ?? '',
          type: 'select',
          items: collectionMonthItems.value,
          readonly: ro,
          required: rq,
        }, {
          id: 'collection_date',
          label: constTable.itemLabel.get('collection_date') ?? '',
          type: 'number',
          readonly: ro,
          required: rq,
        }],
      }],
    }, {
      id: 'default_value',
      label: constTable.itemLabel.get('default_value'),
      groups: [{
        items: [{
          id: 'public_private_works_type',
          label: constTable.itemLabel.get('public_private_works_type') ?? '',
          type: 'select',
          items: publicPrivateWorksTypeItems.value,
          readonly: ro,
          required: rq,
        }],
      }, {
        items: [{
          id: 'tax_rounding',
          label: constTable.itemLabel.get('tax_rounding') ?? '',
          type: 'select',
          items: taxRoundingItems.value,
          readonly: ro,
          required: rq,
        }, {
          id: 'amount_rounding',
          label: constTable.itemLabel.get('amount_rounding') ?? '',
          type: 'select',
          items: amountRoundingItems.value,
          readonly: ro,
          required: rq,
        }],
      }],
    }]

    return items
  }

  return {
    collectionMonthItems,
    publicPrivateWorksTypeItems,
    taxRoundingItems,
    amountRoundingItems,
    getPadItems,
  }
}
