export interface DbDeposit {
  id: string
  payment_date: string
  billing_target_code: string
  billing_target_name: string
  billing_target_kana: string
  number_of_deposits: number
  payment_method: string
  payment_method_type: number
  deposit_amount: bigint
  updated_at: string
  updated_by_name: string
  updated_by_kana: string
}

export default function deposits () {
  const deposits: Array<DbDeposit> = [{
    id: '1234',
    payment_date: '2023-02-03',
    billing_target_code: '12345678',
    billing_target_name: '福島データセンター郡山支部',
    billing_target_kana: 'フクシマデータセンターコオリヤマシブ',
    number_of_deposits: 1,
    payment_method: '振込',
    payment_method_type: 1,
    deposit_amount: 10000000n,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }]

  return {
    deposits,
  }
}
