export interface DbJournalPurchaseItems {
  id: string
  element_name: string
  expense_type: string
  expenseType: string
  account_code_main_debit: number | null
  account_code_main_credit: number | null
  account_code_side_debit: number | null
  account_code_side_credit: number | null
  account_code_sale_debit: number | null
  account_code_sale_credit: number | null
  account_code_assets_debit: number | null
  account_code_assets_credit: number | null
  updated_at: string
  updated_by: string
  updated_by_name: string
  updated_by_kana: string
}

export default function journalPurchaseItems () {
  const journalPurchaseItems: Array<DbJournalPurchaseItems> = [{
    id: '1',
    element_name: '材料費',
    expense_type: '材料費',
    expenseType: '1',
    account_code_main_debit: 511,
    account_code_main_credit: 312,
    account_code_side_debit: 451,
    account_code_side_credit: 313,
    account_code_sale_debit: null,
    account_code_sale_credit: null,
    account_code_assets_debit: null,
    account_code_assets_credit: null,
      updated_at: '2023-04-01',
    updated_by: '1',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインA',
  }, {
    id: '2',
    element_name: '外注費',
    expense_type: '外注費',
    expenseType: '2',
    account_code_main_debit: 530,
    account_code_main_credit: 312,
    account_code_side_debit: 453,
    account_code_side_credit: 313,
    account_code_sale_debit: null,
    account_code_sale_credit: null,
    account_code_assets_debit: null,
    account_code_assets_credit: null,
    updated_at: '2023-05-01',
    updated_by: '2',
    updated_by_name: '社員Ｂ',
    updated_by_kana: 'シャインB',
  }, {
    id: '3',
    element_name: '消耗品費',
    expense_type: '経費',
    expenseType: '3',
    account_code_main_debit: null,
    account_code_main_credit: null,
    account_code_side_debit: null,
    account_code_side_credit: null,
    account_code_sale_debit: 634,
    account_code_sale_credit: 317,
    account_code_assets_debit: null,
    account_code_assets_credit: null,
    updated_at: '2023-06-03',
    updated_by: '3',
    updated_by_name: '社員Ｃ',
    updated_by_kana: 'シャインC',
  }, {
    id: '4',
    element_name: '会議費',
    expense_type: '経費',
    expenseType: '3',
    account_code_main_debit: null,
    account_code_main_credit: null,
    account_code_side_debit: null,
    account_code_side_credit: null,
    account_code_sale_debit: 647,
    account_code_sale_credit: 317,
    account_code_assets_debit: null,
    account_code_assets_credit: null,
    updated_at: '2023-07-01',
    updated_by: '2',
    updated_by_name: '社員Ｂ',
    updated_by_kana: 'シャインB',
  }, {
    id: '4',
    element_name: '建設仮勘定',
    expense_type: 'その他',
    expenseType: '4',
    account_code_main_debit: null,
    account_code_main_credit: null,
    account_code_side_debit: null,
    account_code_side_credit: null,
    account_code_sale_debit: null,
    account_code_sale_credit: null,
    account_code_assets_debit: 218,
    account_code_assets_credit: 317,
    updated_at: '2023-07-01',
    updated_by: '2',
    updated_by_name: '社員Ｂ',
    updated_by_kana: 'シャインB',
  }, {
    id: '5',
    element_name: '車両費',
    expense_type: '経費',
    expenseType: '3',
    account_code_main_debit: null,
    account_code_main_credit: null,
    account_code_side_debit: null,
    account_code_side_credit: null,
    account_code_sale_debit: 643,
    account_code_sale_credit: 317,
    account_code_assets_debit: 219,
    account_code_assets_credit: 317,
    updated_at: '2023-07-23',
    updated_by: '1',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインA',
  }]

  return {
    journalPurchaseItems,
  }
}
