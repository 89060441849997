export const projectsTableName = 'プロジェクト'

export default function projects () {
  const itemLabel = new Map([
    [ 'id', 'プロジェクト番号' ],
    [ 'name', 'プロジェクト名' ],
    [ 'kana', 'プロジェクト名（カナ）' ],
    [ 'contractJobAmount', '請負金額［円］' ],
    [ 'targetMarginalProfit', '目標限界利益［円］' ],
    [ 'marginalProfit', '限界利益［円］' ],
    [ 'beforeCost', '受注前原価［円］' ],
    [ 'variableCost', '原価［円］' ],
    [ 'contractConstructionPeriod', '契約工期' ],
    [ 'contractConstructionPeriodStartDate', '契約工期（開始日）' ],
    [ 'contractConstructionPeriodEndDate', '契約工期（終了日）' ],
    [ 'startDate', '開始日' ],
    [ 'endDate', '終了日' ],
    [ 'completionTime', '完成時期' ],
    [ 'salesRep', '営業担当者' ],
    [ 'siteAgent', '現場代理人' ],
    [ 'note', '備考' ],
    [ 'processStatus', '工程ステータス' ],
    [ 'invoiceStatus', '請求書ステータス' ],
    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],
  ])
  const columnLabel = new Map([
    [ 'id', 'PJ番号' ],
    [ 'name', 'PJ名' ],
    [ 'kana', 'PJ名（カナ）' ],
    [ 'contractJobAmount', '請負金額［円］' ],
    [ 'targetMarginalProfit', '目標限界利益［円］' ],
    [ 'marginalProfit', '限界利益［円］' ],
    [ 'beforeCost', '受注前原価［円］' ],
    [ 'variableCost', '原価［円］' ],
    [ 'contractConstructionPeriod', '契約工期' ],
    [ 'contractConstructionPeriodStartDate', '契約工期（開始日）' ],
    [ 'contractConstructionPeriodEndDate', '契約工期（終了日）' ],
    [ 'startDate', '開始日' ],
    [ 'endDate', '終了日' ],
    [ 'completionTime', '完成時期' ],
    [ 'salesRep', '営業担当者' ],
    [ 'siteAgent', '現場代理人' ],
    [ 'note', '備考' ],
    [ 'processStatus', '工程ST' ],
    [ 'invoiceStatus', '請求書ST' ],
    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],
  ])
  const processStatus = new Map([
    [ '0', '引合' ],
    [ '1', '見積' ],
    [ '2', '受注' ],
    [ '3', '中断中' ],
    [ '4', '完成' ],
    [ '10', '失注' ],
  ])
  const invoiceStatus = new Map([
    [ '1', '未請求' ],
    [ '2', '請求済み' ],
    [ '3', '一部入金' ],
    [ '4', '入金済み' ],
  ])

  return {
    itemLabel,
    columnLabel,
    processStatus,
    invoiceStatus,
  }
}
