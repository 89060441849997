import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import { PadViewItem } from '@/const/component/padView'
import ConstEmployees from '@/const/transaction/master/employees'

export default function pad () {
  const constTable = ConstEmployees()

  const genderItems = computed(() => {
    return Array.from(constTable.gender).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })
  const bloodTypeItems = computed(() => {
    return Array.from(constTable.bloodType).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })

  const getPadItems = (readonly = true) => {
    const ro = readonly
    const rq = !readonly

    const items: PadViewItem[] = [{
      id: 'employee',
      groups: [{
        items: [{
          id: 'employee_number',
          label: constTable.itemLabel.get('employee_number') ?? '',
          type: 'number',
          readonly: ro,
          required: rq,
        }]
      }, {
        items: [{
          id: 'name',
          label: constTable.itemLabel.get('name') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }, {
          id: 'kana',
          label: constTable.itemLabel.get('kana') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }]
      }, {
        items: [{
          id: 'birthdate',
          label: constTable.itemLabel.get('birthdate') ?? '',
          type: 'date',
          readonly: ro,
          required: false,
        }, {
          id: 'age',
          label: constTable.itemLabel.get('age') ?? '',
          type: 'number',
          readonly: true,
          required: false,
        }]
      }, {
        items: [{
          id: 'gender',
          label: constTable.itemLabel.get('gender') ?? '',
          type: 'select',
          items: genderItems.value,
          readonly: ro,
          required: rq,
        }, {
          id: 'blood_type',
          label: constTable.itemLabel.get('blood_type') ?? '',
          type: 'select',
          items: bloodTypeItems.value,
          readonly: ro,
          required: rq,
        }]
      }, {
        items: [{
          id: 'postcode',
          label: constTable.itemLabel.get('postcode') ?? '',
          type: 'text',
          readonly: ro,
          required: false,
        }, {
          id: 'address',
          label: constTable.itemLabel.get('address') ?? '',
          type: 'text',
          readonly: ro,
          required: false,
        }]
      }, {
        items: [{
          id: 'email_address',
          label: constTable.itemLabel.get('email_address') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }],
      }, {
        items: [{
          id: 'division',
          label: constTable.itemLabel.get('division') ?? '',
          type: 'division',
          readonly: ro,
          required: rq,
        }, {
          id: 'base',
          label: constTable.itemLabel.get('base') ?? '',
          type: 'base',
          readonly: ro,
          required: rq,
        }]
      }, {
        items: [{
          id: 'joining_date',
          label: constTable.itemLabel.get('joining_date') ?? '',
          type: 'date',
          readonly: ro,
          required: rq,
        }, {
          id: 'retirement_date',
          label: constTable.itemLabel.get('retirement_date') ?? '',
          type: 'date',
          readonly: ro,
          required: false,
        }, {
          id: 'length_of_service',
          label: constTable.itemLabel.get('length_of_service') ?? '',
          type: 'number',
          readonly: true,
          required: false,
        }, {
          id: 'enrollment_type',
          label: constTable.itemLabel.get('enrollment_type') ?? '',
          type: 'text',
          readonly: true,
          required: false,
        }]
      }, {
        items: [{
          id: 'health_insurance_insured_number',
          label: constTable.itemLabel.get('health_insurance_insured_number') ?? '',
          type: 'text',
          readonly: ro,
          required: false,
        }, {
          id: 'employment_insurance_insured_number',
          label: constTable.itemLabel.get('employment_insurance_insured_number') ?? '',
          type: 'text',
          readonly: ro,
          required: false,
        }, {
          id: 'basic_pension_number',
          label: constTable.itemLabel.get('basic_pension_number') ?? '',
          type: 'text',
          readonly: ro,
          required: false,
        }],
      }, {
        items: [{
          id: 'labor_cost_unit_price',
          label: constTable.itemLabel.get('labor_cost_unit_price') ?? '',
          type: 'number',
          readonly: ro,
          required: rq,
        }],
      }],
    }]

    return items
  }

  return {
    getPadItems,
  }
}
