export default {
  namespaced: true,
  state: () => ({
    visible: true,
    visibility: false,
    //unfoldable: false,

  }),
  mutations: {
    visible (state: { visible: boolean }, value: boolean) {
      state.visible = value
    },
    visibility (state: { visibility: boolean }, value: boolean) {
      state.visibility = value
    },
    /*
    unfoldable (state: { unfoldable: boolean }, value: boolean) {
      state.unfoldable = value
    },
    */
  },
  getters: {
    visible (state: { visible: boolean }) {
      return state.visible
    },
    visibility (state: { visibility: boolean }) {
      return state.visibility
    },
    /*
    unfoldable (state: { unfoldable: boolean }) {
      return state.unfoldable
    },
    */
  },
  actions: {},
}
