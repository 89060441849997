export interface DbEstimate {
  id: string
  id_number: number
  status: string
  status_number: number
  revision_no: number | null
  revision_description: string | null
  client_id: string | null
  client_name: string | null
  client_kana: string | null
  estimate_date: string | null
  memo: string | null
  issue_date: string | null
  issuer_id: string | null
  issuer_name: string | null
  issuer_kana: string | null
  sent_date: string | null
  sender_id: string | null
  sender_name: string | null
  sender_kana: string | null
  updated_at: string
  updated_by_id: string
  updated_by_name: string
  updated_by_kana: string

  company_name: string
  base_name: string | null
  base_display_order: number | null
  base_postcode: string | null
  base_address: string | null
  base_tel: string | null
  base_fax: string | null

  contract_id: string | null
  contract_id_number: number | null
  contract_name: string | null

  delivery_date: string | null
  place_of_delivery: string | null
  transaction_system: string | null
  date_of_expiry: string | null

  amount_including_tax: bigint | null
  amount_excluding_tax: bigint | null
  consumption_tax_amount: bigint | null

  expanded?: boolean
  expansionItems?: DbEstimate[]
}

export default function estimates () {
  const estimates: Array<DbEstimate> = [{
    id: '19608',
    id_number: 19608,
    status: '14',
    status_number: 2,
    revision_no: 10,
    revision_description: '「３．広域NW保守」追加',
    client_id: '1',
    client_name: '協和エクシオ　東北支店',
    client_kana: 'キョウワエクシオ　トウホクシテン',
    estimate_date: '2021-07-13',
    memo: 'メモ用エリアです',
    issue_date: '2021-07-11',
    issuer_id: '111',
    issuer_name: '山崎 太郎',
    issuer_kana: 'ヤマザキ タロウ',
    sent_date: '2021-07-13',
    sender_id: '111',
    sender_name: '山崎 太郎',
    sender_kana: 'ヤマザキ タロウ',
    updated_at: '2021-07-13 11:30:23',
    updated_by_id: '111',
    updated_by_name: '山崎 太郎',
    updated_by_kana: 'ヤマザキ タロウ',
  
    company_name: '安積電気通信工業株式会社',
    base_name: '那覇支店',
    base_display_order: 1,
    base_postcode: null,
    base_address: null,
    base_tel: null,
    base_fax: null,
  
    contract_id: '1234',
    contract_id_number: 1234,
    contract_name: '田村広域行政組合　保守作業費',
  
    delivery_date: '別途お打合せによる',
    place_of_delivery: 'ご指定場所',
    transaction_system: '別途お打合せによる',
    date_of_expiry: '2019-03-31',
    
    amount_including_tax: 4108500n,
    amount_excluding_tax: 3735000n,
    consumption_tax_amount: 373500n,

    expanded: false,
    expansionItems: [{
      id: '19608',
      id_number: 19608,
      status: '14',
      status_number: 20,
      revision_no: 9,
      revision_description: 'Revision 9',
      client_id: '1',
      client_name: '協和エクシオ　東北支店',
      client_kana: 'キョウワエクシオ　トウホクシテン',
      estimate_date: '2021-07-13',
      memo: 'メモ用エリアです',
      issue_date: '2021-07-11',
      issuer_id: '111',
      issuer_name: '山崎 太郎',
      issuer_kana: 'ヤマザキ タロウ',
      sent_date: '2021-07-13',
      sender_id: '111',
      sender_name: '山崎 太郎',
      sender_kana: 'ヤマザキ タロウ',
      updated_at: '2021-07-13 11:30:23',
      updated_by_id: '111',
      updated_by_name: '山崎 太郎',
      updated_by_kana: 'ヤマザキ タロウ',
    
      company_name: '安積電気通信工業株式会社',
      base_name: '那覇支店',
      base_display_order: 1,
      base_postcode: null,
      base_address: null,
      base_tel: null,
      base_fax: null,
    
      contract_id: '1234',
      contract_id_number: 1234,
      contract_name: '田村広域行政組合　保守作業費',
    
      delivery_date: '別途お打合せによる',
      place_of_delivery: 'ご指定場所',
      transaction_system: '別途お打合せによる',
      date_of_expiry: '2019-03-31',
      
      amount_including_tax: 4108500n,
      amount_excluding_tax: 3735000n,
      consumption_tax_amount: 373500n,
    }, {
      id: '19608',
      id_number: 19608,
      status: '14',
      status_number: 20,
      revision_no: 8,
      revision_description: 'Revision 8',
      client_id: '1',
      client_name: '協和エクシオ　東北支店',
      client_kana: 'キョウワエクシオ　トウホクシテン',
      estimate_date: '2021-07-13',
      memo: 'メモ用エリアです',
      issue_date: '2021-07-11',
      issuer_id: '111',
      issuer_name: '山崎 太郎',
      issuer_kana: 'ヤマザキ タロウ',
      sent_date: '2021-07-13',
      sender_id: '111',
      sender_name: '山崎 太郎',
      sender_kana: 'ヤマザキ タロウ',
      updated_at: '2021-07-13 11:30:23',
      updated_by_id: '111',
      updated_by_name: '山崎 太郎',
      updated_by_kana: 'ヤマザキ タロウ',
    
      company_name: '安積電気通信工業株式会社',
      base_name: '那覇支店',
      base_display_order: 1,
      base_postcode: null,
      base_address: null,
      base_tel: null,
      base_fax: null,
    
      contract_id: '1234',
      contract_id_number: 1234,
      contract_name: '田村広域行政組合　保守作業費',
    
      delivery_date: '別途お打合せによる',
      place_of_delivery: 'ご指定場所',
      transaction_system: '別途お打合せによる',
      date_of_expiry: '2019-03-31',
      
      amount_including_tax: 4108500n,
      amount_excluding_tax: 3735000n,
      consumption_tax_amount: 373500n,
    }, {
      id: '19608',
      id_number: 19608,
      status: '12',
      status_number: 20,
      revision_no: 7,
      revision_description: 'Revision 7',
      client_id: '1',
      client_name: '協和エクシオ　東北支店',
      client_kana: 'キョウワエクシオ　トウホクシテン',
      estimate_date: '2021-07-13',
      memo: 'メモ用エリアです',
      issue_date: '2021-07-11',
      issuer_id: '111',
      issuer_name: '山崎 太郎',
      issuer_kana: 'ヤマザキ タロウ',
      sent_date: '2021-07-13',
      sender_id: '111',
      sender_name: '山崎 太郎',
      sender_kana: 'ヤマザキ タロウ',
      updated_at: '2021-07-13 11:30:23',
      updated_by_id: '111',
      updated_by_name: '山崎 太郎',
      updated_by_kana: 'ヤマザキ タロウ',
    
      company_name: '安積電気通信工業株式会社',
      base_name: '那覇支店',
      base_display_order: 1,
      base_postcode: null,
      base_address: null,
      base_tel: null,
      base_fax: null,
    
      contract_id: '1234',
      contract_id_number: 1234,
      contract_name: '田村広域行政組合　保守作業費',
    
      delivery_date: '別途お打合せによる',
      place_of_delivery: 'ご指定場所',
      transaction_system: '別途お打合せによる',
      date_of_expiry: '2019-03-31',
      
      amount_including_tax: 4108500n,
      amount_excluding_tax: 3735000n,
      consumption_tax_amount: 373500n,
    }, {
      id: '19608',
      id_number: 19608,
      status: '12',
      status_number: 20,
      revision_no: 6,
      revision_description: 'Revision 6',
      client_id: '1',
      client_name: '協和エクシオ　東北支店',
      client_kana: 'キョウワエクシオ　トウホクシテン',
      estimate_date: '2021-07-13',
      memo: 'メモ用エリアです',
      issue_date: '2021-07-11',
      issuer_id: '111',
      issuer_name: '山崎 太郎',
      issuer_kana: 'ヤマザキ タロウ',
      sent_date: '2021-07-13',
      sender_id: '111',
      sender_name: '山崎 太郎',
      sender_kana: 'ヤマザキ タロウ',
      updated_at: '2021-07-13 11:30:23',
      updated_by_id: '111',
      updated_by_name: '山崎 太郎',
      updated_by_kana: 'ヤマザキ タロウ',
    
      company_name: '安積電気通信工業株式会社',
      base_name: '那覇支店',
      base_display_order: 1,
      base_postcode: null,
      base_address: null,
      base_tel: null,
      base_fax: null,
    
      contract_id: '1234',
      contract_id_number: 1234,
      contract_name: '田村広域行政組合　保守作業費',
    
      delivery_date: '別途お打合せによる',
      place_of_delivery: 'ご指定場所',
      transaction_system: '別途お打合せによる',
      date_of_expiry: '2019-03-31',
      
      amount_including_tax: 4108500n,
      amount_excluding_tax: 3735000n,
      consumption_tax_amount: 373500n,
    }, {
      id: '19608',
      id_number: 19608,
      status: '12',
      status_number: 20,
      revision_no: 5,
      revision_description: 'Revision 5',
      client_id: '1',
      client_name: '協和エクシオ　東北支店',
      client_kana: 'キョウワエクシオ　トウホクシテン',
      estimate_date: '2021-07-13',
      memo: 'メモ用エリアです',
      issue_date: '2021-07-11',
      issuer_id: '111',
      issuer_name: '山崎 太郎',
      issuer_kana: 'ヤマザキ タロウ',
      sent_date: '2021-07-13',
      sender_id: '111',
      sender_name: '山崎 太郎',
      sender_kana: 'ヤマザキ タロウ',
      updated_at: '2021-07-13 11:30:23',
      updated_by_id: '111',
      updated_by_name: '山崎 太郎',
      updated_by_kana: 'ヤマザキ タロウ',
    
      company_name: '安積電気通信工業株式会社',
      base_name: '那覇支店',
      base_display_order: 1,
      base_postcode: null,
      base_address: null,
      base_tel: null,
      base_fax: null,
    
      contract_id: '1234',
      contract_id_number: 1234,
      contract_name: '田村広域行政組合　保守作業費',
    
      delivery_date: '別途お打合せによる',
      place_of_delivery: 'ご指定場所',
      transaction_system: '別途お打合せによる',
      date_of_expiry: '2019-03-31',
      
      amount_including_tax: 4108500n,
      amount_excluding_tax: 3735000n,
      consumption_tax_amount: 373500n,
    }, {
      id: '19608',
      id_number: 19608,
      status: '12',
      status_number: 20,
      revision_no: 4,
      revision_description: 'Revision 4',
      client_id: '1',
      client_name: '協和エクシオ　東北支店',
      client_kana: 'キョウワエクシオ　トウホクシテン',
      estimate_date: '2021-07-13',
      memo: 'メモ用エリアです',
      issue_date: '2021-07-11',
      issuer_id: '111',
      issuer_name: '山崎 太郎',
      issuer_kana: 'ヤマザキ タロウ',
      sent_date: '2021-07-13',
      sender_id: '111',
      sender_name: '山崎 太郎',
      sender_kana: 'ヤマザキ タロウ',
      updated_at: '2021-07-13 11:30:23',
      updated_by_id: '111',
      updated_by_name: '山崎 太郎',
      updated_by_kana: 'ヤマザキ タロウ',
    
      company_name: '安積電気通信工業株式会社',
      base_name: '那覇支店',
      base_display_order: 1,
      base_postcode: null,
      base_address: null,
      base_tel: null,
      base_fax: null,
    
      contract_id: '1234',
      contract_id_number: 1234,
      contract_name: '田村広域行政組合　保守作業費',
    
      delivery_date: '別途お打合せによる',
      place_of_delivery: 'ご指定場所',
      transaction_system: '別途お打合せによる',
      date_of_expiry: '2019-03-31',
      
      amount_including_tax: 4108500n,
      amount_excluding_tax: 3735000n,
      consumption_tax_amount: 373500n,
    }, {
      id: '19608',
      id_number: 19608,
      status: '12',
      status_number: 20,
      revision_no: 3,
      revision_description: 'Revision 3',
      client_id: '1',
      client_name: '協和エクシオ　東北支店',
      client_kana: 'キョウワエクシオ　トウホクシテン',
      estimate_date: '2021-07-13',
      memo: 'メモ用エリアです',
      issue_date: '2021-07-11',
      issuer_id: '111',
      issuer_name: '山崎 太郎',
      issuer_kana: 'ヤマザキ タロウ',
      sent_date: '2021-07-13',
      sender_id: '111',
      sender_name: '山崎 太郎',
      sender_kana: 'ヤマザキ タロウ',
      updated_at: '2021-07-13 11:30:23',
      updated_by_id: '111',
      updated_by_name: '山崎 太郎',
      updated_by_kana: 'ヤマザキ タロウ',
    
      company_name: '安積電気通信工業株式会社',
      base_name: '那覇支店',
      base_display_order: 1,
      base_postcode: null,
      base_address: null,
      base_tel: null,
      base_fax: null,
    
      contract_id: '1234',
      contract_id_number: 1234,
      contract_name: '田村広域行政組合　保守作業費',
    
      delivery_date: '別途お打合せによる',
      place_of_delivery: 'ご指定場所',
      transaction_system: '別途お打合せによる',
      date_of_expiry: '2019-03-31',
      
      amount_including_tax: 4108500n,
      amount_excluding_tax: 3735000n,
      consumption_tax_amount: 373500n,
    }, {
      id: '19608',
      id_number: 19608,
      status: '12',
      status_number: 20,
      revision_no: 2,
      revision_description: 'Revision 2',
      client_id: '1',
      client_name: '協和エクシオ　東北支店',
      client_kana: 'キョウワエクシオ　トウホクシテン',
      estimate_date: '2021-07-13',
      memo: 'メモ用エリアです',
      issue_date: '2021-07-11',
      issuer_id: '111',
      issuer_name: '山崎 太郎',
      issuer_kana: 'ヤマザキ タロウ',
      sent_date: '2021-07-13',
      sender_id: '111',
      sender_name: '山崎 太郎',
      sender_kana: 'ヤマザキ タロウ',
      updated_at: '2021-07-13 11:30:23',
      updated_by_id: '111',
      updated_by_name: '山崎 太郎',
      updated_by_kana: 'ヤマザキ タロウ',
    
      company_name: '安積電気通信工業株式会社',
      base_name: '那覇支店',
      base_display_order: 1,
      base_postcode: null,
      base_address: null,
      base_tel: null,
      base_fax: null,
    
      contract_id: '1234',
      contract_id_number: 1234,
      contract_name: '田村広域行政組合　保守作業費',
    
      delivery_date: '別途お打合せによる',
      place_of_delivery: 'ご指定場所',
      transaction_system: '別途お打合せによる',
      date_of_expiry: '2019-03-31',
      
      amount_including_tax: 4108500n,
      amount_excluding_tax: 3735000n,
      consumption_tax_amount: 373500n,
    }, {
      id: '19608',
      id_number: 19608,
      status: '12',
      status_number: 20,
      revision_no: 1,
      revision_description: 'Revision 1',
      client_id: '1',
      client_name: '協和エクシオ　東北支店',
      client_kana: 'キョウワエクシオ　トウホクシテン',
      estimate_date: '2021-07-13',
      memo: 'メモ用エリアです',
      issue_date: '2021-07-11',
      issuer_id: '111',
      issuer_name: '山崎 太郎',
      issuer_kana: 'ヤマザキ タロウ',
      sent_date: '2021-07-13',
      sender_id: '111',
      sender_name: '山崎 太郎',
      sender_kana: 'ヤマザキ タロウ',
      updated_at: '2021-07-13 11:30:23',
      updated_by_id: '111',
      updated_by_name: '山崎 太郎',
      updated_by_kana: 'ヤマザキ タロウ',
    
      company_name: '安積電気通信工業株式会社',
      base_name: '那覇支店',
      base_display_order: 1,
      base_postcode: null,
      base_address: null,
      base_tel: null,
      base_fax: null,
    
      contract_id: '1234',
      contract_id_number: 1234,
      contract_name: '田村広域行政組合　保守作業費',
    
      delivery_date: '別途お打合せによる',
      place_of_delivery: 'ご指定場所',
      transaction_system: '別途お打合せによる',
      date_of_expiry: '2019-03-31',
      
      amount_including_tax: 4108500n,
      amount_excluding_tax: 3735000n,
      consumption_tax_amount: 373500n,
    }, {
      id: '19608',
      id_number: 19608,
      status: '12',
      status_number: 20,
      revision_no: null,
      revision_description: '初版',
      client_id: '1',
      client_name: '協和エクシオ　東北支店',
      client_kana: 'キョウワエクシオ　トウホクシテン',
      estimate_date: '2021-07-13',
      memo: 'メモ用エリアです',
      issue_date: '2021-07-11',
      issuer_id: '111',
      issuer_name: '山崎 太郎',
      issuer_kana: 'ヤマザキ タロウ',
      sent_date: '2021-07-13',
      sender_id: '111',
      sender_name: '山崎 太郎',
      sender_kana: 'ヤマザキ タロウ',
      updated_at: '2021-07-13 11:30:23',
      updated_by_id: '111',
      updated_by_name: '山崎 太郎',
      updated_by_kana: 'ヤマザキ タロウ',
    
      company_name: '安積電気通信工業株式会社',
      base_name: '那覇支店',
      base_display_order: 1,
      base_postcode: null,
      base_address: null,
      base_tel: null,
      base_fax: null,
    
      contract_id: '1234',
      contract_id_number: 1234,
      contract_name: '田村広域行政組合　保守作業費',
    
      delivery_date: '別途お打合せによる',
      place_of_delivery: 'ご指定場所',
      transaction_system: '別途お打合せによる',
      date_of_expiry: '2019-03-31',
      
      amount_including_tax: 4108500n,
      amount_excluding_tax: 3735000n,
      consumption_tax_amount: 373500n,
    }],
  }, {
    id: '2',
    id_number: 2,
    status: '0',
    status_number: 0,
    revision_no: null,
    revision_description: null,
    client_id: '1',
    client_name: '発注者Ａ',
    client_kana: 'ハッチュウシャエー',
    estimate_date: '2022-12-02',
    memo: '',
    issue_date: '',
    issuer_id: '',
    issuer_name: '',
    issuer_kana: '',
    sent_date: '',
    sender_id: '',
    sender_name: '',
    sender_kana: '',
    updated_at: '',
    updated_by_id: '',
    updated_by_name: '',
    updated_by_kana: '',

    company_name: '安積電気通信工業株式会社',
    base_name: '那覇支店',
    base_display_order: 1,
    base_postcode: null,
    base_address: null,
    base_tel: null,
    base_fax: null,

    contract_id: '',
    contract_id_number: null,
    contract_name: '',
  
    delivery_date: null,
    place_of_delivery: null,
    transaction_system: null,
    date_of_expiry: null,
    
    amount_including_tax: 9900000n,
    amount_excluding_tax: 9000000n,
    consumption_tax_amount: 900000n,
  }, {
    id: '3',
    id_number: 3,
    status: '10',
    status_number: 1,
    revision_no: null,
    revision_description: null,
    client_id: '1',
    client_name: '発注者Ａ',
    client_kana: 'ハッチュウシャエー',
    estimate_date: '2023-01-21',
    memo: '',
    issue_date: '',
    issuer_id: '',
    issuer_name: '',
    issuer_kana: '',
    sent_date: '',
    sender_id: '',
    sender_name: '',
    sender_kana: '',
    updated_at: '',
    updated_by_id: '',
    updated_by_name: '',
    updated_by_kana: '',

    company_name: '安積電気通信工業株式会社',
    base_name: '那覇支店',
    base_display_order: 1,
    base_postcode: null,
    base_address: null,
    base_tel: null,
    base_fax: null,

    contract_id: '',
    contract_id_number: null,
    contract_name: '',
  
    delivery_date: null,
    place_of_delivery: null,
    transaction_system: null,
    date_of_expiry: null,
    
    amount_including_tax: 8800000n,
    amount_excluding_tax: 8000000n,
    consumption_tax_amount: 800000n,
  }, {
    id: '4',
    id_number: 4,
    status: '11',
    status_number: 10,
    revision_no: null,
    revision_description: null,
    client_id: '1',
    client_name: '発注者Ａ',
    client_kana: 'ハッチュウシャエー',
    estimate_date: '2022-12-02',
    memo: '',
    issue_date: '',
    issuer_id: '',
    issuer_name: '',
    issuer_kana: '',
    sent_date: '',
    sender_id: '',
    sender_name: '',
    sender_kana: '',
    updated_at: '',
    updated_by_id: '',
    updated_by_name: '',
    updated_by_kana: '',

    company_name: '安積電気通信工業株式会社',
    base_name: '那覇支店',
    base_display_order: 1,
    base_postcode: null,
    base_address: null,
    base_tel: null,
    base_fax: null,

    contract_id: '',
    contract_id_number: null,
    contract_name: '',
  
    delivery_date: null,
    place_of_delivery: null,
    transaction_system: null,
    date_of_expiry: null,
    
    amount_including_tax: 9900000n,
    amount_excluding_tax: 9000000n,
    consumption_tax_amount: 900000n,
  }, {
    id: '5',
    id_number: 5,
    status: '20',
    status_number: 11,
    revision_no: null,
    revision_description: null,
    client_id: '1',
    client_name: '発注者Ａ',
    client_kana: 'ハッチュウシャエー',
    estimate_date: '2023-01-21',
    memo: '',
    issue_date: '',
    issuer_id: '',
    issuer_name: '',
    issuer_kana: '',
    sent_date: '',
    sender_id: '',
    sender_name: '',
    sender_kana: '',
    updated_at: '',
    updated_by_id: '',
    updated_by_name: '',
    updated_by_kana: '',

    company_name: '安積電気通信工業株式会社',
    base_name: '那覇支店',
    base_display_order: 1,
    base_postcode: null,
    base_address: null,
    base_tel: null,
    base_fax: null,

    contract_id: '',
    contract_id_number: null,
    contract_name: '',
  
    delivery_date: null,
    place_of_delivery: null,
    transaction_system: null,
    date_of_expiry: null,
    
    amount_including_tax: 8800000n,
    amount_excluding_tax: 8000000n,
    consumption_tax_amount: 800000n,
  }, {
    id: '6',
    id_number: 6,
    status: '21',
    status_number: 20,
    revision_no: null,
    revision_description: null,
    client_id: '1',
    client_name: '発注者Ａ',
    client_kana: 'ハッチュウシャエー',
    estimate_date: '2023-01-21',
    memo: '',
    issue_date: '',
    issuer_id: '',
    issuer_name: '',
    issuer_kana: '',
    sent_date: '',
    sender_id: '',
    sender_name: '',
    sender_kana: '',
    updated_at: '',
    updated_by_id: '',
    updated_by_name: '',
    updated_by_kana: '',

    company_name: '安積電気通信工業株式会社',
    base_name: '那覇支店',
    base_display_order: 1,
    base_postcode: null,
    base_address: null,
    base_tel: null,
    base_fax: null,

    contract_id: '',
    contract_id_number: null,
    contract_name: '',
  
    delivery_date: null,
    place_of_delivery: null,
    transaction_system: null,
    date_of_expiry: null,
    
    amount_including_tax: 8800000n,
    amount_excluding_tax: 8000000n,
    consumption_tax_amount: 800000n,
  }, {
    id: '7',
    id_number: 7,
    status: '22',
    status_number: 30,
    revision_no: null,
    revision_description: null,
    client_id: '1',
    client_name: '発注者Ａ',
    client_kana: 'ハッチュウシャエー',
    estimate_date: '2023-01-21',
    memo: '',
    issue_date: '',
    issuer_id: '',
    issuer_name: '',
    issuer_kana: '',
    sent_date: '',
    sender_id: '',
    sender_name: '',
    sender_kana: '',
    updated_at: '',
    updated_by_id: '',
    updated_by_name: '',
    updated_by_kana: '',

    company_name: '安積電気通信工業株式会社',
    base_name: '那覇支店',
    base_display_order: 1,
    base_postcode: null,
    base_address: null,
    base_tel: null,
    base_fax: null,

    contract_id: '',
    contract_id_number: null,
    contract_name: '',
  
    delivery_date: null,
    place_of_delivery: null,
    transaction_system: null,
    date_of_expiry: null,
    
    amount_including_tax: 8800000n,
    amount_excluding_tax: 8000000n,
    consumption_tax_amount: 800000n,
  }]

  return {
    estimates,
  }
}
