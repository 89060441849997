import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import ConstApprovals from '@/const/transaction/approvals'
import {
  SearchBoxValue,
  SearchBoxItem,
} from '@/const/component/searchBox'

const constApprovals = ConstApprovals()

const items = [
  'id',
  'contractId',
  'contractName',
  'documentId',
]

export default function search () {
  const searchItems = computed(() => {
    return items.map(el => {
      return {
        id: el,
        label: constApprovals.searchLabel.get(el),
      } as SearchBoxItem
    })
  })

  return {
    searchItems,
  }
}
