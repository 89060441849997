import { isReactive, toRaw } from 'vue'
import { GraphQLErrors } from '@apollo/client/errors'

/**
 * `receiveOrdersType` -> `receive_orders_type`
 *
 * @param property
 */
const snakeCase = (property: string) => property.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

/**
 * @param src
 */
const camelCaseToSnakeCase = (src: Record<string, unknown>): Record<string, unknown> => {
  const raw = isReactive(src) ? toRaw(src) : src
  const to: Record<string, unknown> = {}
  Object.keys(raw).forEach((p) => {
    to[snakeCase(p)] = raw[p]
  })
  return to
}

/**
 * @param src
 */
const copyFormToInput = (src: Record<string, unknown>): Record<string, unknown> => {
  const snakeCases = camelCaseToSnakeCase(src)
  const input: Record<string, unknown> = {}
  Object.keys(snakeCases).forEach((p) => {
    const v = snakeCases[p]
    input[p] = typeof v === 'string' && v.trim().length === 0 ? null : v
  })
  return input
}

/**
 * @param graphQLErrors
 */
const extractValidationErrors = (graphQLErrors: GraphQLErrors) => {
  const externalErrors: { [key: string]: string[] } = {}
  for (const e of graphQLErrors) {
    if (e.extensions.category === 'validation') {
      const validation = e.extensions.validation as { [key: string]: unknown }
      Object.keys(validation).forEach((key) => {
        const attr = key.replace('input.', '')
        externalErrors[attr] = validation[key] as string[]
      })
    }
  }
  return externalErrors
}

export {
  camelCaseToSnakeCase,
  copyFormToInput,
  extractValidationErrors
}
