import {
  Construction,
  Supplier,
  Base,
  Employee,
  SendOrderStatus,
  ExpenseType,
  Rounding,
  SendOrderSearchCondition,
} from '@/graphql'

export interface DbPurchaseItem {
  //id: number
  id: string
  //send_order_id: number
  send_order_id: string
  send_order_revision: number
  details_line_no: number
  details_line_no_text: string
  detail_type: number      // 1: 注文品 / 2: メモ
  change_type: number                 // 新規／代替品／単価変更／発注残変更 など（詳細は未定）
  change_items: number                // bit flags 0: 摘要、1: 単位、2: 経費種別、3: 経費勘定科目種別、4: 発注残を管理しない、5: 数量、6: 単価、7: 消費税率
  transaction_type: number            // 0: なし／1: マイナス／2: プラス
  alternative_send_order_detail_id: number | null
  model_number: string
  description: string
  expense_type: string | null         // 1: 材料費 / 2:外注費 / 3: 経費 / 4:労務費
  expense_account_type: string | null
  not_manage_backlog: number | null   // 0: 管理する / 1: 管理しない
  quantity: number | null
  unit: string | null
  unit_price: number | null
  amount_including_tax: bigint | null
  amount_excluding_tax: bigint | null
  consumption_tax_amount: number | null
  consumption_tax_type: string | null
  consumption_tax_rate: string | null
  deliveried_quantity: number | null
  remaining_quantity: number | null
  accounts_payable: number | null
  payment_amount?: number | null
  balanced_payment?: number | null
  checked?: boolean
  icon?: string | null
  expanded?: boolean
  expansionItems?: DbPurchaseItem[]
  order_date: string
  order_by_name: string
  order_by_kana: string
}

export const ConverterExpenseType = {
  1: {
    text: '材料費',
    type: 'ellipse',
    style: {
      background: 'rgb(0,140,0)',
      color: 'rgb(255,255,255)',
    },
  },
  2: {
    text: '外注費',
    type: 'ellipse',
    style: {
      background: 'rgb(180,140,0)',
      color: 'rgb(255,255,255)',
    },
  },
  3: {
    text: '経費',
    type: 'ellipse',
    style: {
      background: 'rgb(180,0,0)',
      color: 'rgb(255,255,255)',
    },
  },
  4: {
    text: '労務費',
    type: 'ellipse',
    style: {
      background: 'rgb(0,40,160)',
      color: 'rgb(255,255,255)',
    },
  },
}
export const ConverterNotManageBacklog = {
  0: {
    text: 'する',
    type: 'rectangle',
    style: {
      background: 'rgb(0,180,180)',
      color: 'rgb(255,255,255)',
    },
  },
  1: {
    text: 'しない',
    type: 'rectangle',
    style: {
      background: 'rgb(240,80,0)',
      color: 'rgb(255,255,255)',
    },
  },
}

export default function purchaseItems () {
  const purchaseItems: Array<DbPurchaseItem> = [{
    id: '2',
    send_order_id: '1',
    send_order_revision: 0,
    details_line_no: 2,
    details_line_no_text: '2',
    detail_type: 1,
    change_type: 0,
    change_items: 0,
    transaction_type: 0,
    alternative_send_order_detail_id: null,
    model_number: 'ABC-123',
    description: 'ラズベリーパイ4 16GB',
    expense_type: '1',
    expense_account_type: '消耗品費',
    not_manage_backlog: 0,
    quantity: 10,
    unit: '個',
    unit_price: 3850,
    amount_including_tax: 42350n,
    amount_excluding_tax: 38500n,
    consumption_tax_amount: 3850,
    consumption_tax_type: '内税',
    consumption_tax_rate: '10％',
    deliveried_quantity: 8,
    remaining_quantity: 2,
    accounts_payable: 36000,
    payment_amount: null,
    balanced_payment: null,
    order_date: '2021-10-05',
    order_by_name: '福島 太郎',
    order_by_kana: 'フクシマ タロウ',
    icon: 'favorite',
  }, {
    id: '3',
    send_order_id: '1',
    send_order_revision: 0,
    details_line_no: 3,
    details_line_no_text: '3',
    detail_type: 1,
    change_type: 0,
    change_items: 0,
    transaction_type: 0,
    alternative_send_order_detail_id: null,
    model_number: 'M302-SL',
    description: 'Transcend SDカード　32GB',
    expense_type: '1',
    expense_account_type: '',
    not_manage_backlog: 0,
    quantity: 10,
    unit: '枚',
    unit_price: 1800,
    amount_including_tax: 18000n,
    amount_excluding_tax: 18000n,
    consumption_tax_amount: 0,
    consumption_tax_type: '税無し',
    consumption_tax_rate: '非課税',
    deliveried_quantity: 8,
    remaining_quantity: 2,
    accounts_payable: 15540,
    payment_amount: null,
    balanced_payment: null,
    order_date: '2020-06-04',
    order_by_name: '郡山 花子',
    order_by_kana: 'コオリヤマ ハナコ',
  }, {
    id: '6',
    send_order_id: '1',
    send_order_revision: 0,
    details_line_no: 6,
    details_line_no_text: '6',
    detail_type: 1,
    change_type: 0,
    change_items: 0,
    transaction_type: 0,
    alternative_send_order_detail_id: null,
    model_number: '',
    description: 'トラック式 折り曲げ式ブーム',
    expense_type: '3',
    expense_account_type: '交際費',
    not_manage_backlog: 1,
    quantity: 10,
    unit: '日',
    unit_price: 24000,
    amount_including_tax: 240000n,
    amount_excluding_tax: 240000n,
    consumption_tax_amount: 0,
    consumption_tax_type: '税無し',
    consumption_tax_rate: '不課税',
    deliveried_quantity: 8,
    remaining_quantity: 0,
    accounts_payable: 192000,
    payment_amount: null,
    balanced_payment: null,
    order_date: '2023-01-23',
    order_by_name: '町田 浩二',
    order_by_kana: 'マチダ コウジ',
    icon: 'favorite',
  }, {
    id: '7',
    send_order_id: '1',
    send_order_revision: 0,
    details_line_no: 7,
    details_line_no_text: '7',
    detail_type: 1,
    change_type: 0,
    change_items: 0,
    transaction_type: 0,
    alternative_send_order_detail_id: null,
    model_number: '',
    description: '垂直昇降型クローラ',
    expense_type: '2',
    expense_account_type: '車両費',
    not_manage_backlog: 1,
    quantity: 10,
    unit: '日',
    unit_price: 45000,
    amount_including_tax: 48600n,
    amount_excluding_tax: 45000n,
    consumption_tax_amount: 3600,
    consumption_tax_type: '外税',
    consumption_tax_rate: '軽減8%',
    deliveried_quantity: 10,
    remaining_quantity: 0,
    accounts_payable: 45000,
    payment_amount: 40000,
    balanced_payment: 5000,
    order_date: '2022-11-13',
    order_by_name: '成瀬 りか',
    order_by_kana: 'ナルセ リカ',
    icon: 'favorite',
  }, {
    id: '8',
    send_order_id: '1',
    send_order_revision: 0,
    details_line_no: 8,
    details_line_no_text: '8',
    detail_type: 1,
    change_type: 0,
    change_items: 0,
    transaction_type: 0,
    alternative_send_order_detail_id: null,
    model_number: '',
    description: '自走式ポスト型ホイール',
    expense_type: '0',
    expense_account_type: null,
    not_manage_backlog: 1,
    quantity: 10,
    unit: '日',
    unit_price: 50000,
    amount_including_tax: 550000n,
    amount_excluding_tax: 500000n,
    consumption_tax_amount: 50000,
    consumption_tax_type: '外税',
    consumption_tax_rate: '10％',
    deliveried_quantity: 0,
    remaining_quantity: 10,
    accounts_payable: null,
    payment_amount: null,
    balanced_payment: null,
    order_date: '2022-01-21',
    order_by_name: '吉本 勝',
    order_by_kana: 'ヨシモト マサル',
  }]

  return {
    purchaseItems,
  }
}
