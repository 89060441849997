export const tableName = '見積明細'

export default function estimateDetails () {
  const columnLabel = new Map([
    [ 'detailsLineNo', '日付' ],
    [ 'description', '勤務時間' ],
    [ 'quantity', '数量' ],
    [ 'unit', '単位' ],
    [ 'unitPrice', '単価［円］' ],
    [ 'amount', '金額［円］' ],
    [ 'consumptionTaxRate', '消費税率［％］' ],
    [ 'note', '備考' ],
    [ 'supplier', '見積先業者' ],
    [ 'estimatedUnitPrice', '見積単価［円］' ],
    [ 'orderCount', '発注回数' ],
  ])

  return {
    columnLabel,
  }
}
