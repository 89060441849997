import { watch } from 'vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { auth } from '@/router/auth'
import { Employee } from '@/graphql'
import useGetAuthorizedEmployee from '@/composables/auth/UseGetAuthorizedEmployee'
import { authorizedEmployeeStore } from '@/store/AuthorizedEmployee'

//import RouterView from '@/views/RouterView.vue'

import Contract from '@/views/contract/Contract.vue'
import ContractProject from '@/views/contract/ContractProject.vue'
import ContractGeneral from '@/views/contract/ContractGeneral.vue'
import ContractProspect from '@/views/contract/ContractProspect.vue'
//import ConstructionPreparation from '@/views/contract/ConstructionPreparation.vue'
import ContractProgress from '@/views/contract/ContractProgress.vue'
//import ConstructionOrdered from '@/views/contract/ConstructionOrdered.vue'
//import ConstructionSold from '@/views/contract/ConstructionSold.vue'
import ContractCompleted from '@/views/contract/ContractCompleted.vue'
import ContractId from '@/views/contract/ContractId.vue'
import ContractInformation from '@/views/contract/id/ContractInformation.vue'

import ContractPlan from '@/views/contract/id/ContractPlan.vue'

import ContractPurchase from '@/views/contract/id/ContractPurchase.vue'
//import ConstructionSendOrder from '@/views/contract/id/ConstructionSendOrder.vue'
//import ConstructionPurchase from '@/views/contract/id/ConstructionPurchase.vue'
import ContractEstimate from '@/views/contract/id/ContractEstimate.vue'
import ContractBill from '@/views/contract/id/ContractBill.vue'
import ContractAttendance from '@/views/contract/id/ContractAttendance.vue'

import Project from '@/views/contract/project/Project.vue'
import ProjectId from '@/views/contract/project/ProjectId.vue'
import ProjectPurchase from '@/views/contract/project/ProjectPurchase.vue'

import Plan from '@/views/plan/Plan.vue'
import PlanId from '@/views/plan/PlanId.vue'
import PlanInformation from '@/components/plan/PlanInformationForm.vue'

import Estimate from '@/views/estimate/Estimate.vue'
import EstimateGeneral from '@/views/estimate/EstimateGeneral.vue'
import EstimateDraft from '@/views/estimate/EstimateDraft.vue'
import EstimateWaiting from '@/views/estimate/EstimateWaiting.vue'
import EstimateId from '@/views/estimate/EstimateId.vue'
import EstimateInformation from '@/views/estimate/id/EstimateInformation.vue'

import Bill from '@/views/bill/Bill.vue'
import BillGeneral from '@/views/bill/BillGeneral.vue'
import BillDraft from '@/views/bill/BillDraft.vue'
import BillNoInvoice from '@/views/bill/BillNoInvoice.vue'
//import BillAccrued from '@/views/bill/BillAccrued.vue'
import BillId from '@/views/bill/BillId.vue'
import BillInformation from '@/views/bill/id/BillInformation.vue'

import Deposit from '@/views/deposit/Deposit.vue'
import DepositGeneral from '@/views/deposit/DepositGeneral.vue'
import DepositBalance from '@/views/deposit/DepositBalance.vue'
//import DepositClient from '@/views/deposit/DepositClient.vue'
/*
import SendOrder from '@/views/sendOrder/SendOrder.vue'
import SendOrderDraft from '@/views/sendOrder/SendOrderDraft.vue'
import SendOrderOrdered from '@/views/sendOrder/SendOrderOrdered.vue'
import SendOrderMonthly from '@/views/sendOrder/SendOrderMonthly.vue'
import SendOrderId from '@/views/sendOrder/SendOrderId.vue'
import SendOrderInformation from '@/views/sendOrder/id/SendOrderInformation.vue'
*/

import Purchase from '@/views/purchase/Purchase.vue'
import PurchaseGeneral from '@/views/purchase/PurchaseGeneral.vue'
import PurchaseDraft from '@/views/purchase/PurchaseDraft.vue'
import PurchaseBacklog from '@/views/purchase/PurchaseBacklog.vue'
import PurchaseId from '@/views/purchase/PurchaseId.vue'
import PurchaseInformation from '@/views/purchase/id/PurchaseInformation.vue'
import PurchaseDelivery from '@/views/purchase/delivery/PurchaseDelivery.vue'
import PurchaseDeliveryGeneral from '@/views/purchase/delivery/PurchaseDeliveryGeneral.vue'
import PurchaseDeliveryTotal from '@/views/purchase/delivery/PurchaseDeliveryTotal.vue'
import PurchaseDeliveryId from '@/views/purchase/delivery/PurchaseDeliveryId.vue'
import PurchaseDeliveryInformation from '@/views/purchase/delivery/id/PurchaseDeliveryInformation.vue'

import PurchaseDeliveryForm from '@/components/purchase/delivery/PurchaseDeliveryForm.vue'

import Payment from '@/views/payment/Payment.vue'
import PaymentGeneral from '@/views/payment/PaymentGeneral.vue'
import PaymentBalance from '@/views/payment/PaymentBalance.vue'
//import PaymentSupplier from '@/views/payment/PaymentSupplier.vue'

import Budget from '@/views/budget/Budget.vue'
import BudgetForm from '@/components/budget/BudgetForm.vue'
import CostSummaryForm from '@/components/budget/CostSummaryForm.vue'

import Journal from '@/views/journal/Journal.vue'
import JournalSalesForm from '@/components/journal/JournalSalesForm.vue'
import JournalDepositForm from '@/components/journal/JournalDepositForm.vue'
import JournalAdvanceDepositForm from '@/components/journal/JournalAdvanceDepositForm.vue'
import JournalPurchaseForm from '@/components/journal/JournalPurchaseForm.vue'
import JournalSettings from '@/views/journal/settings/JournalSettings.vue'
import JournalSalesItemsForm from '@/components/journal/settings/salesItems/JournalSalesItemsForm.vue'
import JournalDepositItemsForm from '@/components/journal/settings/depositItems/JournalDepositItemsForm.vue'
import JournalPurchaseItemsForm from '@/components/journal/settings/purchaseItems/JournalPurchaseItemsForm.vue'
import JournalPrefixForm from '@/components/journal/settings/prefix/JournalPrefixForm.vue'
import JournalDepartmentForm from '@/components/journal/settings/department/JournalDepartmentForm.vue'
import JournalFixedValueForm from '@/components/journal/settings/fixedValue/JournalFixedValueForm.vue'

import Approval from '@/views/approval/Approval.vue'
import ApprovalForm from '@/components/approval/ApprovalForm.vue'
//import ApprovalRouteForm from '@/components/approval/ApprovalRouteForm.vue'

//import Audit from '@/views/audit/Audit.vue'
import AuditForm from '@/components/audit/AuditForm.vue'

import Role from '@/views/role/Role.vue'
import RoleForm from '@/components/role/RoleForm.vue'
import RoleGroupForm from '@/components/role/RoleGroupForm.vue'

import Notification from '@/views/notification/Notification.vue'

import Master from '@/views/master/Master.vue'
import EmployeeForm from '@/components/master/employee/EmployeeForm.vue'
import ClientForm from '@/components/master/client/ClientForm.vue'
import SupplierForm from '@/components/master/supplier/SupplierForm.vue'
import CalendarForm from '@/components/master/calendar/CalendarForm.vue'
import DivisionForm from '@/components/master/division/DivisionForm.vue'
import BaseForm from '@/components/master/base/BaseForm.vue'
import ContractCategoryForm from '@/components/master/contractCategory/ContractCategoryForm.vue'
import ConstructionTypeForm from '@/components/master/constructionType/ConstructionTypeForm.vue'
import OrderProbabilityForm from '@/components/master/orderProbability/OrderProbabilityForm.vue'
//import MasterAccount from '@/views/master/MasterAccount.vue'
import MasterRegistrationRequest from '@/views/master/MasterRegistrationRequest.vue'
import ApprovalRouteForm from '@/components/master/approvalRoute/ApprovalRouteForm.vue'
import TaxTypeForm from '@/components/master/taxType/TaxTypeForm.vue'

import Mypage from '@/views/mypage/Mypage.vue'
import MypageDashboard from '@/views/mypage/MypageDashboard.vue'
import MypageNotice from '@/views/mypage/MypageNotice.vue'
//import MypageAttendance from '@/views/mypage/MypageAttendance.vue'

import DailyReportForm from '@/components/dailyReport/DailyReportForm.vue'

import ChartForm from '@/components/chart/ChartForm.vue'
import SettingsForm from '@/components/settings/SettingsForm.vue'
/*
import MypageApproval from '@/views/mypage/MypageApproval.vue'
import MypageApprovalDraft from '@/views/mypage/MypageApprovalDraft.vue'
import MypageApprovalJudge from '@/views/mypage/MypageApprovalJudge.vue'
*/
import PdfViewer from '@/views/appendix/PdfViewer.vue'
import CalendarViewer from '@/views/appendix/CalendarViewer.vue'

const routes: Array<RouteRecordRaw> = [{
  name: 'index',
  path: '/',
  //redirect: '/mypage/dashboard',
  redirect: '/contract',
}, {
  name: 'contract',
  path: '/contract',
  component: Contract,
  meta: { title: '案件管理' },
  children: [{
    path: 'project',
    component: ContractProject,
    meta: { title: 'プロジェクト' },
    children: [{
      path: '',
      component: Project,
    }, {
      path: ':projectId',
      component: ProjectId,
      children: [{
        path: 'purchase',
        component: ProjectPurchase,
      }]
    }]
  }, {
    path: 'general',
    component: ContractGeneral,
    meta: { title: '案件一覧' },
  }, {
    path: 'prospect',
    component: ContractProspect,
    meta: { title: '見込み - 案件管理' },
  }, {
    path: 'progress',
    component: ContractProgress,
    meta: { title: '受注／仕掛 - 案件管理' },
  }, {
    path: 'completed',
    component: ContractCompleted,
    meta: { title: '完成／売上 - 案件管理' },
  }, {
    path: ':contractId',
    component: ContractId,
    children: [{
      path: 'information',
      component: ContractInformation,
      meta: { title: '案件情報： 1' },
    }, {
      path: 'plan',
      component: ContractPlan,
      meta: { title: '予算 - 案件管理' },
      /*
      children: [{
        path: ':planId',
        component: ContractPlanId,
        children: [{
          path: 'information',
          component: ContractPlanInformation,
          meta: { title: '予算情報： 1' },
        }],
      }],
      */
    }, {
      path: 'purchase',
      component: ContractPurchase,
      meta: { title: '原価 - 案件管理' },
    }, {
      path: 'estimate',
      component: ContractEstimate,
      meta: { title: '見積 - 案件管理' },
    }, {
      path: 'bill',
      component: ContractBill,
      meta: { title: '請求 - 案件管理' },
    }, {
      path: 'attendance',
      component: ContractAttendance,
      meta: { title: '稼動表 - 案件管理' },
    }],
  }],
}, {
  name: 'plan',
  path: '/plan',
  component: Plan,
  meta: { title: '案件予算管理' },
  children: [{
    path: ':planId',
    component: PlanId,
    children: [{
      path: 'information',
      component: PlanInformation,
    }],
  }],
}, {
  name: 'estimate',
  path: '/estimate',
  component: Estimate,
  meta: { title: '見積管理' },
  children: [{
    path: 'general',
    component: EstimateGeneral,
  }, {
    path: 'draft',
    component: EstimateDraft,
  }, {
    path: 'waiting',
    component: EstimateWaiting,
  }, {
    path: ':estimateId',
    component: EstimateId,
    children: [{
      path: 'information',
      component: EstimateInformation,
    }],
  }],
}, {
  name: 'bill',
  path: '/bill',
  component: Bill,
  meta: { title: '請求管理' },
  children: [{
    path: 'general',
    component: BillGeneral
  }, {
    path: 'draft',
    component: BillDraft
  }, {
    path: 'no_invoice',
    component: BillNoInvoice
  }, {
    path: ':billId',
    component: BillId,
    children: [{
      path: 'information',
      component: BillInformation,
    }],
  }],
}, {
  name: 'deposit',
  path: '/deposit',
  component: Deposit,
  meta: { title: '入金管理' },
  children: [{
    path: 'general',
    component: DepositGeneral,
  }, {
    path: 'balance',
    component: DepositBalance,
  }],
}, {
  name: 'purchase',
  path: '/purchase',
  component: Purchase,
  meta: { title: '発注管理' },
  children: [{
    path: 'general',
    component: PurchaseGeneral,
  }, {
    path: 'draft',
    component: PurchaseDraft,
  }, {
    path: 'backlog',
    component: PurchaseBacklog,
  }, {
    path: ':purchaseId',
    component: PurchaseId,
    children: [{
      path: 'information',
      component: PurchaseInformation,
    }, {
      path: 'delivery',
      component: PurchaseDeliveryForm,
    }],
  }],
}, {
  name: 'purchaseDelivery',
  path: '/purchase/delivery',
  component: PurchaseDelivery,
  meta: { title: '仕入管理' },
  children: [{
    path: 'general',
    component: PurchaseDeliveryGeneral
  }, {
    path: 'total',
    component: PurchaseDeliveryTotal
  }, {
    path: ':purchaseDeliveryId',
    component: PurchaseDeliveryId,
    children: [{
      path: 'information',
      component: PurchaseDeliveryInformation,
    }],
  }],
}, {
  name: 'payment',
  path: '/payment',
  component: Payment,
  meta: { title: '支払管理' },
  children: [{
    path: 'general',
    component: PaymentGeneral,
  }, {
    path: 'balance',
    component: PaymentBalance,
  }],
}, {
  name: 'budget',
  path: '/budget',
  component: Budget,
  meta: { title: '予算管理' },
  children: [{
    path: 'general',
    component: BudgetForm,
  }, {
    path: 'unfinished',
    component: CostSummaryForm,
  }],
}, {
  name: 'journal',
  path: '/journal',
  component: Journal,
  meta: { title: '仕訳管理' },
  children: [{
    path: 'purchase',
    component: JournalPurchaseForm,
  }, {
    path: 'sales',
    component: JournalSalesForm,
  }, {
    path: 'deposit',
    component: JournalDepositForm,
  }, {
    path: 'advance_deposit',
    component: JournalAdvanceDepositForm,
  }, {
    path: 'purchase',
    component: JournalPurchaseForm,
  }],
}, {
  name: 'approval',
  path: '/approval',
  component: Approval,
  meta: { title: '稟議管理' },
  children: [{
    path: 'general',
    component: ApprovalForm,
    meta: { title: '稟議一覧' },
  }, {
    path: 'routing',
    component: ApprovalForm,
    meta: { title: '回覧中 - 稟議管理' },
    /*
  }, {
    path: 'route',
    component: ApprovalRouteForm,
    */
  }],
}, {
  name: 'audit',
  path: '/audit',
  component: AuditForm,
  meta: { title: '監査管理' },
}, {
  name: 'role',
  path: '/role',
  component: Role,
  meta: { title: 'ロール管理' },
  children: [{
    path: 'general',
    component: RoleForm,
    meta: { title: 'ロール一覧 - ロール管理' },
  }, {
    path: 'group',
    component: RoleGroupForm,
    meta: { title: 'グループ設定 - ロール管理' },
  }],
}, {
  name: 'notification',
  path: '/notification',
  component: Notification,
  meta: { title: '通知設定' },
}, {
  name: 'journalSettings',
  path: '/journal/settings',
  component: JournalSettings,
  meta: { title: '仕訳設定' },
  children: [{
    path: 'sales',
    component: JournalSalesItemsForm,
    meta: { title: '売上内訳 - 仕訳設定' },
  }, {
    path: 'deposit',
    component: JournalDepositItemsForm,
    meta: { title: '入金内訳 - 仕訳設定' },
  }, {
    path: 'purchase',
    component: JournalPurchaseItemsForm,
    meta: { title: '仕入内訳 - 仕訳設定' },
  }, {
    path: 'prefix',
    component: JournalPrefixForm,
    meta: { title: '要素内訳名接頭辞 - 仕訳設定' },
  }, {
    path: 'department',
    component: JournalDepartmentForm,
    meta: { title: '部門コード - 仕訳設定' },
  }, {
    path: 'fixed',
    component: JournalFixedValueForm,
    meta: { title: '固定値 - 仕訳設定' },
  }],
}, {
  name: 'master',
  path: '/master',
  component: Master,
  meta: { title: 'マスタ管理' },
  children: [{
    path: 'employee',
    component: EmployeeForm,
    meta: { title: '社員 - マスタ管理' },
  }, {
    path: 'client',
    component: ClientForm,
    meta: { title: '発注者 - マスタ管理' },
  }, {
    path: 'supplier',
    component: SupplierForm,
    meta: { title: '業者 - マスタ管理' },
  }, {
    path: 'division',
    component: DivisionForm,
    meta: { title: '部門 - マスタ管理' },
  }, {
    path: 'base',
    component: BaseForm,
    meta: { title: '拠点 - マスタ管理' },
  }, {
    path: 'calendar',
    component: CalendarForm,
    meta: { title: 'カレンダー - マスタ管理' },
  }, {
    path: 'contract_category',
    component: ContractCategoryForm,
    meta: { title: '案件種別 - マスタ管理' },
  }, {
    path: 'construction_type',
    component: ConstructionTypeForm,
    meta: { title: '工事区分 - マスタ管理' },
  }, {
    path: 'order_probability',
    component: OrderProbabilityForm,
    meta: { title: '受注確度 - マスタ管理' },
  }, {
    path: 'tax_type',
    component: TaxTypeForm,
    meta: { title: '消費税区分 - マスタ管理' },
  }, {
    path: 'approval_route',
    component: ApprovalRouteForm,
    meta: { title: '承認ルート - マスタ管理' },
  }, {
    path: 'registration_request',
    component: MasterRegistrationRequest,
  }],
}, {
  name: 'mypage',
  path: '/mypage',
  component: Mypage,
  meta: { title: 'マイページ' },
  children: [{
    path: 'dashboard',
    component: MypageDashboard,
    meta: { title: 'ダッシュボード - マイページ' },
  }, {
    path: 'notice',
    component: MypageNotice,
    meta: { title: 'お知らせ - マイページ' },
  }, {
    path: 'daily_report',
    component: DailyReportForm,
    meta: { title: '日報 - マイページ' },
  }, {
    path: 'approval/drafter',
    component: ApprovalForm,
    meta: { title: '稟議 【起案】 - マイページ' },
  }, {
    path: 'approval/approver',
    component: ApprovalForm,
    meta: { title: '稟議 【承認】 - マイページ' },
    /*
  }, {
    path: 'approval/result',
    component: ApprovalForm,
  }, {
    path: 'approval/circulation',
    component: ApprovalForm,
  }, {
    path: 'approval/request',
    component: ApprovalForm,
    */
  }],
}, {
  name: 'pdf',
  path: '/pdf',
  component: PdfViewer,
  meta: { title: '見積書：19608-10' },
}, {
  name: 'calendar',
  path: '/calendar/:year',
  component: CalendarViewer,
  meta: { title: 'カレンダー 2023年' },
}, {
  name: 'chart',
  path: '/chart',
  component: ChartForm,
  meta: { title: 'chart' },
}, {
  name: 'settings',
  path: '/settings',
  component: SettingsForm,
  meta: { title: 'settings' },
},
  ...auth,
]

const DEFAULT_TITLE = '販管システム'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL || 'http://localhost:8081'),
  routes
})

async function fetchAuthorizedEmployee(): Promise<Employee | undefined> {
  return new Promise((resolve) => {
    const { authorizedEmployee, error } = useGetAuthorizedEmployee()
    watch(authorizedEmployee, () => {
      resolve(authorizedEmployee.value)
    })
    watch(error, () => {
      resolve(undefined)
    })
  })
}

router.beforeEach(async (to, from, next) => {
  if (to.name !== 'LoginAsEmployee') {
    const employee = await fetchAuthorizedEmployee()
    await authorizedEmployeeStore.dispatch('setAuthorizedEmployee', employee)
  }
  next()
})
router.afterEach((to) => {
  const title = to.meta.title
    ? to.meta.title as string
    : DEFAULT_TITLE
  document.title = title
})

export default router
