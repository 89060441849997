import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstRoles from '@/const/transaction/roles'

const constTable = ConstRoles()

const baseListColumns: ListViewColumn[] = [{
  id: 'order_no',
  text: constTable.columnLabel.get('order_no'),
  displayField: 'order_no',
  sortField: 'order_no',
  align: 'right',
}, {
  id: 'name',
  text: constTable.columnLabel.get('name'),
  displayField: 'name',
  sortField: 'name',
  edit: {
    type: 'text',
  },
}, {
  id: 'employee_number',
  text: constTable.columnLabel.get('employee_number'),
  displayField: 'employee_number',
  sortField: 'employee_number',
  align: 'right',
}, {
  id: 'employee_name',
  text: constTable.columnLabel.get('employee_name'),
  displayField: 'employee_name',
  sortField: 'employee_kana',
}, {
  id: 'role_group',
  text: constTable.columnLabel.get('role_group'),
  displayField: 'role_group',
  sortField: 'role_group_order_no',
  edit: {
    type: 'select',
    items: [{
      value: '0',
      label: '（未設定）',
    }, {
      value: '1',
      label: '営業',
    }, {
      value: '2',
      label: 'エンジニア',
    }, {
      value: '3',
      label: 'システム',
    }, {
      value: '4',
      label: '総務',
    }, {
      value: '5',
      label: 'システム管理者',
    }],
  },
}, {
  id: 'contract',
  text: constTable.columnLabel.get('contract'),
  align: 'center',
  columnCount: 18,
  groups: [{
    id: 'view',
    text: '表示権限',
    align: 'center',
    columns: [{
      id: 'contract_view_project',
      text: 'プロジェクト',
      displayField: 'contract_view_project',
      sortField: 'contract_view_project',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'contract_view_general',
      text: '案件一覧',
      displayField: 'contract_view_general',
      sortField: 'contract_view_general',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'contract_view_prospect',
      text: '見込み',
      displayField: 'contract_view_prospect',
      sortField: 'contract_view_prospect',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'contract_view_progress',
      text: '受注／仕掛',
      displayField: 'contract_view_progress',
      sortField: 'contract_view_progress',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'contract_view_completed',
      text: '完成／売上',
      displayField: 'contract_view_completed',
      sortField: 'contract_view_completed',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }],
  }, {
    id: 'editProject',
    text: '編集権限（プロジェクト）',
    align: 'center',
    columns: [{
      id: 'create',
      text: '追加',
      displayField: 'contract_editProject_create',
      sortField: 'contract_editProject_create',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'update',
      text: '修正',
      displayField: 'contract_editProject_update',
      sortField: 'contract_editProject_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'contract_editProject_delete',
      sortField: 'contract_editProject_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }],
  }, {
    id: 'editContract',
    text: '編集権限（案件）',
    align: 'center',
    columns: [{
      id: 'information',
      text: '情報',
      displayField: 'contract_editContract_information',
      sortField: 'contract_editContract_information',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'create',
      text: '追加',
      displayField: 'contract_editContract_create',
      sortField: 'contract_editContract_create',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'update',
      text: '修正',
      displayField: 'contract_editContract_update',
      sortField: 'contract_editContract_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'contract_editContract_delete',
      sortField: 'contract_editContract_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'accept',
      text: '受注',
      displayField: 'contract_editContract_accept',
      sortField: 'contract_editContract_accept',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'complete',
      text: '完成',
      displayField: 'contract_editContract_complete',
      sortField: 'contract_editContract_complete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'lostOrder',
      text: '失注',
      displayField: 'contract_editContract_lostOrder',
      sortField: 'contract_editContract_lostOrder',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'acceptCancel',
      text: '受注取消',
      displayField: 'contract_editContract_acceptCancel',
      sortField: 'contract_editContract_acceptCancel',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'completeCancel',
      text: '完成取消',
      displayField: 'contract_editContract_completeCancel',
      sortField: 'contract_editContract_completeCancel',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'lostOrderCancel',
      text: '失注取消',
      displayField: 'contract_editContract_lostOrderCancel',
      sortField: 'contract_editContract_lostOrderCancel',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }],
  }],
}, {
  id: 'estimate',
  text: constTable.columnLabel.get('estimate'),
  align: 'center',
  columnCount: 12,
  groups: [{
    id: 'view',
    text: '表示権限',
    align: 'center',
    columns: [{
      id: 'general',
      text: '見積一覧',
      displayField: 'estimate_view_general',
      sortField: 'estimate_view_general',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'draft',
      text: 'ドラフト',
      displayField: 'estimate_view_draft',
      sortField: 'estimate_view_draft',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'waiting',
      text: '受注待ち',
      displayField: 'estimate_view_waiting',
      sortField: 'estimate_view_waiting',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }],
  }, {
    id: 'edit',
    text: '編集権限',
    align: 'center',
    columns: [{
      id: 'information',
      text: '情報',
      displayField: 'estimate_edit_information',
      sortField: 'estimate_edit_information',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'create',
      text: '追加',
      displayField: 'estimate_edit_create',
      sortField: 'estimate_edit_create',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'copy',
      text: '複製',
      displayField: 'estimate_edit_copy',
      sortField: 'estimate_edit_copy',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'update',
      text: '修正',
      displayField: 'estimate_edit_update',
      sortField: 'estimate_edit_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'estimate_edit_delete',
      sortField: 'estimate_edit_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'attach',
      text: '添付',
      displayField: 'estimate_edit_attach',
      sortField: 'estimate_edit_attach',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'issue',
      text: '発行',
      displayField: 'estimate_edit_issue',
      sortField: 'estimate_edit_issue',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'send',
      text: '送付',
      displayField: 'estimate_edit_send',
      sortField: 'estimate_edit_send',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'cancel',
      text: '送付取消',
      displayField: 'estimate_edit_cancel',
      sortField: 'estimate_edit_cancel',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }],
  }],
}, {
  id: 'bill',
  text: constTable.columnLabel.get('bill'),
  align: 'center',
  columnCount: 13,
  groups: [{
    id: 'view',
    text: '表示権限',
    align: 'center',
    columns: [{
      id: 'general',
      text: '請求一覧',
      displayField: 'bill_view_general',
      sortField: 'bill_view_general',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'draft',
      text: 'ドラフト',
      displayField: 'bill_view_draft',
      sortField: 'bill_view_draft',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'noInvoice',
      text: '未作成案件',
      displayField: 'bill_view_noInvoice',
      sortField: 'bill_view_noInvoice',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }],
  }, {
    id: 'edit',
    text: '編集権限',
    align: 'center',
    columns: [{
      id: 'information',
      text: '情報',
      displayField: 'bill_edit_information',
      sortField: 'bill_edit_information',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'individual',
      text: '個別請求',
      displayField: 'bill_edit_individual',
      sortField: 'bill_edit_individual',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'lumpSum',
      text: '一括請求',
      displayField: 'bill_edit_lumpSum',
      sortField: 'bill_edit_lumpSum',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'installment',
      text: '分割請求',
      displayField: 'bill_edit_installment',
      sortField: 'bill_edit_installment',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'update',
      text: '修正',
      displayField: 'bill_edit_update',
      sortField: 'bill_edit_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'bill_edit_delete',
      sortField: 'bill_edit_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'apploval',
      text: '承認依頼',
      displayField: 'bill_edit_apploval',
      sortField: 'bill_edit_apploval',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'issue',
      text: '発行',
      displayField: 'bill_edit_issue',
      sortField: 'bill_edit_issue',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'send',
      text: '送付',
      displayField: 'bill_edit_send',
      sortField: 'bill_edit_send',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'cancel',
      text: '送付取消',
      displayField: 'bill_edit_cancel',
      sortField: 'bill_edit_cancel',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }],
  }],
}, {
  id: 'deposit',
  text: constTable.columnLabel.get('deposit'),
  align: 'center',
  columnCount: 5,
  groups: [{
    id: 'general',
    text: '入金一覧',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'deposit_general_view',
      sortField: 'deposit_general_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'create',
      text: '追加',
      displayField: 'deposit_general_create',
      sortField: 'deposit_general_create',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'update',
      text: '修正',
      displayField: 'deposit_general_update',
      sortField: 'deposit_general_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'deposit_general_delete',
      sortField: 'deposit_general_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }],
  }, {
    id: 'balance',
    text: '売掛残',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'deposit_balance_view',
      sortField: 'deposit_balance_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }],
  }],
}, {
  id: 'purchase',
  text: constTable.columnLabel.get('purchase'),
  align: 'center',
  columnCount: 11,
  groups: [{
    id: 'view',
    text: '表示権限',
    align: 'center',
    columns: [{
      id: 'general',
      text: '発注一覧',
      displayField: 'purchase_view_general',
      sortField: 'purchase_view_general',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'draft',
      text: 'ドラフト',
      displayField: 'purchase_view_draft',
      sortField: 'purchase_view_draft',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'backlog',
      text: '発注残',
      displayField: 'purchase_view_backlog',
      sortField: 'purchase_view_backlog',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }],
  }, {
    id: 'edit',
    text: '編集権限',
    align: 'center',
    columns: [{
      id: 'information',
      text: '情報',
      displayField: 'purchase_edit_information',
      sortField: 'purchase_edit_information',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'create',
      text: '追加',
      displayField: 'purchase_edit_create',
      sortField: 'purchase_edit_create',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'update',
      text: '修正',
      displayField: 'purchase_edit_update',
      sortField: 'purchase_edit_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'purchase_edit_delete',
      sortField: 'purchase_edit_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'issue',
      text: '発行',
      displayField: 'purchase_edit_issue',
      sortField: 'purchase_edit_issue',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'order',
      text: '発注',
      displayField: 'purchase_edit_order',
      sortField: 'purchase_edit_order',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'orderCancel',
      text: '発注取消',
      displayField: 'purchase_edit_orderCancel',
      sortField: 'purchase_edit_orderCancel',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'complete',
      text: '完納',
      displayField: 'purchase_edit_complete',
      sortField: 'purchase_edit_complete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }],
  }],
}, {
  id: 'purchaseDelivery',
  text: constTable.columnLabel.get('purchaseDelivery'),
  align: 'center',
  columnCount: 6,
  groups: [{
    id: 'general',
    text: '仕入一覧',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'purchaseDelivery_general_view',
      sortField: 'purchaseDelivery_general_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'create',
      text: '追加',
      displayField: 'purchaseDelivery_general_create',
      sortField: 'purchaseDelivery_general_create',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'update',
      text: '修正',
      displayField: 'purchaseDelivery_general_update',
      sortField: 'purchaseDelivery_general_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'purchaseDelivery_general_delete',
      sortField: 'purchaseDelivery_general_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }, {
      id: 'attach',
      text: '添付',
      displayField: 'purchaseDelivery_general_attach',
      sortField: 'purchaseDelivery_general_attach',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }],
  }, {
    id: 'total',
    text: '仕入集計',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'purchaseDelivery_total_view',
      sortField: 'purchaseDelivery_total_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },    
    }],
  }],
}, {
  id: 'payment',
  text: constTable.columnLabel.get('payment'),
  align: 'center',
  columnCount: 5,
  groups: [{
    id: 'general',
    text: '支払一覧',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'payment_general_view',
      sortField: 'payment_general_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'create',
      text: '追加',
      displayField: 'payment_general_create',
      sortField: 'payment_general_create',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'update',
      text: '修正',
      displayField: 'payment_general_update',
      sortField: 'payment_general_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'payment_general_delete',
      sortField: 'payment_general_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }, {
    id: 'balance',
    text: '買掛残',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'payment_balance_view',
      sortField: 'payment_balance_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }],
}, {
  id: 'budget',
  text: constTable.columnLabel.get('budget'),
  align: 'center',
  columnCount: 2,
  groups: [{
    id: 'general',
    text: '予算一覧',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'budget_general_view',
      sortField: 'budget_general_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }, {
    id: 'unfinished',
    text: '未成工事原価集計',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'budget_unfinished_view',
      sortField: 'budget_unfinished_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }],
}, {
  id: 'journal',
  text: constTable.columnLabel.get('journal'),
  align: 'center',
  columnCount: 4,
  groups: [{
    id: 'sales',
    text: '売上一覧',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'journal_sales_view',
      sortField: 'journal_sales_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'update',
      text: '修正',
      displayField: 'journal_sales_update',
      sortField: 'journal_sales_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }, {
    id: 'purchase',
    text: '仕入一覧',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'journal_purchase_view',
      sortField: 'journal_purchase_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'update',
      text: '修正',
      displayField: 'journal_purchase_update',
      sortField: 'journal_purchase_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }],
}, {
  id: 'approval',
  text: constTable.columnLabel.get('approval'),
  align: 'center',
  columnCount: 2,
  groups: [{
    id: 'general',
    text: '稟議一覧',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'approval_general_view',
      sortField: 'approval_general_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }, {
    id: 'routing',
    text: '回覧中',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'approval_routing_view',
      sortField: 'approval_routing_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }],
}, {
  id: 'audit',
  text: constTable.columnLabel.get('audit'),
  align: 'center',
  columnCount: 1,
  groups: [{
    id: 'general',
    text: '監査一覧',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'audit_general_view',
      sortField: 'audit_general_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }],
}, {
  id: 'role',
  text: constTable.columnLabel.get('role'),
  align: 'center',
  columnCount: 8,
  groups: [{
    id: 'general',
    text: 'ロール一覧',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'role_general_view',
      sortField: 'role_general_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'create',
      text: '追加',
      displayField: 'role_general_create',
      sortField: 'role_general_create',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'update',
      text: '修正',
      displayField: 'role_general_update',
      sortField: 'role_general_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'role_general_delete',
      sortField: 'role_general_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }, {
    id: 'group',
    text: 'グループ設定',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'role_group_view',
      sortField: 'role_group_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'create',
      text: '追加',
      displayField: 'role_group_create',
      sortField: 'role_group_create',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'update',
      text: '修正',
      displayField: 'role_group_update',
      sortField: 'role_group_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'role_group_delete',
      sortField: 'role_group_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }],
}, {
  id: 'master',
  text: constTable.columnLabel.get('master'),
  align: 'center',
  columnCount: 48,
  groups: [{
    id: 'employee',
    text: '社員',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'master_employee_view',
      sortField: 'master_employee_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'create',
      text: '追加',
      displayField: 'master_employee_create',
      sortField: 'master_employee_create',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'update',
      text: '修正',
      displayField: 'master_employee_update',
      sortField: 'master_employee_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'master_employee_delete',
      sortField: 'master_employee_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }, {
    id: 'client',
    text: '発注者',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'master_client_view',
      sortField: 'master_client_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'create',
      text: '追加',
      displayField: 'master_client_create',
      sortField: 'master_client_create',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'update',
      text: '修正',
      displayField: 'master_client_update',
      sortField: 'master_client_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'master_client_delete',
      sortField: 'master_client_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }, {
    id: 'supplier',
    text: '業者',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'master_supplier_view',
      sortField: 'master_supplier_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'create',
      text: '追加',
      displayField: 'master_supplier_create',
      sortField: 'master_supplier_create',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'update',
      text: '修正',
      displayField: 'master_supplier_update',
      sortField: 'master_supplier_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'master_supplier_delete',
      sortField: 'master_supplier_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }, {
    id: 'calendar',
    text: 'カレンダー',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'master_calendar_view',
      sortField: 'master_calendar_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'create',
      text: '追加',
      displayField: 'master_calendar_create',
      sortField: 'master_calendar_create',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'update',
      text: '修正',
      displayField: 'master_calendar_update',
      sortField: 'master_calendar_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'master_calendar_delete',
      sortField: 'master_calendar_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }, {
    id: 'division',
    text: '部門',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'master_division_view',
      sortField: 'master_division_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'create',
      text: '追加',
      displayField: 'master_division_create',
      sortField: 'master_division_create',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'update',
      text: '修正',
      displayField: 'master_division_update',
      sortField: 'master_division_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'master_division_delete',
      sortField: 'master_division_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }, {
    id: 'base',
    text: '拠点',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'master_base_view',
      sortField: 'master_base_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'create',
      text: '追加',
      displayField: 'master_base_create',
      sortField: 'master_base_create',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'update',
      text: '修正',
      displayField: 'master_base_update',
      sortField: 'master_base_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'master_base_delete',
      sortField: 'master_base_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }, {
    id: 'contractCategory',
    text: '案件種別',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'master_contractCategory_view',
      sortField: 'master_contractCategory_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'create',
      text: '追加',
      displayField: 'master_contractCategory_create',
      sortField: 'master_contractCategory_create',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'update',
      text: '修正',
      displayField: 'master_contractCategory_update',
      sortField: 'master_contractCategory_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'master_contractCategory_delete',
      sortField: 'master_contractCategory_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }, {
    id: 'constructionType',
    text: '工事区分',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'master_constructionType_view',
      sortField: 'master_constructionType_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'create',
      text: '追加',
      displayField: 'master_constructionType_create',
      sortField: 'master_constructionType_create',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'update',
      text: '修正',
      displayField: 'master_constructionType_update',
      sortField: 'master_constructionType_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'master_constructionType_delete',
      sortField: 'master_constructionType_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }, {
    id: 'orderProbability',
    text: '受注確度',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'master_orderProbability_view',
      sortField: 'master_orderProbability_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'create',
      text: '追加',
      displayField: 'master_orderProbability_create',
      sortField: 'master_orderProbability_create',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'update',
      text: '修正',
      displayField: 'master_orderProbability_update',
      sortField: 'master_orderProbability_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'master_orderProbability_delete',
      sortField: 'master_orderProbability_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }, {
    id: 'account',
    text: '勘定科目',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'master_account_view',
      sortField: 'master_account_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'create',
      text: '追加',
      displayField: 'master_account_create',
      sortField: 'master_account_create',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'update',
      text: '修正',
      displayField: 'master_account_update',
      sortField: 'master_account_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'master_account_delete',
      sortField: 'master_account_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }, {
    id: 'consumptionTax',
    text: '消費税',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'master_consumptionTax_view',
      sortField: 'master_consumptionTax_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'create',
      text: '追加',
      displayField: 'master_consumptionTax_create',
      sortField: 'master_consumptionTax_create',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'update',
      text: '修正',
      displayField: 'master_consumptionTax_update',
      sortField: 'master_consumptionTax_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'master_consumptionTax_delete',
      sortField: 'master_consumptionTax_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }, {
    id: 'approvalRoute',
    text: '承認ルート',
    align: 'center',
    columns: [{
      id: 'view',
      text: '表示',
      displayField: 'master_approvalRoute_view',
      sortField: 'master_approvalRoute_view',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'create',
      text: '追加',
      displayField: 'master_approvalRoute_create',
      sortField: 'master_approvalRoute_create',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'update',
      text: '修正',
      displayField: 'master_approvalRoute_update',
      sortField: 'master_approvalRoute_update',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'delete',
      text: '削除',
      displayField: 'master_approvalRoute_delete',
      sortField: 'master_approvalRoute_delete',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }],
}, {
  id: 'mypage',
  text: constTable.columnLabel.get('mypage'),
  align: 'center',
  columnCount: 3,
  groups: [{
    id: 'permission',
    text: '表示・編集 権限',
    align: 'center',
    columns: [{
      id: 'attendance',
      text: '日報',
      displayField: 'mypage_attendance',
      sortField: 'mypage_attendance',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'approvalDrafter',
      text: '稟議【起案】',
      displayField: 'mypage_approvalDrafter',
      sortField: 'mypage_approvalDrafter',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }, {
      id: 'approvalApprover',
      text: '稟議【承認】',
      displayField: 'mypage_approvalApprover',
      sortField: 'mypage_approvalApprover',
      displayConvert: 'check',
      align: 'center',
      width: '100px',
      edit: {
        type: 'check',
      },
    }],
  }],
}]

const standardListColumns = {
  general: [
    'employee_number',
    'employee_name',
    'role_group',
    'contract',
    'estimate',
    'bill',
    'deposit',
    'purchase',
    'purchaseDelivery',
    'payment',
    'budget',
    'journal',
    'approval',
    'audit',
    'role',
    'master',
    'mypage',
  ],
}

const listIcon: ListViewIcon = {
}
const listConverter: ListViewConverter = {
  role_group: {
    '0': {
      text: '（未設定）',
      type: 'text',
    },
    '1': {
      text: '営業',
      type: 'text',
    },
    '2': {
      text: 'エンジニア',
      type: 'text',
    },
    '3': {
      text: 'システム',
      type: 'text',
    },
    '4': {
      text: '総務',
      type: 'text',
    },
    '5': {
      text: 'システム管理者',
      type: 'text',
    },
  },
}

export default function role () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'employee_number',
    order: {
      'employee_number': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
