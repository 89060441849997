export interface DbJournalDepartment {
  id: string
  base_name: string
  base_display_order: number
  division_name: string
  division_display_order: number
  code: number | null
  updated_at: string
  updated_by_name: string
  updated_by_kana: string
}

export default function journalDepartments () {
  const journalDepartments: Array<DbJournalDepartment> = [{
    id: '1',
    base_name: '郡山本社',
    base_display_order: 1,
    division_name: 'エンジニア',
    division_display_order: 1,
    code: 100,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '2',
    base_name: '郡山本社',
    base_display_order: 1,
    division_name: 'システム',
    division_display_order: 2,
    code: 500,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '3',
    base_name: '仙台',
    base_display_order: 2,
    division_name: 'エンジニア',
    division_display_order: 1,
    code: 110,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '4',
    base_name: '仙台',
    base_display_order: 2,
    division_name: 'システム',
    division_display_order: 2,
    code: 500,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '5',
    base_name: '東京',
    base_display_order: 3,
    division_name: 'エンジニア',
    division_display_order: 1,
    code: 120,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '6',
    base_name: '東京',
    base_display_order: 3,
    division_name: 'システム',
    division_display_order: 2,
    code: 500,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }]

  return {
    journalDepartments,
  }
}
