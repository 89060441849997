export const tableName = '支払'

export default function payments () {
  const itemLabel = new Map([
    [ 'paymentDate', '支払日' ],
    [ 'supplierCode', '仕入先コード' ], //id, name, kana
    [ 'supplier', '仕入先' ], //id, name, kana
    //[ 'numberOfDeposits', '月次支払回数' ],
    [ 'paymentMethod', '支払方法' ],
    //[ 'paymentMethodType', '支払方法種別' ],
    [ 'paymentAmount', '支払金額［円］' ],
    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],
  ])
  const searchLabel = new Map([
    [ 'supplierCode', '仕入先コード' ],
    [ 'supplier', '仕入先' ],
  ])
  const columnLabel = new Map([
    [ 'paymentDate', '支払日' ],
    [ 'supplierCode', '仕入先コード' ], //id, name, kana
    [ 'supplier', '仕入先' ], //id, name, kana
    //[ 'numberOfDeposits', '月次支払回数' ],
    [ 'paymentMethod', '支払方法' ],
    //[ 'paymentMethodType', '支払方法種別' ],
    [ 'paymentAmount', '支払金額［円］' ],
    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],
  ])

  const paymentMethod = new Map([
    [ 'transfer', '振込' ],
    [ 'promissoryNote', '手形' ],
  ])

  return {
    itemLabel,
    searchLabel,
    columnLabel,
    paymentMethod,
  }
}
