import { computed } from 'vue'
import { useRoute } from 'vue-router'

export interface SiteMapItem {
  id: string
  name: string
  location?: string
  hidden?: boolean
  action?: string
  type?: string
  menuItems?: SiteMapItem[]
  //tabItems?: SiteMapItem[]
  idItems?: SiteMapItem[]
}

export default function route () {
  const route = useRoute()
  const routePath = computed(() => {
    if (
      route &&
      route.path.length > 1 &&
      route.path.slice(-1) === '/'
    ) return route.path.slice(0, -1)
    return route?.path
  })
  const routeMatched = computed(() => {
    //console.log('routeMatched', route?.matched)
    return route?.matched
  })
  const routeParams = computed(() => {
    //console.log('routeParams', route?.params)
    return route?.params
  })
  const rootPath = computed(() => {
    if (!routeMatched.value || !routeMatched.value.length) return null
    return routeMatched.value[0].path
  })
  const siteMapItem = computed(() => {
    return siteMapItems.find(el => el.location === rootPath.value)
  })
  const matchedSiteMapItems = computed(() => {
    //console.log('siteMapItem.value', siteMapItem.value)
    //console.log('routeMatched.value', routeMatched.value)
    if (!siteMapItem.value || !routeMatched.value) return null
    const items: SiteMapItem[] = [ siteMapItem.value ]
    //console.log('items', items)
    if (routeMatched.value.length <= 1) return items
    let cld: SiteMapItem | undefined = siteMapItem.value
    //console.log('cld', cld)
    for (let i = 1; i < routeMatched.value.length; i++) {
      //console.log('cld, i', cld, i)
      //console.log('routeMatched.value[i]', routeMatched.value[i])
      let ncld: SiteMapItem | undefined
      if (cld.menuItems) {
        ncld = cld.menuItems.find(el => el.location === routeMatched.value[i].path)
        if (ncld) ncld = {
          type: 'menu',
          ...ncld,
        }
      }
      /*
      if (!ncld && cld.tabItems) {
        ncld = cld.tabItems.find(el => el.location === routeMatched.value[i].path)
        if (ncld) ncld = {
          type: 'tab',
          ...ncld,
        }
      }
      */
      if (!ncld && cld.idItems) {
        ncld = cld.idItems.find(el => el.location === routeMatched.value[i].path)
        if (ncld) ncld = {
          type: 'id',
          ...ncld,
        }
      }
      if (!ncld) return items
      items.push(ncld)
      cld = ncld
    }
    //console.log('items', items)
    return items
  })

  const getBreadcrumbItems = () => {
    const ret: SiteMapItem[] = []
    if (siteMapItem.value) {
      const smi = siteMapItem.value
      ret.push(smi)
      if (smi.menuItems) {
        const me = smi.menuItems.find(el => el.location === routePath.value)
        if (me) ret.push(me)
      /*
      } else if (smi.tabItems) {
        const me = smi.tabItems.find(el => el.location === routePath.value)
        if (me) ret.push(me)
      */
      } else if (smi.idItems) {
        const me = smi.idItems.find(el => el.location === routePath.value)
        if (me) ret.push(me)
      }
    }
    return ret
  }

  const siteMapItems: SiteMapItem[] = [{
    id: 'contract',
    name: '案件管理',
    location: '/contract',
    menuItems: [{
      id: 'project',
      name: 'プロジェクト',
      location: '/contract/project',
      idItems: [{
        id: 'projectId',
        name: 'プロジェクト番号： P1011',
        location: '/contract/project/:projectId',
        menuItems: [{
          id: 'purchase',
          name: '原価',
          location: '/contract/project/:projectId/purchase',
        }],
      }],
    }, {
      id: 'general',
      name: '案件一覧',
      location: '/contract/general',
    }, {
      id: 'prospect',
      name: '見込み',
      location: '/contract/prospect',
    }, {
      id: 'progress',
      name: '受注／仕掛',
      location: '/contract/progress',
    }, {
      id: 'completed',
      name: '完成／売上',
      location: '/contract/completed',
    }],
    idItems: [{
      id: 'contractId',
      name: '案件番号',
      location: '/contract/:contractId',
      menuItems: [{
        id: 'information',
        name: '案件情報',
        location: '/contract/:contractId/information',
      }, {
        id: 'plan',
        name: '予算',
        location: '/contract/:contractId/plan',
        /*
        idItems: [{
          id: 'information',
          name: '予算情報',
          location: '/contract/:contractId/plan/:planId/information',
        }],
        */
      }, {
        id: 'purchase',
        name: '原価',
        location: '/contract/:contractId/purchase',
      }, {
        id: 'estimate',
        name: '見積',
        location: '/contract/:contractId/estimate',
      }, {
        id: 'bill',
        name: '請求',
        location: '/contract/:contractId/bill',
      }, {
        id: 'attendance',
        name: '稼動表',
        location: '/contract/:contractId/attendance',
      }],
    }],
  }, {
    id: 'plan',
    name: '案件予算管理',
    location: '/plan',
    hidden: true,
    idItems: [{
      id: 'planId',
      name: '［プラン番号］',
      location: '/plan/:planId',
      menuItems: [{
        id: 'information',
        name: '予算情報',
        location: '/plan/:planId/information',
      }],
    }],
  }, {
    id: 'estimate',
    name: '見積管理',
    location: '/estimate',
    menuItems: [{
      id: 'general',
      name: '見積一覧',
      location: '/estimate/general',
    }, {
      id: 'draft',
      name: 'ドラフト',
      location: '/estimate/draft',
    }, {
      id: 'waiting',
      name: '受注待ち',
      location: '/estimate/waiting',
    }],
    idItems: [{
      id: 'estimateId',
      name: '見積番号',
      location: '/estimate/:estimateId',
      menuItems: [{
        id: 'information',
        name: '見積情報',
        location: '/estimate/:estimateId/information',
      }],
    }],
  }, {
    id: 'bill',
    name: '請求管理',
    location: '/bill',
    menuItems: [{
      id: 'general',
      name: '請求一覧',
      location: '/bill/general',
    }, {
      id: 'draft',
      name: 'ドラフト',
      location: '/bill/draft',
    }, {
      id: 'noInvoice',
      name: '未作成案件',
      location: '/bill/no_invoice',
      /*
    }, {
      id: 'accrued',
      name: '売掛一覧',
      location: '/bill/accrued',
      */
    }],
    idItems: [{
      id: 'billId',
      name: '請求番号',
      location: '/bill/:billId',
      menuItems: [{
        id: 'information',
        name: '請求情報',
        location: '/bill/:billId/information',
      }],
    }],
  }, {
    id: 'deposit',
    name: '入金管理',
    location: '/deposit',
    menuItems: [{
      id: 'general',
      name: '入金一覧',
      location: '/deposit/general',
    }, {
      id: 'balance',
      name: '売掛残',
      location: '/deposit/balance',
    }],
  }, {
    id: 'purchase',
    name: '発注管理',
    location: '/purchase',
    menuItems: [{
      id: 'general',
      name: '発注一覧',
      location: '/purchase/general',
    }, {
      id: 'draft',
      name: 'ドラフト',
      location: '/purchase/draft',
    }, {
      id: 'backlog',
      name: '発注残',
      location: '/purchase/backlog',
    }],
    idItems: [{
      id: 'purchaseId',
      name: '発注番号',
      location: '/purchase/:purchaseId',
      menuItems: [{
        id: 'information',
        name: '発注情報',
        location: '/purchase/:purchaseId/information',
      }, {
        id: 'delivery',
        name: '仕入一覧',
        location: '/purchase/:purchaseId/delivery',
      }],
    }],
  }, {
    id: 'purchaseDelivery',
    name: '仕入管理',
    location: '/purchase/delivery',
    menuItems: [{
      id: 'general',
      name: '仕入一覧',
      location: '/purchase/delivery/general',
    }, {
      id: 'total',
      name: '仕入集計',
      location: '/purchase/delivery/total',
    }],
    idItems: [{
      id: 'purchaseDeliveryId',
      name: '仕入番号',
      location: '/purchase/delivery/:purchaseDeliveryId',
      menuItems: [{
        id: 'information',
        name: '仕入情報',
        location: '/purchase/delivery/:purchaseDeliveryId/information',
      }],
    }],
  }, {
    id: 'payment',
    name: '支払管理',
    location: '/payment',
    menuItems: [{
      id: 'general',
      name: '支払一覧',
      location: '/payment/general',
    }, {
      id: 'balance',
      name: '買掛残',
      location: '/payment/balance',
    }],
  }, {
    id: 'budget',
    name: '予算管理',
    location: '/budget',
    menuItems: [{
      id: 'general',
      name: '予算一覧',
      location: '/budget/general',
    }, {
      id: 'unfinished',
      name: '未成工事原価集計',
      location: '/budget/unfinished',
    }],
  }, {
    id: 'journal',
    name: '仕訳管理',
    location: '/journal',
    menuItems: [{
      id: 'sales',
      name: '売上',
      location: '/journal/sales',
    }, {
      id: 'deposit',
      name: '入金',
      location: '/journal/deposit',
    }, {
      id: 'advanceDeposit',
      name: '完成振替入金',
      location: '/journal/advance_deposit',
    }, {
      id: 'purchase',
      name: '仕入',
      location: '/journal/purchase',
    }],
  }, {
    id: 'approval',
    name: '稟議管理',
    location: '/approval',
    menuItems: [{
      id: 'general',
      name: '稟議一覧',
      location: '/approval/general',
    }, {
      id: 'routing',
      name: '回覧中',
      location: '/approval/routing',
      /*
    }, {
      id: 'route',
      name: '承認ルート',
      location: '/approval/route',
      */
    }],
  }, {
    id: 'audit',
    name: '監査',
    location: '/audit',
  }, {
    id: 'role',
    name: 'ロール管理',
    location: '/role',
    menuItems: [{
      id: 'general',
      name: 'ロール一覧',
      location: '/role/general',
    }, {
      id: 'group',
      name: 'グループ設定',
      location: '/role/group',
    }],
  }, {
    id: 'notification',
    name: '通知設定',
    location: '/notification',
  }, {
    id: 'journalSettings',
    name: '仕訳設定',
    location: '/journal/settings',
    menuItems: [{
      id: 'sales',
      name: '売上内訳',
      location: '/journal/settings/sales',
    }, {
      id: 'deposit',
      name: '入金内訳',
      location: '/journal/settings/deposit',
    }, {
      id: 'purchase',
      name: '仕入内訳',
      location: '/journal/settings/purchase',
    }, {
      id: 'prefix',
      name: '要素内訳名接頭辞',
      location: '/journal/settings/prefix',
    }, {
      id: 'fixed',
      name: '固定値',
      location: '/journal/settings/fixed',
    }, {
      id: 'department',
      name: '部門コード',
      location: '/journal/settings/department',
    }],
  }, {
    id: 'master',
    name: 'マスター管理',
    location: '/master',
    menuItems: [{
      id: 'employee',
      name: '社員',
      location: '/master/employee',
    }, {
      id: 'client',
      name: '発注者',
      location: '/master/client',
    }, {
      id: 'supplier',
      name: '業者',
      location: '/master/supplier',
    }, {
      id: 'base',
      name: '拠点',
      location: '/master/base',
    }, {
      id: 'division',
      name: '部門',
      location: '/master/division',
    }, {
      id: 'calendar',
      name: 'カレンダー',
      location: '/master/calendar',
    }, {
      id: 'contract_category',
      name: '案件種別',
      location: '/master/contract_category',
    }, {
      id: 'construction_type',
      name: '工事区分',
      location: '/master/construction_type',
    }, {
      id: 'order_probability',
      name: '受注確度',
      location: '/master/order_probability',
      /*
    }, {
      id: 'account',
      name: '勘定科目',
      location: '/master/account',
      */
    }, {
      id: 'tax_type',
      name: '消費税区分',
      location: '/master/tax_type',
    }, {
      id: 'approval_route',
      name: '承認ルート',
      location: '/master/approval_route',
      /*
    }, {
      id: 'registration_request',
      name: 'マスタ登録依頼',
      location: '/master/registration_request',
      */
    }],
  }, {
    id: 'mypage',
    name: 'マイページ',
    location: '/mypage',
    menuItems: [{
      /*
      id: 'dashboard',
      name: 'ダッシュボード',
      location: '/mypage/dashboard',
    }, {
      id: 'notice',
      name: 'お知らせ',
      location: '/mypage/notice',
    }, {
      */
      id: 'dailyReport',
      name: '日報',
      location: '/mypage/daily_report',
    }, {
      id: 'approvalDrafter',
      name: '稟議 【起案】',
      location: '/mypage/approval/drafter',
    }, {
      id: 'approvalApprover',
      name: '稟議 【承認】',
      location: '/mypage/approval/approver',
      /*
    }, {
      id: 'approvalCirculation',
      name: '稟議 【回覧】',
      location: '/mypage/approval/circulation',
    }, {
      id: 'approvalRequest',
      name: '稟議 【承認依頼】',
      location: '/mypage/approval/request',
    }, {
      id: 'approvalResult',
      name: '稟議 【決裁済み】',
      location: '/mypage/approval/result',
      */
      /*
    }, {
      id: 'settings',
      name: '設定',
      action: 'settings',
      */
    }, {
      id: 'logout',
      name: 'ログアウト',
      action: 'logout',
    }],
  }]

  return {
    routePath,
    routeMatched,
    routeParams,
    rootPath,
    siteMapItem,
    siteMapItems,
    matchedSiteMapItems,
    getBreadcrumbItems,
  }
}
