import {
  defineComponent,
  inject,
  ref,
  reactive,
  toRefs,
  computed,
  watch,
  nextTick,
} from 'vue'
import {
  TreeViewProps,
  TreeViewNode_I,
} from '@/const/component/treeView'
import {
  ListViewProps,
  ListViewColumn,
  ListViewConverter,
} from '@/const/component/listView'
import {
  PadViewProps,
} from '@/const/component/padView'
import ConstPlanDetails from '@/const/transaction/planDetails'

const overColor = {
  exclusion: 'rgba(0,0,0,0.4)',
  delete: 'rgba(0,0,0,0.7)',
}

export default function plan () {
  const constTable = ConstPlanDetails()

  const listConverter: ListViewConverter = {
    expense_type: {
      '1': {
        text: constTable.expenseType.get('1') ?? '',
        type: 'ellipse',
        style: {
          background: 'rgb(0,140,0)',
          color: 'rgb(255,255,255)',
        },
      },
      '2': {
        text: constTable.expenseType.get('2') ?? '',
        type: 'ellipse',
        style: {
          background: 'rgb(180,140,0)',
          color: 'rgb(255,255,255)',
        },
      },
      '3': {
        text: constTable.expenseType.get('3') ?? '',
        type: 'ellipse',
        style: {
          background: 'rgb(200,100,100)',
          color: 'rgb(255,255,255)',
        },
      },
      '4': {
        text: constTable.expenseType.get('4') ?? '',
        type: 'ellipse',
        style: {
          background: 'rgb(50,50,200)',
          color: 'rgb(255,255,255)',
        },
      },
      '0': {
        text: constTable.expenseType.get('0') ?? '',
        type: 'ellipse',
        style: {
          background: 'rgb(0,40,160)',
          color: 'rgb(255,255,255)',
        },
      },
    },
  }
  const icon = {
    status: {
      ok: {
        name: 'circle-check',
        color: 'rgb(0,200,0)',
        classes: [
          '--success',
        ],
      },
      ng: {
        name: 'circle-exclamation',
        color: 'rgb(255,0,0)',
        classes: [
          '--error',
        ],
      },
      exclusion: {
        name: 'circle-minus',
        color: 'rgb(80,80,80)',
      },
      pending: {
        name: 'circle-question',
        color: 'rgb(255,120,0)',
        classes: [
          '--warning',
        ],
      },
    },
  }

  const columns: { [key: string]: ListViewColumn[] } = {
    plan: [{
      id: 'constraction',
      text: '工事',
      displayField: 'constraction_name',
      //sortField: 'constraction_name',
    }, {
      id: 'costEstimate',
      text: '原価 ／ 見積',
      align: 'center',
      columns: [{
        id: 'material',
        text: '材料費［円］',
        displayStackFields: ['material_cost', 'material_estimate'],
        //sortFields: ['material_cost', 'material_estimate'],
        displayConvert: 'numericalDelimiter',
        align: 'right',
      }, {
        id: 'outsource',
        text: '外注費［円］',
        displayStackFields: ['outsource_cost','outsource_estimate'],
        //sortFields: ['outsource_cost','outsource_estimate'],
        displayConvert: 'numericalDelimiter',
        align: 'right',
      }, {
        id: 'labor',
        text: '労務費［円］',
        displayStackFields: ['labor_cost','labor_estimate'],
        //sortFields: ['labor_cost','labor_estimate'],
        displayConvert: 'numericalDelimiter',
        align: 'right',
      }, {
        id: 'expenses',
        text: '経費［円］',
        displayStackFields: ['expenses_cost','expenses_estimate'],
        //sortFields: ['expenses_cost','expenses_estimate'],
        displayConvert: 'numericalDelimiter',
        align: 'right',
      }, {
        id: 'others',
        text: 'その他［円］',
        displayStackFields: ['others_cost','others_estimate'],
        //sortFields: ['others_cost','others_estimate'],
        displayConvert: 'numericalDelimiter',
        align: 'right',
      }],
    }],
    construction: [{
      id: 'expense_type',
      text: '経費種別',
      displayField: 'expense_type',
      //sortField: 'expense_type',
      width: '100px',
    }, {
      id: 'item_name',
      text: '名称',
      displayField: 'item_name',
      //sortField: 'item_name',
    }, {
      id: 'cost_rate',
      text: '原価率［％］',
      displayField: 'cost_rate',
      //sortField: 'cost_rate',
      displayConvert: 'numericalDelimiter',
      align: 'right',
    }, {
      id: 'costEstimate',
      text: '原価 ／ 見積',
      align: 'center',
      columns: [{
        id: 'quantity',
        text: '数量',
        displayStackFields: ['quantity_cost','quantity_estimate'],
        //sortFields: ['quantity_cost','quantity_estimate'],
        displayConvert: 'numericalDelimiter',
        align: 'right',
      }, {
        id: 'unit',
        text: '単位',
        displayStackFields: ['unit_cost','unit_estimate'],
        //sortFields: ['unit_cost','unit_estimate'],
      }, {
        id: 'unit_price',
        text: '単価［円］',
        displayStackFields: ['unit_price_cost','unit_price_estimate'],
        //sortFields: ['unit_price_cost','unit_price_estimate'],
        displayConvert: 'numericalDelimiter',
        align: 'right',
      }, {
        id: 'amount',
        text: '金額［円］',
        displayStackFields: ['amount_cost','amount_estimate'],
        //sortFields: ['amount_cost','amount_estimate'],
        displayConvert: 'numericalDelimiter',
        align: 'right',
        /*
      }, {
        id: 'amount_excluding_tax',
        text: '税抜金額［円］',
        displayStackFields: ['amount_excluding_tax_cost','amount_excluding_tax_estimate'],
        //sortFields: ['amount_excluding_tax_cost','amount_excluding_tax_estimate'],
        displayConvert: 'numericalDelimiter',
        align: 'right',
        */
      }, {
        id: 'consumption_tax_rate',
        text: '消費税率',
        displayStackFields: ['consumption_tax_rate_cost','consumption_tax_rate_estimate'],
        //sortFields: ['consumption_tax_rate_cost','consumption_tax_rate_estimate'],
        /*
      }, {
        id: 'consumption_tax_amount',
        text: '消費税額［円］',
        displayStackFields: ['consumption_tax_amount_cost','consumption_tax_amount_estimate'],
        //sortFields: ['consumption_tax_amount_cost','consumption_tax_amount_estimate'],
        displayConvert: 'numericalDelimiter',
        align: 'right',
      }, {
        id: 'amount_including_tax',
        text: '税込金額［円］',
        displayStackFields: ['amount_including_tax_cost','amount_including_tax_estimate'],
        //sortFields: ['amount_including_tax_cost','amount_including_tax_estimate'],
        displayConvert: 'numericalDelimiter',
        align: 'right',
        */
      }],
    }],
    cost: [{
      id: 'item_name',
      text: '名称',
      displayField: 'item_name',
      //sortField: 'item_name',
    }, {
      id: 'cost_rate',
      text: '原価率［％］',
      displayField: 'cost_rate',
      //sortField: 'cost_rate',
      displayConvert: 'numericalDelimiter',
      align: 'right',
    }, {
      id: 'cost_estimate',
      text: '原価 ／ 見積',
      align: 'center',
      columns: [{
        id: 'quantity',
        text: '数量',
        displayStackFields: ['quantity_cost','quantity_estimate'],
        //sortFields: ['quantity_cost','quantity_estimate'],
        displayConvert: 'numericalDelimiter',
        align: 'right',
      }, {
        id: 'unit',
        text: '単位',
        displayStackFields: ['unit_cost','unit_estimate'],
        //sortFields: ['unit_cost','unit_estimate'],
      }, {
        id: 'unit_price',
        text: '単価［円］',
        displayStackFields: ['unit_price_cost','unit_price_estimate'],
        //sortFields: ['unit_price_cost','unit_price_estimate'],
        displayConvert: 'numericalDelimiter',
        align: 'right',
        /*
      }, {
        id: 'amount_excluding_tax',
        text: '税抜金額［円］',
        displayStackFields: ['amount_excluding_tax_cost','amount_excluding_tax_estimate'],
        //sortFields: ['amount_excluding_tax_cost','amount_excluding_tax_estimate'],
        displayConvert: 'numericalDelimiter',
        align: 'right',
        */
      }, {
        id: 'amount_tax',
        text: '金額［円］',
        displayStackFields: ['amount_cost','amount_estimate'],
        //sortFields: ['amount_cost','amount_estimate'],
        displayConvert: 'numericalDelimiter',
        align: 'right',
      }, {
        id: 'consumption_tax_rate',
        text: '消費税率',
        displayStackFields: ['consumption_tax_rate_cost','consumption_tax_rate_estimate'],
        //sortFields: ['consumption_tax_rate_cost','consumption_tax_rate_estimate'],
        /*
      }, {
        id: 'consumption_tax_amount',
        text: '消費税額［円］',
        displayStackFields: ['consumption_tax_amount_cost','consumption_tax_amount_estimate'],
        //sortFields: ['consumption_tax_amount_cost','consumption_tax_amount_estimate'],
        displayConvert: 'numericalDelimiter',
        align: 'right',
      }, {
        id: 'amount_including_tax',
        text: '税込金額［円］',
        displayStackFields: ['amount_including_tax_cost','amount_including_tax_estimate'],
        //sortFields: ['amount_including_tax_cost','amount_including_tax_estimate'],
        displayConvert: 'numericalDelimiter',
        align: 'right',
        */
      }],
    }],
    item_material: [{
      id: 'supplier',
      text: '仕入先',
      displayField: 'supplier_name',
      //sortField: 'supplier_kana',
    }, {
      id: 'quotation_number',
      text: '仕入先見積番号',
      displayField: 'quotation_number',
      //sortField: 'quotation_number',
    }, {
      id: 'model_number',
      text: '型番',
      displayField: 'model_number',
      //sortField: 'model_number',
    }, {
      id: 'description',
      text: '品名／規格',
      displayField: 'description',
      //sortField: 'description',
    }, {
      id: 'quantity',
      text: '数量',
      displayField: 'quantity',
      //sortField: 'quantity',
      displayConvert: 'numericalDelimiter',
      align: 'right',
    }, {
      id: 'unit',
      text: '単位',
      displayField: 'unit',
      //sortField: 'unit',
    }, {
      id: 'unit_price',
      text: '単価［円］',
      displayField: 'unit_price',
      //sortField: 'unit_price',
      displayConvert: 'numericalDelimiter',
      align: 'right',
      /*
    }, {
      id: 'amount_excluding_tax',
      text: '税抜金額［円］',
      displayField: 'amount_excluding_tax',
      //sortField: 'amount_excluding_tax',
      displayConvert: 'numericalDelimiter',
      align: 'right',
      */
    }, {
      id: 'amount',
      text: '金額［円］',
      displayField: 'amount',
      //sortField: 'amount',
      displayConvert: 'numericalDelimiter',
      align: 'right',
    }, {
      id: 'consumption_tax_rate',
      text: '消費税率',
      displayField: 'consumption_tax_rate',
      //sortField: 'consumption_tax_rate',
      /*
    }, {
      id: 'consumption_tax_amount',
      text: '消費税額［円］',
      displayField: 'consumption_tax_amount',
      //sortField: 'consumption_tax_amount',
      displayConvert: 'numericalDelimiter',
      align: 'right',
    }, {
      id: 'amount_including_tax',
      text: '税込金額［円］',
      displayField: 'amount_including_tax',
      //sortField: 'amount_including_tax',
      displayConvert: 'numericalDelimiter',
      align: 'right',
      */
    }],
    item_outsource: [{
      id: 'supplier',
      text: '外注先',
      displayField: 'supplier_name',
      //sortField: 'supplier_kana',
    }, {
      id: 'quotation_number',
      text: '外注先見積番号',
      displayField: 'quotation_number',
      //sortField: 'quotation_number',
    }, {
      id: 'model_number',
      text: '型番',
      displayField: 'model_number',
      //sortField: 'model_number',
    }, {
      id: 'description',
      text: '品名／規格',
      displayField: 'description',
      //sortField: 'description',
    }, {
      id: 'quantity',
      text: '数量',
      displayField: 'quantity',
      //sortField: 'quantity',
      displayConvert: 'numericalDelimiter',
      align: 'right',
    }, {
      id: 'unit',
      text: '単位',
      displayField: 'unit',
      //sortField: 'unit',
    }, {
      id: 'unit_price',
      text: '単価［円］',
      displayField: 'unit_price',
      //sortField: 'unit_price',
      displayConvert: 'numericalDelimiter',
      align: 'right',
      /*
    }, {
      id: 'amount_excluding_tax',
      text: '税抜金額［円］',
      displayField: 'amount_excluding_tax',
      //sortField: 'amount_excluding_tax',
      displayConvert: 'numericalDelimiter',
      align: 'right',
      */
    }, {
      id: 'amount',
      text: '金額［円］',
      displayField: 'amount',
      //sortField: 'amount',
      displayConvert: 'numericalDelimiter',
      align: 'right',
      /*
    }, {
      id: 'consumption_tax_rate',
      text: '消費税率［％］',
      displayField: 'consumption_tax_rate',
      //sortField: 'consumption_tax_rate',
      displayConvert: 'numericalDelimiter',
      align: 'right',
    }, {
      id: 'consumption_tax_amount',
      text: '消費税額［円］',
      displayField: 'consumption_tax_amount',
      //sortField: 'consumption_tax_amount',
      displayConvert: 'numericalDelimiter',
      align: 'right',
    }, {
      id: 'amount_including_tax',
      text: '税込金額［円］',
      displayField: 'amount_including_tax',
      //sortField: 'amount_including_tax',
      displayConvert: 'numericalDelimiter',
      align: 'right',
      */
    }],
    item_labor: [{
      id: 'detail_name',
      text: '細目',
      displayField: 'detail_name',
      //sortField: 'detail_name',
    }, {
      id: 'quantity',
      text: '数量',
      displayField: 'quantity',
      //sortField: 'quantity',
      displayConvert: 'decimalDelimiter',
      align: 'right',
    }, {
      id: 'production_rate',
      text: '歩掛［人工］',
      displayField: 'production_rate',
      //sortField: 'production_rate',
      displayConvert: 'decimalDelimiter',
      align: 'right',
    }, {
      id: 'labor_unit_price',
      text: '労務単価［円／時］',
      displayField: 'labor_unit_price',
      //sortField: 'labor_unit_price',
      displayConvert: 'numericalDelimiter',
      align: 'right',
    }, {
      id: 'holiday_premium',
      text: '休日割増率',
      displayField: 'holiday_premium',
      //sortField: 'holiday_premium',
      displayConvert: 'decimalDelimiter',
      align: 'right',
    }, {
      id: 'holiday_work_rate',
      text: '休出率［％］',
      displayField: 'holiday_work_rate',
      //sortField: 'holiday_work_rate',
      displayConvert: 'numericalDelimiter',
      align: 'right',
    }, {
      id: 'amount',
      text: '金額［円］',
      displayField: 'amount',
      //sortField: 'amount',
      displayConvert: 'numericalDelimiter',
      align: 'right',
    }],
    item_expenses: [{
      id: 'supplier',
      text: '支払先',
      displayField: 'supplier_name',
      //sortField: 'supplier_kana',
    }, {
      id: 'quotation_number',
      text: '支払先見積番号',
      displayField: 'quotation_number',
      //sortField: 'quotation_number',
    }, {
      id: 'model_number',
      text: '型番',
      displayField: 'model_number',
      //sortField: 'model_number',
    }, {
      id: 'description',
      text: '品名／規格',
      displayField: 'description',
      //sortField: 'description',
    }, {
      id: 'quantity',
      text: '数量',
      displayField: 'quantity',
      //sortField: 'quantity',
      displayConvert: 'numericalDelimiter',
      align: 'right',
    }, {
      id: 'unit',
      text: '単位',
      displayField: 'unit',
      //sortField: 'unit',
    }, {
      id: 'unit_price',
      text: '単価［円］',
      displayField: 'unit_price',
      //sortField: 'unit_price',
      displayConvert: 'numericalDelimiter',
      align: 'right',
    }, {
      id: 'amount',
      text: '金額［円］',
      displayField: 'amount',
      //sortField: 'amount_excluding_tax',
      displayConvert: 'numericalDelimiter',
      align: 'right',
      /*
    }, {
      id: 'amount_excluding_tax',
      text: '税抜金額［円］',
      displayField: 'amount_excluding_tax',
      //sortField: 'amount_excluding_tax',
      displayConvert: 'numericalDelimiter',
      align: 'right',
      */
    }, {
      id: 'consumption_tax_rate',
      text: '消費税率',
      displayField: 'consumption_tax_rate',
      //sortField: 'consumption_tax_rate',
      /*
    }, {
      id: 'consumption_tax_amount',
      text: '消費税額［円］',
      displayField: 'consumption_tax_amount',
      //sortField: 'consumption_tax_amount',
      displayConvert: 'numericalDelimiter',
      align: 'right',
    }, {
      id: 'amount_including_tax',
      text: '税込金額［円］',
      displayField: 'amount_including_tax',
      //sortField: 'amount_including_tax',
      displayConvert: 'numericalDelimiter',
      align: 'right',
      */
    }],
  }

  const list = reactive<{ [ key: string ]: ListViewProps }>({
    plan1: {
      icon: icon.status,
      columns: columns.plan,
      rows: [{
        id: '1-1',
        constraction_name: '電気工事',
        material_cost: 969000,
        material_estimate: 1292000,
        outsource_cost: 200000,
        outsource_estimate: 333400,
        expenses_cost: 0,
        expenses_estimate: 0,
        labor_cost: 1154560,
        labor_estimate: 2101387,
        others_cost: 0,
        others_estimate: 0,
        icon: 'ng',
      }, {
        id: '1-2',
        constraction_name: 'LAN設備工事',
        material_cost: 1217390,
        material_estimate: 1528504,
        outsource_cost: 0,
        outsource_estimate: 0,
        labor_cost: 1712840,
        labor_estimate: 3228334,
        expenses_cost: 150000,
        expenses_estimate: 150000,
        others_cost: 0,
        others_estimate: 0,
        icon: 'ok',
      }],
    },
    plan1_construction1: {
      converter: listConverter,
      icon: icon.status,
      columns: columns.construction,
      rows: [{
        id: '1-1-1-1',
        expense_type: '1',
        item_name: '600V VVF',
        cost_rate: 75,
        quantity_cost: 100,
        quantity_estimate: 100,
        unit_cost: '巻',
        unit_estimate: '巻',
        unit_price_cost: 9690,
        unit_price_estimate: 12920,
        consumption_tax_rate_cost: '外税 10％',
        consumption_tax_rate_estimate: '外税 10％',
        /*
        consumption_tax_amount_cost: 9690,
        consumption_tax_amount_estimate: 129200,
        */
        amount_cost: 969000,
        amount_estimate: 1292000,
        /*
        amount_excluding_tax_cost: 969000,
        amount_excluding_tax_estimate: 1292000,
        amount_including_tax_cost: 1065900,
        amount_including_tax_estimate: 1421200,
        */
        icon: 'ng',
      }, {
        id: '1-1-2-1',
        expense_type: '2',
        item_name: 'コネクタ加工',
        cost_rate: 60,
        quantity_cost: 200,
        quantity_estimate: 200,
        unit_cost: '式',
        unit_estimate: '式',
        unit_price_cost: 1000,
        unit_price_estimate: 1667,
        consumption_tax_rate_cost: '外税 10％',
        consumption_tax_rate_estimate: '外税 10％',
        /*
        consumption_tax_amount_cost: 20000,
        consumption_tax_amount_estimate: 33340,
        */
        amount_cost: 200000,
        amount_estimate: 333400,
        /*
        amount_excluding_tax_cost: 200000,
        amount_excluding_tax_estimate: 333400,
        amount_including_tax_cost: 220000,
        amount_including_tax_estimate: 366740,
        */
        icon: 'ok',
      }, {
        id: '1-1-3-1',
        expense_type: '4',
        item_name: 'ケーブル配線作業費',
        cost_rate: 50,
        quantity_cost: 1,
        quantity_estimate: 1,
        unit_cost: '式',
        unit_estimate: '式',
        unit_price_cost: 531360,
        unit_price_estimate: 1062720,
        consumption_tax_rate_cost: '外税 10％',
        consumption_tax_rate_estimate: '外税 10％',
        amount_cost: 531360,
        amount_estimate: 1062720,
        /*
        consumption_tax_amount_cost: 53136,
        consumption_tax_amount_estimate: 106272,
        amount_excluding_tax_cost: 531360,
        amount_excluding_tax_estimate: 1062720,
        amount_including_tax_cost: 584496,
        amount_including_tax_estimate: 1168992,
        */
        icon: 'ok',
      }, {
        id: '1-1-3-2',
        expense_type: '4',
        item_name: '試験調整費',
        cost_rate: 60,
        quantity_cost: 1,
        quantity_estimate: 1,
        unit_cost: '式',
        unit_estimate: '式',
        unit_price_cost: 623200,
        unit_price_estimate: 1038667,
        consumption_tax_rate_cost: '外税 10％',
        consumption_tax_rate_estimate: '外税 10％',
        amount_cost: 623200,
        amount_estimate: 1038667,
        /*
        consumption_tax_amount_cost: 62320,
        consumption_tax_amount_estimate: 103866,
        amount_excluding_tax_cost: 623200,
        amount_excluding_tax_estimate: 1038667,
        amount_including_tax_cost: 685520,
        amount_including_tax_estimate: 1142533,
        */
        icon: 'ok',
      }],
    },
    plan1_construction1_cost1: {
      icon: icon.status,
      columns: columns.cost,
      rows: [{
        id: '1-1-1-1',
        item_name: '600V VVF',
        cost_rate: 75,
        quantity_cost: 100,
        quantity_estimate: 100,
        unit_cost: '巻',
        unit_estimate: '巻',
        unit_price_cost: 9690,
        unit_price_estimate: 12920,
        consumption_tax_rate_cost: '外税 10％',
        consumption_tax_rate_estimate: '外税 10％',
        amount_cost: 969000,
        amount_estimate: 1292000,
        /*
        consumption_tax_amount_cost: 9690,
        consumption_tax_amount_estimate: 129200,
        amount_excluding_tax_cost: 969000,
        amount_excluding_tax_estimate: 1292000,
        amount_including_tax_cost: 1065900,
        amount_including_tax_estimate: 1421200,
        */
        icon: 'ng',
      }],
    },
    plan1_construction1_cost1_item1: {
      icon: icon.status,
      columns: columns.item_material,
      rows: [{
        id: '1-1-1-1-1',
        supplier_name: '仕入先Ａ',
        supplier_kana: 'シイレサキA',
        quotation_number: '1234-01',
        model_number: 'VVFケーブル2X2.0mm',
        description: '導体径2mm 1巻(50m)',
        quantity: 100,
        unit: '巻',
        unit_price: 9690,
        consumption_tax_rate: '外税 10％',
        amount: 969000,
        /*
        consumption_tax_amount: 96900,
        amount_excluding_tax: 969000,
        amount_including_tax: 1065900,
        */
        icon: 'ok',
      }, {
        id: '1-1-1-1-2',
        supplier_name: '仕入先Ｂ',
        supplier_kana: 'シイレサキB',
        quotation_number: 'ABC0103',
        model_number: 'VVF2.0mm×2C',
        description: '電線 VVFケーブル 2.0mm×2芯 100m',
        quantity: 50,
        unit: '巻',
        unit_price: 13170,
        consumption_tax_rate: '外税 10％',
        amount: 658500,
        /*
        consumption_tax_amount: 65850,
        amount_excluding_tax: 658500,
        amount_including_tax: 724350,
        */
        icon: 'pending',
      }, {
        id: '1-1-1-1-3',
        supplier_name: '仕入先Ｃ',
        supplier_kana: 'シイレサキC',
        quotation_number: 'A98701',
        model_number: '57039825',
        description: '導体径2mm 1巻(100m)',
        quantity: 50,
        unit: '巻',
        unit_price: 19900,
        consumption_tax_rate: '外税 10％',
        amount: 995000,
        /*
        consumption_tax_amount: 99500,
        amount_excluding_tax: 995000,
        amount_including_tax: 1094500,
        */
        icon: 'pending',
      }],
    },
    plan1_construction1_cost2: {
      icon: icon.status,
      columns: columns.cost,
      rows: [{
        id: '1-1-2-1',
        item_name: 'コネクタ加工',
        cost_rate: 60,
        quantity_cost: 200,
        quantity_estimate: 200,
        unit_cost: '式',
        unit_estimate: '式',
        unit_price_cost: 1000,
        unit_price_estimate: 1667,
        consumption_tax_rate_cost: '外税 10％',
        consumption_tax_rate_estimate: '外税 10％',
        amount_cost: 200000,
        amount_estimate: 333400,
        /*
        consumption_tax_amount_cost: 20000,
        consumption_tax_amount_estimate: 33340,
        amount_excluding_tax_cost: 200000,
        amount_excluding_tax_estimate: 333400,
        amount_including_tax_cost: 220000,
        amount_including_tax_estimate: 366740,
        */
        icon: 'ok',
      }],
    },
    plan1_construction1_cost2_item1: {
      icon: icon.status,
      columns: columns.item_outsource,
      rows: [{
        id: '1-1-2-1-1',
        supplier_name: '外注先Ａ',
        supplier_kana: 'ガイチュウサキA',
        quotation_number: '123-ABC',
        model_number: '',
        description: '特殊コネクタ加工',
        quantity: 200,
        unit: '個',
        unit_price: 1000,
        consumption_tax_rate: '外税 10％',
        amount: 200000,
        /*
        consumption_tax_amount: 20000,
        amount_excluding_tax: 200000,
        amount_including_tax: 220000,
        */
        icon: 'ok',
      }],
    },
    plan1_construction1_cost3: {
      icon: icon.status,
      columns: columns.cost,
      rows: [{
        id: '1-1-3-1',
        item_name: 'ケーブル配線作業費',
        cost_rate: 50,
        quantity_cost: 1,
        quantity_estimate: 1,
        unit_cost: '式',
        unit_estimate: '式',
        unit_price_cost: 531360,
        unit_price_estimate: 1062720,
        consumption_tax_rate_cost: '外税 10％',
        consumption_tax_rate_estimate: '外税 10％',
        amount_cost: 531360,
        amount_estimate: 1062720,
        /*
        consumption_tax_amount_cost: 53136,
        consumption_tax_amount_estimate: 106272,
        amount_excluding_tax_cost: 531360,
        amount_excluding_tax_estimate: 1062720,
        amount_including_tax_cost: 584496,
        amount_including_tax_estimate: 1168992,
        */
        icon: 'ok',
      }, {
        id: '1-1-3-2',
        item_name: '試験調整費',
        cost_rate: 60,
        quantity_cost: 1,
        quantity_estimate: 1,
        unit_cost: '式',
        unit_estimate: '式',
        unit_price_cost: 623200,
        unit_price_estimate: 1038667,
        consumption_tax_rate_cost: '外税 10％',
        consumption_tax_rate_estimate: '外税 10％',
        amount_cost: 623200,
        amount_estimate: 1038667,
        /*
        consumption_tax_amount_cost: 62320,
        consumption_tax_amount_estimate: 103866,
        amount_excluding_tax_cost: 623200,
        amount_excluding_tax_estimate: 1038667,
        amount_including_tax_cost: 685520,
        amount_including_tax_estimate: 1142533,
        */
        icon: 'ok',
      }],
    },
    plan1_construction1_cost3_item1: {
      icon: icon.status,
      columns: columns.item_labor,
      rows: [{
        id: '1-1-3-1-1',
        detail_name: '600V VVF 配線作業費（～25m）',
        quantity: 100,
        production_rate: 0.05,
        labor_unit_price: 3600,
        holiday_premium: 1.25,
        holiday_work_rate: 10,
        amount: 147600,
        icon: 'ok',
      }, {
        id: '1-1-3-1-2',
        detail_name: '600V VVF 配線作業費（～50m）',
        quantity: 50,
        production_rate: 0.10,
        labor_unit_price: 3600,
        holiday_premium: 1.25,
        holiday_work_rate: 10,
        amount: 147600,
        icon: 'ok',
      }, {
        id: '1-1-3-1-3',
        detail_name: '600V VVF 配線作業費（～75m）',
        quantity: 30,
        production_rate: 0.15,
        labor_unit_price: 3600,
        holiday_premium: 1.25,
        holiday_work_rate: 10,
        amount: 132840,
        icon: 'exclusion',
        overColor: overColor.exclusion,
      }, {
        id: '1-1-3-1-4',
        detail_name: '600V VVF 配線作業費（～100m）',
        quantity: 10,
        production_rate: 0.20,
        labor_unit_price: 3600,
        holiday_premium: 1.25,
        holiday_work_rate: 10,
        amount: 59040,
        icon: 'exclusion',
        overColor: overColor.exclusion,
      }, {
        id: '1-1-3-1-5',
        detail_name: '600V VVF 配線作業費（～100m）',
        quantity: 40,
        production_rate: 0.20,
        labor_unit_price: 3600,
        holiday_premium: 1.25,
        holiday_work_rate: 10,
        amount: 236160,
        icon: 'ok',
      }],
    },
    plan1_construction1_cost3_item2: {
      icon: icon.status,
      columns: columns.item_labor,
      rows: [{
        id: '1-1-3-2-1',
        detail_name: 'ケーブル試験調整費',
        quantity: 190,
        production_rate: 0.10,
        labor_unit_price: 4000,
        holiday_premium: 1.25,
        holiday_work_rate: 10,
        amount: 623200,
        icon: 'ok',
      }],
    },
    plan1_construction2: {
      converter: listConverter,
      icon: icon.status,
      columns: columns.construction,
      rows: [{
        id: '1-2-1-1',
        expense_type: '1',
        item_name: 'LANケーブル\ncat6A',
        cost_rate: 80,
        quantity_cost: 40,
        quantity_estimate: 40,
        unit_cost: '巻',
        unit_estimate: '巻',
        unit_price_cost: 30030,
        unit_price_estimate: 37538,
        consumption_tax_rate_cost: '外税 10％',
        consumption_tax_rate_estimate: '外税 10％',
        amount_cost: 1201200,
        amount_estimate: 1501520,
        /*
        consumption_tax_amount_cost: 120120,
        consumption_tax_amount_estimate: 150152,
        amount_excluding_tax_cost: 1201200,
        amount_excluding_tax_estimate: 1501520,
        amount_including_tax_cost: 1321320,
        amount_including_tax_estimate: 1651672,
        */
        icon: 'ok',
      }, {
        id: '1-2-1-2',
        expense_type: '1',
        item_name: 'ケーブル保護材（雑材料・消耗品含む）',
        cost_rate: 60,
        quantity_cost: 1,
        quantity_estimate: 1,
        unit_cost: '式',
        unit_estimate: '式',
        unit_price_cost: 16190,
        unit_price_estimate: 26984,
        consumption_tax_rate_cost: '外税 10％',
        consumption_tax_rate_estimate: '外税 10％',
        amount_cost: 16190,
        amount_estimate: 26984,
        /*
        consumption_tax_amount_cost: 1619,
        consumption_tax_amount_estimate: 2698,
        amount_excluding_tax_cost: 16190,
        amount_excluding_tax_estimate: 26984,
        amount_including_tax_cost: 17809,
        amount_including_tax_estimate: 29682,
        */
        icon: 'ok',
      }, {
        id: '1-2-2-1',
        expense_type: '4',
        item_name: 'ケーブル配線・成端作業費',
        cost_rate: 50,
        quantity_cost: 1,
        quantity_estimate: 1,
        unit_cost: '式',
        unit_estimate: '式',
        unit_price_cost: 943680,
        unit_price_estimate: 1887360,
        consumption_tax_rate_cost: '外税 10％',
        consumption_tax_rate_estimate: '外税 10％',
        amount_cost: 943680,
        amount_estimate: 1887360,
        /*
        consumption_tax_amount_cost: 94368,
        consumption_tax_amount_estimate: 188736,
        amount_excluding_tax_cost: 943680,
        amount_excluding_tax_estimate: 1887360,
        amount_including_tax_cost: 1038048,
        amount_including_tax_estimate: 2076096,
        */
        icon: 'ok',
      }, {
        id: '1-2-2-2',
        expense_type: '4',
        item_name: 'ＡＰ取付・調整費',
        cost_rate: 50,
        quantity_cost: 1,
        quantity_estimate: 1,
        unit_cost: '式',
        unit_estimate: '式',
        unit_price_cost: 177120,
        unit_price_estimate: 354240,
        consumption_tax_rate_cost: '外税 10％',
        consumption_tax_rate_estimate: '外税 10％',
        amount_cost: 177120,
        amount_estimate: 354240,
        /*
        consumption_tax_amount_cost: 17712,
        consumption_tax_amount_estimate: 35424,
        amount_excluding_tax_cost: 177120,
        amount_excluding_tax_estimate: 354240,
        amount_including_tax_cost: 194832,
        amount_including_tax_estimate: 389664,
        */
        icon: 'ok',
      }, {
        id: '1-2-2-3',
        expense_type: '4',
        item_name: '試験調整費',
        cost_rate: 60,
        quantity_cost: 1,
        quantity_estimate: 1,
        unit_cost: '式',
        unit_estimate: '式',
        unit_price_cost: 592040,
        unit_price_estimate: 986734,
        consumption_tax_rate_cost: '外税 10％',
        consumption_tax_rate_estimate: '外税 10％',
        amount_cost: 592040,
        amount_estimate: 986734,
        /*
        consumption_tax_amount_cost: 59204,
        consumption_tax_amount_estimate: 98673,
        amount_excluding_tax_cost: 592040,
        amount_excluding_tax_estimate: 986734,
        amount_including_tax_cost: 651244,
        amount_including_tax_estimate: 1085407,
        */
        icon: 'ok',
      }, {
        id: '1-2-3-1',
        expense_type: '3',
        item_name: '諸経費',
        cost_rate: 100,
        quantity_cost: 1,
        quantity_estimate: 1,
        unit_cost: '式',
        unit_estimate: '式',
        unit_price_cost: 150000,
        unit_price_estimate: 150000,
        consumption_tax_rate_cost: '外税 10％',
        consumption_tax_rate_estimate: '外税 10％',
        amount_cost: 150000,
        amount_estimate: 150000,
        /*
        consumption_tax_amount_cost: 15000,
        consumption_tax_amount_estimate: 15000,
        amount_excluding_tax_cost: 150000,
        amount_excluding_tax_estimate: 150000,
        amount_including_tax_cost: 165000,
        amount_including_tax_estimate: 165000,
        */
        icon: 'ok',
      }],
    },
    plan1_construction2_cost1: {
      icon: icon.status,
      columns: columns.cost,
      rows: [{
        id: '1-2-1-1',
        item_name: 'LANケーブル\ncat6A',
        cost_rate: 80,
        quantity_cost: 40,
        quantity_estimate: 40,
        unit_cost: '巻',
        unit_estimate: '巻',
        unit_price_cost: 30030,
        unit_price_estimate: 37538,
        consumption_tax_rate_cost: '外税 10％',
        consumption_tax_rate_estimate: '外税 10％',
        amount_cost: 1201200,
        amount_estimate: 1501520,
        /*
        consumption_tax_amount_cost: 120120,
        consumption_tax_amount_estimate: 150152,
        amount_excluding_tax_cost: 1201200,
        amount_excluding_tax_estimate: 1501520,
        amount_including_tax_cost: 1321320,
        amount_including_tax_estimate: 1651672,
        */
        icon: 'ok',
      }, {
        id: '1-2-1-2',
        item_name: 'ケーブル保護材（雑材料・消耗品含む）',
        cost_rate: 60,
        quantity_cost: 1,
        quantity_estimate: 1,
        unit_cost: '式',
        unit_estimate: '式',
        unit_price_cost: 16190,
        unit_price_estimate: 26984,
        consumption_tax_rate_cost: '外税 10％',
        consumption_tax_rate_estimate: '外税 10％',
        amount_cost: 16190,
        amount_estimate: 26984,
        /*
        consumption_tax_amount_cost: 1619,
        consumption_tax_amount_estimate: 2698,
        amount_excluding_tax_cost: 16190,
        amount_excluding_tax_estimate: 26984,
        amount_including_tax_cost: 17809,
        amount_including_tax_estimate: 29682,
        */
        icon: 'ok',
      }],
    },
    plan1_construction2_cost1_item1: {
      icon: icon.status,
      columns: columns.item_material,
      rows: [{
        id: '1-2-1-1-1',
        supplier_name: '仕入先Ａ',
        supplier_kana: 'シイレサキA',
        quotation_number: '1234-01',
        model_number: 'MLN-UFC6A/BU300R',
        description: 'LANケーブル U/FTP (高遮蔽アルミシールド型) 300m巻 Cat.6A カテゴリー6A 10Gbps',
        quantity: 40,
        unit: '巻',
        unit_price: 26180,
        consumption_tax_rate: '外税 10％',
        amount: 1047200,
        /*
        consumption_tax_amount: 104720,
        amount_excluding_tax: 1047200,
        amount_including_tax: 1151920,
        */
        icon: 'exclusion',
        overColor: overColor.exclusion,
      }, {
        id: '1-2-1-1-2',
        supplier_name: '仕入先Ｂ',
        supplier_kana: 'シイレサキB',
        quotation_number: 'ABC0104',
        model_number: 'MLN-HFU6A/BU300R',
        description: 'LANケーブル F/UTP AWG26 細径 Cat.6A LANケーブル 300m ブルー',
        quantity: 40,
        unit: '巻',
        unit_price: 30030,
        consumption_tax_rate: '外税 10％',
        amount: 1201200,
        /*
        consumption_tax_amount: 120120,
        amount_excluding_tax: 1201200,
        amount_including_tax: 1321320,
        */
        icon: 'ok',
      }],
    },
    plan1_construction2_cost1_item2: {
      icon: icon.status,
      columns: columns.item_material,
      rows: [{
        id: '1-2-1-2-1',
        supplier_name: '仕入先Ｂ',
        supplier_kana: 'シイレサキB',
        quotation_number: 'ABC0103',
        model_number: 'CLP-S6A',
        description: 'U/FTP Cat.6Aケーブル専用 LANプラグ RJ-45 【※注意: CAT5e / CAT6/細径CAT6A に非対応】',
        quantity: 50,
        unit: '個',
        unit_price: 99,
        consumption_tax_rate: '外税 10％',
        amount: 4950,
        /*
        consumption_tax_amount: 495,
        amount_excluding_tax: 4950,
        amount_including_tax: 5445,
        */
        icon: 'exclusion',
        overColor: overColor.exclusion,
      }, {
        id: '1-2-1-2-2',
        supplier_name: '仕入先Ｂ',
        supplier_kana: 'シイレサキB',
        quotation_number: 'ABC0103',
        model_number: 'CLP-26S6ASET',
        description: '【Cat.6A】AWG26細径ケーブル用コネクタ (コネクタ+ブーツ+錫めっき銅箔テープ 100個セット)',
        quantity: 1,
        unit: '箱',
        unit_price: 9020,
        consumption_tax_rate: '外税 10％',
        amount: 9020,
        /*
        consumption_tax_amount: 902,
        amount_excluding_tax: 9020,
        amount_including_tax: 9922,
        */
        icon: 'ok',
      }, {
        id: '1-2-1-2-3',
        supplier_name: '仕入先Ｃ',
        supplier_kana: 'シイレサキC',
        quotation_number: 'D512604',
        model_number: 'CLP-26S6ASET',
        description: '【Cat.6A】AWG26細径ケーブル用コネクタ (コネクタ+ブーツ+錫めっき銅箔テープ 100個セット)',
        quantity: 10,
        unit: '個',
        unit_price: 717,
        consumption_tax_rate: '外税 10％',
        amount: 7170,
        /*
        consumption_tax_amount: 717,
        amount_excluding_tax: 7170,
        amount_including_tax: 7887,
        */
        icon: 'ok',
      }],
    },
    plan1_construction2_cost2: {
      icon: icon.status,
      columns: columns.cost,
      rows: [{
        id: '1-2-2-1',
        item_name: 'ケーブル配線・成端作業費',
        cost_rate: 50,
        quantity_cost: 1,
        quantity_estimate: 1,
        unit_cost: '式',
        unit_estimate: '式',
        unit_price_cost: 943680,
        unit_price_estimate: 1887360,
        consumption_tax_rate_cost: '外税 10％',
        consumption_tax_rate_estimate: '外税 10％',
        amount_cost: 943680,
        amount_estimate: 1887360,
        /*
        consumption_tax_amount_cost: 94368,
        consumption_tax_amount_estimate: 188736,
        amount_excluding_tax_cost: 943680,
        amount_excluding_tax_estimate: 1887360,
        amount_including_tax_cost: 1038048,
        amount_including_tax_estimate: 2076096,
        */
        icon: 'ok',
      }, {
        id: '1-2-2-2',
        item_name: 'ＡＰ取付・調整費',
        cost_rate: 50,
        quantity_cost: 1,
        quantity_estimate: 1,
        unit_cost: '式',
        unit_estimate: '式',
        unit_price_cost: 177120,
        unit_price_estimate: 354240,
        consumption_tax_rate_cost: '外税 10％',
        consumption_tax_rate_estimate: '外税 10％',
        amount_cost: 177120,
        amount_estimate: 354240,
        /*
        consumption_tax_amount_cost: 17712,
        consumption_tax_amount_estimate: 35424,
        amount_excluding_tax_cost: 177120,
        amount_excluding_tax_estimate: 354240,
        amount_including_tax_cost: 194832,
        amount_including_tax_estimate: 389664,
        */
        icon: 'ok',
      }, {
        id: '1-2-2-3',
        item_name: '試験調整費',
        cost_rate: 60,
        quantity_cost: 1,
        quantity_estimate: 1,
        unit_cost: '式',
        unit_estimate: '式',
        unit_price_cost: 592040,
        unit_price_estimate: 986734,
        consumption_tax_rate_cost: '外税 10％',
        consumption_tax_rate_estimate: '外税 10％',
        amount_cost: 592040,
        amount_estimate: 986734,
        /*
        consumption_tax_amount_cost: 59204,
        consumption_tax_amount_estimate: 98673,
        amount_excluding_tax_cost: 592040,
        amount_excluding_tax_estimate: 986734,
        amount_including_tax_cost: 651244,
        amount_including_tax_estimate: 1085407,
        */
        icon: 'ok',
      }],
    },
    plan1_construction2_cost2_item1: {
      icon: icon.status,
      columns: columns.item_labor,
      rows: [{
        id: '1-2-2-1-1',
        detail_name: 'ケーブル配線作業費（～20m）',
        quantity: 80,
        production_rate: 0.05,
        labor_unit_price: 4000,
        holiday_premium: 1.25,
        holiday_work_rate: 8,
        amount: 130560,
        icon: 'ok',
      }, {
        id: '1-2-2-1-2',
        detail_name: 'ケーブル配線作業費（～50m）',
        quantity: 40,
        production_rate: 0.15,
        labor_unit_price: 4000,
        holiday_premium: 1.25,
        holiday_work_rate: 4,
        amount: 193920,
        icon: 'ok',
      }, {
        id: '1-2-2-1-3',
        detail_name: 'ケーブル配線作業費（～100m）',
        quantity: 20,
        production_rate: 0.25,
        labor_unit_price: 4000,
        holiday_premium: 1.25,
        holiday_work_rate: 0,
        amount: 160000,
        icon: 'ok',
      }, {
        id: '1-2-2-1-4',
        detail_name: '成端作業費（RJ-45 6A シールド）',
        quantity: 280,
        production_rate: 0.05,
        labor_unit_price: 4000,
        holiday_premium: 1.25,
        holiday_work_rate: 10,
        amount: 459200,
        icon: 'ok',
      }],
    },
    plan1_construction2_cost2_item2: {
      icon: icon.status,
      columns: columns.item_labor,
      rows: [{
        id: '1-2-2-2-1',
        detail_name: 'ＡＰ取付作業費',
        quantity: 36,
        production_rate: 0.10,
        labor_unit_price: 4000,
        holiday_premium: 1.25,
        holiday_work_rate: 10,
        amount: 118080,
        icon: 'ok',
      }, {
        id: '1-2-2-2-2',
        detail_name: 'ＡＰ設定費',
        quantity: 36,
        production_rate: 0.05,
        labor_unit_price: 4000,
        holiday_premium: 1.25,
        holiday_work_rate: 10,
        amount: 59040,
        icon: 'ok',
      }],
    },
    plan1_construction2_cost2_item3: {
      icon: icon.status,
      columns: columns.item_labor,
      rows: [{
        id: '1-2-2-3-1',
        detail_name: 'ケーブル試験調整費',
        quantity: 140,
        production_rate: 0.10,
        labor_unit_price: 4000,
        holiday_premium: 1.25,
        holiday_work_rate: 10,
        amount: 459200,
        icon: 'ok',
      }, {
        id: '1-2-2-3-2',
        detail_name: 'ＡＰ試験調整費',
        quantity: 36,
        production_rate: 0.10,
        labor_unit_price: 4500,
        holiday_premium: 1.25,
        holiday_work_rate: 10,
        amount: 132840,
        icon: 'ok',
      }],
    },
    plan1_construction2_cost3: {
      icon: icon.status,
      columns: columns.cost,
      rows: [{
        id: '1-2-3-1',
        item_name: '諸経費',
        cost_rate: 100,
        quantity_cost: 1,
        quantity_estimate: 1,
        unit_cost: '式',
        unit_estimate: '式',
        unit_price_cost: 150000,
        unit_price_estimate: 150000,
        consumption_tax_rate_cost: '外税 10％',
        consumption_tax_rate_estimate: '外税 10％',
        amount_cost: 150000,
        amount_estimate: 150000,
        /*
        consumption_tax_amount_cost: 15000,
        consumption_tax_amount_estimate: 15000,
        amount_excluding_tax_cost: 150000,
        amount_excluding_tax_estimate: 150000,
        amount_including_tax_cost: 165000,
        amount_including_tax_estimate: 165000,
        */
        icon: 'ok',
      }],
    },
    plan1_construction2_cost3_item1: {
      icon: icon.status,
      columns: columns.item_expenses,
      rows: [{
        id: '1-2-3-1-1',
        supplier_name: '支払先Ａ',
        supplier_kana: 'シハライサキA',
        quotation_number: '',
        model_number: '',
        description: '法定福利費',
        quantity: 1,
        unit: '式',
        unit_price: 100000,
        consumption_tax_rate: '外税 10％',
        amount: 100000,
        icon: 'ok',
      }, {
        id: '1-2-3-1-2',
        supplier_name: '支払先Ａ',
        supplier_kana: 'シハライサキA',
        quotation_number: '',
        model_number: '',
        description: '現場管理費',
        quantity: 1,
        unit: '式',
        unit_price: 50000,
        consumption_tax_rate: '外税 10％',
        amount: 50000,
        icon: 'ok',
      }],
    },
  })

  const readonly = ref(true)
  const ro = computed(() => {
    return readonly.value
  })
  const rq = computed(() => {
    return !readonly.value
  })

  const pad = reactive<{ [ key: string ]: PadViewProps }>({
    plan1_construction1_cost1_item1_option1: {
      editItems: [{
        id: 'plan1_construction1_cost1_item1_option1',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'supplier',
            label: '仕入先',
            type: 'supplier',
            value: '仕入先Ａ',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quotation_number',
            label: '仕入先見積番号',
            type: 'text',
            value: '1234-01',
            readonly: ro.value,
            required: false,
          }, {
            id: 'model_number',
            label: '型番',
            type: 'text',
            value: 'VVFケーブル2X2.0mm',
            readonly: ro.value,
            required: false,
          }, {
            id: 'description',
            label: '品名／規格',
            type: 'text',
            value: '導体径2mm 1巻(50m)',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '100',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit',
            label: '単位',
            type: 'unit',
            value: '巻',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit_price',
            label: '単価［円］',
            type: 'text',
            value: '9,690',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '969,000',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'consumption_tax_rate',
            label: '消費税率',
            type: 'tax',
            value: '外税 10％',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction1_cost1_item1_option2: {
      editItems: [{
        id: 'plan1_construction2_cost3_item1_option1',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'supplier',
            label: '仕入先',
            type: 'supplier',
            value: '仕入先Ｂ',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quotation_number',
            label: '仕入先見積番号',
            type: 'text',
            value: 'ABC0103',
            readonly: ro.value,
            required: false,
          }, {
            id: 'model_number',
            label: '型番',
            type: 'text',
            value: 'VVF2.0mm×2C',
            readonly: ro.value,
            required: false,
          }, {
            id: 'description',
            label: '品名／規格',
            type: 'text',
            value: '電線 VVFケーブル 2.0mm×2芯 100m',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '50',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit',
            label: '単位',
            type: 'unit',
            value: '巻',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit_price',
            label: '単価［円］',
            type: 'text',
            value: '13,170',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '658,500',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'consumption_tax_rate',
            label: '消費税率',
            type: 'tax',
            value: '外税 10％',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction1_cost1_item1_option3: {
      editItems: [{
        id: 'plan1_construction2_cost3_item1_option2',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'supplier',
            label: '仕入先',
            type: 'supplier',
            value: '仕入先Ｃ',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quotation_number',
            label: '仕入先見積番号',
            type: 'text',
            value: 'A98701',
            readonly: ro.value,
            required: false,
          }, {
            id: 'model_number',
            label: '型番',
            type: 'text',
            value: '57039825',
            readonly: ro.value,
            required: false,
          }, {
            id: 'description',
            label: '品名／規格',
            type: 'text',
            value: '導体径2mm 1巻(100m)',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '50',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit',
            label: '単位',
            type: 'unit',
            value: '巻',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit_price',
            label: '単価［円］',
            type: 'text',
            value: '19,900',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '995,000',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'consumption_tax_rate',
            label: '消費税率',
            type: 'tax',
            value: '外税 10％',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction1_cost2_item1_option1: {
      editItems: [{
        id: 'plan1_construction1_cost2_item1_option1',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'supplier',
            label: '外注先',
            type: 'supplier',
            value: '外注先Ａ',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quotation_number',
            label: '外注先見積番号',
            type: 'text',
            value: '123-ABC',
            readonly: ro.value,
            required: false,
          }, {
            id: 'model_number',
            label: '型番',
            type: 'text',
            value: '',
            readonly: ro.value,
            required: false,
          }, {
            id: 'description',
            label: '品名／規格',
            type: 'text',
            value: '特殊コネクタ加工',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '200',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit',
            label: '単位',
            type: 'unit',
            value: '個',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit_price',
            label: '単価［円］',
            type: 'text',
            value: '1,000',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '200,000',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'consumption_tax_rate',
            label: '消費税率',
            type: 'tax',
            value: '外税 10％',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction1_cost3_item1_option1: {
      editItems: [{
        id: 'plan1_construction1_cost3_item1_option1',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'detail_name',
            label: '細目',
            type: 'text',
            value: '600V VVF 配線作業費（～25m）',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '100',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'production_rate',
            label: '歩掛［人工］',
            type: 'text',
            value: '0.05',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'labor_unit_price',
            label: '労務単価［円／時］',
            type: 'text',
            value: '3,600',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_premium',
            label: '休日割増率',
            type: 'text',
            value: '1.25',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_work_rate',
            label: '休出率［％］',
            type: 'text',
            value: '10',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '147,600',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction1_cost3_item1_option2: {
      editItems: [{
        id: 'plan1_construction1_cost3_item1_option2',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'detail_name',
            label: '細目',
            type: 'text',
            value: '600V VVF 配線作業費（～50m）',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '50',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'production_rate',
            label: '歩掛［人工］',
            type: 'text',
            value: '0.10',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'labor_unit_price',
            label: '労務単価［円／時］',
            type: 'text',
            value: '3,600',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_premium',
            label: '休日割増率',
            type: 'text',
            value: '1.25',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_work_rate',
            label: '休出率［％］',
            type: 'text',
            value: '10',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '147,600',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction1_cost3_item1_option3: {
      editItems: [{
        id: 'plan1_construction1_cost3_item1_option3',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'detail_name',
            label: '細目',
            type: 'text',
            value: '600V VVF 配線作業費（～75m）',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '30',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'production_rate',
            label: '歩掛［人工］',
            type: 'text',
            value: '0.15',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'labor_unit_price',
            label: '労務単価［円／時］',
            type: 'text',
            value: '3,600',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_premium',
            label: '休日割増率',
            type: 'text',
            value: '1.25',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_work_rate',
            label: '休出率［％］',
            type: 'text',
            value: '10',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '132,840',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction1_cost3_item1_option4: {
      editItems: [{
        id: 'plan1_construction1_cost3_item1_option4',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'detail_name',
            label: '細目',
            type: 'text',
            value: '600V VVF 配線作業費（～100m）',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '10',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'production_rate',
            label: '歩掛［人工］',
            type: 'text',
            value: '0.20',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'labor_unit_price',
            label: '労務単価［円／時］',
            type: 'text',
            value: '3,600',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_premium',
            label: '休日割増率',
            type: 'text',
            value: '1.25',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_work_rate',
            label: '休出率［％］',
            type: 'text',
            value: '10',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '59,040',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction1_cost3_item1_option5: {
      editItems: [{
        id: 'plan1_construction1_cost3_item1_option5',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'detail_name',
            label: '細目',
            type: 'text',
            value: '600V VVF 配線作業費（～100m）',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '40',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'production_rate',
            label: '歩掛［人工］',
            type: 'text',
            value: '0.20',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'labor_unit_price',
            label: '労務単価［円／時］',
            type: 'text',
            value: '3,600',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_premium',
            label: '休日割増率',
            type: 'text',
            value: '1.25',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_work_rate',
            label: '休出率［％］',
            type: 'text',
            value: '10',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '236,160',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction1_cost3_item2_option1: {
      editItems: [{
        id: 'plan1_construction1_cost3_item2_option1',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'detail_name',
            label: '細目',
            type: 'text',
            value: 'ケーブル試験調整費',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '190',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'production_rate',
            label: '歩掛［人工］',
            type: 'text',
            value: '0.10',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'labor_unit_price',
            label: '労務単価［円／時］',
            type: 'text',
            value: '4,000',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_premium',
            label: '休日割増率',
            type: 'text',
            value: '1.25',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_work_rate',
            label: '休出率［％］',
            type: 'text',
            value: '10',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '623,200',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction2_cost1_item1_option1: {
      editItems: [{
        id: 'plan1_construction2_cost1_item1_option1',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'supplier',
            label: '仕入先',
            type: 'supplier',
            value: '仕入先Ａ',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quotation_number',
            label: '仕入先見積番号',
            type: 'text',
            value: '1234-01',
            readonly: ro.value,
            required: false,
          }, {
            id: 'model_number',
            label: '型番',
            type: 'text',
            value: 'MLN-UFC6A/BU300R',
            readonly: ro.value,
            required: false,
          }, {
            id: 'description',
            label: '品名／規格',
            type: 'text',
            value: 'LANケーブル U/FTP (高遮蔽アルミシールド型) 300m巻 Cat.6A カテゴリー6A 10Gbps',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '40',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit',
            label: '単位',
            type: 'unit',
            value: '巻',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit_price',
            label: '単価［円］',
            type: 'text',
            value: '26,180',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '1,047,200',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'consumption_tax_rate',
            label: '消費税率',
            type: 'tax',
            value: '外税 10％',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction2_cost1_item1_option2: {
      editItems: [{
        id: 'plan1_construction2_cost1_item1_option2',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'supplier',
            label: '仕入先',
            type: 'supplier',
            value: '仕入先Ｂ',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quotation_number',
            label: '仕入先見積番号',
            type: 'text',
            value: 'ABC0104',
            readonly: ro.value,
            required: false,
          }, {
            id: 'model_number',
            label: '型番',
            type: 'text',
            value: 'MLN-HFU6A/BU300R',
            readonly: ro.value,
            required: false,
          }, {
            id: 'description',
            label: '品名／規格',
            type: 'text',
            value: 'LANケーブル F/UTP AWG26 細径 Cat.6A LANケーブル 300m ブルー',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '40',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit',
            label: '単位',
            type: 'unit',
            value: '巻',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit_price',
            label: '単価［円］',
            type: 'text',
            value: '30,030',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '1,201,200',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'consumption_tax_rate',
            label: '消費税率',
            type: 'tax',
            value: '外税 10％',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction2_cost1_item2_option1: {
      editItems: [{
        id: 'plan1_construction2_cost1_item2_option1',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'supplier',
            label: '仕入先',
            type: 'supplier',
            value: '仕入先Ｂ',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quotation_number',
            label: '仕入先見積番号',
            type: 'text',
            value: 'ABC0103',
            readonly: ro.value,
            required: false,
          }, {
            id: 'model_number',
            label: '型番',
            type: 'text',
            value: 'CLP-S6A',
            readonly: ro.value,
            required: false,
          }, {
            id: 'description',
            label: '品名／規格',
            type: 'text',
            value: 'U/FTP Cat.6Aケーブル専用 LANプラグ RJ-45 【※注意: CAT5e / CAT6/細径CAT6A に非対応】',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '50',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit',
            label: '単位',
            type: 'unit',
            value: '個',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit_price',
            label: '単価［円］',
            type: 'text',
            value: '99',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '4,950',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'consumption_tax_rate',
            label: '消費税率',
            type: 'tax',
            value: '外税 10％',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction2_cost1_item2_option2: {
      editItems: [{
        id: 'plan1_construction2_cost1_item2_option2',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'supplier',
            label: '仕入先',
            type: 'supplier',
            value: '仕入先Ｂ',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quotation_number',
            label: '仕入先見積番号',
            type: 'text',
            value: 'ABC0103',
            readonly: ro.value,
            required: false,
          }, {
            id: 'model_number',
            label: '型番',
            type: 'text',
            value: 'CLP-26S6ASET',
            readonly: ro.value,
            required: false,
          }, {
            id: 'description',
            label: '品名／規格',
            type: 'text',
            value: '【Cat.6A】AWG26細径ケーブル用コネクタ (コネクタ+ブーツ+錫めっき銅箔テープ 100個セット)',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '1',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit',
            label: '単位',
            type: 'unit',
            value: '箱',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit_price',
            label: '単価［円］',
            type: 'text',
            value: '9,020',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '9,020',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'consumption_tax_rate',
            label: '消費税率',
            type: 'tax',
            value: '外税 10％',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction2_cost1_item2_option3: {
      editItems: [{
        id: 'plan1_construction2_cost1_item2_option2',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'supplier',
            label: '仕入先',
            type: 'supplier',
            value: '仕入先Ｃ',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quotation_number',
            label: '仕入先見積番号',
            type: 'text',
            value: 'D512604',
            readonly: ro.value,
            required: false,
          }, {
            id: 'model_number',
            label: '型番',
            type: 'text',
            value: 'CLP-26S6ASET',
            readonly: ro.value,
            required: false,
          }, {
            id: 'description',
            label: '品名／規格',
            type: 'text',
            value: '【Cat.6A】AWG26細径ケーブル用コネクタ (コネクタ+ブーツ+錫めっき銅箔テープ 100個セット)',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '10',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit',
            label: '単位',
            type: 'unit',
            value: '個',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit_price',
            label: '単価［円］',
            type: 'text',
            value: '717',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '717',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'consumption_tax_rate',
            label: '消費税率',
            type: 'tax',
            value: '外税 10％',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction2_cost2_item1_option1: {
      editItems: [{
        id: 'plan1_construction2_cost2_item1_option1',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'detail_name',
            label: '細目',
            type: 'text',
            value: 'ケーブル配線作業費（～20m）',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '80',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'production_rate',
            label: '歩掛［人工］',
            type: 'text',
            value: '0.05',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'labor_unit_price',
            label: '労務単価［円／時］',
            type: 'text',
            value: '4,000',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_premium',
            label: '休日割増率',
            type: 'text',
            value: '1.25',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_work_rate',
            label: '休出率［％］',
            type: 'text',
            value: '8',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '130,560',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction2_cost2_item1_option2: {
      editItems: [{
        id: 'plan1_construction2_cost2_item1_option2',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'detail_name',
            label: '細目',
            type: 'text',
            value: 'ケーブル配線作業費（～50m）',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '40',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'production_rate',
            label: '歩掛［人工］',
            type: 'text',
            value: '0.15',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'labor_unit_price',
            label: '労務単価［円／時］',
            type: 'text',
            value: '4,000',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_premium',
            label: '休日割増率',
            type: 'text',
            value: '1.25',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_work_rate',
            label: '休出率［％］',
            type: 'text',
            value: '4',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '193,920',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction2_cost2_item1_option3: {
      editItems: [{
        id: 'plan1_construction2_cost2_item1_option3',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'detail_name',
            label: '細目',
            type: 'text',
            value: 'ケーブル配線作業費（～100m）',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '20',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'production_rate',
            label: '歩掛［人工］',
            type: 'text',
            value: '0.25',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'labor_unit_price',
            label: '労務単価［円／時］',
            type: 'text',
            value: '4,000',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_premium',
            label: '休日割増率',
            type: 'text',
            value: '1.25',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_work_rate',
            label: '休出率［％］',
            type: 'text',
            value: '0',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '160,000',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction2_cost2_item1_option4: {
      editItems: [{
        id: 'plan1_construction2_cost2_item1_option4',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'detail_name',
            label: '細目',
            type: 'text',
            value: '成端作業費（RJ-45 6A シールド）',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '280',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'production_rate',
            label: '歩掛［人工］',
            type: 'text',
            value: '0.05',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'labor_unit_price',
            label: '労務単価［円／時］',
            type: 'text',
            value: '4,000',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_premium',
            label: '休日割増率',
            type: 'text',
            value: '1.25',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_work_rate',
            label: '休出率［％］',
            type: 'text',
            value: '10',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '459,200',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction2_cost2_item2_option1: {
      editItems: [{
        id: 'plan1_construction2_cost2_item2_option1',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'detail_name',
            label: '細目',
            type: 'text',
            value: 'ＡＰ取付作業費',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '36',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'production_rate',
            label: '歩掛［人工］',
            type: 'text',
            value: '0.10',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'labor_unit_price',
            label: '労務単価［円／時］',
            type: 'text',
            value: '4,000',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_premium',
            label: '休日割増率',
            type: 'text',
            value: '1.25',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_work_rate',
            label: '休出率［％］',
            type: 'text',
            value: '10',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '118,080',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction2_cost2_item2_option2: {
      editItems: [{
        id: 'plan1_construction2_cost2_item2_option2',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'detail_name',
            label: '細目',
            type: 'text',
            value: 'ＡＰ設定費',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '36',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'production_rate',
            label: '歩掛［人工］',
            type: 'text',
            value: '0.05',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'labor_unit_price',
            label: '労務単価［円／時］',
            type: 'text',
            value: '4,000',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_premium',
            label: '休日割増率',
            type: 'text',
            value: '1.25',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_work_rate',
            label: '休出率［％］',
            type: 'text',
            value: '10',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '59,040',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction2_cost2_item3_option1: {
      editItems: [{
        id: 'plan1_construction2_cost2_item3_option1',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'detail_name',
            label: '細目',
            type: 'text',
            value: 'ケーブル試験調整費',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '140',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'production_rate',
            label: '歩掛［人工］',
            type: 'text',
            value: '0.10',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'labor_unit_price',
            label: '労務単価［円／時］',
            type: 'text',
            value: '4,000',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_premium',
            label: '休日割増率',
            type: 'text',
            value: '1.25',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_work_rate',
            label: '休出率［％］',
            type: 'text',
            value: '10',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '459,200',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction2_cost2_item3_option2: {
      editItems: [{
        id: 'plan1_construction2_cost2_item3_option2',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'detail_name',
            label: '細目',
            type: 'text',
            value: 'ＡＰ試験調整費',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '36',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'production_rate',
            label: '歩掛［人工］',
            type: 'text',
            value: '0.10',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'labor_unit_price',
            label: '労務単価［円／時］',
            type: 'text',
            value: '4,500',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_premium',
            label: '休日割増率',
            type: 'text',
            value: '1.25',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'holiday_work_rate',
            label: '休出率［％］',
            type: 'text',
            value: '10',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '132,840',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction2_cost3_item1_option1: {
      editItems: [{
        id: 'plan1_construction2_cost3_item1_option1',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'supplier',
            label: '支払先',
            type: 'supplier',
            value: '支払先Ａ',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quotation_number',
            label: '支払先見積番号',
            type: 'text',
            value: '',
            readonly: ro.value,
            required: false,
          }, {
            id: 'model_number',
            label: '型番',
            type: 'text',
            value: '',
            readonly: ro.value,
            required: false,
          }, {
            id: 'description',
            label: '品名／規格',
            type: 'text',
            value: '法定福利費',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '1',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit',
            label: '単位',
            type: 'unit',
            value: '式',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit_price',
            label: '単価［円］',
            type: 'text',
            value: '100,000',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '100,000',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'consumption_tax_rate',
            label: '消費税率',
            type: 'tax',
            value: '外税 10％',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
    plan1_construction2_cost3_item1_option2: {
      editItems: [{
        id: 'plan1_construction2_cost3_item1_option2',
        //label: constTable.itemLabel.get('budget_information'),
        groups: [{
          items: [{
            id: 'supplier',
            label: '支払先',
            type: 'supplier',
            value: '支払先Ａ',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quotation_number',
            label: '支払先見積番号',
            type: 'text',
            value: '',
            readonly: ro.value,
            required: false,
          }, {
            id: 'model_number',
            label: '型番',
            type: 'text',
            value: '',
            readonly: ro.value,
            required: false,
          }, {
            id: 'description',
            label: '品名／規格',
            type: 'text',
            value: '現場管理費',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'quantity',
            label: '数量',
            type: 'text',
            value: '1',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit',
            label: '単位',
            type: 'unit',
            value: '式',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'unit_price',
            label: '単価［円］',
            type: 'text',
            value: '50,000',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'amount',
            label: '金額［円］',
            type: 'text',
            value: '50,000',
            textAlign: 'right',
            readonly: ro.value,
            required: rq.value,
          }, {
            id: 'consumption_tax_rate',
            label: '消費税率',
            type: 'tax',
            value: '外税 10％',
            readonly: ro.value,
            required: rq.value,
          }],
        }],
      }],
    },
  })

  const tree = reactive<TreeViewProps>({
    levels: [
      'plan',
      'construction',
      'expense',
      'item',
      'option',
    ],
    nodes: [{
      type: 'plan',
      id: '1',
      label: 'おすすめプラン',
      expanded: true,
      icon: icon.status.ng,
      list: list.plan1,
      nodes: [{
        type: 'construction',
        id: '1-1',
        label: '電気工事',
        expanded: true,
        icon: icon.status.ng,
        list: list.plan1_construction1,
        nodes: [{
          type: 'expense',
          dataType: 'material',
          id: '1-1-1',
          label: '材料費',
          title: '材料費 - 電気工事',
          expanded: true,
          icon: icon.status.ng,
          list: list.plan1_construction1_cost1,
          nodes: [{
            type: 'item',
            dataType: 'material',
            id: '1-1-1-1',
            label: '600V VVF',
            expanded: false,
            icon: icon.status.ng,
            list: list.plan1_construction1_cost1_item1,
            nodes: [{
              type: 'option',
              dataType: 'material',
              id: '1-1-1-1-1',
              label: '仕入先Ａ VVFケーブル2X2.0mm　導体径2mm 1巻(50m)',
              icon: icon.status.ok,
              pad: pad.plan1_construction1_cost1_item1_option1,
            }, {
              type: 'option',
              dataType: 'material',
              id: '1-1-1-1-2',
              label: '仕入先Ｂ VVF2.0mm×2C 電線 VVFケーブル 2.0mm×2芯 100m',
              icon: icon.status.pending,
              pad: pad.plan1_construction1_cost1_item1_option2,
            }, {
              type: 'option',
              dataType: 'material',
              id: '1-1-1-1-3',
              label: '仕入先Ｃ 57039825 導体径2mm 1巻(100m)',
              icon: icon.status.pending,
              pad: pad.plan1_construction1_cost1_item1_option3,
            }],
          }],
        }, {
          type: 'expense',
          dataType: 'outsource',
          id: '1-1-2',
          label: '外注費',
          title: '外注費 - 電気工事',
          expanded: true,
          icon: icon.status.ok,
          list: list.plan1_construction1_cost2,
          nodes: [{
            type: 'item',
            dataType: 'outsource',
            id: '1-1-2-1',
            label: 'コネクタ加工',
            expanded: false,
            icon: icon.status.ok,
            list: list.plan1_construction1_cost2_item1,
            nodes: [{
              type: 'option',
              dataType: 'outsource',
              id: '1-1-2-1-1',
              label: '特殊コネクタ加工',
              icon: icon.status.ok,
              pad: pad.plan1_construction1_cost2_item1_option1,
            }],
          }],
        }, {
          type: 'expense',
          dataType: 'labor',
          id: '1-1-3',
          label: '労務費',
          title: '労務費 - 電気工事',
          expanded: true,
          icon: icon.status.ok,
          list: list.plan1_construction1_cost3,
          nodes: [{
            type: 'item',
            dataType: 'labor',
            id: '1-1-3-1',
            label: 'ケーブル配線作業費',
            expanded: false,
            icon: icon.status.ok,
            list: list.plan1_construction1_cost3_item1,
            nodes: [{
              type: 'option',
              dataType: 'labor',
              id: '1-1-3-1-1',
              label: '600V VVF 配線作業費（～25m）',
              icon: icon.status.ok,
              pad: pad.plan1_construction1_cost3_item1_option1,
            }, {
              type: 'option',
              dataType: 'labor',
              id: '1-1-3-1-2',
              label: '600V VVF 配線作業費（～50m）',
              icon: icon.status.ok,
              pad: pad.plan1_construction1_cost3_item1_option2,
            }, {
              type: 'option',
              dataType: 'labor',
              id: '1-1-3-1-3',
              label: '600V VVF 配線作業費（～100m）',
              icon: icon.status.exclusion,
              pad: pad.plan1_construction1_cost3_item1_option3,
            }, {
              type: 'option',
              dataType: 'labor',
              id: '1-1-3-1-4',
              label: '600V VVF 配線作業費（～150m）',
              icon: icon.status.exclusion,
              pad: pad.plan1_construction1_cost3_item1_option4,
            }, {
              type: 'option',
              dataType: 'labor',
              id: '1-1-3-1-5',
              label: '600V VVF 配線作業費（～150m）',
              icon: icon.status.ok,
              pad: pad.plan1_construction1_cost3_item1_option5,
            }],
          }, {
            type: 'item',
            dataType: 'labor',
            id: '1-1-3-2',
            label: '試験調整費',
            expanded: false,
            icon: icon.status.ok,
            list: list.plan1_construction1_cost3_item2,
            nodes: [{
              type: 'option',
              dataType: 'labor',
              id: '1-1-3-2-1',
              label: 'ケーブル試験調整費',
              icon: icon.status.ok,
              pad: pad.plan1_construction1_cost3_item2_option1,
            }],
          }],
        }],
      }, {
        type: 'construction',
        id: '1-2',
        label: 'LAN設備工事',
        expanded: true,
        icon: icon.status.ok,
        list: list.plan1_construction2,
        nodes: [{
          type: 'expense',
          dataType: 'material',
          id: '1-2-1',
          label: '材料費',
          title: '材料費 - LAN設備工事',
          icon: icon.status.ok,
          expanded: true,
          list: list.plan1_construction2_cost1,
          nodes: [{
            type: 'item',
            dataType: 'material',
            id: '1-2-1-1',
            label: 'LANケーブル\ncat6A',
            expanded: false,
            icon: icon.status.ok,
            list: list.plan1_construction2_cost1_item1,
            nodes: [{
              type: 'option',
              dataType: 'material',
              id: '1-2-1-1-1',
              label: '仕入先Ａ MLN-UFC6A/BU300R LANケーブル U/FTP (高遮蔽アルミシールド型) 300m巻 Cat.6A カテゴリー6A 10Gbps',
              icon: icon.status.exclusion,
              pad: pad.plan1_construction2_cost1_item1_option1,
            }, {
              type: 'option',
              dataType: 'material',
              id: '1-2-1-1-2',
              label: '仕入先Ｂ MLN-HFU6A/BU300R LANケーブル F/UTP AWG26 細径 Cat.6A LANケーブル 300m ブルー',
              icon: icon.status.ok,
              pad: pad.plan1_construction2_cost1_item1_option2,
            }],
          }, {
            type: 'item',
            dataType: 'material',
            id: '1-2-1-2',
            label: 'ケーブル保護材（雑材料・消耗品含む）',
            expanded: false,
            icon: icon.status.ok,
            list: list.plan1_construction2_cost1_item2,
            nodes: [{
              type: 'option',
              dataType: 'material',
              id: '1-2-1-2-1',
              label: '仕入先Ｂ CLP-S6A U/FTP Cat.6Aケーブル専用 LANプラグ RJ-45',
              icon: icon.status.exclusion,
              pad: pad.plan1_construction2_cost1_item2_option1,
            }, {
              type: 'option',
              dataType: 'material',
              id: '1-2-1-2-2',
              label: '仕入先Ｃ CLP-26S6ASET 【Cat.6A】AWG26細径ケーブル用コネクタ (コネクタ+ブーツ+錫めっき銅箔テープ 100個セット)',
              icon: icon.status.ok,
              pad: pad.plan1_construction2_cost1_item2_option2,
            }, {
              type: 'option',
              dataType: 'material',
              id: '1-2-1-2-3',
              label: '仕入先Ｃ CLA-SJJ6A LAN RJ45 中継アダプタ Cat.6A対応 (RJ-45) STP金属シールド',
              icon: icon.status.ok,
              pad: pad.plan1_construction2_cost1_item2_option3,
            }],
          }],
        }, {
          type: 'expense',
          dataType: 'labor',
          id: '1-2-2',
          label: '労務費',
          title: '労務費 - LAN設備工事',
          icon: icon.status.ok,
          expanded: true,
          list: list.plan1_construction2_cost2,
          nodes: [{
            type: 'item',
            dataType: 'labor',
            id: '1-2-2-1',
            label: 'ケーブル配線・成端作業費',
            expanded: false,
            icon: icon.status.ok,
            list: list.plan1_construction2_cost2_item1,
            nodes: [{
              type: 'option',
              dataType: 'labor',
              id: '1-2-2-1-1',
              label: 'ケーブル配線作業費（～20m）',
              icon: icon.status.ok,
              pad: pad.plan1_construction2_cost2_item1_option1,
            }, {
              type: 'option',
              dataType: 'labor',
              id: '1-2-2-1-2',
              label: 'ケーブル配線作業費（～50m）',
              icon: icon.status.ok,
              pad: pad.plan1_construction2_cost2_item1_option2,
            }, {
              type: 'option',
              dataType: 'labor',
              id: '1-2-2-1-3',
              label: 'ケーブル配線作業費（～100m）',
              icon: icon.status.ok,
              pad: pad.plan1_construction2_cost2_item1_option3,
            }, {
              type: 'option',
              dataType: 'labor',
              id: '1-2-2-1-4',
              label: '成端作業費（RJ-45 6A シールド）',
              icon: icon.status.ok,
              pad: pad.plan1_construction2_cost2_item1_option4,
            }],
          }, {
            type: 'item',
            dataType: 'labor',
            id: '1-2-2-2',
            label: 'ＡＰ取付・調整費',
            expanded: false,
            icon: icon.status.ok,
            list: list.plan1_construction2_cost2_item2,
            nodes: [{
              type: 'option',
              dataType: 'labor',
              id: '1-2-2-2-1',
              label: 'ＡＰ取付作業費',
              icon: icon.status.ok,
              pad: pad.plan1_construction2_cost2_item2_option1,
            }, {
              type: 'option',
              dataType: 'labor',
              id: '1-2-2-2-2',
              label: 'ＡＰ設定費',
              icon: icon.status.ok,
              pad: pad.plan1_construction2_cost2_item2_option2,
            }],
          }, {
            type: 'item',
            dataType: 'labor',
            id: '1-2-2-3',
            label: '試験調整費',
            expanded: false,
            icon: icon.status.ok,
            list: list.plan1_construction2_cost2_item3,
            nodes: [{
              type: 'option',
              dataType: 'labor',
              id: '1-2-2-3-1',
              label: 'ケーブル試験調整費',
              icon: icon.status.ok,
              pad: pad.plan1_construction2_cost2_item3_option1,
            }, {
              type: 'option',
              dataType: 'labor',
              id: '1-2-2-3-2',
              label: 'ＡＰ試験調整費',
              icon: icon.status.ok,
              pad: pad.plan1_construction2_cost2_item3_option2,
            }],
          }],
        }, {
          type: 'expense',
          dataType: 'expenses',
          id: '1-2-3',
          label: '経費',
          title: '経費 - LAN設備工事',
          //label: 'その他',
          //title: 'その他 - LAN設備工事',
          icon: icon.status.ok,
          expanded: true,
          list: list.plan1_construction2_cost3,
          nodes: [{
            type: 'item',
            dataType: 'expenses',
            id: '1-2-3-1',
            label: '諸経費',
            expanded: false,
            icon: icon.status.ok,
            list: list.plan1_construction2_cost3_item1,
            nodes: [{
              type: 'option',
              dataType: 'expenses',
              id: '1-2-3-1-1',
              label: '法定福利費',
              icon: icon.status.ok,
              pad: pad.plan1_construction2_cost3_item1_option1,
            }, {
              type: 'option',
              dataType: 'expenses',
              id: '1-2-3-1-2',
              label: '現場管理費',
              icon: icon.status.ok,
              pad: pad.plan1_construction2_cost3_item1_option2,
            }],
          }],
        }],
      }],
    }],
  })
  /*
  const setReadonly = (value: boolean) => {
    readonly.value = value
  }
  */
  const getTree = (value = true) => {
    readonly.value = value
    return tree
  }

  return {
    tree,
    //setReadonly,
    getTree,
  }
}
