import {
  reactive,
} from 'vue'

const standardFilterItems = {
  monthly: [

  ],
}

export interface FilterSettingItem {
  id: string
  name: string
  filter: string
}

export default function construction () {
  /*
  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }
  const getFilterItems = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  */
  const getFilterPresetItems = () => {
    const items: string[] = [
      '* 標準',
      '  郡山本社案件',
      '  仙台支部案件',
    ]
    return items
  }
  const getFilterSettingItems = () => {
    const items: FilterSettingItem[] = [{
      id: 'id',
      name: '案件番号',
      filter: '1000 ～ 2000',
    }, {
      id: 'name',
      name: '案件名',
      filter: '',
    }, {
      id: 'start',
      name: '契約工期（開始日）',
      filter: '',
    }, {
      id: 'end',
      name: '契約工期（終了日）',
      filter: '',
    }, {
      id: 'status',
      name: '工程ステータス',
      filter: '受注、完成',
    }, {
      id: 'explanation',
      name: '【説明】',
      filter: 'この例は、案件一覧の一部抜粋',
    }]
    return items
  }

  return {
    //searchItem,
    //searchValue,
    //getFilterItems,
    getFilterPresetItems,
    getFilterSettingItems,
  }
}
