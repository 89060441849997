import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import ConstClients from '@/const/transaction/master/clients'
import {
  SearchBoxValue,
  SearchBoxItem,
} from '@/const/component/searchBox'

const constTable = ConstClients()

const items = [
  'code',
  'name',
  'official_company_name',
  'official_branch_name',
  'address',
]

export default function search () {
  const searchItems = computed(() => {
    return items.map(el => {
      return {
        id: el,
        label: constTable.searchLabel.get(el),
      } as SearchBoxItem
    })
  })

  return {
    searchItems,
  }
}
