export interface DbPlan {
  status: string
  id: string
  id_number: number
  name: string
  note: string
  estimate_id: string | null
  estimate_id_number: number | null
  contract_amount_budget: bigint
  material_cost_budget: bigint
  outsourcing_cost_budget: bigint
  expense_cost_budget: bigint
  others_cost_budget: bigint
  labor_cost_budget: bigint
  marginal_profit_budget: bigint
  marginal_profit_ratio_budget: number
  productivity_budget: number
  gross_profit_budget: bigint
  updated_at: string
  updated_by: string
  updated_by_name: string
  updated_by_kana: string
  icon?: string
}

export default function plans () {
  const plans: Array<DbPlan> = [{
    id: '101',
    id_number: 101,
    name: 'おすすめ',
    note: 'Cat6A 使用',
    estimate_id: null,
    estimate_id_number: null,
    status: '0',
    contract_amount_budget: 8633625n,
    material_cost_budget: 2186390n,
    outsourcing_cost_budget: 200000n,
    labor_cost_budget: 2867400n,
    expense_cost_budget: 150000n,
    others_cost_budget: 0n,
    marginal_profit_budget: 6097235n,
    marginal_profit_ratio_budget: 70.62,
    gross_profit_budget: 3229835n,
    productivity_budget: 2.40,
    updated_at: '2023-04-01',
    updated_by: '1',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインA',
    icon: 'caution',
  }, {
    id: '102',
    id_number: 102,
    name: 'ハイスペック',
    note: 'Cat8 使用',
    estimate_id: '19608-10',
    estimate_id_number: 19608010,
    status: '10',
    contract_amount_budget: 10200000n,
    material_cost_budget: 2828000n,
    outsourcing_cost_budget: 200000n,
    labor_cost_budget: 3147400n,
    expense_cost_budget: 150000n,
    others_cost_budget: 0n,
    marginal_profit_budget: 7022000n,
    marginal_profit_ratio_budget: 68.84,
    gross_profit_budget: 3874600n,
    productivity_budget: 2.23,
    updated_at: '2023-05-01',
    updated_by: '2',
    updated_by_name: '社員Ｂ',
    updated_by_kana: 'シャインB',
  }, {
    id: '103',
    id_number: 103,
    name: 'エコノミー',
    note: 'Cat5e 使用',
    estimate_id: null,
    estimate_id_number: null,
    status: '0',
    contract_amount_budget: 6986100n,
    material_cost_budget: 1684000n,
    outsourcing_cost_budget: 180000n,
    labor_cost_budget: 2190800n,
    expense_cost_budget: 150000n,
    others_cost_budget: 0n,
    marginal_profit_budget: 4972100n,
    marginal_profit_ratio_budget: 71.17,
    gross_profit_budget: 2781300n,
    productivity_budget: 2.27,
    updated_at: '2023-06-03',
    updated_by: '3',
    updated_by_name: '社員Ｃ',
    updated_by_kana: 'シャインC',
  }]

  return {
    plans,
  }
}
