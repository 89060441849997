export interface DbEstimateDetail {
  id: string
  estimate_id: string
  estimate_revision_no: number
  details_line_no: number
  detail_type: number                 // 1: 注文品 / 2: メモ
  description: string
  quantity: number | null
  unit: string | null
  unit_price: number | null
  consumption_tax_rate: string | null
  consumption_tax_rate_number: number | null
  consumption_tax_amount: number | null
  amount_excluding_tax: number | null
  amount_including_tax: number | null
  note: string | null
  checked?: boolean
  icon?: string | null
}

export default function estimateDetails () {
  const estimateDetails:Array<DbEstimateDetail> = [{
    id: '1',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 1,
    detail_type: 2,
    description: '　',
    quantity: null,
    unit: null,
    unit_price: null,
    consumption_tax_rate: null,
    consumption_tax_rate_number: null,
    consumption_tax_amount: null,
    amount_excluding_tax: null,
    amount_including_tax: null,
    note: null,
  }, {
    id: '2',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 2,
    detail_type: 2,
    description: '１．イントラネット基盤保守',
    quantity: null,
    unit: null,
    unit_price: null,
    consumption_tax_rate: null,
    consumption_tax_rate_number: null,
    consumption_tax_amount: null,
    amount_excluding_tax: null,
    amount_including_tax: null,
    note: null,
  }, {
    id: '3',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 3,
    detail_type: 2,
    description: '（１）故障受付及び故障修理作業費',
    unit: null,
    quantity: null,
    unit_price: null,
    consumption_tax_rate: null,
    consumption_tax_rate_number: null,
    consumption_tax_amount: null,
    amount_excluding_tax: null,
    amount_including_tax: null,
    note: null,
  }, {
    id: '4',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 4,
    detail_type: 2,
    description: '　・作業時間　月曜から金曜　8:30～17:30',
    unit: null,
    quantity: null,
    unit_price: null,
    consumption_tax_rate: null,
    consumption_tax_rate_number: null,
    consumption_tax_amount: null,
    amount_excluding_tax: null,
    amount_including_tax: null,
    note: null,
  }, {
    id: '5',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 5,
    detail_type: 1,
    description: '　　　三春町分',
    quantity: 12,
    unit: 'ヵ月',
    unit_price: 20000,
    consumption_tax_rate: '外税 10％',
    consumption_tax_rate_number: 10,
    consumption_tax_amount: 24000,
    amount_excluding_tax: 240000,
    amount_including_tax: 264000,
    note: null,
  }, {
    id: '6',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 6,
    detail_type: 1,
    description: '　　　小野町分',
    quantity: 12,
    unit: 'ヵ月',
    unit_price: 12000,
    consumption_tax_rate: '外税 10％',
    consumption_tax_rate_number: 10,
    consumption_tax_amount: 14400,
    amount_excluding_tax: 144000,
    amount_including_tax: 158400,
    note: null,
  }, {
    id: '7',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 7,
    detail_type: 1,
    description: '　　　田村市分',
    quantity: 12,
    unit: 'ヵ月',
    unit_price: 48000,
    consumption_tax_rate: '外税 10％',
    consumption_tax_rate_number: 10,
    consumption_tax_amount: 57600,
    amount_excluding_tax: 576000,
    amount_including_tax: 633600,
    note: null,
  },{
    id: '8',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 8,
    detail_type: 2,
    description: '　',
    unit: null,
    quantity: null,
    unit_price: null,
    consumption_tax_rate: null,
    consumption_tax_rate_number: null,
    consumption_tax_amount: null,
    amount_excluding_tax: null,
    amount_including_tax: null,
    note: null,
  }, {
    id: '9',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 9,
    detail_type: 2,
    description: '（２）定期点検作業費',
    unit: null,
    quantity: null,
    unit_price: null,
    consumption_tax_rate: null,
    consumption_tax_rate_number: null,
    consumption_tax_amount: null,
    amount_excluding_tax: null,
    amount_including_tax: null,
    note: null,
  }, {
    id: '10',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 10,
    detail_type: 2,
    description: '　・作業拠点',
    unit: null,
    quantity: null,
    unit_price: null,
    consumption_tax_rate: null,
    consumption_tax_rate_number: null,
    consumption_tax_amount: null,
    amount_excluding_tax: null,
    amount_including_tax: null,
    note: null,
  }, {
    id: '11',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 11,
    detail_type: 2,
    description: '　　役場庁舎、行政局：８箇所',
    unit: null,
    quantity: null,
    unit_price: null,
    consumption_tax_rate: null,
    consumption_tax_rate_number: null,
    consumption_tax_amount: null,
    amount_excluding_tax: null,
    amount_including_tax: null,
    note: null,
  }, {
    id: '12',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 12,
    detail_type: 2,
    description: '　　出先拠点：85箇所（令和３年実施拠点）',
    unit: null,
    quantity: null,
    unit_price: null,
    consumption_tax_rate: null,
    consumption_tax_rate_number: null,
    consumption_tax_amount: null,
    amount_excluding_tax: null,
    amount_including_tax: null,
    note: null,
  }, {
    id: '13',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 13,
    detail_type: 1,
    description: '　　　三春町分',
    quantity: 2,
    unit: '回',
    unit_price: 150000,
    consumption_tax_rate: '外税 10％',
    consumption_tax_rate_number: 10,
    consumption_tax_amount: 30000,
    amount_excluding_tax: 300000,
    amount_including_tax: 330000,
    note: null,
  }, {
    id: '14',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 14,
    detail_type: 1,
    description: '　　　小野町分',
    quantity: 2,
    unit: '回',
    unit_price: 90000,
    consumption_tax_rate: '外税 10％',
    consumption_tax_rate_number: 10,
    consumption_tax_amount: 18000,
    amount_excluding_tax: 180000,
    amount_including_tax: 198000,
    note: null,
  }, {
    id: '15',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 15,
    detail_type: 1,
    description: '　　　田村市分',
    quantity: 2,
    unit: '回',
    unit_price: 370000,
    consumption_tax_rate: '外税 10％',
    consumption_tax_rate_number: 10,
    consumption_tax_amount: 74000,
    amount_excluding_tax: 740000,
    amount_including_tax: 814000,
    note: null,
  },{
    id: '16',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 16,
    detail_type: 2,
    description: '　',
    unit: null,
    quantity: null,
    unit_price: null,
    consumption_tax_rate: null,
    consumption_tax_rate_number: null,
    consumption_tax_amount: null,
    amount_excluding_tax: null,
    amount_including_tax: null,
    note: null,
  },{
    id: '17',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 17,
    detail_type: 2,
    description: '　',
    unit: null,
    quantity: null,
    unit_price: null,
    consumption_tax_rate: null,
    consumption_tax_rate_number: null,
    consumption_tax_amount: null,
    amount_excluding_tax: null,
    amount_including_tax: null,
    note: null,
  },{
    id: '18',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 18,
    detail_type: 2,
    description: '　',
    unit: null,
    quantity: null,
    unit_price: null,
    consumption_tax_rate: null,
    consumption_tax_rate_number: null,
    consumption_tax_amount: null,
    amount_excluding_tax: null,
    amount_including_tax: null,
    note: null,
  }, {
    id: '19',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 19,
    detail_type: 2,
    description: '２．ウイルスマスター ライセンス更新',
    unit: null,
    quantity: null,
    unit_price: null,
    consumption_tax_rate: null,
    consumption_tax_rate_number: null,
    consumption_tax_amount: null,
    amount_excluding_tax: null,
    amount_including_tax: null,
    note: null,
  }, {
    id: '20',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 20,
    detail_type: 1,
    description: '　TRSL Trend Micro InterScan VirusWall',
    quantity: 1000,
    unit: '式',
    unit_price: 535,
    consumption_tax_rate: '外税 10％',
    consumption_tax_rate_number: 10,
    consumption_tax_amount: 53500,
    amount_excluding_tax: 535000,
    amount_including_tax: 588500,
    note: '更新：12月',
  }, {
    id: '21',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 21,
    detail_type: 2,
    description: '　移行用更新ガバメントG（1000-1999）',
    quantity: null,
    unit: null,
    unit_price: null,
    consumption_tax_rate: null,
    consumption_tax_rate_number: null,
    consumption_tax_amount: null,
    amount_excluding_tax: null,
    amount_including_tax: null,
    note: null,
  }, {
    id: '22',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 22,
    detail_type: 1,
    description: '　TRSL Client/Server Suite Premium ガバメントG（1000-1999）',
    quantity: 800,
    unit: '式',
    unit_price: 1000,
    consumption_tax_rate: '外税 10％',
    consumption_tax_rate_number: 10,
    consumption_tax_amount: 80000,
    amount_excluding_tax: 800000,
    amount_including_tax: 880000,
    note: '更新：12月',
  }, {
    id: '23',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 23,
    detail_type: 1,
    description: '　更新作業',
    quantity: 1,
    unit: '式',
    unit_price: 70000,
    consumption_tax_rate: '外税 10％',
    consumption_tax_rate_number: 10,
    consumption_tax_amount: 7000,
    amount_excluding_tax: 70000,
    amount_including_tax: 77000,
    note: null,
  },{
    id: '24',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 24,
    detail_type: 2,
    description: '　',
    unit: null,
    quantity: null,
    unit_price: null,
    consumption_tax_rate: null,
    consumption_tax_rate_number: null,
    consumption_tax_amount: null,
    amount_excluding_tax: null,
    amount_including_tax: null,
    note: null,
  }, {
    id: '25',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 25,
    detail_type: 2,
    description: '３．広域NW保守',
    unit: null,
    quantity: null,
    unit_price: null,
    consumption_tax_rate: null,
    consumption_tax_rate_number: null,
    consumption_tax_amount: null,
    amount_excluding_tax: null,
    amount_including_tax: null,
    note: null,
  }, {
    id: '26',
    estimate_id: '1',
    estimate_revision_no: 0,
    details_line_no: 26,
    detail_type: 1,
    description: '　保守作業費',
    quantity: 12,
    unit: 'ヵ月',
    unit_price: 12500,
    consumption_tax_rate: '外税 10％',
    consumption_tax_rate_number: 10,
    consumption_tax_amount: 15000,
    amount_excluding_tax: 150000,
    amount_including_tax: 165000,
    note: null,
  }]

  return {
    estimateDetails,
  }
}
