import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstBills from '@/const/transaction/bills'

const constBills = ConstBills()

const baseListColumns: ListViewColumn[] = [{
  id: 'billInformation',
  text: constBills.columnLabel.get('billInformation'),
  align: 'center',
  columns: [{
    id: 'status',
    text: constBills.columnLabel.get('status'),
    displayField: 'status',
    sortField: 'status_number',
    align: 'center',
    width: '100px',
  }, {
    id: 'id',
    text: constBills.columnLabel.get('id'),
    displayField: 'id',
    sortField: 'id_number',
    align: 'right',
  }, {
    id: 'clientOrderNo',
    text: constBills.columnLabel.get('clientOrderNo'),
    displayField: 'client_order_no',
    sortField: 'client_order_no',
  }, {
    id: 'billingTarget',
    text: constBills.columnLabel.get('billingTarget'),
    displayField: 'billing_target_name',
    sortField: 'billing_target_kana',
  }, {
    id: 'billingDate',
    text: constBills.columnLabel.get('billingDate'),
    displayField: 'billing_date',
    sortField: 'billing_date',
  }, {
    id: 'invoiceDate',
    text: constBills.columnLabel.get('invoiceDate'),
    displayField: 'invoice_date',
    sortField: 'invoice_date',
  }, {
    id: 'amountIncludingTax',
    text: constBills.columnLabel.get('amountIncludingTax'),
    displayField: 'amount_including_tax',
    sortField: 'amount_including_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'amountExcludingTax',
    text: constBills.columnLabel.get('amountExcludingTax'),
    displayField: 'amount_excluding_tax',
    sortField: 'amount_excluding_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'consumptionTaxAmount',
    text: constBills.columnLabel.get('consumptionTaxAmount'),
    displayField: 'consumption_tax_amount',
    sortField: 'consumption_tax_amount',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'memo',
    text: constBills.columnLabel.get('memo'),
    displayField: 'memo',
    sortField: 'memo',
  }, {
    id: 'invoiceFormat',
    text: constBills.columnLabel.get('invoiceFormat'),
    displayField: 'invoice_format',
    sortField: 'invoice_format',
  }, {
    id: 'sentDate',
    text: constBills.columnLabel.get('sentDate'),
    displayField: 'sent_date',
    sortField: 'sent_date',
  }, {
    id: 'sender',
    text: constBills.columnLabel.get('sender'),
    displayField: 'sender_name',
    sortField: 'sender_kana',
  }, {
    id: 'updated_at',
    text: constBills.columnLabel.get('updatedAt'),
    displayField: 'updated_at',
    sortField: 'updated_at',
  }, {
    id: 'updated_by',
    text: constBills.columnLabel.get('updatedBy'),
    displayField: 'updated_by_name',
    sortField: 'updated_by_kana',
  }],
}, {
  id: 'billInformation_draft',
  text: constBills.columnLabel.get('billInformation'),
  align: 'center',
  columns: [{
    id: 'id',
    text: constBills.columnLabel.get('id'),
    displayField: 'id',
    sortField: 'id_number',
    align: 'right',
  }, {
    id: 'clientOrderNo',
    text: constBills.columnLabel.get('clientOrderNo'),
    displayField: 'client_order_no',
    sortField: 'client_order_no',
  }, {
    id: 'billingTarget',
    text: constBills.columnLabel.get('billingTarget'),
    displayField: 'billing_target_name',
    sortField: 'billing_target_kana',
  }, {
    id: 'billingDate',
    text: constBills.columnLabel.get('billingDate'),
    displayField: 'billing_date',
    sortField: 'billing_date',
  }, {
    id: 'amountIncludingTax',
    text: constBills.columnLabel.get('amountIncludingTax'),
    displayField: 'amount_including_tax',
    sortField: 'amount_including_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'amountExcludingTax',
    text: constBills.columnLabel.get('amountExcludingTax'),
    displayField: 'amount_excluding_tax',
    sortField: 'amount_excluding_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'consumptionTaxAmount',
    text: constBills.columnLabel.get('consumptionTaxAmount'),
    displayField: 'consumption_tax_amount',
    sortField: 'consumption_tax_amount',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'memo',
    text: constBills.columnLabel.get('memo'),
    displayField: 'memo',
    sortField: 'memo',
  }, {
    id: 'invoiceFormat',
    text: constBills.columnLabel.get('invoiceFormat'),
    displayField: 'invoice_format',
    sortField: 'invoice_format',
  }, {
    id: 'updated_at',
    text: constBills.columnLabel.get('updatedAt'),
    displayField: 'updated_at',
    sortField: 'updated_at',
  }, {
    id: 'updated_by',
    text: constBills.columnLabel.get('updatedBy'),
    displayField: 'updated_by_name',
    sortField: 'updated_by_kana',
  }],
}, {
  id: 'billInformation_balance',
  text: constBills.columnLabel.get('billInformation'),
  align: 'center',
  columns: [{
    /*
    id: 'status',
    text: constBills.columnLabel.get('status'),
    displayField: 'status',
    sortField: 'status_number',
    align: 'center',
    width: '100px',
  }, {
    */
    id: 'id',
    text: constBills.columnLabel.get('id'),
    displayField: 'id',
    sortField: 'id_number',
    align: 'right',
  }, {
    id: 'clientOrderNo',
    text: constBills.columnLabel.get('clientOrderNo'),
    displayField: 'client_order_no',
    sortField: 'client_order_no',
  }, {
    id: 'billingDate',
    text: constBills.columnLabel.get('billingDate'),
    displayField: 'billing_date',
    sortField: 'billing_date',
    /*
  }, {
    id: 'invoiceDate',
    text: constBills.columnLabel.get('invoiceDate'),
    displayField: 'invoice_date',
    sortField: 'invoice_date',
    */
  }, {
    id: 'amountIncludingTax',
    text: constBills.columnLabel.get('amountIncludingTax'),
    displayField: 'amount_including_tax',
    sortField: 'amount_including_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'amountExcludingTax',
    text: constBills.columnLabel.get('amountExcludingTax'),
    displayField: 'amount_excluding_tax',
    sortField: 'amount_excluding_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'consumptionTaxAmount',
    text: constBills.columnLabel.get('consumptionTaxAmount'),
    displayField: 'consumption_tax_amount',
    sortField: 'consumption_tax_amount',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'memo',
    text: constBills.columnLabel.get('memo'),
    displayField: 'memo',
    sortField: 'memo',
    /*
  }, {
    id: 'invoiceFormat',
    text: constBills.columnLabel.get('invoiceFormat'),
    displayField: 'invoice_format',
    sortField: 'invoice_format',
  }, {
    id: 'sentDate',
    text: constBills.columnLabel.get('sentDate'),
    displayField: 'sent_date',
    sortField: 'sent_date',
  }, {
    id: 'sender',
    text: constBills.columnLabel.get('sender'),
    displayField: 'sender_name',
    sortField: 'sender_kana',
  }, {
    id: 'updated_at',
    text: constBills.columnLabel.get('updatedAt'),
    displayField: 'updated_at',
    sortField: 'updated_at',
  }, {
    id: 'updated_by',
    text: constBills.columnLabel.get('updatedBy'),
    displayField: 'updated_by_name',
    sortField: 'updated_by_kana',
    */
  }],
}, {
  id: 'contractInformation',
  text: constBills.columnLabel.get('contractInformation'),
  align: 'center',
  columns: [{
    id: 'contract_id',
    text: constBills.columnLabel.get('contractId'),
    displayField: 'contract_id',
    sortField: 'contract_id_number',
    align: 'right',
  }, {
    id: 'contract_name',
    text: constBills.columnLabel.get('contractName'),
    displayField: 'contract_name',
    sortField: 'contract_kana',
  }, {
    id: 'siteAgent',
    text: constBills.columnLabel.get('siteAgent'),
    displayField: 'site_agent_name',
    sortField: 'site_agent_kana',
  }, {
    id: 'completeDate',
    text: constBills.columnLabel.get('completeDate'),
    displayField: 'complete_date',
    sortField: 'complete_date',
  }, {
    id: 'salesDate',
    text: constBills.columnLabel.get('salesDate'),
    displayField: 'sales_date',
    sortField: 'sales_date',
  }],
}, {
  id: 'constractorInformation',
  text: constBills.columnLabel.get('constractorInformation'),
  align: 'center',
  columns: [{
    id: 'base',
    text: constBills.columnLabel.get('base'),
    displayField: 'base_name',
    sortField: 'base_display_order',
  }],
}]

const standardListColumns = {
  bill: [
    'billInformation',
    'contractInformation',
    'constractorInformation',
  ],
  general: [
    'billInformation',
    'contractInformation',
    'constractorInformation',
  ],
  draft: [
    'billInformation_draft',
    'contractInformation',
    'constractorInformation',
  ],
  balance: [
    'billInformation_balance',
    'contractInformation',
    'constractorInformation',
  ],
}

export default function bill () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'id_number',
    order: {
      'id_number': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  const listConverter: ListViewConverter = {
    status: {
      '0': {
        text: constBills.status.get('0') ?? '',
        type: 'ellipse',
        style: {
          background: 'rgb(100,0,0)',
          color: 'rgb(255,255,255)',
        },
      },
      '10': {
        text: constBills.status.get('10') ?? '',
        type: 'ellipse',
        style: {
          background: 'rgb(200,140,0)',
          color: 'rgb(255,255,255)',
        },
      },
      '11': {
        text: constBills.status.get('11') ?? '',
        type: 'ellipse',
        style: {
          background: 'rgb(0,200,0)',
          color: 'rgb(255,255,255)',
        },
      },
      '12': {
        text: constBills.status.get('12') ?? '',
        type: 'rectangle',
        style: {
          background: 'rgb(200,0,0)',
          color: 'rgb(255,255,255)',
        },
      },
      '20': {
        text: constBills.status.get('20') ?? '',
        type: 'rectangle',
        style: {
          background: 'rgb(0,80,200)',
          color: 'rgb(255,255,255)',
        },
      },
    },
  }

  return {
    baseListColumns,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
