export interface DbContractCategory {
  id: string
  display_order: number
  name: string
  kana: string
  updated_at: string
  updated_by_name: string
  updated_by_kana: string
}

export default function contractCategories () {
  const contractCategories: Array<DbContractCategory> = [{
    id: '1',
    display_order: 1,
    name: 'LAN',
    kana: 'ラン',
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '2',
    display_order: 2,
    name: 'TEL',
    kana: 'テル',
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '3',
    display_order: 3,
    name: 'AV',
    kana: 'エーブイ',
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '4',
    display_order: 4,
    name: '電気',
    kana: 'デンキ',
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }]

  return {
    contractCategories,
  }
}
