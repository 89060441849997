export interface DbSupplier {
  id: string
  code: string
  name: string
  kana: string
  official_company_name: string
  official_branch_name: string
  honorific_title: string
  postcode: string
  address0: string
  address1: string | null
  closing_date: number
  payment_month: string
  payment_date: number
  tax_rounding: string
  amount_rounding: string
  expense_type: string
  updated_at: string
  updated_by_name: string
  updated_by_kana: string
}

export default function suppliers () {
  const suppliers: Array<DbSupplier> = [{
    id: '1',
    code: '12345678',
    name: '〇〇 ＸＸＸ',
    kana: 'マルマル バツバツバツ',
    official_company_name: '〇〇株式会社',
    official_branch_name: 'ＸＸＸ支店',
    honorific_title: '御中',
    postcode: '123-4567',
    address0: '福島県白河市',
    address1: '五日市町 1024',
    closing_date: 20,
    payment_month: '2',
    payment_date: 31,
    tax_rounding: '1',
    amount_rounding: '2',
    expense_type: 'material',
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }]

  return {
    suppliers,
  }
}
