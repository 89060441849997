import {
  Construction,
  Supplier,
  Base,
  Employee,
  SendOrderStatus,
  //ExpenseType,
  Rounding,
  SendOrderSearchCondition,
} from '@/graphql'

export interface DbPurchaseDetail {
  //id: number
  id: string
  id_number: number
  //send_order_id: number
  purchase_id: string
  details_line_no: number
  details_line_no_text: string
  detail_type: number                 // 1: 注文品 / 2: メモ
  model_number: string
  description: string
  expense_type: string | null         // 1: 材料費 / 2: 外注費 / 3: 経費 / 4: 労務費 / 0: その他
  expense_account_type: string | null
  not_manage_backlog: number | null   // 0: 管理する / 1: 管理しない
  quantity: number | null
  unit: string | null
  unit_price: number | null
  amount: number | null
  amount_including_tax: number | null
  amount_excluding_tax: number | null
  consumption_tax_amount: number | null
  consumption_tax_rate: string | null
  consumption_tax_rate_number: number | null
  deliveried_quantity: number | null
  remaining_quantity: number | null
  checked?: boolean
  icon?: string | null
  expanded?: boolean
  expansionItems?: DbPurchaseDetail[]
}

export default function purchaseDetails () {
  const purchaseDetails:Array<DbPurchaseDetail> = [{
    id: '1',
    id_number: 1,
    purchase_id: '1',
    details_line_no: 1,
    details_line_no_text: '1',
    detail_type: 2,
    model_number: '１．治具製作',
    description: '',
    expense_type: null,
    expense_account_type: null,
    not_manage_backlog: null,
    quantity: null,
    unit: null,
    unit_price: null,
    amount: null,
    amount_including_tax: null,
    amount_excluding_tax: null,
    consumption_tax_amount: null,
    consumption_tax_rate: null,
    consumption_tax_rate_number: null,
    deliveried_quantity: null,
    remaining_quantity: null,
  }, {
    id: '2',
    id_number: 2,
    purchase_id: '1',
    details_line_no: 2,
    details_line_no_text: '2',
    detail_type: 1,
    model_number: 'ABC-123',
    description: 'ラズベリーパイ4 16GB',
    expense_type: '1',
    expense_account_type: '〇〇費',
    not_manage_backlog: 0,
    quantity: 10,
    unit: '個',
    unit_price: 3850,
    amount: 38500,
    amount_including_tax: 42350,
    amount_excluding_tax: 38500,
    consumption_tax_amount: 3850,
    consumption_tax_rate: '外税 10％',
    consumption_tax_rate_number: 10,
    deliveried_quantity: 0,
    remaining_quantity: 10,
  }, {
    id: '3',
    id_number: 3,
    purchase_id: '1',
    details_line_no: 3,
    details_line_no_text: '3',
    detail_type: 1,
    model_number: 'M302-SL',
    description: 'Transcend SDカード　32GB',
    expense_type: '2',
    expense_account_type: '〇〇費',
    not_manage_backlog: 0,
    quantity: 10,
    unit_price: 1800,
    unit: '枚',
    amount: 18000,
    amount_including_tax: 18000,
    amount_excluding_tax: 16364,
    consumption_tax_amount: 1636,
    consumption_tax_rate: '内税 10％',
    consumption_tax_rate_number: 10,
    deliveried_quantity: 4,
    remaining_quantity: 6,
    expanded: false,
    expansionItems: [{
      id: 'd160',
      id_number: 160,
      purchase_id: '3',
      details_line_no: 3,
      details_line_no_text: '2023-01-24',
      detail_type: 1,
      model_number: 'M302-SL',
      description: 'Transcend SDカード　32GB',
      expense_type: '2',
      expense_account_type: '〇〇費',
      not_manage_backlog: 0,
      quantity: 2,
      unit_price: 1800,
      unit: '枚',
      amount: 3600,
      amount_including_tax: 3600,
      amount_excluding_tax: 3273,
      consumption_tax_amount: 327,
      consumption_tax_rate: '内税 10％',
      consumption_tax_rate_number: 10,
      deliveried_quantity: 4,
      remaining_quantity: 6,
    }, {
      id: 'd100',
      id_number: 100,
      purchase_id: '3',
      details_line_no: 3,
      details_line_no_text: '2022-12-20',
      detail_type: 1,
      model_number: 'M302-SL',
      description: 'Transcend SDカード　32GB',
      expense_type: '2',
      expense_account_type: '〇〇費',
      not_manage_backlog: 0,
      quantity: 2,
      unit_price: 1800,
      unit: '枚',
      amount: 3600,
      amount_including_tax: 3600,
      amount_excluding_tax: 3273,
      consumption_tax_amount: 327,
      consumption_tax_rate: '内税 10％',
      consumption_tax_rate_number: 10,
      deliveried_quantity: 2,
      remaining_quantity: 8,
    }],
  }, {
    id: '4',
    id_number: 4,
    purchase_id: '1',
    details_line_no: 4,
    details_line_no_text: '4',
    detail_type: 2,
    model_number: '　',
    description: '　',
    unit: null,
    expense_type: null,
    expense_account_type: null,
    not_manage_backlog: null,
    quantity: null,
    unit_price: null,
    amount: null,
    amount_including_tax: null,
    amount_excluding_tax: null,
    consumption_tax_amount: null,
    consumption_tax_rate: null,
    consumption_tax_rate_number: null,
    deliveried_quantity: null,
    remaining_quantity: null,
  }, {
    id: '5',
    id_number: 5,
    purchase_id: '1',
    details_line_no: 5,
    details_line_no_text: '5',
    detail_type: 2,
    model_number: '２．工事車両レンタル',
    description: '',
    unit: null,
    expense_type: null,
    expense_account_type: null,
    not_manage_backlog: null,
    quantity: null,
    unit_price: null,
    amount: null,
    amount_including_tax: null,
    amount_excluding_tax: null,
    consumption_tax_amount: null,
    consumption_tax_rate: null,
    consumption_tax_rate_number: null,
    deliveried_quantity: null,
    remaining_quantity: null,
  }, {
    id: '6',
    id_number: 6,
    purchase_id: '1',
    details_line_no: 6,
    details_line_no_text: '6',
    detail_type: 1,
    model_number: '',
    description: 'トラック式 折り曲げ式ブーム',
    expense_type: '3',
    expense_account_type: '〇〇費',
    not_manage_backlog: 1,
    quantity: 10,
    unit: '日',
    unit_price: 24000,
    amount: null,
    amount_including_tax: 240000,
    amount_excluding_tax: 240000,
    consumption_tax_amount: 0,
    consumption_tax_rate: '非課税',
    consumption_tax_rate_number: 0,
    deliveried_quantity: 7,
    remaining_quantity: 0,
    expanded: false,
    expansionItems: [{
      id: 'd106',
      id_number: 106,
      purchase_id: '6',
      details_line_no: 6,
      details_line_no_text: '2021-02-20',
      detail_type: 1,
      model_number: '',
      description: 'トラック式 折り曲げ式ブーム',
      expense_type: '3',
      expense_account_type: '〇〇費',
      not_manage_backlog: 1,
      quantity: 2,
      unit: '日',
      unit_price: 24000,
      amount: null,
      amount_including_tax: 24000,
      amount_excluding_tax: 24000,
      consumption_tax_amount: 0,
      consumption_tax_rate: '非課税',
      consumption_tax_rate_number: 0,
      deliveried_quantity: 7,
      remaining_quantity: 0,
    }, {
      id: 'd104',
      id_number: 104,
      purchase_id: '6',
      details_line_no: 6,
      details_line_no_text: '2021-07-22',
      detail_type: 1,
      model_number: '',
      description: 'トラック式 折り曲げ式ブーム',
      expense_type: '3',
      expense_account_type: '〇〇費',
      not_manage_backlog: 0,
      quantity: 4,
      unit: '日',
      unit_price: 24000,
      amount: null,
      amount_including_tax: 24000,
      amount_excluding_tax: 24000,
      consumption_tax_amount: 0,
      consumption_tax_rate: '非課税',
      consumption_tax_rate_number: 0,
      deliveried_quantity: 5,
      remaining_quantity: 5,
    }, {
      id: 'd96',
      id_number: 96,
      purchase_id: '6',
      details_line_no: 6,
      details_line_no_text: '2021-12-16',
      detail_type: 1,
      model_number: '',
      description: 'トラック式 折り曲げ式ブーム',
      expense_type: '3',
      expense_account_type: '〇〇費',
      not_manage_backlog: 0,
      quantity: 1,
      unit: '日',
      unit_price: 24000,
      amount: null,
      amount_including_tax: 24000,
      amount_excluding_tax: 24000,
      consumption_tax_amount: 0,
      consumption_tax_rate: '非課税',
      consumption_tax_rate_number: 0,
      deliveried_quantity: 1,
      remaining_quantity: 9,
    }],
  }, {
    id: '7',
    id_number: 7,
    purchase_id: '1',
    details_line_no: 7,
    details_line_no_text: '7',
    detail_type: 1,
    model_number: '',
    description: '垂直昇降型クローラ',
    expense_type: '0',
    expense_account_type: '〇〇費',
    not_manage_backlog: 1,
    quantity: 10,
    unit: '日',
    unit_price: 45000,
    amount: null,
    amount_including_tax: 486000,
    amount_excluding_tax: 450000,
    consumption_tax_amount: 36000,
    consumption_tax_rate: '外税 8％',
    consumption_tax_rate_number: 8,
    deliveried_quantity: 6,
    remaining_quantity: 4,
/*
    amount: 45000n,
    consumption_tax_rate: 10,
    deliveried_quantity: 10,
    remaining_quantity: 0,
    accounts_payable: 45000,
    payment_amount: 40000,
    balanced_payment: 5000,
    expanded: false,
    expansionItems: [{
      id: '7',
      purchase_id: '1',
      details_line_no: 7,
      details_line_no_text: '完納\n2022-11-30',
      detail_type: 1,
      change_type: 0,
      change_items: 0,
      transaction_type: 0,
      alternative_send_order_detail_id: null,
      model_number: '垂直昇降型クローラ',
      description: '',
      expense_type: '0',
      expense_account_type: '〇〇費',
      not_manage_backlog: 1,
      quantity: 10,
      unit: '日',
      unit_price: 45000,
      amount: 45000n,
      consumption_tax_rate: 10,
      deliveried_quantity: 10,
      remaining_quantity: 0,
      accounts_payable: 45000,
    }],*/
  }, {
    id: '8',
    id_number: 8,
    purchase_id: '1',
    details_line_no: 8,
    details_line_no_text: '8',
    detail_type: 1,
    model_number: '',
    description: '自走式ポスト型ホイール',
    expense_type: '1',
    expense_account_type: '〇〇費',
    not_manage_backlog: 1,
    quantity: 10,
    unit: '日',
    unit_price: 50000,
    amount: null,
    amount_including_tax: 500000,
    amount_excluding_tax: 500000,
    consumption_tax_amount: 0,
    consumption_tax_rate: '不課税',
    consumption_tax_rate_number: null,
    deliveried_quantity: 10,
    remaining_quantity: 0,
    expanded: false,
    expansionItems: [{
      id: 'd1008',
      id_number: 1008,
      purchase_id: '1',
      details_line_no: 8,
      details_line_no_text: '8',
      detail_type: 1,
      model_number: '',
      description: '自走式ポスト型ホイール',
      expense_type: '1',
      expense_account_type: '〇〇費',
      not_manage_backlog: 1,
      quantity: 10,
      unit: '日',
      unit_price: 50000,
      amount: null,
      amount_including_tax: 500000,
      amount_excluding_tax: 500000,
      consumption_tax_amount: 0,
      consumption_tax_rate: '不課税',
      consumption_tax_rate_number: null,
      deliveried_quantity: 10,
      remaining_quantity: 0,
    }]
  }]

  return {
    purchaseDetails,
  }
}
