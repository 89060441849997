import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'

const baseListColumns: ListViewColumn[] = [{
  id: 'trigger',
  text: 'トリガー',
  displayField: 'trigger',
  sortField: 'trigger',
  edit: {
    type: 'settingsTrigger',
  },
}, {
  id: 'days',
  text: '動作日',
  displayField: 'days',
  sortField: 'days',
  edit: {
    type: 'settingsDays',
  },
}, {
  id: 'action',
  text: 'アクション',
  displayField: 'action',
  sortField: 'action',
  edit: {
    type: 'settingsAction',
  },
}, {
  id: 'pause',
  text: '休止',
  displayField: 'pause',
  sortField: 'pause',
  align: 'center',
  width: '100px',
  edit: {
    type: 'check',
  },
}]

const listIcon: ListViewIcon = {
}
const listConverter: ListViewConverter = {
}

export default function role () {
  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'trigger',
    order: {
      'trigger': 1,
    },
  })

  const getFormItems = () => {
    return [
      'trigger',
      'days',
      'action',
      'pause',
    ]
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
