import {
  ListViewConverterItems,
} from '@/const/component/listView'
import Constant from '@/const/common/constant'

export default function listConverter () {
  const constant = Constant()

  //const listConverter: ListViewConverter = {
  const contractTypeType =  'rectangle'
  const contractTypeColor =  '#FFFFFF'
  const contractType: ListViewConverterItems = {
    1: {
      text: constant.contract.construction.shortName,
      type: contractTypeType,
      style: {
        background: constant.contract.construction.color,
        color: contractTypeColor,
      },
    },
    2: {
      text: constant.contract.productSales.shortName,
      type: contractTypeType,
      style: {
        background: constant.contract.productSales.color,
        color: contractTypeColor,
      },
    },
    3: {
      text: constant.contract.hindrance.shortName,
      type: contractTypeType,
      style: {
        background: constant.contract.hindrance.color,
        color: contractTypeColor,
      },
    },
    4: {
      text: constant.contract.maintenance.shortName,
      type: contractTypeType,
      style: {
        background: constant.contract.maintenance.color,
        color: contractTypeColor,
      },
    },
    5: {
      text: constant.contract.service.shortName,
      type: contractTypeType,
      style: {
        background: constant.contract.service.color,
        color: contractTypeColor,
      },
    },
  }
  const contractorTypeType =  'rectangle'
  const contractorTypeColor =  '#FFFFFF'
  const contractorType: ListViewConverterItems = {
    1: {
      text: constant.contractor.prime.shortName,
      type: contractorTypeType,
      style: {
        background: constant.contractor.prime.color,
        color: contractorTypeColor,
      },
    },
    2: {
      text: constant.contractor.sub.shortName,
      type: contractorTypeType,
      style: {
        background: constant.contractor.sub.color,
        color: contractorTypeColor,
      },
    },
  }
  const publicPrivateWorksTypeType =  'rectangle'
  const publicPrivateWorksTypeColor =  '#FFFFFF'
  const publicPrivateWorksType: ListViewConverterItems = {
    1: {
      text: constant.publicPrivateWorks.public.shortName,
      type: publicPrivateWorksTypeType,
      style: {
        background: constant.publicPrivateWorks.public.color,
        color: publicPrivateWorksTypeColor,
      },
    },
    2: {
      text: constant.publicPrivateWorks.private.shortName,
      type: publicPrivateWorksTypeType,
      style: {
        background: constant.publicPrivateWorks.private.color,
        color: publicPrivateWorksTypeColor,
      },
    },
  }
  const ordersAccuracyType =  'rectangle'
  const ordersAccuracyColor =  '#FFFFFF'
  const ordersAccuracy: ListViewConverterItems = {
    a: {
      text: constant.ordersAccuracy.lebelA.shortName,
      type: ordersAccuracyType,
      style: {
        background: constant.ordersAccuracy.lebelA.color,
        color: ordersAccuracyColor,
      },
    },
    b: {
      text: constant.ordersAccuracy.lebelB.shortName,
      type: ordersAccuracyType,
      style: {
        background: constant.ordersAccuracy.lebelB.color,
        color: ordersAccuracyColor,
      },
    },
    c: {
      text: constant.ordersAccuracy.lebelC.shortName,
      type: ordersAccuracyType,
      style: {
        background: constant.ordersAccuracy.lebelC.color,
        color: ordersAccuracyColor,
      },
    },
  }
  const processStatusType =  'ellipse'
  const processStatusType_finished =  'rectangle'
  const processStatusColor =  '#FFFFFF'
  const processStatus: ListViewConverterItems = {
    0: {
      text: constant.processStatus.inquiry.name,
      type: processStatusType,
      style: {
        background: constant.processStatus.inquiry.color,
        color: processStatusColor,
      },
    },
    1: {
      text: constant.processStatus.estimated.name,
      type: processStatusType,
      style: {
        background: constant.processStatus.estimated.color,
        color: processStatusColor,
      },
    },
    2: {
      text: constant.processStatus.ordered.name,
      type: processStatusType,
      style: {
        background: constant.processStatus.ordered.color,
        color: processStatusColor,
      },
    },
    3: {
      text: constant.processStatus.completed.name,
      type: processStatusType_finished,
      style: {
        background: constant.processStatus.completed.color,
        color: processStatusColor,
      },
    },
    10: {
      text: constant.processStatus.lost.name,
      type: processStatusType_finished,
      style: {
        background: constant.processStatus.lost.color,
        color: processStatusColor,
      },
    },
  }
  const invoiceStatusType =  'ellipse'
  const invoiceStatusColor =  '#FFFFFF'
  const invoiceStatus: ListViewConverterItems = {
    0: {
      text: constant.invoiceStatus.yet.name,
      type: invoiceStatusType,
      style: {
        background: constant.invoiceStatus.yet.color,
        color: invoiceStatusColor,
      },
    },
    1: {
      text: constant.invoiceStatus.partially.name,
      type: invoiceStatusType,
      style: {
        background: constant.invoiceStatus.partially.color,
        color: invoiceStatusColor,
      },
    },
    2: {
      text: constant.invoiceStatus.done.name,
      type: invoiceStatusType,
      style: {
        background: constant.invoiceStatus.done.color,
        color: invoiceStatusColor,
      },
    },
  }
  const expenseTypeType =  'ellipse'
  const expenseTypeColor =  '#FFFFFF'
  const expenseType: ListViewConverterItems = {
    1: {
      text: constant.cost.material.name,
      type: expenseTypeType,
      style: {
        background: constant.cost.material.color,
        color: expenseTypeColor,
      },
    },
    2: {
      text: constant.cost.outsource.name,
      type: expenseTypeType,
      style: {
        background: constant.cost.outsource.color,
        color: expenseTypeColor,
      },
    },
    3: {
      text: constant.cost.expenses.name,
      type: expenseTypeType,
      style: {
        background: constant.cost.expenses.color,
        color: expenseTypeColor,
      },
    },
    4: {
      text: constant.cost.others.name,
      type: expenseTypeType,
      style: {
        background: constant.cost.others.color,
        color: expenseTypeColor,
      },
    },
  }
  const expenseAccountType: ListViewConverterItems = {
    1: {
      text: '仕入',
      type: 'ellipse',
      style: {
        background: 'rgb(0,0,220)',
        color: 'rgb(255,255,255)',
      },
    },
    2: {
      text: '租税公課',
      type: 'ellipse',
      style: {
        background: 'rgb(0,100,200)',
        color: 'rgb(255,255,255)',
      },
    },
    3: {
      text: '水道光熱費',
      type: 'ellipse',
      style: {
        background: 'rgb(0,100,100)',
        color: 'rgb(255,255,255)',
      },
    },
    4: {
      text: '通信費',
      type: 'ellipse',
      style: {
        background: 'rgb(0,200,150)',
        color: 'rgb(255,255,255)',
      },
    },
    5: {
      text: '旅費交通費',
      type: 'ellipse',
      style: {
        background: 'rgb(0,140,0)',
        color: 'rgb(255,255,255)',
      },
    },
    6: {
      text: '広告宣伝費',
      type: 'ellipse',
      style: {
        background: 'rgb(120,180,0)',
        color: 'rgb(255,255,255)',
      },
    },
    7: {
      text: '接待交際費',
      type: 'ellipse',
      style: {
        background: 'rgb(160,160,0)',
        color: 'rgb(255,255,255)',
      },
    },
    8: {
      text: '消耗品費',
      type: 'ellipse',
      style: {
        background: 'rgb(200,120,0)',
        color: 'rgb(255,255,255)',
      },
    },
    9: {
      text: '給与賃金',
      type: 'ellipse',
      style: {
        background: 'rgb(200,0,0)',
        color: 'rgb(255,255,255)',
      },
    },
    10: {
      text: '福利厚生費',
      type: 'ellipse',
      style: {
        background: 'rgb(220,100,100)',
        color: 'rgb(255,255,255)',
      },
    },
    11: {
      text: '地代家賃',
      type: 'ellipse',
      style: {
        background: 'rgb(140,0,200)',
        color: 'rgb(255,255,255)',
      },
    },
    12: {
      text: '減価償却費',
      type: 'ellipse',
      style: {
        background: 'rgb(100,0,100)',
        color: 'rgb(255,255,255)',
      },
    },
    13: {
      text: '雑費',
      type: 'ellipse',
      style: {
        background: 'rgb(0,0,100)',
        color: 'rgb(255,255,255)',
      },
    },
    20: {
      text: '売上',
      type: 'ellipse',
      style: {
        background: 'rgb(100,0,0)',
        color: 'rgb(255,255,255)',
      },
    },
  }
  const depositTypeType =  'ellipse'
  const depositTypeColor =  '#FFFFFF'
  const deposit_type: ListViewConverterItems = {
    1: {
      text: constant.depositType.transfer.name,
      type: depositTypeType,
      style: {
        background: constant.depositType.transfer.color,
        color: depositTypeColor,
      },
    },
    2: {
      text: constant.depositType.cash.name,
      type: depositTypeType,
      style: {
        background: constant.depositType.cash.color,
        color: depositTypeColor,
      },
    },
    3: {
      text: constant.depositType.bill.name,
      type: depositTypeType,
      style: {
        background: constant.depositType.bill.color,
        color: depositTypeColor,
      },
    },
    4: {
      text: constant.depositType.commission.name,
      type: depositTypeType,
      style: {
        background: constant.depositType.commission.color,
        color: depositTypeColor,
      },
    },
    5: {
      text: constant.depositType.discount.name,
      type: depositTypeType,
      style: {
        background: constant.depositType.discount.color,
        color: depositTypeColor,
      },
    },
    6: {
      text: constant.depositType.offset.name,
      type: depositTypeType,
      style: {
        background: constant.depositType.offset.color,
        color: depositTypeColor,
      },
    },
    7: {
      text: constant.depositType.membership.name,
      type: depositTypeType,
      style: {
        background: constant.depositType.membership.color,
        color: depositTypeColor,
      },
    },
    8: {
      text: constant.depositType.others.name,
      type: depositTypeType,
      style: {
        background: constant.depositType.others.color,
        color: depositTypeColor,
      },
    },
  }

  return {
    contractType,
    contractorType,
    publicPrivateWorksType,
    ordersAccuracy,
    processStatus,
    invoiceStatus,
    expenseType,
    expenseAccountType,
    deposit_type,
  }
}