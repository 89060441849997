export default function dateTime () {
  const weeks = [ '日', '月', '火', '水', '木', '金', '土' ]

  const getYMD_YMD = (y: number, m: number, d: number, cn = '-') => {
    return `${y}${cn}${(`0${m}`).slice(-2)}${cn}${(`0${d}`).slice(-2)}`
  }
  const getYM_YM = (y: number, m: number, cn = '-') => {
    return `${y}${cn}${(`0${m}`).slice(-2)}`
  }
  const getJpYMD_YMD = (y: number, m: number, d: number) => {
    return `${y}年${m}月${d}日`
  }
  const getJpYM_YM = (y: number, m: number) => {
    return `${y}年${m}月`
  }
  const getYMD_today = (cn = '-') => {
    const ob = new Date()
    return getYMD_YMD(ob.getFullYear(), ob.getMonth() + 1, ob.getDate(), cn)
  }
  const getYMD_nextDate = (value: string, cn = '-') => {
    const dt = Date.parse(value)
    const ob = new Date(dt)
    ob.setDate(ob.getDate() + 1)
    return getYMD_YMD(ob.getFullYear(), ob.getMonth() + 1, ob.getDate(), cn)
  }
  const getYMD_previousDate = (value: string, cn = '-') => {
    const dt = Date.parse(value)
    const ob = new Date(dt)
    ob.setDate(ob.getDate() - 1)
    return getYMD_YMD(ob.getFullYear(), ob.getMonth() + 1, ob.getDate(), cn)
  }
  const getYMD_beforeDates = (value: string, days: number, cn = '-') => {
    const dt = Date.parse(value)
    const ob = new Date(dt)
    ob.setDate(ob.getDate() - days )
    return getYMD_YMD(ob.getFullYear(), ob.getMonth() + 1, ob.getDate(), cn)
  }
  const getYMD_afterDates = (value: string, days: number, cn = '-') => {
    const dt = Date.parse(value)
    const ob = new Date(dt)
    ob.setDate(ob.getDate() + days )
    return getYMD_YMD(ob.getFullYear(), ob.getMonth() + 1, ob.getDate(), cn)
  }
  const getJpYMD = (value: string) => {
    const dt = Date.parse(value)
    const ob = new Date(dt)
    return getJpYMD_YMD(ob.getFullYear(), ob.getMonth() + 1, ob.getDate())
  }
  const getYM_thisMonth = (cn = '-') => {
    const ob = new Date()
    return getYM_YM(ob.getFullYear(), ob.getMonth() + 1, cn)
  }
  const getYM_nextMonth = (value: string, cn = '-') => {
    const dt = Date.parse(`${value}-01`)
    const ob = new Date(dt)
    ob.setMonth(ob.getMonth() + 1)
    return getYM_YM(ob.getFullYear(), ob.getMonth() + 1, cn)
  }
  const getYM_previousMonth = (value: string, cn = '-') => {
    const dt = Date.parse(`${value}-01`)
    const ob = new Date(dt)
    ob.setMonth(ob.getMonth() - 1)
    return getYM_YM(ob.getFullYear(), ob.getMonth() + 1, cn)
  }
  const getYM_beforeMonths = (value: string, months: number, cn = '-') => {
    const dt = Date.parse(`${value}-01`)
    const ob = new Date(dt)
    ob.setMonth(ob.getMonth() - months )
    return getYM_YM(ob.getFullYear(), ob.getMonth() + 1, cn)
  }
  const getYM_afterMonths = (value: string, months: number, cn = '-') => {
    const dt = Date.parse(`${value}-01`)
    const ob = new Date(dt)
    ob.setMonth(ob.getMonth() + months )
    return getYM_YM(ob.getFullYear(), ob.getMonth() + 1, cn)
  }
  const get_thisYear = () => {
    const ob = new Date()
    return ob.getFullYear()
  }
  const getJpYM = (value: string) => {
    const dt = Date.parse(value)
    const ob = new Date(dt)
    return getJpYM_YM(ob.getFullYear(), ob.getMonth() + 1)
  }
  const getNumberOfDays = (start: string, end: string) => {
    const dtSt = Date.parse(start)
    const obSt = new Date(dtSt).getTime()
    const dtEd = Date.parse(end)
    const obEd = new Date(dtEd).getTime()
    return Math.floor((obEd - obSt) / 86400000) + 1
  }
  const getNumberOfMonths = (start: string, end: string) => {
    const dtSt = Date.parse(`${start}-01`)
    const obSt = new Date(dtSt)
    const dtEd = Date.parse(`${end}-01`)
    const obEd = new Date(dtEd)
    const y = obEd.getFullYear() - obSt.getFullYear()
    const m = obEd.getMonth() - obSt.getMonth()
    return y * 12 + m + 1
  }
  const dateToMonth = (value: string, cn = '-') => {
    const dt = Date.parse(value)
    const ob = new Date(dt)
    return getYM_YM(ob.getFullYear(), ob.getMonth() + 1, cn)
  }
  const monthToFirstDate = (value: string, cn = '-') => {
    return `${value}${cn}01`
  }
  const monthToLastDate = (value: string, cn = '-') => {
    const fd = monthToFirstDate(value, cn)
    const dt = Date.parse(fd)
    const ob = new Date(dt)
    ob.setMonth(ob.getMonth() + 1)
    ob.setDate(ob.getDate() - 1)
    return getYMD_YMD(ob.getFullYear(), ob.getMonth() + 1, ob.getDate(), cn)
  }
  const getWeek = (y: number, m: number, d: number) => {
    const dt = Date.parse(`${y}-${m}-${d}`)
    const ob = new Date(dt)
    return ob.getDay()
  }
  const getLastDate = (y: number, m: number) => {
    const fd = monthToFirstDate(`${y}-${m}`)
    const dt = Date.parse(fd)
    const ob = new Date(dt)
    ob.setMonth(ob.getMonth() + 1)
    ob.setDate(ob.getDate() - 1)
    return ob.getDate()
  }

  return {
    weeks,
    getYMD_today,
    getYMD_nextDate,
    getYMD_previousDate,
    getYMD_beforeDates,
    getYMD_afterDates,
    getJpYMD,
    getYM_thisMonth,
    getYM_nextMonth,
    getYM_previousMonth,
    getYM_beforeMonths,
    getYM_afterMonths,
    get_thisYear,
    getJpYM,
    getNumberOfDays,
    getNumberOfMonths,
    dateToMonth,
    monthToFirstDate,
    monthToLastDate,
    getWeek,
    getLastDate,
  }
}
