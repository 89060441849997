import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstCostSummaries from '@/const/transaction/costSummaries'

const constTable = ConstCostSummaries()

const baseListColumns: ListViewColumn[] = [{
  id: 'dataHeader',
  text: constTable.columnLabel.get('dataHeader'),
  displayField: 'data_header',
  bottomRow: {
    align: 'right',
    bold: true,
  },
}, {
  id: 'material',
  text: constTable.columnLabel.get('material'),
  displayField: 'material',
  //sortField: 'material',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  bottomRow: {
    bold: true,
  },
}, {
  id: 'outsource',
  text: constTable.columnLabel.get('outsource'),
  displayField: 'outsource',
  //sortField: 'outsource',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  bottomRow: {
    bold: true,
  },
}, {
  id: 'expenses',
  text: constTable.columnLabel.get('expenses'),
  displayField: 'expenses',
  //sortField: 'expenses',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  bottomRow: {
    bold: true,
  },
}, {
  id: 'labor',
  text: constTable.columnLabel.get('labor'),
  displayField: 'labor',
  //sortField: 'labor',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  bottomRow: {
    bold: true,
  },
}, {
  id: 'others',
  text: constTable.columnLabel.get('others'),
  displayField: 'others',
  //sortField: 'others',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  bottomRow: {
    bold: true,
  },
}, {
  id: 'total',
  text: constTable.columnLabel.get('total'),
  displayField: 'total',
  //sortField: 'total',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  bottomRow: {
    bold: true,
  },
}]

const standardListColumns = {
  unfinished: [
    'dataHeader',
    'material',
    'outsource',
    'expenses',
    'labor',
    'others',
    'total',
  ],
}

export default function deposit () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  //const selectedListItem = ref<ListViewRow | null>(null)

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  /*
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }
  */
  const sumValue = reactive({
    id: 'total',
    data_header: '合計',
    material: 2000000n,
    outsource: 2000000n,
    expenses: 2000000n,
    labor: 2000000n,
    others: 2000000n,
    total: 10000000n,
  })

  return {
    //baseListColumns,
    //selectedListItem,
    sumValue,
    getListColumns,
    //setListColumns,
  }
}
