export const tableName = '拠点'

export default function bases () {
  const itemLabel = new Map([
    [ 'id', 'ID' ],
    [ 'display_order', '表示順' ],
    [ 'name', '拠点名' ],
    [ 'kana', '拠点名（カナ）' ],
    [ 'postcode', '郵便番号' ],
    [ 'address', '住所' ],
    [ 'tel', 'TEL' ],
    [ 'fax', 'FAX' ],
    [ 'chief', '所長' ],
    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])
  const searchLabel = new Map([
    [ 'name', '拠点名' ],
    [ 'address', '住所' ],
  ])
  const columnLabel = new Map([
    [ 'id', 'ID' ],
    [ 'name', '拠点名' ],
    [ 'kana', '拠点名（カナ）' ],
    [ 'display_order', '表示順' ],
    [ 'postcode', '郵便番号' ],
    [ 'address', '住所' ],
    [ 'tel', 'TEL' ],
    [ 'fax', 'FAX' ],
    [ 'chief', '所長' ],
    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])

  return {
    itemLabel,
    searchLabel,
    columnLabel,
  }
}
