export interface DbAttendanceDaily {
  id: string,
  date: string
  time_start: string
  time_start_number: number
  time_end: string
  time_end_number: number
  within_time: string
  within_time_number: number
  overtime: string
  overtime_number: number
  holiday_time: string
  holiday_time_number: number
  midnight_time: string
  midnight_time_number: number
  working_total_time: string
  working_total_time_number: number
  icon?: string | null
}

export default function attendanceDailies () {
  const attendanceDailies: Array<DbAttendanceDaily> = [{
    id: '2020-01-25 08:00',
    date: '2020-01-25（土）',
    time_start: '8:00',
    time_start_number: 800,
    time_end: '17:00',
    time_end_number: 1700,
    within_time: '8:00',
    within_time_number: 800,
    overtime: '',
    overtime_number: 0,
    holiday_time: '8:00',
    holiday_time_number: 800,
    midnight_time: '',
    midnight_time_number: 0,
    working_total_time: '8:00',
    working_total_time_number: 800,
  }, {
    id: '2020-01-26 16:00',
    date: '2020-01-26（日）',
    time_start: '16:00',
    time_start_number: 1600,
    time_end: '25:00',
    time_end_number: 2500,
    within_time: '8:00',
    within_time_number: 800,
    overtime: '1:00',
    overtime_number: 100,
    holiday_time: '9:00',
    holiday_time_number: 900,
    midnight_time: '3:00',
    midnight_time_number: 300,
    working_total_time: '9:00',
    working_total_time_number: 900,
  }, {
    id: '2020-02-03 08:00',
    date: '2020-02-03（月）',
    time_start: '8:00',
    time_start_number: 800,
    time_end: '12:00',
    time_end_number: 1200,
    within_time: '4:00',
    within_time_number: 400,
    overtime: '',
    overtime_number: 0,
    holiday_time: '',
    holiday_time_number: 0,
    midnight_time: '',
    midnight_time_number: 0,
    working_total_time: '4:00',
    working_total_time_number: 400,
  }, {
    id: '2020-02-03 20:00',
    date: '2020-02-03（月）',
    time_start: '20:00',
    time_start_number: 2000,
    time_end: '24:00',
    time_end_number: 2400,
    within_time: '4:00',
    within_time_number: 400,
    overtime: '',
    overtime_number: 0,
    holiday_time: '',
    holiday_time_number: 0,
    midnight_time: '2:00',
    midnight_time_number: 0,
    working_total_time: '4:00',
    working_total_time_number: 0,
  }, {
    id: '2020-02-04 08:00',
    date: '2020-02-04（火）',
    time_start: '8:00',
    time_start_number: 800,
    time_end: '',
    time_end_number: 0,
    within_time: '',
    within_time_number: 0,
    overtime: '',
    overtime_number: 0,
    holiday_time: '',
    holiday_time_number: 0,
    midnight_time: '',
    midnight_time_number: 0,
    working_total_time: '',
    working_total_time_number: 0,
    icon: 'error',
  }]

  return {
    attendanceDailies,
  }
}
