export interface DbAttendance {
  id: string
  employee_number: string
  employee_name: string
  employee_kana: string
  base_name: string
  base_kana: string
  working_period_start: string
  working_period_end: string
  working_days: number
  within_time: string
  within_time_minute: number
  overtime: string
  overtime_minute: number
  working_total_time: string
  working_total_time_minute: number
  holiday_time: string
  holiday_time_minute: number
  midnight_time: string
  midnight_time_minute: number
  correction_required_count: number
}

export default function attendances () {
  const attendances: Array<DbAttendance> = [{
    id: '1',
    employee_number: '1234',
    employee_name: '福島 太郎',
    employee_kana: 'フクシマ タロウ',
    base_name: '郡山本社',
    base_kana: 'コオリヤマホンシャ',
    working_period_start: '2020-01-25（土）',
    working_period_end: '2020-02-04（火）',
    working_days: 5,
    within_time: '24:00',
    within_time_minute: 1440,
    overtime: '1:00',
    overtime_minute: 60,
    working_total_time: '25:00',
    working_total_time_minute: 1500,
    holiday_time: '17:00',
    holiday_time_minute: 1020,
    midnight_time: '5:00',
    midnight_time_minute: 300,
    correction_required_count: 1,
  }, {
    id: '2',
    employee_number: '1256',
    employee_name: '郡山 花子',
    employee_kana: 'コオリヤマ ハナコ',
    base_name: '郡山本社',
    base_kana: 'コオリヤマホンシャ',
    working_period_start: '2020-01-20（月）',
    working_period_end: '2020-02-14（金）',
    working_days: 15,
    within_time: '105:00',
    within_time_minute: 6300,
    overtime: '20:00',
    overtime_minute: 1200,
    working_total_time: '125:00',
    working_total_time_minute: 7500,
    holiday_time: '',
    holiday_time_minute: 0,
    midnight_time: '',
    midnight_time_minute: 0,
    correction_required_count: 0,
  }]

  return {
    attendances,
  }
}
