import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: any;
  Int64: bigint;
};

export type Account = {
  __typename?: 'Account';
  account_name: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type AccountInput = {
  account_name: Scalars['String'];
};

/** A paginated list of Account items. */
export type AccountPaginator = {
  __typename?: 'AccountPaginator';
  /** A list of Account items. */
  data: Array<Account>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type ApiToken = {
  __typename?: 'ApiToken';
  token?: Maybe<Scalars['String']>;
};

export type Bank = {
  __typename?: 'Bank';
  bank_branches: Array<BankBranch>;
  code: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  kana: Scalars['String'];
  name: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type BankBranch = {
  __typename?: 'BankBranch';
  bank: Bank;
  bank_code: Scalars['String'];
  code: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  kana: Scalars['String'];
  name: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of BankBranch items. */
export type BankBranchPaginator = {
  __typename?: 'BankBranchPaginator';
  /** A list of BankBranch items. */
  data: Array<BankBranch>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** A paginated list of Bank items. */
export type BankPaginator = {
  __typename?: 'BankPaginator';
  /** A list of Bank items. */
  data: Array<Bank>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type Base = {
  __typename?: 'Base';
  base_name: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  display_order?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type BaseInput = {
  base_name: Scalars['String'];
  display_order?: InputMaybe<Scalars['Int']>;
};

/** A paginated list of Base items. */
export type BasePaginator = {
  __typename?: 'BasePaginator';
  /** A list of Base items. */
  data: Array<Base>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum BillingType {
  /** Prime contractor */
  PrimeContractor = 'PRIME_CONTRACTOR',
  /** Sub contractor */
  SubContractor = 'SUB_CONTRACTOR'
}

export enum BloodType {
  /** A */
  A = 'A',
  /** Ab */
  Ab = 'AB',
  /** B */
  B = 'B',
  /** O */
  O = 'O',
  /** Unknown */
  Unknown = 'UNKNOWN'
}

export enum CollectionMonth {
  /** Month after next */
  MonthAfterNext = 'MONTH_AFTER_NEXT',
  /** Next month */
  NextMonth = 'NEXT_MONTH',
  /** This month */
  ThisMonth = 'THIS_MONTH'
}

export type Construction = {
  __typename?: 'Construction';
  attribute_type: ConstructionAttributeType;
  attribute_type_value: Scalars['Int'];
  billing_target?: Maybe<SendOrderClient>;
  billing_type?: Maybe<BillingType>;
  billing_type_value?: Maybe<Scalars['Int']>;
  complete_date?: Maybe<Scalars['Date']>;
  construction_designer?: Maybe<Employee>;
  construction_location?: Maybe<Scalars['String']>;
  construction_rep?: Maybe<Employee>;
  contract_construction_period_end_date?: Maybe<Scalars['Date']>;
  contract_construction_period_start_date?: Maybe<Scalars['Date']>;
  contract_job_amount?: Maybe<Scalars['Int64']>;
  contract_job_amount_excluding_tax?: Maybe<Scalars['Int64']>;
  contract_job_consumption_tax_amount?: Maybe<Scalars['Int64']>;
  created_at?: Maybe<Scalars['DateTime']>;
  customer_rep: Scalars['String'];
  deletable: Scalars['Boolean'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  destination_target?: Maybe<SendOrderClient>;
  division?: Maybe<Division>;
  estimate?: Maybe<Estimate>;
  id: Scalars['ID'];
  implementation_construction_period_end_date?: Maybe<Scalars['Date']>;
  implementation_construction_period_start_date?: Maybe<Scalars['Date']>;
  kana: Scalars['String'];
  lead_engineer?: Maybe<Employee>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  orders_date?: Maybe<Scalars['Date']>;
  overview?: Maybe<Scalars['String']>;
  payment_term_value?: Maybe<Scalars['Int']>;
  payment_terms?: Maybe<PaymentTerm>;
  project?: Maybe<Project>;
  public_private_works_type?: Maybe<PublicPrivateWorkType>;
  public_private_works_type_value?: Maybe<Scalars['Int']>;
  receive_orders_type: ReceiveOrdersType;
  receive_orders_type_value: Scalars['Int'];
  sales_rep?: Maybe<Employee>;
  send_order_client: SendOrderClient;
  send_orders: Array<SendOrder>;
  site_agent?: Maybe<Employee>;
  status: ConstructionStatus;
  status_value: Scalars['Int'];
  supervising_engineer?: Maybe<Employee>;
  tax_rate_type?: Maybe<TaxRateType>;
  tax_rate_type_value?: Maybe<Scalars['Int']>;
  updatable: Scalars['Boolean'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum ConstructionAttributeType {
  /** Outside cost */
  OutsideCost = 'OUTSIDE_COST',
  /** Standard */
  Standard = 'STANDARD'
}

export type ConstructionInput = {
  attribute_type?: InputMaybe<ConstructionAttributeType>;
  billing_target?: InputMaybe<Scalars['String']>;
  billing_type?: InputMaybe<BillingType>;
  complete_date?: InputMaybe<Scalars['String']>;
  construction_designer?: InputMaybe<Scalars['String']>;
  construction_location?: InputMaybe<Scalars['String']>;
  construction_rep?: InputMaybe<Scalars['String']>;
  contract_construction_period_end_date?: InputMaybe<Scalars['String']>;
  contract_construction_period_start_date?: InputMaybe<Scalars['String']>;
  contract_job_amount_excluding_tax?: InputMaybe<Scalars['String']>;
  customer_rep?: InputMaybe<Scalars['String']>;
  destination_target?: InputMaybe<Scalars['String']>;
  division_id?: InputMaybe<Scalars['String']>;
  estimate_id?: InputMaybe<Scalars['String']>;
  implementation_construction_period_end_date?: InputMaybe<Scalars['String']>;
  implementation_construction_period_start_date?: InputMaybe<Scalars['String']>;
  kana?: InputMaybe<Scalars['String']>;
  lead_engineer?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  orders_date?: InputMaybe<Scalars['String']>;
  overview?: InputMaybe<Scalars['String']>;
  payment_terms?: InputMaybe<PaymentTerm>;
  project_id?: InputMaybe<Scalars['String']>;
  public_private_works_type?: InputMaybe<PublicPrivateWorkType>;
  receive_orders_type?: InputMaybe<ReceiveOrdersType>;
  sales_rep?: InputMaybe<Scalars['String']>;
  send_order_client?: InputMaybe<Scalars['String']>;
  site_agent?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ConstructionStatus>;
  supervising_engineer?: InputMaybe<Scalars['String']>;
  tax_rate_type?: InputMaybe<TaxRateType>;
};

/** A paginated list of Construction items. */
export type ConstructionPaginator = {
  __typename?: 'ConstructionPaginator';
  /** A list of Construction items. */
  data: Array<Construction>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type ConstructionSearchCondition = {
  estimate_date_from?: InputMaybe<Scalars['String']>;
  estimate_date_to?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  not_associated_with_projects?: InputMaybe<Scalars['String']>;
  overview?: InputMaybe<Scalars['String']>;
  project_name?: InputMaybe<Scalars['String']>;
  receive_orders_accuracy?: InputMaybe<ReceiveOrdersAccuracy>;
  receive_orders_type?: InputMaybe<ReceiveOrdersType>;
  sales_rep?: InputMaybe<Scalars['String']>;
  send_order_client_code?: InputMaybe<Scalars['String']>;
  send_order_client_name?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<InputMaybe<ConstructionStatus>>>;
};

export enum ConstructionStatus {
  /** Acceptance */
  Acceptance = 'ACCEPTANCE',
  /** Completion */
  Completion = 'COMPLETION',
  /** Estimate */
  Estimate = 'ESTIMATE',
  /** Failure */
  Failure = 'FAILURE',
  /** Inquiry */
  Inquiry = 'INQUIRY'
}

export type ConstructionType = {
  __typename?: 'ConstructionType';
  construction_type_name: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type ConstructionTypeInput = {
  construction_type_name: Scalars['String'];
};

/** A paginated list of ConstructionType items. */
export type ConstructionTypePaginator = {
  __typename?: 'ConstructionTypePaginator';
  /** A list of ConstructionType items. */
  data: Array<ConstructionType>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum ConsumptionTaxCalcType {
  /** Per item */
  PerItem = 'PER_ITEM',
  /** Per project */
  PerProject = 'PER_PROJECT'
}

export enum ContractJobType {
  /** Prime contract */
  PrimeContract = 'PRIME_CONTRACT',
  /** Sub contract */
  SubContract = 'SUB_CONTRACT'
}

export type Customer = {
  __typename?: 'Customer';
  created_at?: Maybe<Scalars['DateTime']>;
  customer_kana?: Maybe<Scalars['String']>;
  customer_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  projects: Array<Project>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum DataType {
  /** Default */
  Default = 'DEFAULT',
  /** Deleted */
  Deleted = 'DELETED'
}

export type Division = {
  __typename?: 'Division';
  constructions: Array<Construction>;
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  direct_division: DivisionStatus;
  display_order?: Maybe<Scalars['Int']>;
  division_name: Scalars['String'];
  id: Scalars['ID'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type DivisionInput = {
  direct_division?: InputMaybe<Scalars['Int']>;
  display_order?: InputMaybe<Scalars['Int']>;
  division_name: Scalars['String'];
};

/** A paginated list of Division items. */
export type DivisionPaginator = {
  __typename?: 'DivisionPaginator';
  /** A list of Division items. */
  data: Array<Division>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** 部門ステータス */
export enum DivisionStatus {
  /** 直接部門 */
  Direct = 'DIRECT',
  /** 間接部門 */
  Indirect = 'INDIRECT'
}

export type EmailAddress = {
  __typename?: 'EmailAddress';
  created_at?: Maybe<Scalars['DateTime']>;
  data_id: Scalars['Int'];
  data_type: MasterDataType;
  data_type_value: Scalars['Int'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  display_order?: Maybe<Scalars['Int']>;
  email_address: Scalars['String'];
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type EmailAddressInput = {
  data_id?: InputMaybe<Scalars['String']>;
  data_type?: InputMaybe<MasterDataType>;
  display_order?: InputMaybe<Scalars['String']>;
  email_address?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
};

export type Employee = {
  __typename?: 'Employee';
  address?: Maybe<Scalars['String']>;
  base?: Maybe<Base>;
  basic_pension_number?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['Date']>;
  blood_type: BloodType;
  blood_type_value: Scalars['Int'];
  construction_designer_constructions: Array<Construction>;
  construction_projects: Array<Project>;
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  division?: Maybe<Division>;
  email?: Maybe<Scalars['String']>;
  employee_number?: Maybe<Scalars['Int']>;
  employment_insurance_insured_number?: Maybe<Scalars['String']>;
  health_insurance_insured_number?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  joining_date?: Maybe<Scalars['Date']>;
  kana?: Maybe<Scalars['String']>;
  labor_cost_unit_price?: Maybe<Scalars['String']>;
  lead_engineer_constructions: Array<Construction>;
  name?: Maybe<Scalars['String']>;
  phone_numbers?: Maybe<Array<Maybe<PhoneNumber>>>;
  postcode?: Maybe<Scalars['String']>;
  retirement_date?: Maybe<Scalars['Date']>;
  roles: Array<Role>;
  sales_projects: Array<Project>;
  sales_rep_constructions: Array<Construction>;
  sales_representative_send_Orders: Array<SendOrder>;
  send_order_clients: Array<SendOrderClient>;
  sex: Sex;
  sex_value: Scalars['Int'];
  site_agent_constructions: Array<Construction>;
  site_agent_send_orders: Array<SendOrder>;
  supervising_engineer_constructions: Array<Construction>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type EmployeeInput = {
  address?: InputMaybe<Scalars['String']>;
  base_id?: InputMaybe<Scalars['String']>;
  basic_pension_number?: InputMaybe<Scalars['String']>;
  birthdate?: InputMaybe<Scalars['String']>;
  blood_type?: InputMaybe<BloodType>;
  division_id?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  employee_number?: InputMaybe<Scalars['String']>;
  employment_insurance_insured_number?: InputMaybe<Scalars['String']>;
  health_insurance_insured_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  joining_date?: InputMaybe<Scalars['String']>;
  kana?: InputMaybe<Scalars['String']>;
  labor_cost_unit_price?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone_numbers?: InputMaybe<Array<InputMaybe<PhoneNumberInput>>>;
  postcode?: InputMaybe<Scalars['String']>;
  retirement_date?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Sex>;
};

/** A paginated list of Employee items. */
export type EmployeePaginator = {
  __typename?: 'EmployeePaginator';
  /** A list of Employee items. */
  data: Array<Employee>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** NOTE: 仕様未定のため仮 */
export type Estimate = {
  __typename?: 'Estimate';
  amount_excluding_tax?: Maybe<Scalars['Int']>;
  amount_including_tax?: Maybe<Scalars['Int']>;
  construction: Construction;
  consumption_tax?: Maybe<Scalars['Int']>;
  consumption_tax_calc_type?: Maybe<ConsumptionTaxCalcType>;
  consumption_tax_calc_type_value?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  delivery_deadline?: Maybe<PaymentTerm>;
  delivery_deadline_value?: Maybe<Scalars['Int']>;
  estimate_construction_location?: Maybe<Scalars['String']>;
  estimate_construction_overview?: Maybe<Scalars['String']>;
  estimate_date: Scalars['Date'];
  expiration_date?: Maybe<PaymentTerm>;
  expiration_date_value?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  receive_orders_accuracy?: Maybe<ReceiveOrdersAccuracy>;
  receive_orders_accuracy_value?: Maybe<Scalars['Int']>;
  sales_rep: Employee;
  tax_rate_kind?: Maybe<TaxRateKind>;
  tax_rate_kind_value?: Maybe<Scalars['Int']>;
  tax_rate_type?: Maybe<TaxRateType>;
  tax_rate_type_value?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum ExpenseAccountType {
  /** Purchases */
  Purchases = 'PURCHASES',
  /** Taxes */
  Taxes = 'TAXES',
  /** Utilities */
  Utilities = 'UTILITIES'
}

export type ExpenseItem = {
  __typename?: 'ExpenseItem';
  created_at?: Maybe<Scalars['DateTime']>;
  expense_item_name: Scalars['String'];
  id: Scalars['ID'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type ExpenseItemInput = {
  expense_item_name: Scalars['String'];
};

/** A paginated list of ExpenseItem items. */
export type ExpenseItemPaginator = {
  __typename?: 'ExpenseItemPaginator';
  /** A list of ExpenseItem items. */
  data: Array<ExpenseItem>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum ExpenseType {
  /** Expenses */
  Expenses = 'EXPENSES',
  /** Labor */
  Labor = 'LABOR',
  /** Material */
  Material = 'MATERIAL',
  /** Outsourcing */
  Outsourcing = 'OUTSOURCING'
}

export enum LineType {
  /** Fax */
  Fax = 'FAX',
  /** Phone */
  Phone = 'PHONE'
}

export type LoginAsEmployeeInput = {
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export enum MasterDataType {
  /** Employee */
  Employee = 'EMPLOYEE',
  /** Send order client */
  SendOrderClient = 'SEND_ORDER_CLIENT',
  /** Supplier */
  Supplier = 'SUPPLIER'
}

export type MasterRegistrationRequest = {
  __typename?: 'MasterRegistrationRequest';
  created_at?: Maybe<Scalars['DateTime']>;
  /** FIXME: テーブル定義はNULLABLE */
  data_type: MasterRegistrationRequestDataType;
  data_type_value?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  official_branch_name?: Maybe<Scalars['String']>;
  official_company_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  send_orders: Array<SendOrder>;
  status?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum MasterRegistrationRequestDataType {
  /** Send order client */
  SendOrderClient = 'SEND_ORDER_CLIENT',
  /** Supplier */
  Supplier = 'SUPPLIER'
}

export type Mutation = {
  __typename?: 'Mutation';
  createAccount: Account;
  createBase: Base;
  createConstruction: Construction;
  createConstructionType: ConstructionType;
  createDivision: Division;
  /** 草稿発注を作成する */
  createDraftSendOrder: SendOrder;
  createEmployee: Employee;
  createExpenseItem: ExpenseItem;
  createProject: Project;
  createSendOrderClient: SendOrderClient;
  /** 草稿発注に発注明細を追加する */
  createSendOrderDetail: SendOrder;
  createSupplier: Supplier;
  deleteBase: Base;
  deleteConstruction: Construction;
  deleteDivision: Division;
  deleteEmployee: Employee;
  deleteProject: Project;
  /** 草稿発注を論理削除する */
  deleteSendOrder: SendOrder;
  deleteSendOrderClient: SendOrderClient;
  /** 草稿発注の発注明細を削除する */
  deleteSendOrderDetail: SendOrder;
  deleteSupplier: Supplier;
  /** 論理削除した草稿発注を完全削除する */
  forceDeleteSendOrder: SendOrder;
  loginAsEmployee: ApiToken;
  logoutAsEmployee: Scalars['Boolean'];
  /** 草稿発注を「発注」にステータス変更する */
  orderDraftSendOrder: SendOrder;
  /** 発注を「発注取消」にステータス変更する */
  resetSendOrder: SendOrder;
  /** 論理削除した草稿発注を復元する */
  restoreSendOrder: SendOrder;
  updateAccount: Account;
  updateBase: Base;
  updateConstruction: Construction;
  updateConstructionType: ConstructionType;
  updateDivision: Division;
  /** 草稿発注を編集する */
  updateDraftSendOrder: SendOrder;
  updateEmployee: Employee;
  updateExpenseItem: ExpenseItem;
  updateProject: Project;
  updateProjectConstruction: Project;
  updateSendOrderClient: SendOrderClient;
  /** 草稿発注の発注明細を編集する */
  updateSendOrderDetail: SendOrder;
  updateSupplier: Supplier;
};


export type MutationCreateAccountArgs = {
  input: AccountInput;
};


export type MutationCreateBaseArgs = {
  input: BaseInput;
};


export type MutationCreateConstructionArgs = {
  input: ConstructionInput;
};


export type MutationCreateConstructionTypeArgs = {
  input: ConstructionTypeInput;
};


export type MutationCreateDivisionArgs = {
  input: DivisionInput;
};


export type MutationCreateDraftSendOrderArgs = {
  input: SendOrderInput;
};


export type MutationCreateEmployeeArgs = {
  input: EmployeeInput;
};


export type MutationCreateExpenseItemArgs = {
  input: ExpenseItemInput;
};


export type MutationCreateProjectArgs = {
  input: ProjectInput;
};


export type MutationCreateSendOrderClientArgs = {
  input: SendOrderClientInput;
};


export type MutationCreateSendOrderDetailArgs = {
  input: SendOrderDetailInput;
  send_order_id: Scalars['ID'];
};


export type MutationCreateSupplierArgs = {
  input: SupplierInput;
};


export type MutationDeleteBaseArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteConstructionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDivisionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEmployeeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProjectArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSendOrderArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSendOrderClientArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSendOrderDetailArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSupplierArgs = {
  id: Scalars['ID'];
};


export type MutationForceDeleteSendOrderArgs = {
  id: Scalars['ID'];
};


export type MutationLoginAsEmployeeArgs = {
  input: LoginAsEmployeeInput;
};


export type MutationOrderDraftSendOrderArgs = {
  id: Scalars['ID'];
};


export type MutationResetSendOrderArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreSendOrderArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateAccountArgs = {
  id: Scalars['ID'];
  input: AccountInput;
};


export type MutationUpdateBaseArgs = {
  id: Scalars['ID'];
  input: BaseInput;
};


export type MutationUpdateConstructionArgs = {
  id: Scalars['ID'];
  input: ConstructionInput;
};


export type MutationUpdateConstructionTypeArgs = {
  id: Scalars['ID'];
  input: ConstructionTypeInput;
};


export type MutationUpdateDivisionArgs = {
  id: Scalars['ID'];
  input: DivisionInput;
};


export type MutationUpdateDraftSendOrderArgs = {
  id: Scalars['ID'];
  input: SendOrderInput;
};


export type MutationUpdateEmployeeArgs = {
  input: EmployeeInput;
};


export type MutationUpdateExpenseItemArgs = {
  id: Scalars['ID'];
  input: ExpenseItemInput;
};


export type MutationUpdateProjectArgs = {
  id: Scalars['ID'];
  input: ProjectInput;
};


export type MutationUpdateProjectConstructionArgs = {
  id: Scalars['ID'];
  input: ProjectConstructionInput;
};


export type MutationUpdateSendOrderClientArgs = {
  id: Scalars['ID'];
  input: SendOrderClientInput;
};


export type MutationUpdateSendOrderDetailArgs = {
  id: Scalars['ID'];
  input: SendOrderDetailInput;
};


export type MutationUpdateSupplierArgs = {
  id: Scalars['ID'];
  input: SupplierInput;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export enum PaymentMonth {
  /** Month after next */
  MonthAfterNext = 'MONTH_AFTER_NEXT',
  /** Next month */
  NextMonth = 'NEXT_MONTH',
  /** This month */
  ThisMonth = 'THIS_MONTH'
}

export enum PaymentTerm {
  /** Basic contract */
  BasicContract = 'BASIC_CONTRACT',
  /** By meeting */
  ByMeeting = 'BY_MEETING',
  /** Fifth of the month after next */
  FifthOfTheMonthAfterNext = 'FIFTH_OF_THE_MONTH_AFTER_NEXT',
  /** Last day of the following month */
  LastDayOfTheFollowingMonth = 'LAST_DAY_OF_THE_FOLLOWING_MONTH',
  /** One month */
  OneMonth = 'ONE_MONTH',
  /** Specified location */
  SpecifiedLocation = 'SPECIFIED_LOCATION',
  /** Three month */
  ThreeMonth = 'THREE_MONTH',
  /** Two month */
  TwoMonth = 'TWO_MONTH'
}

export enum PaymentType {
  /** Bank */
  Bank = 'BANK'
}

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  created_at?: Maybe<Scalars['DateTime']>;
  data_id?: Maybe<Scalars['Int']>;
  data_type: MasterDataType;
  data_type_value: Scalars['Int'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  display_order?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  line_type: LineType;
  line_type_value: Scalars['Int'];
  phone_number: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type PhoneNumberInput = {
  data_id?: InputMaybe<Scalars['String']>;
  data_type?: InputMaybe<MasterDataType>;
  display_order?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  line_type?: InputMaybe<LineType>;
  phone_number?: InputMaybe<Scalars['String']>;
};

export type Project = {
  __typename?: 'Project';
  constructions: Array<Construction>;
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  estimate_constructions: Array<Construction>;
  id: Scalars['ID'];
  kana: Scalars['String'];
  name: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type ProjectConstructionInput = {
  construction_id?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ProjectInput = {
  kana?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** A paginated list of Project items. */
export type ProjectPaginator = {
  __typename?: 'ProjectPaginator';
  /** A list of Project items. */
  data: Array<Project>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum PublicPrivateWorkType {
  /** Private work */
  PrivateWork = 'PRIVATE_WORK',
  /** Public work */
  PublicWork = 'PUBLIC_WORK'
}

/** Indicates what fields are available at the top level of a query operation. */
export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  accounts?: Maybe<AccountPaginator>;
  authorizedAsEmployee?: Maybe<Employee>;
  bank?: Maybe<Bank>;
  bankBranch?: Maybe<BankBranch>;
  bankBranches?: Maybe<BankBranchPaginator>;
  banks?: Maybe<BankPaginator>;
  base?: Maybe<Base>;
  bases?: Maybe<BasePaginator>;
  /** enum `Rounding`で税金額を計算する（端数処理の誤差問題対応済） */
  calcTaxAmount: Scalars['Int'];
  /** `send_order_clients.tax_rounding`で税金額を計算する（端数処理の誤差問題対応済） */
  calcTaxAmountBySendOrderClient: Scalars['Int'];
  /** FIXME: このQueryだけシグニチャ違う */
  calcTaxAmountBySupplier: Scalars['Int'];
  calcTotalAmountBySendOrderClient: Scalars['Int'];
  calcTotalAmountBySupplier: Scalars['Int'];
  construction?: Maybe<Construction>;
  constructionType?: Maybe<ConstructionType>;
  constructionTypes?: Maybe<ConstructionTypePaginator>;
  constructions?: Maybe<ConstructionPaginator>;
  division?: Maybe<Division>;
  divisions?: Maybe<DivisionPaginator>;
  employee?: Maybe<Employee>;
  employees?: Maybe<EmployeePaginator>;
  expenseItem?: Maybe<ExpenseItem>;
  expenseItems?: Maybe<ExpenseItemPaginator>;
  project?: Maybe<Project>;
  projects?: Maybe<ProjectPaginator>;
  sendOrder?: Maybe<SendOrder>;
  sendOrderClient?: Maybe<SendOrderClient>;
  sendOrderClients?: Maybe<SendOrderClientPaginator>;
  sendOrders: Array<SendOrder>;
  supplier?: Maybe<Supplier>;
  suppliers: SuppliersResult;
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryAccountArgs = {
  id: Scalars['Int'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryAccountsArgs = {
  first: Scalars['Int'];
  orderBy?: InputMaybe<Array<QueryAccountsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryBankArgs = {
  code: Scalars['String'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryBankBranchArgs = {
  bank_code: Scalars['String'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryBankBranchesArgs = {
  first: Scalars['Int'];
  orderBy?: InputMaybe<Array<QueryBankBranchesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryBanksArgs = {
  first: Scalars['Int'];
  orderBy?: InputMaybe<Array<QueryBanksOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryBaseArgs = {
  id: Scalars['Int'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryBasesArgs = {
  first: Scalars['Int'];
  orderBy?: InputMaybe<Array<QueryBasesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryCalcTaxAmountArgs = {
  amount: Scalars['Int'];
  rounding: Rounding;
  taxRate: Scalars['Float'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryCalcTaxAmountBySendOrderClientArgs = {
  amount: Scalars['Int'];
  sendOrderClientId: Scalars['Int'];
  taxRate: Scalars['Float'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryCalcTaxAmountBySupplierArgs = {
  amount: Scalars['Int'];
  consumptionTaxRate: Scalars['Int'];
  supplierId: Scalars['Int'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryCalcTotalAmountBySendOrderClientArgs = {
  quantity: Scalars['Float'];
  sendOrderClientId: Scalars['Int'];
  unitPrice: Scalars['Float'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryCalcTotalAmountBySupplierArgs = {
  quantity: Scalars['Float'];
  supplierId: Scalars['Int'];
  unitPrice: Scalars['Float'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryConstructionArgs = {
  id: Scalars['Int'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryConstructionTypeArgs = {
  id: Scalars['Int'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryConstructionTypesArgs = {
  first: Scalars['Int'];
  orderBy?: InputMaybe<Array<QueryConstructionTypesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryConstructionsArgs = {
  condition: ConstructionSearchCondition;
  first: Scalars['Int'];
  orderBy?: InputMaybe<Array<QueryConstructionsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryDivisionArgs = {
  id: Scalars['Int'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryDivisionsArgs = {
  first: Scalars['Int'];
  orderBy?: InputMaybe<Array<QueryDivisionsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryEmployeeArgs = {
  id: Scalars['Int'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryEmployeesArgs = {
  base_name?: InputMaybe<Scalars['String']>;
  division_name?: InputMaybe<Scalars['String']>;
  employee_number?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<QueryEmployeesOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryExpenseItemArgs = {
  id: Scalars['Int'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryExpenseItemsArgs = {
  first: Scalars['Int'];
  orderBy?: InputMaybe<Array<QueryExpenseItemsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryProjectArgs = {
  id: Scalars['Int'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryProjectsArgs = {
  first: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<QueryProjectsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


/** Indicates what fields are available at the top level of a query operation. */
export type QuerySendOrderArgs = {
  id: Scalars['Int'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QuerySendOrderClientArgs = {
  id: Scalars['Int'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QuerySendOrderClientsArgs = {
  code?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<QuerySendOrderClientsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


/** Indicates what fields are available at the top level of a query operation. */
export type QuerySendOrdersArgs = {
  condition: SendOrderSearchCondition;
  orderBy?: InputMaybe<Array<QuerySendOrdersOrderByOrderByClause>>;
};


/** Indicates what fields are available at the top level of a query operation. */
export type QuerySupplierArgs = {
  id: Scalars['Int'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QuerySuppliersArgs = {
  code?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<QuerySuppliersOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};

/** Allowed column names for Query.accounts.orderBy. */
export enum QueryAccountsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.accounts.orderBy. */
export type QueryAccountsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryAccountsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.bankBranches.orderBy. */
export enum QueryBankBranchesOrderByColumn {
  BankCode = 'BANK_CODE',
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.bankBranches.orderBy. */
export type QueryBankBranchesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryBankBranchesOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.banks.orderBy. */
export enum QueryBanksOrderByColumn {
  Code = 'CODE',
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.banks.orderBy. */
export type QueryBanksOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryBanksOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.bases.orderBy. */
export enum QueryBasesOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.bases.orderBy. */
export type QueryBasesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryBasesOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.constructionTypes.orderBy. */
export enum QueryConstructionTypesOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.constructionTypes.orderBy. */
export type QueryConstructionTypesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryConstructionTypesOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.constructions.orderBy. */
export enum QueryConstructionsOrderByColumn {
  CompleteDate = 'COMPLETE_DATE',
  ContractJobAmount = 'CONTRACT_JOB_AMOUNT',
  Id = 'ID',
  Name = 'NAME',
  OrdersDate = 'ORDERS_DATE',
  ReceiveOrdersType = 'RECEIVE_ORDERS_TYPE',
  SalesRep = 'SALES_REP',
  SiteAgent = 'SITE_AGENT',
  Status = 'STATUS'
}

/** Order by clause for Query.constructions.orderBy. */
export type QueryConstructionsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryConstructionsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.divisions.orderBy. */
export enum QueryDivisionsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.divisions.orderBy. */
export type QueryDivisionsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryDivisionsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.employees.orderBy. */
export enum QueryEmployeesOrderByColumn {
  Base = 'BASE',
  CreatedAt = 'CREATED_AT',
  Division = 'DIVISION',
  EmployeeNumber = 'EMPLOYEE_NUMBER',
  Id = 'ID',
  Kana = 'KANA',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.employees.orderBy. */
export type QueryEmployeesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryEmployeesOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.expenseItems.orderBy. */
export enum QueryExpenseItemsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.expenseItems.orderBy. */
export type QueryExpenseItemsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryExpenseItemsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.projects.orderBy. */
export enum QueryProjectsOrderByColumn {
  Id = 'ID',
  Kana = 'KANA',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.projects.orderBy. */
export type QueryProjectsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryProjectsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.sendOrderClients.orderBy. */
export enum QuerySendOrderClientsOrderByColumn {
  ClosingDate = 'CLOSING_DATE',
  Code = 'CODE',
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  Kana = 'KANA',
  Name = 'NAME',
  OfficialBranchName = 'OFFICIAL_BRANCH_NAME',
  OfficialCompanyName = 'OFFICIAL_COMPANY_NAME',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.sendOrderClients.orderBy. */
export type QuerySendOrderClientsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QuerySendOrderClientsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.sendOrders.orderBy. */
export enum QuerySendOrdersOrderByColumn {
  AmountExcludingTax = 'AMOUNT_EXCLUDING_TAX',
  AmountIncludingTax = 'AMOUNT_INCLUDING_TAX',
  ConstructionCode = 'CONSTRUCTION_CODE',
  ConstructionName = 'CONSTRUCTION_NAME',
  ConstructionPeriod = 'CONSTRUCTION_PERIOD',
  ConsumptionTaxAmount = 'CONSUMPTION_TAX_AMOUNT',
  DeliveryAddress = 'DELIVERY_ADDRESS',
  DeliveryConditions = 'DELIVERY_CONDITIONS',
  DeliveryDeadline = 'DELIVERY_DEADLINE',
  DeliveryDestination = 'DELIVERY_DESTINATION',
  Id = 'ID',
  Note = 'NOTE',
  OrderDetails = 'ORDER_DETAILS',
  PaymentTerms = 'PAYMENT_TERMS',
  SalesRepresentativeId = 'SALES_REPRESENTATIVE_ID',
  SalesRepresentativeName = 'SALES_REPRESENTATIVE_NAME',
  SendOrderDate = 'SEND_ORDER_DATE',
  SiteAgentId = 'SITE_AGENT_ID',
  SiteAgentName = 'SITE_AGENT_NAME',
  Status = 'STATUS',
  SupplierAddress = 'SUPPLIER_ADDRESS',
  SupplierEstimate = 'SUPPLIER_ESTIMATE',
  SupplierId = 'SUPPLIER_ID',
  SupplierName = 'SUPPLIER_NAME',
  SupplierPostcode = 'SUPPLIER_POSTCODE',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.sendOrders.orderBy. */
export type QuerySendOrdersOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QuerySendOrdersOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Query.suppliers.orderBy. */
export enum QuerySuppliersOrderByColumn {
  ClosingDate = 'CLOSING_DATE',
  Code = 'CODE',
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  Kana = 'KANA',
  Name = 'NAME',
  OfficialBranchName = 'OFFICIAL_BRANCH_NAME',
  OfficialCompanyName = 'OFFICIAL_COMPANY_NAME',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Query.suppliers.orderBy. */
export type QuerySuppliersOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QuerySuppliersOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export enum ReceiveOrdersAccuracy {
  /** A */
  A = 'A',
  /** B */
  B = 'B',
  /** C */
  C = 'C'
}

export enum ReceiveOrdersType {
  /** Construction */
  Construction = 'CONSTRUCTION',
  /** Fault */
  Fault = 'FAULT',
  /** Maintenance */
  Maintenance = 'MAINTENANCE',
  /** Product sales */
  ProductSales = 'PRODUCT_SALES',
  /** Service */
  Service = 'SERVICE'
}

export type Role = {
  __typename?: 'Role';
  ability?: Maybe<Scalars['String']>;
  employees: Array<Employee>;
  id: Scalars['ID'];
  kana?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum Rounding {
  /** Rounding */
  Rounding = 'ROUNDING',
  /** Round down */
  RoundDown = 'ROUND_DOWN',
  /** Round up */
  RoundUp = 'ROUND_UP'
}

export type SendOrder = {
  __typename?: 'SendOrder';
  base?: Maybe<Base>;
  base_address?: Maybe<Scalars['String']>;
  base_fax?: Maybe<Scalars['String']>;
  base_name?: Maybe<Scalars['String']>;
  base_postcode?: Maybe<Scalars['String']>;
  base_tel?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  construction?: Maybe<Construction>;
  construction_code?: Maybe<Scalars['String']>;
  construction_kana?: Maybe<Scalars['String']>;
  construction_name?: Maybe<Scalars['String']>;
  construction_period?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  deleted_by?: Maybe<Employee>;
  delivery_address?: Maybe<Scalars['String']>;
  delivery_conditions?: Maybe<Scalars['String']>;
  delivery_deadline?: Maybe<Scalars['String']>;
  delivery_destination?: Maybe<Scalars['String']>;
  force_deletable: Scalars['Boolean'];
  id: Scalars['ID'];
  latest_send_order_revision?: Maybe<SendOrderRevision>;
  note?: Maybe<Scalars['String']>;
  order_details?: Maybe<Scalars['String']>;
  order_employee?: Maybe<Employee>;
  order_employee_kana?: Maybe<Scalars['String']>;
  order_employee_name?: Maybe<Scalars['String']>;
  payment_terms?: Maybe<Scalars['String']>;
  resettable: Scalars['Boolean'];
  revision?: Maybe<Scalars['Int']>;
  sales_representative?: Maybe<Employee>;
  sales_representative_kana?: Maybe<Scalars['String']>;
  sales_representative_name?: Maybe<Scalars['String']>;
  send_order_date?: Maybe<Scalars['Date']>;
  send_order_revisions: Array<SendOrderRevision>;
  site_agent?: Maybe<Employee>;
  site_agent_kana?: Maybe<Scalars['String']>;
  site_agent_name?: Maybe<Scalars['String']>;
  soft_deletable: Scalars['Boolean'];
  status: SendOrderStatus;
  status_value: Scalars['Int'];
  supplier?: Maybe<Supplier>;
  supplier_address?: Maybe<Scalars['String']>;
  supplier_estimate?: Maybe<Scalars['String']>;
  supplier_kana?: Maybe<Scalars['String']>;
  supplier_name?: Maybe<Scalars['String']>;
  supplier_postcode?: Maybe<Scalars['String']>;
  supplier_registration_request_id?: Maybe<MasterRegistrationRequest>;
  tax_rounding?: Maybe<Rounding>;
  tax_rounding_value?: Maybe<Scalars['Int']>;
  total_rounding?: Maybe<Rounding>;
  total_rounding_value?: Maybe<Scalars['Int']>;
  updatable: Scalars['Boolean'];
  updated_at?: Maybe<Scalars['DateTime']>;
  updated_by: Employee;
};

export enum SendOrderChangeItems {
  /** Description */
  Description = 'DESCRIPTION',
  /** Expenses accounts type */
  ExpensesAccountsType = 'EXPENSES_ACCOUNTS_TYPE',
  /** Expenses type */
  ExpensesType = 'EXPENSES_TYPE',
  /** Management */
  Management = 'MANAGEMENT',
  /** Quantity */
  Quantity = 'QUANTITY',
  /** Tax rate */
  TaxRate = 'TAX_RATE',
  /** Unit */
  Unit = 'UNIT',
  /** Unit price */
  UnitPrice = 'UNIT_PRICE'
}

export enum SendOrderChangeType {
  /** New order */
  NewOrder = 'NEW_ORDER',
  /** Order backlog change */
  OrderBacklogChange = 'ORDER_BACKLOG_CHANGE',
  /** Substitute */
  Substitute = 'SUBSTITUTE',
  /** Unit price change */
  UnitPriceChange = 'UNIT_PRICE_CHANGE'
}

export type SendOrderClient = {
  __typename?: 'SendOrderClient';
  address?: Maybe<Scalars['String']>;
  bank_branch_code?: Maybe<Scalars['String']>;
  bank_code?: Maybe<Scalars['String']>;
  bill_site?: Maybe<Scalars['Int64']>;
  closing_date?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  collection_charge?: Maybe<Employee>;
  collection_date?: Maybe<Scalars['Int']>;
  collection_month?: Maybe<CollectionMonth>;
  collection_month_value?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  designated_request?: Maybe<Scalars['String']>;
  email_addresses?: Maybe<Array<Maybe<EmailAddress>>>;
  honorific_title?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kana?: Maybe<Scalars['String']>;
  last_order_date?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  number_of_orders: Scalars['Int'];
  official_branch_name?: Maybe<Scalars['String']>;
  official_company_name?: Maybe<Scalars['String']>;
  phone_numbers?: Maybe<Array<Maybe<PhoneNumber>>>;
  postcode?: Maybe<Scalars['String']>;
  send_order_client_type: SendOrderClientType;
  send_order_client_type_value?: Maybe<Scalars['Int']>;
  tax_rounding: Rounding;
  tax_rounding_value?: Maybe<Scalars['Int']>;
  total_rounding: Rounding;
  total_rounding_value?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type SendOrderClientInput = {
  address?: InputMaybe<Scalars['String']>;
  bank_branch_code?: InputMaybe<Scalars['String']>;
  bank_code?: InputMaybe<Scalars['String']>;
  bill_site?: InputMaybe<Scalars['String']>;
  closing_date?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  collection_charge_id?: InputMaybe<Scalars['String']>;
  collection_date?: InputMaybe<Scalars['String']>;
  collection_month?: InputMaybe<CollectionMonth>;
  designated_request?: InputMaybe<Scalars['String']>;
  email_addresses?: InputMaybe<Array<InputMaybe<EmailAddressInput>>>;
  honorific_title?: InputMaybe<Scalars['String']>;
  kana?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  official_branch_name?: InputMaybe<Scalars['String']>;
  official_company_name?: InputMaybe<Scalars['String']>;
  phone_numbers?: InputMaybe<Array<InputMaybe<PhoneNumberInput>>>;
  postcode?: InputMaybe<Scalars['String']>;
  send_order_client_type?: InputMaybe<SendOrderClientType>;
  tax_rounding?: InputMaybe<Rounding>;
  total_rounding?: InputMaybe<Rounding>;
};

/** A paginated list of SendOrderClient items. */
export type SendOrderClientPaginator = {
  __typename?: 'SendOrderClientPaginator';
  /** A list of SendOrderClient items. */
  data: Array<SendOrderClient>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum SendOrderClientType {
  /** Government office */
  GovernmentOffice = 'GOVERNMENT_OFFICE',
  /** Municipalities */
  Municipalities = 'MUNICIPALITIES',
  /** Others */
  Others = 'OTHERS',
  /** Other builders */
  OtherBuilders = 'OTHER_BUILDERS',
  /** Other companies */
  OtherCompanies = 'OTHER_COMPANIES',
  /** Prime contractor */
  PrimeContractor = 'PRIME_CONTRACTOR',
  /** Private company */
  PrivateCompany = 'PRIVATE_COMPANY'
}

export type SendOrderDetail = {
  __typename?: 'SendOrderDetail';
  change_no: Scalars['Int'];
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  latest_send_order_detail_change?: Maybe<SendOrderDetailChange>;
  send_order_detail_changes: Array<SendOrderDetailChange>;
  send_order_detail_type: SendOrderDetailType;
  send_order_detail_type_value: Scalars['Int'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type SendOrderDetailChange = {
  __typename?: 'SendOrderDetailChange';
  alternative_send_order_detail_id?: Maybe<Scalars['Int']>;
  amount_excluding_tax?: Maybe<Scalars['Int64']>;
  change_items: SendOrderChangeItems;
  change_items_value: Scalars['Int'];
  change_no: Scalars['Int'];
  change_type: SendOrderChangeType;
  change_type_value: Scalars['Int'];
  consumption_tax_rate?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deliveried_quantity?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  expense_account_type?: Maybe<Scalars['Int']>;
  expense_type?: Maybe<ExpenseType>;
  expense_type_value?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  not_manage_backlog?: Maybe<Scalars['Int']>;
  payment_amount?: Maybe<Scalars['Int64']>;
  quantity?: Maybe<Scalars['Float']>;
  remaining_deliveried_quantity?: Maybe<Scalars['Float']>;
  remaining_payment_amount?: Maybe<Scalars['Int']>;
  send_order_detail: SendOrderDetail;
  transaction_type: TransactionType;
  transaction_type_value: Scalars['Int'];
  unit?: Maybe<Scalars['String']>;
  unit_price?: Maybe<Scalars['Float']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  updated_by?: Maybe<Employee>;
};

export type SendOrderDetailInput = {
  amount_excluding_tax?: InputMaybe<Scalars['String']>;
  consumption_tax_rate?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  details_line_no?: InputMaybe<Scalars['String']>;
  expense_type?: InputMaybe<ExpenseType>;
  not_manage_backlog?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['String']>;
  send_order_detail_type?: InputMaybe<SendOrderDetailType>;
  unit?: InputMaybe<Scalars['String']>;
  unit_price?: InputMaybe<Scalars['String']>;
};

export enum SendOrderDetailType {
  /** Note */
  Note = 'NOTE',
  /** Order product */
  OrderProduct = 'ORDER_PRODUCT'
}

export type SendOrderInput = {
  base_address?: InputMaybe<Scalars['String']>;
  base_fax?: InputMaybe<Scalars['String']>;
  base_id?: InputMaybe<Scalars['String']>;
  /** NOTE: 現状まだ拠点(base)に以下の`base_*`の情報を持っていないので、`base_*`もinputに定義しておく */
  base_name?: InputMaybe<Scalars['String']>;
  base_postcode?: InputMaybe<Scalars['String']>;
  base_tel?: InputMaybe<Scalars['String']>;
  company_name?: InputMaybe<Scalars['String']>;
  construction_code?: InputMaybe<Scalars['String']>;
  construction_id?: InputMaybe<Scalars['String']>;
  construction_kana?: InputMaybe<Scalars['String']>;
  construction_name?: InputMaybe<Scalars['String']>;
  construction_period?: InputMaybe<Scalars['String']>;
  delivery_address?: InputMaybe<Scalars['String']>;
  delivery_conditions?: InputMaybe<Scalars['String']>;
  delivery_deadline?: InputMaybe<Scalars['String']>;
  delivery_destination?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  order_details?: InputMaybe<Scalars['String']>;
  payment_terms?: InputMaybe<Scalars['String']>;
  sales_representative_id?: InputMaybe<Scalars['String']>;
  sales_representative_kana?: InputMaybe<Scalars['String']>;
  sales_representative_name?: InputMaybe<Scalars['String']>;
  send_order_date?: InputMaybe<Scalars['String']>;
  send_order_details?: InputMaybe<Array<InputMaybe<SendOrderDetailInput>>>;
  site_agent_id?: InputMaybe<Scalars['String']>;
  site_agent_kana?: InputMaybe<Scalars['String']>;
  site_agent_name?: InputMaybe<Scalars['String']>;
  supplier_address?: InputMaybe<Scalars['String']>;
  supplier_estimate?: InputMaybe<Scalars['String']>;
  supplier_id?: InputMaybe<Scalars['String']>;
  supplier_postcode?: InputMaybe<Scalars['String']>;
  supplier_registration_request_id?: InputMaybe<Scalars['String']>;
};

export type SendOrderRevision = {
  __typename?: 'SendOrderRevision';
  amount_excluding_tax: Scalars['Int64'];
  amount_including_tax: Scalars['Int64'];
  consumption_tax_amount: Scalars['Int64'];
  created_at?: Maybe<Scalars['DateTime']>;
  delivery_completed_lines_count: Scalars['Int'];
  id: Scalars['ID'];
  real_detail_lines_count: Scalars['Int'];
  revision: Scalars['Int'];
  send_order: SendOrder;
  send_order_details: Array<SendOrderDetail>;
  settlement_completed_lines_count: Scalars['Int'];
  total_detail_lines_count: Scalars['Int'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type SendOrderSearchCondition = {
  amount_excluding_tax?: InputMaybe<Scalars['String']>;
  amount_including_tax?: InputMaybe<Scalars['String']>;
  construction_id?: InputMaybe<Scalars['String']>;
  consumption_tax_amount?: InputMaybe<Scalars['String']>;
  data_types?: InputMaybe<Array<InputMaybe<DataType>>>;
  id?: InputMaybe<Scalars['String']>;
  max_amount_excluding_tax?: InputMaybe<Scalars['String']>;
  max_amount_including_tax?: InputMaybe<Scalars['String']>;
  max_consumption_tax_amount?: InputMaybe<Scalars['String']>;
  max_id?: InputMaybe<Scalars['String']>;
  min_amount_excluding_tax?: InputMaybe<Scalars['String']>;
  min_amount_including_tax?: InputMaybe<Scalars['String']>;
  min_consumption_tax_amount?: InputMaybe<Scalars['String']>;
  min_id?: InputMaybe<Scalars['String']>;
  order_employee_id?: InputMaybe<Scalars['String']>;
  sales_representative_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  send_order_date_from?: InputMaybe<Scalars['String']>;
  send_order_date_to?: InputMaybe<Scalars['String']>;
  site_agent_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  statuses?: InputMaybe<Array<InputMaybe<SendOrderStatus>>>;
  supplier_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['String']>;
  updated_at_from?: InputMaybe<Scalars['String']>;
  updated_at_to?: InputMaybe<Scalars['String']>;
  updated_by_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum SendOrderStatus {
  /** Draft */
  Draft = 'DRAFT',
  /** Order */
  Order = 'ORDER',
  /** Order reset */
  OrderReset = 'ORDER_RESET',
  /** Payment full */
  PaymentFull = 'PAYMENT_FULL',
  /** Payment partial */
  PaymentPartial = 'PAYMENT_PARTIAL',
  /** Settlement complete */
  SettlementComplete = 'SETTLEMENT_COMPLETE',
  /** Settlement part */
  SettlementPart = 'SETTLEMENT_PART'
}

export enum Sex {
  /** Female */
  Female = 'FEMALE',
  /** Male */
  Male = 'MALE',
  /** Unknown */
  Unknown = 'UNKNOWN'
}

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type Supplier = {
  __typename?: 'Supplier';
  account_holder?: Maybe<Scalars['String']>;
  account_number?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  bank_branch_id?: Maybe<Scalars['Int']>;
  bank_id?: Maybe<Scalars['Int']>;
  bill_holder?: Maybe<Scalars['String']>;
  bill_shipping_cost?: Maybe<Scalars['Int64']>;
  bill_site?: Maybe<Scalars['Int64']>;
  bill_standard_amount?: Maybe<Scalars['Int64']>;
  bill_standard_amount_2?: Maybe<Scalars['Int64']>;
  bill_standard_amount_3?: Maybe<Scalars['Int64']>;
  closing_date?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  deposit_type?: Maybe<Scalars['Int']>;
  email_addresses?: Maybe<Array<Maybe<EmailAddress>>>;
  honorific_title?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kana?: Maybe<Scalars['String']>;
  last_order_date?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  number_of_orders: Scalars['Int'];
  official_branch_name?: Maybe<Scalars['String']>;
  official_company_name?: Maybe<Scalars['String']>;
  payment_day?: Maybe<Scalars['Int']>;
  payment_month?: Maybe<PaymentMonth>;
  payment_month_value?: Maybe<Scalars['Int']>;
  payment_type?: Maybe<PaymentType>;
  payment_type_value?: Maybe<Scalars['Int']>;
  phone_numbers?: Maybe<Array<Maybe<PhoneNumber>>>;
  postcode?: Maybe<Scalars['String']>;
  send_orders: Array<SendOrder>;
  tax_rounding: Rounding;
  tax_rounding_value?: Maybe<Scalars['Int']>;
  total_rounding: Rounding;
  total_rounding_value?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type SupplierInput = {
  account_holder?: InputMaybe<Scalars['String']>;
  account_number?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  bank_branch_id?: InputMaybe<Scalars['String']>;
  bank_id?: InputMaybe<Scalars['String']>;
  bill_holder?: InputMaybe<Scalars['String']>;
  bill_shipping_cost?: InputMaybe<Scalars['String']>;
  bill_site?: InputMaybe<Scalars['String']>;
  bill_standard_amount?: InputMaybe<Scalars['String']>;
  bill_standard_amount2?: InputMaybe<Scalars['String']>;
  bill_standard_amount3?: InputMaybe<Scalars['String']>;
  closing_date?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  deposit_type?: InputMaybe<Scalars['String']>;
  email_addresses?: InputMaybe<Array<InputMaybe<EmailAddressInput>>>;
  honorific_title?: InputMaybe<Scalars['String']>;
  kana?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  official_branch_name?: InputMaybe<Scalars['String']>;
  official_company_name?: InputMaybe<Scalars['String']>;
  payment_day?: InputMaybe<Scalars['String']>;
  payment_month?: InputMaybe<PaymentMonth>;
  payment_type?: InputMaybe<PaymentType>;
  phone_numbers?: InputMaybe<Array<InputMaybe<PhoneNumberInput>>>;
  postcode?: InputMaybe<Scalars['String']>;
  tax_rounding?: InputMaybe<Rounding>;
  total_rounding?: InputMaybe<Rounding>;
};

export type SuppliersResult = {
  __typename?: 'SuppliersResult';
  data: Array<Supplier>;
  paginatorInfo: PaginatorInfo;
};

export enum TaxRate {
  /** Eight */
  Eight = 'EIGHT',
  /** Five */
  Five = 'FIVE',
  /** Ten */
  Ten = 'TEN',
  /** Three */
  Three = 'THREE',
  /** Zero */
  Zero = 'ZERO'
}

export enum TaxRateKind {
  /** Reduced */
  Reduced = 'REDUCED',
  /** Standard */
  Standard = 'STANDARD'
}

export enum TaxRateType {
  /** Ten */
  Ten = 'TEN',
  /** Zero */
  Zero = 'ZERO'
}

export enum TaxTreatment {
  /** Consumption */
  Consumption = 'CONSUMPTION',
  /** Excluded */
  Excluded = 'EXCLUDED',
  /** Included */
  Included = 'INCLUDED',
  /** None */
  None = 'NONE'
}

export enum TransactionType {
  /** Minus */
  Minus = 'MINUS',
  /** None */
  None = 'NONE',
  /** Plus */
  Plus = 'PLUS'
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

/** Account of a person who utilizes this application. */
export type User = {
  __typename?: 'User';
  /** When the account was created. */
  created_at: Scalars['DateTime'];
  /** Unique email address. */
  email: Scalars['String'];
  /** When the email was verified. */
  email_verified_at?: Maybe<Scalars['DateTime']>;
  /** Unique primary key. */
  id: Scalars['ID'];
  /** Non-unique name. */
  name: Scalars['String'];
  /** When the account was last updated. */
  updated_at: Scalars['DateTime'];
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Account: ResolverTypeWrapper<Account>;
  AccountInput: AccountInput;
  AccountPaginator: ResolverTypeWrapper<AccountPaginator>;
  ApiToken: ResolverTypeWrapper<ApiToken>;
  Bank: ResolverTypeWrapper<Bank>;
  BankBranch: ResolverTypeWrapper<BankBranch>;
  BankBranchPaginator: ResolverTypeWrapper<BankBranchPaginator>;
  BankPaginator: ResolverTypeWrapper<BankPaginator>;
  Base: ResolverTypeWrapper<Base>;
  BaseInput: BaseInput;
  BasePaginator: ResolverTypeWrapper<BasePaginator>;
  BillingType: BillingType;
  BloodType: BloodType;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  CollectionMonth: CollectionMonth;
  Construction: ResolverTypeWrapper<Construction>;
  ConstructionAttributeType: ConstructionAttributeType;
  ConstructionInput: ConstructionInput;
  ConstructionPaginator: ResolverTypeWrapper<ConstructionPaginator>;
  ConstructionSearchCondition: ConstructionSearchCondition;
  ConstructionStatus: ConstructionStatus;
  ConstructionType: ResolverTypeWrapper<ConstructionType>;
  ConstructionTypeInput: ConstructionTypeInput;
  ConstructionTypePaginator: ResolverTypeWrapper<ConstructionTypePaginator>;
  ConsumptionTaxCalcType: ConsumptionTaxCalcType;
  ContractJobType: ContractJobType;
  Customer: ResolverTypeWrapper<Customer>;
  DataType: DataType;
  Date: ResolverTypeWrapper<Scalars['Date']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  Division: ResolverTypeWrapper<Division>;
  DivisionInput: DivisionInput;
  DivisionPaginator: ResolverTypeWrapper<DivisionPaginator>;
  DivisionStatus: DivisionStatus;
  EmailAddress: ResolverTypeWrapper<EmailAddress>;
  EmailAddressInput: EmailAddressInput;
  Employee: ResolverTypeWrapper<Employee>;
  EmployeeInput: EmployeeInput;
  EmployeePaginator: ResolverTypeWrapper<EmployeePaginator>;
  Estimate: ResolverTypeWrapper<Estimate>;
  ExpenseAccountType: ExpenseAccountType;
  ExpenseItem: ResolverTypeWrapper<ExpenseItem>;
  ExpenseItemInput: ExpenseItemInput;
  ExpenseItemPaginator: ResolverTypeWrapper<ExpenseItemPaginator>;
  ExpenseType: ExpenseType;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Int64: ResolverTypeWrapper<Scalars['Int64']>;
  LineType: LineType;
  LoginAsEmployeeInput: LoginAsEmployeeInput;
  MasterDataType: MasterDataType;
  MasterRegistrationRequest: ResolverTypeWrapper<MasterRegistrationRequest>;
  MasterRegistrationRequestDataType: MasterRegistrationRequestDataType;
  Mutation: ResolverTypeWrapper<{}>;
  OrderByClause: OrderByClause;
  OrderByRelationAggregateFunction: OrderByRelationAggregateFunction;
  OrderByRelationWithColumnAggregateFunction: OrderByRelationWithColumnAggregateFunction;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  PaginatorInfo: ResolverTypeWrapper<PaginatorInfo>;
  PaymentMonth: PaymentMonth;
  PaymentTerm: PaymentTerm;
  PaymentType: PaymentType;
  PhoneNumber: ResolverTypeWrapper<PhoneNumber>;
  PhoneNumberInput: PhoneNumberInput;
  Project: ResolverTypeWrapper<Project>;
  ProjectConstructionInput: ProjectConstructionInput;
  ProjectInput: ProjectInput;
  ProjectPaginator: ResolverTypeWrapper<ProjectPaginator>;
  PublicPrivateWorkType: PublicPrivateWorkType;
  Query: ResolverTypeWrapper<{}>;
  QueryAccountsOrderByColumn: QueryAccountsOrderByColumn;
  QueryAccountsOrderByOrderByClause: QueryAccountsOrderByOrderByClause;
  QueryBankBranchesOrderByColumn: QueryBankBranchesOrderByColumn;
  QueryBankBranchesOrderByOrderByClause: QueryBankBranchesOrderByOrderByClause;
  QueryBanksOrderByColumn: QueryBanksOrderByColumn;
  QueryBanksOrderByOrderByClause: QueryBanksOrderByOrderByClause;
  QueryBasesOrderByColumn: QueryBasesOrderByColumn;
  QueryBasesOrderByOrderByClause: QueryBasesOrderByOrderByClause;
  QueryConstructionTypesOrderByColumn: QueryConstructionTypesOrderByColumn;
  QueryConstructionTypesOrderByOrderByClause: QueryConstructionTypesOrderByOrderByClause;
  QueryConstructionsOrderByColumn: QueryConstructionsOrderByColumn;
  QueryConstructionsOrderByOrderByClause: QueryConstructionsOrderByOrderByClause;
  QueryDivisionsOrderByColumn: QueryDivisionsOrderByColumn;
  QueryDivisionsOrderByOrderByClause: QueryDivisionsOrderByOrderByClause;
  QueryEmployeesOrderByColumn: QueryEmployeesOrderByColumn;
  QueryEmployeesOrderByOrderByClause: QueryEmployeesOrderByOrderByClause;
  QueryExpenseItemsOrderByColumn: QueryExpenseItemsOrderByColumn;
  QueryExpenseItemsOrderByOrderByClause: QueryExpenseItemsOrderByOrderByClause;
  QueryProjectsOrderByColumn: QueryProjectsOrderByColumn;
  QueryProjectsOrderByOrderByClause: QueryProjectsOrderByOrderByClause;
  QuerySendOrderClientsOrderByColumn: QuerySendOrderClientsOrderByColumn;
  QuerySendOrderClientsOrderByOrderByClause: QuerySendOrderClientsOrderByOrderByClause;
  QuerySendOrdersOrderByColumn: QuerySendOrdersOrderByColumn;
  QuerySendOrdersOrderByOrderByClause: QuerySendOrdersOrderByOrderByClause;
  QuerySuppliersOrderByColumn: QuerySuppliersOrderByColumn;
  QuerySuppliersOrderByOrderByClause: QuerySuppliersOrderByOrderByClause;
  ReceiveOrdersAccuracy: ReceiveOrdersAccuracy;
  ReceiveOrdersType: ReceiveOrdersType;
  Role: ResolverTypeWrapper<Role>;
  Rounding: Rounding;
  SendOrder: ResolverTypeWrapper<SendOrder>;
  SendOrderChangeItems: SendOrderChangeItems;
  SendOrderChangeType: SendOrderChangeType;
  SendOrderClient: ResolverTypeWrapper<SendOrderClient>;
  SendOrderClientInput: SendOrderClientInput;
  SendOrderClientPaginator: ResolverTypeWrapper<SendOrderClientPaginator>;
  SendOrderClientType: SendOrderClientType;
  SendOrderDetail: ResolverTypeWrapper<SendOrderDetail>;
  SendOrderDetailChange: ResolverTypeWrapper<SendOrderDetailChange>;
  SendOrderDetailInput: SendOrderDetailInput;
  SendOrderDetailType: SendOrderDetailType;
  SendOrderInput: SendOrderInput;
  SendOrderRevision: ResolverTypeWrapper<SendOrderRevision>;
  SendOrderSearchCondition: SendOrderSearchCondition;
  SendOrderStatus: SendOrderStatus;
  Sex: Sex;
  SimplePaginatorInfo: ResolverTypeWrapper<SimplePaginatorInfo>;
  SortOrder: SortOrder;
  String: ResolverTypeWrapper<Scalars['String']>;
  Supplier: ResolverTypeWrapper<Supplier>;
  SupplierInput: SupplierInput;
  SuppliersResult: ResolverTypeWrapper<SuppliersResult>;
  TaxRate: TaxRate;
  TaxRateKind: TaxRateKind;
  TaxRateType: TaxRateType;
  TaxTreatment: TaxTreatment;
  TransactionType: TransactionType;
  Trashed: Trashed;
  User: ResolverTypeWrapper<User>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Account: Account;
  AccountInput: AccountInput;
  AccountPaginator: AccountPaginator;
  ApiToken: ApiToken;
  Bank: Bank;
  BankBranch: BankBranch;
  BankBranchPaginator: BankBranchPaginator;
  BankPaginator: BankPaginator;
  Base: Base;
  BaseInput: BaseInput;
  BasePaginator: BasePaginator;
  Boolean: Scalars['Boolean'];
  Construction: Construction;
  ConstructionInput: ConstructionInput;
  ConstructionPaginator: ConstructionPaginator;
  ConstructionSearchCondition: ConstructionSearchCondition;
  ConstructionType: ConstructionType;
  ConstructionTypeInput: ConstructionTypeInput;
  ConstructionTypePaginator: ConstructionTypePaginator;
  Customer: Customer;
  Date: Scalars['Date'];
  DateTime: Scalars['DateTime'];
  Division: Division;
  DivisionInput: DivisionInput;
  DivisionPaginator: DivisionPaginator;
  EmailAddress: EmailAddress;
  EmailAddressInput: EmailAddressInput;
  Employee: Employee;
  EmployeeInput: EmployeeInput;
  EmployeePaginator: EmployeePaginator;
  Estimate: Estimate;
  ExpenseItem: ExpenseItem;
  ExpenseItemInput: ExpenseItemInput;
  ExpenseItemPaginator: ExpenseItemPaginator;
  Float: Scalars['Float'];
  ID: Scalars['ID'];
  Int: Scalars['Int'];
  Int64: Scalars['Int64'];
  LoginAsEmployeeInput: LoginAsEmployeeInput;
  MasterRegistrationRequest: MasterRegistrationRequest;
  Mutation: {};
  OrderByClause: OrderByClause;
  PageInfo: PageInfo;
  PaginatorInfo: PaginatorInfo;
  PhoneNumber: PhoneNumber;
  PhoneNumberInput: PhoneNumberInput;
  Project: Project;
  ProjectConstructionInput: ProjectConstructionInput;
  ProjectInput: ProjectInput;
  ProjectPaginator: ProjectPaginator;
  Query: {};
  QueryAccountsOrderByOrderByClause: QueryAccountsOrderByOrderByClause;
  QueryBankBranchesOrderByOrderByClause: QueryBankBranchesOrderByOrderByClause;
  QueryBanksOrderByOrderByClause: QueryBanksOrderByOrderByClause;
  QueryBasesOrderByOrderByClause: QueryBasesOrderByOrderByClause;
  QueryConstructionTypesOrderByOrderByClause: QueryConstructionTypesOrderByOrderByClause;
  QueryConstructionsOrderByOrderByClause: QueryConstructionsOrderByOrderByClause;
  QueryDivisionsOrderByOrderByClause: QueryDivisionsOrderByOrderByClause;
  QueryEmployeesOrderByOrderByClause: QueryEmployeesOrderByOrderByClause;
  QueryExpenseItemsOrderByOrderByClause: QueryExpenseItemsOrderByOrderByClause;
  QueryProjectsOrderByOrderByClause: QueryProjectsOrderByOrderByClause;
  QuerySendOrderClientsOrderByOrderByClause: QuerySendOrderClientsOrderByOrderByClause;
  QuerySendOrdersOrderByOrderByClause: QuerySendOrdersOrderByOrderByClause;
  QuerySuppliersOrderByOrderByClause: QuerySuppliersOrderByOrderByClause;
  Role: Role;
  SendOrder: SendOrder;
  SendOrderClient: SendOrderClient;
  SendOrderClientInput: SendOrderClientInput;
  SendOrderClientPaginator: SendOrderClientPaginator;
  SendOrderDetail: SendOrderDetail;
  SendOrderDetailChange: SendOrderDetailChange;
  SendOrderDetailInput: SendOrderDetailInput;
  SendOrderInput: SendOrderInput;
  SendOrderRevision: SendOrderRevision;
  SendOrderSearchCondition: SendOrderSearchCondition;
  SimplePaginatorInfo: SimplePaginatorInfo;
  String: Scalars['String'];
  Supplier: Supplier;
  SupplierInput: SupplierInput;
  SuppliersResult: SuppliersResult;
  User: User;
};

export type AccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['Account'] = ResolversParentTypes['Account']> = {
  account_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountPaginator'] = ResolversParentTypes['AccountPaginator']> = {
  data?: Resolver<Array<ResolversTypes['Account']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApiTokenResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApiToken'] = ResolversParentTypes['ApiToken']> = {
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BankResolvers<ContextType = any, ParentType extends ResolversParentTypes['Bank'] = ResolversParentTypes['Bank']> = {
  bank_branches?: Resolver<Array<ResolversTypes['BankBranch']>, ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  kana?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BankBranchResolvers<ContextType = any, ParentType extends ResolversParentTypes['BankBranch'] = ResolversParentTypes['BankBranch']> = {
  bank?: Resolver<ResolversTypes['Bank'], ParentType, ContextType>;
  bank_code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  kana?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BankBranchPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['BankBranchPaginator'] = ResolversParentTypes['BankBranchPaginator']> = {
  data?: Resolver<Array<ResolversTypes['BankBranch']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BankPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['BankPaginator'] = ResolversParentTypes['BankPaginator']> = {
  data?: Resolver<Array<ResolversTypes['Bank']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Base'] = ResolversParentTypes['Base']> = {
  base_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  display_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BasePaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['BasePaginator'] = ResolversParentTypes['BasePaginator']> = {
  data?: Resolver<Array<ResolversTypes['Base']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConstructionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Construction'] = ResolversParentTypes['Construction']> = {
  attribute_type?: Resolver<ResolversTypes['ConstructionAttributeType'], ParentType, ContextType>;
  attribute_type_value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  billing_target?: Resolver<Maybe<ResolversTypes['SendOrderClient']>, ParentType, ContextType>;
  billing_type?: Resolver<Maybe<ResolversTypes['BillingType']>, ParentType, ContextType>;
  billing_type_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  complete_date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  construction_designer?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>;
  construction_location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  construction_rep?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>;
  contract_construction_period_end_date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  contract_construction_period_start_date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  contract_job_amount?: Resolver<Maybe<ResolversTypes['Int64']>, ParentType, ContextType>;
  contract_job_amount_excluding_tax?: Resolver<Maybe<ResolversTypes['Int64']>, ParentType, ContextType>;
  contract_job_consumption_tax_amount?: Resolver<Maybe<ResolversTypes['Int64']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  customer_rep?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deletable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  destination_target?: Resolver<Maybe<ResolversTypes['SendOrderClient']>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['Division']>, ParentType, ContextType>;
  estimate?: Resolver<Maybe<ResolversTypes['Estimate']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  implementation_construction_period_end_date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  implementation_construction_period_start_date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  kana?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lead_engineer?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orders_date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  overview?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payment_term_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  payment_terms?: Resolver<Maybe<ResolversTypes['PaymentTerm']>, ParentType, ContextType>;
  project?: Resolver<Maybe<ResolversTypes['Project']>, ParentType, ContextType>;
  public_private_works_type?: Resolver<Maybe<ResolversTypes['PublicPrivateWorkType']>, ParentType, ContextType>;
  public_private_works_type_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  receive_orders_type?: Resolver<ResolversTypes['ReceiveOrdersType'], ParentType, ContextType>;
  receive_orders_type_value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sales_rep?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>;
  send_order_client?: Resolver<ResolversTypes['SendOrderClient'], ParentType, ContextType>;
  send_orders?: Resolver<Array<ResolversTypes['SendOrder']>, ParentType, ContextType>;
  site_agent?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ConstructionStatus'], ParentType, ContextType>;
  status_value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  supervising_engineer?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>;
  tax_rate_type?: Resolver<Maybe<ResolversTypes['TaxRateType']>, ParentType, ContextType>;
  tax_rate_type_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConstructionPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConstructionPaginator'] = ResolversParentTypes['ConstructionPaginator']> = {
  data?: Resolver<Array<ResolversTypes['Construction']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConstructionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConstructionType'] = ResolversParentTypes['ConstructionType']> = {
  construction_type_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConstructionTypePaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConstructionTypePaginator'] = ResolversParentTypes['ConstructionTypePaginator']> = {
  data?: Resolver<Array<ResolversTypes['ConstructionType']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Customer'] = ResolversParentTypes['Customer']> = {
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  customer_kana?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  projects?: Resolver<Array<ResolversTypes['Project']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DivisionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Division'] = ResolversParentTypes['Division']> = {
  constructions?: Resolver<Array<ResolversTypes['Construction']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  direct_division?: Resolver<ResolversTypes['DivisionStatus'], ParentType, ContextType>;
  display_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  division_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DivisionPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['DivisionPaginator'] = ResolversParentTypes['DivisionPaginator']> = {
  data?: Resolver<Array<ResolversTypes['Division']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailAddress'] = ResolversParentTypes['EmailAddress']> = {
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  data_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  data_type?: Resolver<ResolversTypes['MasterDataType'], ParentType, ContextType>;
  data_type_value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  display_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  email_address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmployeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Employee'] = ResolversParentTypes['Employee']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  base?: Resolver<Maybe<ResolversTypes['Base']>, ParentType, ContextType>;
  basic_pension_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  birthdate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  blood_type?: Resolver<ResolversTypes['BloodType'], ParentType, ContextType>;
  blood_type_value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  construction_designer_constructions?: Resolver<Array<ResolversTypes['Construction']>, ParentType, ContextType>;
  construction_projects?: Resolver<Array<ResolversTypes['Project']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['Division']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  employee_number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  employment_insurance_insured_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  health_insurance_insured_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  joining_date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  kana?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  labor_cost_unit_price?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lead_engineer_constructions?: Resolver<Array<ResolversTypes['Construction']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone_numbers?: Resolver<Maybe<Array<Maybe<ResolversTypes['PhoneNumber']>>>, ParentType, ContextType>;
  postcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  retirement_date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  roles?: Resolver<Array<ResolversTypes['Role']>, ParentType, ContextType>;
  sales_projects?: Resolver<Array<ResolversTypes['Project']>, ParentType, ContextType>;
  sales_rep_constructions?: Resolver<Array<ResolversTypes['Construction']>, ParentType, ContextType>;
  sales_representative_send_Orders?: Resolver<Array<ResolversTypes['SendOrder']>, ParentType, ContextType>;
  send_order_clients?: Resolver<Array<ResolversTypes['SendOrderClient']>, ParentType, ContextType>;
  sex?: Resolver<ResolversTypes['Sex'], ParentType, ContextType>;
  sex_value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  site_agent_constructions?: Resolver<Array<ResolversTypes['Construction']>, ParentType, ContextType>;
  site_agent_send_orders?: Resolver<Array<ResolversTypes['SendOrder']>, ParentType, ContextType>;
  supervising_engineer_constructions?: Resolver<Array<ResolversTypes['Construction']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmployeePaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeePaginator'] = ResolversParentTypes['EmployeePaginator']> = {
  data?: Resolver<Array<ResolversTypes['Employee']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstimateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Estimate'] = ResolversParentTypes['Estimate']> = {
  amount_excluding_tax?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  amount_including_tax?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  construction?: Resolver<ResolversTypes['Construction'], ParentType, ContextType>;
  consumption_tax?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  consumption_tax_calc_type?: Resolver<Maybe<ResolversTypes['ConsumptionTaxCalcType']>, ParentType, ContextType>;
  consumption_tax_calc_type_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  delivery_deadline?: Resolver<Maybe<ResolversTypes['PaymentTerm']>, ParentType, ContextType>;
  delivery_deadline_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  estimate_construction_location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  estimate_construction_overview?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  estimate_date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  expiration_date?: Resolver<Maybe<ResolversTypes['PaymentTerm']>, ParentType, ContextType>;
  expiration_date_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  receive_orders_accuracy?: Resolver<Maybe<ResolversTypes['ReceiveOrdersAccuracy']>, ParentType, ContextType>;
  receive_orders_accuracy_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sales_rep?: Resolver<ResolversTypes['Employee'], ParentType, ContextType>;
  tax_rate_kind?: Resolver<Maybe<ResolversTypes['TaxRateKind']>, ParentType, ContextType>;
  tax_rate_kind_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tax_rate_type?: Resolver<Maybe<ResolversTypes['TaxRateType']>, ParentType, ContextType>;
  tax_rate_type_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExpenseItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExpenseItem'] = ResolversParentTypes['ExpenseItem']> = {
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  expense_item_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExpenseItemPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExpenseItemPaginator'] = ResolversParentTypes['ExpenseItemPaginator']> = {
  data?: Resolver<Array<ResolversTypes['ExpenseItem']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface Int64ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Int64'], any> {
  name: 'Int64';
}

export type MasterRegistrationRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['MasterRegistrationRequest'] = ResolversParentTypes['MasterRegistrationRequest']> = {
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  data_type?: Resolver<ResolversTypes['MasterRegistrationRequestDataType'], ParentType, ContextType>;
  data_type_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  official_branch_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  official_company_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  send_orders?: Resolver<Array<ResolversTypes['SendOrder']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  createAccount?: Resolver<ResolversTypes['Account'], ParentType, ContextType, RequireFields<MutationCreateAccountArgs, 'input'>>;
  createBase?: Resolver<ResolversTypes['Base'], ParentType, ContextType, RequireFields<MutationCreateBaseArgs, 'input'>>;
  createConstruction?: Resolver<ResolversTypes['Construction'], ParentType, ContextType, RequireFields<MutationCreateConstructionArgs, 'input'>>;
  createConstructionType?: Resolver<ResolversTypes['ConstructionType'], ParentType, ContextType, RequireFields<MutationCreateConstructionTypeArgs, 'input'>>;
  createDivision?: Resolver<ResolversTypes['Division'], ParentType, ContextType, RequireFields<MutationCreateDivisionArgs, 'input'>>;
  createDraftSendOrder?: Resolver<ResolversTypes['SendOrder'], ParentType, ContextType, RequireFields<MutationCreateDraftSendOrderArgs, 'input'>>;
  createEmployee?: Resolver<ResolversTypes['Employee'], ParentType, ContextType, RequireFields<MutationCreateEmployeeArgs, 'input'>>;
  createExpenseItem?: Resolver<ResolversTypes['ExpenseItem'], ParentType, ContextType, RequireFields<MutationCreateExpenseItemArgs, 'input'>>;
  createProject?: Resolver<ResolversTypes['Project'], ParentType, ContextType, RequireFields<MutationCreateProjectArgs, 'input'>>;
  createSendOrderClient?: Resolver<ResolversTypes['SendOrderClient'], ParentType, ContextType, RequireFields<MutationCreateSendOrderClientArgs, 'input'>>;
  createSendOrderDetail?: Resolver<ResolversTypes['SendOrder'], ParentType, ContextType, RequireFields<MutationCreateSendOrderDetailArgs, 'input' | 'send_order_id'>>;
  createSupplier?: Resolver<ResolversTypes['Supplier'], ParentType, ContextType, RequireFields<MutationCreateSupplierArgs, 'input'>>;
  deleteBase?: Resolver<ResolversTypes['Base'], ParentType, ContextType, RequireFields<MutationDeleteBaseArgs, 'id'>>;
  deleteConstruction?: Resolver<ResolversTypes['Construction'], ParentType, ContextType, RequireFields<MutationDeleteConstructionArgs, 'id'>>;
  deleteDivision?: Resolver<ResolversTypes['Division'], ParentType, ContextType, RequireFields<MutationDeleteDivisionArgs, 'id'>>;
  deleteEmployee?: Resolver<ResolversTypes['Employee'], ParentType, ContextType, RequireFields<MutationDeleteEmployeeArgs, 'id'>>;
  deleteProject?: Resolver<ResolversTypes['Project'], ParentType, ContextType, RequireFields<MutationDeleteProjectArgs, 'id'>>;
  deleteSendOrder?: Resolver<ResolversTypes['SendOrder'], ParentType, ContextType, RequireFields<MutationDeleteSendOrderArgs, 'id'>>;
  deleteSendOrderClient?: Resolver<ResolversTypes['SendOrderClient'], ParentType, ContextType, RequireFields<MutationDeleteSendOrderClientArgs, 'id'>>;
  deleteSendOrderDetail?: Resolver<ResolversTypes['SendOrder'], ParentType, ContextType, RequireFields<MutationDeleteSendOrderDetailArgs, 'id'>>;
  deleteSupplier?: Resolver<ResolversTypes['Supplier'], ParentType, ContextType, RequireFields<MutationDeleteSupplierArgs, 'id'>>;
  forceDeleteSendOrder?: Resolver<ResolversTypes['SendOrder'], ParentType, ContextType, RequireFields<MutationForceDeleteSendOrderArgs, 'id'>>;
  loginAsEmployee?: Resolver<ResolversTypes['ApiToken'], ParentType, ContextType, RequireFields<MutationLoginAsEmployeeArgs, 'input'>>;
  logoutAsEmployee?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  orderDraftSendOrder?: Resolver<ResolversTypes['SendOrder'], ParentType, ContextType, RequireFields<MutationOrderDraftSendOrderArgs, 'id'>>;
  resetSendOrder?: Resolver<ResolversTypes['SendOrder'], ParentType, ContextType, RequireFields<MutationResetSendOrderArgs, 'id'>>;
  restoreSendOrder?: Resolver<ResolversTypes['SendOrder'], ParentType, ContextType, RequireFields<MutationRestoreSendOrderArgs, 'id'>>;
  updateAccount?: Resolver<ResolversTypes['Account'], ParentType, ContextType, RequireFields<MutationUpdateAccountArgs, 'id' | 'input'>>;
  updateBase?: Resolver<ResolversTypes['Base'], ParentType, ContextType, RequireFields<MutationUpdateBaseArgs, 'id' | 'input'>>;
  updateConstruction?: Resolver<ResolversTypes['Construction'], ParentType, ContextType, RequireFields<MutationUpdateConstructionArgs, 'id' | 'input'>>;
  updateConstructionType?: Resolver<ResolversTypes['ConstructionType'], ParentType, ContextType, RequireFields<MutationUpdateConstructionTypeArgs, 'id' | 'input'>>;
  updateDivision?: Resolver<ResolversTypes['Division'], ParentType, ContextType, RequireFields<MutationUpdateDivisionArgs, 'id' | 'input'>>;
  updateDraftSendOrder?: Resolver<ResolversTypes['SendOrder'], ParentType, ContextType, RequireFields<MutationUpdateDraftSendOrderArgs, 'id' | 'input'>>;
  updateEmployee?: Resolver<ResolversTypes['Employee'], ParentType, ContextType, RequireFields<MutationUpdateEmployeeArgs, 'input'>>;
  updateExpenseItem?: Resolver<ResolversTypes['ExpenseItem'], ParentType, ContextType, RequireFields<MutationUpdateExpenseItemArgs, 'id' | 'input'>>;
  updateProject?: Resolver<ResolversTypes['Project'], ParentType, ContextType, RequireFields<MutationUpdateProjectArgs, 'id' | 'input'>>;
  updateProjectConstruction?: Resolver<ResolversTypes['Project'], ParentType, ContextType, RequireFields<MutationUpdateProjectConstructionArgs, 'id' | 'input'>>;
  updateSendOrderClient?: Resolver<ResolversTypes['SendOrderClient'], ParentType, ContextType, RequireFields<MutationUpdateSendOrderClientArgs, 'id' | 'input'>>;
  updateSendOrderDetail?: Resolver<ResolversTypes['SendOrder'], ParentType, ContextType, RequireFields<MutationUpdateSendOrderDetailArgs, 'id' | 'input'>>;
  updateSupplier?: Resolver<ResolversTypes['Supplier'], ParentType, ContextType, RequireFields<MutationUpdateSupplierArgs, 'id' | 'input'>>;
};

export type PageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatorInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatorInfo'] = ResolversParentTypes['PaginatorInfo']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  firstItem?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasMorePages?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastItem?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lastPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  perPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhoneNumberResolvers<ContextType = any, ParentType extends ResolversParentTypes['PhoneNumber'] = ResolversParentTypes['PhoneNumber']> = {
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  data_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  data_type?: Resolver<ResolversTypes['MasterDataType'], ParentType, ContextType>;
  data_type_value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  display_order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  line_type?: Resolver<ResolversTypes['LineType'], ParentType, ContextType>;
  line_type_value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  phone_number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['Project'] = ResolversParentTypes['Project']> = {
  constructions?: Resolver<Array<ResolversTypes['Construction']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  estimate_constructions?: Resolver<Array<ResolversTypes['Construction']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kana?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectPaginator'] = ResolversParentTypes['ProjectPaginator']> = {
  data?: Resolver<Array<ResolversTypes['Project']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  account?: Resolver<Maybe<ResolversTypes['Account']>, ParentType, ContextType, RequireFields<QueryAccountArgs, 'id'>>;
  accounts?: Resolver<Maybe<ResolversTypes['AccountPaginator']>, ParentType, ContextType, RequireFields<QueryAccountsArgs, 'first'>>;
  authorizedAsEmployee?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>;
  bank?: Resolver<Maybe<ResolversTypes['Bank']>, ParentType, ContextType, RequireFields<QueryBankArgs, 'code'>>;
  bankBranch?: Resolver<Maybe<ResolversTypes['BankBranch']>, ParentType, ContextType, RequireFields<QueryBankBranchArgs, 'bank_code'>>;
  bankBranches?: Resolver<Maybe<ResolversTypes['BankBranchPaginator']>, ParentType, ContextType, RequireFields<QueryBankBranchesArgs, 'first'>>;
  banks?: Resolver<Maybe<ResolversTypes['BankPaginator']>, ParentType, ContextType, RequireFields<QueryBanksArgs, 'first'>>;
  base?: Resolver<Maybe<ResolversTypes['Base']>, ParentType, ContextType, RequireFields<QueryBaseArgs, 'id'>>;
  bases?: Resolver<Maybe<ResolversTypes['BasePaginator']>, ParentType, ContextType, RequireFields<QueryBasesArgs, 'first'>>;
  calcTaxAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryCalcTaxAmountArgs, 'amount' | 'rounding' | 'taxRate'>>;
  calcTaxAmountBySendOrderClient?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryCalcTaxAmountBySendOrderClientArgs, 'amount' | 'sendOrderClientId' | 'taxRate'>>;
  calcTaxAmountBySupplier?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryCalcTaxAmountBySupplierArgs, 'amount' | 'consumptionTaxRate' | 'supplierId'>>;
  calcTotalAmountBySendOrderClient?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryCalcTotalAmountBySendOrderClientArgs, 'quantity' | 'sendOrderClientId' | 'unitPrice'>>;
  calcTotalAmountBySupplier?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryCalcTotalAmountBySupplierArgs, 'quantity' | 'supplierId' | 'unitPrice'>>;
  construction?: Resolver<Maybe<ResolversTypes['Construction']>, ParentType, ContextType, RequireFields<QueryConstructionArgs, 'id'>>;
  constructionType?: Resolver<Maybe<ResolversTypes['ConstructionType']>, ParentType, ContextType, RequireFields<QueryConstructionTypeArgs, 'id'>>;
  constructionTypes?: Resolver<Maybe<ResolversTypes['ConstructionTypePaginator']>, ParentType, ContextType, RequireFields<QueryConstructionTypesArgs, 'first'>>;
  constructions?: Resolver<Maybe<ResolversTypes['ConstructionPaginator']>, ParentType, ContextType, RequireFields<QueryConstructionsArgs, 'condition' | 'first'>>;
  division?: Resolver<Maybe<ResolversTypes['Division']>, ParentType, ContextType, RequireFields<QueryDivisionArgs, 'id'>>;
  divisions?: Resolver<Maybe<ResolversTypes['DivisionPaginator']>, ParentType, ContextType, RequireFields<QueryDivisionsArgs, 'first'>>;
  employee?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType, RequireFields<QueryEmployeeArgs, 'id'>>;
  employees?: Resolver<Maybe<ResolversTypes['EmployeePaginator']>, ParentType, ContextType, RequireFields<QueryEmployeesArgs, 'first'>>;
  expenseItem?: Resolver<Maybe<ResolversTypes['ExpenseItem']>, ParentType, ContextType, RequireFields<QueryExpenseItemArgs, 'id'>>;
  expenseItems?: Resolver<Maybe<ResolversTypes['ExpenseItemPaginator']>, ParentType, ContextType, RequireFields<QueryExpenseItemsArgs, 'first'>>;
  project?: Resolver<Maybe<ResolversTypes['Project']>, ParentType, ContextType, RequireFields<QueryProjectArgs, 'id'>>;
  projects?: Resolver<Maybe<ResolversTypes['ProjectPaginator']>, ParentType, ContextType, RequireFields<QueryProjectsArgs, 'first'>>;
  sendOrder?: Resolver<Maybe<ResolversTypes['SendOrder']>, ParentType, ContextType, RequireFields<QuerySendOrderArgs, 'id'>>;
  sendOrderClient?: Resolver<Maybe<ResolversTypes['SendOrderClient']>, ParentType, ContextType, RequireFields<QuerySendOrderClientArgs, 'id'>>;
  sendOrderClients?: Resolver<Maybe<ResolversTypes['SendOrderClientPaginator']>, ParentType, ContextType, RequireFields<QuerySendOrderClientsArgs, 'first'>>;
  sendOrders?: Resolver<Array<ResolversTypes['SendOrder']>, ParentType, ContextType, RequireFields<QuerySendOrdersArgs, 'condition'>>;
  supplier?: Resolver<Maybe<ResolversTypes['Supplier']>, ParentType, ContextType, RequireFields<QuerySupplierArgs, 'id'>>;
  suppliers?: Resolver<ResolversTypes['SuppliersResult'], ParentType, ContextType, RequireFields<QuerySuppliersArgs, 'first'>>;
};

export type RoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Role'] = ResolversParentTypes['Role']> = {
  ability?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  employees?: Resolver<Array<ResolversTypes['Employee']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kana?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendOrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendOrder'] = ResolversParentTypes['SendOrder']> = {
  base?: Resolver<Maybe<ResolversTypes['Base']>, ParentType, ContextType>;
  base_address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  base_fax?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  base_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  base_postcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  base_tel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  company_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  construction?: Resolver<Maybe<ResolversTypes['Construction']>, ParentType, ContextType>;
  construction_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  construction_kana?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  construction_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  construction_period?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deleted_by?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>;
  delivery_address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  delivery_conditions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  delivery_deadline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  delivery_destination?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  force_deletable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  latest_send_order_revision?: Resolver<Maybe<ResolversTypes['SendOrderRevision']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order_details?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order_employee?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>;
  order_employee_kana?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order_employee_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payment_terms?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resettable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  revision?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sales_representative?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>;
  sales_representative_kana?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sales_representative_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  send_order_date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  send_order_revisions?: Resolver<Array<ResolversTypes['SendOrderRevision']>, ParentType, ContextType>;
  site_agent?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>;
  site_agent_kana?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  site_agent_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  soft_deletable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['SendOrderStatus'], ParentType, ContextType>;
  status_value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  supplier?: Resolver<Maybe<ResolversTypes['Supplier']>, ParentType, ContextType>;
  supplier_address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  supplier_estimate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  supplier_kana?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  supplier_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  supplier_postcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  supplier_registration_request_id?: Resolver<Maybe<ResolversTypes['MasterRegistrationRequest']>, ParentType, ContextType>;
  tax_rounding?: Resolver<Maybe<ResolversTypes['Rounding']>, ParentType, ContextType>;
  tax_rounding_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_rounding?: Resolver<Maybe<ResolversTypes['Rounding']>, ParentType, ContextType>;
  total_rounding_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updated_by?: Resolver<ResolversTypes['Employee'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendOrderClientResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendOrderClient'] = ResolversParentTypes['SendOrderClient']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bank_branch_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bank_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bill_site?: Resolver<Maybe<ResolversTypes['Int64']>, ParentType, ContextType>;
  closing_date?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  collection_charge?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>;
  collection_date?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  collection_month?: Resolver<Maybe<ResolversTypes['CollectionMonth']>, ParentType, ContextType>;
  collection_month_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  designated_request?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email_addresses?: Resolver<Maybe<Array<Maybe<ResolversTypes['EmailAddress']>>>, ParentType, ContextType>;
  honorific_title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kana?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_order_date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number_of_orders?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  official_branch_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  official_company_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone_numbers?: Resolver<Maybe<Array<Maybe<ResolversTypes['PhoneNumber']>>>, ParentType, ContextType>;
  postcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  send_order_client_type?: Resolver<ResolversTypes['SendOrderClientType'], ParentType, ContextType>;
  send_order_client_type_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tax_rounding?: Resolver<ResolversTypes['Rounding'], ParentType, ContextType>;
  tax_rounding_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_rounding?: Resolver<ResolversTypes['Rounding'], ParentType, ContextType>;
  total_rounding_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendOrderClientPaginatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendOrderClientPaginator'] = ResolversParentTypes['SendOrderClientPaginator']> = {
  data?: Resolver<Array<ResolversTypes['SendOrderClient']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendOrderDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendOrderDetail'] = ResolversParentTypes['SendOrderDetail']> = {
  change_no?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  latest_send_order_detail_change?: Resolver<Maybe<ResolversTypes['SendOrderDetailChange']>, ParentType, ContextType>;
  send_order_detail_changes?: Resolver<Array<ResolversTypes['SendOrderDetailChange']>, ParentType, ContextType>;
  send_order_detail_type?: Resolver<ResolversTypes['SendOrderDetailType'], ParentType, ContextType>;
  send_order_detail_type_value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendOrderDetailChangeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendOrderDetailChange'] = ResolversParentTypes['SendOrderDetailChange']> = {
  alternative_send_order_detail_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  amount_excluding_tax?: Resolver<Maybe<ResolversTypes['Int64']>, ParentType, ContextType>;
  change_items?: Resolver<ResolversTypes['SendOrderChangeItems'], ParentType, ContextType>;
  change_items_value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  change_no?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  change_type?: Resolver<ResolversTypes['SendOrderChangeType'], ParentType, ContextType>;
  change_type_value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  consumption_tax_rate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deliveried_quantity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expense_account_type?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  expense_type?: Resolver<Maybe<ResolversTypes['ExpenseType']>, ParentType, ContextType>;
  expense_type_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  not_manage_backlog?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  payment_amount?: Resolver<Maybe<ResolversTypes['Int64']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  remaining_deliveried_quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  remaining_payment_amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  send_order_detail?: Resolver<ResolversTypes['SendOrderDetail'], ParentType, ContextType>;
  transaction_type?: Resolver<ResolversTypes['TransactionType'], ParentType, ContextType>;
  transaction_type_value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unit_price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updated_by?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendOrderRevisionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendOrderRevision'] = ResolversParentTypes['SendOrderRevision']> = {
  amount_excluding_tax?: Resolver<ResolversTypes['Int64'], ParentType, ContextType>;
  amount_including_tax?: Resolver<ResolversTypes['Int64'], ParentType, ContextType>;
  consumption_tax_amount?: Resolver<ResolversTypes['Int64'], ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  delivery_completed_lines_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  real_detail_lines_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  send_order?: Resolver<ResolversTypes['SendOrder'], ParentType, ContextType>;
  send_order_details?: Resolver<Array<ResolversTypes['SendOrderDetail']>, ParentType, ContextType>;
  settlement_completed_lines_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total_detail_lines_count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SimplePaginatorInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['SimplePaginatorInfo'] = ResolversParentTypes['SimplePaginatorInfo']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  firstItem?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasMorePages?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastItem?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  perPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SupplierResolvers<ContextType = any, ParentType extends ResolversParentTypes['Supplier'] = ResolversParentTypes['Supplier']> = {
  account_holder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  account_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bank_branch_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bank_id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  bill_holder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bill_shipping_cost?: Resolver<Maybe<ResolversTypes['Int64']>, ParentType, ContextType>;
  bill_site?: Resolver<Maybe<ResolversTypes['Int64']>, ParentType, ContextType>;
  bill_standard_amount?: Resolver<Maybe<ResolversTypes['Int64']>, ParentType, ContextType>;
  bill_standard_amount_2?: Resolver<Maybe<ResolversTypes['Int64']>, ParentType, ContextType>;
  bill_standard_amount_3?: Resolver<Maybe<ResolversTypes['Int64']>, ParentType, ContextType>;
  closing_date?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deleted_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deposit_type?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  email_addresses?: Resolver<Maybe<Array<Maybe<ResolversTypes['EmailAddress']>>>, ParentType, ContextType>;
  honorific_title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kana?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  last_order_date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number_of_orders?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  official_branch_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  official_company_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payment_day?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  payment_month?: Resolver<Maybe<ResolversTypes['PaymentMonth']>, ParentType, ContextType>;
  payment_month_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  payment_type?: Resolver<Maybe<ResolversTypes['PaymentType']>, ParentType, ContextType>;
  payment_type_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  phone_numbers?: Resolver<Maybe<Array<Maybe<ResolversTypes['PhoneNumber']>>>, ParentType, ContextType>;
  postcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  send_orders?: Resolver<Array<ResolversTypes['SendOrder']>, ParentType, ContextType>;
  tax_rounding?: Resolver<ResolversTypes['Rounding'], ParentType, ContextType>;
  tax_rounding_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total_rounding?: Resolver<ResolversTypes['Rounding'], ParentType, ContextType>;
  total_rounding_value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SuppliersResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SuppliersResult'] = ResolversParentTypes['SuppliersResult']> = {
  data?: Resolver<Array<ResolversTypes['Supplier']>, ParentType, ContextType>;
  paginatorInfo?: Resolver<ResolversTypes['PaginatorInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  created_at?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email_verified_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated_at?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Account?: AccountResolvers<ContextType>;
  AccountPaginator?: AccountPaginatorResolvers<ContextType>;
  ApiToken?: ApiTokenResolvers<ContextType>;
  Bank?: BankResolvers<ContextType>;
  BankBranch?: BankBranchResolvers<ContextType>;
  BankBranchPaginator?: BankBranchPaginatorResolvers<ContextType>;
  BankPaginator?: BankPaginatorResolvers<ContextType>;
  Base?: BaseResolvers<ContextType>;
  BasePaginator?: BasePaginatorResolvers<ContextType>;
  Construction?: ConstructionResolvers<ContextType>;
  ConstructionPaginator?: ConstructionPaginatorResolvers<ContextType>;
  ConstructionType?: ConstructionTypeResolvers<ContextType>;
  ConstructionTypePaginator?: ConstructionTypePaginatorResolvers<ContextType>;
  Customer?: CustomerResolvers<ContextType>;
  Date?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  Division?: DivisionResolvers<ContextType>;
  DivisionPaginator?: DivisionPaginatorResolvers<ContextType>;
  EmailAddress?: EmailAddressResolvers<ContextType>;
  Employee?: EmployeeResolvers<ContextType>;
  EmployeePaginator?: EmployeePaginatorResolvers<ContextType>;
  Estimate?: EstimateResolvers<ContextType>;
  ExpenseItem?: ExpenseItemResolvers<ContextType>;
  ExpenseItemPaginator?: ExpenseItemPaginatorResolvers<ContextType>;
  Int64?: GraphQLScalarType;
  MasterRegistrationRequest?: MasterRegistrationRequestResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PaginatorInfo?: PaginatorInfoResolvers<ContextType>;
  PhoneNumber?: PhoneNumberResolvers<ContextType>;
  Project?: ProjectResolvers<ContextType>;
  ProjectPaginator?: ProjectPaginatorResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Role?: RoleResolvers<ContextType>;
  SendOrder?: SendOrderResolvers<ContextType>;
  SendOrderClient?: SendOrderClientResolvers<ContextType>;
  SendOrderClientPaginator?: SendOrderClientPaginatorResolvers<ContextType>;
  SendOrderDetail?: SendOrderDetailResolvers<ContextType>;
  SendOrderDetailChange?: SendOrderDetailChangeResolvers<ContextType>;
  SendOrderRevision?: SendOrderRevisionResolvers<ContextType>;
  SimplePaginatorInfo?: SimplePaginatorInfoResolvers<ContextType>;
  Supplier?: SupplierResolvers<ContextType>;
  SuppliersResult?: SuppliersResultResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
};

