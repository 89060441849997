import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import { PadViewItem } from '@/const/component/padView'
import ConstDailyReportDetails from '@/const/transaction/dailyReportDetails'

export default function pad () {
  const constTable = ConstDailyReportDetails()

  const getPadItems = (readonly = true) => {
    const ro = readonly
    const rq = !readonly

    const items: PadViewItem[] = [{
      id: 'dailyReport',
      groups: [{
        items: [{
          id: 'start_time',
          label: constTable.itemLabel.get('start_time') ?? '',
          type: 'time',
          readonly: ro,
          required: rq,
        }, {
          id: 'end_time',
          label: constTable.itemLabel.get('end_time') ?? '',
          type: 'time',
          readonly: ro,
          required: rq,
        }],
      }, {
        items: [{
          id: 'contract',
          label: constTable.itemLabel.get('contract') ?? '',
          type: 'contract',
          readonly: ro,
          required: rq,
        }],
      }, {
        items: [{
          id: 'within_time',
          label: constTable.itemLabel.get('within_time') ?? '',
          type: 'text',
          readonly: true,
          required: false,
        }, {
          id: 'overtime',
          label: constTable.itemLabel.get('overtime') ?? '',
          type: 'text',
          readonly: true,
          required: false,
        }, {
          id: 'working_total_time',
          label: constTable.itemLabel.get('working_total_time') ?? '',
          type: 'text',
          readonly: true,
          required: false,
        }],
      }, {
        items: [{
          id: 'holiday_time',
          label: constTable.itemLabel.get('holiday_time') ?? '',
          type: 'text',
          readonly: true,
          required: false,
        }, {
          id: 'midnight_time',
          label: constTable.itemLabel.get('midnight_time') ?? '',
          type: 'text',
          readonly: true,
          required: false,
        }],
      }],
    }]

    return items
  }

  return {
    getPadItems,
  }
}
