export const tableName = 'ロール'

export default function roles () {
  const searchLabel = new Map([
    [ 'employee_number', '社員番号' ],
    [ 'employee_name', '氏名' ],
  ])
  const columnLabel = new Map([
    [ 'order_no', 'No.' ],
    [ 'name', 'ロールグループ名' ],
    [ 'employee_number', '社員番号' ],
    [ 'employee_name', '氏名' ],
    [ 'role_group', 'ロールグループ' ],
    [ 'contract', '案件管理' ],
    [ 'estimate', '見積管理' ],
    [ 'bill', '請求管理' ],
    [ 'deposit', '入金管理' ],
    [ 'purchase', '発注管理' ],
    [ 'purchaseDelivery', '仕入管理' ],
    [ 'payment', '支払管理' ],
    [ 'budget', '予算管理' ],
    [ 'journal', '仕訳管理' ],
    [ 'approval', '稟議管理' ],
    [ 'audit', '監査' ],
    [ 'role', 'ロール管理' ],
    [ 'notification', '通知設定' ],
    [ 'master', 'マスタ管理' ],
    [ 'mypage', 'マイページ' ],
  ])

  return {
    searchLabel,
    columnLabel,
  }
}
