export interface DbPaymentBalance {
  id: string
  year_month: string
  supplier_code: string
  supplier_name: string
  supplier_kana: string
  previous_month_balance: bigint
  amount_incurred: bigint
  payment_amount: bigint
  current_month_balance: bigint
}

export default function paymentBalances () {
  const paymentBalances: Array<DbPaymentBalance> = [{
    id: '1234',
    year_month: '2023-02',
    supplier_code: '22345678',
    supplier_name: 'KSY',
    supplier_kana: 'ケイエスワイ',
    previous_month_balance: 20000n,
    amount_incurred: 11000000n,
    payment_amount: 10000000n,
    current_month_balance: 1020000n,
  }, {
    id: '1235',
    year_month: '2023-02',
    supplier_code: '22345555',
    supplier_name: '〇〇〇〇〇〇',
    supplier_kana: 'マルマルマルマルマルマル',
    previous_month_balance: 0n,
    amount_incurred: 2500000n,
    payment_amount: 25000000n,
    current_month_balance: 0n,
  }, {
    id: '1236',
    year_month: '2023-02',
    supplier_code: '22346666',
    supplier_name: 'ＸＸＸＸＸＸ',
    supplier_kana: 'バツバツバツバツバツバツ',
    previous_month_balance: 10000n,
    amount_incurred: 500000n,
    payment_amount: 5010000n,
    current_month_balance: 0n,
  }]

  return {
    paymentBalances,
  }
}
