import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstEstimateDetails from '@/const/transaction/estimateDetails'
import {
  Construction,
  Supplier,
  Base,
  Employee,
  SendOrderStatus,
  ExpenseType,
  Rounding,
  SendOrderSearchCondition,
} from '@/graphql'

const constEstimateDetails = ConstEstimateDetails()

const baseListColumns: ListViewColumn[] = [{
  id: 'details_line_no',
  text: constEstimateDetails.columnLabel.get('detailsLineNo'),
  displayField: 'details_line_no',
  align: 'right',
}, {
  id: 'description',
  text: constEstimateDetails.columnLabel.get('description'),
  displayField: 'description',
  multipleLines: true,
  colspan: {
    key: 'detail_type',
    value: 2,
    count: 9,
  },
}, {
  id: 'quantity',
  text: constEstimateDetails.columnLabel.get('quantity'),
  displayField: 'quantity',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'unit',
  text: constEstimateDetails.columnLabel.get('unit'),
  displayField: 'unit',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'unit_price',
  text: constEstimateDetails.columnLabel.get('unitPrice'),
  displayField: 'unit_price',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
  /*
}, {
  id: 'consumption_tax_type',
  text: constEstimateDetails.columnLabel.get('consumptionTaxType'),
  displayField: 'consumption_tax_type',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
  */
}, {
  id: 'consumption_tax_rate',
  text: constEstimateDetails.columnLabel.get('consumptionTaxRate'),
  displayField: 'consumption_tax_rate',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'consumption_tax_amount',
  text: constEstimateDetails.columnLabel.get('consumptionTaxAmount'),
  displayField: 'consumption_tax_amount',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
  /*
}, {
  id: 'amount',
  text: constEstimateDetails.columnLabel.get('amount'),
  displayField: 'amount',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
  */
}, {
  id: 'amount_excluding_tax',
  text: constEstimateDetails.columnLabel.get('amountExcludingTax'),
  displayField: 'amount_excluding_tax',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'amount_including_tax',
  text: constEstimateDetails.columnLabel.get('amountIncludingTax'),
  displayField: 'amount_including_tax',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'note',
  text: constEstimateDetails.columnLabel.get('note'),
  displayField: 'note',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
  /*
}, {
  id: 'quotation_information',
  text: constEstimateDetails.columnLabel.get('quotationInformation'),
  align: 'center',
  columns: [{
    id: 'quotation_supplier',
    text: constEstimateDetails.columnLabel.get('quotationSupplier'),
    displayField: 'quotation_supplier_name',
    sortField: 'quotation_supplier_kana',
  }, {
    id: 'quotation_unit_price',
    text: constEstimateDetails.columnLabel.get('quotationUnitPrice'),
    displayField: 'quotation_unit_price',
    sortField: 'quotation_unit_price',
    displayConvert: 'decimalDelimiter',
    align: 'right',
  }, {
    id: 'quotation_consumption_tax_rate',
    text: constEstimateDetails.columnLabel.get('quotationConsumptionTaxRate'),
    displayField: 'quotation_consumption_tax_rate',
    sortField: 'quotation_consumption_tax_rate',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'order_count',
    text: constEstimateDetails.columnLabel.get('orderCount'),
    displayField: 'order_count',
    sortField: 'order_count',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }],
  invisible: {
    key: 'estimate_detail_type',
    value: 2,
  },
  */
}]

const standardListColumns = {
  estimate: [
    'details_line_no',
    'description',
    'quantity',
    'unit',
    'unit_price',
    'consumption_tax_rate',
    'consumption_tax_amount',
    //'amount',
    'amount_excluding_tax',
    'amount_including_tax',
    'note',
    //'quotation_information',
  ],
  general: [
    'details_line_no',
    'description',
    'quantity',
    'unit',
    'unit_price',
    'consumption_tax_rate',
    'consumption_tax_amount',
    //'amount',
    'amount_excluding_tax',
    'amount_including_tax',
    'note',
  ],
  draft: [
    'details_line_no',
    'description',
    'quantity',
    'unit',
    'unit_price',
    'consumption_tax_rate',
    'consumption_tax_amount',
    //'amount',
    'amount_excluding_tax',
    'amount_including_tax',
    'note',
  ],
  waiting: [
    'details_line_no',
    'description',
    'quantity',
    'unit',
    'unit_price',
    'consumption_tax_rate',
    'consumption_tax_amount',
    //'amount',
    'amount_excluding_tax',
    'amount_including_tax',
    'note',
  ],
  information: [
    'details_line_no',
    'description',
    'quantity',
    'unit',
    'unit_price',
    'consumption_tax_rate',
    'consumption_tax_amount',
    //'amount',
    'amount_excluding_tax',
    'amount_including_tax',
    'note',
  ],
}

const listConverter: ListViewConverter = {
}

export default function estimateDetail () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'details_line_no',
    order: {
      'details_line_no': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
