import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import ConstEmployees from '@/const/transaction/master/employees'
import {
  SearchBoxValue,
  SearchBoxItem,
} from '@/const/component/searchBox'

const constTable = ConstEmployees()

const items = [
  'employee_number',
  'name',
  'address',
  'email_address',
  'health_insurance_insured_number',
  'employment_insurance_insured_number',
  'basic_pension_number',
]

export default function search () {
  const searchItems = computed(() => {
    return items.map(el => {
      return {
        id: el,
        label: constTable.searchLabel.get(el),
      } as SearchBoxItem
    })
  })

  return {
    searchItems,
  }
}
