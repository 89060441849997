export interface DbConstructionType {
  id: string
  display_order: number
  name: string
  kana: string
  updated_at: string
  updated_by_name: string
  updated_by_kana: string
}

export default function constructionTypes () {
  const constructionTypes: Array<DbConstructionType> = [{
    id: '1',
    display_order: 1,
    name: '電気工事',
    kana: 'デンキコウジ',
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '2',
    display_order: 2,
    name: '通信工事',
    kana: 'ツウシンコウジ',
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '3',
    display_order: 3,
    name: '消防施設工事',
    kana: 'ショウボウシセツコウジ',
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }]

  return {
    constructionTypes,
  }
}
