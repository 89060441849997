export interface DbCostSummary {
  id: string
  data_header: string
  material: bigint
  outsource: bigint
  expenses: bigint
  labor: bigint
  others: bigint
  total: bigint
}

export default function costSummaries () {
  const costSummaries: Array<DbCostSummary> = [{
    id: '1',
    data_header: '工事',
    material: 1000000n,
    outsource: 1000000n,
    expenses: 1000000n,
    labor: 1000000n,
    others: 1000000n,
    total: 5000000n,
  }, {
    id: '2',
    data_header: '兼業',
    material: 1000000n,
    outsource: 1000000n,
    expenses: 1000000n,
    labor: 1000000n,
    others: 1000000n,
    total: 5000000n,
  }]

  return {
    costSummaries,
  }
}
