import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstPurchaseDeliveries from '@/const/transaction/purchaseDeliveries'

const constPurchaseDeliveries = ConstPurchaseDeliveries()

const baseListColumns: ListViewColumn[] = [{
  id: 'deliveryInformation',
  text: constPurchaseDeliveries.columnLabel.get('deliveryInformation'),
  align: 'center',
  columns: [{
    id: 'id',
    text: constPurchaseDeliveries.columnLabel.get('id'),
    displayField: 'id',
    sortField: 'id_number',
    align: 'right',
  }, {
    id: 'deliveryDate',
    text: constPurchaseDeliveries.columnLabel.get('deliveryDate'),
    displayField: 'delivery_date',
    sortField: 'delivery_date',
  }, {
    id: 'supplier',
    text: constPurchaseDeliveries.columnLabel.get('supplier'),
    displayField: 'supplier_name',
    sortField: 'supplier_kana',
  }, {
    id: 'delivery_slip_number',
    text: constPurchaseDeliveries.columnLabel.get('deliverySlipNumber'),
    displayField: 'delivery_slip_number',
    sortField: 'delivery_slip_number',
  }, {
    id: 'preOrderCost',
    text: constPurchaseDeliveries.columnLabel.get('preOrderCost'),
    displayField: 'pre_order_cost',
    sortField: 'pre_order_cost',
    displayConvert: 'check',
    align: 'center',
    width: '100px',
  }, {
    id: 'amount_including_tax',
    text: constPurchaseDeliveries.columnLabel.get('amountIncludingTax'),
    displayField: 'amount_including_tax',
    displayConvert: 'numericalDelimiter',
    sortField: 'amount_including_tax',
    align: 'right',
  }, {
    id: 'amount_excluding_tax',
    text: constPurchaseDeliveries.columnLabel.get('amountExcludingTax'),
    displayField: 'amount_excluding_tax',
    displayConvert: 'numericalDelimiter',
    sortField: 'amount_excluding_tax',
    align: 'right',
  }, {
    id: 'consumption_tax_amount',
    text: constPurchaseDeliveries.columnLabel.get('consumptionTaxAmount'),
    displayField: 'consumption_tax_amount',
    displayConvert: 'numericalDelimiter',
    sortField: 'consumption_tax_amount',
    align: 'right',
  }, {
    id: 'memo',
    text: constPurchaseDeliveries.columnLabel.get('memo'),
    displayField: 'memo',
    sortField: 'memo',
  }, {
    id: 'updated_at',
    text: constPurchaseDeliveries.columnLabel.get('updatedAt'),
    displayField: 'updated_at',
    sortField: 'updated_at',
  }, {
    id: 'updated_by',
    text: constPurchaseDeliveries.columnLabel.get('updatedBy'),
    displayField: 'updated_by_name',
    sortField: 'updated_by_kana',
  }],
}, {
  id: 'deliveryInformation_total',
  text: constPurchaseDeliveries.columnLabel.get('deliveryInformation'),
  align: 'center',
  columns: [{
    id: 'id',
    text: constPurchaseDeliveries.columnLabel.get('id'),
    displayField: 'id',
    sortField: 'id_number',
    align: 'right',
  }, {
    id: 'deliveryDate',
    text: constPurchaseDeliveries.columnLabel.get('deliveryDate'),
    displayField: 'delivery_date',
    sortField: 'delivery_date',
  }, {
    id: 'delivery_slip_number',
    text: constPurchaseDeliveries.columnLabel.get('deliverySlipNumber'),
    displayField: 'delivery_slip_number',
    sortField: 'delivery_slip_number',
  }, {
    id: 'preOrderCost',
    text: constPurchaseDeliveries.columnLabel.get('preOrderCost'),
    displayField: 'pre_order_cost',
    sortField: 'pre_order_cost',
    displayConvert: 'check',
    align: 'center',
    width: '100px',
  }, {
    id: 'amount_including_tax',
    text: constPurchaseDeliveries.columnLabel.get('amountIncludingTax'),
    displayField: 'amount_including_tax',
    displayConvert: 'numericalDelimiter',
    sortField: 'amount_including_tax',
    align: 'right',
  }, {
    id: 'amount_excluding_tax',
    text: constPurchaseDeliveries.columnLabel.get('amountExcludingTax'),
    displayField: 'amount_excluding_tax',
    displayConvert: 'numericalDelimiter',
    sortField: 'amount_excluding_tax',
    align: 'right',
  }, {
    id: 'consumption_tax_amount',
    text: constPurchaseDeliveries.columnLabel.get('consumptionTaxAmount'),
    displayField: 'consumption_tax_amount',
    displayConvert: 'numericalDelimiter',
    sortField: 'consumption_tax_amount',
    align: 'right',
  }, {
    id: 'memo',
    text: constPurchaseDeliveries.columnLabel.get('memo'),
    displayField: 'memo',
    sortField: 'memo',
  }, {
    id: 'updated_at',
    text: constPurchaseDeliveries.columnLabel.get('updatedAt'),
    displayField: 'updated_at',
    sortField: 'updated_at',
  }, {
    id: 'updated_by',
    text: constPurchaseDeliveries.columnLabel.get('updatedBy'),
    displayField: 'updated_by_name',
    sortField: 'updated_by_kana',
  }],
}, {
  id: 'deliveryInformation_balance',
  text: constPurchaseDeliveries.columnLabel.get('deliveryInformation'),
  align: 'center',
  columns: [{
    id: 'id',
    text: constPurchaseDeliveries.columnLabel.get('id'),
    displayField: 'id',
    sortField: 'id_number',
    align: 'right',
  }, {
    id: 'deliveryDate',
    text: constPurchaseDeliveries.columnLabel.get('deliveryDate'),
    displayField: 'delivery_date',
    sortField: 'delivery_date',
    /*
  }, {
    id: 'supplier',
    text: constPurchaseDeliveries.columnLabel.get('supplier'),
    displayField: 'supplier_name',
    sortField: 'supplier_kana',
    */
  }, {
    id: 'delivery_slip_number',
    text: constPurchaseDeliveries.columnLabel.get('deliverySlipNumber'),
    displayField: 'delivery_slip_number',
    sortField: 'delivery_slip_number',
  }, {
    id: 'preOrderCost',
    text: constPurchaseDeliveries.columnLabel.get('preOrderCost'),
    displayField: 'pre_order_cost',
    sortField: 'pre_order_cost',
    displayConvert: 'check',
    align: 'center',
    width: '100px',
  }, {
    id: 'amount_including_tax',
    text: constPurchaseDeliveries.columnLabel.get('amountIncludingTax'),
    displayField: 'amount_including_tax',
    displayConvert: 'numericalDelimiter',
    sortField: 'amount_including_tax',
    align: 'right',
  }, {
    id: 'amount_excluding_tax',
    text: constPurchaseDeliveries.columnLabel.get('amountExcludingTax'),
    displayField: 'amount_excluding_tax',
    displayConvert: 'numericalDelimiter',
    sortField: 'amount_excluding_tax',
    align: 'right',
  }, {
    id: 'consumption_tax_amount',
    text: constPurchaseDeliveries.columnLabel.get('consumptionTaxAmount'),
    displayField: 'consumption_tax_amount',
    displayConvert: 'numericalDelimiter',
    sortField: 'consumption_tax_amount',
    align: 'right',
  }, {
    id: 'memo',
    text: constPurchaseDeliveries.columnLabel.get('memo'),
    displayField: 'memo',
    sortField: 'memo',
    /*
  }, {
    id: 'updated_at',
    text: constPurchaseDeliveries.columnLabel.get('updatedAt'),
    displayField: 'updated_at',
    sortField: 'updated_at',
  }, {
    id: 'updated_by',
    text: constPurchaseDeliveries.columnLabel.get('updatedBy'),
    displayField: 'updated_by_name',
    sortField: 'updated_by_kana',
    */
  }],
}, {
  id: 'orderInformation',
  text: constPurchaseDeliveries.columnLabel.get('orderInformation'),
  align: 'center',
  columns: [{
    id: 'purchaseId',
    text: constPurchaseDeliveries.columnLabel.get('purchaseId'),
    displayField: 'purchase_id',
    sortField: 'purchase_id_number',
    align: 'right',
  }, {
    id: 'base',
    text: constPurchaseDeliveries.columnLabel.get('base'),
    displayField: 'base_name',
    sortField: 'base_display_order',
  }, {
    id: 'orderer',
    text: constPurchaseDeliveries.columnLabel.get('orderer'),
    displayField: 'orderer_name',
    sortField: 'orderer_kana',
  }, {
    id: 'apportionment_id',
    text: constPurchaseDeliveries.columnLabel.get('apportionmentId'),
    displayField: 'apportionment_id',
    sortField: 'apportionment_id_number',
    align: 'right',
  }, {
    id: 'apportionment_name',
    text: constPurchaseDeliveries.columnLabel.get('apportionmentName'),
    displayField: 'apportionment_name',
    sortField: 'apportionment_kana',
  }, {
    id: 'construction_period',
    text: constPurchaseDeliveries.columnLabel.get('constructionPeriod'),
    displayField: 'construction_period',
    sortField: 'construction_period',
  }, {
    id: 'sales_representative',
    text: constPurchaseDeliveries.columnLabel.get('salesRepresentative'),
    displayField: 'sales_representative_name',
    sortField: 'sales_representative_kana',
  }, {
    id: 'site_agent',
    text: constPurchaseDeliveries.columnLabel.get('siteAgent'),
    displayField: 'site_agent_name',
    sortField: 'site_agent_kana',
  }],
}, {
  id: 'orderInformation_delivery',
  text: constPurchaseDeliveries.columnLabel.get('orderInformation'),
  align: 'center',
  columns: [{
    id: 'base',
    text: constPurchaseDeliveries.columnLabel.get('base'),
    displayField: 'base_name',
    sortField: 'base_display_order',
  }, {
    id: 'orderer',
    text: constPurchaseDeliveries.columnLabel.get('orderer'),
    displayField: 'orderer_name',
    sortField: 'orderer_kana',
  }, {
    id: 'apportionment_id',
    text: constPurchaseDeliveries.columnLabel.get('apportionmentId'),
    displayField: 'apportionment_id',
    sortField: 'apportionment_id_number',
    align: 'right',
  }, {
    id: 'apportionment_name',
    text: constPurchaseDeliveries.columnLabel.get('apportionmentName'),
    displayField: 'apportionment_name',
    sortField: 'apportionment_kana',
  }, {
    id: 'construction_period',
    text: constPurchaseDeliveries.columnLabel.get('constructionPeriod'),
    displayField: 'construction_period',
    sortField: 'construction_period',
  }, {
    id: 'sales_representative',
    text: constPurchaseDeliveries.columnLabel.get('salesRepresentative'),
    displayField: 'sales_representative_name',
    sortField: 'sales_representative_kana',
  }, {
    id: 'site_agent',
    text: constPurchaseDeliveries.columnLabel.get('siteAgent'),
    displayField: 'site_agent_name',
    sortField: 'site_agent_kana',
  }],
}, {
  id: 'paymentInformation',
  text: constPurchaseDeliveries.columnLabel.get('paymentInformation'),
  align: 'center',
  columns: [{
    id: 'closingDate',
    text: constPurchaseDeliveries.columnLabel.get('closingDate'),
    displayField: 'closing_date',
    sortField: 'closing_date',
  }, {
    id: 'closed',
    text: constPurchaseDeliveries.columnLabel.get('closed'),
    displayField: 'closed',
    sortField: 'closed',
    displayConvert: 'check',
    align: 'center',
    width: '100px',
  }, {
    id: 'paymentDate',
    text: constPurchaseDeliveries.columnLabel.get('paymentDate'),
    displayField: 'payment_date',
    sortField: 'payment_date',
  }],
  /*
}, {
  id: 'costInformation',
  text: constPurchaseDeliveries.columnLabel.get('costInformation'),
  align: 'center',
  columns: [{
    id: 'preOrderCost',
    text: constPurchaseDeliveries.columnLabel.get('preOrderCost'),
    displayField: 'pre_order_cost',
    sortField: 'pre_order_cost',
    displayConvert: 'check',
    align: 'center',
    width: '100px',
  }, {
    id: 'amount_including_tax',
    text: constPurchaseDeliveries.columnLabel.get('amountIncludingTax'),
    displayField: 'amount_including_tax',
    displayConvert: 'numericalDelimiter',
    sortField: 'amount_including_tax',
    align: 'right',
  }, {
    id: 'amount_excluding_tax',
    text: constPurchaseDeliveries.columnLabel.get('amountExcludingTax'),
    displayField: 'amount_excluding_tax',
    displayConvert: 'numericalDelimiter',
    sortField: 'amount_excluding_tax',
    align: 'right',
  }, {
    id: 'consumption_tax_amount',
    text: constPurchaseDeliveries.columnLabel.get('consumptionTaxAmount'),
    displayField: 'consumption_tax_amount',
    displayConvert: 'numericalDelimiter',
    sortField: 'consumption_tax_amount',
    align: 'right',
  }],
  */
}]
const standardListColumns = {
  delivery: [
    'deliveryInformation',
    'orderInformation_delivery',
    'paymentInformation',
    //'costInformation',
  ],
  general: [
    'deliveryInformation',
    'orderInformation',
    'paymentInformation',
    //'costInformation',
  ],
  total: [
    'deliveryInformation_total',
    'orderInformation',
    'paymentInformation',
    //'costInformation',
  ],
  balance: [
    'deliveryInformation_balance',
    'orderInformation',
    'paymentInformation',
    //'costInformation',
  ],
}

const listIcon: ListViewIcon = {
  caution: {
    name: 'triangle-exclamation',
    classes: [
      '--error',
    ]
  },
}

export default function purchaseAccount () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'delivery_date',
    order: {
      'delivery_date': 1,
    },
  })
  
  const getFormItems = () => {
    return standardListColumns[
        contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }
  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
