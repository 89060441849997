export interface DbDailyReportDetail {
  id: string
  start_time: string
  start_time_minute: number
  end_time: string
  end_time_minute: number
  contract_id: string
  contract_id_number: number
  contract_name: string
  contract_kana: string
  within_time: string | null
  within_time_minute: number
  overtime: string | null
  overtime_minute: number
  working_total_time: string | null
  working_total_time_minute: number
  holiday_time: string | null
  holiday_time_minute: number
  midnight_time: string | null
  midnight_time_minute: number
  //correction_required_count: number
}

export default function dailyReportDetails () {
  const dailyReportDetails: Array<DbDailyReportDetail> = [{
    id: '1',
    start_time: '09:00',
    start_time_minute: 540,
    end_time: '16:00',
    end_time_minute: 960,
    contract_id: '1234',
    contract_id_number: 1234,
    contract_name: '案件Ａ',
    contract_kana: 'アンケンA',
    within_time: '6:00',
    within_time_minute: 360,
    overtime: null,
    overtime_minute: 0,
    working_total_time: '6:00',
    working_total_time_minute: 360,
    holiday_time: null,
    holiday_time_minute: 0,
    midnight_time: null,
    midnight_time_minute: 0,
  }, {
    id: '2',
    start_time: '16:30',
    start_time_minute: 990,
    end_time: '18:00',
    end_time_minute: 1080,
    contract_id: '2345',
    contract_id_number: 2345,
    contract_name: '案件Ｂ',
    contract_kana: 'アンケンB',
    within_time: '0:30',
    within_time_minute: 30,
    overtime: '0:30',
    overtime_minute: 30,
    working_total_time: '1:00',
    working_total_time_minute: 60,
    holiday_time: null,
    holiday_time_minute: 0,
    midnight_time: null,
    midnight_time_minute: 0,
  }, {
    id: '3',
    start_time: '20:30',
    start_time_minute: 1230,
    end_time: '23:00',
    end_time_minute: 1380,
    contract_id: '3456',
    contract_id_number: 3456,
    contract_name: '案件Ｃ',
    contract_kana: 'アンケンC',
    within_time: null,
    within_time_minute: 0,
    overtime: '3:30',
    overtime_minute: 210,
    working_total_time: '3:30',
    working_total_time_minute: 210,
    holiday_time: '',
    holiday_time_minute: 0,
    midnight_time: '1:00',
    midnight_time_minute: 60,
  }]

  return {
    dailyReportDetails,
  }
}
