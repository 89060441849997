export interface DBDailyReport {
  id: string
  date: number
  date_day: string
  within_time: string | null
  within_time_minute: number | null
  overtime: string | null
  overtime_minute: number | null
  working_total_time: string | null
  working_total_time_minute: number | null
  holiday_time: string | null
  holiday_time_minute: number | null
  midnight_time: string | null
  midnight_time_minute: number | null
  //correction_required_count: number | null
  //icon?: string
}

export default function dailyReports () {
  const dailyReports: Array<DBDailyReport> = [{
    id: '1',
    date: 1,
    date_day: '1（土）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '2',
    date: 2,
    date_day: '2（日）',
    within_time: '6:00',
    within_time_minute: 360,
    overtime: null,
    overtime_minute: 0,
    working_total_time: '6:00',
    working_total_time_minute: 360,
    holiday_time: '6:00',
    holiday_time_minute: 360,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '3',
    date: 3,
    date_day: '3（月）',
    within_time: '6:30',
    within_time_minute: 390,
    overtime: '3:30',
    overtime_minute: 210,
    working_total_time: '10:00',
    working_total_time_minute: 600,
    holiday_time: null,
    holiday_time_minute: 0,
    midnight_time: '1:00',
    midnight_time_minute: 60,
    //correction_required_count: 1,
    //icon: 'caution',
  }, {
    id: '4',
    date: 4,
    date_day: '4（火）',
    within_time: '8:00',
    within_time_minute: 480,
    overtime: null,
    overtime_minute: 0,
    working_total_time: '8:00',
    working_total_time_minute: 480,
    holiday_time: '',
    holiday_time_minute: 0,
    midnight_time: '',
    midnight_time_minute: 0,
    //correction_required_count: 0,
  }, {
    id: '5',
    date: 5,
    date_day: '5（水）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '6',
    date: 6,
    date_day: '6（木）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '7',
    date: 7,
    date_day: '7（金）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '8',
    date: 8,
    date_day: '8（土）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '9',
    date: 9,
    date_day: '9（日）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '10',
    date: 10,
    date_day: '10（月）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '11',
    date: 11,
    date_day: '11（火）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '12',
    date: 12,
    date_day: '12（水）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '13',
    date: 13,
    date_day: '13（木）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '14',
    date: 14,
    date_day: '14（金）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '15',
    date: 15,
    date_day: '15（土）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '16',
    date: 16,
    date_day: '16（日）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '17',
    date: 17,
    date_day: '17（月）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '18',
    date: 18,
    date_day: '18（火）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '19',
    date: 19,
    date_day: '19（水）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '20',
    date: 20,
    date_day: '20（木）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '21',
    date: 21,
    date_day: '21（金）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '22',
    date: 22,
    date_day: '22（土）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '23',
    date: 23,
    date_day: '23（日）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '24',
    date: 24,
    date_day: '24（月）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '25',
    date: 25,
    date_day: '25（火）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '26',
    date: 26,
    date_day: '26（水）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '27',
    date: 27,
    date_day: '27（木）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '28',
    date: 28,
    date_day: '28（金）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '29',
    date: 29,
    date_day: '29（土）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '30',
    date: 30,
    date_day: '30（日）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }, {
    id: '31',
    date: 31,
    date_day: '31（月）',
    within_time: null,
    within_time_minute: null,
    overtime: null,
    overtime_minute: null,
    working_total_time: null,
    working_total_time_minute: null,
    holiday_time: null,
    holiday_time_minute: null,
    midnight_time: null,
    midnight_time_minute: null,
    //correction_required_count: null,
  }]

  return {
    dailyReports,
  }
}
