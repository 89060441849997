import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstBudgetSettings from '@/const/transaction/budgetSettings'

const constBudgetSettings = ConstBudgetSettings()

const baseListColumns: ListViewColumn[] = [{
  id: 'base',
  text: constBudgetSettings.columnLabel.get('base'),
  displayField: 'base_name',
  sortField: 'base_display_no',
}, {
  id: 'division',
  text: constBudgetSettings.columnLabel.get('division'),
  displayField: 'division_name',
  sortField: 'division_display_no',
}, {
  id: 'accountDate',
  text: constBudgetSettings.columnLabel.get('accountDate'),
  displayField: 'account_date',
  sortField: 'account_date',
}, {
  id: 'budgetAmount',
  text: constBudgetSettings.columnLabel.get('budgetAmount'),
  displayField: 'budget_amount',
  sortField: 'budget_amount',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'updatedAt',
  text: constBudgetSettings.columnLabel.get('updatedAt'),
  displayField: 'updated_at',
  sortField: 'updated_at',
}, {
  id: 'updatedBy',
  text: constBudgetSettings.columnLabel.get('updatedBy'),
  displayField: 'updated_by_name',
  sortField: 'updated_by_kana',
}]

const standardListColumns = {
  general: [
    //'base',
    //'division',
    'accountDate',
    'budgetAmount',
    'updatedAt',
    'updatedBy',
  ],
}

const listIcon: ListViewIcon = {
}
const listConverter: ListViewConverter = {
}

export default function deposit () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'account_date',
    order: {
      'account_date': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
