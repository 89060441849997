export const tableName = '要素内訳名接頭辞'

export default function journalPrefixies () {
  const itemLabel = new Map([
    [ 'id', 'ID' ],
    [ 'journal_items_type', '要素科目区分' ],
    [ 'main', '工事' ],
    [ 'side', '兼業' ],
    [ 'sale', '販売' ],
    [ 'assets', '資産' ],
    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])
  const columnLabel = new Map([
    [ 'id', 'ID' ],
    [ 'journal_items_type', '要素科目区分' ],
    [ 'main', '工事' ],
    [ 'side', '兼業' ],
    [ 'sale', '販売' ],
    [ 'assets', '資産' ],
    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])

  return {
    itemLabel,
    columnLabel,
  }
}
