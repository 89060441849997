import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstPurchaseDeliveryTotals from '@/const/transaction/purchaseDeliveryTotals'

const constPurchaseDeliveryTotals = ConstPurchaseDeliveryTotals()

const baseListColumns: ListViewColumn[] = [{
  id: 'supplier_code',
  text: constPurchaseDeliveryTotals.columnLabel.get('supplierCode'),
  displayField: 'supplier_code',
  sortField: 'supplier_code',
  bottomRow: {
    bold: true,
  },
}, {
  id: 'supplier',
  text: constPurchaseDeliveryTotals.columnLabel.get('supplier'),
  displayField: 'supplier_name',
  sortField: 'supplier_kana',
  bottomRow: {
    align: 'right',
    bold: true,
  },
}, {
  id: 'purchase_amount_including_tax',
  text: constPurchaseDeliveryTotals.columnLabel.get('purchaseAmountIncludingTax'),
  displayField: 'purchase_amount_including_tax',
  sortField: 'purchase_amount_including_tax',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  bottomRow: {
    bold: true,
  },
}, {
  id: 'purchase_amount_excluding_tax',
  text: constPurchaseDeliveryTotals.columnLabel.get('purchaseAmountExcludingTax'),
  displayField: 'purchase_amount_excluding_tax',
  sortField: 'purchase_amount_excluding_tax',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  bottomRow: {
    bold: true,
  },
  /*
}, {
  id: 'consumption_tax_amount',
  text: constPurchaseDeliveryTotals.columnLabel.get('consumptionTaxAmount'),
  displayField: 'consumption_tax_amount',
  sortField: 'consumption_tax_amount',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  bottomRow: {
    bold: true,
  },
  */
}, {
  id: 'budget_amount_including_tax',
  text: constPurchaseDeliveryTotals.columnLabel.get('budgetAmountIncludingTax'),
  displayField: 'budget_amount_including_tax',
  sortField: 'budget_amount_including_tax',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  bottomRow: {
    bold: true,
  },
}, {
  id: 'budget_amount_excluding_tax',
  text: constPurchaseDeliveryTotals.columnLabel.get('budgetAmountExcludingTax'),
  displayField: 'budget_amount_excluding_tax',
  sortField: 'budget_amount_excluding_tax',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  bottomRow: {
    bold: true,
  },
  /*
}, {
  id: 'discount_return_amount',
  text: constPurchaseDeliveryTotals.columnLabel.get('discountReturnAmount'),
  displayField: 'discount_return_amount',
  sortField: 'discount_return_amount',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  bottomRow: {
    bold: true,
  },
}, {
  id: 'total_purchase_amount_including_tax',
  text: constPurchaseDeliveryTotals.columnLabel.get('totalPurchaseAmountIncludingTax'),
  displayField: 'total_purchase_amount_including_tax',
  sortField: 'total_purchase_amount_including_tax',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  bottomRow: {
    bold: true,
  },
}, {
  id: 'total_purchase_amount_excluding_tax',
  text: constPurchaseDeliveryTotals.columnLabel.get('totalPurchaseAmountExcludingTax'),
  displayField: 'total_purchase_amount_excluding_tax',
  sortField: 'total_purchase_amount_excluding_tax',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  bottomRow: {
    bold: true,
  },
  */
}, {
  id: 'updated_at',
  text: constPurchaseDeliveryTotals.columnLabel.get('updatedAt'),
  displayField: 'updated_at',
  sortField: 'updated_at',
}, {
  id: 'updated_by',
  text: constPurchaseDeliveryTotals.columnLabel.get('updatedBy'),
  displayField: 'updated_by_name',
  sortField: 'updated_by_kana',
}]
const standardListColumns = {
  total: [
    'supplier_code',
    'supplier',
    'purchase_amount_excluding_tax',
    'purchase_amount_including_tax',
    'budget_amount_excluding_tax',
    'budget_amount_including_tax',
    /*
    'discount_return_amount',
    'total_purchase_amount_including_tax',
    'total_purchase_amount_excluding_tax',
    */
  ],
}
const listConverter: ListViewConverter = {
}

export default function purchaseTotal () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'supplier_kana',
    order: {
      'supplier_kana': 1,
    },
  })
  
  const getFormItems = () => {
    return standardListColumns[
        contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }
  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }
  const sumValue = reactive({
    supplier_name: '合計',
    purchase_amount_excluding_tax: 6179123n,
    purchase_amount_including_tax: 6797034n,
    budget_amount_excluding_tax: 6879869n,
    budget_amount_including_tax: 7567854n,
  })

  return {
    baseListColumns,
    listConverter,
    listSort,
    sumValue,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
