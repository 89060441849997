import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewConverter,
  ListViewIcon,
  ListViewSort,
} from '@/const/component/listView'
import ConstDailyReports from '@/const/transaction/dailyReports'

const constTable = ConstDailyReports()

const baseListColumns: ListViewColumn[] = [{
  id: 'date',
  text: constTable.columnLabel.get('date'),
  displayField: 'date_day',
  sortField: 'date',
  align: 'right',
  width: '50px'
}, {
  id: 'within_time',
  text: constTable.columnLabel.get('within_time'),
  displayField: 'within_time',
  sortField: 'within_time_minute',
  align: 'right',
}, {
  id: 'overtime',
  text: constTable.columnLabel.get('overtime'),
  displayField: 'overtime',
  sortField: 'overtime_minute',
  align: 'right',
}, {
  id: 'working_total_time',
  text: constTable.columnLabel.get('working_total_time'),
  displayField: 'working_total_time',
  sortField: 'working_total_time_minute',
  align: 'right',
}, {
  id: 'holiday_time',
  text: constTable.columnLabel.get('holiday_time'),
  displayField: 'holiday_time',
  sortField: 'holiday_time_minute',
  align: 'right',
}, {
  id: 'midnight_time',
  text: constTable.columnLabel.get('midnight_time'),
  displayField: 'midnight_time',
  sortField: 'midnight_time_minute',
  align: 'right',
  /*
}, {
  id: 'correction_required_count',
  text: constTable.columnLabel.get('correction_required_count'),
  displayField: 'correction_required_count',
  sortField: 'correction_required_count',
  align: 'right',
  */
}]

const standardListColumns = {
  dailyReport: [
    'date',
    'within_time',
    'overtime',
    'working_total_time',
    'holiday_time',
    'midnight_time',
    //'correction_required_count',
  ],
}

const listIcon: ListViewIcon = {
  caution: {
    name: 'triangle-exclamation',
    classes: [
      '--error',
    ]
  },
}

const listConverter: ListViewConverter = {
}

export default function attendance () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'date',
    order: {
      'date': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
