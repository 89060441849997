import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import ConstPurchaseDeliveries from '@/const/transaction/purchaseDeliveries'
import ConstPurchaseDeliveryTotals from '@/const/transaction/purchaseDeliveryTotals'
import {
  SearchBoxValue,
  SearchBoxItem,
} from '@/const/component/searchBox'
import {
  ContentsStatus,
} from '@/const/component/pageForm'

const constPurchaseDeliveries = ConstPurchaseDeliveries()
const constPurchaseDeliveryTotals = ConstPurchaseDeliveryTotals()

const totalItems = [
  'supplierCode',
  'supplier',
  //'purchaseAmountIncludingTax',
  //'purchaseAmountExcludingTax',
  //'consumptionTaxAmount',
  //'totalPurchaseAmountIncludingTax',
  //'totalPurchaseAmountExcludingTax',
  //'updatedAt',
  //'updatedBy',
]
const deliveryItems = [
  //'deliveryDate',
  'supplierCode',
  'supplier',
  'deliverySlipNumber',
  'memo',
  //'updatedAt',
  //'updatedBy',
  'purchaseId',
  //'base',
  //'orderer',
  'apportionmentId',
  'apportionmentName',
  //'constructionPeriod',
  //'salesRepresentative',
  //'siteAgent',
  //'closingDate',
  //'paymentDate',
  //'amountIncludingTax',
  //'amountExcludingTax',
  //'consumptionTaxAmount',
]
const balanceItems = [
  //'deliveryDate',
  'supplierCode',
  'supplier',
  'deliverySlipNumber',
  'memo',
  //'updatedAt',
  //'updatedBy',
  'purchaseId',
  //'base',
  //'orderer',
  'apportionmentId',
  'apportionmentName',
  //'constructionPeriod',
  //'salesRepresentative',
  //'siteAgent',
  //'closingDate',
  //'paymentDate',
  //'amountIncludingTax',
  //'amountExcludingTax',
  //'consumptionTaxAmount',
]

export default function search () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const searchItems = computed(() => {
    console.log('computed searchItems', contentsStatus.page)
    switch (contentsStatus.page) {
      case 'total':
        return totalItems.map(el => {
          return {
            id: el,
            label: constPurchaseDeliveryTotals.searchLabel.get(el),
          } as SearchBoxItem
        })
      case 'balance':
        return balanceItems.map(el => {
          return {
            id: el,
            label: constPurchaseDeliveries.searchLabel.get(el),
          } as SearchBoxItem
        })
      default:
        return deliveryItems.map(el => {
          return {
            id: el,
            label: constPurchaseDeliveries.searchLabel.get(el),
          } as SearchBoxItem
        })
    }

  })

  return {
    searchItems,
  }
}
