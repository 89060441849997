export interface DbBudgetSetting {
  id: string
  account_date: string
  budget_amount: bigint
  updated_at: string
  updated_by_name: string
  updated_by_kana: string
}

export default function budgetSettings () {
  const budgetSettings: Array<DbBudgetSetting> = [{
    id: '1',
    account_date: '2023-01-01',
    budget_amount: 200000000n,
    updated_at: '2023-01-01',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '2',
    account_date: '2023-01-10',
    budget_amount: 300000000n,
    updated_at: '2023-01-10',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '3',
    account_date: '2023-01-20',
    budget_amount: 500000000n,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }]

  return {
    budgetSettings,
  }
}
