export const tableName = '部門'

export default function divisions () {
  const itemLabel = new Map([
    [ 'id', 'ID' ],
    [ 'display_order', '表示順' ],
    [ 'name', '部門名' ],
    [ 'kana', '部門名（カナ）' ],
    [ 'division_type', '部門区分' ],
    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])
  const searchLabel = new Map([
    [ 'name', '部門名' ],
  ])
  const columnLabel = new Map([
    [ 'id', 'ID' ],
    [ 'display_order', '表示順' ],
    [ 'name', '部門名' ],
    [ 'kana', '部門名（カナ）' ],
    [ 'division_type', '部門区分' ],
    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])

  return {
    itemLabel,
    searchLabel,
    columnLabel,
  }
}
