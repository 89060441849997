import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstBases from '@/const/transaction/master/bases'

const constTable = ConstBases()

const baseListColumns: ListViewColumn[] = [{
  id: 'display_order',
  text: constTable.columnLabel.get('display_order'),
  displayField: 'display_order',
  sortField: 'display_order',
  align: 'right',
}, {
  id: 'name',
  text: constTable.columnLabel.get('name'),
  displayField: 'name',
  sortField: 'kana',
}, {
  id: 'kana',
  text: constTable.columnLabel.get('kana'),
  displayField: 'kana',
  sortField: 'kana',
}, {
  id: 'postcode',
  text: constTable.columnLabel.get('postcode'),
  displayField: 'postcode',
  sortField: 'postcode',
}, {
  id: 'address',
  text: constTable.columnLabel.get('address'),
  displayFields: [
    'address0',
    'address1',
  ],
  sortField: 'address0 address1',
}, {
  id: 'tel',
  text: constTable.columnLabel.get('tel'),
  displayField: 'tel',
  sortField: 'tel',
}, {
  id: 'fax',
  text: constTable.columnLabel.get('fax'),
  displayField: 'fax',
  sortField: 'fax',
}, {
  id: 'chief',
  text: constTable.columnLabel.get('chief'),
  displayField: 'chief_name',
  sortField: 'chief_kana',
}, {
  id: 'updated_at',
  text: constTable.columnLabel.get('updated_at'),
  displayField: 'updated_at',
  sortField: 'updated_at',
}, {
  id: 'updated_by',
  text: constTable.columnLabel.get('updated_by'),
  displayField: 'updated_by_name',
  sortField: 'updated_by_kana',
}]

const standardListColumns = {
  base: [
    'display_order',
    'name',
    'postcode',
    'address',
    'tel',
    'fax',
    'chief',
    'updated_at',
    'updated_by',
  ],
}

const listIcon: ListViewIcon = {
}
const listConverter: ListViewConverter = {
}

export default function payment () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'display_order',
    order: {
      'display_order': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
