const JA = {
  message: {
    menu: {
      projects: '案件管理',
      constructions: '工事管理',
    },

    loading: 'Loading...',

    cancel: 'キャンセル',
    ok: 'OK',
    clear: 'クリア',
    new: '新規',
    create: '登録',
    update: '更新',
    edit: '編集',
    detail: '詳細',
    delete: '削除',
    login: 'ログイン',

    email: 'メールアドレス',
    password: 'パスワード',

    project: '案件',
    projectNumber: 'No.',
    projectName: '案件名',
    personInCharge: '担当者',
    orderer: '発注者',
    deliveryDate: '有効期限',
    latestUpdateDate: '最終更新日',
    status: 'ステータス',
    createNewProject: '新規作成',
    kana: 'フリガナ',
    estimateDate: '見積日',
    salesRep: '営業担当者',
    constructionRep: '施工(現場)担当者',
    division: '部門',
    destinationTarget: '折衝先',
    sendOrderClient: '発注者(お客様)',
    clientRep: '得意先担当者',
    postcode: '郵便番号',
    address: '住所',
    billingTarget: '請求先',
    estimateConstructionLocation: '見積工事場所',
    estimateConstructionOverview: '見積工事概要',
    publicPrivateWorksType: '公共民間区分',
    workTypePublic: '公共',
    workTypePrivate: '民間',
    contractJobType: '請負区分',
    primeContract: '元請け',
    subContract: '下請け',
    paymentTerm: '支払い条件',
    paymentTermBasicContract: '御社との基本契約による',
    paymentTermByMeeting: '別途お打ち合わせによる',
    paymentTermSpecifiedLocation: 'ご指定場所',
    paymentTermLastDayOfTheFollowingMonth: '末日締翌月末日支払',
    paymentTermFifthOfTheMonthAfterNext: '末日締翌々月5日支払',
    paymentTermOneMonth: '1ヶ月',
    paymentTermTwoMonth: '2ヶ月',
    paymentTermThreeMonth: '3ヶ月',
    deliveryDeadline: '受け渡し期日',
    costInputType: '原価入力区分',
    costInputTypeInput: 'する',
    costInputTypeNotInput: 'しない',
    costRate: '原価率',
    consumptionTaxCalcType: '消費税計算区分',
    consumptionTaxCalcTypeProject: '見積単位',
    consumptionTaxCalcTypeItem: '明細単位',
    taxRateType: '税率区分',
    taxRateTypeZero: '0%',
    taxRateTypeTen: '10%',
    taxRateKind: '税率種別',
    taxRateKindStandard: '標準税率',
    taxRateKindReduced: '軽減税率',
    amountExcludingTax: 'ネット税別金額',
    consumptionTax: 'ネット消費税',
    receiveOrdersAccuracy: '見積受注確度',
    receiveOrdersAccuracyA: 'A(80%以上)',
    receiveOrdersAccuracyB: 'B(50%以上80%未満)',
    receiveOrdersAccuracyC: 'C(50%未満)',

    construction: '工事',
    constructionNo: 'No.',
    constructionName: '工事名',
    receiveOrdersType: '受注区分',
    receiveOrdersTypeConstruction: '工事',
    receiveOrdersTypeProductSales: '物販',
    receiveOrdersTypeFault: '障害',
    receiveOrdersTypeMaintenance: '保守',
    receiveOrdersTypeService: '役務',
    constructionStatusInquiry: '引き合い',
    constructionStatusEstimate: '見積もり',
    constructionStatusAcceptance: '受注',
    constructionStatusFailure: '失注',
    constructionStatusCompletion: '完了',
    constructionLocation: '工事場所',
    constructionDesigner: '工事設計者',
    constructionOverview: '工事概要',
    siteAgent: '現場代理人',
    leadEngineer: '主任技術者',
    supervisingEngineer: '監理技術者',
    ordersDate: '受注日',
    contractConstructionPeriodStartDate: '契約工期開始日',
    contractConstructionPeriodEndDate: '契約工期終了日',
    implementationConstructionPeriodStartDate: '実施工期開始日',
    implementationConstructionPeriodEndDate: '実施工期終了日',
    completeDate: '完成日',
    constructionAttributeTypeStandard: '標準工事',
    constructionAttributeTypeOutsideCost: '原価集計外',
    attributeType: '属性区分',
    note: '備考',
    contractJobTypeInfo: '請負情報',
    taxTreatment: '税処理',
    taxTreatmentExcluded: '外税',
    taxTreatmentIncluded: '内税',
    taxTreatmentNone: '税無',
    taxTreatmentConsumption: '消費税',
    contractJobAmount: '請負金額',
    contractJobAmountExcludingTax: '請負税抜額',
    contractJobConsumptionTaxAmount: '請負消費税額',
    taxRate: '税率',
    productName: '品名',
    quantity: '数量',
    unit: '単位',
    unitPrice: '単価',
    amount: '金額',
    constructionItemNo: 'No.',
    constructionItem: '工事明細',

    createNewSendOrderClient: '新規作成',
    sendOrderClientNo: 'No.',
    sendOrderClientCode: '発注者コード',
    sendOrderClientName: '発注者名',
    sendOrderClientKana: '発注者名（カナ）',
    officialCompanyName: '正式社名',
    officialBranchName: '正式支店名',
    honorificTitle: '敬称',
    sendOrderClientType: '発注者区分',
    sendOrderClientTypeGovernmentOffice: '官公庁',
    sendOrderClientTypeMunicipalities: '市町村',
    sendOrderClientTypePrimeContractor: '元請先',
    sendOrderClientTypeOtherCompanies: '同業者',
    sendOrderClientTypeOtherBuilders: '其他建築業',
    sendOrderClientTypePrivateCompany: '一般民間',
    sendOrderClientTypeOthers: 'その他',
    closingDate: '締め日',
    collectionMonth: '回収月',
    collectionMonthThisMonth: '当月',
    collectionMonthNextMonth: '翌月',
    collectionMonthMonthAfterNext: '翌々月',
    collectionDate: '回収日',
    taxRounding: '消費税端数処理',
    totalRounding: '全額端数処理',
    roundingRoundDown: '切り捨て',
    roundingRounding: '四捨五入',
    roundingRoundUp: '切り上げ',
    collectionCharge: '回収担当者',
    bankCode: '銀行コード',
    bankBranchCode: '銀行支店コード',
    billSite: '手形サイト',
    designatedRequest: '指定請求',
    lastOrderDate: '最終受注日',
    numberOfOrders: '受注回数',

    createNewSupplier: '新規作成',
    supplierNo: 'No.',
    supplierCode: '業者コード',
    supplierName: '業者名',
    supplierKana: '業者名（カナ）',
    paymentMonth: '支払月',
    paymentMonthThisMonth: '当月',
    paymentMonthNextMonth: '翌月',
    paymentMonthMonthAfterNext: '翌々月',
    paymentDay: '支払日',
    paymentType: '支払区分',
    paymentTypeBank: '銀行振込',
    depositType: '預金種目',
    accountNumber: '口座番号',
    accountHolder: '口座名義',
    billHolder: '手形名義',
    billStandardAmount: '手形基準額',
    billStandardAmount2: '手形基準額2',
    billStandardAmount3: '手形基準額3',
    billShippingCost: '手形郵送料',
    bankId: '銀行コード',
    bankBranchId: '銀行支店コード',

    emailAddress: 'メールアドレス',
    label: 'ラベル',
    phoneNumber: '電話',
    phoneNumberOrFax: '電話 / FAX',
    phoneNumberOrFaxNo: '電話 / FAX番号',
    lineType: '回線種別',
    lineTypePhone: '電話',
    lineTypeFax: 'FAX',

    createNewEmployee: '新規作成',
    employeeNumber: '社員番号',
    employeeName: '社員名',
    employeeKana: '社員名（カナ）',
    sex: '性別',
    sexUnknown: '不明',
    sexFemale: '男性',
    sexMale: '女性',
    bloodType: '血液型',
    bloodTypeUnknown: '不明',
    bloodTypeA: 'A型',
    bloodTypeAb: 'AB型',
    bloodTypeB: 'B型',
    bloodTypeO: 'O型',
    birthdate: '生年月日',
    divisionName: '部門名',
    base: '拠点',
    baseName: '拠点名',
    joiningDate: '入社年月日',
    retirementDate: '退職年月日',
    healthInsuranceInsuredNumber: '健康保険被保険者番号',
    employmentInsuranceInsuredNumber: '雇用保険被保険者番号',
    basicPensionNumber: '基礎年金番号',
    laborCostUnitPrice: '労務費単価',

    confirmDelete: '削除します。よろしいですか？',

    format: {
      projectDialogTitle: '案件番号「{0}」',
      createProjectToastMessage: '案件番号「{0}」を登録しました。',
      updateProjectToastMessage: '案件番号「{0}」を更新しました。',
      deleteProjectToastMessage: '案件番号「{0}」を削除しました。',
      deleteProjectToastErrorMessage: '案件番号「{0}」の削除に失敗しました。',

      constructionDialogTitle: '工事番号「{0}」',
      createConstructionToastMessage: '工事番号「{0}」を登録しました。',
      updateConstructionToastMessage: '工事番号「{0}」を更新しました。',
      deleteConstructionToastMessage: '工事番号「{0}」を削除しました。',
      deleteConstructionToastErrorMessage: '工事番号「{0}」の削除に失敗しました。',

      constructionItemDialogTitle: '工事明細番号「{0}」',
      createConstructionItemToastMessage: '工事明細番号「{0}」を登録しました。',
      updateConstructionItemToastMessage: '工事明細番号「{0}」を更新しました。',
      deleteConstructionItemToastMessage: '工事明細番号「{0}」を削除しました。',
      deleteConstructionItemToastErrorMessage: '工事明細番号「{0}」の削除に失敗しました。',

      divisionDialogTitle: '部門番号「{0}」',
      deleteDivisionToastMessage: '部門番号「{0}」を削除しました。',
      deleteDivisionToastErrorMessage: '部門番号「{0}」の削除に失敗しました。',

      baseDialogTitle: '拠点番号「{0}」',
      deleteBaseToastMessage: '拠点番号「{0}」を削除しました。',
      deleteBaseToastErrorMessage: '拠点番号「{0}」の削除に失敗しました。',

      sendOrderClientDialogTitle: '発注者番号「{0}」',
      createSendOrderClientToastMessage: '発注者番号「{0}」を登録しました。',
      updateSendOrderClientToastMessage: '発注者番号「{0}」を更新しました。',
      deleteSendOrderClientToastMessage: '発注者番号「{0}」を削除しました。',
      deleteSendOrderClientToastErrorMessage: '発注者番号「{0}」の削除に失敗しました。',

      supplierDialogTitle: '業者番号「{0}」',
      createSupplierToastMessage: '業者番号「{0}」を登録しました。',
      updateSupplierToastMessage: '業者番号「{0}」を更新しました。',
      deleteSupplierToastMessage: '業者番号「{0}」を削除しました。',
      deleteSupplierToastErrorMessage: '業者番号「{0}」の削除に失敗しました。',

      employeeDialogTitle: '社員番号「{0}」',
      createEmployeeToastMessage: '社員番号「{0}」を登録しました。',
      updateEmployeeToastMessage: '社員番号「{0}」を更新しました。',
      deleteEmployeeToastMessage: '社員番号「{0}」を削除しました。',
      deleteEmployeeToastErrorMessage: '社員番号「{0}」の削除に失敗しました。',
    },

    // error messages
    error: {
      systemError: '予期せぬエラーが発生しました。',
      unauthorized: 'メールアドレスまたはパスワードが間違えています。',
      validationError: '入力内容に誤りがあります。',
      projectNotFound: '案件は登録されていません。',
      constructionNotFound: '工事は登録されていません。',
      constructionItemNotFound: '工事明細は登録されていません。',
      sendOrderClientNotFound: '発注者マスタは登録されていません。',
      supplierNotFound: '業者マスタは登録されていません。',
      emailAddressNotFound: 'メールアドレスは登録されていません。',
      phoneNumberOrFaxNotFound: '電話/FAX情報は登録されていません。',
      employeeNotFound: '社員マスタは登録されていません。',
    },

    // tab names
    tabs: {
      project: '案件',
      construction: '工事',
      constructionItem: '工事明細'
    },

    // NOTE: `asaka-api/resources/lang/ja/validation.php`と合わせる
    validation: {
      required: '{0}は必須です。',
      maxLength: '{0}は{1}文字以下で入力して下さい。',
      date: '{0}は日付を入力して下さい。',
      email: 'メールアドレスの形式が不正です。',
      integer: '{0}は整数値で入力してください。',
      postcode: '{0}は7桁の数値で入力してください。',
      employeePasswordRegex: 'パスワードは10文字以上30文字以下の半角英数字で入力してください。',
      employeeLaborCostUnitPriceRegex: '労務費単価は6桁以下（小数点2位まで可能）の数値で入力してください。'
    },
    
    ability: {
      admin: 'admin',
      employee: 'employee',
    }
  }
}

export default JA
