export const tableName = '発注者'

export default function sendOrderClients () {
  const columnLabel = new Map([
    [ 'id', 'ID' ],
    [ 'code', '発注者コード' ],
    [ 'name', '発注者' ],
    [ 'kana', '発注者（カナ）' ],
    [ 'officialCompanyName', '正式社名' ],
    [ 'officialBranchName', '正式支店名' ],
    [ 'honorificTitle', '敬称' ],
    [ 'postcode', '郵便番号' ],
    [ 'address', '住所' ],
    [ 'sendOrderClientType', '発注者区分' ],
    [ 'closingDate', '締め日' ],
    [ 'collectionMonth', '回収月' ],
    [ 'collectionDate', '回収日' ],
    [ 'taxRounding', '消費税端数処理' ],
    [ 'totalRounding', '金額端数処理' ],
    [ 'collectionChargeId', '回収担当ID' ],
    [ 'bankCode', '銀行コード' ],
    [ 'bankBranchCode', '銀行支店コード' ],
    [ 'billSite', '手形サイト' ],
    [ 'designatedRequest', '指定請求' ],
    [ 'lastOrderDate', '最終受注日' ],
    [ 'numberOfOrders', '受注回数' ],
  ])

  return {
    columnLabel,
  }
}
