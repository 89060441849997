import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstAttendances from '@/const/transaction/attendances'

const constAttendances = ConstAttendances()

const baseListColumns: ListViewColumn[] = [{
  id: 'employee_number',
  text: '社員番号',
  displayField: 'employee_number',
  sortField: 'employee_number',
}, {
  id: 'employee_name',
  text: '氏名',
  displayField: 'employee_name',
  sortField: 'employee_kana',
}, {
  id: 'base',
  text: '拠点',
  displayField: 'base_name',
  sortField: 'base_kana',
}, {
  id: 'working_period',
  text: '稼働期間',
  align: 'center',
  columns: [{
    id: 'working_period_start',
    text: '開始日',
    displayField: 'working_period_start',
    sortField: 'working_period_start',
    align: 'right',
  }, {
    id: 'working_period_end',
    text: '終了日',
    displayField: 'working_period_end',
    sortField: 'working_period_end',
    align: 'right',
  }, {
    id: 'working_days',
    text: '稼動日数',
    displayField: 'working_days',
    sortField: 'working_days',
    align: 'right',
  }],
  /*
}, {
  id: 'working_period_start',
  text: '稼働期間（開始）',
  displayField: 'working_period_start',
  sortField: 'working_period_start',
}, {
  id: 'working_period_end',
  text: '稼働期間（終了）',
  displayField: 'working_period_end',
  sortField: 'working_period_end',
}, {
  id: 'working_days',
  text: '稼働日数',
  displayField: 'working_days',
  sortField: 'working_days',
  align: 'right',
  */
}, {
  id: 'working_time',
  text: '稼働時間',
  align: 'center',
  columns: [{
    id: 'within_time',
    text: '時間内',
    displayField: 'within_time',
    sortField: 'within_time',
    align: 'right',
  }, {
    id: 'overtime',
    text: '時間外',
    displayField: 'overtime',
    sortField: 'overtime',
    align: 'right',
  }, {
    id: 'working_total_time',
    text: '合計',
    displayField: 'working_total_time',
    sortField: 'working_total_time',
    align: 'right',
  }, {
    id: 'holiday_time',
    text: '休出',
    displayField: 'holiday_time',
    sortField: 'holiday_time',
    align: 'right',
  }, {
    id: 'midnight_time',
    text: '深夜',
    displayField: 'midnight_time',
    sortField: 'midnight_time',
    align: 'right',
  }],
  /*
}, {
  id: 'working_time',
  text: '稼働時間',
  displayField: 'working_time',
  sortField: 'working_time',
  align: 'right',
}, {
  id: 'within_time',
  text: '時間内',
  displayField: 'within_time',
  sortField: 'within_time',
}, {
  id: 'overtime',
  text: '時間外',
  displayField: 'overtime',
  sortField: 'overtime',
}, {
  id: 'holiday_time',
  text: '休出時間',
  displayField: 'holiday_time',
  sortField: 'holiday_time',
}, {
  id: 'midnight_time',
  text: '深夜時間',
  displayField: 'midnight_time',
  sortField: 'midnight_time',
  */
}, {
  id: 'correction_required_count',
  text: '要修正件数',
  displayField: 'correction_required_count',
  sortField: 'correction_required_count',
  align: 'right',
}]

const standardListColumns = {
  attendance: [
    'employee_number',
    'employee_name',
    'base',
    'working_period',
    //'working_period_start',
    //'working_period_end',
    //'working_days',
    'working_time',
    //'within_time',
    //'overtime',
    //'holiday_time',
    //'midnight_time',
    'correction_required_count',
  ],
}

const listConverter: ListViewConverter = {
}

export default function attendance () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'employee_number',
    order: {
      'employee_number': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
