import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstPlanDetails from '@/const/transaction/planDetails'

const constTable = ConstPlanDetails()

/*
import {
  TreeViewType,
} from '@/const/component/treeView'
*/
//import ConstPlans from '@/const/transaction/plans'

//const constTable = ConstPlans()

const columns: { [key: string]: ListViewColumn[] } = {
  plan: [{
    id: 'constraction',
    text: '工事',
    displayField: 'constraction_name',
    sortField: 'constraction_name',
  }, {
    id: 'material',
    text: '材料費',
    displayField: 'material',
    sortField: 'material',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'outsource',
    text: '外注費',
    displayField: 'outsource',
    sortField: 'outsource',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'expenses',
    text: '経費',
    displayField: 'expenses',
    sortField: 'expenses',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'labor',
    text: '労務費',
    displayField: 'labor',
    sortField: 'labor',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'others',
    text: 'その他',
    displayField: 'others',
    sortField: 'others',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }],
  construction: [{
    id: 'cost_type',
    text: '経費種別',
    displayField: 'name',
    sortField: 'name',
  }, {
    id: 'quantity',
    text: '数量',
    displayField: 'quantity',
    sortField: 'quantity',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'unit_price',
    text: '単価［円］',
    displayField: 'unit_price',
    sortField: 'unit_price',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'consumption_tax_type',
    text: '消費税区分',
    displayField: 'consumption_tax_type',
    sortField: 'consumption_tax_type',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'consumption_tax_rate',
    text: '消費税率',
    displayField: 'consumption_tax_rate',
    sortField: 'consumption_tax_rate',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'consumption_tax_amount',
    text: '消費税額［円］',
    displayField: 'consumption_tax_amount',
    sortField: 'consumption_tax_amount',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'amount_excluding_tax',
    text: '税抜金額［円］',
    displayField: 'amount_excluding_tax',
    sortField: 'amount_excluding_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'amount_including_tax',
    text: '税込金額［円］',
    displayField: 'amount_including_tax',
    sortField: 'amount_including_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }],
  cost: [{
    id: 'name',
    text: '名称',
    displayField: 'name',
    sortField: 'name',
  }, {
    id: 'quantity',
    text: '数量',
    displayField: 'quantity',
    sortField: 'quantity',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'unit_price',
    text: '単価［円］',
    displayField: 'unit_price',
    sortField: 'unit_price',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'consumption_tax_type',
    text: '消費税区分',
    displayField: 'consumption_tax_type',
    sortField: 'consumption_tax_type',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'consumption_tax_rate',
    text: '消費税率',
    displayField: 'consumption_tax_rate',
    sortField: 'consumption_tax_rate',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'consumption_tax_amount',
    text: '消費税額［円］',
    displayField: 'consumption_tax_amount',
    sortField: 'consumption_tax_amount',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'amount_excluding_tax',
    text: '税抜金額［円］',
    displayField: 'amount_excluding_tax',
    sortField: 'amount_excluding_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'amount_including_tax',
    text: '税込金額［円］',
    displayField: 'amount_including_tax',
    sortField: 'amount_including_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }],
  item: [{
    id: 'supplier',
    text: '仕入先',
    displayField: 'supplier_name',
    sortField: 'supplier_kana',
  }, {
    id: 'model_number',
    text: '型番',
    displayField: 'model_number',
    sortField: 'model_number',
  }, {
    id: 'description',
    text: '品名／規格',
    displayField: 'description',
    sortField: 'description',
  }, {
    id: 'quantity',
    text: '数量',
    displayField: 'quantity',
    sortField: 'quantity',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'unit',
    text: '単位',
    displayField: 'unit',
    sortField: 'unit',
  }, {
    id: 'unit_price',
    text: '単価［円］',
    displayField: 'unit_price',
    sortField: 'unit_price',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'consumption_tax_type',
    text: '消費税区分',
    displayField: 'consumption_tax_type',
    sortField: 'consumption_tax_type',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'consumption_tax_rate',
    text: '消費税率［％］',
    displayField: 'consumption_tax_rate',
    sortField: 'consumption_tax_rate',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'consumption_tax_amount',
    text: '消費税額［円］',
    displayField: 'consumption_tax_amount',
    sortField: 'consumption_tax_amount',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'amount_excluding_tax',
    text: '税抜金額［円］',
    displayField: 'amount_excluding_tax',
    sortField: 'amount_excluding_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'amount_including_tax',
    text: '税込金額［円］',
    displayField: 'amount_including_tax',
    sortField: 'amount_including_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }],
  option: [{
    id: 'model_number',
    text: '型番',
    displayField: 'model_number',
    sortField: 'model_number',
  }, {
    id: 'description',
    text: '品名／規格',
    displayField: 'description',
    sortField: 'description',
  }, {
    id: 'quantity',
    text: '数量',
    displayField: 'quantity',
    sortField: 'quantity',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'unit_price',
    text: '単価［円］',
    displayField: 'unit_price',
    sortField: 'unit_price',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'consumption_tax_type',
    text: '消費税区分',
    displayField: 'consumption_tax_type',
    sortField: 'consumption_tax_type',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'consumption_tax_rate',
    text: '消費税率',
    displayField: 'consumption_tax_rate',
    sortField: 'consumption_tax_rate',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'consumption_tax_amount',
    text: '消費税額［円］',
    displayField: 'consumption_tax_amount',
    sortField: 'consumption_tax_amount',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'amount_excluding_tax',
    text: '税抜金額［円］',
    displayField: 'amount_excluding_tax',
    sortField: 'amount_excluding_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'amount_including_tax',
    text: '税込金額［円］',
    displayField: 'amount_including_tax',
    sortField: 'amount_including_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }],
}

const listIcon: ListViewIcon = {
}
const listConverter: ListViewConverter = {
  expense_type: {
    '1': {
      text: constTable.expenseType.get('1') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(0,140,0)',
        color: 'rgb(255,255,255)',
      },
    },
    '2': {
      text: constTable.expenseType.get('2') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(180,140,0)',
        color: 'rgb(255,255,255)',
      },
    },
    '3': {
      text: constTable.expenseType.get('3') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(200,100,100)',
        color: 'rgb(255,255,255)',
      },
    },
    '4': {
      text: constTable.expenseType.get('4') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(50,50,200)',
        color: 'rgb(255,255,255)',
      },
    },
    '0': {
      text: constTable.expenseType.get('0') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(0,40,160)',
        color: 'rgb(255,255,255)',
      },
    },
  },
}

export default function planInformation () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'base_display_no',
    order: {
      'base_display_no': 1,
    },
  })

  const getListColumns = (type: string) => columns[type]
  const getListSort = (type = 'root') => {
    const ret: ListViewSort = {
      field: '',
      order: {
        '': 1,
      }
    }
    switch (type) {
      case 'plan':
      case 'construction':
      case 'cost':
      case 'item':
      case 'option':
        ret.field = ''
        ret.order = {
          '': 1,
        }
        break
    }
    return ret
  }

  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    getListSort,
    setListColumns,
  }
}
