import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstEmployees from '@/const/transaction/master/employees'

const constTable = ConstEmployees()

const baseListColumns: ListViewColumn[] = [{
  id: 'employee_number',
  text: constTable.columnLabel.get('employee_number'),
  displayField: 'employee_number',
  sortField: 'employee_number',
  align: 'right',
}, {
  id: 'name',
  text: constTable.columnLabel.get('name'),
  displayField: 'name',
  sortField: 'kana',
}, {
  id: 'kana',
  text: constTable.columnLabel.get('kana'),
  displayField: 'kana',
  sortField: 'kana',
}, {
  id: 'birthdate',
  text: constTable.columnLabel.get('birthdate'),
  displayField: 'birthdate',
  sortField: 'birthdate',
}, {
  id: 'age',
  text: constTable.columnLabel.get('age'),
  displayField: 'age',
  sortField: 'age',
  align: 'right',
}, {
  id: 'gender',
  text: constTable.columnLabel.get('gender'),
  displayField: 'gender',
  sortField: 'gender',
  align: 'center',
  width: '100px',
}, {
  id: 'blood_type',
  text: constTable.columnLabel.get('blood_type'),
  displayField: 'blood_type',
  sortField: 'blood_type',
  align: 'center',
  width: '100px',
}, {
  id: 'postcode',
  text: constTable.columnLabel.get('postcode'),
  displayField: 'postcode',
  sortField: 'postcode',
}, {
  id: 'address',
  text: constTable.columnLabel.get('address'),
  displayFields: [
    'address0',
    'address1',
  ],
  sortField: 'address0 address1',
}, {
  id: 'email_address',
  text: constTable.columnLabel.get('email_address'),
  displayField: 'email_address',
  sortField: 'email_address',
}, {
  id: 'slack_username',
  text: constTable.columnLabel.get('slack_username'),
  displayField: 'slack_username',
  sortField: 'slack_username',
}, {
  id: 'division',
  text: constTable.columnLabel.get('division'),
  displayField: 'division_name',
  sortField: 'division_display_order',
}, {
  id: 'base',
  text: constTable.columnLabel.get('base'),
  displayField: 'base_name',
  sortField: 'base_display_order',
}, {
  id: 'joining_date',
  text: constTable.columnLabel.get('joining_date'),
  displayField: 'joining_date',
  sortField: 'joining_date',
}, {
  id: 'retirement_date',
  text: constTable.columnLabel.get('retirement_date'),
  displayField: 'retirement_date',
  sortField: 'retirement_date',
}, {
  id: 'length_of_service',
  text: constTable.columnLabel.get('length_of_service'),
  displayField: 'length_of_service',
  sortField: 'length_of_service',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'enrollment_type',
  text: constTable.columnLabel.get('enrollment_type'),
  displayField: 'enrollment_type',
  sortField: 'enrollment_type',
  align: 'center',
  width: '100px',
}, {
  id: 'health_insurance_insured_number',
  text: constTable.columnLabel.get('health_insurance_insured_number'),
  displayField: 'health_insurance_insured_number',
  sortField: 'health_insurance_insured_number',
}, {
  id: 'employment_insurance_insured_number',
  text: constTable.columnLabel.get('employment_insurance_insured_number'),
  displayField: 'employment_insurance_insured_number',
  sortField: 'employment_insurance_insured_number',
}, {
  id: 'basic_pension_number',
  text: constTable.columnLabel.get('basic_pension_number'),
  displayField: 'basic_pension_number',
  sortField: 'basic_pension_number',
}, {
  id: 'labor_cost_unit_price',
  text: constTable.columnLabel.get('labor_cost_unit_price'),
  displayField: 'labor_cost_unit_price',
  sortField: 'labor_cost_unit_price',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'updated_at',
  text: constTable.columnLabel.get('updated_at'),
  displayField: 'updated_at',
  sortField: 'updated_at',
}, {
  id: 'updated_by',
  text: constTable.columnLabel.get('updated_by'),
  displayField: 'updated_by_name',
  sortField: 'updated_by_kana',
}]

const standardListColumns = {
  employee: [
    'employee_number',
    'name',
    //'kana',
    'birthdate',
    'age',
    'gender',
    'blood_type',
    'postcode',
    'address',
    'email_address',
    //'slack_username',
    'division',
    'base',
    'joining_date',
    'retirement_date',
    'length_of_service',
    'enrollment_type',
    'health_insurance_insured_number',
    'employment_insurance_insured_number',
    'basic_pension_number',
    'labor_cost_unit_price',
    'updated_at',
    'updated_by',
  ],
}

const listIcon: ListViewIcon = {
}
const listConverter: ListViewConverter = {
  enrollment_type: {
    '0': {
      text: '入社前',
      type: 'text',
    },
    '1': {
      text: '在籍中',
      type: 'text',
    },
    '2': {
      text: '退職済み',
      type: 'text',
    },
  },
  gender: {
    '0': {
      text: '不明',
      type: 'text',
    },
    '1': {
      text: '男性',
      type: 'text',
    },
    '2': {
      text: '女性',
      type: 'text',
    },
  },
  blood_type: {
    '0': {
      text: '不明',
      type: 'text',
    },
    '1': {
      text: 'Ａ型',
      type: 'text',
    },
    '2': {
      text: 'Ｂ型',
      type: 'text',
    },
    '3': {
      text: 'Ｏ型',
      type: 'text',
    },
    '4': {
      text: 'AB型',
      type: 'text',
    },
  },
}

export default function employee () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'employee_number',
    order: {
      'employee_number': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
