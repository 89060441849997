import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstJournalPrefixies from '@/const/transaction/journalPrefixies'

const constTable = ConstJournalPrefixies()

const baseListColumns: ListViewColumn[] = [{
  id: 'journal_items_type',
  text: constTable.columnLabel.get('journal_items_type'),
  displayField: 'journal_items_type',
  sortField: 'journal_items_type_number',
}, {
  id: 'main',
  text: constTable.columnLabel.get('main'),
  displayField: 'main',
  sortField: 'main',
}, {
  id: 'side',
  text: constTable.columnLabel.get('side'),
  displayField: 'side',
  sortField: 'side',
}, {
  id: 'sale',
  text: constTable.columnLabel.get('sale'),
  displayField: 'sale',
  sortField: 'sale',
  style: {
    conditions: 'valueIsNull',
    backgroundColor: 'rgba(0,0,0,0.7)'
  },
}, {
  id: 'assets',
  text: constTable.columnLabel.get('assets'),
  displayField: 'assets',
  sortField: 'assets',
  style: {
    conditions: 'valueIsNull',
    backgroundColor: 'rgba(0,0,0,0.7)'
  }
}, {
  id: 'updated_at',
  text: constTable.columnLabel.get('updated_at'),
  displayField: 'updated_at',
  sortField: 'updated_at',
}, {
  id: 'updated_by',
  text: constTable.columnLabel.get('updated_by'),
  displayField: 'updated_by_name',
  sortField: 'updated_by_kana',
}]

const standardListColumns = {
  prefix: [
    'journal_items_type',
    'main',
    'side',
    'sale',
    'assets',
    'updated_at',
    'updated_by',
  ],
}

const listIcon: ListViewIcon = {
}
const listConverter: ListViewConverter = {
}

export default function payment () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'journal_items_type_number',
    order: {
      'journal_items_type_number': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
