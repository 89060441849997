export interface DbBase {
  id: string
  name: string
  kana: string
  display_order: number
  postcode: string | null
  address0: string | null
  address1: string | null
  tel: string | null
  fax: string | null
  chief_id: string
  chief_name: string
  chief_kana: string
  updated_at: string
  updated_by_name: string
  updated_by_kana: string
}

export default function bases () {
  const bases: Array<DbBase> = [{
    id: '1',
    name: '郡山本社',
    kana: 'コオリヤマホンシャ',
    display_order: 1,
    postcode: '963-0201',
    address0: '福島県★★市☆☆★★ 12-450',
    address1: null,
    tel: '024-956-7890',
    fax: '024-956-7891',
    chief_id: '1',
    chief_name: '所長Ａ',
    chief_kana: 'ショチョウA',
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '2',
    name: '仙台',
    kana: 'センダイ',
    display_order: 2,
    postcode: '984-0032',
    address0: '宮城県★★区☆☆★★ 12-450',
    address1: null,
    tel: '022-345-7890',
    fax: '022-345-7891',
    chief_id: '2',
    chief_name: '所長Ｂ',
    chief_kana: 'ショチョウB',
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '3',
    name: '東京',
    kana: 'トウキョウ',
    display_order: 3,
    postcode: '102-0094',
    address0: '東京都★★区☆☆★★ 12-450',
    address1: null,
    tel: '03-3456-7890',
    fax: '03-3456-7891',
    chief_id: '3',
    chief_name: '所長Ｃ',
    chief_kana: 'ショチョウc',
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }]

  return {
    bases,
  }
}
