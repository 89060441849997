import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import { PadViewItem } from '@/const/component/padView'
import ConstJournalDepositItems from '@/const/transaction/journalDepositItems'

export default function pad () {
  const constTable = ConstJournalDepositItems()
  /*
  const publicPrivateWorksTypeItems = computed(() => {
    return Array.from(constTable.longPublicPrivateWorksType).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })
  const collectionMonthItems = computed(() => {
    return Array.from(constTable.collectionMonth).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })
  const taxRoundingItems = computed(() => {
    return Array.from(constTable.taxRounding).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })
  const amountRoundingItems = computed(() => {
    return Array.from(constTable.amountRounding).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })
  */
  const getPadItems = (readonly = true) => {
    const ro = readonly
    const rq = !readonly

    const items: PadViewItem[] = [{
      id: 'journalDepositItems',
      groups: [{
        items: [{
          id: 'deposit_type',
          label: constTable.itemLabel.get('deposit_type') ?? '',
          type: 'depositType',
          readonly: ro,
          required: rq,
        }, {
          id: 'element_name',
          label: constTable.itemLabel.get('element_name') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }]
      }],
    }, {
      id: 'account_code_main',
      label: constTable.itemLabel.get('account_code_main'),
      groups: [{
        items: [{
          id: 'account_code_main_debit',
          label: constTable.itemLabel.get('account_code_main_debit') ?? '',
          type: 'number',
          readonly: ro,
          required: false,
        }, {
          id: 'account_code_main_credit',
          label: constTable.itemLabel.get('account_code_main_credit') ?? '',
          type: 'number',
          readonly: ro,
          required: false,
        }]
      }],
    }, {
      id: 'account_code_side',
      label: constTable.itemLabel.get('account_code_side'),
      groups: [{
        items: [{
          id: 'account_code_side_debit',
          label: constTable.itemLabel.get('account_code_side_debit') ?? '',
          type: 'number',
          readonly: ro,
          required: false,
        }, {
          id: 'account_code_side_credit',
          label: constTable.itemLabel.get('account_code_side_credit') ?? '',
          type: 'number',
          readonly: ro,
          required: false,
        }]
      }],
    }, {
      id: 'account_code_main_advance',
      label: constTable.itemLabel.get('account_code_main_advance'),
      groups: [{
        items: [{
          id: 'account_code_main_debit_advance',
          label: constTable.itemLabel.get('account_code_main_debit_advance') ?? '',
          type: 'number',
          readonly: ro,
          required: false,
        }, {
          id: 'account_code_main_credit_advance',
          label: constTable.itemLabel.get('account_code_main_credit_advance') ?? '',
          type: 'number',
          readonly: ro,
          required: false,
        }]
      }],
    }, {
      id: 'account_code_side_advance',
      label: constTable.itemLabel.get('account_code_side_advance'),
      groups: [{
        items: [{
          id: 'account_code_side_debit_advance',
          label: constTable.itemLabel.get('account_code_side_debit_advance') ?? '',
          type: 'number',
          readonly: ro,
          required: false,
        }, {
          id: 'account_code_side_credit_advance',
          label: constTable.itemLabel.get('account_code_side_credit_advance') ?? '',
          type: 'number',
          readonly: ro,
          required: false,
        }]
      }],
    }]

    return items
  }

  return {
    /*
    collectionMonthItems,
    publicPrivateWorksTypeItems,
    taxRoundingItems,
    amountRoundingItems,
    */
    getPadItems,
  }
}
