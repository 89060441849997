import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
//import ConstAttendanceDailies from '@/const/transaction/attendanceDailies'

//const constAttendanceDailies = ConstAttendanceDailies()

const baseListColumns: ListViewColumn[] = [{
  id: 'date',
  text: '日付',
  displayField: 'date',
  sortField: 'id',
}, {
  id: 'time',
  text: '稼働時刻',
  align: 'center',
  columns: [{
    id: 'time_start',
    text: '開始',
    displayField: 'time_start',
    sortField: 'time_start_number',
    align: 'right',
  }, {
    id: 'time_end',
    text: '終了',
    displayField: 'time_end',
    sortField: 'time_end_number',
    align: 'right',
  }],
}, {
  id: 'working_time',
  text: '稼働時間',
  align: 'center',
  columns: [{
    id: 'within_time',
    text: '時間内',
    displayField: 'within_time',
    sortField: 'within_time',
    align: 'right',
  }, {
    id: 'overtime',
    text: '時間外',
    displayField: 'overtime',
    sortField: 'overtime',
    align: 'right',
  }, {
    id: 'working_total_time',
    text: '合計',
    displayField: 'working_total_time',
    sortField: 'working_total_time',
    align: 'right',
  }, {
    id: 'holiday_time',
    text: '休出',
    displayField: 'holiday_time',
    sortField: 'holiday_time',
    align: 'right',
  }, {
    id: 'midnight_time',
    text: '深夜',
    displayField: 'midnight_time',
    sortField: 'midnight_time',
    align: 'right',
  }],
}]

const standardListColumns = {
  attendance: [
    'date',
    'time',
    'working_time',
  ],
}

const listIcon: ListViewIcon = {
  warning: {
    name: 'triangle-exclamation',
    classes: [
      '--warning',
    ]
  },
  error: {
    name: 'circle-xmark',
    classes: [
      '--error',
    ]
  },
}
const listConverter: ListViewConverter = {
}

export default function attendanceDaily () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'date time_start_number',
    order: {
      'date time_start_number': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
