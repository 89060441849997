export const tableName = 'ロールグループ'

export default function roleGroups () {
  const columnLabel = new Map([
    [ 'id', 'ID' ],
    [ 'name', 'グループ名' ],
    [ 'displayOrder', '表示順' ],
  ])

  return {
    columnLabel,
  }
}
