export interface StateAppUser {
  id: number
  name: string
}
export interface StateAppUserBase {
  id: number
  name: string
}

export default {
  namespaced: true,
  state: () => ({
    user: {
      id: null,
      name: null,
    },
    userBase:{
      id: null,
      name: null,
    },
  }),
  mutations: {
    user (state: { user: StateAppUser }, value: StateAppUser) {
      state.user = value
    },
    userBase (state: { userBase: StateAppUserBase }, value: StateAppUserBase) {
      state.userBase = value
    },
  },
  getters: {
    user (state: { user: StateAppUser }) {
      return state.user
    },
    userBase (state: { userBase: StateAppUserBase }) {
      return state.userBase
    },
  },
  actions: {},
}
