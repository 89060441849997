export const tableName = '仕入'

export default function purchases () {
  const itemLabel = new Map([
    [ 'purchaseInformation', '発注情報' ],

    [ 'status', 'ステータス' ],
    [ 'id', '発注番号' ],

    [ 'issueDate', '発行日時' ], 
    [ 'issuer', '発行者' ], //id, name, kana

    [ 'orderDate', '発注日' ],
    [ 'orderer', '発注者' ], //id, name, kana
    
    //[ 'paymentDate', '支払予定日' ],

    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ], //id, name, kana


    [ 'supplierInformation', '発注先情報' ],

    [ 'supplier', '発注先' ],
    [ 'supplierPostcode', '発注先郵便番号' ],
    [ 'supplierAddress', '発注先住所' ],
    [ 'supplierQuotationNo', '見積番号' ],


    [ 'buyerInformation', '発注元情報' ],
    
    [ 'companyName', '社名' ],
    [ 'base', '拠点' ],
    [ 'basePostcode', '拠点郵便番号' ],
    [ 'baseAddress', '拠点住所' ],
    [ 'baseTel', '拠点TEL' ],
    [ 'baseFax', '拠点FAX' ],


    [ 'contractInformation', '案件情報' ],

    [ 'apportionment', '原価按分' ],
    [ 'apportionmentType', '按分対象' ],
    [ 'apportionmentId', '案件番号' ],
    [ 'apportionmentName', '案件名' ],
    //[ 'apportionmentRate', '按分率［％］' ],
    [ 'constructionPeriod', '工期' ],
    [ 'salesRepresentative', '営業担当者' ],
    [ 'siteAgent', '現場代理人' ],


    [ 'transactionInformation', '取引情報' ],

    [ 'deliveryDestination', '納入先' ],
    [ 'deliveryAddress', '納入先住所' ],
    [ 'deliveryDeadline', '納期' ],
    [ 'deliveryConditions', '引渡条件' ],
    [ 'paymentTerms', '支払条件' ],
    [ 'orderDetails', '発注内容' ],
    [ 'note', '備考' ],


    [ 'costInformation', '原価情報' ],

    [ 'preOrderCost', '受注前原価' ],

    [ 'amountIncludingTax', '税込金額［円］' ],
    [ 'amountExcludingTax', '税抜金額［円］' ],
    [ 'consumptionTaxAmount', '消費税額［円］' ],

    [ 'deliveredAmountIncludingTax', '税込仕入金額［円］' ],
    [ 'deliveredAmountExcludingTax', '税抜仕入金額［円］' ],

    [ 'amountBalanceIncludingTax', '税込発注残金額［円］' ],
    [ 'amountBalanceExcludingTax', '税抜発注残金額［円］' ],
  ])
  const searchLabel = new Map([
    [ 'id', '発注番号' ],

    [ 'issueDate', '発行日時' ], 
    [ 'issuer', '発行者' ], //id, name, kana
    
    [ 'orderDate', '発注日' ],
    [ 'orderer', '発注者' ],

    //[ 'paymentDate', '支払予定日' ], 

    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],


    [ 'supplier', '発注先' ],
    [ 'supplierAddress', '発注先住所' ],
    [ 'supplierQuotationNo', '見積番号' ],


    //[ 'base', '拠点' ],


    [ 'apportionmentId', '案件番号' ],
    [ 'apportionmentName', '案件名' ],
    [ 'constructionPeriod', '工期' ],
    [ 'salesRepresentative', '営業担当者' ],
    [ 'siteAgent', '現場代理人' ],

    [ 'deliveryDestination', '納入先' ],
    [ 'deliveryAddress', '納入先住所' ],
    [ 'deliveryDeadline', '納期' ],
    [ 'deliveryConditions', '引渡条件' ],
    [ 'paymentTerms', '支払条件' ],
    [ 'orderDetails', '発注内容' ],
    [ 'note', '備考' ],


    [ 'amountIncludingTax', '税込金額' ],
    [ 'amountExcludingTax', '税抜金額' ],
    [ 'consumptionTaxAmount', '消費税額' ],

    [ 'deliveredAmountIncludingTax', '税込仕入金額' ],
    [ 'deliveredAmountExcludingTax', '税抜仕入金額' ],

    [ 'amountBalanceIncludingTax', '税込発注残金額' ],
    [ 'amountBalanceExcludingTax', '税抜発注残金額' ],
  ])
  const columnLabel = new Map([
    [ 'purchaseInformation', '発注情報' ],

    [ 'status', 'ステータス' ],
    [ 'id', '発注番号' ],

    [ 'issueDate', '発行日時' ], 
    [ 'issuer', '発行者' ], //id, name, kana

    [ 'orderDate', '発注日' ],
    [ 'orderer', '発注者' ],

    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],


    [ 'supplierInformation', '発注先情報' ],

    [ 'supplier', '発注先' ],
    [ 'supplierAddress', '発注先住所' ],
    [ 'supplierQuotationNo', '見積番号' ],


    [ 'buyerInformation', '発注元情報' ],

    [ 'companyName', '社名' ],
    [ 'base', '拠点' ],
    [ 'basePostcode', '拠点郵便番号' ],
    [ 'baseAddress', '拠点住所' ],
    [ 'baseTel', '拠点TEL' ],
    [ 'baseFax', '拠点FAX' ],


    [ 'contractInformation', '案件情報' ],

    //[ 'apportionment', '原価按分' ],
    //[ 'apportionmentType', '按分対象' ],
    [ 'apportionmentId', '案件番号' ],
    [ 'apportionmentName', '案件名' ],
    //[ 'apportionmentRate', '按分率［％］' ],
    [ 'constructionPeriod', '工期' ],
    [ 'salesRepresentative', '営業担当者' ],
    [ 'siteAgent', '現場代理人' ],


    [ 'transactionInformation', '取引情報' ],
        
    [ 'deliveryDestination', '納入先' ],
    [ 'deliveryAddress', '納入先住所' ],
    [ 'deliveryDeadline', '納期' ],
    [ 'deliveryConditions', '引渡条件' ],
    [ 'paymentTerms', '支払条件' ],
    [ 'orderDetails', '発注内容' ],
    [ 'note', '備考' ],

    
    [ 'costInformation', '原価情報' ],

    [ 'preOrderCost', '受注前原価' ],

    [ 'amountIncludingTax', '税込金額［円］' ],
    [ 'amountExcludingTax', '税抜金額［円］' ],
    [ 'consumptionTaxAmount', '消費税額［円］' ],

    [ 'deliveredAmountIncludingTax', '税込仕入金額［円］' ],
    [ 'deliveredAmountExcludingTax', '税抜仕入金額［円］' ],

    [ 'amountBalanceIncludingTax', '税込発注残金額［円］' ],
    [ 'amountBalanceExcludingTax', '税抜発注残金額［円］' ],


    [ 'details', '明細' ],
  ])

  const status = new Map([
    [ '0', 'ドラフト' ],
    [ '1', '発注' ],
    [ '2', '分納' ],
    [ '3', '完納' ],
    [ '4', '締め処理完了' ],
    [ '10', '発注取消' ],
    [ '20', '発注書なし仕入' ],
  ])

  return {
    itemLabel,
    searchLabel,
    columnLabel,
    status,
  }
}
