export const tableName = '部門コード'

export default function journalDepartments () {
  const itemLabel = new Map([
    [ 'id', 'ID' ],
    [ 'base', '拠点' ],
    [ 'division', '部門' ],
    [ 'code', '仕訳部門コード' ],
    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])
  const searchLabel = new Map([
  ])
  const columnLabel = new Map([
    [ 'id', 'ID' ],
    [ 'base', '拠点' ],
    [ 'division', '部門' ],
    [ 'code', '仕訳部門コード' ],
    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])

  return {
    itemLabel,
    searchLabel,
    columnLabel,
  }
}
