import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import { PadViewItem } from '@/const/component/padView'
import ConstConstructionTypes from '@/const/transaction/master/constructionTypes'

export default function pad () {
  const constTable = ConstConstructionTypes()
  /*
  const publicPrivateWorksTypeItems = computed(() => {
    return Array.from(constTable.longPublicPrivateWorksType).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })
  const collectionMonthItems = computed(() => {
    return Array.from(constTable.collectionMonth).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })
  const taxRoundingItems = computed(() => {
    return Array.from(constTable.taxRounding).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })
  const amountRoundingItems = computed(() => {
    return Array.from(constTable.amountRounding).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })
  */
  const getPadItems = (readonly = true) => {
    const ro = readonly
    const rq = !readonly

    const items: PadViewItem[] = [{
      id: 'constructionType',
      groups: [{
        items: [{
          id: 'name',
          label: constTable.itemLabel.get('name') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }, {
          id: 'kana',
          label: constTable.itemLabel.get('kana') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }],
      }],
    }]

    return items
  }

  return {
    /*
    collectionMonthItems,
    publicPrivateWorksTypeItems,
    taxRoundingItems,
    amountRoundingItems,
    */
    getPadItems,
  }
}
