const overColor = {
  closed: 'rgba(0,0,0,0.7)'
}

export interface DbContract {
  id: string
  id_number: number
  name: string
  kana: string
  project_id: string | null
  project_id_number: number | null
  project_name: string | null
  project_kana: string | null
  construction_location: string
  overview: string
  contract_type: number
  contractor_type: number
  public_private_works_type: number
  contract_category: string
  construction_type: string
  destination_target_name: string
  destination_target_kana: string
  client_name: string
  client_kana: string
  billing_target_name: string
  billing_target_kana: string
  client_rep_name: string
  client_rep_kana: string
  sales_rep_name: string
  sales_rep_kana: string
  site_agent_name: string
  site_agent_kana: string
  construction_rep_name: string
  construction_rep_kana: string
  base_name: string
  base_kana: string
  estimate_id: string | null
  estimate_id_number: number | null
  orders_accuracy: string
  order_date: string
  complete_date: string | null
  contract_period_start_date: string
  contract_period_end_date: string
  lost_order_date: string
  lost_reason: string
  last_estimate_date: string
  last_send_order_date: string
  last_purchase_date: string
  last_invoice_issue_date: string
  target_marginal_profit_ratio: number
  marginal_profit_ratio: string
  target_marginal_profit: number
  marginal_profit: string
  predicted_contract_job_Amount: number
  contract_job_amount: string
  material_cost: number
  outsourcing_cost: number
  expense_cost: number
  labor_cost: number
  total_variable_cost: number
  budget: string
  budget_balance: string
  sales_amount: string
  accounts_receivable_amount: string
  unbilled_amount: string
  purchase_amount_balance: string
  amount_of_accounts_payable: string
  not_counted: boolean
  edit_lock: boolean
  process_status: string
  invoice_status: string
  updated_at: string
  updated_by_name: string
  updated_by_kana: string
  icon?: string 
  background?: string
  overColor?: string
}

export default function contract () {
  const contracts: DbContract[] = [{
    id: '1',
    id_number: 1,
    name: '案件１',
    kana: 'コウジ１',
    project_id: 'P1',
    project_id_number: 1,
    project_name: 'プロジェクト１',
    project_kana: 'アンケン１',
    construction_location: '施工先１',
    overview: '概要１',
    contract_type: 1,
    contractor_type: 1,
    public_private_works_type: 2,
    contract_category: 'LAN',
    construction_type: '電気工事',
    destination_target_name: '折衝先１',
    destination_target_kana: 'セッショウサキ１',
    client_name: '発注者１',
    client_kana: 'ハッチュウシャ１',
    billing_target_name: '請求先１',
    billing_target_kana: 'セイキュウサキ１',
    client_rep_name: '得意先担当者１',
    client_rep_kana: 'トクイサキタントウシャ１',
    sales_rep_name: '営業担当者１',
    sales_rep_kana: 'エイギョウタントウシャ１',
    site_agent_name: '現場代理人１',
    site_agent_kana: 'ゲンバダイリニン１',
    construction_rep_name: '施工担当者１',
    construction_rep_kana: 'セコウタントウシャ１',
    base_name: '郡山本社',
    base_kana: 'コオリヤマホンシャ',
    estimate_id: '1234',
    estimate_id_number: 1234,
    orders_accuracy: 'c',
    order_date: '2020-11-22',
    complete_date: null,
    contract_period_start_date: '2021-01-05',
    contract_period_end_date: '2021-12-25',
    lost_order_date: '',
    lost_reason: '',
    last_estimate_date: '',
    last_send_order_date: '',
    last_purchase_date: '',
    last_invoice_issue_date: '',
    target_marginal_profit_ratio: 50,
    marginal_profit_ratio: '',
    target_marginal_profit: 25000000,
    marginal_profit: '',
    predicted_contract_job_Amount: 50000000,
    contract_job_amount: '',
    material_cost: 100000,
    outsourcing_cost: 200000,
    expense_cost: 300000,
    labor_cost: 400000,
    total_variable_cost: 1000000,
    budget: '',
    budget_balance: '',
    sales_amount: '',
    accounts_receivable_amount: '',
    unbilled_amount: '',
    purchase_amount_balance: '',
    amount_of_accounts_payable: '',
    not_counted: false,
    edit_lock: false,
    process_status: '0',
    invoice_status: '0',
    updated_at: '2020-12-13',
    updated_by_name: '最終更新者１',
    updated_by_kana: 'サイシュウコウシンシャ１',
  }, {
    id: '2',
    id_number: 2,
    name: '案件２',
    kana: 'コウジ２',
    project_id: 'P1',
    project_id_number: 1,
    project_name: 'プロジェクト１',
    project_kana: 'アンケン１',
    construction_location: '施工先１',
    overview: '概要１',
    contract_type: 2,
    contractor_type: 2,
    public_private_works_type: 2,
    contract_category: 'TEL',
    construction_type: '通信工事',
    destination_target_name: '折衝先１',
    destination_target_kana: 'セッショウサキ１',
    client_name: '発注者１',
    client_kana: 'ハッチュウシャ１',
    billing_target_name: '請求先２',
    billing_target_kana: 'セイキュウサキ２',
    client_rep_name: '得意先担当者２',
    client_rep_kana: 'トクイサキタントウシャ２',
    sales_rep_name: '営業担当者１',
    sales_rep_kana: 'エイギョウタントウシャ１',
    site_agent_name: '現場代理人２',
    site_agent_kana: 'ゲンバダイリニン２',
    construction_rep_name: '施工担当者２',
    construction_rep_kana: 'セコウタントウシャ２',
    base_name: '東京オフィス',
    base_kana: 'トウキョウオフィス',
    estimate_id: null,
    estimate_id_number: null,
    orders_accuracy: 'b',
    order_date: '2021-12-26',
    complete_date: null,
    contract_period_start_date: '2021-01-05',
    contract_period_end_date: '2021-10-11',
    lost_order_date: '',
    lost_reason: '',
    last_estimate_date: '',
    last_send_order_date: '',
    last_purchase_date: '',
    last_invoice_issue_date: '',
    target_marginal_profit_ratio: 50,
    marginal_profit_ratio: '',
    target_marginal_profit: 8000000,
    marginal_profit: '',
    predicted_contract_job_Amount: 0,
    contract_job_amount: '',
    material_cost: 100000,
    outsourcing_cost: 200000,
    expense_cost: 300000,
    labor_cost: 400000,
    total_variable_cost: 1000000,
    budget: '',
    budget_balance: '',
    sales_amount: '',
    accounts_receivable_amount: '',
    unbilled_amount: '',
    purchase_amount_balance: '',
    amount_of_accounts_payable: '',
    not_counted: true,
    edit_lock: false,
    process_status: '0',
    invoice_status: '0',
    updated_at: '2020-12-14',
    updated_by_name: '最終更新者１',
    updated_by_kana: 'サイシュウコウシンシャ１',
    icon: 'caution',
  }, {
    id: '3',
    id_number: 3,
    name: '案件３',
    kana: 'コウジ３',
    project_id: null,
    project_id_number: null,
    project_name: null,
    project_kana: null,
    construction_location: '施工先３',
    overview: '概要３',
    contract_type: 3,
    contractor_type: 1,
    public_private_works_type: 1,
    contract_category: 'LAN',
    construction_type: '消防施設工事',
    destination_target_name: '折衝先３',
    destination_target_kana: 'セッショウサキ３',
    client_name: '発注者３',
    client_kana: 'ハッチュウシャ３',
    billing_target_name: '請求先３',
    billing_target_kana: 'セイキュウサキ３',
    client_rep_name: '得意先担当者３',
    client_rep_kana: 'トクイサキタントウシャ３',
    sales_rep_name: '営業担当者３',
    sales_rep_kana: 'エイギョウタントウシャ３',
    site_agent_name: '現場代理人３',
    site_agent_kana: 'ゲンバダイリニン３',
    construction_rep_name: '施工担当者３',
    construction_rep_kana: 'セコウタントウシャ３',
    base_name: '東京オフィス',
    base_kana: 'トウキョウオフィス',
    estimate_id: '2345',
    estimate_id_number: 2345,
    orders_accuracy: 'a',
    order_date: '2020-04-26',
    complete_date: null,
    contract_period_start_date: '2021-01-05',
    contract_period_end_date: '2021-12-25',
    lost_order_date: '',
    lost_reason: '',
    last_estimate_date: '',
    last_send_order_date: '',
    last_purchase_date: '',
    last_invoice_issue_date: '',
    target_marginal_profit_ratio: 50,
    marginal_profit_ratio: '',
    target_marginal_profit: 25000000,
    marginal_profit: '',
    predicted_contract_job_Amount: 0,
    contract_job_amount: '',
    material_cost: 100000,
    outsourcing_cost: 200000,
    expense_cost: 300000,
    labor_cost: 400000,
    total_variable_cost: 1000000,
    budget: '',
    budget_balance: '',
    sales_amount: '',
    accounts_receivable_amount: '',
    unbilled_amount: '',
    purchase_amount_balance: '',
    amount_of_accounts_payable: '',
    not_counted: false,
    edit_lock: true,
    process_status: '1',
    invoice_status: '0',
    updated_at: '2020-12-13',
    updated_by_name: '最終更新者１',
    updated_by_kana: 'サイシュウコウシンシャ１',
  }, {
    id: '4',
    id_number: 4,
    name: '案件４',
    kana: 'コウジ４',
    project_id: null,
    project_id_number: null,
    project_name: null,
    project_kana: null,
    construction_location: '施工先４',
    overview: '概要４',
    contract_type: 4,
    contractor_type: 2,
    public_private_works_type: 2,
    contract_category: 'LAN',
    construction_type: '消防施設工事',
    destination_target_name: '折衝先４',
    destination_target_kana: 'セッショウサキ４',
    client_name: '発注者１',
    client_kana: 'ハッチュウシャ１',
    billing_target_name: '請求先１',
    billing_target_kana: 'セイキュウサキ１',
    client_rep_name: '得意先担当者１',
    client_rep_kana: 'トクイサキタントウシャ１',
    sales_rep_name: '営業担当者４',
    sales_rep_kana: 'エイギョウタントウシャ４',
    site_agent_name: '現場代理人４',
    site_agent_kana: 'ゲンバダイリニン４',
    construction_rep_name: '施工担当者３',
    construction_rep_kana: 'セコウタントウシャ３',
    base_name: '郡山本社',
    base_kana: 'コオリヤマホンシャ',
    estimate_id: '3456',
    estimate_id_number: 3456,
    orders_accuracy: 'c',
    order_date: '2020-02-20',
    complete_date: null,
    contract_period_start_date: '2021-01-05',
    contract_period_end_date: '2021-12-25',
    lost_order_date: '',
    lost_reason: '',
    last_estimate_date: '',
    last_send_order_date: '',
    last_purchase_date: '',
    last_invoice_issue_date: '',
    target_marginal_profit_ratio: 50,
    marginal_profit_ratio: '',
    target_marginal_profit: 25000000,
    marginal_profit: '',
    predicted_contract_job_Amount: 0,
    contract_job_amount: '',
    material_cost: 100000,
    outsourcing_cost: 200000,
    expense_cost: 300000,
    labor_cost: 400000,
    total_variable_cost: 1000000,
    budget: '',
    budget_balance: '',
    sales_amount: '',
    accounts_receivable_amount: '',
    unbilled_amount: '',
    purchase_amount_balance: '',
    amount_of_accounts_payable: '',
    not_counted: true,
    edit_lock: true,
    process_status: '3',
    invoice_status: '0',
    updated_at: '2020-12-13',
    updated_by_name: '最終更新者１',
    updated_by_kana: 'サイシュウコウシンシャ１',
    icon: 'caution',
    //background: 'gray',
    overColor: overColor.closed,
  }, {
    id: '5',
    id_number: 5,
    name: '案件５',
    kana: 'コウジ５',
    project_id: 'P1',
    project_id_number: 1,
    project_name: 'プロジェクト１',
    project_kana: 'アンケン１',
    construction_location: '施工先５',
    overview: '概要５',
    contract_type: 5,
    contractor_type: 2,
    public_private_works_type: 1,
    contract_category: 'TEL',
    construction_type: '消防施設工事',
    destination_target_name: '折衝先５',
    destination_target_kana: 'セッショウサキ５',
    client_name: '発注者５',
    client_kana: 'ハッチュウシャ５',
    billing_target_name: '請求先５',
    billing_target_kana: 'セイキュウサキ５',
    client_rep_name: '得意先担当者１',
    client_rep_kana: 'トクイサキタントウシャ１',
    sales_rep_name: '営業担当者５',
    sales_rep_kana: 'エイギョウタントウシャ５',
    site_agent_name: '現場代理人１',
    site_agent_kana: 'ゲンバダイリニン１',
    construction_rep_name: '施工担当者１',
    construction_rep_kana: 'セコウタントウシャ１',
    base_name: '郡山本社',
    base_kana: 'コオリヤマホンシャ',
    estimate_id: null,
    estimate_id_number: null,
    orders_accuracy: 'b',
    order_date: '2020-12-24',
    complete_date: null,
    contract_period_start_date: '2021-01-05',
    contract_period_end_date: '2021-12-25',
    lost_order_date: '',
    lost_reason: '',
    last_estimate_date: '',
    last_send_order_date: '',
    last_purchase_date: '',
    last_invoice_issue_date: '',
    target_marginal_profit_ratio: 50,
    marginal_profit_ratio: '',
    target_marginal_profit: 25000000,
    marginal_profit: '',
    predicted_contract_job_Amount: 0,
    contract_job_amount: '',
    material_cost: 110000,
    outsourcing_cost: 220000,
    expense_cost: 330000,
    labor_cost: 440000,
    total_variable_cost: 1100000,
    budget: '',
    budget_balance: '',
    sales_amount: '',
    accounts_receivable_amount: '',
    unbilled_amount: '',
    purchase_amount_balance: '',
    amount_of_accounts_payable: '',
    not_counted: false,
    edit_lock: false,
    process_status: '2',
    invoice_status: '0',
    updated_at: '2020-12-13',
    updated_by_name: '最終更新者１',
    updated_by_kana: 'サイシュウコウシンシャ１',
    //background: 'gray',
    //overColor: overColor.closed,
  }, {
    id: '6',
    id_number: 6,
    name: '案件６',
    kana: 'コウジ６',
    project_id: null,
    project_id_number: null,
    project_name: null,
    project_kana: null,
    construction_location: '施工先６',
    overview: '概要６',
    contract_type: 1,
    contractor_type: 1,
    public_private_works_type: 2,
    contract_category: 'TEL',
    construction_type: '通信工事',
    destination_target_name: '折衝先６',
    destination_target_kana: 'セッショウサキ６',
    client_name: '発注者１',
    client_kana: 'ハッチュウシャ１',
    billing_target_name: '請求先６',
    billing_target_kana: 'セイキュウサキ６',
    client_rep_name: '得意先担当者１',
    client_rep_kana: 'トクイサキタントウシャ１',
    sales_rep_name: '営業担当者６',
    sales_rep_kana: 'エイギョウタントウシャ６',
    site_agent_name: '現場代理人６',
    site_agent_kana: 'ゲンバダイリニン６',
    construction_rep_name: '施工担当者３',
    construction_rep_kana: 'セコウタントウシャ３',
    base_name: '郡山本社',
    base_kana: 'コオリヤマホンシャ',
    estimate_id: '8453',
    estimate_id_number: 8453,
    orders_accuracy: 'a',
    order_date: '2021-01-12',
    complete_date: null,
    contract_period_start_date: '2021-01-05',
    contract_period_end_date: '2021-12-25',
    lost_order_date: '2021-08-15',
    lost_reason: 'コスト',
    last_estimate_date: '',
    last_send_order_date: '',
    last_purchase_date: '',
    last_invoice_issue_date: '',
    target_marginal_profit_ratio: 50,
    marginal_profit_ratio: '',
    target_marginal_profit: 25000000,
    marginal_profit: '',
    predicted_contract_job_Amount: 0,
    contract_job_amount: '',
    material_cost: 100000,
    outsourcing_cost: 200000,
    expense_cost: 300000,
    labor_cost: 400000,
    total_variable_cost: 1000000,
    budget: '',
    budget_balance: '',
    sales_amount: '',
    accounts_receivable_amount: '',
    unbilled_amount: '',
    purchase_amount_balance: '',
    amount_of_accounts_payable: '',
    not_counted: false,
    edit_lock: true,
    process_status: '10',
    invoice_status: '0',
    updated_at: '2020-12-13',
    updated_by_name: '最終更新者１',
    updated_by_kana: 'サイシュウコウシンシャ１',
    overColor: overColor.closed,
  }, {
    id: '7',
    id_number: 7,
    name: '案件７',
    kana: 'コウジ７',
    project_id: 'P2',
    project_id_number: 2,
    project_name: 'プロジェクト２',
    project_kana: 'アンケン２',
    construction_location: '施工先１',
    overview: '概要１',
    contract_type: 2,
    contractor_type: 1,
    public_private_works_type: 2,
    contract_category: 'TEL',
    construction_type: '通信工事',
    destination_target_name: '折衝先１',
    destination_target_kana: 'セッショウサキ１',
    client_name: '発注者７',
    client_kana: 'ハッチュウシャ７',
    billing_target_name: '請求先７',
    billing_target_kana: 'セイキュウサキ７',
    client_rep_name: '得意先担当者１',
    client_rep_kana: 'トクイサキタントウシャ１',
    sales_rep_name: '営業担当者１',
    sales_rep_kana: 'エイギョウタントウシャ１',
    site_agent_name: '現場代理人７',
    site_agent_kana: 'ゲンバダイリニン７',
    construction_rep_name: '施工担当者１',
    construction_rep_kana: 'セコウタントウシャ１',
    base_name: '仙台オフィス',
    base_kana: 'センダイオフィス',
    estimate_id: null,
    estimate_id_number: null,
    orders_accuracy: 'c',
    order_date: '2021-12-25',
    complete_date: null,
    contract_period_start_date: '2021-01-05',
    contract_period_end_date: '2021-12-25',
    lost_order_date: '',
    lost_reason: '',
    last_estimate_date: '',
    last_send_order_date: '',
    last_purchase_date: '',
    last_invoice_issue_date: '',
    target_marginal_profit_ratio: 50,
    marginal_profit_ratio: '',
    target_marginal_profit: 25000000,
    marginal_profit: '',
    predicted_contract_job_Amount: 0,
    contract_job_amount: '',
    material_cost: 100000,
    outsourcing_cost: 200000,
    expense_cost: 300000,
    labor_cost: 400000,
    total_variable_cost: 1000000,
    budget: '',
    budget_balance: '',
    sales_amount: '',
    accounts_receivable_amount: '',
    unbilled_amount: '',
    purchase_amount_balance: '',
    amount_of_accounts_payable: '',
    not_counted: true,
    edit_lock: true,
    process_status: '2',
    invoice_status: '2',
    updated_at: '2020-12-13',
    updated_by_name: '最終更新者１',
    updated_by_kana: 'サイシュウコウシンシャ１',
  }, {
    id: '8',
    id_number: 8,
    name: '案件８',
    kana: 'コウジ８',
    project_id: 'P3',
    project_id_number: 3,
    project_name: 'プロジェクト３',
    project_kana: 'アンケン３',
    construction_location: '施工先１',
    overview: '概要１',
    contract_type: 3,
    contractor_type: 1,
    public_private_works_type: 1,
    contract_category: 'LAN',
    construction_type: '電気工事',
    destination_target_name: '折衝先８',
    destination_target_kana: 'セッショウサキ８',
    client_name: '発注者８',
    client_kana: 'ハッチュウシャ８',
    billing_target_name: '請求先１',
    billing_target_kana: 'セイキュウサキ１',
    client_rep_name: '得意先担当者８',
    client_rep_kana: 'トクイサキタントウシャ８',
    sales_rep_name: '営業担当者１',
    sales_rep_kana: 'エイギョウタントウシャ１',
    site_agent_name: '現場代理人８',
    site_agent_kana: 'ゲンバダイリニン８',
    construction_rep_name: '施工担当者２',
    construction_rep_kana: 'セコウタントウシャ２',
    base_name: '仙台オフィス',
    base_kana: 'センダイオフィス',
    estimate_id: '8521',
    estimate_id_number: 8521,
    orders_accuracy: 'a',
    order_date: '2022-12-25',
    complete_date: null,
    contract_period_start_date: '2022-11-25',
    contract_period_end_date: '2021-12-25',
    lost_order_date: '',
    lost_reason: '',
    last_estimate_date: '',
    last_send_order_date: '',
    last_purchase_date: '',
    last_invoice_issue_date: '',
    target_marginal_profit_ratio: 50,
    marginal_profit_ratio: '',
    target_marginal_profit: 25000000,
    marginal_profit: '',
    predicted_contract_job_Amount: 0,
    contract_job_amount: '',
    material_cost: 100000,
    outsourcing_cost: 200000,
    expense_cost: 300000,
    labor_cost: 400000,
    total_variable_cost: 1000000,
    budget: '',
    budget_balance: '',
    sales_amount: '',
    accounts_receivable_amount: '',
    unbilled_amount: '',
    purchase_amount_balance: '',
    amount_of_accounts_payable: '',
    not_counted: true,
    edit_lock: false,
    process_status: '2',
    invoice_status: '0',
    updated_at: '2020-12-13',
    updated_by_name: '最終更新者１',
    updated_by_kana: 'サイシュウコウシンシャ１',
  }, {
    id: '9',
    id_number: 9,
    name: '案件９',
    kana: 'コウジ９',
    project_id: null,
    project_id_number: null,
    project_name: null,
    project_kana: null,
    construction_location: '施工先１',
    overview: '概要１',
    contract_type: 4,
    contractor_type: 2,
    public_private_works_type: 1,
    contract_category: 'LAN',
    construction_type: '通信工事',
    destination_target_name: '折衝先１',
    destination_target_kana: 'セッショウサキ１',
    client_name: '発注者９',
    client_kana: 'ハッチュウシャ９',
    billing_target_name: '請求先１',
    billing_target_kana: 'セイキュウサキ１',
    client_rep_name: '得意先担当者９',
    client_rep_kana: 'トクイサキタントウシャ９',
    sales_rep_name: '営業担当者１',
    sales_rep_kana: 'エイギョウタントウシャ１',
    site_agent_name: '現場代理人９',
    site_agent_kana: 'ゲンバダイリニン９',
    construction_rep_name: '施工担当者３',
    construction_rep_kana: 'セコウタントウシャ３',
    base_name: '郡山本社',
    base_kana: 'コオリヤマホンシャ',
    estimate_id: null,
    estimate_id_number: null,
    orders_accuracy: 'a',
    order_date: '2023-01-25',
    complete_date: null,
    contract_period_start_date: '2021-02-15',
    contract_period_end_date: '2021-12-25',
    lost_order_date: '',
    lost_reason: '',
    last_estimate_date: '',
    last_send_order_date: '',
    last_purchase_date: '',
    last_invoice_issue_date: '',
    target_marginal_profit_ratio: 50,
    marginal_profit_ratio: '',
    target_marginal_profit: 25000000,
    marginal_profit: '',
    predicted_contract_job_Amount: 0,
    contract_job_amount: '',
    material_cost: 130000,
    outsourcing_cost: 240000,
    expense_cost: 310000,
    labor_cost: 400000,
    total_variable_cost: 1080000,
    budget: '',
    budget_balance: '',
    sales_amount: '',
    accounts_receivable_amount: '',
    unbilled_amount: '',
    purchase_amount_balance: '',
    amount_of_accounts_payable: '',
    not_counted: false,
    edit_lock: false,
    process_status: '3',
    invoice_status: '1',
    updated_at: '2020-12-13',
    updated_by_name: '最終更新者１',
    updated_by_kana: 'サイシュウコウシンシャ１',
    //background: 'gray',
    overColor: overColor.closed,
  }, {
    id: '10',
    id_number: 10,
    name: '案件１０',
    kana: 'コウジ１０',
    project_id: 'P2',
    project_id_number: 2,
    project_name: 'プロジェクト２',
    project_kana: 'アンケン２',
    construction_location: '施工先１',
    overview: '概要１',
    contract_type: 5,
    contractor_type: 1,
    public_private_works_type: 2,
    contract_category: 'LAN',
    construction_type: '電気工事',
    destination_target_name: '折衝先１',
    destination_target_kana: 'セッショウサキ１',
    client_name: '発注者９',
    client_kana: 'ハッチュウシャ９',
    billing_target_name: '請求先１',
    billing_target_kana: 'セイキュウサキ１',
    client_rep_name: '得意先担当者１０',
    client_rep_kana: 'トクイサキタントウシャ１０',
    sales_rep_name: '営業担当者１',
    sales_rep_kana: 'エイギョウタントウシャ１',
    site_agent_name: '現場代理人３',
    site_agent_kana: 'ゲンバダイリニン３',
    construction_rep_name: '施工担当者２',
    construction_rep_kana: 'セコウタントウシャ２',
    base_name: '仙台オフィス',
    base_kana: 'センダイオフィス',
    estimate_id: '5486',
    estimate_id_number: 5486,
    orders_accuracy: 'b',
    order_date: '2023-02-05',
    complete_date: '2023-12-23',
    contract_period_start_date: '2021-02-05',
    contract_period_end_date: '2021-12-25',
    lost_order_date: '',
    lost_reason: '',
    last_estimate_date: '',
    last_send_order_date: '',
    last_purchase_date: '',
    last_invoice_issue_date: '',
    target_marginal_profit_ratio: 50,
    marginal_profit_ratio: '',
    target_marginal_profit: 25000000,
    marginal_profit: '',
    predicted_contract_job_Amount: 0,
    contract_job_amount: '',
    material_cost: 100000,
    outsourcing_cost: 250000,
    expense_cost: 340000,
    labor_cost: 400000,
    total_variable_cost: 1090000,
    budget: '',
    budget_balance: '',
    sales_amount: '',
    accounts_receivable_amount: '',
    unbilled_amount: '',
    purchase_amount_balance: '',
    amount_of_accounts_payable: '',
    not_counted: false,
    edit_lock: false,
    process_status: '3',
    invoice_status: '2',
    updated_at: '2020-12-13',
    updated_by_name: '最終更新者１',
    updated_by_kana: 'サイシュウコウシンシャ１',
    //background: 'gray',
    overColor: overColor.closed,
  }]

  return {
    contracts,
  }
}
