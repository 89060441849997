export const tableName = '業者'

export default function suppliers () {
  const itemLabel = new Map([
    [ 'id', 'ID' ],
    [ 'code', '業者コード' ],
    [ 'name', '業者名' ],
    [ 'kana', '業者名（カナ）' ],
    [ 'official_company_name', '正式社名' ],
    [ 'official_branch_name', '正式支店名' ],
    [ 'honorific_title', '敬称' ],
    [ 'postcode', '郵便番号' ],
    [ 'address', '住所' ],
    [ 'closing_date', '締め日' ],  // 1～31
    [ 'payment_month', '支払月' ], // 0:当月／1:翌月／2:翌々月
    [ 'payment_date', '支払日' ],  // 1～31

    [ 'default_value', '初期値' ],
    [ 'expense_type', '経費種別' ],
    [ 'tax_rounding', '消費税端数処理' ],
    [ 'amount_rounding', '金額端数処理' ],

    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])
  const searchLabel = new Map([
    [ 'code', '業者コード' ],
    [ 'name', '業者名' ],
    [ 'official_company_name', '正式社名' ],
    [ 'official_branch_name', '正式支店名' ],
    [ 'address', '住所' ],
  ])
  const columnLabel = new Map([
    [ 'id', 'ID' ],
    [ 'code', 'コード' ],
    [ 'name', '業者名' ],
    [ 'official_company_name', '正式社名' ],
    [ 'official_branch_name', '正式支店名' ],
    [ 'honorific_title', '敬称' ],
    [ 'postcode', '郵便番号' ],
    [ 'address', '住所' ],
    [ 'closing_date', '締め日' ],  // 1～31
    [ 'payment_month', '支払月' ], // 0:当月／1:翌月／2:翌々月
    [ 'payment_date', '支払日' ],  // 1～31

    [ 'default_value', '初期値' ],
    [ 'tax_rounding', '消費税' ],
    [ 'amount_rounding', '金額' ],
    [ 'expense_type', '経費種別' ],
    
    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])

  const collectionMonth = new Map([
    [ '0', '当月' ],
    [ '1', '翌月' ],
    [ '2', '翌々月' ],
  ])
  const longPublicPrivateWorksType = new Map([
    [ '0', '公共' ],
    [ '1', '民間' ],
  ])
  const taxRounding = new Map([
    [ '1', '切り捨て' ],
    [ '2', '四捨五入' ],
    [ '3', '切り上げ' ],
  ])
  const amountRounding = new Map([
    [ '1', '切り捨て' ],
    [ '2', '四捨五入' ],
    [ '3', '切り上げ' ],
  ])
  const expenseType = new Map([
    [ 'material', '材料費' ],
    [ 'outsource', '外注費' ],
    [ 'expenses', '経費' ],
    [ 'others', 'その他' ],
  ])

  return {
    itemLabel,
    searchLabel,
    columnLabel,
    collectionMonth,
    longPublicPrivateWorksType,
    taxRounding,
    amountRounding,
    expenseType,
  }
}
