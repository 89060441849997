export default function constant () {
  const cost = {
    material: {
      name: '材料費',
      shortName: '材',
      longName: '材料費',
      color: '#0000CC',
    },
    outsource: {
      name: '外注費',
      shortName: '外',
      longName: '外注費',
      color: '#00BB00',
    },
    expenses: {
      name: '経費',
      shortName: '経',
      longName: '経費',
      color: '#BBAA00',
    },
    others: {
      name: 'その他',
      shortName: '他',
      longName: 'その他の変動費',
      color: '#FF7700',
    },
    labor: {
      name: '労務費',
      shortName: '労',
      longName: '労務費',
      color: '#00AADD',
    },
  }
  const profit = {
    marginal: {
      name: '限界利益',
      shortName: '限',
      longName: '限界利益',
      color: '#AA0099',
    },
    marginalRate: {
      name: '限界利益率',
      shortName: '率',
      longName: '限界利益率',
      color: '#EE00DD',
    },
    gross: {
      name: '粗利益',
      shortName: '粗',
      longName: '粗利益',
      color: '#6600FF',
    },
    productivity: {
      name: '生産性',
      shortName: '生',
      longName: '生産性',
      color: '#6600FF',
    },
  }
  const color = {
    a: '#009900',
    b: '#DD8800',
    c: '#DD2244',
    d: '#FF9900',
  }
  const contract = {
    construction: {
      name: '工事',
      shortName: '工',
      longName: '工事',
      color: color.a,
    },
    productSales: {
      name: '物販',
      shortName: '物',
      longName: '物販',
      color: color.b,
    },
    hindrance: {
      name: '障害',
      shortName: '障',
      longName: '障害',
      color: color.b,
    },
    maintenance: {
      name: '保守',
      shortName: '保',
      longName: '保守',
      color: color.b,
    },
    service: {
      name: '役務',
      shortName: '役',
      longName: '役務',
      color: color.b,
    },
  }
  const contractor = {
    prime: {
      name: '元請',
      shortName: '元',
      longName: '元請',
      color: color.a,
    },
    sub: {
      name: '下請',
      shortName: '下',
      longName: '下請',
      color: color.b,
    },
  }
  const publicPrivateWorks = {
    public: {
      name: '公共',
      shortName: '公',
      longName: '公共',
      color: color.a,
    },
    private: {
      name: '民間',
      shortName: '民',
      longName: '民間',
      color: color.b,
    },
  }
  const ordersAccuracy = {
    unofficialNotice: {
      name: '内示',
      shortName: '内',
      longName: '内示（100%）',
      color: '#000099',
    },
    lebelA: {
      name: 'A',
      shortName: 'A',
      longName: 'A（70%）',
      color: '#009999',
    },
    lebelB: {
      name: 'B',
      shortName: 'B',
      longName: 'B（50%）',
      color: '#999900',
    },
    lebelC: {
      name: 'C',
      shortName: 'C',
      longName: 'C（30%）',
      color: '#990000',
    },
    lessThan: {
      name: '500以上',
      shortName: '500',
      longName: '500以上',
      color: '#990044',
    },
  }
  const processStatus = {
    inquiry: {
      name: '引合',
      shortName: '引',
      longName: '引合中',
      color: '#EE8800',
    },
    estimated: {
      name: '見積',
      shortName: '見',
      longName: '見積済み',
      color: '#AA9900',
    },
    ordered: {
      name: '受注',
      shortName: '受',
      longName: '受注済み',
      color: '#008800',
    },
    completed: {
      name: '完成',
      shortName: '完',
      longName: '完成済み',
      color: '#00AAFF',
    },
    lost: {
      name: '失注',
      shortName: '失',
      longName: '失注',
      color: '#888888',
    },
  }
  const invoiceStatus = {
    yet: {
      name: '未作成',
      shortName: '未',
      longName: '未作成',
      color: color.c,
    },
    partially: {
      name: '一部作成済み',
      shortName: '一部',
      longName: '一部作成済み',
      color: color.d,
    },
    done: {
      name: '作成済み',
      shortName: '済',
      longName: '作成済み',
      color: color.a,
    },
  }
  const consumptionTax: {
    label: {
      [ key: string ]: string
    }
    type: {
      item: {
        [ key: string ]: {
          name: {
            [ key: string ]: string
          }
          color: string
        }
      }
      orderedItems: string[]
    }
  } = {
    label: {
      edit: '消費税率',
      list: '消費税率',
    },
    type: {
      item: {
        include: {
          name: {
            edit: '内税',
            list: '内税',
          },
          color: color.a,
        },
        exclude: {
          name: {
            edit: '外税',
            list: '外税',
          },
          color: color.a,
        },
        none: {
          name: {
            edit: '税無し',
            list: '無税',
          },
          color: color.b,
        },
      },
      orderedItems: [
        'include',
        'exclude',
        'none',
      ],
    },
  }
  const depositType = {
    transfer: {
      name: '振込',
      shortName: '振込',
      longName: '振込',
      color: '#0000CC',
    },
    cash: {
      name: '現金',
      shortName: '現金',
      longName: '現金',
      color: '#00BB00',
    },
    bill: {
      name: '手形',
      shortName: '手形',
      longName: '手形',
      color: '#BBAA00',
    },
    commission: {
      name: '手数料',
      shortName: '手数料',
      longName: '手数料',
      color: '#FF7700',
    },
    discount: {
      name: '値引',
      shortName: '値引',
      longName: '値引',
      color: '#00AADD',
    },
    offset: {
      name: '相殺',
      shortName: '相殺',
      longName: '相殺',
      color: '#9900DD',
    },
    membership: {
      name: '協力会費',
      shortName: '協力会費',
      longName: '協力会費',
      color: '#559922',
    },
    others: {
      name: 'その他',
      shortName: 'その他',
      longName: 'その他',
      color: '#99AA55',
    },
  }

  const consumptionTaxLabel = (type = 'edit') => {
    return consumptionTax.label[type]
  }
  const consumptionTaxTypeItems = (type = 'edit') => {
    return consumptionTax.type.orderedItems.map(e => {
      //console.log('consumptionTaxTypeItems e', e)
      //console.log('label', consumptionTax.type.item[e].name[type])
      return {
        value: e,
        label: consumptionTax.type.item[e].name[type],
      }
    })
  }

  return {
    cost,
    profit,
    contract,
    contractor,
    publicPrivateWorks,
    ordersAccuracy,
    processStatus,
    invoiceStatus,
    consumptionTax,
    depositType,
    consumptionTaxLabel,
    consumptionTaxTypeItems,
  }
}