export interface DbApproval {
  id: string
  id_number: number
  revision: string | null
  revision_number: number | null
  //draft_date: string
  drafter_name: string
  drafter_kana: string
  circulation_date_time: string
  result_date_time: string | null
  contract_id: string
  contract_id_number: number
  contract_name: string
  contract_kana: string
  document_type: string
  document_type_number: number
  document_id: string
  document_id_number: number
  route_id: string
  route_name: string
  approver_name: string | null
  approver_kana: string | null
  status: string
  status_number: number

  expanded?: boolean
  expansionItems?: DbApproval[]
}

export default function approvals () {
  const approvals: Array<DbApproval> = [{
    id: '1234',
    id_number: 1234,
    revision: '2',
    revision_number: 2,
    drafter_name: '起案者Ａ',
    drafter_kana: 'キアンシャA',
    circulation_date_time: '2023-02-01 08:30:25',
    result_date_time: '2023-02-11 17:02:30',
    contract_id: '1',
    contract_id_number: 1,
    contract_name: '案件Ａ',
    contract_kana: 'アンケンA',
    document_type: '見積書',
    document_type_number: 2,
    document_id: '19608-10',
    document_id_number: 19608,
    route_id: '6',
    route_name: '社長決裁',
    approver_name: '社長Ｃ',
    approver_kana: 'シャチョウC',
    status: 'rejection',
    status_number: 21,

    expanded: false,
    expansionItems: [{
      id: '1234',
      id_number: 1234,
      revision: '1',
      revision_number: 1,
      drafter_name: '起案者Ａ',
      drafter_kana: 'キアンシャA',
      circulation_date_time: '2023-01-21 11:11:22',
      result_date_time: '2023-01-30 12:50:12',
      contract_id: '1',
      contract_id_number: 1,
      contract_name: '案件Ａ',
      contract_kana: 'アンケンA',
      document_type: '見積書',
      document_type_number: 2,
      document_id: '19608-9',
      document_id_number: 19608,
      route_id: '6',
      route_name: '社長決裁',
      approver_name: '課長Ａ',
      approver_kana: 'カチョウA',
      status: 'sendBack',
      status_number: 10,
    }, {
      id: '1234',
      id_number: 1234,
      revision: '',
      revision_number: 0,
      drafter_name: '起案者Ｂ',
      drafter_kana: 'キアンシャB',
      circulation_date_time: '2023-01-10 12:12:12',
      result_date_time: null,
      contract_id: '1',
      contract_id_number: 1,
      contract_name: '案件Ａ',
      contract_kana: 'アンケンA',
      document_type: '見積書',
      document_type_number: 2,
      document_id: '19608-8',
      document_id_number: 19608,
      route_id: '6',
      route_name: '社長決裁',
      approver_name: '部長Ｂ',
      approver_kana: 'ブチョウB',
      status: 'sendBack',
      status_number: 10,
    }],
  }, {
    id: '1235',
    id_number: 1235,
    revision: '',
    revision_number: 0,
    drafter_name: '起案者Ａ',
    drafter_kana: 'キアンシャA',
    circulation_date_time: '2023-03-10 23:21:41',
    result_date_time: '2023-04-11 20:25:54',
    contract_id: '2',
    contract_id_number: 2,
    contract_name: '案件Ｂ',
    contract_kana: 'アンケンB',
    document_type: '実行予算計画書',
    document_type_number: 1,
    document_id: '22234',
    document_id_number: 22234,
    route_id: '5',
    route_name: '部長決裁',
    approver_name: '部長Ｂ',
    approver_kana: 'ブチョウB',
    status: 'approval',
    status_number: 20,
  }, {
    id: '1236',
    id_number: 1236,
    revision: '',
    revision_number: 0,
    drafter_name: '起案者Ａ',
    drafter_kana: 'キアンシャA',
    circulation_date_time: '2023-05-01 14:01:02',
    result_date_time: null,
    contract_id: '3',
    contract_id_number: 3,
    contract_name: '案件Ｃ',
    contract_kana: 'アンケンC',
    document_type: '施工報告書',
    document_type_number: 3,
    document_id: '24',
    document_id_number: 24,
    route_id: '4',
    route_name: '課長決裁',
    approver_name: '課長Ａ',
    approver_kana: 'カチョウA',
    status: 'circulation',
    status_number: 1,
  }]

  return {
    approvals,
  }
}
