export interface ValidateItem {
  lengthEqual?: number,
  lengthMore?: number,
  lengthMoreLess?: Array<number>,
  lengthLess?: number,
  numberLength?: number,
  numberMin?: number,
  numberMax?: number,
  numberMinMax?: Array<number>,
}

export default function validation () {
  const regKana = new RegExp(/^[ァ-ヺー０-９　]*$/)
  const regNumber = new RegExp(/^[0-9]*$/)
  const regInteger = new RegExp(/^[-]?([1-9]\d*|0)$/)
  const regDecimal = new RegExp(/^[-]?([1-9]\d*|0)(\.(\d+)?)?$/)
  const regPostalCode = new RegExp(/^[0-9]{3}-[0-9]{4}$/)
  const regTelephone = new RegExp(/^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/)
  const msgRequired = '入力してください'
  const msgRequiredSelect = '選択してください'
  const msgKana = '全角カナ、数字、スペースで入力してください'
  const msgNumber = '半角数字で入力してください'
  const msgInteger = '半角で整数を入力してください'
  const msgDecimal = '半角で数値を入力してください'
  const msgPostalCode = '000-0000 の形式で入力してください'
  const msgTelephone = '半角数字とハイフンで入力してください'
  const msgLengthEqual = (val: number) => `${val}文字で入力してください`
  const msgLengthMore = (val: number) => `${val}文字以上で入力してください`
  const msgLengthLess = (val: number) => `${val}文字以下で入力してください`
  const msgLengthMoreLess = (more: number, less: number) => `${more}文字以上 ${less}文字以下で入力してください`
  const msgNumberLength = (val: number) => `${val}桁の数値を入力してください`
  const msgNumberMin = (val: number) => `${val}以上の数値を入力してください`
  const msgNumberMax = (val: number) => `${val}以下の数値を入力してください`
  const msgNumberMinMax = (min: number, max: number) => `${min}以上 ${max}以下の数値を入力してください`
  const msgDecimalPosition = (position: number) => `小数第 ${position}位までの数値を入力してください`
  const phdPostalCode = '000-0000'
  const phdTelephone = '0000-0000-0000'
  
  const validateRequired = (val: string | null) => {
    if (!val) return msgRequired
    if (!val.length) return msgRequired
    return null
  }
  const validateRequiredSelect = (val: string | null) => {
    if (!val) return msgRequiredSelect
    if (!val.length) return msgRequiredSelect
    return null
  }
  const validateKana = (val: string | null) => {
    if (!val) return null
    return regKana.test(val) ? null : msgKana
  }
  const validateNumber = (val: string | null) => {
    if (!val) return null
    return regNumber.test(val) ? null : msgNumber
  }
  const validateInteger = (val: string | null) => {
    if (!val) return null
    return regInteger.test(val) ? null : msgInteger
  }
  const validateDecimal = (val: string | null) => {
    if (!val) return null
    return regDecimal.test(val) ? null : msgDecimal
  }
  const validatePostalCode = (val: string | null) => {
    if (!val) return null
    return regPostalCode.test(val) ? null : msgPostalCode
  }
  const validateTelephone = (val: string | null) => {
    if (!val) return null
    return regTelephone.test(val) ? null : msgTelephone
  }
  const validateDecimalPosition = (val: string | null, position: number) => {
    if (!val) return null
    if (!regDecimal.test(val)) return msgDecimal
    const pos = val.indexOf('.')
    if (
      pos > -1 &&
      val.length - pos - 1 > position
    ) return msgDecimalPosition(position)
    return null
  }
  const validateUnitPrice = (val: string | null) => {
    return validateDecimalPosition(val, 2)
  }
  const validateQuantity = (val: string | null) => {
    return validateDecimalPosition(val, 2)
  }
  const validateLengthEqual = (val: string | null, len: number) => {
    if (!val) return null
    return val.length === len ? null : msgLengthEqual(len)
  }
  const validateLengthMore = (val: string | null, len: number) => {
    if (!val) return null
    return val.length >= len ? null : msgLengthMore(len)
  }
  const validateLengthLess = (val: string | null, len: number) => {
    if (!val) return null
    return val.length <= len ? null : msgLengthLess(len)
  }
  const validateLengthMoreLess = (val: string | null, more: number, less: number) => {
    if (!val) return null
    return val.length >= more && val.length <= less ? null : msgLengthMoreLess(more, less)
  }
  const validateNumberLength = (val: string | null, len: number) => {
    if (!val) return null
    try {
      if (Number.isNaN(val)) return msgNumberLength(len)
      return val.length === len ? null : msgNumberLength(len)
    } catch {
      return msgNumberLength(len)
    }
  }
  const validateNumberMin = (val: string | null, min: number) => {
    if (!val) return null
    try {
      if (Number.isNaN(val)) return msgNumberMin(min)
      return Number(val) >= min ? null : msgNumberMin(min)
    } catch {
      return msgNumberMin(min)
    }
  }
  const validateNumberMax = (val: string | null, max: number) => {
    if (!val) return null
    try {
      if (Number.isNaN(val)) return msgNumberMax(max)
      return Number(val) <= max ? null : msgNumberMax(max)
    } catch {
      return msgNumberMax(max)
    }
  }
  const validateNumberMinMax = (val: string | null, min: number, max: number) => {
    if (!val) return null
    try {
      if (Number.isNaN(val)) return msgNumberMinMax(min, max)
      const value = Number(val)
      return value >= min && value <= max ? null : msgNumberMinMax(min, max)
    } catch {
      return msgNumberMinMax(min, max)
    }
  }

  const validate = (value: string | null, items: Array<string | ValidateItem> | null) => {
    if (!items || !items.length) return null
    let ret
    for (const item of items) {
      if (typeof(item) === 'string') {
        switch (item) {
          case 'required':
            ret = validateRequired(value)
            if (ret) return ret
            break;
          case 'required-select':
            ret = validateRequiredSelect(value)
            if (ret) return ret
            break;
          case 'kana':
            ret = validateKana(value)
            if (ret) return ret
            break;
          case 'number':
            ret = validateNumber(value)
            if (ret) return ret
            break;
          case 'integer':
            ret = validateInteger(value)
            if (ret) return ret
            break;
          case 'decimal':
            ret = validateDecimal(value)
            if (ret) return ret
            break;
          case 'postalCode':
            ret = validatePostalCode(value)
            if (ret) return ret
            break;
          case 'telephone':
            ret = validateTelephone(value)
            if (ret) return ret
            break;
          case 'unitPrice':
            ret = validateUnitPrice(value)
            if (ret) return ret
            break;
          case 'quantity':
            ret = validateQuantity(value)
            if (ret) return ret
            break;
        }
      } else if (typeof(item) === 'object') {
        if (item.lengthEqual !== undefined) {
          ret = validateLengthEqual(value, item.lengthEqual)
          if (ret) return ret
        } else if (item.lengthMore !== undefined) {
          ret = validateLengthMore(value, item.lengthMore)
          if (ret) return ret
        } else if (item.lengthLess !== undefined) {
          ret = validateLengthLess(value, item.lengthLess)
          if (ret) return ret
        } else if (item.lengthMoreLess !== undefined) {
          ret = validateLengthMoreLess(value, item.lengthMoreLess[0], item.lengthMoreLess[1])
          if (ret) return ret
        } else if (item.numberLength !== undefined) {
          ret = validateNumberLength(value, item.numberLength)
          if (ret) return ret
        } else if (item.numberMin !== undefined) {
          ret = validateNumberMin(value, item.numberMin)
          if (ret) return ret
        } else if (item.numberMax !== undefined) {
          ret = validateNumberMax(value, item.numberMax)
          if (ret) return ret
        } else if (item.numberMinMax !== undefined) {
          ret = validateNumberMinMax(value, item.numberMinMax[0], item.numberMinMax[1])
          if (ret) return ret
        }
      }
    }
    return null
  }

  return {
    validate,
    phdPostalCode,
    phdTelephone,
  }
}
