export const tableName = '監査'

export default function audits () {
  const columnLabel = new Map([
    [ 'user', 'ユーザ' ],
    [ 'event', 'イベント' ],
    [ 'auditableType', '管理名' ],
    [ 'auditableId', '更新したデータのID' ],
    [ 'oldValues', '更新前のデータ' ],
    [ 'newValues', '更新後のデータ' ],
    [ 'createdAt', '作成日時' ],
    [ 'updatedAt', '更新日時' ],
  ])

  return {
    columnLabel,
  }
}
