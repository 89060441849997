export const tableName = '入金内訳'

export default function journalDepositItems () {
  const itemLabel = new Map([
    [ 'deposit_type', '入金区分' ],
    [ 'element_name', '要素内訳名' ],

    [ 'account_code_main', '入金 【工事】' ],
    [ 'account_code_main_debit', '（借方）勘定科目コード' ],
    [ 'account_code_main_credit', '（貸方）勘定科目コード' ],
    [ 'account_code_side', '入金 【兼業】' ],
    [ 'account_code_side_debit', '（借方）勘定科目コード' ],
    [ 'account_code_side_credit', '（貸方）勘定科目コード' ],

    [ 'account_code_main_advance', '完成振替入金 【工事】' ],
    [ 'account_code_main_debit_advance', '（借方）勘定科目コード' ],
    [ 'account_code_main_credit_advance', '（貸方）勘定科目コード' ],
    [ 'account_code_side_advance', '完成振替入金 【兼業】' ],
    [ 'account_code_side_debit_advance', '（借方）勘定科目コード' ],
    [ 'account_code_side_credit_advance', '（貸方）勘定科目コード' ],
  ])
  const columnLabel = new Map([
    [ 'deposit_type', '入金区分' ],
    [ 'element_name', '要素内訳名' ],
    
    [ 'account_code', '勘定科目コード 【入金】' ],
    [ 'account_code_main', '工事' ],
    [ 'account_code_main_debit', '借方' ],
    [ 'account_code_main_credit', '貸方' ],
    [ 'account_code_side', '兼業' ],
    [ 'account_code_side_debit', '借方' ],
    [ 'account_code_side_credit', '貸方' ],

    [ 'account_code_advance', '勘定科目コード 【完成振替入金】' ],
    [ 'account_code_main_advance', '工事' ],
    [ 'account_code_main_debit_advance', '借方' ],
    [ 'account_code_main_credit_advance', '貸方' ],
    [ 'account_code_side_advance', '兼業' ],
    [ 'account_code_side_debit_advance', '借方' ],
    [ 'account_code_side_credit_advance', '貸方' ],

    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],
  ])

  return {
    itemLabel,
    columnLabel,
  }
}
