export interface DbBill {
  id: string
  id_number: number
  status: string
  client_order_no: string
  billing_target_name: string
  billing_target_kana: string
  billing_date: string
  invoice_date: string
  invoice_date_format: string
  amount_including_tax: bigint
  amount_excluding_tax: bigint
  consumption_tax_amount: number
  memo: string
  invoice_format: string
  sent_date: string
  sender_name: string
  sender_kana: string
  updated_at: string
  updated_by_name: string
  updated_by_kana: string

  contract_id: string
  contract_name: string
  contract_kana: string
  site_agent_name: string
  site_agent_kana: string
  complete_date: string
  sales_date: string
  //client_id: string
  client_name: string
  client_kana: string
  billing_target_company_name: string
  billing_target_branch_name: string
  billing_target_postcode: string
  billing_target_address: string

  company_name: string
  representative_title: string
  representative_name: string
  representative_kana: string
  base_name: string
  base_display_order: number
  base_chief_title: string
  base_chief_name: string
  base_chief_kana: string
  base_postcode: string
  base_address: string
  base_tel: string
  base_fax: string
  bank_code: string
  bank_name: string
  bank_branch_code: string
  bank_branch_name: string
  deposit_type: string
  account_number: string
  account_holder: string
}
/*
export const ConverterBillingType = {
  1: {
    text: '通常',
    type: 'ellipse',
    style: {
      background: 'rgb(0,140,0)',
      color: 'rgb(255,255,255)',
    },
  },
  2: {
    text: '一括',
    type: 'ellipse',
    style: {
      background: 'rgb(0,0,200)',
      color: 'rgb(255,255,255)',
    },
  },
  3: {
    text: '分割',
    type: 'ellipse',
    style: {
      background: 'rgb(200,0,0)',
      color: 'rgb(255,255,255)',
    },
  },
}

export const ConvertercontractType = {
  1: {
    text: '工事',
    type: 'rectangle',
    style: {
      background: 'rgb(200,0,0)',
      color: 'rgb(255,255,255)',
    },
  },
  2: {
    text: '物販',
    type: 'rectangle',
    style: {
      background: 'rgb(200,180,0)',
      color: 'rgb(255,255,255)',
    },
  },
  3: {
    text: '障害',
    type: 'rectangle',
    style: {
      background: 'rgb(0,140,0)',
      color: 'rgb(200,200,200)',
    },
  },
  4: {
    text: '保守',
    type: 'rectangle',
    style: {
      background: 'rgb(0,140,140)',
      color: 'rgb(255,255,255)',
    },
  },
  5: {
    text: '役務',
    type: 'rectangle',
    style: {
      background: 'rgb(0,0,200)',
      color: 'rgb(255,255,255)',
    },
  },
}

export const ConverterApprovalStatus = {
  0: {
    text: '未申請',
    type: 'ellipse',
    style: {
      background: 'rgb(200,200,0)',
      color: 'rgb(255,255,255)',
    },
  },
  10: {
    text: '申請中',
    type: 'ellipse',
    style: {
      background: 'rgb(0,200,200)',
      color: 'rgb(255,255,255)',
    },
  },
  20: {
    text: '差戻',
    type: 'ellipse',
    style: {
      background: 'rgb(200,0,0)',
      color: 'rgb(255,255,255)',
    },
  },
  30: {
    text: '承認済',
    type: 'ellipse',
    style: {
      background: 'rgb(0,140,0)',
      color: 'rgb(255,255,255)',
    },
  },
}

export const ConverterStatus = {
  0: {
    text: '未出力',
    type: 'ellipse',
    style: {
      background: 'rgb(200,0,0)',
      color: 'rgb(255,255,255)',
    },
  },
  10: {
    text: '締め前',
    type: 'ellipse',
    style: {
      background: 'rgb(220,160,0)',
      color: 'rgb(255,255,255)',
    },
  },
  20: {
    text: '出力済',
    type: 'ellipse',
    style: {
      background: 'rgb(0,140,0)',
      color: 'rgb(255,255,255)',
    },
  },
}
*/
export default function bills () {
  const bills: Array<DbBill> = [{
    id: '1234',
    id_number: 1234,
    status: '10',
    client_order_no: '1234-5678',
    billing_target_name: '福島データセンター郡山支部',
    billing_target_kana: 'フクシマデータセンター フクシマシテン',
    billing_date: '2023-04-04',
    invoice_date: '2023-04-10',
    invoice_date_format: '1',
    amount_including_tax: 11000000n,
    amount_excluding_tax: 10000000n,
    consumption_tax_amount: 1000000,
    memo: '社内メモ欄',
    invoice_format: '日付_税区分',
    //issuer_date: '',
    //issuer_name: '社員Ａ',
    //issuer_kana: 'シャインエー',
    sent_date: '2022-12-02',
    sender_name: '社員Ａ',
    sender_kana: 'シャインエー',
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',

    contract_id: '1',
    contract_name: '案件１',
    contract_kana: 'アンケン１',
    site_agent_name: '現場代理人１',
    site_agent_kana: 'ゲンバダイリニン１',
    complete_date: '2023-01-10',
    sales_date: '2023-04-04',
    client_name: '福島データセンター郡山支部',
    client_kana: 'フクシマデータセンター フクシマシテン',
    billing_target_company_name: '福島データセンター株式会社',
    billing_target_branch_name: '郡山支部',
    billing_target_postcode: '111-222',
    billing_target_address: '福島県郡山市〇〇△△',

    company_name: '安積電気通信興業株式会社',
    representative_title: '代表取締役',
    representative_name: '〇〇 〇〇',
    representative_kana: 'マルマル マルマル',
    base_name: '東京オフィス',
    base_display_order: 3,
    base_chief_title: '事業所長',
    base_chief_name: '☓☓ ☓☓',
    base_chief_kana: 'バツバツ バツバツ',
    base_postcode: '',
    base_address: '',
    base_tel: '',
    base_fax: '',
    bank_code: '',
    bank_name: '',
    bank_branch_code: '',
    bank_branch_name: '',
    deposit_type: '',
    account_number: '',
    account_holder: '',
  }]

  return {
    bills,
  }
}
