export const tableName = '売上内訳'

export default function journalSalesItems () {
  const itemLabel = new Map([
    [ 'element_name', '要素内訳名' ],
    
    [ 'account_code_main', '工事' ],
    [ 'account_code_main_debit', '（借方）勘定科目コード' ],
    [ 'account_code_main_credit', '（貸方）勘定科目コード' ],
    [ 'account_code_side', '兼業' ],
    [ 'account_code_side_debit', '（借方）勘定科目コード' ],
    [ 'account_code_side_credit', '（貸方）勘定科目コード' ],
  ])
  const columnLabel = new Map([
    [ 'element_name', '要素内訳名' ],
    
    [ 'account_code', '勘定科目コード' ],
    [ 'account_code_main', '工事' ],
    [ 'account_code_main_debit', '借方' ],
    [ 'account_code_main_credit', '貸方' ],
    [ 'account_code_side', '兼業' ],
    [ 'account_code_side_debit', '借方' ],
    [ 'account_code_side_credit', '貸方' ],

    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],
  ])

  return {
    itemLabel,
    columnLabel,
  }
}
