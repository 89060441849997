import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import { PadViewItem } from '@/const/component/padView'
import ConstOrderProbabilities from '@/const/transaction/master/orderProbabilities'

export default function pad () {
  const constTable = ConstOrderProbabilities()
  /*
  const publicPrivateWorksTypeItems = computed(() => {
    return Array.from(constTable.longPublicPrivateWorksType).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })
  const collectionMonthItems = computed(() => {
    return Array.from(constTable.collectionMonth).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })
  const taxRoundingItems = computed(() => {
    return Array.from(constTable.taxRounding).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })
  const amountRoundingItems = computed(() => {
    return Array.from(constTable.amountRounding).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })
  */
  const getPadItems = (readonly = true) => {
    const ro = readonly
    const rq = !readonly

    const items: PadViewItem[] = [{
      id: 'orderProbability',
      groups: [{
        items: [{
          id: 'base',
          label: constTable.itemLabel.get('base') ?? '',
          type: 'text',
          readonly: true,
          required: false,
        }, {
          id: 'division',
          label: constTable.itemLabel.get('division') ?? '',
          type: 'text',
          readonly: true,
          required: false,
        }]
      }, {
        items: [{
          id: 'unofficial',
          label: constTable.itemLabel.get('unofficial') ?? '',
          type: 'number',
          readonly: ro,
          required: rq,
        }, {
          id: 'rank_a',
          label: constTable.itemLabel.get('rank_a') ?? '',
          type: 'number',
          readonly: ro,
          required: rq,
        }, {
          id: 'rank_b',
          label: constTable.itemLabel.get('rank_b') ?? '',
          type: 'number',
          readonly: ro,
          required: rq,
        }, {
          id: 'rank_c',
          label: constTable.itemLabel.get('rank_c') ?? '',
          type: 'number',
          readonly: ro,
          required: rq,
        }, {
          id: 'rank_d',
          label: constTable.itemLabel.get('rank_d') ?? '',
          type: 'number',
          readonly: ro,
          required: rq,
        }, {
          id: 'rank_e',
          label: constTable.itemLabel.get('rank_e') ?? '',
          type: 'number',
          readonly: ro,
          required: rq,
        }],
      }],
    }]

    return items
  }

  return {
    /*
    collectionMonthItems,
    publicPrivateWorksTypeItems,
    taxRoundingItems,
    amountRoundingItems,
    */
    getPadItems,
  }
}
