import gql from 'graphql-tag'

export const LOGIN_AS_EMPLOYEE = gql`
  mutation LoginAsEmployee ($input: LoginAsEmployeeInput!) {
    loginAsEmployee (input: $input) {
      token
    }
  }
`

export const LOGOUT_AS_EMPLOYEE = gql`
  mutation LogoutAsEmployee {
    logoutAsEmployee
  }
`
