export interface DbAudit {
  id: string
  id_number: number
  user_name: string
  user_kana: string
  event: string
  event_number: number
  auditable_type: string
  auditable_id: string
  auditable_id_number: number
  old_values: string | null
  new_values: string | null
  created_at: string
  updated_at: string
}

export default function audits () {
  const audits: Array<DbAudit> = [{
    id: '1',
    id_number: 1,
    user_name: '三宅 陽一',
    user_kana: 'ミヤケ ヨウイチ',
    event: 'created',
    event_number: 1,
    auditable_type: '案件',
    auditable_id: '1',
    auditable_id_number: 1,
    old_values: null,
    new_values: '{"id":"1","name":"Ａ社LAN配線工事","kana":"AシャLANハイセンコウジ"}',
    created_at: '2023-05-01 11:22:33',
    updated_at: '2023-05-01 11:22:33',
  }, {
    id: '2',
    id_number: 1,
    user_name: '三宅 陽一',
    user_kana: 'ミヤケ ヨウイチ',
    event: 'updated',
    event_number: 2,
    auditable_type: '請求',
    auditable_id: '222',
    auditable_id_number: 222,
    old_values: '{"billing_date":"2023-04-01"}',
    new_values: '{"billing_date":"2023-04-11"}',
    created_at: '2023-04-10 10:12:34',
    updated_at: '2023-06-01 16:41:23',
  }, {
    id: '3',
    id_number: 3,
    user_name: '三宅 陽一',
    user_kana: 'ミヤケ ヨウイチ',
    event: 'deleted',
    event_number: 3,
    auditable_type: '見積',
    auditable_id: '34',
    auditable_id_number: 34,
    old_values: '{"id":"34","revision_no":"1","revision_description":"現場代理人変更"}',
    new_values: null,
    created_at: '2023-01-10 10:12:34',
    updated_at: '2023-02-01 18:52:53',
  }]

  return {
    audits,
  }
}
