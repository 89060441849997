export const tableName = '仕入集計'

export default function purchaseDeliveryTotals () {
  const itemLabel = new Map([
    [ 'supplierCode', '仕入先コード' ],
    [ 'supplier', '仕入先' ],
    [ 'purchaseAmountExcludingTax', '税抜仕入額［円］' ],
    [ 'purchaseAmountIncludingTax', '税込仕入額［円］' ],
    [ 'budgetAmountExcludingTax', '税抜予算金額［円］' ],
    [ 'budgetAmountIncludingTax', '税込予算金額［円］' ],
    //[ 'discountReturnAmount', '返品・値引額［円］' ],
    //[ 'totalPurchaseAmountIncludingTax', '税込総仕入額［円］' ],
    //[ 'totalPurchaseAmountExcludingTax', '税抜総仕入額［円］' ],
  ])
  const searchLabel = new Map([
    [ 'supplierCode', '仕入先コード' ],
    [ 'supplier', '仕入先' ],
    [ 'purchaseAmountExcludingTax', '税抜仕入額' ],
    [ 'purchaseAmountIncludingTax', '税込仕入額' ],
    [ 'budgetAmountExcludingTax', '税抜予算金額' ],
    [ 'budgetAmountIncludingTax', '税込予算金額' ],
    //[ 'discountReturnAmount', '返品・値引額' ],
    //[ 'totalPurchaseAmountIncludingTax', '税込総仕入額' ],
    //[ 'totalPurchaseAmountExcludingTax', '税抜総仕入額' ],
  ])
  const columnLabel = new Map([
    [ 'supplierCode', '仕入先コード' ],
    [ 'supplier', '仕入先' ],
    [ 'purchaseAmountExcludingTax', '税抜仕入額［円］' ],
    [ 'purchaseAmountIncludingTax', '税込仕入額［円］' ],
    [ 'budgetAmountExcludingTax', '税抜予算金額［円］' ],
    [ 'budgetAmountIncludingTax', '税込予算金額［円］' ],
    //[ 'discountReturnAmount', '返品・値引額［円］' ],
    //[ 'totalPurchaseAmountIncludingTax', '税込総仕入額［円］' ],
    //[ 'totalPurchaseAmountExcludingTax', '税抜総仕入額［円］' ],
  ])

  return {
    itemLabel,
    searchLabel,
    columnLabel,
  }
}