import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstJournalPurchases from '@/const/transaction/journalPurchases'

const constTable = ConstJournalPurchases()

const baseListColumns: ListViewColumn[] = [{
  id: 'totalingMonth',
  text: constTable.columnLabel.get('totalingMonth'),
  displayField: 'totaling_month',
  sortField: 'totaling_month',
}, {
  id: 'status',
  text: constTable.columnLabel.get('status'),
  displayField: 'status',
  sortField: 'status',
  align: 'center',
  width: '100px',
}, {
  id: 'amount',
  text: constTable.columnLabel.get('amount'),
  displayField: 'amount',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'generatedDate',
  text: constTable.columnLabel.get('generatedDate'),
  displayField: 'generated_date',
  sortField: 'generated_date',
}, {
  id: 'generator',
  text: constTable.columnLabel.get('generator'),
  displayField: 'generator_name',
  sortField: 'generator_kana',
}, {
  id: 'updatedAt',
  text: constTable.columnLabel.get('updatedAt'),
  displayField: 'updated_at',
  sortField: 'updated_at',
}, {
  id: 'updatedBy',
  text: constTable.columnLabel.get('updatedBy'),
  displayField: 'updated_by_name',
  sortField: 'updated_by_kana',
}]

const standardListColumns = {
  purchase: [
    'totalingMonth',
    'status',
    'amount',
    'generatedDate',
    'generator',
    'updatedAt',
    'updatedBy',
  ],
}

const listIcon: ListViewIcon = {
}
const listConverter: ListViewConverter = {
  status: {
    '0': {
      text: constTable.status.get('0') ?? '',
      type: 'rectangle',
      style: {
        background: 'rgb(0,100,0)',
        color: 'rgb(255,255,255)',
      },
    },
    '10': {
      text: constTable.status.get('10') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(200,0,0)',
        color: 'rgb(255,255,255)',
      },
    },
  },
}

export default function journalPurchase () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'totaling_month',
    order: {
      'totaling_month': -1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
