import gql from 'graphql-tag'
import {
  ref,
  reactive,
  computed,
  watch,
} from 'vue'
import {
  useQuery,
} from '@vue/apollo-composable'
import {
  Base,
  Division,
  QueryBasesArgs,
  QueryBasesOrderByColumn,
  QueryDivisionsArgs,
  QueryDivisionsOrderByColumn,
  SortOrder ,
} from '@/graphql'

const doc = {
  bases: {
    select: gql`
      query GetDivisionSelectBases($orderBy: [QueryBasesOrderByOrderByClause!], $first: Int!, $page: Int!) {
        bases(orderBy: $orderBy, first: $first, page: $page) {
          data {
            id
            base_name
          }
          paginatorInfo {
            hasMorePages
          }
        }
      }
    `,
  },
  divisions: {
    select: gql`
      query GetDivisionSelectDivisions($orderBy: [QueryDivisionsOrderByOrderByClause!], $first: Int!, $page: Int!) {
        divisions(orderBy: $orderBy, first: $first, page: $page) {
          data {
            id
            division_name
          }
          paginatorInfo {
            hasMorePages
          }
        }
      }
    `,
  },
}

const bases = () => {
  const items = ref<Base[]>([])
  const variables = reactive<QueryBasesArgs>({
    first: 100,
    page: 1,
    orderBy: [{ column: QueryBasesOrderByColumn.Id, order: SortOrder.Asc }],  //DisplayOrder に変更
  })
  const options:{ [ key: string ]: string } = {
    fetchPolicy: 'cache-and-network',
  }
  const query = useQuery(doc.bases.select, variables, options)
  const result = computed(() => (query.result.value ? query.result.value.bases : {}))
  const data = computed(() => result.value.data ?? [])
  const paginator = computed(() => result.value.paginatorInfo ?? {})

  watch(data, (val) => {
    if (!val.length) return
    items.value = items.value.concat(val)
  })
  watch(paginator, (val) => {
    if (variables && val.hasMorePages && variables.page) variables.page++
  })

  return {
    loading: query.loading,
    loadError: query.error,
    items,
  }
}
const divisions = () => {
  const items = ref<Division[]>([])
  const variables = reactive<QueryDivisionsArgs>({
    first: 100,
    page: 1,
    orderBy: [{ column: QueryDivisionsOrderByColumn.Id, order: SortOrder.Asc }],  //DisplayOrder に変更
  })
  const options:{ [ key: string ]: string } = {
    fetchPolicy: 'cache-and-network',
  }
  const query = useQuery(doc.divisions.select, variables, options)
  const result = computed(() => (query.result.value ? query.result.value.divisions : {}))
  const data = computed(() => result.value.data ?? [])
  const paginator = computed(() => result.value.paginatorInfo ?? {})

  watch(data, (val) => {
    if (!val.length) return
    items.value = items.value.concat(val)
  })
  watch(paginator, (val) => {
    if (variables && val.hasMorePages && variables.page) variables.page++
  })

  return {
    loading: query.loading,
    loadError: query.error,
    items,
  }
}

export default function baseDivisionSelectBox () {
  return {
    bases,
    divisions,
  }
}
