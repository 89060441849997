import {
  computed,
} from 'vue'
import ConstPurchaseItems from '@/const/transaction/purchaseItems'
import {
  SearchBoxItem,
} from '@/const/component/searchBox'

const constPurchaseItems = ConstPurchaseItems()

const items = [
  'modelNumber',
  'description',
  /*
  'expenseType',
  'expenseAccountType',
  'quantity',
  'unit',
  'unitPrice',
  */
]

export default function search () {
  const searchItems = computed(() => {
    return items.map(el => {
      return {
        id: el,
        label: constPurchaseItems.searchLabel.get(el),
      } as SearchBoxItem
    })
  })

  return {
    searchItems,
  }
}
