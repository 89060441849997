import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstApprovalRoutes from '@/const/transaction/master/approvalRoutes'

const constTable = ConstApprovalRoutes()

const baseListColumns: ListViewColumn[] = [{
  id: 'name',
  text: constTable.columnLabel.get('name'),
  displayField: 'name',
  sortField: 'name',
}, {
  id: 'document_type',
  text: constTable.columnLabel.get('document_type'),
  displayField: 'document_type',
  sortField: 'document_type_number',
  align: 'center',
  width: '100px',
}, {
  id: 'approver1',
  text: constTable.columnLabel.get('approver1'),
  displayField: 'approver1_name',
  sortField: 'approver1_kana',
}, {
  id: 'approver2',
  text: constTable.columnLabel.get('approver2'),
  displayField: 'approver2_name',
  sortField: 'approver2_kana',
}, {
  id: 'approver3',
  text: constTable.columnLabel.get('approver3'),
  displayField: 'approver3_name',
  sortField: 'approver3_kana',
}, {
  id: 'approver4',
  text: constTable.columnLabel.get('approver4'),
  displayField: 'approver4_name',
  sortField: 'approver4_kana',
}, {
  id: 'updated_at',
  text: constTable.columnLabel.get('updated_at'),
  displayField: 'updated_at',
  sortField: 'updated_at',
}, {
  id: 'updated_by',
  text: constTable.columnLabel.get('updated_by'),
  displayField: 'updated_by_name',
  sortField: 'updated_by_kana',
}]

const standardListColumns = {
  approval_route: [
    'name',
    'document_type',
    'approver1',
    'approver2',
    'approver3',
    'approver4',
    'updated_at',
    'updated_by',
  ],
}

const listIcon: ListViewIcon = {
}
const listConverter: ListViewConverter = {
  document_type: {
    '1': {
      text: '見積',
      type: 'text',
    },
    '2': {
      text: '請求',
      type: 'text',
    },
    '3': {
      text: '発注',
      type: 'text',
    },
  },
}

export default function payment () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'name',
    order: {
      'name': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
