import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstBudgets from '@/const/transaction/budgets'

const constBudgets = ConstBudgets()

const baseListColumns: ListViewColumn[] = [{
  id: 'base',
  text: constBudgets.columnLabel.get('base'),
  displayField: 'base_name',
  sortField: 'base_display_no',
  bottomRow: {
    bold: true,
  },
}, {
  id: 'division',
  text: constBudgets.columnLabel.get('division'),
  displayField: 'division_name',
  sortField: 'division_display_no',
  bottomRow: {
    bold: true,
  },
}, {
  id: 'budgetAmount',
  text: constBudgets.columnLabel.get('budgetAmount'),
  displayField: 'budget_amount',
  sortField: 'budget_amount',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  bottomRow: {
    bold: true,
  },
}, {
  id: 'completeAmount',
  text: constBudgets.columnLabel.get('completeAmount'),
  displayField: 'complete_amount',
  sortField: 'complete_amount',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  bottomRow: {
    bold: true,
  },
}, {
  id: 'progressAmount',
  text: constBudgets.columnLabel.get('progressAmount'),
  displayField: 'progress_amount',
  sortField: 'progress_amount',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  bottomRow: {
    bold: true,
  },
}, {
  id: 'prospectAmount',
  text: constBudgets.columnLabel.get('prospectAmount'),
  displayField: 'prospect_amount',
  sortField: 'prospect_amount',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  bottomRow: {
    bold: true,
  },
}, {
  id: 'achievementRate',
  text: constBudgets.columnLabel.get('achievementRate'),
  displayField: 'achievement_rate',
  sortField: 'achievement_rate',
  displayConvert: 'decimalDelimiter',
  align: 'right',
  bottomRow: {
    bold: true,
  },
}]

const standardListColumns = {
  general: [
    'base',
    'division',
    'budgetAmount',
    'completeAmount',
    'progressAmount',
    'prospectAmount',
    'achievementRate',
  ],
}

const listIcon: ListViewIcon = {
}
const listConverter: ListViewConverter = {
}

export default function deposit () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'base_display_no',
    order: {
      'base_display_no': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
