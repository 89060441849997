export const tableName = '売掛残'

export default function depositBalances () {
  const columnLabel = new Map([
    [ 'yearMonth', '集計月' ],
    [ 'billingTargetCode', '請求先コード' ], //id, name, kana
    [ 'billingTarget', '請求先' ], //id, name, kana
    
    [ 'previousMonthBalance', '前月残金［円］' ],
    [ 'amountIncurred', '当月発生金額［円］' ],
    [ 'depositAmount', '当月入金額［円］' ],
    [ 'currentMonthBalance', '当月残金［円］' ],
  ])

  return {
    columnLabel,
  }
}
