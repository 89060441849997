import { toRaw } from 'vue'
import FormError from '@/composables/common/form/FormError'

/**
 * @deprecated Vuelidate導入に伴い不要になったので
 * @param formRef
 * @param formErrorsRef
 */
export default function clearFormErrors (formRef: Record<string, unknown>, formErrorsRef: Record<string, unknown>) {
  const rawForm = toRaw(formRef)
  const emptyErrors = {}
  Object.keys(rawForm).forEach((k) => {
    // @ts-ignore
    emptyErrors[k] = new FormError('')
  })
  Object.assign(formErrorsRef, { ...emptyErrors, __errorCount__: 0 })
}
