/**
 * @deprecated Vuelidate導入に伴い不要になったので
 */
export default class FormError {
  private readonly _message: string

  constructor (message: string) {
    this._message = message
  }

  get message (): string {
    return this._message
  }

  get invalid (): boolean {
    return !!this._message
  }
}
