import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import { PadViewItem } from '@/const/component/padView'
import ConstPayments from '@/const/transaction/payments'

export default function pad () {
  const constTable = ConstPayments()

  const paymentMethodItems = computed(() => {
    return Array.from(constTable.paymentMethod).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })

  const getPadItems = (readonly = true) => {
    const ro = readonly
    const rq = !readonly

    const items: PadViewItem[] = [{
      id: 'deposit',
      groups: [{
        items: [{
          id: 'paymentDate',
          label: constTable.itemLabel.get('paymentDate') ?? '',
          type: 'date',
          readonly: ro,
          required: rq,
        }]
      }, {
        items: [{
          id: 'supplier',
          label: constTable.itemLabel.get('supplier') ?? '',
          type: 'client',
          readonly: ro,
          required: rq,
        }]
      }, {
        items: [{
          id: 'paymentMethod',
          label: constTable.itemLabel.get('paymentMethod') ?? '',
          type: 'select',
          items: paymentMethodItems.value,
          readonly: ro,
          required: rq,
        }, {
          id: 'paymentAmount',
          label: constTable.itemLabel.get('paymentAmount') ?? '',
          type: 'number',
          readonly: ro,
          required: rq,
        }]
        /*
      }, {
        items: [{
          id: 'updatedAt',
          label: constTable.itemLabel.get('updatedAt') ?? '',
          type: 'date',
          readonly: true,
        }, {
          id: 'updatedBy',
          label: constTable.itemLabel.get('updatedBy') ?? '',
          type: 'text',
          readonly: true,
        }]
        */
      }],
    }]

    return items
  }

  return {
    getPadItems,
  }
}
