export const tableName = '請求'

export default function bills () {
  const itemLabel = new Map([
    [ 'billInformation', '請求情報' ],

    [ 'status', 'ステータス' ],
    [ 'id', '請求番号' ],
    [ 'clientOrderNo', '得意先注文番号' ],
    [ 'billingTarget', '請求先' ],
    [ 'billingDate', '請求日' ],
    [ 'invoiceDate', '請求書に印字される日付' ],
    [ 'invoiceDateFormat', '西暦／和暦' ],
    [ 'amountIncludingTax', '税込金額［円］' ],
    [ 'amountExcludingTax', '税抜金額［円］' ],
    [ 'consumptionTaxAmount', '消費税額［円］' ],
    [ 'memo', 'メモ' ],
    [ 'invoiceFormat', '請求書フォーマット' ],
    [ 'sentDate', '送付日時' ], 
    [ 'sender', '送付者' ],
    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],


    [ 'contractInformation', '案件情報' ],

    [ 'contract', '案件' ],
    [ 'contractId', '案件番号' ],
    [ 'contractName', '案件名' ],
    [ 'siteAgent', '現場代理人' ],
    [ 'completeDate', '完成日' ],
    [ 'salesDate', '売上日' ],
    [ 'client', '発注者' ],
    [ 'billingTargetCompanyName', '請求先社名' ],//
    [ 'billingTargetBranchName', '請求先支店名' ],//
    [ 'billingTargetPostcode', '請求先郵便番号' ],//
    [ 'billingTargetAddress', '請求先住所' ],//


    [ 'constractorInformation', '自社情報' ],

    [ 'companyName', '社名' ],//
    [ 'representativeTitle', '会社代表者役職' ],//
    [ 'representativeName', '会社代表者名' ],//
    [ 'base', '拠点' ],
    [ 'baseChiefTitle', '拠点代表者役職' ],//
    [ 'baseChiefName', '拠点代表者名' ],//
    [ 'basePostcode', '拠点郵便番号' ],//
    [ 'baseAddress', '拠点住所' ],//
    [ 'baseTel', '拠点TEL' ],//
    [ 'baseFax', '拠点FAX' ],//
    [ 'bank', '振込先銀行名' ],//
    [ 'bankBranch', '銀行支店名' ],//
    [ 'depositType', '預金種目' ],//
    [ 'accountNumber', '口座番号' ],//
    [ 'accountHolder', '口座名義' ],//


    [ 'details', '明細' ],
  ])
  const searchLabel = new Map([
    [ 'id', '請求番号' ],
    [ 'clientOrderNo', '得意先注文番号' ],
    [ 'memo', 'メモ' ],
    [ 'contractId', '案件番号' ],
    [ 'contractName', '案件名' ],
  ])
  const columnLabel = new Map([
    [ 'billInformation', '請求情報' ],

    [ 'status', 'ステータス' ],
    [ 'id', '請求番号' ],
    [ 'clientOrderNo', '得意先注文番号' ],
    [ 'billingTarget', '請求先' ],
    [ 'billingDate', '請求日' ],
    [ 'invoiceDate', '請求書に印字される日付' ],
    [ 'invoiceDateFormat', '西暦／和暦' ],
    [ 'amountIncludingTax', '税込金額［円］' ],
    [ 'amountExcludingTax', '税抜金額［円］' ],
    [ 'consumptionTaxAmount', '消費税額［円］' ],
    [ 'memo', 'メモ' ],
    [ 'invoiceFormat', 'フォーマット' ],
    [ 'sentDate', '送付日時' ], 
    [ 'sender', '送付者' ],
    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],


    [ 'contractInformation', '案件情報' ],

    [ 'contract', '案件' ],//
    [ 'contractId', '案件番号' ],
    [ 'contractName', '案件名' ],
    [ 'siteAgent', '現場代理人' ],
    [ 'completeDate', '完成日' ],
    [ 'salesDate', '売上日' ],
    [ 'client', '発注者' ],//
    [ 'billingTargetCompanyName', '請求先社名' ],//
    [ 'billingTargetBranchName', '請求先支店名' ],//
    [ 'billingTargetPostcode', '請求先郵便番号' ],//
    [ 'billingTargetAddress', '請求先住所' ],//


    [ 'constractorInformation', '自社情報' ],

    [ 'companyName', '社名' ],//
    [ 'representativeTitle', '会社代表者役職' ],//
    [ 'representativeName', '会社代表者名' ],//
    [ 'base', '拠点' ],
    [ 'baseChiefTitle', '拠点代表者役職' ],//
    [ 'baseChiefName', '拠点代表者名' ],//
    [ 'basePostcode', '拠点郵便番号' ],//
    [ 'baseAddress', '拠点住所' ],//
    [ 'baseTel', '拠点TEL' ],//
    [ 'baseFax', '拠点FAX' ],//
    [ 'bank', '振込先銀行名' ],//
    [ 'bankBranch', '銀行支店名' ],//
    [ 'depositType', '預金種目' ],//
    [ 'accountNumber', '口座番号' ],//
    [ 'accountHolder', '口座名義' ],//


    [ 'details', '明細' ],
  ])

  const invoiceFormat = new Map([
    [ 'representative', '代表名' ],
    [ 'chief', '所長名' ],
    [ 'salesDate', '日付' ],
    [ 'salesDate_taxType', '日付_税区分' ],
    [ 'salesDate_taxRate', '日付_税率' ],
    [ 'salesDate_representative', '日付_代表名' ],
    [ 'salesDate_chief', '日付_所長名' ],
  ])
  const status = new Map([
    [ '0', 'ドラフト' ],
    [ '10', '承認中' ],
    [ '11', '承認済み' ],
    [ '12', '承認拒否' ],
    [ '20', '送付済み' ],
  ])

  return {
    itemLabel,
    searchLabel,
    columnLabel,
    invoiceFormat,
    status,
  }
}
