import {
  Rounding,
} from '@/graphql'

export interface DbPurchase {
  id: string
  id_number: number

  status: string

  issue_date: string | null
  issuer_id: string | null
  issuer_name: string | null
  issuer_kana: string | null

  order_date: string | null
  orderer_id: string | null
  orderer_name: string | null
  orderer_kana: string | null

  supplier_id: string | null
  supplier_name: string | null
  supplier_kana: string | null
  supplier_postcode: string | null
  supplier_address: string | null
  supplier_quotation_no: string | null
  supplier_registration_request_id: number | null
  //total_rounding: 1 | 2 | 3 | null
  //tax_rounding: 1 | 2 | 3 | null
  total_rounding: Rounding | null
  tax_rounding: Rounding | null

  delivery_destination: string | null
  delivery_address: string | null

  company_name: string | null
  
  base_id: string | null
  base_name: string | null
  base_postcode: string | null
  base_address: string | null
  base_tel: string | null
  base_fax: string | null

  apportionment_type: string | null
  apportionment_id: string | null
  apportionment_id_number: number | null
  apportionment_name: string | null
  apportionment_kana: string | null

  construction_period: string | null
  
  sales_representative_id: string | null
  sales_representative_name: string | null
  sales_representative_kana: string | null
  
  site_agent_id: string | null
  site_agent_name: string | null
  site_agent_kana: string | null
  
  delivery_deadline: string | null
  delivery_conditions: string | null
  payment_terms: string | null
  order_details: string | null
  note: string | null

  pre_order_cost: boolean

  amount_including_tax: bigint
  amount_excluding_tax: bigint
  consumption_tax_amount: bigint

  delivered_amount_including_tax: bigint
  delivered_amount_excluding_tax: bigint

  amount_balance_including_tax: bigint
  amount_balance_excluding_tax: bigint

  deleted_at: string | null
  updated_at: string
  updated_by_name: string
  updated_by_kana: string
  icon?: string
}

export default function purchases () {
  const purchases:Array<DbPurchase> = [{
    id: '1',
    id_number: 1,
    status: '0',
    issue_date: null,
    issuer_id: null,
    issuer_name: null,
    issuer_kana: null,
    order_date: '2023-02-04',
    orderer_id: null,
    orderer_name: null,
    orderer_kana: null,
    supplier_id: '1111',
    supplier_name: 'KSY',
    supplier_kana: 'ケイエスワイ',
    supplier_postcode: null,
    supplier_address: null,
    supplier_quotation_no: 'abc1234-1',
    supplier_registration_request_id: null,
    total_rounding: null,
    tax_rounding: null,
    delivery_destination: null,
    delivery_address: null,
    company_name: '安積電気通信興行株式会社',
    base_id: '1',
    base_name: '郡山本社',
    base_postcode: '963-0101',
    base_address: '福島県郡山市安積町日出山4丁目123',
    base_tel: '024-941-2211',
    base_fax: '024-941-3535',
    apportionment_type: '案件',
    apportionment_id: '1',
    apportionment_id_number: 1,
    apportionment_name: '案件１',
    apportionment_kana: 'アンケン１',
    construction_period: null,
    sales_representative_id: '2222',
    sales_representative_name: '営業 太郎',
    sales_representative_kana: 'エイギョウ タロウ',
    site_agent_id: '3333',
    site_agent_name: '現場 次郎',
    site_agent_kana: 'ゲンバ ジロウ',
    delivery_deadline: '最短',
    delivery_conditions: null,
    payment_terms: '末日締め　翌々月5日払い',
    order_details: '（省略）',
    note: null,
    pre_order_cost: false,
    amount_including_tax: 925650n,
    amount_excluding_tax: 841500n,
    consumption_tax_amount: 84150n,
    delivered_amount_including_tax: 0n,
    delivered_amount_excluding_tax: 0n,
    amount_balance_including_tax: 0n,
    amount_balance_excluding_tax: 0n,
    deleted_at: null,
    updated_at: '2023-02-14',
    updated_by_name: '最終更新者１',
    updated_by_kana: 'サイシュウコウシンシャ１',
  }, {
    id: '2',
    id_number: 2,
    status: '1',
    issue_date: '2022-01-11',
    issuer_id: '222',
    issuer_name: '〇〇〇 ☓☓☓',
    issuer_kana: 'マルマルマル バツバツ',
    order_date: '2022-01-12',
    orderer_id: '2221',
    orderer_name: '▼▼▼ △△△',
    orderer_kana: 'サンカクサンカクサンカク サンカクサンカクサンカク',
    supplier_id: '1111',
    supplier_name: 'KSY',
    supplier_kana: 'ケイエスワイ',
    supplier_postcode: null,
    supplier_address: null,
    supplier_quotation_no: 'abc1255',
    supplier_registration_request_id: null,
    total_rounding: null,
    tax_rounding: null,
    delivery_destination: null,
    delivery_address: null,
    company_name: '安積電気通信興行株式会社',
    base_id: '2',
    base_name: '仙台営業所',
    base_postcode: '984-0032',
    base_address: '宮城県仙台市若林区荒井1丁目11-4',
    base_tel: '022-349-8118',
    base_fax: '022-390-0678',
    apportionment_type: 'プロジェクト',
    apportionment_id: 'P38',
    apportionment_id_number: 38,
    apportionment_name: 'Pプロジェクト1011',
    apportionment_kana: 'プロジェクト1011',
    construction_period: '2000-01-01 ～ 2020-02-20',
    sales_representative_id: null,
    sales_representative_name: null,
    sales_representative_kana: null,
    site_agent_id: null,
    site_agent_name: null,
    site_agent_kana: null,
    delivery_deadline: '最短',
    delivery_conditions: null,
    payment_terms: '末日締め　翌々月5日払い',
    order_details: '（省略）',
    note: null,
    pre_order_cost: true,
    amount_including_tax: 2201n,
    amount_excluding_tax: 2001n,
    consumption_tax_amount: 200n,
    delivered_amount_including_tax: 0n,
    delivered_amount_excluding_tax: 0n,
    amount_balance_including_tax: 0n,
    amount_balance_excluding_tax: 0n,
    deleted_at: null,
    updated_at: '2020-12-13',
    updated_by_name: '最終更新者１',
    updated_by_kana: 'サイシュウコウシンシャ１',
    icon: 'caution',
  }, {
    id: '3',
    id_number: 3,
    status: '2',
    issue_date: null,
    issuer_id: null,
    issuer_name: null,
    issuer_kana: null,
    order_date: '2023-02-04',
    orderer_id: null,
    orderer_name: null,
    orderer_kana: null,
    supplier_id: '1111',
    supplier_name: 'KSY',
    supplier_kana: 'ケイエスワイ',
    supplier_postcode: null,
    supplier_address: null,
    supplier_quotation_no: 'abc1234-1',
    supplier_registration_request_id: null,
    total_rounding: null,
    tax_rounding: null,
    delivery_destination: null,
    delivery_address: null,
    company_name: '安積電気通信興行株式会社',
    base_id: '1',
    base_name: '郡山本社',
    base_postcode: '963-0101',
    base_address: '福島県郡山市安積町日出山4丁目123',
    base_tel: '024-941-2211',
    base_fax: '024-941-3535',
    apportionment_type: '案件',
    apportionment_id: '1',
    apportionment_id_number: 1,
    apportionment_name: '案件１',
    apportionment_kana: 'アンケン１',
    construction_period: null,
    sales_representative_id: '2222',
    sales_representative_name: '営業 太郎',
    sales_representative_kana: 'エイギョウ タロウ',
    site_agent_id: '3333',
    site_agent_name: '現場 次郎',
    site_agent_kana: 'ゲンバ ジロウ',
    delivery_deadline: '最短',
    delivery_conditions: null,
    payment_terms: '末日締め　翌々月5日払い',
    order_details: '（省略）',
    note: null,
    pre_order_cost: false,
    amount_including_tax: 925650n,
    amount_excluding_tax: 841500n,
    consumption_tax_amount: 84150n,
    delivered_amount_including_tax: 0n,
    delivered_amount_excluding_tax: 0n,
    amount_balance_including_tax: 0n,
    amount_balance_excluding_tax: 0n,
    deleted_at: null,
    updated_at: '2023-02-14',
    updated_by_name: '最終更新者１',
    updated_by_kana: 'サイシュウコウシンシャ１',
  }, {
    id: '4',
    id_number: 4,
    status: '3',
    issue_date: '2022-01-11',
    issuer_id: '222',
    issuer_name: '〇〇〇 ☓☓☓',
    issuer_kana: 'マルマルマル バツバツ',
    order_date: '2022-01-12',
    orderer_id: '2221',
    orderer_name: '▼▼▼ △△△',
    orderer_kana: 'サンカクサンカクサンカク サンカクサンカクサンカク',
    supplier_id: '1111',
    supplier_name: 'KSY',
    supplier_kana: 'ケイエスワイ',
    supplier_postcode: null,
    supplier_address: null,
    supplier_quotation_no: 'abc1255',
    supplier_registration_request_id: null,
    total_rounding: null,
    tax_rounding: null,
    delivery_destination: null,
    delivery_address: null,
    company_name: '安積電気通信興行株式会社',
    base_id: '2',
    base_name: '仙台営業所',
    base_postcode: '984-0032',
    base_address: '宮城県仙台市若林区荒井1丁目11-4',
    base_tel: '022-349-8118',
    base_fax: '022-390-0678',
    apportionment_type: 'プロジェクト',
    apportionment_id: 'P38',
    apportionment_id_number: 38,
    apportionment_name: 'Pプロジェクト1011',
    apportionment_kana: 'プロジェクト1011',
    construction_period: null,
    sales_representative_id: null,
    sales_representative_name: null,
    sales_representative_kana: null,
    site_agent_id: null,
    site_agent_name: null,
    site_agent_kana: null,
    delivery_deadline: '最短',
    delivery_conditions: null,
    payment_terms: '末日締め　翌々月5日払い',
    order_details: '（省略）',
    note: null,
    pre_order_cost: true,
    amount_including_tax: 2201n,
    amount_excluding_tax: 2001n,
    consumption_tax_amount: 200n,
    delivered_amount_including_tax: 0n,
    delivered_amount_excluding_tax: 0n,
    amount_balance_including_tax: 0n,
    amount_balance_excluding_tax: 0n,
    deleted_at: null,
    updated_at: '2020-12-13',
    updated_by_name: '最終更新者１',
    updated_by_kana: 'サイシュウコウシンシャ１',
    icon: 'caution',
  }, {
    id: '13',
    id_number: 13,
    status: '4',
    issue_date: null,
    issuer_id: null,
    issuer_name: null,
    issuer_kana: null,
    order_date: '2023-02-04',
    orderer_id: null,
    orderer_name: null,
    orderer_kana: null,
    supplier_id: '1111',
    supplier_name: 'KSY',
    supplier_kana: 'ケイエスワイ',
    supplier_postcode: null,
    supplier_address: null,
    supplier_quotation_no: 'abc1234-1',
    supplier_registration_request_id: null,
    total_rounding: null,
    tax_rounding: null,
    delivery_destination: null,
    delivery_address: null,
    company_name: '安積電気通信興行株式会社',
    base_id: '1',
    base_name: '郡山本社',
    base_postcode: '963-0101',
    base_address: '福島県郡山市安積町日出山4丁目123',
    base_tel: '024-941-2211',
    base_fax: '024-941-3535',
    apportionment_type: '案件',
    apportionment_id: '1',
    apportionment_id_number: 1,
    apportionment_name: '案件１',
    apportionment_kana: 'アンケン１',
    construction_period: null,
    sales_representative_id: '2222',
    sales_representative_name: '営業 太郎',
    sales_representative_kana: 'エイギョウ タロウ',
    site_agent_id: '3333',
    site_agent_name: '現場 次郎',
    site_agent_kana: 'ゲンバ ジロウ',
    delivery_deadline: '最短',
    delivery_conditions: null,
    payment_terms: '末日締め　翌々月5日払い',
    order_details: '（省略）',
    note: null,
    pre_order_cost: true,
    amount_including_tax: 925650n,
    amount_excluding_tax: 841500n,
    consumption_tax_amount: 84150n,
    delivered_amount_including_tax: 0n,
    delivered_amount_excluding_tax: 0n,
    amount_balance_including_tax: 0n,
    amount_balance_excluding_tax: 0n,
    deleted_at: null,
    updated_at: '2023-02-14',
    updated_by_name: '最終更新者１',
    updated_by_kana: 'サイシュウコウシンシャ１',
  }, {
    id: '15',
    id_number: 15,
    status: '10',
    issue_date: '2022-01-11',
    issuer_id: '222',
    issuer_name: '〇〇〇 ☓☓☓',
    issuer_kana: 'マルマルマル バツバツ',
    order_date: '2022-12-10',
    orderer_id: '2221',
    orderer_name: '▼▼▼ △△△',
    orderer_kana: 'サンカクサンカクサンカク サンカクサンカクサンカク',
    supplier_id: '1235',
    supplier_name: '〇△商会',
    supplier_kana: 'マルサンカクショウカイ',
    supplier_postcode: '111-2222',
    supplier_address: '東京都港区\n六本木160-1',
    supplier_quotation_no: 'bc125-225',
    supplier_registration_request_id: null,
    total_rounding: null,
    tax_rounding: null,
    delivery_destination: null,
    delivery_address: null,
    company_name: '安積電気通信興行株式会社',
    base_id: '2',
    base_name: '仙台営業所',
    base_postcode: '984-0032',
    base_address: '宮城県仙台市若林区荒井1丁目11-4',
    base_tel: '022-349-8118',
    base_fax: '022-390-0678',
    apportionment_type: '案件',
    apportionment_id: '1000',
    apportionment_id_number: 1000,
    apportionment_name: '案件１０００',
    apportionment_kana: 'アンケン１０００',
    construction_period: null,
    sales_representative_id: '2222',
    sales_representative_name: '営業 太郎',
    sales_representative_kana: 'エイギョウ タロウ',
    site_agent_id: '3333',
    site_agent_name: '現場 次郎',
    site_agent_kana: 'ゲンバ ジロウ',
    delivery_deadline: '最短',
    delivery_conditions: null,
    payment_terms: '末日締め　翌々月5日払い',
    order_details: '（省略）',
    note: null,
    pre_order_cost: false,
    amount_including_tax: 0n,
    amount_excluding_tax: 0n,
    consumption_tax_amount: 0n,
    delivered_amount_including_tax: 0n,
    delivered_amount_excluding_tax: 0n,
    amount_balance_including_tax: 0n,
    amount_balance_excluding_tax: 0n,
    deleted_at: null,
    updated_at: '2020-12-13',
    updated_by_name: '最終更新者１',
    updated_by_kana: 'サイシュウコウシンシャ１',
  }, {
    id: '26',
    id_number: 26,
    status: '20',
    issue_date: '2022-01-11',
    issuer_id: '222',
    issuer_name: '〇〇〇 ☓☓☓',
    issuer_kana: 'マルマルマル バツバツ',
    order_date: '2022-12-10',
    orderer_id: '2221',
    orderer_name: '▼▼▼ △△△',
    orderer_kana: 'サンカクサンカクサンカク サンカクサンカクサンカク',
    supplier_id: '1235',
    supplier_name: '〇△商会',
    supplier_kana: 'マルサンカクショウカイ',
    supplier_postcode: '111-2222',
    supplier_address: '東京都港区\n六本木160-1',
    supplier_quotation_no: 'bc125-225',
    supplier_registration_request_id: null,
    total_rounding: null,
    tax_rounding: null,
    delivery_destination: null,
    delivery_address: null,
    company_name: '安積電気通信興行株式会社',
    base_id: '2',
    base_name: '仙台営業所',
    base_postcode: '984-0032',
    base_address: '宮城県仙台市若林区荒井1丁目11-4',
    base_tel: '022-349-8118',
    base_fax: '022-390-0678',
    apportionment_type: '案件',
    apportionment_id: '1000',
    apportionment_id_number: 1000,
    apportionment_name: '案件１０００',
    apportionment_kana: 'アンケン１０００',
    construction_period: null,
    sales_representative_id: '2222',
    sales_representative_name: '営業 太郎',
    sales_representative_kana: 'エイギョウ タロウ',
    site_agent_id: '3333',
    site_agent_name: '現場 次郎',
    site_agent_kana: 'ゲンバ ジロウ',
    delivery_deadline: '最短',
    delivery_conditions: null,
    payment_terms: '末日締め　翌々月5日払い',
    order_details: '（省略）',
    note: null,
    pre_order_cost: false,
    amount_including_tax: 0n,
    amount_excluding_tax: 0n,
    consumption_tax_amount: 0n,
    delivered_amount_including_tax: 0n,
    delivered_amount_excluding_tax: 0n,
    amount_balance_including_tax: 0n,
    amount_balance_excluding_tax: 0n,
    deleted_at: null,
    updated_at: '2020-12-13',
    updated_by_name: '最終更新者１',
    updated_by_kana: 'サイシュウコウシンシャ１',
  }]

  return {
    purchases,
  }
}
