export const tableName = '売上'

export default function journalSales () {
  const columnLabel = new Map([
    [ 'totalingMonth', '集計月' ],
    [ 'status', 'ステータス' ],
    [ 'amount', '売上金額［円］' ],
    [ 'generatedDate', 'CSV生成日時' ],
    [ 'generator', 'CSV生成者' ],
    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],
  ])

  const status = new Map([
    [ '0', '生成済み' ],
    [ '10', '修正あり' ],
  ])

  return {
    columnLabel,
    status,
  }
}
