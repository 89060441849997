export default function address () {
  const postcodeWithHyphen = (code: string) => {
    if (code.length === 7) return `${code.substring(0, 3)}-${code.substring(3)}`
    else return code
  }

  return {
    postcodeWithHyphen,
  }
}
