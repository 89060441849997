import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewSort,
} from '@/const/component/listView'
import { CheckSelectorItem } from '@/const/component/checkSelector'
import ConstContracts from '@/const/transaction/contracts'

const constTable = ConstContracts()

const baseListColumns: ListViewColumn[] = [{
  id: 'contractInformation',
  text: constTable.columnLabel.get('contractInformation'),
  align: 'center',
  columns: [{
    id: 'processStatus',
    text: constTable.columnLabel.get('processStatus'),
    displayField: 'process_status',
    sortField: 'process_status',
    align: 'center',
    width: '100px',
  }, {
    id: 'id',
    text: constTable.columnLabel.get('id'),
    displayField: 'id',
    sortField: 'id_number',
    align: 'right',
  }, {
    id: 'projectId',
    text: constTable.columnLabel.get('projectId'),
    displayField: 'project_id',
    sortField: 'project_id_number',
    align: 'right',
  }, {
    id: 'name',
    text: constTable.columnLabel.get('name'),
    displayField: 'name',
    sortField: 'kana',
  }, {
    id: 'contractType',
    text: constTable.columnLabel.get('contractType'),
    displayField: 'contract_type',
    sortField: 'contract_type',
    align: 'center',
    width: '20px',
  }, {
    id: 'publicPrivateWorksType',
    text: constTable.columnLabel.get('publicPrivateWorksType'),
    displayField: 'public_private_works_type',
    sortField: 'public_private_works_type',
    align: 'center',
    width: '20px',
  }, {
    id: 'contractorType',
    text: constTable.columnLabel.get('contractorType'),
    displayField: 'contractor_type',
    sortField: 'contractor_type',
    align: 'center',
    width: '20px',
  }, {
    id: 'contractCategory',
    text: constTable.columnLabel.get('contractCategory'),
    displayField: 'contract_category',
    sortField: 'contract_category',
  }, {
    id: 'constructionType',
    text: constTable.columnLabel.get('constructionType'),
    displayField: 'construction_type',
    sortField: 'construction_type',
  }, {
    id: 'division',
    text: constTable.columnLabel.get('division'),
    displayField: 'division_name',
    sortField: 'division_disp_order',
  }, {
    id: 'base',
    text: constTable.columnLabel.get('base'),
    displayField: 'base_name',
    sortField: 'base_disp_order',
  }, {
    id: 'salesRep',
    text: constTable.columnLabel.get('salesRep'),
    displayField: 'sales_rep_name',
    sortField: 'sales_rep_kana',
  }, {
    id: 'constructionLocation',
    text: constTable.columnLabel.get('constructionLocation'),
    displayField: 'construction_location',
    sortField: 'construction_location',
  }, {
    id: 'siteAgent',
    text: constTable.columnLabel.get('siteAgent'),
    displayField: 'site_agent_name',
    sortField: 'site_agent_kana',
  }, {
    id: 'client',
    text: constTable.columnLabel.get('client'),
    displayField: 'client_name',
    sortField: 'client_kana',
  }, {
    id: 'billingTarget',
    text: constTable.columnLabel.get('billingTarget'),
    displayField: 'billing_target_name',
    sortField: 'billing_target_kana',
  }, {
    id: 'contractPeriodStartDate',
    text: constTable.columnLabel.get('contractPeriodStartDate'),
    displayField: 'contract_period_start_date',
    sortField: 'contract_period_start_date',
  }, {
    id: 'contractPeriodEndDate',
    text: constTable.columnLabel.get('contractPeriodEndDate'),
    displayField: 'contract_period_end_date',
    sortField: 'contract_period_end_date',
  }, {
    id: 'note',
    text: constTable.columnLabel.get('note'),
    displayField: 'note',
    sortField: 'note',
  }, {
    id: 'notCounted',
    text: constTable.columnLabel.get('notCounted'),
    displayField: 'not_counted',
    sortField: 'not_counted',
    displayConvert: 'check',
    align: 'center',
    width: '100px',
  }, {
    id: 'editLock',
    text: constTable.columnLabel.get('editLock'),
    displayField: 'edit_lock',
    sortField: 'edit_lock',
    displayConvert: 'check',
    align: 'center',
    width: '100px',
  }, {
    id: 'inquiryDate',
    text: constTable.columnLabel.get('inquiryDate'),
    displayField: 'inquiry_date',
    sortField: 'inquiry_date',
  }, {
    id: 'lostOrderDate',
    text: constTable.columnLabel.get('lostOrderDate'),
    displayField: 'lost_order_date',
    sortField: 'lost_order_date',
  }],
}, {
  id: 'contractInformation_noInvoice',
  text: constTable.columnLabel.get('contractInformation'),
  align: 'center',
  columns: [{
    id: 'invoiceStatus',
    text: constTable.columnLabel.get('invoiceStatus'),
    displayField: 'invoice_status',
    sortField: 'invoice_status',
    align: 'center',
    width: '100px',
  }, {
    id: 'processStatus',
    text: constTable.columnLabel.get('processStatus'),
    displayField: 'process_status',
    sortField: 'process_status',
    align: 'center',
    width: '100px',
  }, {
    id: 'id',
    text: constTable.columnLabel.get('id'),
    displayField: 'id',
    sortField: 'id_number',
    align: 'right',
  }, {
    id: 'projectId',
    text: constTable.columnLabel.get('projectId'),
    displayField: 'project_id',
    sortField: 'project_id_number',
    align: 'right',
  }, {
    id: 'name',
    text: constTable.columnLabel.get('name'),
    displayField: 'name',
    sortField: 'kana',
  }, {
    id: 'contractType',
    text: constTable.columnLabel.get('contractType'),
    displayField: 'contract_type',
    sortField: 'contract_type',
    align: 'center',
    width: '20px',
  }, {
    id: 'publicPrivateWorksType',
    text: constTable.columnLabel.get('publicPrivateWorksType'),
    displayField: 'public_private_works_type',
    sortField: 'public_private_works_type',
    align: 'center',
    width: '20px',
  }, {
    id: 'contractorType',
    text: constTable.columnLabel.get('contractorType'),
    displayField: 'contractor_type',
    sortField: 'contractor_type',
    align: 'center',
    width: '20px',
  }, {
    id: 'contractCategory',
    text: constTable.columnLabel.get('contractCategory'),
    displayField: 'contract_category',
    sortField: 'contract_category',
  }, {
    id: 'constructionType',
    text: constTable.columnLabel.get('constructionType'),
    displayField: 'construction_type',
    sortField: 'construction_type',
  }, {
    id: 'division',
    text: constTable.columnLabel.get('division'),
    displayField: 'division_name',
    sortField: 'division_disp_order',
  }, {
    id: 'base',
    text: constTable.columnLabel.get('base'),
    displayField: 'base_name',
    sortField: 'base_disp_order',
  }, {
    id: 'salesRep',
    text: constTable.columnLabel.get('salesRep'),
    displayField: 'sales_rep_name',
    sortField: 'sales_rep_kana',
  }, {
    id: 'constructionLocation',
    text: constTable.columnLabel.get('constructionLocation'),
    displayField: 'construction_location',
    sortField: 'construction_location',
  }, {
    id: 'siteAgent',
    text: constTable.columnLabel.get('siteAgent'),
    displayField: 'site_agent_name',
    sortField: 'site_agent_kana',
  }, {
    id: 'client',
    text: constTable.columnLabel.get('client'),
    displayField: 'client_name',
    sortField: 'client_kana',
  }, {
    id: 'billingTarget',
    text: constTable.columnLabel.get('billingTarget'),
    displayField: 'billing_target_name',
    sortField: 'billing_target_kana',
  }, {
    id: 'contractPeriodStartDate',
    text: constTable.columnLabel.get('contractPeriodStartDate'),
    displayField: 'contract_period_start_date',
    sortField: 'contract_period_start_date',
  }, {
    id: 'contractPeriodEndDate',
    text: constTable.columnLabel.get('contractPeriodEndDate'),
    displayField: 'contract_period_end_date',
    sortField: 'contract_period_end_date',
  }, {
    id: 'note',
    text: constTable.columnLabel.get('note'),
    displayField: 'note',
    sortField: 'note',
  }, {
    id: 'notCounted',
    text: constTable.columnLabel.get('notCounted'),
    displayField: 'not_counted',
    sortField: 'not_counted',
    displayConvert: 'check',
    align: 'center',
    width: '100px',
  }, {
    id: 'editLock',
    text: constTable.columnLabel.get('editLock'),
    displayField: 'edit_lock',
    sortField: 'edit_lock',
    displayConvert: 'check',
    align: 'center',
    width: '100px',
  }, {
    id: 'inquiryDate',
    text: constTable.columnLabel.get('inquiryDate'),
    displayField: 'inquiry_date',
    sortField: 'inquiry_date',
  }, {
    id: 'lostOrderDate',
    text: constTable.columnLabel.get('lostOrderDate'),
    displayField: 'lost_order_date',
    sortField: 'lost_order_date',
  }],  
}, {
  id: 'prospectInformation',
  text: constTable.columnLabel.get('prospectInformation'),
  align: 'center',
  columns: [{
    id: 'ordersAccuracy',
    text: constTable.columnLabel.get('ordersAccuracy'),
    displayField: 'orders_accuracy',
    sortField: 'orders_accuracy',
    align: 'center',
    width: '100px',
  }, {
    id: 'destinationTarget',
    text: constTable.columnLabel.get('destinationTarget'),
    displayField: 'destination_target_name',
    sortField: 'destination_target_kana',
  }, {
    id: 'predictedContractJobAmount',
    text: constTable.columnLabel.get('predictedContractJobAmount'),
    displayField: 'predicted_contract_job_Amount',
    sortField: 'predicted_contract_job_Amount',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'estimatedMaterialCost',
    text: constTable.columnLabel.get('estimatedMaterialCost'),
    displayField: 'estimated_material_cost',
    sortField: 'estimated_material_cost',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'estimatedOutsourcingCost',
    text: constTable.columnLabel.get('estimatedOutsourcingCost'),
    displayField: 'estimated_outsourcing_cost',
    sortField: 'estimated_outsourcing_cost',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'estimatedExpenseCost',
    text: constTable.columnLabel.get('estimatedExpenseCost'),
    displayField: 'estimated_expense_cost',
    sortField: 'estimated_expense_cost',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'estimatedOthersCost',
    text: constTable.columnLabel.get('estimatedOthersCost'),
    displayField: 'estimated_others_cost',
    sortField: 'estimated_others_cost',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'estimatedLaborCost',
    text: constTable.columnLabel.get('estimatedLaborCost'),
    displayField: 'estimated_labor_cost',
    sortField: 'estimated_labor_cost',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'targetMarginalProfit',
    text: constTable.columnLabel.get('targetMarginalProfit'),
    displayField: 'target_marginal_profit',
    sortField: 'target_marginal_profit',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'targetMarginalProfitRatio',
    text: constTable.columnLabel.get('targetMarginalProfitRatio'),
    displayField: 'target_marginal_profit_ratio',
    sortField: 'target_marginal_profit_ratio',
    align: 'right',
  }, {
    id: 'targetProductivity',
    text: constTable.columnLabel.get('targetProductivity'),
    displayField: 'target_productivity',
    sortField: 'target_productivity',
    align: 'right',
  }, {
    id: 'targetGrossProfit',
    text: constTable.columnLabel.get('targetGrossProfit'),
    displayField: 'target_gross_profit',
    sortField: 'target_gross_profit',
    align: 'right',
  }, {
    id: 'estimatedSalesDate',
    text: constTable.columnLabel.get('estimatedSalesDate'),
    displayField: 'estimated_sales_date',
    sortField: 'estimated_sales_date',
  }, {
    id: 'beforeCost',
    text: constTable.columnLabel.get('beforeCost'),
    displayField: 'before_Cost',
    sortField: 'before_Cost',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }],
}, {
  id: 'estimateInformation',
  text: constTable.columnLabel.get('estimateInformation'),
  align: 'center',
  columns: [{
    id: 'estimateId',
    text: constTable.columnLabel.get('estimateId'),
    displayField: 'estimate_id',
    sortField: 'estimate_id_number',
    align: 'right',
  }, {
    id: 'ordersAccuracy',
    text: constTable.columnLabel.get('ordersAccuracy'),
    displayField: 'orders_accuracy',
    sortField: 'orders_accuracy',
    align: 'center',
    width: '100px',
  }, {
    id: 'contractAmountBudget',
    text: constTable.columnLabel.get('contractAmountBudget'),
    displayField: 'contract_amount_budget',
    sortField: 'contract_amount_budget',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'materialCostBudget',
    text: constTable.columnLabel.get('materialCostBudget'),
    displayField: 'material_cost_budget',
    sortField: 'material_cost_budget',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'outsourcingCostBudget',
    text: constTable.columnLabel.get('outsourcingCostBudget'),
    displayField: 'outsourcing_cost_budget',
    sortField: 'outsourcing_cost_budget',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'expenseCostBudget',
    text: constTable.columnLabel.get('expenseCostBudget'),
    displayField: 'expense_cost_budget',
    sortField: 'expense_cost_budget',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'othersCostBudget',
    text: constTable.columnLabel.get('othersCostBudget'),
    displayField: 'others_cost_budget',
    sortField: 'others_cost_budget',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'laborCostBudget',
    text: constTable.columnLabel.get('laborCostBudget'),
    displayField: 'labor_cost_budget',
    sortField: 'labor_cost_budget',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'marginalProfitBudget',
    text: constTable.columnLabel.get('marginalProfitBudget'),
    displayField: 'marginal_profit_budget',
    sortField: 'marginal_profit_budget',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'marginalProfitRatioBudget',
    text: constTable.columnLabel.get('marginalProfitRatioBudget'),
    displayField: 'marginal_profit_ratio_budget',
    sortField: 'marginal_profit_ratio_budget',
    align: 'right',
  }, {
    id: 'productivityBudget',
    text: constTable.columnLabel.get('productivityBudget'),
    displayField: 'productivity_budget',
    sortField: 'productivity_budget',
    align: 'right',
  }, {
    id: 'grossProfitBudget',
    text: constTable.columnLabel.get('grossProfitBudget'),
    displayField: 'gross_profit_budget',
    sortField: 'gross_profit_budget',
    align: 'right',
  }, {
    id: 'estimateDate',
    text: constTable.columnLabel.get('estimateDate'),
    displayField: 'estimate_date',
    sortField: 'estimate_date',
  }, {
    id: 'expectedSalesDate',
    text: constTable.columnLabel.get('expectedSalesDate'),
    displayField: 'expected_sales_date',
    sortField: 'expected_sales_date',
  }],
}, {
  id: 'orderInformation',
  text: constTable.columnLabel.get('orderInformation'),
  align: 'center',
  columns: [{
    id: 'constructionRep',
    text: constTable.columnLabel.get('constructionRep'),
    displayField: 'construction_rep_name',
    sortField: 'construction_rep_kana',
  }, {
    id: 'constructionTarget',
    text: constTable.columnLabel.get('constructionTarget'),
    displayField: 'construction_target_name',
    sortField: 'construction_target_kana',
  }, {
    id: 'contractJobAmount',
    text: constTable.columnLabel.get('contractJobAmount'),
    displayField: 'contract_job_amount',
    sortField: 'contract_job_amount',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'marginalProfit',
    text: constTable.columnLabel.get('marginalProfit'),
    displayField: 'marginal_profit',
    sortField: 'marginal_profit',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'marginalProfitRatio',
    text: constTable.columnLabel.get('marginalProfitRatio'),
    displayField: 'marginal_profit_ratio',
    sortField: 'marginal_profit_ratio',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'marginalProfitBudgetBalance',
    text: constTable.columnLabel.get('marginalProfitBudgetBalance'),
    displayField: 'marginal_profit_budget_balance',
    sortField: 'marginal_profit_budget_balance',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'productivity',
    text: constTable.columnLabel.get('productivity'),
    displayField: 'productivity',
    sortField: 'productivity',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'grossProfit',
    text: constTable.columnLabel.get('grossProfit'),
    displayField: 'gross_profit',
    sortField: 'gross_profit',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'materialCost',
    text: constTable.columnLabel.get('materialCost'),
    displayField: 'material_cost',
    sortField: 'material_cost',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'materialCostBalance',
    text: constTable.columnLabel.get('materialCostBalance'),
    displayField: 'material_cost_balance',
    sortField: 'material_cost_balance',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'outsourcingCost',
    text: constTable.columnLabel.get('outsourcingCost'),
    displayField: 'outsourcing_cost',
    sortField: 'outsourcing_cost',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'outsourcingCostBalance',
    text: constTable.columnLabel.get('outsourcingCostBalance'),
    displayField: 'outsourcing_cost_balance',
    sortField: 'outsourcing_cost_balance',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'expenseCost',
    text: constTable.columnLabel.get('expenseCost'),
    displayField: 'expense_cost',
    sortField: 'expense_cost',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'expenseCostBalance',
    text: constTable.columnLabel.get('expenseCostBalance'),
    displayField: 'expense_cost_balance',
    sortField: 'expense_cost_balance',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'othersCost',
    text: constTable.columnLabel.get('othersCost'),
    displayField: 'others_cost',
    sortField: 'others_cost',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'othersCostBalance',
    text: constTable.columnLabel.get('othersCostBalance'),
    displayField: 'others_cost_balance',
    sortField: 'others_cost_balance',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'laborCost',
    text: constTable.columnLabel.get('laborCost'),
    displayField: 'labor_cost',
    sortField: 'labor_cost',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'laborCostBalance',
    text: constTable.columnLabel.get('laborCostBalance'),
    displayField: 'labor_cost_balance',
    sortField: 'labor_cost_balance',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'purchaseAmountBalance',
    text: constTable.columnLabel.get('purchaseAmountBalance'),
    displayField: 'purchase_amount_balance',
    sortField: 'purchase_amount_balance',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'orderDate',
    text: constTable.columnLabel.get('orderDate'),
    displayField: 'order_date',
    sortField: 'order_date',
  }, {
    id: 'completeDate',
    text: constTable.columnLabel.get('completeDate'),
    displayField: 'complete_date',
    sortField: 'complete_date',
  }, {
    id: 'billDate',
    text: constTable.columnLabel.get('billDate'),
    displayField: 'billing_date',
    sortField: 'billing_date',
  }, {
    id: 'salesDate',
    text: constTable.columnLabel.get('salesDate'),
    displayField: 'sales_date',
    sortField: 'sales_date',
  }, {
    id: 'autoCompleteDate',
    text: constTable.columnLabel.get('autoCompleteDate'),
    displayField: 'auto_complete_date',
    sortField: 'auto_complete_date',
  }],
}]
const standardListColumns = {
  project: [
    'contractInformation',
    'prospectInformation',
    'estimateInformation',
    'orderInformation',
  ],
  general: [
    'contractInformation',
    'prospectInformation',
    'estimateInformation',
    'orderInformation',
  ],
  prospect: [
    'contractInformation',
    'prospectInformation',
    'estimateInformation',
    'orderInformation',
  ],
  progress: [
    'contractInformation',
    'prospectInformation',
    'estimateInformation',
    'orderInformation',
  ],
  completed: [
    'contractInformation',
    'prospectInformation',
    'estimateInformation',
    'orderInformation',
  ],
  noInvoice: [
    'contractInformation_noInvoice',
    'prospectInformation',
    'estimateInformation',
    'orderInformation',
  ],
}

const listIcon: ListViewIcon = {
  caution: {
    name: 'triangle-exclamation',
    classes: [
      '--error',
    ]
  },
}

export default function contract () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus
  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'id_number',
    order: {
      'id_number': 1,
    },
  })
  
  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }
  const getListColumns = (groupItems?: CheckSelectorItem[]) => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      let item = baseListColumns.find(el => el.id === id)
      if (item && groupItems) {
        const gi = groupItems.find(el => el.id === id)
        if (!gi || !gi.value) item = undefined
      }
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    //listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
