export const tableName = '部門'

export default function divisions () {
  const columnLabel = new Map([
    [ 'id', 'ID' ],
    [ 'name', '部門名' ],
    [ 'displayOrder', '表示順' ],
    [ 'directDivision', '直接部門' ],
  ])

  return {
    columnLabel,
  }
}
