export const tableName = '入金'

export default function deposits () {
  const itemLabel = new Map([
    [ 'paymentDate', '入金日' ],
    [ 'billingTargetCode', '請求先コード' ], //id, name, kana
    [ 'billingTarget', '請求先' ], //id, name, kana
    //[ 'numberOfDeposits', '月次入金回数' ],
    [ 'paymentMethod', '入金方法' ],
    //[ 'paymentMethodType', '入金方法種別' ],
    [ 'depositAmount', '入金額［円］' ],
    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],
  ])
  const searchLabel = new Map([
    [ 'billingTargetCode', '請求先コード' ],
    [ 'billingTarget', '請求先' ],
  ])
  const columnLabel = new Map([
    [ 'paymentDate', '入金日' ],
    [ 'billingTargetCode', '請求先コード' ], //id, name, kana
    [ 'billingTarget', '請求先' ], //id, name, kana
    //[ 'numberOfDeposits', '月次入金回数' ],
    [ 'paymentMethod', '入金方法' ],
    //[ 'paymentMethodType', '入金方法種別' ],
    [ 'depositAmount', '入金額［円］' ],
    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ],
  ])

  const paymentMethod = new Map([
    [ 'transfer', '振込' ],
    [ 'promissoryNote', '手形' ],
  ])

  return {
    itemLabel,
    searchLabel,
    columnLabel,
    paymentMethod,
  }
}
