export const tableName = '発注仕入'

export default function purchaseDeliveries () {
  const itemLabel = new Map([
    [ 'deliveryInformation', '仕入情報' ],

    [ 'id', '仕入番号' ],
    [ 'deliveryDate', '仕入日' ],

    [ 'supplier', '仕入先' ],
    [ 'deliverySlipNumber', '納品書番号' ],
    [ 'memo', 'メモ' ],

    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ], //id, name, kana


    [ 'orderInformation', '発注情報' ],

    [ 'purchaseId', '発注番号' ],
    [ 'base', '拠点' ],
    [ 'orderer', '発注者' ], //id, name, kana

    [ 'apportionment', '原価按分' ],
    [ 'apportionmentType', '按分対象' ],
    [ 'apportionmentId', '案件番号' ],
    [ 'apportionmentName', '案件名' ],
    [ 'constructionPeriod', '工期' ],
    [ 'salesRepresentative', '営業担当者' ],
    [ 'siteAgent', '現場代理人' ],


    [ 'paymentInformation', '支払情報' ],

    [ 'closingDate', '締め日' ],
    [ 'closed', '締め済' ],
    [ 'paymentDate', '支払予定日' ],


    [ 'costInformation', '原価情報' ],

    [ 'preOrderCost', '受注前原価' ],

    [ 'amountIncludingTax', '税込金額［円］' ],
    [ 'amountExcludingTax', '税抜金額［円］' ],
    [ 'consumptionTaxAmount', '消費税額［円］' ],
  ])
  const searchLabel = new Map([
    [ 'id', '仕入番号' ],
    //[ 'deliveryDate', '仕入日' ],

    [ 'supplierCode', '仕入先コード' ],
    [ 'supplier', '仕入先' ],
    [ 'deliverySlipNumber', '納品書番号' ],
    [ 'memo', 'メモ' ],

    //[ 'updatedAt', '最終更新日時' ],
    //[ 'updatedBy', '最終更新者' ], //id, name, kana

    [ 'purchaseId', '発注番号' ],
    //[ 'base', '拠点' ],
    //[ 'orderer', '発注者' ], //id, name, kana

    [ 'apportionmentId', '案件番号' ],
    [ 'apportionmentName', '案件名' ],
    //[ 'constructionPeriod', '工期' ],
    //[ 'salesRepresentative', '営業担当者' ],
    //[ 'siteAgent', '現場代理人' ],

    //[ 'closingDate', '締め日' ],
    //[ 'paymentDate', '支払予定日' ],

    //[ 'amountIncludingTax', '税込金額' ],
    //[ 'amountExcludingTax', '税抜金額' ],
    //[ 'consumptionTaxAmount', '消費税額' ],
  ])
  const columnLabel = new Map([
    [ 'deliveryInformation', '仕入情報' ],

    [ 'id', '仕入番号' ],
    [ 'deliveryDate', '仕入日' ],

    [ 'supplier', '仕入先' ],
    [ 'deliverySlipNumber', '納品書番号' ],
    [ 'memo', 'メモ' ],

    [ 'updatedAt', '最終更新日時' ],
    [ 'updatedBy', '最終更新者' ], //id, name, kana


    [ 'orderInformation', '発注情報' ],

    [ 'purchaseId', '発注番号' ],
    [ 'base', '拠点' ],
    [ 'orderer', '発注者' ], //id, name, kana

    [ 'apportionmentId', '案件番号' ],
    [ 'apportionmentName', '案件名' ],
    [ 'constructionPeriod', '工期' ],
    [ 'salesRepresentative', '営業担当者' ],
    [ 'siteAgent', '現場代理人' ],


    [ 'paymentInformation', '支払情報' ],

    [ 'closingDate', '締め日' ],
    [ 'paymentDate', '支払予定日' ],
    [ 'closed', '締め済' ],


    [ 'costInformation', '原価情報' ],

    [ 'preOrderCost', '受注前原価' ],

    [ 'amountIncludingTax', '税込金額［円］' ],
    [ 'amountExcludingTax', '税抜金額［円］' ],
    [ 'consumptionTaxAmount', '消費税額［円］' ],
  ])

  return {
    itemLabel,
    searchLabel,
    columnLabel,
  }
}