import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstClients from '@/const/transaction/master/clients'
import Constant from '@/const/common/constant'

const constTable = ConstClients()

const baseListColumns: ListViewColumn[] = [{
  id: 'code',
  text: constTable.columnLabel.get('code'),
  displayField: 'code',
  sortField: 'code',
  align: 'right',
}, {
  id: 'name',
  text: constTable.columnLabel.get('name'),
  displayField: 'name',
  sortField: 'kana',
}, {
  id: 'kana',
  text: constTable.columnLabel.get('kana'),
  displayField: 'kana',
  sortField: 'kana',
}, {
  id: 'official_company_name',
  text: constTable.columnLabel.get('official_company_name'),
  displayField: 'official_company_name',
  sortField: 'official_company_name',
}, {
  id: 'official_branch_name',
  text: constTable.columnLabel.get('official_branch_name'),
  displayField: 'official_branch_name',
  sortField: 'official_branch_name',
}, {
  id: 'honorific_title',
  text: constTable.columnLabel.get('honorific_title'),
  displayField: 'honorific_title',
  sortField: 'honorific_title',
}, {
  id: 'postcode',
  text: constTable.columnLabel.get('postcode'),
  displayField: 'postcode',
  sortField: 'postcode',
}, {
  id: 'address',
  text: constTable.columnLabel.get('address'),
  displayFields: [
    'address0',
    'address1',
  ],
  sortField: 'address0 address1',
}, {
  id: 'closing_date',
  text: constTable.columnLabel.get('closing_date'),
  displayField: 'closing_date',
  sortField: 'closing_date',
  align: 'right',
}, {
  id: 'collection_month',
  text: constTable.columnLabel.get('collection_month'),
  displayField: 'collection_month',
  sortField: 'collection_month',
  align: 'center',
  width: '100px',
}, {
  id: 'collection_date',
  text: constTable.columnLabel.get('collection_date'),
  displayField: 'collection_date',
  sortField: 'collection_date',
  align: 'right',
}, {
  id: 'default_value',
  text: constTable.columnLabel.get('default_value'),
  align: 'center',
  columns: [{
    id: 'public_private_works_type',
    text: constTable.columnLabel.get('public_private_works_type'),
    displayField: 'public_private_works_type',
    sortField: 'public_private_works_type',
    align: 'center',
    width: '100px',
  }, {
    id: 'tax_rounding',
    text: constTable.columnLabel.get('tax_rounding'),
    displayField: 'tax_rounding',
    sortField: 'tax_rounding',
    align: 'center',
    width: '100px',
  }, {
    id: 'amount_rounding',
    text: constTable.columnLabel.get('amount_rounding'),
    displayField: 'amount_rounding',
    sortField: 'amount_rounding',
    align: 'center',
    width: '100px',
  }],
}, {
  id: 'updated_at',
  text: constTable.columnLabel.get('updated_at'),
  displayField: 'updated_at',
  sortField: 'updated_at',
}, {
  id: 'updated_by',
  text: constTable.columnLabel.get('updated_by'),
  displayField: 'updated_by_name',
  sortField: 'updated_by_kana',
}]

const standardListColumns = {
  client: [
    'code',
    'name',
    'official_company_name',
    'official_branch_name',
    'honorific_title',
    'postcode',
    'address',
    'public_private_works_type',
    'closing_date',
    'collection_month',
    'collection_date',
    'default_value',
    'updated_at',
    'updated_by',
  ],
}

const constant = Constant()
const publicPrivateWorksTypeColor =  '#FFFFFF'

const listIcon: ListViewIcon = {
}
const listConverter: ListViewConverter = {
  collection_month: {
    '0': {
      text: '当月',
      type: 'text',
    },
    '1': {
      text: '翌月',
      type: 'text',
    },
    '2': {
      text: '翌々月',
      type: 'text',
    },
  },
  public_private_works_type: {
    '1': {
      text: '公',
      type: 'rectangle',
      style: {
        background: constant.publicPrivateWorks.public.color,
        color: publicPrivateWorksTypeColor,
      },
    },
    '2': {
      text: '民',
      type: 'rectangle',
      style: {
        background: constant.publicPrivateWorks.private.color,
        color: publicPrivateWorksTypeColor,
      },
    },
  },
  tax_rounding: {
    '1': {
      text: '切り捨て',
      type: 'text',
    },
    '2': {
      text: '四捨五入',
      type: 'text',
    },
    '3': {
      text: '切り上げ',
      type: 'text',
    },
  },
  amount_rounding: {
    '1': {
      text: '切り捨て',
      type: 'text',
    },
    '2': {
      text: '四捨五入',
      type: 'text',
    },
    '3': {
      text: '切り上げ',
      type: 'text',
    },
  },
}

export default function client () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'code',
    order: {
      'code': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
