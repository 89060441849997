
export default function listIcon () {
  const caution = {
    name: 'triangle-exclamation',
    classes: [
      '--error',
    ],
  }

  return {
    caution,
  }
}