import { InjectionKey } from 'vue'
import { createStore, Store } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { ApiToken } from '@/graphql'

export interface ApiTokenStore {
  apiToken?: ApiToken
}

export const apiTokenStoreKey: InjectionKey<Store<ApiTokenStore>> = Symbol('apiTokenStoreKey')

export const apiTokenStore = createStore<ApiTokenStore>({
  plugins: [createPersistedState({
    key: 'apiTokenStore'
  })],

  state: {
    apiToken: undefined
  },

  mutations: {
    setApiToken (state, apiToken: ApiToken) {
      state.apiToken = apiToken
    }
  },

  actions: {
    setApiToken ({ commit }, apiToken: ApiToken) {
      commit('setApiToken', apiToken)
    }
  },

  getters: {
    token: (state) => state.apiToken?.token
  },
});
