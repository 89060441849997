import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstBillDetails from '@/const/transaction/billDetails'

const constBillDetails = ConstBillDetails()

const baseListColumns: ListViewColumn[] = [{
  id: 'details_line_no',
  text: constBillDetails.columnLabel.get('detailsLineNo'),
  displayField: 'details_line_no',
  align: 'right',
}, {
  id: 'description',
  text: constBillDetails.columnLabel.get('description'),
  displayField: 'description',
  multipleLines: true,
  colspan: {
    key: 'detail_type',
    value: 2,
    count: 8,
  },
}, {
  id: 'quantity',
  text: constBillDetails.columnLabel.get('quantity'),
  displayField: 'quantity',
  displayConvert: 'decimalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'unit',
  text: constBillDetails.columnLabel.get('unit'),
  displayField: 'unit',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'unit_price',
  text: constBillDetails.columnLabel.get('unitPrice'),
  displayField: 'unit_price',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'consumption_tax_rate',
  text: constBillDetails.columnLabel.get('consumptionTaxRate'),
  displayField: 'consumption_tax_rate',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'consumption_tax_amount',
  text: constBillDetails.columnLabel.get('consumptionTaxAmount'),
  displayField: 'consumption_tax_amount',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'amount_excluding_tax',
  text: constBillDetails.columnLabel.get('amountExcludingTax'),
  displayField: 'amount_excluding_tax',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'amount_including_tax',
  text: constBillDetails.columnLabel.get('amountIncludingTax'),
  displayField: 'amount_including_tax',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}]

const standardListColumns = {
  bill: [
    'details_line_no',
    'description',
    'quantity',
    'unit',
    'unit_price',
    'consumption_tax_rate',
    'consumption_tax_amount',
    'amount_excluding_tax',
    'amount_including_tax',
  ],
  general: [
    'details_line_no',
    'description',
    'quantity',
    'unit',
    'unit_price',
    'consumption_tax_rate',
    'consumption_tax_amount',
    'amount_excluding_tax',
    'amount_including_tax',
  ],
  draft: [
    'details_line_no',
    'description',
    'quantity',
    'unit',
    'unit_price',
    'consumption_tax_rate',
    'consumption_tax_amount',
    'amount_excluding_tax',
    'amount_including_tax',
  ],
  information: [
    'details_line_no',
    'description',
    'quantity',
    'unit',
    'unit_price',
    'consumption_tax_rate',
    'consumption_tax_amount',
    'amount_excluding_tax',
    'amount_including_tax',
  ],
}

const listConverter: ListViewConverter = {
}

export default function billDetail () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'details_line_no',
    order: {
      'details_line_no': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
