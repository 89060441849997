export interface DbTaxType {
  id: string
  display_order: number
  name: string
  rate: number
  updated_at: string
  updated_by_name: string
  updated_by_kana: string
}

export default function taxTypes () {
  const taxTypes: Array<DbTaxType> = [{
    id: '1',
    display_order: 1,
    name: '10%',
    rate: 10,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '2',
    display_order: 2,
    name: '8%',
    rate: 8,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '3',
    display_order: 3,
    name: '軽減 8%',
    rate: 8,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }]

  return {
    taxTypes,
  }
}
