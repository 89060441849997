import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import { PadViewItem } from '@/const/component/padView'
import ConstPlans from '@/const/transaction/plans'

export default function pad () {
  const constTable = ConstPlans()

  const statusItems = computed(() => {
    return Array.from(constTable.status).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })

  const getPadItems = (readonly = true) => {
    const ro = readonly
    const rq = !readonly

    const items: PadViewItem[] = [{
      id: 'budget_information',
      label: constTable.itemLabel.get('budget_information'),
      groups: [{
        items: [{
          id: 'contract_amount',
          label: constTable.itemLabel.get('contract_amount') ?? '',
          type: 'text',
          value: '8,633,625',
          textAlign: 'right',
          readonly: ro,
          required: rq,
        }, {
          id: 'marginal_profit',
          label: constTable.itemLabel.get('marginal_profit') ?? '',
          type: 'text',
          value: '6,097,235',
          textAlign: 'right',
          readonly: ro,
          required: rq,
        }, {
          id: 'marginal_profit_ratio',
          label: constTable.itemLabel.get('marginal_profit_ratio') ?? '',
          type: 'text',
          value: '70.6',
          textAlign: 'right',
          readonly: ro,
          required: rq,
        }, {
          id: 'gross_profit',
          label: constTable.itemLabel.get('gross_profit') ?? '',
          type: 'text',
          value: '3,229,835',
          textAlign: 'right',
          readonly: ro,
          required: rq,
        }, {
          id: 'productivity',
          label: constTable.itemLabel.get('productivity') ?? '',
          type: 'text',
          value: '2.40',
          textAlign: 'right',
          readonly: ro,
          required: rq,
        }],
      }],
    }, {
      id: 'cost_information',
      label: constTable.itemLabel.get('cost_information'),
      groups: [{
        items: [{
          id: 'variable_costs',
          label: constTable.itemLabel.get('variable_costs') ?? '',
          type: 'text',
          value: '2,536,390',
          textAlign: 'right',
          readonly: ro,
          required: rq,
        }, {
          id: 'labor_cost',
          label: constTable.itemLabel.get('labor_cost') ?? '',
          type: 'text',
          value: '2,867,400',
          textAlign: 'right',
          readonly: ro,
          required: rq,
        }],
      }],
      /*
    }, {
      id: 'cost_information',
      label: constTable.itemLabel.get('cost_information'),
      groups: [{
        items: [{
          id: 'material_cost',
          label: constTable.itemLabel.get('material_cost') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }, {
          id: 'outsourcing_cost',
          label: constTable.itemLabel.get('outsourcing_cost') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }, {
          id: 'expense_cost',
          label: constTable.itemLabel.get('expense_cost') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }, {
          id: 'labor_cost',
          label: constTable.itemLabel.get('labor_cost') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }, {
          id: 'others_cost',
          label: constTable.itemLabel.get('others_cost') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }],
      }],
    }, {
      id: 'estimate_information',
      label: constTable.itemLabel.get('estimate_information'),
      groups: [{
        items: [{
          id: 'material_estimate',
          label: constTable.itemLabel.get('material_estimate') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }, {
          id: 'outsourcing_estimate',
          label: constTable.itemLabel.get('outsourcing_estimate') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }, {
          id: 'expense_estimate',
          label: constTable.itemLabel.get('expense_estimate') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }, {
          id: 'labor_estimate',
          label: constTable.itemLabel.get('labor_estimate') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }, {
          id: 'others_estimate',
          label: constTable.itemLabel.get('others_estimate') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }],
      }],
      */
    }]

    return items
  }

  return {
    getPadItems,
  }
}
