import { ApolloError } from '@apollo/client/errors'
import { clearFormErrors, onFormErrors } from '@/composables/common/form'

/**
 * @deprecated Vuelidate導入に伴い不要になったので
 * @param error
 * @param formRef
 * @param formErrorsRef
 */
export default async function onMutationError (error: ApolloError, formRef: Record<string, unknown>, formErrorsRef: Record<string, unknown>) {
  if (error.graphQLErrors) {
    // バリデーションエラー
    clearFormErrors(formRef, formErrorsRef)
    onFormErrors(error.graphQLErrors, formErrorsRef)

    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 200)
  }
}
