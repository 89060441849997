export const tableName = '稟議'

export default function approvals () {
  const itemLabel = new Map([
    [ 'status', 'ステータス' ],
    [ 'id', '稟議番号' ],
    [ 'revision', '訂番' ],
    //[ 'draftDate', '起案日' ],
    [ 'drafter', '起案者' ],
    [ 'circulationDateTime', '回付日時' ],
    [ 'resultDateTime', '決裁日時' ],
    [ 'contractId', '案件番号' ],
    [ 'contractName', '案件名' ],
    [ 'documentType', '書類種別' ],
    [ 'documentId', '書類番号' ],
    [ 'route', '承認ルート' ],
    [ 'approver', '承認者' ],
  ])
  const searchLabel = new Map([
    [ 'id', '稟議番号' ],
    [ 'contractId', '案件番号' ],
    [ 'contractName', '案件名' ],
    [ 'documentId', '書類番号' ],
  ])
  const columnLabel = new Map([
    [ 'status', 'ステータス' ],
    [ 'id', '稟議番号' ],
    [ 'revision', '訂番' ],
    //[ 'draftDate', '起案日' ],
    [ 'drafter', '起案者' ],
    [ 'circulationDateTime', '回付日時' ],
    [ 'resultDateTime', '決裁日時' ],
    [ 'contractId', '案件番号' ],
    [ 'contractName', '案件名' ],
    [ 'documentType', '書類種別' ],
    [ 'documentId', '書類番号' ],
    [ 'route', '承認ルート' ],
    [ 'approver', '承認者' ],
  ])
  /*
  const role = new Map([
    [ 'drafter', '起案者' ],
    [ 'approver', '承認者' ],
  ])
  */
  const status = new Map([
    //[ 'draft', '起案' ],
    [ 'circulation', '回覧中' ],  //1
    [ 'sendBack', '差戻' ],       //10
    [ 'approval', '可決' ],       //20
    [ 'rejection', '否決' ],      //21
    /*
    [ 'approval', '承認' ],
    [ 'rejection', '却下' ],
    */
  ])
  const documentType = new Map([
    [ 'workingBudgetPlan', '実行予算計画書' ], //1
    [ 'quotation', '見積書' ],                //2
    [ 'constructionReport', '施工報告書' ],   //3
    [ 'salesSlip', '売上原票' ],              //4
  ])

  return {
    itemLabel,
    searchLabel,
    columnLabel,
    //role,
    status,
    documentType,
  }
}
