export const tableName = 'プラン'

export default function plans () {
  const itemLabel = new Map([
    [ 'budget_information', '予算情報' ],
    [ 'contract_amount', '請負金額［円］' ],
    [ 'marginal_profit', '限界利益［円］' ],
    [ 'marginal_profit_ratio', '限界利益率［％］' ],
    [ 'productivity', '生産性' ],
    [ 'gross_profit', '粗利益［円］' ],

    [ 'cost_information', '原価情報' ],
    [ 'variable_costs', '変動費合計［円］' ],
    [ 'material_cost', '材料費［円］' ],
    [ 'outsourcing_cost', '外注費［円］' ],
    [ 'expense_cost', '経費［円］' ],
    [ 'labor_cost', '労務費［円］' ],
    [ 'others_cost', 'その他［円］' ],

    [ 'estimate_information', '見積情報' ],
    [ 'material_estimate', '材料費［円］' ],
    [ 'outsourcing_estimate', '外注費［円］' ],
    [ 'expense_estimate', '経費［円］' ],
    [ 'labor_estimate', '労務費［円］' ],
    [ 'others_estimate', 'その他［円］' ],
  ])
  const columnLabel = new Map([
    [ 'status', 'ステータス' ],
    [ 'id', 'プラン番号' ],
    [ 'name', 'プラン名' ],
    [ 'note', '備考' ],
    [ 'estimate_id', '見積番号' ],
    [ 'contract_amount_budget', '請負金額予算［円］' ],
    [ 'material_cost_budget', '材料費予算［円］' ],
    [ 'outsourcing_cost_budget', '外注費予算［円］' ],
    [ 'expense_cost_budget', '経費予算［円］' ],
    [ 'others_cost_budget', 'その他予算［円］' ],
    [ 'labor_cost_budget', '労務費予算［円］' ],
    [ 'marginal_profit_budget', '限界利益予算［円］' ],
    [ 'marginal_profit_ratio_budget', '限界利益率予算［％］' ],
    [ 'productivity_budget', '生産性予算' ],
    [ 'gross_profit_budget', '粗利益予算［円］' ],
    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])

  const status = new Map([
    [ '0', 'ドラフト' ],
    [ '10', '見積' ],
    [ '20', '受注' ],
  ])

  return {
    itemLabel,
    columnLabel,
    status,
  }
}
