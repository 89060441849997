import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import { PadViewItem } from '@/const/component/padView'
import ConstBills from '@/const/transaction/bills'

export default function pad () {
  const constTable = ConstBills()

  const formatItems = computed(() => {
    return Array.from(constTable.invoiceFormat).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })

  const getPadItems = (readonly = true) => {
    const ro = readonly
    const rq = !readonly

    const items = reactive<PadViewItem[]>([{
      id: 'contractInformation',
      label: constTable.itemLabel.get('contractInformation'),
      groups: [{
        items: [{
          id: 'contract',
          label: constTable.itemLabel.get('contract') ?? '',
          type: 'contract',
          readonly: true,
        }, {
          id: 'completeDate',
          label: constTable.itemLabel.get('completeDate') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'salesDate',
          label: constTable.itemLabel.get('salesDate') ?? '',
          type: 'text',
          readonly: true,
        }],
      }, {
        items: [{
          id: 'client',
          label: constTable.itemLabel.get('client') ?? '',
          type: 'text',
          readonly: true,
        }],
      }, {
        items: [{
          id: 'billingTargetCompanyName',
          label: constTable.itemLabel.get('billingTargetCompanyName') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'billingTargetBranchName',
          label: constTable.itemLabel.get('billingTargetBranchName') ?? '',
          type: 'text',
          readonly: true,
        }],
      }, {
        items: [{
          id: 'billingTargetPostcode',
          label: constTable.itemLabel.get('billingTargetPostcode') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'billingTargetAddress',
          label: constTable.itemLabel.get('billingTargetAddress') ?? '',
          type: 'text',
          readonly: true,
        }],
      }],
    }, {
      id: 'constractorInformation',
      label: constTable.itemLabel.get('constractorInformation'),
      groups: [{
        items: [{
          id: 'companyName',
          label: constTable.itemLabel.get('companyName') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'representativeTitle',
          label: constTable.itemLabel.get('representativeTitle') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'representativeName',
          label: constTable.itemLabel.get('representativeName') ?? '',
          type: 'text',
          readonly: true,
        }],
      }, {
        items: [{
          id: 'base',
          label: constTable.itemLabel.get('base') ?? '',
          type: 'base',
          readonly: true,
        }, {
          id: 'baseAddress',
          label: constTable.itemLabel.get('baseAddress') ?? '',
          type: 'addressPhoneView',
          readonly: true,
        }, {
          id: 'baseChiefTitle',
          label: constTable.itemLabel.get('baseChiefTitle') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'baseChiefName',
          label: constTable.itemLabel.get('baseChiefName') ?? '',
          type: 'text',
          readonly: true,
        }],
      }, {
        items: [{
          id: 'bank',
          label: constTable.itemLabel.get('bank') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'bankBranch',
          label: constTable.itemLabel.get('bankBranch') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'depositType',
          label: constTable.itemLabel.get('depositType') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'accountNumber',
          label: constTable.itemLabel.get('accountNumber') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'accountHolder',
          label: constTable.itemLabel.get('accountHolder') ?? '',
          type: 'text',
          readonly: true,
        }],
      }],
    }])

    const billInformation = reactive({
      id: 'billInformation',
      label: constTable.itemLabel.get('billInformation'),
      groups: [{
        items: [{
          id: 'status',
          label: constTable.itemLabel.get('status') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'id',
          label: constTable.itemLabel.get('id') ?? '',
          type: 'text',
          readonly: true,
        }]
      }, {
        items: [{
          id: 'clientOrderNo',
          label: constTable.itemLabel.get('clientOrderNo') ?? '',
          type: 'text',
          readonly: ro,
          required: false,
        }, {
          id: 'billingTarget',
          label: constTable.itemLabel.get('billingTarget') ?? '',
          type: 'client',
          readonly: ro,
          required: rq,
        }, {
          id: 'billingDate',
          label: constTable.itemLabel.get('billingDate') ?? '',
          type: 'date',
          readonly: true,
        }]
      }, {
        items: [{
          id: 'invoiceDate',
          label: constTable.itemLabel.get('invoiceDate') ?? '',
          type: 'dateGJ',
          readonly: ro,
          required: false,
        }]
      }, {
        items: [{
          id: 'amountIncludingTax',
          label: constTable.itemLabel.get('amountIncludingTax') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'amountExcludingTax',
          label: constTable.itemLabel.get('amountExcludingTax') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'consumptionTaxAmount',
          label: constTable.itemLabel.get('consumptionTaxAmount') ?? '',
          type: 'number',
          readonly: true,
        }],
      }, {
        items: [{
          id: 'memo',
          label: constTable.itemLabel.get('memo') ?? '',
          type: 'textarea',
          cols: 50,
          readonly: ro,
          required: false,
        }, {
          id: 'invoiceFormat',
          label: constTable.itemLabel.get('invoiceFormat') ?? '',
          type: 'select',
          items: formatItems.value,
          readonly: ro,
          required: rq,
        }],
      }, {
        items: [{
          id: 'sentDate',
          label: constTable.itemLabel.get('sentDate') ?? '',
          type: 'date',
          readonly: true,
        }, {
          id: 'sender',
          label: constTable.itemLabel.get('sender') ?? '',
          type: 'text',
          readonly: true,
        }]
      }, {
        items: [{
          id: 'updatedAt',
          label: constTable.itemLabel.get('updatedAt') ?? '',
          type: 'date',
          readonly: true,
        }, {
          id: 'updatedBy',
          label: constTable.itemLabel.get('updatedBy') ?? '',
          type: 'text',
          readonly: true,
        }]
      }],
    })
    const billInformation_edit = reactive({
      id: 'billInformation',
      label: constTable.itemLabel.get('billInformation'),
      groups: [{
        items: [{
          id: 'status',
          label: constTable.itemLabel.get('status') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'id',
          label: constTable.itemLabel.get('id') ?? '',
          type: 'text',
          readonly: true,
        }]
      }, {
        items: [{
          id: 'clientOrderNo',
          label: constTable.itemLabel.get('clientOrderNo') ?? '',
          type: 'text',
          readonly: ro,
          required: false,
        }, {
          id: 'billingTarget',
          label: constTable.itemLabel.get('billingTarget') ?? '',
          type: 'client',
          readonly: ro,
          required: rq,
        }, {
          id: 'billingDate',
          label: constTable.itemLabel.get('billingDate') ?? '',
          type: 'date',
          readonly: true,
        }]
      }, {
        items: [{
          id: 'invoiceDate',
          label: constTable.itemLabel.get('invoiceDate') ?? '',
          type: 'dateGJ',
          readonly: ro,
          required: false,
          /*
        }, {
          id: 'invoiceDateFormat',
          label: constTable.itemLabel.get('invoiceDateFormat') ?? '',
          type: 'radio',
          items: [{
            id: '0',
            label: '西暦',
          }, {
            id: '1',
            label: '和暦',
          }],
          value: '0',
          readonly: ro,
          required: false,
          */
        }]
      }, {
        items: [{
          id: 'memo',
          label: constTable.itemLabel.get('memo') ?? '',
          type: 'textarea',
          cols: 50,
          required: false,
        }, {
          id: 'invoiceFormat',
          label: constTable.itemLabel.get('invoiceFormat') ?? '',
          type: 'select',
          items: formatItems.value,
          readonly: ro,
          required: rq,
        }],
      }, {
        items: [{
          id: 'sentDate',
          label: constTable.itemLabel.get('sentDate') ?? '',
          type: 'date',
          readonly: true,
        }, {
          id: 'sender',
          label: constTable.itemLabel.get('sender') ?? '',
          type: 'text',
          readonly: true,
        }]
      }, {
        items: [{
          id: 'updatedAt',
          label: constTable.itemLabel.get('updatedAt') ?? '',
          type: 'date',
          readonly: true,
        }, {
          id: 'updatedBy',
          label: constTable.itemLabel.get('updatedBy') ?? '',
          type: 'text',
          readonly: true,
        }]
      }],
    })

    if (ro) items.unshift(billInformation)
    else items.unshift(billInformation_edit)

    return items
  }

  return {
    getPadItems,
  }
}
