export const tableName = '固定値'

export default function journalFixedValues () {
  const itemLabel = new Map([
    [ 'id', 'ID' ],
    [ 'journal_type', '仕訳区分' ],

    //[ '', '伝票日付' ],
    //[ '', '伝票番号' ],
    [ 'data_type', 'データ種別' ],
    [ 'journal_entry_format', '仕訳入力形式' ],
    [ 'slip_type', '伝票種別' ],
    //[ '', '(借方)勘定科目コード' ],

    [ 'debit', '借方' ],
    [ 'supplementary_code_debit', '科目別補助コード' ],
    //[ '', '部門コード' ],
    //[ '', '税込/税抜区分' ],
    [ 'subdivision_1st_debit', '第1補助区分' ],
    //[ '', '第1補助コード' ],
    [ 'subdivision_2nd_debit', '第2補助区分' ],
    [ 'auxiliary_code_2nd_debit', '第2補助コード' ],
    //[ '', '金額(入力金額)' ],
    [ 'consumption_tax_code_debit', '消費税コード' ],
    //[ 'consumption_tax_rate_debit', '消費税率' ],
    [ 'tax_excluded_type_debit', '外税同時入力区分' ],

    [ 'credit', '貸方' ],
    //[ '', '勘定科目コード' ],
    [ 'supplementary_code_credit', '科目別補助コード' ],
    //[ '', '部門コード' ],
    //[ '', '税込/税抜区分' ],
    [ 'subdivision_1st_credit', '第1補助区分' ],
    //[ '', '第1補助コード' ],
    [ 'subdivision_2nd_credit', '第2補助区分' ],
    [ 'auxiliary_code_2nd_credit', '第2補助コード' ],
    //[ '', '金額(入力金額)' ],
    [ 'consumption_tax_code_credit', '消費税コード' ],
    //[ 'consumption_tax_rate_credit', '消費税率' ],
    [ 'tax_excluded_type_credit', '外税同時入力区分' ],

    //[ '', '摘要' ],

    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])
  const columnLabel = new Map([
    [ 'id', 'ID' ],
    [ 'journal_type', '仕訳区分' ],

    //[ '', '伝票日付' ],
    //[ '', '伝票番号' ],
    [ 'data_type', 'データ種別' ],
    [ 'journal_entry_format', '仕訳入力形式' ],
    [ 'slip_type', '伝票種別' ],
    //[ '', '(借方)勘定科目コード' ],
    [ 'supplementary_code_debit', '(借方)科目別補助コード' ],
    //[ '', '(借方)部門コード' ],
    //[ '', '(借方)税込/税抜区分' ],
    [ 'subdivision_1st_debit', '(借方)第1補助区分' ],
    //[ '', '(借方)第1補助コード' ],
    [ 'subdivision_2nd_debit', '(借方)第2補助区分' ],
    [ 'auxiliary_code_2nd_debit', '(借方)第2補助コード' ],
    //[ '', '(借方)金額(入力金額)' ],
    //[ 'consumption_tax_code_debit', '(借方)消費税コード' ],
    //[ 'consumption_tax_rate_debit', '(借方)消費税率' ],
    [ 'tax_excluded_type_debit', '(借方)外税同時入力区分' ],
    //[ '', '(貸方)勘定科目コード' ],
    [ 'supplementary_code_credit', '(貸方)科目別補助コード' ],
    //[ '', '(貸方)部門コード' ],
    //[ '', '(貸方)税込/税抜区分' ],
    [ 'subdivision_1st_credit', '(貸方)第1補助区分' ],
    //[ '', '(貸方)第1補助コード' ],
    [ 'subdivision_2nd_credit', '(貸方)第2補助区分' ],
    [ 'auxiliary_code_2nd_credit', '(貸方)第2補助コード' ],
    //[ '', '(貸方)金額(入力金額)' ],
    //[ 'consumption_tax_code_credit', '(貸方)消費税コード' ],
    //[ 'consumption_tax_rate_credit', '(貸方)消費税率' ],
    [ 'tax_excluded_type_credit', '(貸方)外税同時入力区分' ],
    //[ '', '摘要' ],

    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])

  return {
    itemLabel,
    columnLabel,
  }
}
