import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstApprovalDetails from '@/const/transaction/approvalDetails'
import {
  Construction,
  Supplier,
  Base,
  Employee,
  SendOrderStatus,
  ExpenseType,
  Rounding,
  SendOrderSearchCondition,
} from '@/graphql'

const constTable = ConstApprovalDetails()

const baseListColumns: ListViewColumn[] = [{
  id: 'circulationNo',
  text: constTable.columnLabel.get('circulationNo'),
  displayField: 'circulation_no',
  sortField: 'circulation_no',
  align: 'right',
}, {
  id: 'status',
  text: constTable.columnLabel.get('status'),
  displayField: 'status',
  sortField: 'status_number',
  align: 'center',
  width: '100px',
}, {
  id: 'approver',
  text: constTable.columnLabel.get('approver'),
  displayField: 'approver_name',
  sortField: 'approver_kana',
}, {
  id: 'comment',
  text: constTable.columnLabel.get('comment'),
  displayField: 'comment',
  sortField: 'comment',
}, {
  id: 'approvalDateTime',
  text: constTable.columnLabel.get('approvalDateTime'),
  displayField: 'approval_date_time',
  sortField: 'approval_date_time',
}]

const standardListColumns = {
  general: [
    'circulationNo',
    'status',
    'approver',
    'comment',
    'approvalDateTime',
  ],
  routing: [
    'circulationNo',
    'status',
    'approver',
    'comment',
    'approvalDateTime',
  ],
  approvalDrafter: [
    'circulationNo',
    'status',
    'approver',
    'comment',
    'approvalDateTime',
  ],
  approvalApprover: [
    'circulationNo',
    'status',
    'approver',
    'comment',
    'approvalDateTime',
  ],
}

const listConverter: ListViewConverter = {
  status: {
    'waiting': {
      text: constTable.status.get('waiting') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(0,120,180)',
        color: 'rgb(255,255,255)',
      },
    },
    'untreated': {
      text: constTable.status.get('rejection') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(200,160,0)',
        color: 'rgb(255,255,255)',
      },
    },
    'sendBack': {
      text: constTable.status.get('sendBack') ?? '',
      type: 'rectangle',
      style: {
        background: 'rgb(200,160,0)',
        color: 'rgb(255,255,255)',
      },
    },
    'approval': {
      text: constTable.status.get('approval') ?? '',
      type: 'rectangle',
      style: {
        background: 'rgb(0,140,0)',
        color: 'rgb(255,255,255)',
      },
    },
    'rejection': {
      text: constTable.status.get('rejection') ?? '',
      type: 'rectangle',
      style: {
        background: 'rgb(200,0,0)',
        color: 'rgb(255,255,255)',
      },
    },
  },
}

export default function estimateDetail () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'circulation_no',
    order: {
      'circulation_no': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
