import {
  defineComponent,
} from 'vue'
import {
  TreeViewNode_I,
  TreeViewProvide,
} from '@/const/component/treeView'
import {
  ListViewRow,
} from '@/const/component/listView'

export interface TreeFormProvide {
  id: string
  tree: TreeViewProvide
  list: {
    selectedRow?: ListViewRow
  }
}

export default function treeForm () {
  const getNode = (nodes: TreeViewNode_I[], id: string) => {
    const ids = id.split('-')
    let node
    let nds = nodes
    const level = ids.length
    let sid = ''

    for (let i = 0; i < level; i++) {
      sid += ids[i]
      node = nds.find(e => e.id === sid)
      if (i < level - 1 && node && node.nodes) {
        nds = node.nodes
      }
      sid += '-'
    }

    return node
  }

  return {
    getNode,
  }
}