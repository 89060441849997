import {
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibGoogle,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibCcStripe,
  cibCcPaypal,
  cibCcApplePay,
  cibCcAmex,
  cifUs, cifBr, cifIn, cifFr, cifEs, cifPl,
  cilAccountLogout,
  cilArrowBottom,
  cilArrowLeft,
  cilArrowRight,
  cilArrowThickBottom,
  cilArrowThickToBottom,
  cilArrowThickTop,
  cilArrowThickToTop,
  cilArrowTop,
  cilBan,
  cilBasket,
  cilBell,
  cilBellExclamation,
  cilBriefcase,
  cilBuilding,
  cilCaretTop,
  cilCaretBottom,
  cilCalculator,
  cilCalendar,
  cilCaretLeft,
  cilCaretRight,
  cilCart,
  cilCash,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilCheckAlt,
  cilCheckCircle,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCode,
  cilCog,
  cilCommentBubble,
  cilCommentSquare,
  cilCursor,
  cilDescription,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilFile,
  cilFolder,
  cilFullscreen,
  cilFullscreenExit,
  cilGlobeAlt,
  cilGrid,
  cilHamburgerMenu,
  cilHistory,
  cilInfo,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListFilter,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMediaPlay,
  cilMenu,
  cilMoney,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPlus,
  cilPrint,
  cilPuzzle,
  cilRecycle,
  cilSearch,
  cilSettings,
  cilShare,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilSquare,
  cilStar,
  cilSwapVertical,
  cilSync,
  cilTask,
  cilTrash,
  cilTruck,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilViewStream,
  cilWarning,
  cilX,
  cilXCircle,
} from '@coreui/icons'

export const iconsSet = Object.assign(
  {},
  {
    cilArrowBottom,
    cilArrowRight,
    cilArrowTop,
    cilArrowThickBottom,
    cilArrowThickToBottom,
    cilArrowThickTop,
    cilArrowThickToTop,
    cilBan,
    cilBasket,
    cilBell,
    cilBellExclamation,
    cilCalculator,
    cilCalendar,
    cilCaretLeft,
    cilCaretRight,
    cilCart,
    cilCash,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilCheckAlt,
    cilCheckCircle,
    cilChevronBottom,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCode,
    cilCog,
    cilCommentBubble,
    cilCommentSquare,
    cilCursor,
    cilDescription,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilFile,
    cilFolder,
    cilFullscreen,
    cilFullscreenExit,
    cilGlobeAlt,
    cilGrid,
    cilHamburgerMenu,
    cilHistory,
    cilInfo,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilListFilter,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMediaPlay,
    cilMenu,
    cilMoney,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPencil,
    cilPeople,
    cilPlus,
    cilPrint,
    cilPuzzle,
    cilRecycle,
    cilSettings,
    cilShare,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilSwapVertical,
    cilSync,
    cilTask,
    cilTruck,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilViewStream,
    cilX,
    cilXCircle,
  },
  {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl
  },
  {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibGoogle,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibCcStripe,
    cibCcPaypal,
    cibCcApplePay,
    cibCcAmex,
    cilAccountLogout,
    cilSearch,
    cilDescription,
    cilBriefcase,
    cilCaretTop,
    cilCaretBottom,
    cilArrowLeft,
    cilWarning,
    cilTrash,
    cilBuilding,
    cilSquare,
  }
)
