import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstPurchaseDetails from '@/const/transaction/purchaseDetails'

const constPurchaseDetails = ConstPurchaseDetails()

const baseListColumns: ListViewColumn[] = [{
  id: 'details_line_no',
  text: constPurchaseDetails.columnLabel.get('detailsLineNo'),
  displayField: 'details_line_no_text',
  //multipleLines: true,
  align: 'center',
}, {
  id: 'model_number',
  text: constPurchaseDetails.columnLabel.get('modelNumber'),
  displayField: 'model_number',
  sortField: 'model_number',
  //multipleLines: true,
  colspan: {
    key: 'detail_type',
    value: 2,
    count: 14,
  },
}, {
  id: 'model_number_draft',
  text: constPurchaseDetails.columnLabel.get('modelNumber'),
  displayField: 'model_number',
  //multipleLines: true,
  colspan: {
    key: 'detail_type',
    value: 2,
    count: 12,
  },
}, {
  id: 'description',
  text: constPurchaseDetails.columnLabel.get('description'),
  displayField: 'description',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'expense_type',
  text: constPurchaseDetails.columnLabel.get('expenseType'),
  displayField: 'expense_type',
  align: 'center',
  width: '100px',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'expense_account_type',
  text: constPurchaseDetails.columnLabel.get('expenseAccountType'),
  displayField: 'expense_account_type',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
  /*
}, {
  id: 'not_manage_backlog',
  text: constPurchaseDetails.columnLabel.get('notManageBacklog'),
  displayField: 'not_manage_backlog',
  width: '100px',
  align: 'center',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
  */
}, {
  id: 'quantity',
  text: constPurchaseDetails.columnLabel.get('quantity'),
  displayField: 'quantity',
  displayConvert: 'decimalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'unit',
  text: constPurchaseDetails.columnLabel.get('unit'),
  displayField: 'unit',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'unit_price',
  text: constPurchaseDetails.columnLabel.get('unitPrice'),
  displayField: 'unit_price',
  displayConvert: 'decimalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2
  },
}, {
  id: 'amount',
  text: constPurchaseDetails.columnLabel.get('amount'),
  displayField: 'amount',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'amount_including_tax',
  text: constPurchaseDetails.columnLabel.get('amountIncludingTax'),
  displayField: 'amount_including_tax',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'amount_excluding_tax',
  text: constPurchaseDetails.columnLabel.get('amountExcludingTax'),
  displayField: 'amount_excluding_tax',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'consumption_tax_amount',
  text: constPurchaseDetails.columnLabel.get('consumptionTaxAmount'),
  displayField: 'consumption_tax_amount',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'consumption_tax_type',
  text: constPurchaseDetails.columnLabel.get('consumptionTaxType'),
  displayField: 'consumption_tax_type',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'consumption_tax_rate',
  text: constPurchaseDetails.columnLabel.get('consumptionTaxRate'),
  displayField: 'consumption_tax_rate',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'deliveried_quantity',
  text: constPurchaseDetails.columnLabel.get('deliveriedQuantity'),
  displayField: 'deliveried_quantity',
  displayConvert: 'decimalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'remaining_quantity',
  text: constPurchaseDetails.columnLabel.get('remainingQuantity'),
  displayField: 'remaining_quantity',
  displayConvert: 'decimalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
  /*
}, {
  id: 'accounts_payable',
  text: constPurchaseDetails.columnLabel.get('accountsPayable'),
  displayField: 'accounts_payable',
  displayConvert: 'numericalDelimiter',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
  */
}]

const standardListColumns = {
  purchase: [
    'details_line_no',
    'model_number',
    'description',
    'expense_type',
    'expense_account_type',
    //'not_manage_backlog',
    'quantity',
    'unit',
    'unit_price',
    //'consumption_tax_type',
    'consumption_tax_rate',
    'consumption_tax_amount',
    //'amount',
    'amount_excluding_tax',
    'amount_including_tax',
    'deliveried_quantity',
    'remaining_quantity',
  ],
  draft: [
    'details_line_no',
    'model_number_draft',
    'description',
    'expense_type',
    'expense_account_type',
    //'not_manage_backlog',
    'quantity',
    'unit',
    'unit_price',
    //'consumption_tax_type',
    'consumption_tax_rate',
    'consumption_tax_amount',
    //'amount',
    'amount_excluding_tax',
    'amount_including_tax',
    //'deliveried_quantity',
    //'remaining_quantity',
  ],
  general: [
    'details_line_no',
    'model_number',
    'description',
    'expense_type',
    'expense_account_type',
    //'not_manage_backlog',
    'quantity',
    'unit',
    'unit_price',
    //'consumption_tax_type',
    'consumption_tax_rate',
    'consumption_tax_amount',
    //'amount',
    'amount_excluding_tax',
    'amount_including_tax',
    'deliveried_quantity',
    'remaining_quantity',
  ],
  backlog: [
    'details_line_no',
    'model_number',
    'description',
    'expense_type',
    'expense_account_type',
    //'not_manage_backlog',
    'quantity',
    'unit',
    'unit_price',
    //'consumption_tax_type',
    'consumption_tax_rate',
    'consumption_tax_amount',
    //'amount',
    'amount_excluding_tax',
    'amount_including_tax',
    'deliveried_quantity',
    'remaining_quantity',
  ],
  information: [
    'details_line_no',
    'model_number',
    'description',
    'expense_type',
    'expense_account_type',
    //'not_manage_backlog',
    'quantity',
    'unit',
    'unit_price',
    //'consumption_tax_type',
    'consumption_tax_rate',
    'consumption_tax_amount',
    //'amount',
    'amount_excluding_tax',
    'amount_including_tax',
    'deliveried_quantity',
    'remaining_quantity',
  ],
}

const listConverter: ListViewConverter = {
  expense_type: {
    '1': {
      text: constPurchaseDetails.expenseType.get('1') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(0,140,0)',
        color: 'rgb(255,255,255)',
      },
    },
    '2': {
      text: constPurchaseDetails.expenseType.get('2') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(180,140,0)',
        color: 'rgb(255,255,255)',
      },
    },
    '3': {
      text: constPurchaseDetails.expenseType.get('3') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(200,100,100)',
        color: 'rgb(255,255,255)',
      },
    },
    '0': {
      text: constPurchaseDetails.expenseType.get('0') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(0,40,160)',
        color: 'rgb(255,255,255)',
      },
    },
  },
  not_manage_backlog: {
    0: {
      text: constPurchaseDetails.notManageBacklog.get('0') ?? '',
      type: 'rectangle',
      style: {
        background: 'rgb(0,180,180)',
        color: 'rgb(255,255,255)',
      },
    },
    1: {
      text: constPurchaseDetails.notManageBacklog.get('1') ?? '',
      type: 'rectangle',
      style: {
        background: 'rgb(240,80,0)',
        color: 'rgb(255,255,255)',
      },
    },
  },
}

export default function sendOrderDetail () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'details_line_no',
    order: {
      'details_line_no': 1,
    },
  })

  const getFormItems = (edit = false) => {
    if (edit) return standardListColumns['draft']
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }
  const getListColumns = (edit = false) => {
    const formItems = getFormItems(edit)
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
