export const tableName = '仕入明細'

export default function journalPurchaseDetails () {
  const columnLabel = new Map([
    [ 'deliveryDate', '仕入日' ],
    [ 'expenseAccountType', '勘定科目' ],
    [ 'consumptionTaxType', '消費税区分' ],
    [ 'amount', '金額［円］' ],
    [ 'supplier', '仕入先' ],
    [ 'purchaseDeliveryId', '仕入番号' ],
    [ 'purchaseDeliveryDetailsLineNo', '仕入明細No.' ],
    [ 'modelNumber', '型番' ],
    [ 'description', '品名／規格' ],
    [ 'expenseType', '経費種別' ],
  ])

  return {
    columnLabel,
  }
}
