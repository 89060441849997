import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstJournalPurchaseItems from '@/const/transaction/journalPurchaseItems'

const constTable = ConstJournalPurchaseItems()

const baseListColumns: ListViewColumn[] = [{
  id: 'element_name',
  text: constTable.columnLabel.get('element_name'),
  displayField: 'element_name',
  sortField: 'element_name',
}, {
  id: 'expenseType',
  text: constTable.columnLabel.get('expense_type'),
  displayField: 'expenseType',
  sortField: 'expenseType',
  align: 'center',
  width: '100px',
}, {
  id: 'account_code',
  text: constTable.columnLabel.get('account_code'),
  align: 'center',
  columnCount: 8,
  groups: [{
    id: 'account_code_main',
    text: constTable.columnLabel.get('account_code_main'),
    align: 'center',
    columns: [{
      id: 'account_code_main_debit',
      text: constTable.columnLabel.get('account_code_main_debit'),
      displayField: 'account_code_main_debit',
      sortField: 'account_code_main_debit',
      align: 'right',
      width: '100px',
      style: {
        conditions: 'valueIsNull',
        backgroundColor: 'rgba(0,0,0,0.7)'
      },
    }, {
      id: 'account_code_main_credit',
      text: constTable.columnLabel.get('account_code_main_credit'),
      displayField: 'account_code_main_credit',
      sortField: 'account_code_main_credit',
      align: 'right',
      width: '100px',
      style: {
        conditions: 'valueIsNull',
        backgroundColor: 'rgba(0,0,0,0.7)'
      },
    }],
  }, {
    id: 'account_code_side',
    text: constTable.columnLabel.get('account_code_side'),
    align: 'center',
    columns: [{
      id: 'account_code_side_debit',
      text: constTable.columnLabel.get('account_code_side_debit'),
      displayField: 'account_code_side_debit',
      sortField: 'account_code_side_debit',
      align: 'center',
      width: '100px',
      style: {
        conditions: 'valueIsNull',
        backgroundColor: 'rgba(0,0,0,0.7)'
      },
    }, {
      id: 'account_code_side_credit',
      text: constTable.columnLabel.get('account_code_side_credit'),
      displayField: 'account_code_side_credit',
      sortField: 'account_code_side_credit',
      align: 'center',
      width: '100px',
      style: {
        conditions: 'valueIsNull',
        backgroundColor: 'rgba(0,0,0,0.7)'
      },
    }],
  }, {
    id: 'account_code_sale',
    text: constTable.columnLabel.get('account_code_sale'),
    align: 'center',
    columns: [{
      id: 'account_code_sale_debit',
      text: constTable.columnLabel.get('account_code_sale_debit'),
      displayField: 'account_code_sale_debit',
      sortField: 'account_code_sale_debit',
      align: 'center',
      width: '100px',
      style: {
        conditions: 'valueIsNull',
        backgroundColor: 'rgba(0,0,0,0.7)'
      },
    }, {
      id: 'account_code_sale_credit',
      text: constTable.columnLabel.get('account_code_sale_credit'),
      displayField: 'account_code_sale_credit',
      sortField: 'account_code_sale_credit',
      align: 'center',
      width: '100px',
      style: {
        conditions: 'valueIsNull',
        backgroundColor: 'rgba(0,0,0,0.7)'
      },
    }],
  }, {
    id: 'account_code_assets',
    text: constTable.columnLabel.get('account_code_assets'),
    align: 'center',
    columns: [{
      id: 'account_code_assets_debit',
      text: constTable.columnLabel.get('account_code_assets_debit'),
      displayField: 'account_code_assets_debit',
      sortField: 'account_code_assets_debit',
      align: 'center',
      width: '100px',
      style: {
        conditions: 'valueIsNull',
        backgroundColor: 'rgba(0,0,0,0.7)'
      },
    }, {
      id: 'account_code_assets_credit',
      text: constTable.columnLabel.get('account_code_assets_credit'),
      displayField: 'account_code_assets_credit',
      sortField: 'account_code_assets_credit',
      align: 'center',
      width: '100px',
      style: {
        conditions: 'valueIsNull',
        backgroundColor: 'rgba(0,0,0,0.7)'
      },
    }],
  }],
}, {
  id: 'updatedAt',
  text: constTable.columnLabel.get('updatedAt'),
  displayField: 'updated_at',
  sortField: 'updated_at',
}, {
  id: 'updatedBy',
  text: constTable.columnLabel.get('updatedBy'),
  displayField: 'updated_by_name',
  sortField: 'updated_by_kana',
}]

const standardListColumns = {
  purchase: [
    'expenseType',
    'element_name',
    'account_code',
    'updatedAt',
    'updatedBy',
  ],
}

const listIcon: ListViewIcon = {
}
const listConverter: ListViewConverter = {
}

export default function deposit () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'expenseType',
    order: {
      'expenseType': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
