export const tableName = '消費税区分'

export default function taxTypes () {
  const itemLabel = new Map([
    [ 'id', 'ID' ],
    [ 'display_order', '表示順' ],
    [ 'name', '消費税区分名' ],
    [ 'rate', '税率［％］' ],
    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])
  const searchLabel = new Map([
  ])
  const columnLabel = new Map([
    [ 'id', 'ID' ],
    [ 'display_order', '表示順' ],
    [ 'name', '区分名' ],
    [ 'rate', '税率［％］' ],
    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])

  return {
    itemLabel,
    searchLabel,
    columnLabel,
  }
}
