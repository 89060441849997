export interface DbJournalSalesItems {
  id: string
  element_name: string
  account_code_main_debit: number | null
  account_code_main_credit: number | null
  account_code_side_debit: number | null
  account_code_side_credit: number | null
  updated_at: string
  updated_by: string
  updated_by_name: string
  updated_by_kana: string
}

export default function journalSalesItems () {
  const journalSalesItems: Array<DbJournalSalesItems> = [{
    id: '1',
    element_name: '売上',
    account_code_main_debit: 162,
    account_code_main_credit: 410,
    account_code_side_debit: 164,
    account_code_side_credit: 412,
    updated_at: '2023-04-01',
    updated_by: '1',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインA',
  }, {
    id: '2',
    element_name: '売上_値引',
    account_code_main_debit: 162,
    account_code_main_credit: 410,
    account_code_side_debit: 164,
    account_code_side_credit: 412,
    updated_at: '2023-05-01',
    updated_by: '2',
    updated_by_name: '社員Ｂ',
    updated_by_kana: 'シャインB',
  }]

  return {
    journalSalesItems,
  }
}
