export interface DbBillDetail {
  id: string
  details_line_no: number
  detail_type: number                 // 1: 注文品 / 2: メモ
  description: string
  quantity: number | null
  unit: string | null
  unit_price: number | null
  consumption_tax_rate: string | null
  consumption_tax_rate_number: number | null
  consumption_tax_amount: number | null
  amount_excluding_tax: number | null
  amount_including_tax: number | null
  note: string | null
  checked?: boolean
  icon?: string | null
}

export default function billDetails () {
  const billDetails:Array<DbBillDetail> = [{
    id: '1',
    details_line_no: 1,
    detail_type: 1,
    description: '案件１',
    quantity: 1,
    unit: '式',
    unit_price: 1000000,
    consumption_tax_rate: '外税 10%',
    consumption_tax_rate_number: 10,
    consumption_tax_amount: 100000,
    amount_excluding_tax: 1000000,
    amount_including_tax: 1100000,
    note: null,
  }]

  return {
    billDetails,
  }
}
