export interface DbJournalPurchase {
  id: string
  totaling_month: string
  status: string
  amount: bigint
  generated_date: string
  generator: string
  generator_name: string
  generator_kana: string
  updated_at: string
  updated_by: string
  updated_by_name: string
  updated_by_kana: string
}

export default function journalPurchases () {
  const journalPurchases: Array<DbJournalPurchase> = [{
    id: '1',
    totaling_month: '2023-03',
    status: '0', // 0: 生成済み　10: 修正あり
    amount: 5000000n,
    generated_date: '2023-04-01',
    generator: '1',
    generator_name: '社員Ａ',
    generator_kana: 'シャインA',
    updated_at: '2023-04-01',
    updated_by: '1',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインA',
  }, {
    id: '2',
    totaling_month: '2023-04',
    status: '0', // 0: 生成済み　10: 修正あり
    amount: 5500000n,
    generated_date: '2023-05-01',
    generator: '2',
    generator_name: '社員Ｂ',
    generator_kana: 'シャインB',
    updated_at: '2023-05-01',
    updated_by: '2',
    updated_by_name: '社員Ｂ',
    updated_by_kana: 'シャインB',
  }, {
    id: '3',
    totaling_month: '2023-05',
    status: '10', // 0: 生成済み　10: 修正あり
    amount: 2000000n,
    generated_date: '2023-06-01',
    generator: '2',
    generator_name: '社員Ｂ',
    generator_kana: 'シャインB',
    updated_at: '2023-06-03',
    updated_by: '3',
    updated_by_name: '社員Ｃ',
    updated_by_kana: 'シャインC',
  }]

  return {
    journalPurchases,
  }
}
