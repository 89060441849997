export const tableName = '原価集計'

export default function costSummaries () {
  const columnLabel = new Map([
    [ 'dataHeader', '' ],
    [ 'material', '材料費' ],
    [ 'outsource', '外注費' ],
    [ 'expenses', '経費' ],
    [ 'labor', '労務費' ],
    [ 'others', 'その他' ],
    [ 'total', '合計' ],
  ])

  return {
    columnLabel,
  }
}
