export interface DbEmployee {
  id: string
  employee_number: number
  name: string
  kana: string
  birthdate: string | null
  age: number | null
  gender: string
  blood_type: string
  postcode: string | null
  address0: string | null
  address1: string | null
  email_address: string
  slack_username: string | null
  division_name: string
  division_display_order: number
  base_name: string
  base_display_order: number
  joining_date: string
  retirement_date: string | null
  length_of_service: number | null
  enrollment_type: string
  health_insurance_insured_number: string
  employment_insurance_insured_number: string
  basic_pension_number: string
  labor_cost_unit_price: number
  updated_at: string
  updated_by_name: string
  updated_by_kana: string
}

export default function employees () {
  const employees: Array<DbEmployee> = [{
    id: '1234',
    employee_number: 1234,
    name: '〇〇 ＸＸＸ',
    kana: 'マルマル バツバツバツ',
    birthdate: '1980-01-03',
    age: 43,
    gender: '2',
    blood_type: '3',
    postcode: '112-0345',
    address0: '☆☆県★★市☆☆★★ 12-450',
    address1: null,
    email_address: 'abcd@efghi.com',
    slack_username: '〇〇 ＸＸＸ',
    division_name: 'エンジニア',
    division_display_order: 3,
    base_name: '郡山本社',
    base_display_order: 1,
    joining_date: '2010-07-20',
    length_of_service: 12,
    retirement_date: null,
    enrollment_type: '1',
    health_insurance_insured_number: '1234567890',
    employment_insurance_insured_number: '1234-567890-1',
    basic_pension_number: '1234-567890',
    labor_cost_unit_price: 3500,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }]

  return {
    employees,
  }
}
