import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from '@/store'
import { toastsStore, toastsStoreKey } from '@/store/Toasts'
import { tabsStore, tabsStoreKey } from '@/store/Tabs'
import { apiTokenStore, apiTokenStoreKey } from '@/store/ApiToken'
import { authorizedEmployeeStore, authorizedEmployeeStoreKey } from '@/store/AuthorizedEmployee'
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'
import ContextMenu from '@imengyu/vue3-context-menu'
// import CoreuiVue from '@coreui/vue'
import CoreuiVue from '@coreui/vue-pro'
import { CIcon } from '@coreui/icons-vue'
// @ts-ignore
import { iconsSet as icons } from './assets/icons'
import apolloClient from '@/graphql/apollo'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { createI18n } from 'vue-i18n'
import { JA } from '@/resources'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleDown,
  faAnglesDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faAnglesUp,
  faBars,
  faBan,
  faBoltLightning,
  faCalculator,
  faCalendar,
  faCaretDown,
  faCaretRight,
  faCartShopping,
  faCertificate,
  faChartSimple,
  faCheck,
  faCheckToSlot,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleCheck,
  faCircleDollarToSlot,
  faCircleExclamation,
  faCircleInfo,
  faCircleMinus,
  faCircleQuestion,
  faCircleXmark,
  faCopy,
  faCubesStacked,
  faDownload,
  faEnvelope,
  faExclamation,
  faFileArrowDown,
  faFileCircleMinus,
  faFileCirclePlus,
  faFileCircleXmark,
  faFileCsv,
  faFileExcel,
  faFileImport,
  faFileLines,
  faFilePdf,
  faFilter,
  faFire,
  faFlag,
  faGear,
  faHeart,
  faHeartCirclePlus,
  faInfo,
  faLink,
  faMagnifyingGlass,
  faMessage,
  faObjectGroup,
  faObjectUngroup,
  faPaperclip,
  faPaperPlane,
  faPaste,
  faPen,
  faPlus,
  faPrint,
  faRectangleList,
  faRectangleXmark,
  faRightFromBracket,
  faRotateLeft,
  faRotateRight,
  faSlash,
  faSort,
  faSpinner,
  faSquareCheck,
  faSquareMinus,
  faSquarePlus,
  faSquareXmark,
  faStamp,
  faTableColumns,
  faTableList,
  faTag,
  faTrash,
  faTrashArrowUp,
  faTrashCan,
  faTrashCanArrowUp,
  faTriangleExclamation,
  faTruck,
  faUnlock,
  faX,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import {
  //faCircle,
} from '@fortawesome/free-regular-svg-icons'
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome'

library.add(
  faAngleDown,
  faAnglesDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faAnglesUp,
  faBars,
  faBan,
  faBoltLightning,
  faCalculator,
  faCalendar,
  faCaretDown,
  faCaretRight,
  faCartShopping,
  faCertificate,
  faChartSimple,
  faCheck,
  faCheckToSlot,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleCheck,
  faCircleDollarToSlot,
  faCircleExclamation,
  faCircleInfo,
  faCircleMinus,
  faCircleQuestion,
  faCircleXmark,
  faCopy,
  faCubesStacked,
  faDownload,
  faEnvelope,
  faExclamation,
  faFileArrowDown,
  faFileCircleMinus,
  faFileCirclePlus,
  faFileCircleXmark,
  faFileCsv,
  faFileExcel,
  faFileImport,
  faFileLines,
  faFilePdf,
  faFilter,
  faFire,
  faFlag,
  faGear,
  faHeart,
  faHeartCirclePlus,
  faInfo,
  faLink,
  faMagnifyingGlass,
  faMessage,
  faObjectGroup,
  faObjectUngroup,
  faPaperclip,
  faPaperPlane,
  faPaste,
  faPen,
  faPlus,
  faPrint,
  faRectangleList,
  faRectangleXmark,
  faRightFromBracket,
  faRotateLeft,
  faRotateRight,
  faSlash,
  faSort,
  faSpinner,
  faSquareCheck,
  faSquareMinus,
  faSquarePlus,
  faSquareXmark,
  faStamp,
  faTableColumns,
  faTableList,
  faTag,
  faTrash,
  faTrashArrowUp,
  faTrashCan,
  faTrashCanArrowUp,
  faTriangleExclamation,
  faTruck,
  faUnlock,
  faX,
  faXmark,
)

// Vue i18n
const i18n = createI18n({
  locale: 'ja',
  messages: {
    ja: JA
  }
})

createApp(App)
  .use(store)
  .use(toastsStore, toastsStoreKey)
  .use(tabsStore, tabsStoreKey)
  .use(apiTokenStore, apiTokenStoreKey)
  .use(authorizedEmployeeStore, authorizedEmployeeStoreKey)
  .use(router)
  .use(ContextMenu)
  .use(CoreuiVue)
  .use(i18n)
  .component('CIcon', CIcon)
  .component('fa', FontAwesomeIcon)
  .component('fa-layers', FontAwesomeLayers)
  .component('fa-layers-text', FontAwesomeLayersText)
  .provide('icons', icons)
  .provide(DefaultApolloClient, apolloClient)
  .mount('#app')
