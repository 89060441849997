import { InjectionKey } from 'vue'
import { createStore, Store } from 'vuex'
import { Employee } from '@/graphql'

export interface AuthorizedEmployeeStore {
  authorizedEmployee?: Employee
}

export const authorizedEmployeeStoreKey: InjectionKey<Store<AuthorizedEmployeeStore>> = Symbol('authorizedEmployeeStoreKey')

export const authorizedEmployeeStore = createStore<AuthorizedEmployeeStore>({
  state: {
    authorizedEmployee: undefined
  },

  mutations: {
    setAuthorizedEmployee (state, employee?: Employee) {
      state.authorizedEmployee = employee
    }
  },

  actions: {
    setAuthorizedEmployee ({ commit }, employee?: Employee) {
      commit('setAuthorizedEmployee', employee)
    }
  },

  getters: {
    authorizedEmployee: (state) => state.authorizedEmployee
  }
})
