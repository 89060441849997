import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import { PadViewItem } from '@/const/component/padView'
import ConstContracts from '@/const/transaction/contracts'

export default function pad () {
  const constContracts = ConstContracts()

  const contractTypeItems = computed(() => {
    return Array.from(constContracts.contractType).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })
  const publicPrivateWorksTypeItems = computed(() => {
    return Array.from(constContracts.publicPrivateWorksType).map(el => {
      return {
        id: el[0],
        label: el[1],
      }
    })
  })
  const contractorTypeItems = computed(() => {
    return Array.from(constContracts.contractorType).map(el => {
      return {
        id: el[0],
        label: el[1],
      }
    })
  })
  const ordersAccuracyItems = computed(() => {
    return Array.from(constContracts.ordersAccuracy).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })

  const getPadItems = (readonly = true) => {
    const ro = readonly
    const rq = !readonly

    const items: PadViewItem[] = [{
      id: 'contractInformation',
      label: constContracts.itemLabel.get('contractInformation'),
      groups: [{
        items: [{
          id: 'processStatus',
          label: constContracts.itemLabel.get('processStatus') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'id',
          label: constContracts.itemLabel.get('id') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'projectId',
          label: constContracts.itemLabel.get('projectId') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'name',
          label: constContracts.itemLabel.get('name') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }, {
          id: 'kana',
          label: constContracts.itemLabel.get('kana') ?? '',
          type: 'text',
          readonly: ro,
          required: rq,
        }, {
          id: 'contractType',
          label: constContracts.itemLabel.get('contractType') ?? '',
          type: 'select',
          items: contractTypeItems.value,
          readonly: ro,
        }, {
          id: 'publicPrivateWorksType',
          label: constContracts.itemLabel.get('publicPrivateWorksType') ?? '',
          type: 'radio',
          items: publicPrivateWorksTypeItems.value,
          readonly: ro,
        }, {
          id: 'contractorType',
          label: constContracts.itemLabel.get('contractorType') ?? '',
          type: 'radio',
          items: contractorTypeItems.value,
          readonly: ro,
        }, {
          id: 'contractCategory',
          label: constContracts.itemLabel.get('contractCategory') ?? '',
          type: 'contractCategory',
          readonly: ro,
        }, {
          id: 'constructionType',
          label: constContracts.itemLabel.get('constructionType') ?? '',
          type: 'constructionType',
          readonly: ro,
        }, {
          id: 'division',
          label: constContracts.itemLabel.get('division') ?? '',
          type: 'division',
          readonly: ro,
        }, {
          id: 'base',
          label: constContracts.itemLabel.get('base') ?? '',
          type: 'base',
          readonly: ro,
        }, {
          id: 'salesRep',
          label: constContracts.itemLabel.get('salesRep') ?? '',
          type: 'employee',
          readonly: ro,
        }, {
          id: 'constructionLocation',
          label: constContracts.itemLabel.get('constructionLocation') ?? '',
          type: 'text',
          readonly: ro,
        }, {
          id: 'siteAgent',
          label: constContracts.itemLabel.get('siteAgent') ?? '',
          type: 'employee',
          readonly: ro,
        }, {
          id: 'client',
          label: constContracts.itemLabel.get('client') ?? '',
          type: 'client',
          readonly: ro,
        }, {
          id: 'billingTarget',
          label: constContracts.itemLabel.get('billingTarget') ?? '',
          type: 'client',
          readonly: ro,
        }, {
          id: 'contractPeriod',
          label: constContracts.itemLabel.get('contractPeriod') ?? '',
          type: 'dateRange',
          readonly: ro,
        }, {
          id: 'note',
          label: constContracts.itemLabel.get('note') ?? '',
          type: 'textarea',
          cols: 50,
          readonly: ro,
        }, {
          id: 'notCounted',
          label: constContracts.itemLabel.get('notCounted') ?? '',
          type: 'switch',
          readonly: ro,
        }, {
          id: 'editLock',
          label: constContracts.itemLabel.get('editLock') ?? '',
          type: 'switch',
          readonly: ro,
        }, {
          id: 'lostOrderDate',
          label: constContracts.itemLabel.get('lostOrderDate') ?? '',
          type: 'date',
          readonly: true,
        }, {
          id: 'lostOrderReason',
          label: constContracts.itemLabel.get('lostOrderReason') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'updatedAt',
          label: constContracts.itemLabel.get('updatedAt') ?? '',
          type: 'date',
          readonly: true,
        }, {
          id: 'updatedBy',
          label: constContracts.itemLabel.get('updatedBy') ?? '',
          type: 'date',
          readonly: true,
        }],
      }],
    }, {
      id: 'prospectInformation',
      label: constContracts.itemLabel.get('prospectInformation'),
      groups: [{
        items: [{
          id: 'ordersAccuracy',
          label: constContracts.itemLabel.get('ordersAccuracy') ?? '',
          type: 'select',
          items: ordersAccuracyItems.value,
          readonly: ro,
        }, {
          id: 'destinationTarget',
          label: constContracts.itemLabel.get('destinationTarget') ?? '',
          type: 'client',
          readonly: ro,
        }, {
          id: 'predictedContractJobAmount',
          label: constContracts.itemLabel.get('predictedContractJobAmount') ?? '',
          type: 'number',
          readonly: ro,
        }, {
          id: 'estimatedMaterialCost',
          label: constContracts.itemLabel.get('estimatedMaterialCost') ?? '',
          type: 'number',
          readonly: ro,
        }, {
          id: 'estimatedOutsourcingCost',
          label: constContracts.itemLabel.get('estimatedOutsourcingCost') ?? '',
          type: 'number',
          readonly: ro,
        }, {
          id: 'estimatedExpenseCost',
          label: constContracts.itemLabel.get('estimatedExpenseCost') ?? '',
          type: 'number',
          readonly: ro,
        }, {
          id: 'estimatedOthersCost',
          label: constContracts.itemLabel.get('estimatedOthersCost') ?? '',
          type: 'number',
          readonly: ro,
        }, {
          id: 'estimatedLaborCost',
          label: constContracts.itemLabel.get('estimatedLaborCost') ?? '',
          type: 'number',
          readonly: ro,
        }, {
          id: 'targetMarginalProfit',
          label: constContracts.itemLabel.get('targetMarginalProfit') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'targetMarginalProfitRatio',
          label: constContracts.itemLabel.get('targetMarginalProfitRatio') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'targetProductivity',
          label: constContracts.itemLabel.get('targetProductivity') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'targetGrossProfit',
          label: constContracts.itemLabel.get('targetGrossProfit') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'estimatedSalesDate',
          label: constContracts.itemLabel.get('estimatedSalesDate') ?? '',
          type: 'date',
          readonly: ro,
        }, {
          id: 'beforeCost',
          label: constContracts.itemLabel.get('beforeCost') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'inquiryDate',
          label: constContracts.itemLabel.get('inquiryDate') ?? '',
          type: 'date',
          readonly: ro,
        }],
      }],
    }, {
      id: 'estimateInformation',
      label: constContracts.itemLabel.get('estimateInformation'),
      groups: [{
        items: [{
          id: 'estimateId',
          label: constContracts.itemLabel.get('estimateId') ?? '',
          type: 'text',
          readonly: true,
        }, {
          id: 'ordersAccuracy',
          label: constContracts.itemLabel.get('ordersAccuracy') ?? '',
          type: 'select',
          items: ordersAccuracyItems.value,
          readonly: ro,
        }, {
          id: 'contractAmountBudget',
          label: constContracts.itemLabel.get('contractAmountBudget') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'materialCostBudget',
          label: constContracts.itemLabel.get('materialCostBudget') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'outsourcingCostBudget',
          label: constContracts.itemLabel.get('outsourcingCostBudget') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'expenseCostBudget',
          label: constContracts.itemLabel.get('expenseCostBudget') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'othersCostBudget',
          label: constContracts.itemLabel.get('othersCostBudget') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'laborCostBudget',
          label: constContracts.itemLabel.get('laborCostBudget') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'marginalProfitBudget',
          label: constContracts.itemLabel.get('marginalProfitBudget') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'marginalProfitRatioBudget',
          label: constContracts.itemLabel.get('marginalProfitRatioBudget') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'productivityBudget',
          label: constContracts.itemLabel.get('productivityBudget') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'grossProfitBudget',
          label: constContracts.itemLabel.get('grossProfitBudget') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'estimateDate',
          label: constContracts.itemLabel.get('estimateDate') ?? '',
          type: 'date',
          readonly: true,
        }, {
          id: 'expectedSalesDate',
          label: constContracts.itemLabel.get('expectedSalesDate') ?? '',
          type: 'date',
          readonly: ro,
        }],
      }],
    }, {
      id: 'orderInformation',
      label: constContracts.itemLabel.get('orderInformation'),
      groups: [{
        items: [{
          id: 'clientOrderNo',
          label: constContracts.itemLabel.get('clientOrderNo') ?? '',
          type: 'text',
          readonly: ro,
        }, {
          id: 'constructionRep',
          label: constContracts.itemLabel.get('constructionRep') ?? '',
          type: 'employee',
          readonly: ro,
        }, {
          id: 'constructionTarget',
          label: constContracts.itemLabel.get('constructionTarget') ?? '',
          type: 'client',
          readonly: ro,
        }, {
          id: 'contractJobAmount',
          label: constContracts.itemLabel.get('contractJobAmount') ?? '',
          type: 'number',
          readonly: ro,
        }, {
          id: 'marginalProfit',
          label: constContracts.itemLabel.get('marginalProfit') ?? '',
          type: 'number',
          readonly: ro,
        }, {
          id: 'marginalProfitRatio',
          label: constContracts.itemLabel.get('marginalProfitRatio') ?? '',
          type: 'number',
          readonly: ro,
        }, {
          id: 'marginalProfitBudgetBalance',
          label: constContracts.itemLabel.get('marginalProfitBudgetBalance') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'productivity',
          label: constContracts.itemLabel.get('productivity') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'grossProfit',
          label: constContracts.itemLabel.get('grossProfit') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'materialCost',
          label: constContracts.itemLabel.get('materialCost') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'materialCostBalance',
          label: constContracts.itemLabel.get('materialCostBalance') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'outsourcingCost',
          label: constContracts.itemLabel.get('outsourcingCost') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'outsourcingCostBalance',
          label: constContracts.itemLabel.get('outsourcingCostBalance') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'expenseCost',
          label: constContracts.itemLabel.get('expenseCost') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'expenseCostBalance',
          label: constContracts.itemLabel.get('expenseCostBalance') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'othersCost',
          label: constContracts.itemLabel.get('othersCost') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'othersCostBalance',
          label: constContracts.itemLabel.get('othersCostBalance') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'laborCost',
          label: constContracts.itemLabel.get('laborCost') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'laborCostBalance',
          label: constContracts.itemLabel.get('laborCostBalance') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'purchaseAmountBalance',
          label: constContracts.itemLabel.get('purchaseAmountBalance') ?? '',
          type: 'number',
          readonly: true,
        }, {
          id: 'completeDate',
          label: constContracts.itemLabel.get('completeDate') ?? '',
          type: 'date',
          readonly: true,
        }, {
          id: 'billDate',
          label: constContracts.itemLabel.get('billDate') ?? '',
          type: 'date',
          readonly: true,
        }, {
          id: 'salesDate',
          label: constContracts.itemLabel.get('salesDate') ?? '',
          type: 'date',
          readonly: true,
        }, {
          id: 'autoCompleteDate',
          label: constContracts.itemLabel.get('autoCompleteDate') ?? '',
          type: 'date',
          readonly: ro,
        }],
      }],
    }]

    return items
  }

  return {
    getPadItems,
  }
}
