import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { ErrorLink } from '@apollo/client/link/error'
import { provideApolloClient } from '@vue/apollo-composable'
import { apiTokenStore } from '@/store/ApiToken'
import { toastsStore } from '@/store/Toasts'
import router from '@/router'

const authLink = setContext(async (_, { headers }) => {
  const token = apiTokenStore.getters.token
  return {
    headers: {
      ...headers,
      "Authorization": token ? `Bearer ${token}` : "",
    }
  }
})

const errorLink = new ErrorLink((error) => {
  error.graphQLErrors?.forEach(async (value) => {
    if (value.extensions.category === 'authentication') {
      // 認証エラー
      console.info('graphQLErrors:', {message: value.message})
      await router.push({ name: 'LoginAsEmployee' })
    } else if (value.extensions.category === 'validation') {
      // NOTE: 各コンポーネントで処理
      // console.log('graphQLErrors:', {category: value.extensions.category, message: value.message})
    } else {
      // その他（今のところ、基本的にシステムエラー）
      await toastsStore.dispatch('push', {
        message: value.message,
        autohide: false,
        colorVariant: 'danger'
      })
    }
  })
})

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_ASAKA_API_ENDPOINT || 'http://localhost:8080/graphql'
})

const cache = new InMemoryCache()

const apolloClient = new ApolloClient({
  link: authLink.concat(errorLink).concat(httpLink),
  cache,
})

provideApolloClient(apolloClient)

export default apolloClient
