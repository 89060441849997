import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstPlanDetails from '@/const/transaction/planDetails'

const constTable = ConstPlanDetails()

const baseListColumns: ListViewColumn[] = [{
  /*
  id: 'status',
  text: constTable.columnLabel.get('status'),
  displayField: 'status',
  sortField: 'status',
  colspan: {
    key: 'detail_type',
    value: 2,
    count: 8,
  },
}, {
  */
  id: 'construction',
  text: constTable.columnLabel.get('construction'),
  displayField: 'construction',
  //sortField: 'construction_id_number',
  colspan: {
    key: 'detail_type',
    value: 2,
    count: 9,
  },
}, {
  id: 'expense_type',
  text: constTable.columnLabel.get('expense_type'),
  displayField: 'expense_type',
  //sortField: 'expense_type',
  width: '100px',
  align: 'center',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'item_name',
  text: constTable.columnLabel.get('item_name'),
  displayField: 'item_name',
  //sortField: 'model_number',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'cost_rate',
  text: constTable.columnLabel.get('cost_rate'),
  displayField: 'cost_rate',
  //sortField: 'cost_rate',
  align: 'right',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
}, {
  id: 'cost_estimate',
  text: constTable.columnLabel.get('cost_estimate'),
  align: 'center',
  invisible: {
    key: 'detail_type',
    value: 2,
  },
  columns: [{
    id: 'quantity',
    text: constTable.columnLabel.get('quantity'),
    displayStackFields: ['quantity_cost','quantity_estimate'],
    //sortField: 'quantity',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'unit',
    text: constTable.columnLabel.get('unit'),
    displayStackFields: ['unit_cost','unit_estimate'],
    //sortField: 'unit_price',
  }, {
    id: 'unit_price',
    text: constTable.columnLabel.get('unit_price'),
    displayStackFields: ['unit_price_cost','unit_price_estimate'],
    //sortField: 'unit_price',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'amount',
    text: constTable.columnLabel.get('amount'),
    displayStackFields: ['amount_cost','amount_estimate'],
    //sortField: 'amount',
    displayConvert: 'numericalDelimiter',
    align: 'right',
    /*
  }, {
    id: 'consumption_tax_type',
    text: constTable.columnLabel.get('consumption_tax_type'),
    displayField: 'consumption_tax_type',
    //sortField: 'consumption_tax_type',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  */
  }, {
    id: 'consumption_tax_rate',
    text: constTable.columnLabel.get('consumption_tax_rate'),
    displayStackFields: ['consumption_tax_rate_cost','consumption_tax_rate_estimate'],
    //sortField: 'consumption_tax_rate',
    displayConvert: 'numericalDelimiter',
    align: 'right',
    /*
  }, {
    id: 'consumption_tax_amount',
    text: constTable.columnLabel.get('consumption_tax_amount'),
    displayField: 'consumption_tax_amount',
    //sortField: 'consumption_tax_amount',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'amount_excluding_tax',
    text: constTable.columnLabel.get('amount_excluding_tax'),
    displayField: 'amount_excluding_tax',
    //sortField: 'amount_excluding_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'amount_including_tax',
    text: constTable.columnLabel.get('amount_including_tax'),
    displayField: 'amount_including_tax',
    //sortField: 'amount_including_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
    */
  }],
}]

const standardListColumns = {
  plan: [
    'construction',
    'expense_type',
    'item_name',
    'cost_rate',
    'cost_estimate',
  ],
}

const listIcon: ListViewIcon = {
  caution: {
    name: 'triangle-exclamation',
    classes: [
      '--error',
    ]
  },
}
const listConverter: ListViewConverter = {
  expense_type: {
    '1': {
      text: constTable.expenseType.get('1') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(0,140,0)',
        color: 'rgb(255,255,255)',
      },
    },
    '2': {
      text: constTable.expenseType.get('2') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(180,140,0)',
        color: 'rgb(255,255,255)',
      },
    },
    '3': {
      text: constTable.expenseType.get('3') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(200,100,100)',
        color: 'rgb(255,255,255)',
      },
    },
    '4': {
      text: constTable.expenseType.get('4') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(50,50,200)',
        color: 'rgb(255,255,255)',
      },
    },
    '0': {
      text: constTable.expenseType.get('0') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(0,40,160)',
        color: 'rgb(255,255,255)',
      },
    },
  },
}

export default function deposit () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'base_display_no',
    order: {
      'base_display_no': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
