import { RouteRecordRaw } from 'vue-router'
import LoginAsEmployee from '@/views/auth/LoginAsEmployee.vue'

export const auth: Array<RouteRecordRaw> = [
  {
    path: '/login-as-employee',
    name: 'LoginAsEmployee',
    component: LoginAsEmployee
  },
]
