import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import ConstPurchases from '@/const/transaction/purchases'
import {
  SearchBoxValue,
  SearchBoxItem,
} from '@/const/component/searchBox'

const constPurchases = ConstPurchases()

const items = [
  'id',
  'issueDate',
  'issuer',
  'orderDate',
  'orderer',
  'updatedAt',
  'updatedBy',
  'supplier',
  'supplierAddress',
  'supplierQuotationNo',
  'deliveryDestination',
  'deliveryAddress',
  //'base',
  'apportionmentId',
  'apportionmentName',
  'constructionPeriod',
  'salesRepresentative',
  'siteAgent',
  'deliveryDeadline',
  'deliveryConditions',
  'paymentTerms',
  'orderDetails',
  'note',
  'amountIncludingTax',
  'amountExcludingTax',
  'consumptionTaxAmount',
  'deliveredAmountIncludingTax',
  'deliveredAmountExcludingTax',
  'amountBalanceIncludingTax',
  'amountBalanceExcludingTax',
]

export default function search () {
  const searchItems = computed(() => {
    return items.map(el => {
      return {
        id: el,
        label: constPurchases.searchLabel.get(el),
      } as SearchBoxItem
    })
  })

  return {
    searchItems,
  }
}
