export interface DbDepositBalance {
  id: string
  year_month: string
  billing_target_code: string
  billing_target_name: string
  billing_target_kana: string
  previous_month_balance: bigint
  amount_incurred: bigint
  deposit_amount: bigint
  current_month_balance: bigint
}

export default function depositBalances () {
  const depositBalances: Array<DbDepositBalance> = [{
    id: '1234',
    year_month: '2023-02',
    billing_target_code: '12345678',
    billing_target_name: '福島データセンター郡山支部',
    billing_target_kana: 'フクシマデータセンターコオリヤマシブ',
    previous_month_balance: 20000n,
    amount_incurred: 11000000n,
    deposit_amount: 10000000n,
    current_month_balance: 1020000n,
  }, {
    id: '1235',
    year_month: '2023-02',
    billing_target_code: '12345555',
    billing_target_name: '〇〇〇〇〇〇',
    billing_target_kana: 'マルマルマルマルマルマル',
    previous_month_balance: 0n,
    amount_incurred: 2500000n,
    deposit_amount: 25000000n,
    current_month_balance: 0n,
  }, {
    id: '1236',
    year_month: '2023-02',
    billing_target_code: '12346666',
    billing_target_name: 'ＸＸＸＸＸＸ',
    billing_target_kana: 'バツバツバツバツバツバツ',
    previous_month_balance: 10000n,
    amount_incurred: 500000n,
    deposit_amount: 5010000n,
    current_month_balance: 0n,
  }]

  return {
    depositBalances,
  }
}
