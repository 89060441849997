export const tableName = '業者'

export default function suppliers () {
  const columnLabel = new Map([
    [ 'id', 'ID' ],
    [ 'code', '業者コード' ],
    [ 'name', '業者名' ],
    [ 'kana', '業者名（カナ）' ],
    [ 'officialCompanyName', '正式社名' ],
    [ 'officialBranchName', '正式支店名' ],
    [ 'honorificTitle', '敬称' ],
    [ 'postcode', '郵便番号' ],
    [ 'address', '住所' ],
    [ 'closingDate', '締め日' ],
    [ 'paymentMonth', '支払月' ],
    [ 'paymentDay', '支払日' ],
    [ 'taxRounding', '消費税端数処理' ],
    [ 'totalRounding', '金額端数処理' ],
    [ 'paymentType', '支払区分' ],
    [ 'bankCode', '銀行コード' ],
    [ 'bankBranchCode', '銀行支店コード' ],
    [ 'depositType', '預金種目' ],
    [ 'accountNumber', '口座番号' ],
    [ 'accountHolder', '口座名義' ],
    [ 'billHolder', '手形名義' ],
    [ 'billStandardAmount', '手形基準額' ],
    [ 'billStandardAmount2', '手形基準額2' ],
    [ 'billStandardAmount3', '手形基準額3' ],
    [ 'billSite', '手形サイト' ],
    [ 'billShippingCost', '手形郵送料' ],
    [ 'lastOrderDate', '最終発注日' ],
    [ 'numberOfOrders', '発注回数' ],
  ])

  return {
    columnLabel,
  }
}
