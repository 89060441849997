export interface StateSendOrder {
  page: string | null
  draftSupplierId: string | null
  draftSort: StateSendOrderSort | null
  orderedSupplierId: string | null
  orderedSort: StateSendOrderSort | null
  archiveSupplierId: string | null
  archiveSort: StateSendOrderSort | null
}
export interface StateSendOrderSort {
  field: string
  order: number
}

export default {
  namespaced: true,
  state: () => ({
    page: null,
    draftSupplierId: null,
    orderedSupplierId: null,
    archiveSupplierId: null,
    draftSort: null,
    orderedSort: null,
    archiveSort: null,
  }),
  mutations: {
    page (state: StateSendOrder, value: string) {
      state.page = value
    },
    draftSupplierId (state: StateSendOrder, value: string | null) {
      state.draftSupplierId = value
    },
    orderedSupplierId (state: StateSendOrder, value: string | null) {
      state.orderedSupplierId = value
    },
    archiveSupplierId (state: StateSendOrder, value: string | null) {
      state.archiveSupplierId = value
    },
    draftSort (state: StateSendOrder, value: StateSendOrderSort | null) {
      state.draftSort = value
    },
    orderedSort (state: StateSendOrder, value: StateSendOrderSort | null) {
      state.orderedSort = value
    },
    archiveSort (state: StateSendOrder, value: StateSendOrderSort | null) {
      state.archiveSort = value
    },
  },
  getters: {
    page (state: StateSendOrder) {
      return state.page
    },
    draftSupplierId (state: StateSendOrder) {
      return state.draftSupplierId
    },
    orderedSupplierId (state: StateSendOrder) {
      return state.orderedSupplierId
    },
    archiveSupplierId (state: StateSendOrder) {
      return state.archiveSupplierId
    },
    draftSort (state: StateSendOrder) {
      return state.draftSort
    },
    orderedSort (state: StateSendOrder) {
      return state.orderedSort
    },
    archiveSort (state: StateSendOrder) {
      return state.archiveSort
    },
  },
  actions: {},
}
