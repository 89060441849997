import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstAudits from '@/const/transaction/audits'

const constTable = ConstAudits()

const baseListColumns: ListViewColumn[] = [{
  id: 'user',
  text: constTable.columnLabel.get('user'),
  displayField: 'user_name',
  sortField: 'user_kana',
}, {
  id: 'event',
  text: constTable.columnLabel.get('event'),
  displayField: 'event',
  sortField: 'event_number',
}, {
  id: 'auditableType',
  text: constTable.columnLabel.get('auditableType'),
  displayField: 'auditable_type',
  sortField: 'auditable_type',
}, {
  id: 'auditableId',
  text: constTable.columnLabel.get('auditableId'),
  displayField: 'auditable_id',
  sortField: 'auditable_id_number',
  align: 'right',
}, {
  id: 'oldValues',
  text: constTable.columnLabel.get('oldValues'),
  displayField: 'old_values',
  sortField: 'old_values',
}, {
  id: 'newValues',
  text: constTable.columnLabel.get('newValues'),
  displayField: 'new_values',
  sortField: 'new_values',
}, {
  id: 'contractId',
  text: constTable.columnLabel.get('contractId'),
  displayField: 'contract_id',
  sortField: 'contract_id_number',
  align: 'right',
}, {
  id: 'createdAt',
  text: constTable.columnLabel.get('createdAt'),
  displayField: 'created_at',
  sortField: 'created_at',
}, {
  id: 'updatedAt',
  text: constTable.columnLabel.get('updatedAt'),
  displayField: 'updated_at',
  sortField: 'updated_at',
}]

const standardListColumns = {
  audit: [
    'user',
    'event',
    'auditableType',
    'auditableId',
    'oldValues',
    'newValues',
    'createdAt',
    'updatedAt',
  ],
}

const listIcon: ListViewIcon = {
}
const listConverter: ListViewConverter = {
}

export default function deposit () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'updated_at',
    order: {
      'updated_at': -1,
    },
  })

  const getFormItems = () => {
    console.log('contentsStatus.page', contentsStatus.page)
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
