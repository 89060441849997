export const tableName = 'プラン明細'

export default function planDetails () {
  const columnLabel = new Map([
    [ 'status', 'ステータス' ],
    [ 'construction', '工事' ],
    //[ 'detailsLineNo', 'No.' ],
    [ 'expense_type', '経費種別' ],
    [ 'item_name', '名称' ],
    [ 'cost_rate', '原価率［％］' ],
    [ 'cost_estimate', '原価 ／ 見積' ],
    //[ 'model_number', '型番' ],
    //[ 'description', '品名／規格' ],
    //[ 'supplier', '仕入先' ],
    //[ 'betting_rate', '掛け率［％］' ],
    [ 'quantity', '数量' ],
    [ 'unit', '単位' ],
    [ 'unit_price', '単価［円］' ],
    [ 'consumption_tax_type', '消費税区分' ],
    [ 'consumption_tax_rate', '消費税率' ],
    [ 'consumption_tax_amount', '消費税額［円］' ],
    [ 'amount', '金額［円］' ],
    [ 'amount_excluding_tax', '税抜金額［円］' ],
    [ 'amount_including_tax', '税込金額［円］' ],
  ])

  const expenseType = new Map([
    [ '1', '材料費' ],
    [ '2', '外注費' ],
    [ '3', '経費' ],
    [ '4', '労務費' ],
    [ '0', 'その他' ],
  ])
  const status = new Map([
    [ '0', '検討中' ],
    [ '10', '確定' ],
  ])

  return {
    columnLabel,
    expenseType,
    status,
  }
}
