import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstJournalPurchaseDetails from '@/const/transaction/journalPurchaseDetails'

const constTable = ConstJournalPurchaseDetails()

const baseListColumns: ListViewColumn[] = [{
  id: 'delivery_date',
  text: constTable.columnLabel.get('deliveryDate'),
  displayField: 'delivery_date',
  sortField: 'delivery_date',
}, {
  id: 'expense_account_type',
  text: constTable.columnLabel.get('expenseAccountType'),
  displayField: 'expense_account_type',
  sortField: 'expense_account_type',
  align: 'center',
  width: '100px',
}, {
  id: 'consumption_tax_type',
  text: constTable.columnLabel.get('consumptionTaxType'),
  displayField: 'consumption_tax_type',
  sortField: 'consumption_tax_type',
}, {
  id: 'amount',
  text: constTable.columnLabel.get('amount'),
  displayField: 'amount',
  sortField: 'amount',
  displayConvert: 'numericalDelimiter',
  align: 'right',
}, {
  id: 'supplier',
  text: constTable.columnLabel.get('supplier'),
  displayField: 'supplier_name',
  sortField: 'supplier_kana',
}, {
  id: 'purchase_delivery_id',
  text: constTable.columnLabel.get('purchaseDeliveryId'),
  displayField: 'purchase_delivery_id',
  sortField: 'purchase_delivery_id_number',
  align: 'right',
}, {
  id: 'purchase_delivery_details_line_no',
  text: constTable.columnLabel.get('purchaseDeliveryDetailsLineNo'),
  displayField: 'purchase_delivery_details_line_no',
  sortField: 'purchase_delivery_details_line_no',
  align: 'right',
}, {
  id: 'model_number',
  text: constTable.columnLabel.get('modelNumber'),
  displayField: 'model_number',
  sortField: 'model_number',
}, {
  id: 'description',
  text: constTable.columnLabel.get('description'),
  displayField: 'description',
  sortField: 'description',
}, {
  id: 'expense_type',
  text: constTable.columnLabel.get('expenseType'),
  displayField: 'expense_type',
  sortField: 'expense_type',
  align: 'center',
  width: '100px',
}]

const standardListColumns = {
  purchase: [
    /*
    'details_line_no',
    'description',
    'quantity',
    'unit',
    'unit_price',
    */
    //'consumption_tax_rate',
    //'consumption_tax_amount',
    //'amount_excluding_tax',
    //'amount_including_tax',
    'delivery_date',
    'expense_account_type',
    'consumption_tax_type',
    'amount',
    'supplier',
    'purchase_delivery_id',
    'purchase_delivery_details_line_no',
    'model_number',
    'description',
    'expense_type',
  ],
}

const listIcon: ListViewIcon = {
}
const listConverter: ListViewConverter = {
}

export default function deposit () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'delivery_date',
    order: {
      'delivery_date': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
