import { InjectionKey } from 'vue'
import { createStore, Store } from 'vuex'

export type Toast = {
  message: string
  autohide: boolean
  colorVariant: string
}

export interface ToastsStore {
  toasts: Array<Toast>
}

export const toastsStoreKey: InjectionKey<Store<ToastsStore>> = Symbol('toastsStoreKey')

export const toastsStore = createStore<ToastsStore>({
  state: {
    toasts: new Array<Toast>()
  },

  mutations: {
    push (state, toast: Toast) {
      state.toasts.push(toast)
    },
    autoRemove (state) {
      state.toasts = state.toasts.filter((toast) => !toast.autohide)
    }
  },

  actions: {
    push ({ commit }, toast: Toast) {
      commit('push', toast)
    },
    autoRemove ({ commit }) {
      commit('autoRemove')
    }
  },

  getters: {
    toasts: (state) => state.toasts
  }
})
