import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewConverter,
  ListViewSort,
  ListViewIcon,
} from '@/const/component/listView'
import ConstDailyReportDetails from '@/const/transaction/dailyReportDetails'

const constTable = ConstDailyReportDetails()

const baseListColumns: ListViewColumn[] = [{
  id: 'start_time',
  text: constTable.columnLabel.get('start_time'),
  displayField: 'start_time',
  sortField: 'start_time_minute',
  align: 'right',
  edit: {
    type: 'time',
  },
}, {
  id: 'end_time',
  text: constTable.columnLabel.get('end_time'),
  displayField: 'end_time',
  sortField: 'end_time_minute',
  align: 'right',
  edit: {
    type: 'time',
  },
}, {
  id: 'contract_id',
  text: constTable.columnLabel.get('contract_id'),
  displayField: 'contract_id',
  sortField: 'contract_id_number',
  align: 'right',
  edit: {
    type: 'contract',
  },
}, {
  id: 'contract_name',
  text: constTable.columnLabel.get('contract_name'),
  displayField: 'contract_name',
  sortField: 'contract_kana',
  edit: {
    type: 'contract',
  },
}, {
  id: 'within_time',
  text: constTable.columnLabel.get('within_time'),
  displayField: 'within_time',
  sortField: 'within_time_minute',
  align: 'right',
}, {
  id: 'overtime',
  text: constTable.columnLabel.get('overtime'),
  displayField: 'overtime',
  sortField: 'overtime_minute',
  align: 'right',
}, {
  id: 'working_total_time',
  text: constTable.columnLabel.get('working_total_time'),
  displayField: 'working_total_time',
  sortField: 'working_total_time_minute',
  align: 'right',
}, {
  id: 'holiday_time',
  text: constTable.columnLabel.get('holiday_time'),
  displayField: 'holiday_time',
  sortField: 'holiday_time_minute',
  align: 'right',
}, {
  id: 'midnight_time',
  text: constTable.columnLabel.get('midnight_time'),
  displayField: 'midnight_time',
  sortField: 'midnight_time_minute',
  align: 'right',
  /*
}, {
  id: 'correction_required_count',
  text: constTable.columnLabel.get('correction_required_count'),
  displayField: 'correction_required_count',
  sortField: 'correction_required_count',
  align: 'right',
  */
}]

const standardListColumns = {
  dailyReport: [
    'start_time',
    'end_time',
    'contract_id',
    'contract_name',
    'within_time',
    'overtime',
    'working_total_time',
    'holiday_time',
    'midnight_time',
    //'correction_required_count',
  ],
}

const listIcon: ListViewIcon = {
  caution: {
    name: 'triangle-exclamation',
    classes: [
      '--error',
    ]
  },
}
const listConverter: ListViewConverter = {
}

export default function attendance () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'employee_number',
    order: {
      'employee_number': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }

  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listConverter,
    listSort,
    listIcon,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
