import gql from 'graphql-tag'
import {
  ref,
  reactive,
  computed,
  watch,
} from 'vue'
import {
  useQuery,
} from '@vue/apollo-composable'
import {
  SendOrderClient,
  QuerySendOrderClientsArgs,
  QuerySendOrderClientsOrderByColumn,
  SortOrder ,
} from '@/graphql'

const doc = {
  sendOrderClients: {
    select: gql`
      query GetSelectSendOrderClients($orderBy: [QuerySendOrderClientsOrderByOrderByClause!], $first: Int!, $page: Int!) {
        sendOrderClients(orderBy: $orderBy, first: $first, page: $page) {
          data {
            id
            code
            name
            kana
            postcode
            address
            tax_rounding
            total_rounding
          }
          paginatorInfo {
            hasMorePages
          }
        }
      }
    `,
  },
}

const sendOrderClients = () => {
  const items = ref<SendOrderClient[]>([])
  const variables = reactive<QuerySendOrderClientsArgs>({
    first: 100,
    page: 1,
    orderBy: [{ column: QuerySendOrderClientsOrderByColumn.Kana, order: SortOrder.Asc }],
  })
  const options:{ [ key: string ]: string } = {
    fetchPolicy: 'cache-and-network',
  }
  const query = useQuery(doc.sendOrderClients.select, variables, options)
  const result = computed(() => (query.result.value ? query.result.value.sendOrderClients : {}))
  const data = computed(() => result.value.data ?? [])
  const paginator = computed(() => result.value.paginatorInfo ?? {})

  watch(data, (val) => {
    if (!val.length) return
    items.value = items.value.concat(val)
  })
  watch(paginator, (val) => {
    if (variables && val.hasMorePages && variables.page) variables.page++
  })

  return {
    loading: query.loading,
    loadError: query.error,
    items,
  }
}

export default function sendOrderClientSelect () {
  return {
    sendOrderClients,
  }
}
