export const tableName = '社員'

export default function employees () {
  const columnLabel = new Map([
    [ 'id', 'ID' ],
    [ 'employeeNumber', '社員番号' ],
  ])

  return {
    columnLabel,
  }
}
