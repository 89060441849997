export interface DbDivision {
  id: string
  name: string
  kana: string
  display_order: number
  division_type: string
  updated_at: string
  updated_by_name: string
  updated_by_kana: string
}

export default function divisions () {
  const divisions: Array<DbDivision> = [{
    id: '1',
    name: 'エンジニア',
    kana: 'エンジニア',
    display_order: 1,
    division_type: '0',
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '2',
    name: 'システム',
    kana: 'システム',
    display_order: 2,
    division_type: '0',
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '3',
    name: '営業',
    kana: 'エイギョウ',
    display_order: 3,
    division_type: '1',
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '4',
    name: '総務',
    kana: 'ソウム',
    division_type: '1',
    display_order: 4,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }]

  return {
    divisions,
  }
}
