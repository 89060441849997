export const tableName = '売上明細'

export default function journalSalesDetails () {
  const columnLabel = new Map([
    [ 'salesDate', '売上日' ],
    [ 'expenseAccountType', '勘定科目' ],
    [ 'consumptionTaxType', '消費税区分' ],
    [ 'amount', '金額［円］' ],
    [ 'client', '発注者' ],
    [ 'billingTarget', '請求先' ],
    [ 'contractId', '案件番号' ],
    [ 'contractName', '案件名' ],
    [ 'contractKana', '案件名（カナ）' ],
    [ 'contractType', '区分' ],
    [ 'publicPrivateWorksType', '公民' ],
    [ 'contractorType', '元下' ],
    [ 'contractCategory', '案件種別' ],
    [ 'constructionType', '工事区分' ],
  ])

  const expenseType = new Map([
    [ '1', '材料費' ],
    [ '2', '外注費' ],
    [ '3', '経費' ],
    [ '4', '労務費' ],
    [ '0', 'その他' ],
  ])

  return {
    columnLabel,
    expenseType,
  }
}
