export interface DbApprovalDetail {
  id: string
  id_number: number
  circulation_no: number
  status: string
  status_number: number
  approver_name: string
  approver_kana: string
  comment: string
  approval_date_time: string
}

export default function approvalDetails () {
  const approvalDetails: Array<DbApprovalDetail> = [{
    id: '11',
    id_number: 11,
    circulation_no: 1,
    status: 'approval',
    status_number: 20,
    approver_name: '課長Ａ',
    approver_kana: 'カチョウA',
    comment: '',
    approval_date_time: '2023-02-01 11:22:33',
  }, {
    id: '12',
    id_number: 12,
    circulation_no: 2,
    status: 'approval',
    status_number: 20,
    approver_name: '部長Ｂ',
    approver_kana: 'ブチョウB',
    comment: 'コメコメ',
    approval_date_time: '2023-02-08 01:02:03',
  }, {
    id: '13',
    id_number: 13,
    circulation_no: 3,
    status: 'rejection',
    status_number: 21,
    approver_name: '社長Ｃ',
    approver_kana: 'シャチョウC',
    comment: 'コメコメコメコメ',
    approval_date_time: '2023-02-11 04:42:36',
  }]

  return {
    approvalDetails,
  }
}
