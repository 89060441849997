import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import { PadViewItem } from '@/const/component/padView'
import ConstJournalFixedValues from '@/const/transaction/journalFixedValues'

export default function pad () {
  const constTable = ConstJournalFixedValues()
  /*
  const publicPrivateWorksTypeItems = computed(() => {
    return Array.from(constTable.longPublicPrivateWorksType).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })
  const collectionMonthItems = computed(() => {
    return Array.from(constTable.collectionMonth).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })
  const taxRoundingItems = computed(() => {
    return Array.from(constTable.taxRounding).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })
  const amountRoundingItems = computed(() => {
    return Array.from(constTable.amountRounding).map(el => {
      return {
        value: el[0],
        label: el[1],
      }
    })
  })
  */
  const getPadItems = (readonly = true) => {
    const ro = readonly
    const rq = !readonly

    const items: PadViewItem[] = [{
      id: 'journalFixedValue',
      groups: [{
        items: [{
          id: 'journal_type',
          label: constTable.itemLabel.get('journal_type') ?? '',
          type: 'text',
          readonly: true,
          required: false,
        }],
      }, {
        items: [{
          id: 'data_type',
          label: constTable.itemLabel.get('data_type') ?? '',
          type: 'number',
          readonly: ro,
          required: rq,
        }, {
          id: 'journal_entry_format',
          label: constTable.itemLabel.get('journal_entry_format') ?? '',
          type: 'number',
          readonly: ro,
          required: rq,
        }, {
          id: 'slip_type',
          label: constTable.itemLabel.get('slip_type') ?? '',
          type: 'number',
          readonly: ro,
          required: rq,
        }],
      }],
    }, {
      id: 'debit',
      label: constTable.itemLabel.get('debit'),
      groups: [{
        items: [{
          id: 'supplementary_code_debit',
          label: constTable.itemLabel.get('supplementary_code_debit') ?? '',
          type: 'number',
          readonly: ro,
          required: false,
        }, {
          id: 'subdivision_1st_debit',
          label: constTable.itemLabel.get('subdivision_1st_debit') ?? '',
          type: 'number',
          readonly: ro,
          required: false,
        }, {
          id: 'subdivision_2nd_debit',
          label: constTable.itemLabel.get('subdivision_2nd_debit') ?? '',
          type: 'number',
          readonly: ro,
          required: false,
        }, {
          id: 'auxiliary_code_2nd_debit',
          label: constTable.itemLabel.get('auxiliary_code_2nd_debit') ?? '',
          type: 'number',
          readonly: ro,
          required: false,
        }, {
          id: 'consumption_tax_code_debit',
          label: constTable.itemLabel.get('consumption_tax_code_debit') ?? '',
          type: 'number',
          readonly: ro,
          required: false,
        }, {
          id: 'tax_excluded_type_debit',
          label: constTable.itemLabel.get('tax_excluded_type_debit') ?? '',
          type: 'number',
          readonly: ro,
          required: false,
        }],
      }],
    }, {
      id: 'credit',
      label: constTable.itemLabel.get('credit'),
      groups: [{
        items: [{
          id: 'supplementary_code_credit',
          label: constTable.itemLabel.get('supplementary_code_credit') ?? '',
          type: 'number',
          readonly: ro,
          required: false,
        }, {
          id: 'subdivision_1st_credit',
          label: constTable.itemLabel.get('subdivision_1st_credit') ?? '',
          type: 'number',
          readonly: ro,
          required: false,
        }, {
          id: 'subdivision_2nd_credit',
          label: constTable.itemLabel.get('subdivision_2nd_credit') ?? '',
          type: 'number',
          readonly: ro,
          required: false,
        }, {
          id: 'auxiliary_code_2nd_credit',
          label: constTable.itemLabel.get('auxiliary_code_2nd_credit') ?? '',
          type: 'number',
          readonly: ro,
          required: false,
        }, {
          id: 'consumption_tax_code_credit',
          label: constTable.itemLabel.get('consumption_tax_code_credit') ?? '',
          type: 'number',
          readonly: ro,
          required: false,
        }, {
          id: 'tax_excluded_type_credit',
          label: constTable.itemLabel.get('tax_excluded_type_credit') ?? '',
          type: 'number',
          readonly: ro,
          required: false,
        }],
      }],
    }]

    return items
  }

  return {
    /*
    collectionMonthItems,
    publicPrivateWorksTypeItems,
    taxRoundingItems,
    amountRoundingItems,
    */
    getPadItems,
  }
}
