import gql from 'graphql-tag'
import {
  ref,
  reactive,
  computed,
  watch,
} from 'vue'
import {
  useQuery,
} from '@vue/apollo-composable'
import {
  Supplier,
  QuerySuppliersArgs,
  QuerySuppliersOrderByColumn,
  SortOrder ,
} from '@/graphql'
import tools from '@/functions/tools'

const doc = {
  suppliers: {
    select: gql`
      query GetSelectSuppliers($orderBy: [QuerySuppliersOrderByOrderByClause!], $first: Int!, $page: Int!) {
        suppliers(orderBy: $orderBy, first: $first, page: $page) {
          data {
            id
            code
            name
            kana
            postcode
            address
            tax_rounding
            total_rounding
          }
          paginatorInfo {
            hasMorePages
          }
        }
      }
    `,
  },
}

const suppliers = () => {
  const { sleep } = tools()
  const items = ref<Supplier[]>([])
  const variables = reactive<QuerySuppliersArgs>({
    first: 100,
    page: 1,
    orderBy: [{ column: QuerySuppliersOrderByColumn.Kana, order: SortOrder.Asc }],
  })
  const options:{ [ key: string ]: string } = {
    fetchPolicy: 'cache-and-network',
  }
  const query = useQuery(doc.suppliers.select, variables, options)
  const result = computed(() => (query.result.value ? query.result.value.suppliers : {}))
  const data = computed(() => result.value.data ?? [])
  const paginator = computed(() => result.value.paginatorInfo ?? {})

  watch(data, (val) => {
    if (!val.length) return
    items.value = items.value.concat(val)
  })
  watch(paginator, (val) => {
    if (variables && val.hasMorePages && variables.page) variables.page++
  })

  const complete = async () => {
    const timeout = {
      id: 0,
      interval: 6000,
      value: false,
    }
    const check = {
      interval: 200,
      value: true,
    }
    return new Promise(async (resolve, reject) => {
      if (!query.loading.value) resolve(0)
      timeout.id = setTimeout(() => {
        clearTimeout(timeout.id)
        timeout.value = true
      }, timeout.interval)
      while (check.value) {
        if (timeout.value) {
          check.value = false
          reject('timeout')
        } else if (!query.loading.value) {
          clearTimeout(timeout.id)
          check.value = false
          resolve(0)
        } else {
          await sleep(check.interval)
        }
      }
    })
  }

  return {
    loading: query.loading,
    loadError: query.error,
    items,
    complete,
  }
}

export default function supplierSelect () {
  return {
    suppliers,
  }
}
