export const tableName = '拠点'

export default function bases () {
  const columnLabel = new Map([
    [ 'id', 'ID' ],
    [ 'name', '拠点名' ],
    [ 'displayOrder', '表示順' ],
    [ 'postcode', '郵便番号' ],
    [ 'address', '住所' ],
  ])

  return {
    columnLabel,
  }
}
