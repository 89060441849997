import {
  Construction,
  Supplier,
  Base,
  Employee,
  SendOrderStatus,
  ExpenseType,
  Rounding,
  SendOrderSearchCondition,
} from '@/graphql'

export const tableName = '発注品目'

export interface PurchaseItem {
  //id: number
  //id: string
  //send_order_id: number
  //send_order_id: string
  //send_order_revision: number
  id: string
  //change_id: string
  details_line_no: number
  detail_type: number      // 1: 注文品 / 2: メモ
  //change_type: number                 // 新規／代替品／単価変更／発注残変更 など（詳細は未定）
  //change_items: number                // bit flags 0: 摘要、1: 単位、2: 経費種別、3: 経費勘定科目種別、4: 発注残を管理しない、5: 数量、6: 単価、7: 消費税率
  //transaction_type: number            // 0: なし／1: マイナス／2: プラス
  //alternative_send_order_detail_id: number | null
  model_number: string
  description: string
  expense_type: ExpenseType | null         // 1: 材料費 / 2:外注費 / 3: 経費 / 4:労務費
  expense_account_type: number | null
  //not_manage_backlog: number | null   // 0: 管理する / 1: 管理しない
  quantity: number | null
  unit: string | null
  unit_price: number | null
  amount_including_tax: number | null
  amount_excluding_tax: number | null
  consumption_tax_amount: number | null
  consumption_tax_type: string | null
  consumption_tax_id: string | null
  consumption_tax_rate: number
  //deliveried_quantity: number | null
  //payment_amount: number | null
  //remaining_quantity?: number
  checked?: boolean
  //icon?: string | null
}

export default function purchaseItems () {
  const searchLabel = new Map([
    [ 'modelNumber', '型番' ],
    [ 'description', '品名／規格' ],
  ])
  const columnLabel = new Map([
    [ 'detailsLineNo', 'No.' ],
    [ 'modelNumber', '型番' ],
    [ 'description', '品名／規格' ],
    [ 'expenseType', '経費種別' ],
    [ 'expenseAccountType', '勘定科目' ],
    //[ 'notManageBacklog', '発注残管理' ],
    [ 'quantity', '数量' ],
    [ 'unit', '単位' ],
    [ 'unitPrice', '単価［円］' ],
    [ 'amountIncludingTax', '税込金額［円］' ],
    [ 'amountExcludingTax', '税抜金額［円］' ],
    [ 'consumptionTaxAmount', '消費税額［円］' ],
    [ 'consumptionTaxType', '消費税区分' ],
    [ 'consumptionTaxRate', '消費税率' ],
    [ 'deliveriedQuantity', '仕入数' ],
    [ 'remainingQuantity', '発注残数' ],
    [ 'orderDate', '発注日' ],
    [ 'orderBy', '発注者' ],
  ])

  const expenseType = new Map([
    [ 'MATERIAL', '材料費' ],
    [ 'OUTSOURCING', '外注費' ],
    [ 'EXPENSES', '経費' ],
    [ 'LABOR', '労務費' ],
  ])
  const notManageBacklog = new Map([
    [ '0', 'する' ],
    [ '1', 'しない' ],
  ])

  return {
    searchLabel,
    columnLabel,
    expenseType,
    notManageBacklog,
  }
}
