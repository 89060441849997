import {
  reactive,
} from 'vue'
import {
  ListBoxItem,
} from '@/const/component/listBox'
import {
  SearchFilterItem,
  SearchFilterValue,
} from '@/const/component/searchFilterList'

const searchItem: {
  menuItems: ListBoxItem[]
  filterItems: SearchFilterItem[]
} = {
  menuItems: [{
    id: 'editedMyself',
    group: 'myself',
    text: '自身で編集したもの',
  }, {
    id: 'ownBase',
    group: 'myself',
    text: '自身の拠点が担当のもの',
  }, {
    id: 'salesRep',
    group: 'myself',
    text: '自身が営業担当のもの',
  }, {
    id: 'siteAgent',
    group: 'myself',
    text: '自身が現場代理人のもの',
  }, {
    id: 'constructionRep',
    group: 'myself',
    text: '自身が施工担当者のもの',
  }],
  filterItems: [{
    id: 'contract_job_amount',
    label: '請負金額［円］',
    type: 'bigint',
  }, {
    id: 'target_marginal_profit',
    label: '目標限界利益［円］',
    type: 'bigint',
  }, {
    id: 'marginal_profit',
    label: '限界利益［円］',
    type: 'bigint',
  }, {
    id: 'variable_cost',
    label: '変動費［円］',
    type: 'bigint',
  }, {
    id: 'process_status',
    label: '工程ステータス',
    type: 'check',
    items: [{
      id: 0,
      label: '引合',
    }, {
      id: 1,
      label: '見積',
    }, {
      id: 2,
      label: '失注',
    }, {
      id: 3,
      label: '受注',
    }, {
      id: 4,
      label: '中断中',
    }, {
      id: 5,
      label: '完成',
    }],
  }, {
    id: 'bill_status',
    label: '請求ステータス',
    type: 'check',
    items: [{
      id: 0,
      label: '完成前',
    }, {
      id: 1,
      label: '未請求',
    }, {
      id: 2,
      label: '一部請求',
    }, {
      id: 3,
      label: '請求済み',
    }],
}]
}

export default function project () {
  const searchValue = reactive<{
    searchText: string | null
    menu: { [ key: string ]: string | null }
    filter: SearchFilterValue
  }>({
    searchText: null,
    menu: {
      myself: 'editedMyself',
    },
    filter: {
      contract_job_amount: {
        type: 'off',
        single: null,
        range: [ null, null ],
      },
      target_marginal_profit: {
        type: 'off',
        single: null,
        range: [ null, null ],
      },
      marginal_profit: {
        type: 'off',
        single: null,
        range: [ null, null ],
      },
      variable_cost: {
        type: 'off',
        single: null,
        range: [ null, null ],
      },
      process_status: [
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      bill_status: [
        false,
        false,
        false,
        false,
      ],
    },
  })

  return {
    searchItem,
    searchValue,
  }
}
