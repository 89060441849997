import {
  inject,
  ref,
  reactive,
  computed,
} from 'vue'
import ConstContracts from '@/const/transaction/contracts'
import {
  SearchBoxValue,
  SearchBoxItem,
} from '@/const/component/searchBox'

const constContracts = ConstContracts()

const items = [
  //'processStatus',
  'id',
  //'projectId',
  'name',
  'client',
  //'contractType',
  //'publicPrivateWorksType',
  //'contractorType',
  //'contractCategory',
  //'constructionType',
  //'division',
  //'base',
  'constructionLocation',
  'salesRep',
  'siteAgent',
  //'billingTarget',
  //'contractPeriodStartDate',
  //'contractPeriodEndDate',
  'note',
  //'ordersAccuracy',
  //'destinationTarget',
  /*
  'predictedContractJobAmount',
  'estimatedMaterialCost',
  'estimatedOutsourcingCost',
  'estimatedExpenseCost',
  'estimatedLaborCost',
  'targetMarginalProfit',
  'targetMarginalProfitRatio',
  'targetProductivity',
  'targetGrossProfit',
  'estimatedSalesDate',
  'beforeCost',
  'estimateId',
  'contractAmountBudget',
  'materialCostBudget',
  'outsourcingCostBudget',
  'expenseCostBudget',
  'laborCostBudget',
  'marginalProfitBudget',
  'marginalProfitRatioBudget',
  'productivityBudget',
  'grossProfitBudget',
  'expectedSalesDate',
  'constructionRep',
  'constructionTarget',
  'contractJobAmount',
  'marginalProfit',
  'marginalProfitRatio',
  'marginalProfitBudgetBalance',
  'grossProfit',
  'materialCost',
  'materialCostBalance',
  'outsourcingCost',
  'outsourcingCostBalance',
  'expenseCost',
  'expenseCostBalance',
  'laborCost',
  'laborCostBalance',
  'purchaseAmountBalance',
  'completeDate',
  'billDate',
  'salesDate',
  'autoCompleteDate',
  */
]

export default function search () {
  const searchItems = computed(() => {
    return items.map(el => {
      return {
        id: el,
        label: constContracts.searchLabel.get(el),
      } as SearchBoxItem
    })
  })

  return {
    searchItems,
  }
}
