export interface DbSettings {
  id: string
  order_no: number
  name: string
  contract_view_project: boolean
  contract_view_general: boolean
  contract_view_prospect: boolean
  contract_view_progress: boolean
  contract_view_completed: boolean
  contract_editProject_create: boolean
  contract_editProject_update: boolean
  contract_editProject_delete: boolean
  contract_editContract_information: boolean
  contract_editContract_create: boolean
  contract_editContract_update: boolean
  contract_editContract_delete: boolean
  contract_editContract_accept: boolean
  contract_editContract_complete: boolean
  contract_editContract_lostOrder: boolean
  contract_editContract_acceptCancel: boolean
  contract_editContract_completeCancel: boolean
  contract_editContract_lostOrderCancel: boolean
  estimate_view_general: boolean
  estimate_view_draft: boolean
  estimate_view_waiting: boolean
}

export default function settings () {
  const settings: Array<DbSettings> = [{
    id: '1',
    order_no: 1,
    name: '営業',
    contract_view_project: true,
    contract_view_general: true,
    contract_view_prospect: true,
    contract_view_progress: true,
    contract_view_completed: true,
    contract_editProject_create: true,
    contract_editProject_update: true,
    contract_editProject_delete: true,
    contract_editContract_information: true,
    contract_editContract_create: false,
    contract_editContract_update: true,
    contract_editContract_delete: false,
    contract_editContract_accept: true,
    contract_editContract_complete: true,
    contract_editContract_lostOrder: true,
    contract_editContract_acceptCancel: true,
    contract_editContract_completeCancel: true,
    contract_editContract_lostOrderCancel: false,
    estimate_view_general: true,
    estimate_view_draft: true,
    estimate_view_waiting: false,
  }, {
    id: '2',
    order_no: 2,
    name: 'エンジニア',
    contract_view_project: true,
    contract_view_general: true,
    contract_view_prospect: true,
    contract_view_progress: false,
    contract_view_completed: true,
    contract_editProject_create: true,
    contract_editProject_update: true,
    contract_editProject_delete: true,
    contract_editContract_information: true,
    contract_editContract_create: true,
    contract_editContract_update: true,
    contract_editContract_delete: false,
    contract_editContract_accept: true,
    contract_editContract_complete: true,
    contract_editContract_lostOrder: true,
    contract_editContract_acceptCancel: true,
    contract_editContract_completeCancel: false,
    contract_editContract_lostOrderCancel: false,
    estimate_view_general: true,
    estimate_view_draft: true,
    estimate_view_waiting: false,
  }, {
    id: '3',
    order_no: 3,
    name: 'システム',
    contract_view_project: true,
    contract_view_general: true,
    contract_view_prospect: true,
    contract_view_progress: false,
    contract_view_completed: true,
    contract_editProject_create: true,
    contract_editProject_update: true,
    contract_editProject_delete: true,
    contract_editContract_information: true,
    contract_editContract_create: true,
    contract_editContract_update: true,
    contract_editContract_delete: false,
    contract_editContract_accept: true,
    contract_editContract_complete: false,
    contract_editContract_lostOrder: true,
    contract_editContract_acceptCancel: true,
    contract_editContract_completeCancel: true,
    contract_editContract_lostOrderCancel: false,
    estimate_view_general: true,
    estimate_view_draft: true,
    estimate_view_waiting: false,
  }, {
    id: '4',
    order_no: 4,
    name: '総務',
    contract_view_project: false,
    contract_view_general: true,
    contract_view_prospect: false,
    contract_view_progress: false,
    contract_view_completed: true,
    contract_editProject_create: true,
    contract_editProject_update: true,
    contract_editProject_delete: false,
    contract_editContract_information: true,
    contract_editContract_create: false,
    contract_editContract_update: true,
    contract_editContract_delete: false,
    contract_editContract_accept: true,
    contract_editContract_complete: false,
    contract_editContract_lostOrder: false,
    contract_editContract_acceptCancel: false,
    contract_editContract_completeCancel: false,
    contract_editContract_lostOrderCancel: false,
    estimate_view_general: true,
    estimate_view_draft: true,
    estimate_view_waiting: false,
  }, {
    id: '5',
    order_no: 5,
    name: 'システム管理者',
    contract_view_project: true,
    contract_view_general: true,
    contract_view_prospect: true,
    contract_view_progress: true,
    contract_view_completed: true,
    contract_editProject_create: true,
    contract_editProject_update: true,
    contract_editProject_delete: true,
    contract_editContract_information: true,
    contract_editContract_create: true,
    contract_editContract_update: true,
    contract_editContract_delete: true,
    contract_editContract_accept: true,
    contract_editContract_complete: true,
    contract_editContract_lostOrder: true,
    contract_editContract_acceptCancel: true,
    contract_editContract_completeCancel: true,
    contract_editContract_lostOrderCancel: true,
    estimate_view_general: true,
    estimate_view_draft: true,
    estimate_view_waiting: true,
  }]

  return {
    settings,
  }
}
