export interface DbPurchaseDeliveryTotal {
  id: string,
  delivery_month: string,
  supplier_id: string,
  supplier_code: string,
  supplier_name: string,
  supplier_kana: string,
  purchase_amount_including_tax: bigint,
  purchase_amount_excluding_tax: bigint,
  //consumptionTaxAmount: bigint,
  budget_amount_including_tax: bigint,
  budget_amount_excluding_tax: bigint,
}

export default function purchaseDeliveryTotals () {
  const purchaseDeliveryTotals: Array<DbPurchaseDeliveryTotal> = [{
    id: '1',
    delivery_month: '2022-01',
    supplier_id: '101',
    supplier_code: '10001234',
    supplier_name: '楽天市場',
    supplier_kana: 'ラクテンイチバ',
    purchase_amount_including_tax: 123456n,
    purchase_amount_excluding_tax: 112233n,
    budget_amount_including_tax: 123456n,
    budget_amount_excluding_tax: 112233n,
  }, {
    id: '2',
    delivery_month: '2022-01',
    supplier_id: '102',
    supplier_code: '10109876',
    supplier_name: '楽天証券',
    supplier_kana: 'ラクテンショウケン',
    purchase_amount_including_tax: 2233445n,
    purchase_amount_excluding_tax: 2030405n,
    budget_amount_including_tax: 2034453n,
    budget_amount_excluding_tax: 1849503n,
  }, {
    id: '3',
    delivery_month: '2022-01',
    supplier_id: '103',
    supplier_code: '10204444',
    supplier_name: '楽天トラベル',
    supplier_kana: 'ラクテントラベル',
    purchase_amount_including_tax: 100256n,
    purchase_amount_excluding_tax: 91142n,
    budget_amount_including_tax: 43956n,
    budget_amount_excluding_tax: 39960n,
  }, {
    id: '4',
    delivery_month: '2022-01',
    supplier_id: '104',
    supplier_code: '55005555',
    supplier_name: 'Amazon',
    supplier_kana: 'アマゾン',
    purchase_amount_including_tax: 1006544n,
    purchase_amount_excluding_tax: 915040n,
    budget_amount_including_tax: 921545n,
    budget_amount_excluding_tax: 837769n,
  }, {
    id: '5',
    delivery_month: '2022-01',
    supplier_id: '105',
    supplier_code: '32001117',
    supplier_name: 'モノタロウ',
    supplier_kana: 'モノタロウ',
    purchase_amount_including_tax: 3333333n,
    purchase_amount_excluding_tax: 3030303n,
    budget_amount_including_tax: 4444444n,
    budget_amount_excluding_tax: 4040404n,
    /*
  }, {
    id: '6',
    id_number: 6,
    purchase_month: '2022-02',
    supplier_id: '101',
    supplier_name: '楽天市場',
    supplier_kana: 'ラクテンイチバ',
    purchase_amount: 100256,
    discount_return_amount: 56300,
    total_purchase_amount: 43956,
  }, {
    id: '7',
    id_number: 7,
    purchase_month: '2022-02',
    supplier_id: '102',
    supplier_name: '楽天証券',
    supplier_kana: 'ラクテンショウケン',
    purchase_amount: 10000000,
    discount_return_amount: 0,
    total_purchase_amount: 10000000,
  }, {
    id: '8',
    id_number: 8,
    purchase_month: '2022-02',
    supplier_id: '103',
    supplier_name: '楽天トラベル',
    supplier_kana: 'ラクテントラベル',
    purchase_amount: 564231,
    discount_return_amount: 123,
    total_purchase_amount: 564108,
  }, {
    id: '9',
    id_number: 9,
    purchase_month: '2022-02',
    supplier_id: null,
    supplier_name: 'Amazon',
    supplier_kana: 'アマゾン',
    purchase_amount: 56442310,
    discount_return_amount: 123000,
    total_purchase_amount: 56319310,
  }, {
    id: '10',
    id_number: 10,
    purchase_month: '2022-02',
    supplier_id: null,
    supplier_name: 'モノタロウ',
    supplier_kana: 'モノタロウ',
    purchase_amount: 8969801,
    discount_return_amount: 0,
    total_purchase_amount: 8969801,
  }, {
    id: '11',
    id_number: 11,
    purchase_month: '2022-03',
    supplier_id: '101',
    supplier_name: '楽天市場',
    supplier_kana: 'ラクテンイチバ',
    purchase_amount: 100256,
    discount_return_amount: 56300,
    total_purchase_amount: 43956,
  }, {
    id: '12',
    id_number: 12,
    purchase_month: '2022-03',
    supplier_id: '102',
    supplier_name: '楽天証券',
    supplier_kana: 'ラクテンショウケン',
    purchase_amount: 10000000,
    discount_return_amount: 0,
    total_purchase_amount: 10000000,
  }, {
    id: '13',
    id_number: 13,
    purchase_month: '2022-03',
    supplier_id: '103',
    supplier_name: '楽天トラベル',
    supplier_kana: 'ラクテントラベル',
    purchase_amount: 564231,
    discount_return_amount: 123,
    total_purchase_amount: 564108,
  }, {
    id: '14',
    id_number: 14,
    purchase_month: '2022-03',
    supplier_id: null,
    supplier_name: 'Amazon',
    supplier_kana: 'アマゾン',
    purchase_amount: 56442310,
    discount_return_amount: 123000,
    total_purchase_amount: 56319310,
  }, {
    id: '15',
    id_number: 15,
    purchase_month: '2022-03',
    supplier_id: null,
    supplier_name: 'モノタロウ',
    supplier_kana: 'モノタロウ',
    purchase_amount: 8969801,
    discount_return_amount: 0,
    total_purchase_amount: 8969801,
  }, {
    id: '16',
    id_number: 16,
    purchase_month: '2022-04',
    supplier_id: '101',
    supplier_name: '楽天市場',
    supplier_kana: 'ラクテンイチバ',
    purchase_amount: 100256,
    discount_return_amount: 56300,
    total_purchase_amount: 43956,
  }, {
    id: '17',
    id_number: 17,
    purchase_month: '2022-04',
    supplier_id: '102',
    supplier_name: '楽天証券',
    supplier_kana: 'ラクテンショウケン',
    purchase_amount: 10000000,
    discount_return_amount: 0,
    total_purchase_amount: 10000000,
  }, {
    id: '18',
    id_number: 18,
    purchase_month: '2022-04',
    supplier_id: '103',
    supplier_name: '楽天トラベル',
    supplier_kana: 'ラクテントラベル',
    purchase_amount: 564231,
    discount_return_amount: 123,
    total_purchase_amount: 564108,
  }, {
    id: '19',
    id_number: 19,
    purchase_month: '2022-04',
    supplier_id: null,
    supplier_name: 'Amazon',
    supplier_kana: 'アマゾン',
    purchase_amount: 56442310,
    discount_return_amount: 123000,
    total_purchase_amount: 56319310,
  }, {
    id: '20',
    id_number: 20,
    purchase_month: '2022-04',
    supplier_id: null,
    supplier_name: 'モノタロウ',
    supplier_kana: 'モノタロウ',
    purchase_amount: 8969801,
    discount_return_amount: 0,
    total_purchase_amount: 8969801,
  }, {
    id: '21',
    id_number: 21,
    purchase_month: '2022-05',
    supplier_id: '101',
    supplier_name: '楽天市場',
    supplier_kana: 'ラクテンイチバ',
    purchase_amount: 100256,
    discount_return_amount: 56300,
    total_purchase_amount: 43956,
  }, {
    id: '22',
    id_number: 22,
    purchase_month: '2022-05',
    supplier_id: '102',
    supplier_name: '楽天証券',
    supplier_kana: 'ラクテンショウケン',
    purchase_amount: 10000000,
    discount_return_amount: 0,
    total_purchase_amount: 10000000,
  }, {
    id: '23',
    id_number: 23,
    purchase_month: '2022-05',
    supplier_id: '103',
    supplier_name: '楽天トラベル',
    supplier_kana: 'ラクテントラベル',
    purchase_amount: 564231,
    discount_return_amount: 123,
    total_purchase_amount: 564108,
  }, {
    id: '24',
    id_number: 24,
    purchase_month: '2022-05',
    supplier_id: null,
    supplier_name: 'Amazon',
    supplier_kana: 'アマゾン',
    purchase_amount: 56442310,
    discount_return_amount: 123000,
    total_purchase_amount: 56319310,
  }, {
    id: '25',
    id_number: 25,
    purchase_month: '2022-05',
    supplier_id: null,
    supplier_name: 'セブンイレブン 大信増見店',
    supplier_kana: 'セブンイレブン　タイシンマスミテン',
    purchase_amount: 1240,
    discount_return_amount: 0,
    total_purchase_amount: 1240,
  }, {
    id: '26',
    id_number: 26,
    purchase_month: '2022-06',
    supplier_id: '101',
    supplier_name: '楽天市場',
    supplier_kana: 'ラクテンイチバ',
    purchase_amount: 100256,
    discount_return_amount: 56300,
    total_purchase_amount: 43956,
  }, {
    id: '27',
    id_number: 27,
    purchase_month: '2022-06',
    supplier_id: '102',
    supplier_name: '楽天証券',
    supplier_kana: 'ラクテンショウケン',
    purchase_amount: 10000000,
    discount_return_amount: 0,
    total_purchase_amount: 10000000,
  }, {
    id: '28',
    id_number: 28,
    purchase_month: '2022-06',
    supplier_id: '103',
    supplier_name: '楽天トラベル',
    supplier_kana: 'ラクテントラベル',
    purchase_amount: 564231,
    discount_return_amount: 123,
    total_purchase_amount: 564108,
  }, {
    id: '29',
    id_number: 29,
    purchase_month: '2022-06',
    supplier_id: null,
    supplier_name: 'セブンイレブン 天栄松本店',
    supplier_kana: 'セブンイレブン　テネイマツモトテン',
    purchase_amount: 4520,
    discount_return_amount: 0,
    total_purchase_amount: 4520,
  }, {
    id: '30',
    id_number: 30,
    purchase_month: '2022-06',
    supplier_id: null,
    supplier_name: 'モノタロウ',
    supplier_kana: 'モノタロウ',
    purchase_amount: 8969801,
    discount_return_amount: 0,
    total_purchase_amount: 8969801,
    */
  }]

  return {
    purchaseDeliveryTotals,
  }
}
