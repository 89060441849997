export default function tools () {
  const sleep = (msec: number) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(0)
      }, msec)
    })
  }
  const copyToClipboard = (value: string) => {
    return new Promise((resolve, reject) => {
      navigator.clipboard.writeText(value)
        .then(() => resolve(0))
        .catch(e => reject(e))
    })
  }
  const stringToInt = (value: string) => {
    try {
      const valnum = parseInt(value, 10)
      if (isNaN(valnum) || valnum.toString() != value) return null
      return valnum
    } catch {
      return null
    }
  }
  const stringToBigInt = (value: string) => {
    try {
      return BigInt(value)
    } catch {
      return null
    }
  }
  const numberToString = (value: number | null | undefined) => {
    try {
      if (value === null || value === undefined) return null
      return value.toString()
    } catch {
      return null
    }
  }

  return {
    sleep,
    copyToClipboard,
    stringToInt,
    stringToBigInt,
    numberToString,
  }
}
