export interface DbPlanDetail {
  id: string
  detail_type: number
  construction?: string
  //construction_id_number?: number
  expense_type?: string
  item_name?: string
  cost_rate?: number
  quantity_cost?: number
  quantity_estimate?: number
  unit_cost?: string
  unit_estimate?: string
  unit_price_cost?: number
  unit_price_estimate?: number
  amount_cost?: number
  amount_estimate?: number
  consumption_tax_rate_cost?: string
  consumption_tax_rate_estimate?: string
  icon?: string
}

export default function planDetails () {
  const planDetails: Array<DbPlanDetail> = [{
    id: '1',
    detail_type: 2,
    construction: '電気工事',
    //construction_id_number: 1,
    icon: 'caution',
  }, {
    id: '1-1-1-1',
    detail_type: 1,
    //construction_id_number: 1,
    expense_type: '1',
    item_name: '600V VVF',
    cost_rate: 75,
    quantity_cost: 100,
    quantity_estimate: 100,
    unit_cost: '巻',
    unit_estimate: '巻',
    unit_price_cost: 9690,
    unit_price_estimate: 12920,
    amount_cost: 969000,
    amount_estimate: 1292000,
    consumption_tax_rate_cost: '外税 10％',
    consumption_tax_rate_estimate: '外税 10％',
icon: 'caution',
  }, {
    id: '1-1-2-1',
    detail_type: 1,
    //construction_id_number: 1,
    expense_type: '2',
    item_name: 'コネクタ加工',
    cost_rate: 60,
    quantity_cost: 200,
    quantity_estimate: 200,
    unit_cost: '式',
    unit_estimate: '式',
    unit_price_cost: 1000,
    unit_price_estimate: 1667,
    amount_cost: 200000,
    amount_estimate: 333400,
    consumption_tax_rate_cost: '外税 10％',
    consumption_tax_rate_estimate: '外税 10％',
  }, {
    id: '1-1-3-1',
    detail_type: 1,
    expense_type: '4',
    item_name: 'ケーブル配線作業費',
    cost_rate: 50,
    quantity_cost: 1,
    quantity_estimate: 1,
    unit_cost: '式',
    unit_estimate: '式',
    unit_price_cost: 531360,
    unit_price_estimate: 1062720,
    amount_cost: 531360,
    amount_estimate: 1062720,
    consumption_tax_rate_cost: '外税 10％',
    consumption_tax_rate_estimate: '外税 10％',
  }, {
    detail_type: 1,
    id: '1-1-3-2',
    expense_type: '4',
    item_name: '試験調整費',
    cost_rate: 60,
    quantity_cost: 1,
    quantity_estimate: 1,
    unit_cost: '式',
    unit_estimate: '式',
    unit_price_cost: 623200,
    unit_price_estimate: 1038667,
    consumption_tax_rate_cost: '外税 10％',
    consumption_tax_rate_estimate: '外税 10％',
    amount_cost: 623200,
    amount_estimate: 1038667,
  }, {
    id: '2',
    detail_type: 2,
    //status: 'LAN設備工事',
    construction: 'LAN設備工事',
    //construction_id_number: 2,
}, {
    detail_type: 1,
    id: '1-2-1-2',
    expense_type: '1',
    item_name: 'ケーブル保護材（雑材料・消耗品含む）',
    cost_rate: 60,
    quantity_cost: 1,
    quantity_estimate: 1,
    unit_cost: '式',
    unit_estimate: '式',
    unit_price_cost: 16190,
    unit_price_estimate: 26984,
    consumption_tax_rate_cost: '外税 10％',
    consumption_tax_rate_estimate: '外税 10％',
    amount_cost: 16190,
    amount_estimate: 26984,
  }, {
    detail_type: 1,
    id: '1-2-2-1',
    expense_type: '4',
    item_name: 'ケーブル配線・成端作業費',
    cost_rate: 50,
    quantity_cost: 1,
    quantity_estimate: 1,
    unit_cost: '式',
    unit_estimate: '式',
    unit_price_cost: 943680,
    unit_price_estimate: 1887360,
    consumption_tax_rate_cost: '外税 10％',
    consumption_tax_rate_estimate: '外税 10％',
    amount_cost: 943680,
    amount_estimate: 1887360,
}, {
    detail_type: 1,
    id: '1-2-2-2',
    expense_type: '4',
    item_name: 'ＡＰ取付・調整費',
    cost_rate: 50,
    quantity_cost: 1,
    quantity_estimate: 1,
    unit_cost: '式',
    unit_estimate: '式',
    unit_price_cost: 177120,
    unit_price_estimate: 354240,
    consumption_tax_rate_cost: '外税 10％',
    consumption_tax_rate_estimate: '外税 10％',
    amount_cost: 177120,
    amount_estimate: 354240,
}, {
    detail_type: 1,
    id: '1-2-2-3',
    expense_type: '4',
    item_name: '試験調整費',
    cost_rate: 60,
    quantity_cost: 1,
    quantity_estimate: 1,
    unit_cost: '式',
    unit_estimate: '式',
    unit_price_cost: 592040,
    unit_price_estimate: 986734,
    consumption_tax_rate_cost: '外税 10％',
    consumption_tax_rate_estimate: '外税 10％',
    amount_cost: 592040,
    amount_estimate: 986734,
}, {
    detail_type: 1,
    id: '1-2-3-1',
    expense_type: '3',
    item_name: '諸経費',
    cost_rate: 100,
    quantity_cost: 1,
    quantity_estimate: 1,
    unit_cost: '式',
    unit_estimate: '式',
    unit_price_cost: 150000,
    unit_price_estimate: 150000,
    consumption_tax_rate_cost: '外税 10％',
    consumption_tax_rate_estimate: '外税 10％',
    amount_cost: 150000,
    amount_estimate: 150000,
  }]

  return {
    planDetails,
  }
}
