export const tableName = '受注確度'

export default function orderProbabilities () {
  const itemLabel = new Map([
    [ 'id', 'ID' ],
    [ 'base', '拠点' ],
    [ 'division', '部門' ],
    [ 'unofficial', '内示［％］' ],
    [ 'rank_a', 'Ａ［％］' ],
    [ 'rank_b', 'Ｂ［％］' ],
    [ 'rank_c', 'Ｃ［％］' ],
    [ 'rank_d', 'Ｄ［％］' ],
    [ 'rank_e', 'Ｅ［％］' ],
    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])
  const searchLabel = new Map([
  ])
  const columnLabel = new Map([
    [ 'id', 'ID' ],
    [ 'base', '拠点' ],
    [ 'division', '部門' ],
    [ 'unofficial', '内示［％］' ],
    [ 'rank_a', 'Ａ［％］' ],
    [ 'rank_b', 'Ｂ［％］' ],
    [ 'rank_c', 'Ｃ［％］' ],
    [ 'rank_d', 'Ｄ［％］' ],
    [ 'rank_e', 'Ｅ［％］' ],
    [ 'updated_at', '最終更新日時' ],
    [ 'updated_by', '最終更新者' ],
  ])

  return {
    itemLabel,
    searchLabel,
    columnLabel,
  }
}
