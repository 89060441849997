export interface DbJournalFixedValue {
  id: string
  journal_type: string
  journal_type_number: number
  data_type: number
  journal_entry_format: number
  slip_type: number | null
  supplementary_code_debit: number | null
  subdivision_1st_debit: number | null
  subdivision_2nd_debit: number | null
  auxiliary_code_2nd_debit: number | null
  //consumption_tax_code_debit: number | null
  //consumption_tax_rate_debit: number | null
  tax_excluded_type_debit: number | null
  supplementary_code_credit: number | null
  subdivision_1st_credit: number | null
  subdivision_2nd_credit: number | null
  auxiliary_code_2nd_credit: number | null
  //consumption_tax_code_credit: number | null
  //consumption_tax_rate_credit: number | null
  tax_excluded_type_credit: number | null
  updated_at: string
  updated_by_name: string
  updated_by_kana: string
}

export default function journalFixedValues () {
  const journalFixedValues: Array<DbJournalFixedValue> = [{
    id: '1',
    journal_type: '売上',
    journal_type_number: 1,
    data_type: 90,
    journal_entry_format: 3,
    slip_type: 0,
    supplementary_code_debit: null,
    subdivision_1st_debit: 3,
    subdivision_2nd_debit: null,
    auxiliary_code_2nd_debit: null,
    //consumption_tax_code_debit: 0,
    //consumption_tax_rate_debit: null,
    tax_excluded_type_debit: 0,
    supplementary_code_credit: null,
    subdivision_1st_credit: null,
    subdivision_2nd_credit: null,
    auxiliary_code_2nd_credit: null,
    //consumption_tax_code_credit: 10,
    //consumption_tax_rate_credit: 4,
    tax_excluded_type_credit: 0,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '2',
    journal_type: '入金',
    journal_type_number: 1,
    data_type: 91,
    journal_entry_format: 3,
    slip_type: 0,
    supplementary_code_debit: null,
    subdivision_1st_debit: 3,
    subdivision_2nd_debit: null,
    auxiliary_code_2nd_debit: null,
    //consumption_tax_code_debit: null,
    //consumption_tax_rate_debit: null,
    tax_excluded_type_debit: 0,
    supplementary_code_credit: null,
    subdivision_1st_credit: 3,
    subdivision_2nd_credit: null,
    auxiliary_code_2nd_credit: null,
    //consumption_tax_code_credit: null,
    //consumption_tax_rate_credit: null,
    tax_excluded_type_credit: 0,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '3',
    journal_type: '完成振替入金',
    journal_type_number: 1,
    data_type: 92,
    journal_entry_format: 3,
    slip_type: 0,
    supplementary_code_debit: null,
    subdivision_1st_debit: null,
    subdivision_2nd_debit: null,
    auxiliary_code_2nd_debit: null,
    //consumption_tax_code_debit: null,
    //consumption_tax_rate_debit: null,
    tax_excluded_type_debit: 0,
    supplementary_code_credit: null,
    subdivision_1st_credit: 3,
    subdivision_2nd_credit: null,
    auxiliary_code_2nd_credit: null,
    //consumption_tax_code_credit: null,
    //consumption_tax_rate_credit: null,
    tax_excluded_type_credit: 0,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }, {
    id: '4',
    journal_type: '仕入',
    journal_type_number: 1,
    data_type: 93,
    journal_entry_format: 3,
    slip_type: 0,
    supplementary_code_debit: null,
    subdivision_1st_debit: null,
    subdivision_2nd_debit: null,
    auxiliary_code_2nd_debit: null,
    //consumption_tax_code_debit: 0,
    //consumption_tax_rate_debit: null,
    tax_excluded_type_debit: 0,
    supplementary_code_credit: null,
    subdivision_1st_credit: 3,
    subdivision_2nd_credit: null,
    auxiliary_code_2nd_credit: null,
    //consumption_tax_code_credit: null,
    //consumption_tax_rate_credit: null,
    tax_excluded_type_credit: 0,
    updated_at: '2023-01-21',
    updated_by_name: '社員Ａ',
    updated_by_kana: 'シャインエー',
  }]

  return {
    journalFixedValues,
  }
}
