import {
  inject,
  ref,
  reactive,
} from 'vue'
import {
  ContentsStatus,
} from '@/const/component/pageForm'
import {
  ListViewColumn,
  ListViewRow,
  ListViewIcon,
  ListViewConverter,
  ListViewSort,
} from '@/const/component/listView'
import ConstEstimates from '@/const/transaction/estimates'

const constEstimates = ConstEstimates()

const baseListColumns: ListViewColumn[] = [{
  id: 'estimateInformation',
  text: constEstimates.columnLabel.get('estimateInformation'),
  align: 'center',
  columns: [{
    id: 'status',
    text: constEstimates.columnLabel.get('status'),
    displayField: 'status',
    sortField: 'status_number',
    align: 'center',
    width: '100px',
  }, {
    id: 'id',
    text: constEstimates.columnLabel.get('id'),
    displayField: 'id',
    sortField: 'id_number',
    align: 'right',
  }, {
    id: 'revision_no',
    text: constEstimates.columnLabel.get('revisionNo'),
    displayField: 'revision_no',
    sortField: 'revision_no',
    align: 'right',
  }, {
    id: 'revision_description',
    text: constEstimates.columnLabel.get('revisionDescription'),
    displayField: 'revision_description',
    sortField: 'revision_description',
  }, {
    id: 'memo',
    text: constEstimates.columnLabel.get('memo'),
    displayField: 'memo',
    sortField: 'memo',
  }, {
    id: 'estimate_date',
    text: constEstimates.columnLabel.get('estimateDate'),
    displayField: 'estimate_date',
    sortField: 'estimate_date',
  }, {
    id: 'issue_date',
    text: constEstimates.columnLabel.get('issueDate'),
    displayField: 'issue_date',
    sortField: 'issue_date',
  }, {
    id: 'issuer',
    text: constEstimates.columnLabel.get('issuer'),
    displayField: 'issuer_name',
    sortField: 'issuer_kana',
  }, {
    id: 'sent_date',
    text: constEstimates.columnLabel.get('sentDate'),
    displayField: 'sent_date',
    sortField: 'sent_date',
  }, {
    id: 'sender',
    text: constEstimates.columnLabel.get('sender'),
    displayField: 'sender_name',
    sortField: 'sender_kana',
  }, {
    id: 'updated_at',
    text: constEstimates.columnLabel.get('updatedAt'),
    displayField: 'updated_at',
    sortField: 'updated_at',
  }, {
    id: 'updated_by',
    text: constEstimates.columnLabel.get('updatedBy'),
    displayField: 'updated_by_name',
    sortField: 'updated_by_kana',
  }],
}, {
  id: 'estimateInformation_draft',
  text: constEstimates.columnLabel.get('estimateInformation'),
  align: 'center',
  columns: [{
    id: 'id',
    text: constEstimates.columnLabel.get('id'),
    displayField: 'id',
    sortField: 'id_number',
    align: 'right',
  }, {
    id: 'revision_no',
    text: constEstimates.columnLabel.get('revisionNo'),
    displayField: 'revision_no',
    sortField: 'revision_no',
    align: 'right',
  }, {
    id: 'revision_description',
    text: constEstimates.columnLabel.get('revisionDescription'),
    displayField: 'revision_description',
    sortField: 'revision_description',
  }, {
    id: 'memo',
    text: constEstimates.columnLabel.get('memo'),
    displayField: 'memo',
    sortField: 'memo',
  }, {
    id: 'estimate_date',
    text: constEstimates.columnLabel.get('estimateDate'),
    displayField: 'estimate_date',
    sortField: 'estimate_date',
  }, {
    id: 'issue_date',
    text: constEstimates.columnLabel.get('issueDate'),
    displayField: 'issue_date',
    sortField: 'issue_date',
  }, {
    id: 'issuer',
    text: constEstimates.columnLabel.get('issuer'),
    displayField: 'issuer_name',
    sortField: 'issuer_kana',
  }, {
    id: 'updated_at',
    text: constEstimates.columnLabel.get('updatedAt'),
    displayField: 'updated_at',
    sortField: 'updated_at',
  }, {
    id: 'updated_by',
    text: constEstimates.columnLabel.get('updatedBy'),
    displayField: 'updated_by_name',
    sortField: 'updated_by_kana',
  }],
}, {
  id: 'estimateInformation_waiting',
  text: constEstimates.columnLabel.get('estimateInformation'),
  align: 'center',
  columns: [{
    id: 'id',
    text: constEstimates.columnLabel.get('id'),
    displayField: 'id',
    sortField: 'id_number',
    align: 'right',
  }, {
    id: 'revision_no',
    text: constEstimates.columnLabel.get('revisionNo'),
    displayField: 'revision_no',
    sortField: 'revision_no',
    align: 'right',
  }, {
    id: 'revision_description',
    text: constEstimates.columnLabel.get('revisionDescription'),
    displayField: 'revision_description',
    sortField: 'revision_description',
  }, {
    id: 'memo',
    text: constEstimates.columnLabel.get('memo'),
    displayField: 'memo',
    sortField: 'memo',
  }, {
    id: 'estimate_date',
    text: constEstimates.columnLabel.get('estimateDate'),
    displayField: 'estimate_date',
    sortField: 'estimate_date',
  }, {
    id: 'issue_date',
    text: constEstimates.columnLabel.get('issueDate'),
    displayField: 'issue_date',
    sortField: 'issue_date',
  }, {
    id: 'issuer',
    text: constEstimates.columnLabel.get('issuer'),
    displayField: 'issuer_name',
    sortField: 'issuer_kana',
  }, {
    id: 'sent_date',
    text: constEstimates.columnLabel.get('sentDate'),
    displayField: 'sent_date',
    sortField: 'sent_date',
  }, {
    id: 'sender',
    text: constEstimates.columnLabel.get('sender'),
    displayField: 'sender',
    sortField: 'sender',
  }, {
    id: 'updated_at',
    text: constEstimates.columnLabel.get('updatedAt'),
    displayField: 'updated_at',
    sortField: 'updated_at',
  }, {
    id: 'updated_by',
    text: constEstimates.columnLabel.get('updatedBy'),
    displayField: 'updated_by_name',
    sortField: 'updated_by_kana',
  }],
}, {
  id: 'contractInformation',
  text: constEstimates.columnLabel.get('contractInformation'),
  align: 'center',
  columns: [{
    id: 'contract_id',
    text: constEstimates.columnLabel.get('contractId'),
    displayField: 'contract_id',
    sortField: 'contract_id_number',
    align: 'right',
  }, {
    id: 'contract_name',
    text: constEstimates.columnLabel.get('contractName'),
    displayField: 'contract_name',
    sortField: 'contract_kana',
  }, {
    id: 'client',
    text: constEstimates.columnLabel.get('client'),
    displayField: 'client_name',
    sortField: 'client_kana',
  }],
}, {
  id: 'constractorInformation',
  text: constEstimates.columnLabel.get('constractorInformation'),
  align: 'center',
  columns: [{
    id: 'base',
    text: constEstimates.columnLabel.get('base'),
    displayField: 'base_name',
    sortField: 'base_display_order',
  }],
}, {
  id: 'transactionInformation',
  text: constEstimates.columnLabel.get('transactionInformation'),
  align: 'center',
  columns: [{
    id: 'delivery_date',
    text: constEstimates.columnLabel.get('deliveryDate'),
    displayField: 'delivery_date',
    sortField: 'delivery_date',
  }, {
    id: 'place_of_delivery',
    text: constEstimates.columnLabel.get('placeOfDelivery'),
    displayField: 'place_of_delivery',
    sortField: 'place_of_delivery',
  }, {
    id: 'transaction_system',
    text: constEstimates.columnLabel.get('transactionSystem'),
    displayField: 'transaction_system',
    sortField: 'transaction_system',
  }, {
    id: 'date_of_expiry',
    text: constEstimates.columnLabel.get('dateOfExpiry'),
    displayField: 'date_of_expiry',
    sortField: 'date_of_expiry',
  }, {
    id: 'amount_including_tax',
    text: constEstimates.columnLabel.get('amountIncludingTax'),
    displayField: 'amount_including_tax',
    sortField: 'amount_including_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'amount_excluding_tax',
    text: constEstimates.columnLabel.get('amountExcludingTax'),
    displayField: 'amount_excluding_tax',
    sortField: 'amount_excluding_tax',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }, {
    id: 'consumption_tax_amount',
    text: constEstimates.columnLabel.get('consumptionTaxAmount'),
    displayField: 'consumption_tax_amount',
    sortField: 'consumption_tax_amount',
    displayConvert: 'numericalDelimiter',
    align: 'right',
  }],
}]

const standardListColumns = {
  estimate: [
    'estimateInformation',
    'contractInformation',
    'constractorInformation',
    'transactionInformation',
  ],
  general: [
    'estimateInformation',
    'contractInformation',
    'constractorInformation',
    'transactionInformation',
  ],
  draft: [
    'estimateInformation_draft',
    'contractInformation',
    'constractorInformation',
    'transactionInformation',
  ],
  waiting: [
    'estimateInformation_waiting',
    'contractInformation',
    'constractorInformation',
    'transactionInformation',
  ],
}

const listIcon: ListViewIcon = {
  caution: {
    name: 'triangle-exclamation',
    classes: [
      '--error',
    ]
  },
}
const listConverter: ListViewConverter = {
  status: {
    '0': {
      text: constEstimates.status.get('0') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(100,0,0)',
        color: 'rgb(255,255,255)',
      },
    },
    '10': {
      text: constEstimates.status.get('10') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(200,100,100)',
        color: 'rgb(255,255,255)',
      },
    },
    '11': {
      text: constEstimates.status.get('11') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(0,0,200)',
        color: 'rgb(255,255,255)',
      },
    },
    '12': {
      text: constEstimates.status.get('12') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(0,140,0)',
        color: 'rgb(255,255,255)',
      },
    },
    '13': {
      text: constEstimates.status.get('13') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(200,100,0)',
        color: 'rgb(255,255,255)',
      },
    },
    '14': {
      text: constEstimates.status.get('14') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(200,0,0)',
        color: 'rgb(255,255,255)',
      },
    },
    '20': {
      text: constEstimates.status.get('20') ?? '',
      type: 'rectangle',
      style: {
        background: 'rgb(0,206,186)',
        color: 'rgb(255,255,255)',
      },
    },
    '21': {
      text: constEstimates.status.get('21') ?? '',
      type: 'rectangle',
      style: {
        background: 'rgb(100,130,100)',
        color: 'rgb(255,255,255)',
      },
    },
    '22': {
      text: constEstimates.status.get('22') ?? '',
      type: 'rectangle',
      style: {
        background: 'rgb(80,80,80)',
        color: 'rgb(255,255,255)',
      },
    },
  },
  /*
  result: {
    WAITING:{
      text: constEstimates.result.get('WAITING') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(200,140,0)',
        color: 'rgb(255,255,255)',
      },
    },
    ADOPTION: {
      text: constEstimates.result.get('ADOPTION') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(70,200,50)',
        color: 'rgb(255,255,255)',
      },
    },
    REJECTION: {
      text: constEstimates.result.get('REJECTION') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(120,140,0)',
        color: 'rgb(255,255,255)',
      },
    },
    LOST: {
      text: constEstimates.result.get('LOST') ?? '',
      type: 'ellipse',
      style: {
        background: 'rgb(200,0,0)',
        color: 'rgb(255,255,255)',
      },
    },
  },
  */
}

export default function estimate () {
  const contentsStatus = inject('contentsStatus') as ContentsStatus

  const selectedListItem = ref<ListViewRow | null>(null)
  const checkedListItems = ref<ListViewRow[]>([])
  const listSort: ListViewSort = reactive({
    field: 'id_number',
    order: {
      'id_number': 1,
    },
  })

  const getFormItems = () => {
    return standardListColumns[
      contentsStatus.page as keyof typeof standardListColumns
    ] ?? []
  }
  const getListColumns = () => {
    const formItems = getFormItems()
    const items: ListViewColumn[] = []
    for (const id of formItems) {
      const item = baseListColumns.find(el => el.id === id)
      if (item) items.push(item)
    }
    return items
  }
  const setListColumns = (items: string[]) => {
    console.log('setListColumns', items)
  }

  return {
    baseListColumns,
    listIcon,
    listConverter,
    listSort,
    selectedListItem,
    checkedListItems,
    getListColumns,
    setListColumns,
  }
}
