import { useQuery, useResult } from '@vue/apollo-composable'
import { AUTHORIZED_AS_EMPLOYEE } from '@/graphql/queries/auth/AuthorizedAsEmployee'
import { Employee } from '@/graphql'

export default function useGetAuthorizedEmployee () {
  const { result, loading, error } = useQuery(AUTHORIZED_AS_EMPLOYEE, {}, {fetchPolicy: 'network-only'})
  const authorizedEmployee = useResult<Employee>(result)

  return {
    authorizedEmployee,
    loading,
    error
  }
}
